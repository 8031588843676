import { useContext } from 'react'
import styled, { css } from 'styled-components'
import PropsContext from 'context/props'
import { moduleBaseFontPadding } from './FontFamilys'

const WrapperStyled = styled.div`
    width: 100%;
    display: table;
    border-spacing: 5px;
    margin-left: 0;

    & > div {
        display: table-row;
    }

    & > div > div {
        display: table-cell;
        word-break: break-all;
        width: ${({layout}) => `${layout}%`};
        transition: all .3s;
        vertical-align: top;
        padding: 8px;
        border: 1px dashed ${({ answer_button}) => `rgba(${answer_button})`};
        border-radius: 3px;

        &:hover {
            background: rgba(185, 185, 185, .1);
        }
    }

    & > div > div.selected {
        border: 1px solid ${({ answer_button}) => `rgba(${answer_button})`};
    }

    & > div > div.selected input[type=text] {
        
    }

    & > div > div.disabled {
        
        &:hover {
            background: transparent;
        }
    }

    & > div > div.disabled input[type=text] {
       
    }


    & > div > div + div {
        margin-left: 10px;
    }

    & > div:not(:first-child) > div {
        margin-top: 4px;
    }
`

const Row1Styled = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: .3em;
    padding-left: 0;
    
    & > div.row1 {
        align-self: flex-start;    
        width: 2.8em;

    }

    & > div.row2 {
        align-self: flex-start;
        width: calc(100% - 2.8em);

        padding-right: .5em;

        ${props => moduleBaseFontPadding[props.font_family]};

        ${props => props.disabled && css`
            opacity: .5;
        `}
    }
`

const Row2Styled = styled.div`
    padding-top: .3em;
    
`

const Wrapper = (props) => {
    const { layout, children } = props
    const { design } = useContext(PropsContext)
    const { answer_button } = design
   
    return (<WrapperStyled layout={layout} answer_button={answer_button}>{children}</WrapperStyled>)
}

const Row1 = (props) => {
    const { disabled, onClick, children } = props
    const { design } = useContext(PropsContext)
    const { font_family } = design
    return (
        <Row1Styled 
            disabled={disabled}
            onClick={onClick}
            font_family={font_family}
        >{children}</Row1Styled>
    )
}


const Row2 = (props) => {
    const { children } = props
    
    return (<Row2Styled>{children}</Row2Styled>)
}



export {
    Wrapper,
    Row1,
    Row2
}