/*
** 설문 참여, 요약 공개페이지 메타태크 설정
** 
*/
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { key as fmi } from 'config/facebook.json'

const locale = {
    'ko-KR': 'ko_KR',
    'en-US': 'en_US'
}

const localeAlternate = {
    'ko-KR': 'en_US',
    'en-US': 'ko_KR'
}
const Component = (props) => {
    // type: survey, analysis (설문참여페이지, analysis)
    const { type, title, url } = props

    const { formatMessage: f, locale: lang } = useIntl()

    const metaTitle = f({id: 'hooks.useHeadTag.title'}, { title })
    const metaContent = f({id: `hooks.useHeadTag.content.${type}`})

    return (
        <Helmet>
            <title>{metaTitle}</title>
            <link rel="canonical" href={process.env.REACT_APP_LANDING}/>
            <meta  name="description" content={metaContent}/>
            <meta property="og:description" content={metaContent}/>
            <meta property="og:locale" content={locale[lang]}/>
            <meta property="og:locale:alternate" content={localeAlternate[lang]}/>
            <meta property="og:title" content={metaTitle}/>
            <meta property="og:image" content={`${process.env.REACT_APP_IMG}/server/logo.png`}/>
            <meta property="og:url" content={url}/>
            <meta property="fb:app_id" content={fmi}/>
            <meta property="og:type" content="website"/>
            <meta name="twitter:title" content={metaTitle}/>
	        <meta name="twitter:description" content={metaContent} />
            {
                process.env.REACT_APP_DOMAIN !== 'listovey.com' && <meta name="ROBOTS" content="NOINDEX, NOFOLLOW"/>
            }
        </Helmet>
    )
 }
 
export default Component
