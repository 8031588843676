import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Method } from 'gql/survey_mbox'
import ConfirmComponent from 'component/Survey/Edit/Confirm'
import {
    SurveyEditTabsComponent,
    SurveyEditTabComponent,
    SurveyEditQuestionComponent,
    SurveyEdit16ExampleComponent, 
    SurveyEdit16OptionComponent,
    SurveyEditSubTabsComponent
} from 'component'

interface Props {
    method: Method
}

const Component = (props: Props) => {
    const { method } = props
    const { formatMessage: f } = useIntl()
    
    const tabs = [f({id: 'container.Survey.Edit.01.tabs.0'}), f({id: 'container.Survey.Edit.01.tabs.1'})]
    const subTabs = [f({id: 'container.Survey.Edit.16.subTabs.0'})]

    if (method === Method.One) subTabs.push(f({id: 'container.Survey.Edit.16.subTabs.1'}))

    return (
        <>
            <SurveyEditTabsComponent tabs={tabs}/>
            <SurveyEditTabComponent index={0}>
                <SurveyEditQuestionComponent/>
                <SurveyEditSubTabsComponent subTabs={subTabs}/>
                <SurveyEdit16ExampleComponent/>
                <ConfirmComponent index={1}/>
            </SurveyEditTabComponent>
            <SurveyEditTabComponent index={1}>
                <SurveyEdit16OptionComponent/>
            </SurveyEditTabComponent>
        </>
    )
}

export default memo(Component)