
import { useCallback, useState, useEffect, createContext } from 'react'
import oc from 'open-color'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import htmlParser from 'react-html-parser'
import { OuterContainer } from 'container'
import GroupContainer from './Group'

export const PPP = createContext({
  flag: false
})

const Workspace = () => {

    const [open, setOpen] = useState(false);

    const [flag, setFlag] = useState(0)

    const handleClickOpen = useCallback(() => {
      setOpen(true)
    }, [])
  
    const handleClose = useCallback(() => {
      setOpen(false);
    }, [])

const handleChange = useCallback(() => {
    localStorage.setItem('notice-20230525', 'checked')
      setOpen(false)
}, [])





    useEffect(() => {
        /*
        setTimeout(() => {
          if (!localStorage.getItem('notice-20230525')) handleClickOpen()
        }, 1000)
        */
        
    }, [handleClickOpen])
    

    return (
        <>
        <PPP.Provider value={{flag}}>
          <OuterContainer>
            <GroupContainer/>
          </OuterContainer>
        </PPP.Provider>
       





        <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title" style={{background: oc.indigo[9], color: 'white'}}>
          공지사항
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{color: 'black', paddingTop: 10, fontSize: 15}}>
            {
                htmlParser(`
                안녕하세요, 리스토베이입니다.<br /><br />

데이터베이스 보안 패치 작업으로 인해 위의 기간 동안

사이트 접속이 일시적으로 제한될 예정입니다.<br /><br />

<font color='#d9480f'><b>- 점검 기간: 2023년 5월 28일 (일) 01:00 ~ 03:00</b></font><br /><br />
 

보다 안정적이고 쾌적한 서비스를 제공하기 위해 진행되는 작업이오니,

고객님들의 너그러운 이해를 부탁 드립니다.<br/><br/>

궁금하신 사항은  <a href='mailto:support@listovey.com' style='color: black; text-decoration: none;'>support@listovey.com</a>으로 문의 주시기 바랍니다.
                
               `)
            }
          </DialogContentText>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <FormControlLabel

        control={<Checkbox onChange={handleChange}  />}
        label={<Typography variant="body1">다시 보지 않기</Typography>}
      />
          <Button onClick={handleClose}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
        </>
    )
}

export default Workspace