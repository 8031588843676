import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    TextField, 
    Typography
} from '@material-ui/core'
import { SEND_NEW_EMAIL_AUTH } from 'gql/user_info'
import { SHOW as BACKDROPSHOW, HIDE as BACKGROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { withBreakPoints } from 'hoc'
import { getMaxlength } from 'policy'
import ReactHtmlParser from 'react-html-parser'
import { validation } from 'utils'
import { postMessage } from 'utils'

const Component = props => {
    const { open, email: oriEmail, onRefetch, onClose, breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const [ email, setEmail ] = useState('')

    const dispatch = useDispatch()

    const [ sendNewEmailAuth ] = useMutation(SEND_NEW_EMAIL_AUTH, {
        onCompleted: async(data) => {
            if (!data.sendNewEmailAuth) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW,
                    message: f({id: 'component.MyAccount.Info.EmailModal.overlap'}),
                    variant: 'error'
                })
                return
            }
            
            dispatch({ type: BACKGROPHIDE })
            onRefetch()
            onClose()
        },
        onError: (e) => {
            dispatch({ type: BACKGROPHIDE })

            postMessage({ 
                dispatch: { type: ERRORPAGESHOW } 
            })
        }
    })

    const isEmail = !email ? true : validation.email(email)
    
    const changeEmail = useCallback((e) => {
        const { value } = e.target
        setEmail(value)
    }, [])

    const handleSave = useCallback(async() => {
        if (!isEmail) {
            dispatch({ 
                type: ALERTSNACKBARSHOW,
                message: f({id: 'component.MyAccount.Info.EmailModal.validation'}),
                variant: 'error'
            })
            return
        }

        await dispatch({type: BACKDROPSHOW })
        sendNewEmailAuth({ variables: { email } })
    }, [f, email, isEmail, sendNewEmailAuth, dispatch])

    useEffect(() => {
        if (!open) setEmail('')
    }, [open])



    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={xs}>
            <DialogTitle id="myaccount-change-email">{f({id: 'component.MyAccount.Info.EmailModal.title'})}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {ReactHtmlParser(f({id: 'component.MyAccount.Info.EmailModal.content'}))}
                </DialogContentText>
                <TextField 
                    autoFocus 
                    margin="dense" 
                    label={f({id: 'component.MyAccount.Info.EmailModal.label'})} 
                    fullWidth 
                    onChange={changeEmail}
                    value={email}
                    error={!isEmail}
                    inputProps={{
                        maxLength: getMaxlength('common.email')
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary" disabled={oriEmail === email || !email}>
                    <Typography variant="button">{f({id: 'component.MyAccount.Info.EmailModal.button.save'})}</Typography>
                </Button>
                <Button onClick={onClose} color="inherit">
                    <Typography variant="button">{f({id: 'component.MyAccount.Info.EmailModal.button.close'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default withBreakPoints(Component)