/**
*** 페이지 기준 점프
**/
import { useEffect, ComponentType, useCallback, useState } from "react"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { useQuery } from "@apollo/client"
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_JUMP_IN_RESPONSE, Kind, Method, Type, JumpProps, JumpRuleProps, JumpRuleChildProps, Conditiond } from "gql/jump"
import { GET_JUMP_POINT_WITH_RELATION } from "gql/jump_point"
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { getCurrentIndex } from './FetchQuestionsOne'
import { FormatAll } from './SetReplys'
import { ClassFilterJump, ClassProps, HideQuestionNos, JumpTarget, initState as defaultInitState, getHideQuestionNos } from './FetchQuestionsWithJumpMulti'

interface ArgsProps {
    mode: string;
    loading: boolean;
}

interface ComponentProps {
    rowsJump: JumpProps[];
    isJumpPoint: boolean;
    questions: SurveyQuestionModuleProps[];
    loadingJump: boolean;
    hideQuestionNos: HideQuestionNos;
    moveHideQuestionNos: HideQuestionNos;
    target: JumpTarget;
    getFilterJumpQuestions: (replys: FormatAll[]) => void;
}

interface ClassOneProps extends ClassProps {
    currentIndex: number;
}

class ClassFilterJumpOne extends ClassFilterJump {
    currentIndex: number;
    moveHideQuestionNos: HideQuestionNos; // 이동시, 그사이에 숨긴값의 question_nos

    constructor(props: ClassOneProps) {
        const { currentIndex, ...rest } = props
        super({ ...rest })

        this.currentIndex = currentIndex
        this.moveHideQuestionNos = []
    } 

    // 현재페이지는에서 다음으로 넘어갈때는 survey_question_nos[0], 이전페이지로 갈때는 prev_survey_page_no
    updateEndNos(method: Method, survey_question_nos: JumpProps['survey_question_nos'], questionIndex: number, id: JumpProps['id']) {
        // 설정된값이 없다는것은 점프설정을 안햇다는것임으로 return
        if (!survey_question_nos[0]) return

        const startIndex = getCurrentIndex(this.questions, id)
        const endIndex = method === Method.End ? this.questions.length : getCurrentIndex(this.questions, survey_question_nos[0])

        for(let i=(startIndex+1); i<endIndex; i++) {
            const question = this.questions[i]
            if (question) {
                if (question._question.survey_question_no) {
                    this.moveHideQuestionNos.push(question._question.survey_question_no)
                }
            }
        }

        // 출발점과 도착점의 index 구하기

        // 해당 문항의 설정값을 저장한다.
        if (questionIndex === this.currentIndex) {
            this.targetMethod = method
            this.targetID = survey_question_nos[0]
            return
        } 

        const cindex = getCurrentIndex(this.questions, survey_question_nos[0])

        // 해당 문항 이전의 값을 체크해서 목표값이 현재 문항이 아니라면 null
        if (questionIndex < this.currentIndex && cindex !== this.currentIndex) { // 목표값이 현재페이지값일때만 부여한다.
            this.prevTargetMethod = null
            this.prevTargetID = null
            return
        } 

        // 해당 문항 이전의 값을 체크해서 목표값이 현재 문항이 아니라면 해당문항의 id를 적용한다 (응답 마지막값이 기준이 된다.)
        if (questionIndex < this.currentIndex && cindex === this.currentIndex) { // 목표값이 현재페이지값일때만 부여한다.
            this.prevTargetMethod = method
            this.prevTargetID = id
        }
    }

    do() {
        const that = this

        // 문항별 포인트 총합 구하기
        this.points()

        function forRules(typename: string, id: JumpProps['id'], index: number, rules: JumpRuleProps[]) {

            function forRuleChilds(childs: JumpRuleChildProps[]) {
                // childs의 전체 조건에 대한 참거짓
                let bool = false

                const childsLength = childs.length
                for (let i=0; i<childsLength; i++) {
                    const { type, sign, conditiond, survey_module_nos, survey_module_answer_no, value } = childs[i]

                    // child별 참 거짓
                    let childBool = false

                    // child 조건
                    if (type === Type.Question) {
                        if (typename === 'Module_09' || typename === 'Module_10') {
                            childBool = that.questionText(id, sign, value)
                        } else if (typename === 'Module_20') {
                            childBool = that.questionAddress(id, sign, value)
                        } else if (type === Type.Question && sign === 1) {
                            childBool = that.questionSign1(typename, id, survey_module_nos, survey_module_answer_no)
                        } else if (type === Type.Question && sign === 2) {
                            childBool = that.questionSign2(typename, id, survey_module_nos, survey_module_answer_no)
                        } 
                    } else if (type === Type.Point) {
                        childBool = that.pointSign(id, sign, value)
                    }

                    // 첫번째 조건은 conditiond pass
                    if (i === 0) {
                        bool = childBool
                        continue
                    }

                    // and 조건은 앞조건과 자신의 조건 모두 true여야 true
                    // or 조건은 앞조건이나 자신의 조건 둘중하나가 true 이면 true
                    if (conditiond === Conditiond.And) bool = bool && childBool
                    else if (conditiond === Conditiond.Or) bool = bool || childBool
                }

                return bool
            }

            // 해당 조건 참 거짓
            let bool = false

            const rulesLength = rules.length
            for (let j=0; j<rulesLength; j++) {
                const { method, survey_question_nos, childs } = rules[j]

                // child 조건문 체크
                const _bool = forRuleChilds(childs)

                // 참일경우 조건을 실행한다.
                if (_bool) {
                    if (method === Method.Hide) that.updateHideNos(survey_question_nos)
                    else if (method === Method.Show) that.updateShowNos(survey_question_nos)
                    else if (method === Method.Move || method === Method.End) that.updateEndNos(method, survey_question_nos, index, id)

                    // 참이면 다음 룰이 false일지라도 true이다. (rules중에 하나만 true여도 else 실행하지 않기 위해서)
                    bool = true
                }
            }

            return bool
        }

        // 점프 로우데이와 replys로 숨김 보이기, 처리를 한다.
        const jumpLenth = this.jump.length
        for (let i=0; i<jumpLenth; i++) {
            const { kind, id, method, survey_question_nos, rules } = this.jump[i]

            // 페이지는 검증안한다. (미사용)
            if (kind === Kind.Page) continue

            let index = -1
            const question = this.questions.find((c, j) => {
                
                if (c._question.survey_question_no === id) {
                    index = j
                    return true
                }
                return false
            })

            // 검증하는 문항이 화면에 보이는 현재문항보다 클경우 검증할필요없다.
            if (index > this.currentIndex) continue

            // 점프로직에는 있지만 삭제된 문항일수가있다. 그렇다면 체크할필요가 없다.
            if (!question) continue
            const { __typename } = question
 
            // rules 검증
            const bool = forRules(__typename, id, index, rules)

            // rules에 걸리는게 없다면 Default 실행
            if (!bool) {
                // 체크한 값이 있을경우 실행한다
                if (this.replysInKey[id]) {
                    // 본 문항이 숨김처리로 되어있을경우 로직 안탄다.
                    if (!this.hideQuestionNos.includes(id)) {
                        if (method === Method.Hide) that.updateHideNos(survey_question_nos)
                        else if (method === Method.Show) that.updateShowNos(survey_question_nos)
                        else if (method === Method.Move || method === Method.End) that.updateEndNos(method, survey_question_nos, index, id)
                    }
                }
            }
        }

        return this.hideQuestionNos
    }
}


const initState = {
    ...defaultInitState,
    hideQuestionNos: []
}

export default (args: ArgsProps) => (WrappedComponent: ComponentType<ComponentProps>)  => (props: { survey_no?: number, questions: SurveyQuestionModuleProps[], currentIndex: number }) => {
    const { mode, loading } = args
    const { questions, currentIndex } = props

    const dispatch = useDispatch()

    const params = useParams<{ survey_no: string }>()

    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    const initHideQuestionNos = getHideQuestionNos(mode, questions)

    const [ data, setData ] = useState<{hideQuestionNos: HideQuestionNos, moveHideQuestionNos: HideQuestionNos, target: JumpTarget}>({
        ...initState,
        hideQuestionNos: initHideQuestionNos,
        moveHideQuestionNos: []
    })

    const { data:jump, loading: loadingJump } = useQuery(GET_JUMP_IN_RESPONSE, {
        variables: {
            survey_no, mode
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const { data:jumpPoint, loading: loadingJumpPoint } = useQuery(GET_JUMP_POINT_WITH_RELATION, {
        variables: {
            survey_no, mode
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const rowsJump = jump ? jump.jumpInResponse : null
    const rowsJumpPoint = jumpPoint ? jumpPoint.jumpPointWithRelation.jumpPoint : null
    const rowsJumpPointText = jumpPoint ? jumpPoint.jumpPointWithRelation.jumpPointText : null

    const getFilterJumpQuestions = useCallback((replys: FormatAll[]) => {
        return new Promise(async(resolve: (a: unknown) => void) => {
            // 점프값이 없다면 리턴
            if (rowsJump.length === 0) {
                resolve(1)
                return
            }

            const filterJump = new ClassFilterJumpOne({ jump: rowsJump, jumpPoint: rowsJumpPoint, jumpPointText: rowsJumpPointText, questions, currentIndex, replys })
            
            filterJump.HideQuestionNos = initHideQuestionNos

            const hideQuestionNos = filterJump.do()
            const moveHideQuestionNos = filterJump.moveHideQuestionNos
            const target = filterJump.getTarget()

            setData({ hideQuestionNos, moveHideQuestionNos, target })

            resolve(1)
        })
    }, [rowsJump, rowsJumpPoint, rowsJumpPointText, questions, currentIndex, initHideQuestionNos])

    useEffect(() =>  {  
        if (loading) {
            if (loadingJump || loadingJumpPoint) dispatch({ type: BACKDROPSHOW })
        }
    }, [loading, loadingJump, loadingJumpPoint, dispatch])

    if (!rowsJump || !rowsJumpPoint || !rowsJumpPointText) return null

    return (
        <WrappedComponent 
            rowsJump={rowsJump}
            isJumpPoint={jumpPoint ? true : false}
            loadingJump={loadingJump} 
            {...props} 
            questions={questions}
            target={data.target}
            hideQuestionNos={data.hideQuestionNos}
            moveHideQuestionNos={data.moveHideQuestionNos}
            getFilterJumpQuestions={getFilterJumpQuestions}
        />
    )
}