/*
**  모든 설정 해제
*/

// 개별문항 및 페이지의 row 추가 삭제 변경시 저장
export const CHANGE = 'survey/create/logic/jumpPointInit/CHANGE'
export const INIT = 'survey/create/logic/jumpPointInit/INIT'

const initState: { val: string } = {
    val: ''
}

interface ActionProps {
    type: string;
    val: string; // 전체 삭제 시, 랜덤 값을 보낸다 
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                val: action.val
            }
        case INIT:
            return initState    
        default:
            return state
    }
}