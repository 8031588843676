import { useCallback, memo } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { ImageListItem  as MuiImageListItem, ImageListItemBar as MuiImageListItemBar, Typography, IconButton } from '@material-ui/core'
import { MeetingRoomTwoTone as MeetingRoomTwoToneIcon } from '@material-ui/icons'
import { app_name } from 'config/unsplash.json'
import Tooltip from 'styled/Custom/Tooltip'
import { isMobile } from 'react-device-detect'
import ImgComponent from './Img'

const useStyles = makeStyles((theme) => ({
    authorIcon: {
        color: 'white'
    }
}))

const ImageListItem = withStyles(theme => ({
    root: (props) => {
        const { is_bar, tab } = props

        /*
        ** isbar이고 isMobile이라면 opacity 1
        ** isbar이고 !isMobile이라면 opacity 0, hover시 1
        ** !isbar이면 무조건 opacity 0 
        */

        let opacity = 0
        let opacityHover = 0

        if (tab === 'my') {
            opacity = 1
            opacityHover = 1
        } else if (is_bar && isMobile) {
            opacity = 1
            opacityHover = 1
        } else if (is_bar && !isMobile) {
            opacity = 0
            opacityHover = 1
        }

        return {
            width: '100%',
            height: '100%',
            padding: 2,
            position: 'relative',
            cursor: 'pointer',
            border: '1px solid white',
            '&.selected': {
                cursor: 'default',
                border:  `2px solid ${theme.palette.secondary.dark}`,
                borderRadius: 3
            },
            '& .bar': {
                cursor: 'default',
                opacity,
                transition: 'opacity .3s',
            },
            '&:hover': {
                '& .bar': {
                    opacity: opacityHover
                }
            }
        }
        
    }
}))(MuiImageListItem)

const ImageListItemBar = withStyles({
    root: {
        height: 'initial',
        padding: 10,
        paddingLeft: 0,
        color: 'white'
    },
})(MuiImageListItemBar)



const TitleTypography = withStyles({
    root: {
        fontSize: 13
    },
})(Typography)

const SubtitleTypography = withStyles({
    root: {
        fontSize: 12
    },
})(Typography)

function visitAuthor(author) {
    window.open(`https://unsplash.com/@${author}?utm_source=${app_name}&utm_medium=referral`, "_blank")
}


const ItemComponent = (props) => {
    const { tabValue, no, name, subtitle, author, src, selected, onDeleteFile } = props

    const classes = useStyles()

    const isSelected = no === selected ? 'selected' : ''

    const ActionIcon = useCallback((props) => {
        const { author } = props

        if (!author) return null

        return (
            <Tooltip title="visit author by unsplash">
                <IconButton 
                    size="small" 
                    onClick={(e) => {
                        e.stopPropagation()
                        visitAuthor(author) 
                    }}
                >
                    <MeetingRoomTwoToneIcon fontSize="small" className={classes.authorIcon}/>
                </IconButton>
            </Tooltip>
        )
    }, [classes])

    return (
        
        <ImageListItem 
            component="div"
            className={isSelected}  
            is_bar={name || subtitle || author}
            tab={tabValue}
        >
            <ImgComponent tabValue={tabValue} no={no} src={src} name={name} onDeleteFile={onDeleteFile}/>
              
            <ImageListItemBar 
                className="bar" 
                title={<TitleTypography>{name}</TitleTypography>} 
                subtitle={<SubtitleTypography>{subtitle}</SubtitleTypography>} 
                actionIcon={<ActionIcon author={author}/>}
            />
        </ImageListItem>
    )
}

export default memo(ItemComponent, (prev, next) => {
    const prevSelected = prev.no === prev.selected
    const nextSelected = next.no === next.selected
    
    return prevSelected === nextSelected
})