import { memo } from 'react'
import { useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { withStyles } from '@material-ui/core/styles'
import { Props as ParentProps } from './Items'

interface Props {
    answer_color: ParentProps['answer_color'];
    line_color: ParentProps['line_color'];
    font_family: ParentProps['font_family'];
    search: string;
    itemLength: number;
}

export const DropdownListItemIcon = withStyles({
    root: {
        minWidth: 45
    }
})(ListItemIcon)

function NothingComponent(props: Props) {
    const {  answer_color, line_color, font_family, search, itemLength } = props

    const { formatMessage: f } = useIntl()

    if (!search || itemLength > 0) return null

    return (
        <MenuItem dense button={false}>
            <DropdownListItemIcon>
                <WarningRoundedIcon style={{color: `rgba(${line_color})`}}/>
            </DropdownListItemIcon>
            <ListItemText>
                <Typography style={{ color: `rgba(${answer_color})`, fontFamily: font_family }}>
                    {f({id: 'component.Survey.Modules.21.Nothing.message'})}
                </Typography>
            </ListItemText>
        </MenuItem>
    
    )
}

export default memo(NothingComponent)