import { memo } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { getQuestionParsing } from 'utils/survey'
import Question from 'styled/Custom/Question'
import htmlParser from 'react-html-parser'

const WrapperStyled = styled.div`
    font-size: 1.3rem;
    word-break: break-all;
    position: relative;
`
const HasNotNumberStyled = styled.div`
    width: 100%;
`

function QuestionComponent(props) {
    const { question } = props

    const { formatMessage: f } = useIntl()

    return (
        <WrapperStyled>
            <HasNotNumberStyled>
                <Question>{getQuestionParsing(question) || htmlParser(f({id: 'component.Survey.Modules.Common.Subject.question'}))}</Question>
            </HasNotNumberStyled>
        </WrapperStyled> 
    )
}

export default memo(QuestionComponent)