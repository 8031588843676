/**
 * save 이벤트시 빈값이 잇을경우 해당값을 펼침하고 포커스준다.
 */
export const INIT = 'survey/create/logic/jumpPointError/INIT'

// 빈값이 있을경우 해당 id kind 저장
export const CHANGE = 'survey/create/logic/jumpPointError/CHANGE'

interface Props {
    survey_question_no: number;
}

const initState: Props = {
    survey_question_no: 0,
}
 
interface ActionProps {
    type: string;
    survey_question_no: number;
}
 
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case INIT:
            return initState
        case CHANGE: 
            return {
                survey_question_no: action.survey_question_no
            }     
        default:
             return state
    }
}