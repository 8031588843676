import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducer'
import { HIDE } from 'reducer/survey/create/moduleTip'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useIntl } from 'react-intl'
import  withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import ReactIframeComponent from 'styled/ReactIframe'

interface Props {
    breakpoints: BreakPointsProps
}

const MyDialog = withStyles(theme => ({
    container: ({ styles }) => {
        return { ...styles }
    }
}))(Dialog)

const MyDialogContent = withStyles(theme => ({
    root: {
        width: '100%',
        height: 500,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            height: '100vh',
            paddingBottom: 0
        }
    }
}))(DialogContent)


function ModuleTipPopupComponent(props: Props) {
    const { breakpoints } = props
    const { xs, sm } = breakpoints

    const dispatch = useDispatch()

    const { formatMessage: f, locale } = useIntl()

    const moduleTip = useSelector((state: RootState) => state.surveyCreateModuleTip)

    const title = moduleTip.index === null ? '' : f({id: `component.Survey.Create.Module.Left.modules.${moduleTip.index}`})

    const localePath = locale === 'en-US' ? '/en' : locale === 'ja-JP' ? '/jp' : ''
    const src = `${process.env.REACT_APP_LANDING}${localePath}/pure/editModule?index=${moduleTip.index}`

    function handleClose() {
        dispatch({ type: HIDE })
    }

    return (
        <MyDialog fullWidth fullScreen={xs} open={typeof moduleTip.index === 'number'} styles={moduleTip.styles} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <MyDialogContent>
                <ReactIframeComponent src={src} style={{border: 'none', borderRadius: 0 }}/>
            </MyDialogContent>
            {
                sm && (
                    <DialogActions onClick={handleClose}>
                        <Button>{f({id: 'ccomponent.ModuleTipPopup.close'})}</Button>
                    </DialogActions>
                )
            }
        </MyDialog>
    )
}

export default withBreakPoints(ModuleTipPopupComponent)