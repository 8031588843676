import gql from 'graphql-tag'

export const CREATE_SURVEY_START = gql`
    mutation CreateSurveyStart($survey_no: Int!) {
        createSurveyStart(survey_no: $survey_no)
    }
`

export const UPDATE_SURVEY_START = gql`
    mutation UpdateSurveyStart($input: UpdateSurveyStart!) {
        updateSurveyStart(input: $input)
    }
`

export const UPDATE_SURVEY_START_USED = gql`
    mutation UpdateSurveyStartUsed($survey_no: Int!, $used: Boolean!) {
        updateSurveyStartUsed(survey_no: $survey_no, used: $used)
    }
`

export const GET_SURVEY_START = gql`
    query SurveyStart($survey_no: Int!, $mode: Mode) {
        surveyStart(survey_no: $survey_no, mode: $mode) {
            message
            button_name
            img_src
            img_position
            img_name
            used
            align
            agree
            agree_icon_type
        }
    }
`

export interface SurveyStartProps {
    message: string;
    button_name: string;
    img_src: string;
    img_position: string;
    img_name: string;
    used: boolean;
    align: string;
    agree: boolean;
    agree_icon_type: string;
}

export interface UpdateSurveyStartUsedVariables {
    survey_no: number;
    used: boolean;
}