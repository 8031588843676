import { memo, useContext } from 'react'
import styled from 'styled-components'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import NumberingComponent from './Numbering'

const getStyle = {
    _1: () => (
        `
        grid-template-columns: 1fr;
        grid-template-rows: auto .5em auto auto;
        grid-template-areas:
            "question"
            "line"
            "module"
            "oneButton";
        `
    ),
    _2: () => (
        `
        grid-template-columns: 1fr;
        grid-template-rows: auto auto .5em auto auto;
        grid-template-areas:
            "question"
            "image"
            "line"
            "module"
            "oneButton";
        `
    ),
}

const WrapperStyled = styled.div`
    display: grid;

    & > div.question {
        grid-area: question;
    }

    & > div.module {
        margin-top: .3em;
        grid-area: module;
    }

    & > div.image {
        grid-area: image;
        margin-top: .5em;
        display: block;
    }

    & img.question {
        
        max-width: 100%;
        border-radius: 3px;
        display: block;
    }

    & > div.oneButton {
        display: ${props => props.isOneButton ? 'block' : 'none'};
        grid-area: oneButton;
        margin-top: 10px;
        padding-left: ${props => (props.module_type === '_01' || props.module_type === '_02') ? '2px' : 0};
    }

    & > div.line {
       /* grid-area: line;
        height: 1px;
        border-top: ${props => `1px dotted rgba(${props.line_color})`};
        opacity: .8;
        margin-top: 1.1em;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);*/
    }

    /*
    경우의 수
    PC: 이미지가 없을때, 이미지가 있을때 - 내용안, 우측 비율맞게, 우측 꽉차게
    모바일: 이미지가 없을때, 이미지가 있을때 - 내용안, 상단 비율맞게, 상단 꽉차게
    */
    ${
        ({mode, method, img_src, img_position}) => (
            mode !== 'edit' && method === 'one' ? (
                !img_src || img_position !== 'stack' ? (
                    `
                    & > div.image {
                        display: none;
                    }
                    ${ getStyle._1() }
                    `
                ) : getStyle._2()
            ) : (
                !img_src ? getStyle._1() : getStyle._2()
            )
        )
        
    }
    
`

function Component(props) {
    const { number, img_src, img_position, module_type, isOneButton=false, required, children } = props

    const { mbox, design } = useContext(PropsContext)
    const { mark_number, mark_required } = mbox
    const { mode } = useContext(AccessMethodContext)

    const { line_color, numbering, font_family } = design
 
    return (
        <>
        <NumberingComponent mark_number={mark_number} mark_required={mark_required} number={number} required={required} module_type={module_type} fontFamily={font_family} questionColor={numbering}/>
        <WrapperStyled 
            mode={mode}
            method={mbox.method}
            line_color={line_color}
            img_src={img_src} 
            img_position={img_position} 
            module_type={module_type}
            isOneButton={isOneButton}
        >
            {children}
        </WrapperStyled>
        </>
  
    )
}

export default memo(Component)