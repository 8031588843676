// 설정값

// 전체 데이터 저장
export const SHOW = 'survey/create/logic/jumpCollapse/SHOW'
export const HIDE = 'survey/create/logic/jumpCollapse/HIDE'

const initState: { open: boolean } = {
    open: false
}

interface ActionProps {
    type: string;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case SHOW:
            return {
                open: true
            }
        case HIDE:
            return {
                open: false
            }
        default:
            return state
    }
}