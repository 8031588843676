import { useContext, useCallback, useEffect, useState } from 'react'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { isEqual } from 'lodash-es'
import { VerifyIsJust } from '../01/Component'
import { memoryReply } from 'container/Survey/Live/Container'
import OverflowComponent from './Overflow'
import CommentComponent from '../Comment'
import useChangeReplys from '../useChangeReplys'
import useJustDebounce from 'hooks/useJustDebounce'

export default (props) => {
    const { mode } = useContext(AccessMethodContext)
    const { mbox, option, format } = useContext(PropsContext)
    const { method } = mbox
    const { just } = option

    const { 
        survey_question_no,
        lists, 
        icon_type, 
        reply,
        is_comment, 
        is_comment_placeholder,
        replyComment
    } = props

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ debounce, onChangDebounce ] = useJustDebounce()

    const [ newReply, setNewReply ] = useState(reply)

    const is_answer = Boolean(lists.find(c => c.answer))

    const handleChange = useCallback(async(survey_module_no) => {
  
        // 기존 선택된값 지운 데이터
        const removeReplys = memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no)
                
        // 이미선택한값을 다시 클릭햇는지 판단
        const isSelected = memoryReply.rows.find(c => c.survey_question_no === survey_question_no && c.survey_module_no === survey_module_no)

        let newReplys = null

        if (!isSelected) {
            const verifyIsJust = new VerifyIsJust({ mode, method, just })
            const isJust = verifyIsJust._14(survey_module_no, is_answer, is_comment)

            if (debounce) return

            onChangDebounce(isJust)

            const reply = format._14({ survey_question_no, survey_module_no, isJust })
            newReplys = [ ...removeReplys, reply ]
        } else {
            newReplys= [ ...removeReplys ]
        }

        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [ method, mode, just, is_answer, is_comment, survey_question_no, format, debounce, onChangeReplys, onChangDebounce ])


    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <>
        <OverflowComponent
            survey_question_no={survey_question_no} 
            mode={mode}
            lists={lists}
            is_answer={is_answer}
            icon_type={icon_type}
            reply={newReply}
            onChange={handleChange}
        />
        <CommentComponent 
            survey_question_no={survey_question_no} 
            is_comment={is_comment}
            is_comment_placeholder={is_comment_placeholder}
            comment={replyComment}
            disabled={newReply.length > 0 ? false : true}
            marginBottom="5px"
        />
       </>
    )
}