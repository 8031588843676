import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { Levels } from 'ts-policy'
import { SHOW } from 'reducer/confirm'
import { GET_SURVEY_WORKSPACES, SurveyWorkspaceProps } from 'gql/survey_workspace'
import useTokenInfo from 'hooks/useTokenInfo'

const limit = 99999999999999
function useCheckCreateSurvey(fetchPolicy:WatchQueryFetchPolicy) {
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const history = useHistory()

    const token:any = useTokenInfo()

    const { data } = useQuery<{surveyWorkspaces: SurveyWorkspaceProps[]}>(GET_SURVEY_WORKSPACES, {
        fetchPolicy
    })

    const handleOpen = useCallback(() => {
        
        if (!data) return false

        const mboxCount = data.surveyWorkspaces.reduce((acc, c) => {
            return acc + c.mbox_count
        }, 0)

        if (token.levels === Levels.Level0 && mboxCount >= limit) {
            dispatch({
                type: SHOW,
                detectiveFullScreen: 'xs',
                title: f({id: 'hooks.useCheckCreateSurvey.title'}),
                content: f({id: 'hooks.useCheckCreateSurvey.content'}, {limit}),
                onActions: [{
                    on: () => history.push('/payment/choice'),
                    name: f({id: 'hooks.useCheckCreateSurvey.button'}),
                    hide: true
                }]
            })

            return false
        }

        return true
        

    }, [token, data, dispatch, history, f])

    return [handleOpen]
}

export default useCheckCreateSurvey