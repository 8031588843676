/**
 * 스크롤이 있는경우, 스크롤이 맨끝으로 갔는지에 따라 화면 연출이 다르다 스크롤이 있구 맨끝이아니라면 우측에 blur표현해준다.
 */

import { MutableRefObject } from 'react'

// return [스크롤있는지 여부, 스크롤 있으면서 위치가 맨 우측이 아니라면 true]
function useScrollScreen(div: MutableRefObject<HTMLDivElement>, scrollLeftPos: number): [boolean, boolean] {
    if (!div) return [false, false]
    if (!div.current) return [false, false]

    const { clientWidth, scrollWidth } = div.current

    // 스크롤이 없다면 blur효과 필요 없음...
    if (clientWidth >= scrollWidth) return [false, false]

    // 스크롤이 있는데, 맨 마지막으로 움직인거라면 blur 효과 필요 없음
    if ((clientWidth + Math.ceil(scrollLeftPos)) >= scrollWidth) {
        return [true, false]
    }

    return [true, true]
}

export default useScrollScreen