import gql from 'graphql-tag'

export const SUBSCRIBE_TOKEN = gql`
    subscription GetToken($token: ID!) {
        getToken(token: $token) {
            token
        }
    }
`

export const CREATE_LOGIN = gql`
    mutation Login($encrypt: String!) {
        login(encrypt: $encrypt) {
            token
        }
    }
`
export const UPDATE_TOKEN = gql`
    mutation UpdateToken {
        updateToken
    }
`