import { useIntl } from 'react-intl'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import Answer from 'styled/Custom/Answer'

interface Props {
    count: number
}

const MyBox = withStyles({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        paddingRight: 5
    }
})(Box)

function CheckCountComponent(props: Props) {
    const { count } = props

    const { formatMessage: f } = useIntl()
    
    if (!count) return (
        <MyBox>
            <Answer style={{fontSize: 12}}>
                <span style={{color: 'transparent'}}>
                    {f({id: 'component.Survey.Modules.02.CheckCount.message'}, {count: 0})}
                </span>
            </Answer>
        </MyBox>
    )
    
    return (
        <MyBox>
            <Answer style={{fontSize: 12}}>{f({id: 'component.Survey.Modules.02.CheckCount.message'}, {count})}</Answer>
        </MyBox>
    )
}

export default CheckCountComponent