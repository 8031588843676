// 에디트 편집부분에서도 체크값이 표기되야해서 hoc로 
import { useState, useRef, useCallback, ComponentType } from 'react'

interface FormatCommon {
    survey_question_no: number;
    module_type: string;
}

export interface Format00 extends FormatCommon {

}

export interface Format01 extends FormatCommon {
    survey_module_no: number;
    comment: string;
    isJust?: boolean;
}

type Format02 = Format01

export interface Format03 extends FormatCommon {
    survey_module_no: number;
    survey_module_answer_no: number;
}

export type Format04 = Format03

export interface Format05 extends FormatCommon {
    survey_module_no: number;
    isJust?: boolean;
}

export type Format06 = Format03

type Format07 = Format05

type Format08 = Format03

export interface Format09 extends FormatCommon {
    answer: string | null;
    national_flag: string | null | undefined;
}

interface Format10 extends FormatCommon {
    answer: string | null;
}

export type Format11 = Format03

type Format12 = Format01

type Format13 = Format01

export type Format14 = Format05

export type Format15 = Format03

export type Format16 = Format05

interface Format17 extends FormatCommon {
    survey_module_no: number;
    survey_module_answer_no: number;
    comment: string;
}

type Format18 = Format17

export interface Format19 extends FormatCommon {
    src: File | string;
    filename: string;
    filetype: string;
    filesize: number;
}

interface Format20 extends FormatCommon {
    zipcode: number | null;
    address1: string | null;
    address2: string | null;
}

export interface Format21 extends FormatCommon {
    survey_module_no: number;
    isJust?: boolean;
}


export type Format22 = Format03

export type FormatAll = Format01 | Format02 | Format03 | Format04 | Format05 | Format06 | Format07 | Format08 | Format09 | Format10 | Format11 | 
                        Format12 | Format13 | Format14 | Format15 | Format16 | Format17 | Format18 | Format19 | Format21 | Format22

// 저장 포멧
const format = {
    _00: ({ survey_question_no }: Format00) => {
        return {
            survey_question_no,
            module_type: '_00'
        }
    },
    _01: ({ survey_question_no, survey_module_no, comment, isJust }: Format01) => {
        return {
            survey_question_no,
            survey_module_no,
            comment,
            isJust,
            module_type: '_01'
        }
    },
    _02: ({ survey_question_no, survey_module_no, comment }: Format02) => {
        return {
            survey_question_no,
            survey_module_no,
            comment,
            module_type: '_02'
        }
    },
    _03: ({ survey_question_no, survey_module_no, survey_module_answer_no }: Format03) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            module_type: '_03'
        }
    },
    _04: ({ survey_question_no, survey_module_no, survey_module_answer_no }: Format04) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            module_type: '_04'
        }
    },
    _05: ({ survey_question_no, survey_module_no, isJust }: Format05) => {
        return {
            survey_question_no,
            survey_module_no,
            isJust,
            module_type: '_05'
        }
    },
    _06: ({ survey_question_no, survey_module_no, survey_module_answer_no }: Format06) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            module_type: '_06'
        }
    },
    _07: ({ survey_question_no, survey_module_no, isJust }: Format07) => {
        return {
            survey_question_no,
            survey_module_no,
            isJust,
            module_type: '_07'
        }
    },
    _08: ({ survey_question_no, survey_module_no, survey_module_answer_no }: Format08) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            module_type: '_08'
        }
    },
    _09: ({ survey_question_no, answer, national_flag }: Format09) => {
      
        return {
            survey_question_no,
            answer,
            national_flag,
            module_type: '_09'
        }
    },
    _10: ({ survey_question_no, answer }: Format10) => {
      
        return {
            survey_question_no,
            answer,
            module_type: '_10'
        }
    },
    _11: ({ survey_question_no, survey_module_no, survey_module_answer_no }: Format11) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            module_type: '_11'
        }
    },
    _12: ({ survey_question_no, survey_module_no, comment, isJust }: Format12) => {
        return {
            survey_question_no,
            survey_module_no,
            comment,
            isJust,
            module_type: '_12'
        }
    },
    _13: ({ survey_question_no, survey_module_no, comment }: Format13) => {
        return {
            survey_question_no,
            survey_module_no,
            comment,
            module_type: '_13'
        }
    },
    _14: ({ survey_question_no, survey_module_no, isJust }: Format14) => {
        return {
            survey_question_no,
            survey_module_no,
            isJust,
            module_type: '_14'
        }
    },
    _15: ({ survey_question_no, survey_module_no, survey_module_answer_no }: Format15) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            module_type: '_15'
        }
    },
    _16: ({ survey_question_no, survey_module_no, isJust }: Format16) => {
        return {
            survey_question_no,
            survey_module_no,
            isJust,
            module_type: '_16'
        }
    },
    _17: ({ survey_question_no, survey_module_no, survey_module_answer_no, comment }: Format17) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            comment,
            module_type: '_17'
        }
    },
    _18: ({ survey_question_no, survey_module_no, survey_module_answer_no, comment }: Format18) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            comment,
            module_type: '_18'
        }
    },
    _19: ({ survey_question_no, src, filename, filetype, filesize }: Format19) => {
      
        return {
            survey_question_no,
            src,
            filename,
            filetype,
            filesize,
            module_type: '_19'
        }
    },
    _20: ({ survey_question_no, zipcode, address1, address2 }: Format20) => {
      
        return {
            survey_question_no,
            zipcode,
            address1,
            address2,
            module_type: '_20'
        }
    },
    _21: ({ survey_question_no, survey_module_no, isJust }: Format21) => {
        return {
            survey_question_no,
            survey_module_no,
            isJust,
            module_type: '_21'
        }
    },
    _22: ({ survey_question_no, survey_module_no, survey_module_answer_no }: Format22) => {
        return {
            survey_question_no,
            survey_module_no,
            survey_module_answer_no,
            module_type: '_22'
        }
    },
}


export default (WrappedComponent: ComponentType) => (props:any) => {
    const { mbox } = props
    const { method } = mbox

    // 답변값
    const [ replys, setReplys ] = useState([])

    const refComments01 = useRef([])
    const refComments02 = useRef([])
    const refComments09 = useRef([])
    const refComments10 = useRef([])
    const refComments12 = useRef([])
    const refComments13 = useRef([])
    const refComments17 = useRef([])
    const refComments18 = useRef([])
    const refComments20 = useRef([])
    const refCommentsWith = useRef([])

    const handleChangeReplys = useCallback((data) => {
        const time = method === 'one' ? 0 : 100

        setTimeout(() => {
            setReplys(data)
        }, time)
    }, [method])
    
    
    
    const propsSetReplys = {
        replys,
        setReplys,
        handleChangeReplys,
        refComments01,
        refComments02,
        refComments09,
        refComments10,
        refComments12,
        refComments13,
        refComments17,
        refComments18,
        refComments20,
        refCommentsWith,
        format
    }

    return <WrappedComponent propsSetReplys={propsSetReplys}  {...props}/>
}