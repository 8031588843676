import { createContext } from 'react'
import { ViewMode, Print } from 'gql/survey_analysis_summary'
import { SurveyMboxProps } from 'gql/survey_mbox'
import { SurveyMboxDesignProps } from 'gql/survey_mbox_design'

interface SummaryContextProps {
    mbox: {
        wdate: SurveyMboxProps['wdate'];
        summary_ending_open: SurveyMboxProps['summary_ending_open'];
    };
    design: SurveyMboxDesignProps;
    viewMode: ViewMode;
    print: Print;

}

const SummaryContext = createContext<SummaryContextProps>({
    mbox: {
        wdate: '',
        summary_ending_open: false
    }, 
    design: {
        question: '',
        answer: '',
        answer_button: '',
        answer_sbutton: '',
        background_color: '',
        line_color: '',
        background_image: '',
        background_size: '',
        background_opacity: '',
        font_family: '',
    }, 
    viewMode: ViewMode.View, 
    print: false
})

export default SummaryContext