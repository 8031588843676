import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_TOKEN } from 'gql/token'
import { LOGIN } from 'reducer/auth'
import { postMessage } from 'utils'

function useUpdateToken() {
    const dispatch = useDispatch()

    const [ updateToken ] = useMutation(UPDATE_TOKEN, {
        onCompleted: (data) => {

            localStorage.setItem('authorization', data.updateToken)
            dispatch({
                type: LOGIN,
                token: data.updateToken
            })

            postMessage({ 
                dispatch: {
                    type: LOGIN,
                    token: data.updateToken
                }
            })
        }
    })

    const handleUpdateToken = useCallback(() => {
        updateToken()
    }, [updateToken])

    return handleUpdateToken
}

export default useUpdateToken