import { handleDisabledRightClick } from 'ts-utils'
import styled from 'styled-components'
import { withSurveyPopoverImage } from 'hoc'

// 이미지 영상 구분해서 뿌리자
const ImgMulti = styled.img`
    max-width: 100%;
    border-radius: 3px;
    display: block;
    cursor: pointer;
`

function ImgMultiComponent(props) {
    const { src, popoverImageProps } = props
    const { on } = popoverImageProps

    const handleOpen = () => {
        on.open(src)
    }

    return <ImgMulti alt="" src={src} onClick={handleOpen} onContextMenu={handleDisabledRightClick}/>
}

export default withSurveyPopoverImage(ImgMultiComponent)