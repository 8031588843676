export const SHOW = 'errorPage/SHOW'
export const HIDE = 'errorPage/HIDE'

const initState = {
    show: false,
    f: '',
    logoHref: '',
    buttonHref: ''
}


export default (state = initState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
                f: action.f,
                logoHref: action.logoHref,
                buttonHref: action.buttonHref
            }
        case HIDE:
            return initState
        default:
            return state
    }
}