import { useReducer, useCallback, useRef, useEffect, createContext } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { 
    UPDATE_SURVEY_QUESTION_00,
    UPDATE_SURVEY_QUESTION_01,
    UPDATE_SURVEY_QUESTION_02,
    UPDATE_SURVEY_QUESTION_03,
    UPDATE_SURVEY_QUESTION_04,
    UPDATE_SURVEY_QUESTION_05,
    UPDATE_SURVEY_QUESTION_06,
    UPDATE_SURVEY_QUESTION_07,
    UPDATE_SURVEY_QUESTION_08,
    UPDATE_SURVEY_QUESTION_09,
    UPDATE_SURVEY_QUESTION_10,
    UPDATE_SURVEY_QUESTION_11,
    UPDATE_SURVEY_QUESTION_12,
    UPDATE_SURVEY_QUESTION_13,
    UPDATE_SURVEY_QUESTION_14,
    UPDATE_SURVEY_QUESTION_15,
    UPDATE_SURVEY_QUESTION_16,
    UPDATE_SURVEY_QUESTION_17,
    UPDATE_SURVEY_QUESTION_18,
    UPDATE_SURVEY_QUESTION_19,
    UPDATE_SURVEY_QUESTION_20,
    UPDATE_SURVEY_QUESTION_21,
    UPDATE_SURVEY_QUESTION_22
} from 'gql/survey_question_modules'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { useIntl } from 'react-intl'
import { scroller} from 'react-scroll'
import { v4 as uuidv4 } from 'uuid'
import { getMaxlength } from 'policy'
import { numberFormat, replaceNotNumber, getCountrys, parseGraphqlError } from 'utils'
import { getImgPosition } from 'utils/survey'
import { withSurveyFetchQuestion, withSurveyEdit, withPrevState } from 'hoc'
import PropsContext from 'context/props'
import { Typography } from '@material-ui/core'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'
import countryList from 'country-list'
import { isEqual } from 'lodash-es'
import { Scale as ScaleClass, getScalePlusRows, getScaleMinusRows, getMatchOriginalScaleRows } from './lib/scale'
import { SurveyEditComponent, SurveyEditTopComponent } from 'component'
import { SurveyEditImageAttachContainer } from 'container'
import SurveyEdit00Container from './00/Container'
import SurveyEdit01Container from './01/Container'
import SurveyEdit02Container from './02/Container'
import SurveyEdit03Container from './03/Container'
import SurveyEdit04Container from './04/Container'
import SurveyEdit05Container from './05/Container'
import SurveyEdit06Container from './06/Container'
import SurveyEdit07Container from './07/Container'
import SurveyEdit08Container from './08/Container'
import SurveyEdit09Container from './09/Container'
import SurveyEdit10Container from './10/Container'
import SurveyEdit11Container from './11/Container'
import SurveyEdit12Container from './12/Container'
import SurveyEdit13Container from './13/Container'
import SurveyEdit14Container from './14/Container'
import SurveyEdit15Container from './15/Container'
import SurveyEdit16Container from './16/Container'
import SurveyEdit17Container from './17/Container'
import SurveyEdit18Container from './18/Container'
import SurveyEdit19Container from './19/Container'
import SurveyEdit20Container from './20/Container'
import SurveyEdit21Container from './21/Container'
import SurveyEdit22Container from './22/Container'
import { classModulesState } from '../Create/ModuleRoot'
import { getError as getDropdownChoiceError } from 'component/Survey/Edit/21/Items'

// ChangeModuleType context
export const ChangeModuleTypeContext = createContext({
    onChangeModuleType: (aaa) => {},
    changeType: false
})

// scrollTop contex
export const ScrollContext = createContext({
    id: ''
})

const initialState = {
    openIsComment: false, // 01, 02 모듈에서 코멘트 설정여부
    subTabIndex: 0, // 행 열  있을씨 탭 (매트릭스 같은거)
    typePhoneAllChecked: { // 09모듈 전화번호 체크
        left: false,
        right: false
    },
    showImageUpload: '', // 이미지형 보기부분 이미지 업로드 창 View 여부
    nationalLists: getCountrys().map(c => ({...c, visible: true, checked: false})), // 전화번호 국가리스트
    row: {
        _question: {
            question: '',
            number: '',
            img_src: '',
            img_poster: '',
            img_name: ''
        },
        _modules: [],
        _answers: [],
        _option: {
            required: false
        }
    },
    tabIndex: 0,
    focus: {
        type: '', // examples, answers, columns
        indexs: ''
    },
    show: 'contents', // contents, image 
    init: false // 초기화시 컴퍼넌트 언마운트를 위하여...
}

// scale index 저장시, 인덱스가 제대로 저장이 안되는 버그로 인해 저장시 다시 인덱스 나열을 한다
function getScaleIndex(len, index, zero_number, reverse) {

    if (zero_number) return reverse ? (len - index - 1) : index
    else return reverse ? (len - index) : index + 1
}

// DnD 정렬 함수
const handleDnD = (result, rows) => {

    // survey_question 복사
    const copy = rows.map(c => c)

    const sourceIndex = result.source.index //원래 인덱스
    const destinationIndex = result.destination.index

    // 옮겨져야할 값 을 배열에서 삭제하고 value값은 저장
    const [removedData] = copy.splice(sourceIndex, 1)

    // 옮겨져야할 값 추가
    copy.splice(destinationIndex, 0, removedData)

    return copy
}

// 문항 유형 변경이 생기면서, 데이터 가져오는 분기가 생겻다

const getDefaultData = (props) => {
    const { change_type, module_type, data } = props

    if (!change_type) return data
    else {
        const modulesStateValue = classModulesState.get()
        const row = modulesStateValue[module_type]
        row['_question'] = modulesStateValue['_question']

        return row
    }
}


const Component = (props) => {
    const { edit, survey_no, method, survey_question_no, propsEdit, propsFetchQuestion, usePrevState, scrolls, on } = props

    const dispatch = useDispatch()

    const refQuestion = useRef()
    const refExamples = useRef([])
    const refColumns = useRef([])
    const refAnswers = useRef([])
    const refLabels = useRef([])
    const refScales = useRef([])
    const refBases = useRef([])
    const refNumbers = useRef([])
    const refComment = useRef()
    const refAgreeRequiredMessage = useRef()
    const refConfirm = useRef()
    
    const handleReducer = (state, action) => {
        switch (action.type) {
            case 'row': { // 문항편집
                return { ...state, row: action.row }
            }
            case 'tabIndex': { // 문항편집
                return { ...state, tabIndex: action.tabIndex }
            }
            case 'subTabIndex': { // 행, 열 탭
                return { ...state, subTabIndex: action.subTabIndex }
            }
            case 'openIsComment': { // is_comment check (is_comment_require 나 is_comment_placeholder 값이 잇으면 기본적으로 true)
                return { ...state, openIsComment: action.openIsComment }
            }
            case 'typePhoneAllChecked': { // 09 모바일형식 전체 체크 상태
                return { ...state, typePhoneAllChecked: action.typePhoneAllChecked }
            }
            case 'nationalLists': { // 09 국가리스트
                return { ...state, nationalLists: action.nationalLists }
            }
            case 'showImageUpload': { // 이미지형 보기의 이미지 업로드 창 보이기 여부
                return { ...state, showImageUpload: action.showImageUpload }
            }
            case 'show': {
                return { ...state, show: action.show }
            }
            case 'focus': {
                return { ...state, focus: action.focus }
            }
            case 'init': {
                return { ...state, init: action.init }
            }
            default: {
                throw new Error(`unexpected action.type: ${action.type}`)
            }
        }
    }

    const [ states, dispatchStates ] = useReducer(handleReducer, initialState)

    const prevRow = usePrevState(states.row)

    const { formatMessage: f } = useIntl()

    const errorChangeModuleType = (e) => {
        if (parseGraphqlError(e) === 'Bad ChangeModuleType') {
            window.alert(f({id: 'container.Survey.Edit.Container.errorChangeModuleType'}))
            on.close('close')
            return true
        }
    
        return false
    }

    const [ updateSurveyQuestion00 ] = useMutation(UPDATE_SURVEY_QUESTION_00, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion01 ] = useMutation(UPDATE_SURVEY_QUESTION_01, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion02 ] = useMutation(UPDATE_SURVEY_QUESTION_02, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion03] = useMutation(UPDATE_SURVEY_QUESTION_03, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion04] = useMutation(UPDATE_SURVEY_QUESTION_04, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion05] = useMutation(UPDATE_SURVEY_QUESTION_05, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion06] = useMutation(UPDATE_SURVEY_QUESTION_06, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion07] = useMutation(UPDATE_SURVEY_QUESTION_07, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion08] = useMutation(UPDATE_SURVEY_QUESTION_08, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })


    const [ updateSurveyQuestion09] = useMutation(UPDATE_SURVEY_QUESTION_09, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })


    const [ updateSurveyQuestion10] = useMutation(UPDATE_SURVEY_QUESTION_10, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion11] = useMutation(UPDATE_SURVEY_QUESTION_11, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion12] = useMutation(UPDATE_SURVEY_QUESTION_12, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion13] = useMutation(UPDATE_SURVEY_QUESTION_13, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion14] = useMutation(UPDATE_SURVEY_QUESTION_14, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion15] = useMutation(UPDATE_SURVEY_QUESTION_15, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion16] = useMutation(UPDATE_SURVEY_QUESTION_16, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion17] = useMutation(UPDATE_SURVEY_QUESTION_17, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion18] = useMutation(UPDATE_SURVEY_QUESTION_18, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion19] = useMutation(UPDATE_SURVEY_QUESTION_19, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion20] = useMutation(UPDATE_SURVEY_QUESTION_20, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion21] = useMutation(UPDATE_SURVEY_QUESTION_21, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const [ updateSurveyQuestion22] = useMutation(UPDATE_SURVEY_QUESTION_22, {
        onCompleted: async() => {
            propsEdit.changeStatus(true)
        },
        onError: (e) => {
            if (!errorChangeModuleType(e)) propsEdit.changeStatus(false)
        }
    })

    const handleQuestion = {
        openImageAttach: (e) => {
            dispatchStates({type: 'show', show: 'image'})
        },
        removeImageAttach: () => {
            dispatchStates({type: 'row', row: {
                ...states.row,
                _question: {
                    ...states.row._question,
                    img_src: '',
                    img_position: 'stack',
                    img_name: ''
                }
            }})
        }
    }

    const handleImageAttach = {
        append: (props) => {
            const { img_src, img_poster, img_name } = props

            dispatchStates({type: 'row', row: {
                ...states.row,
                _question: {
                    ...states.row._question,
                    img_src,
                    img_poster,
                    img_position: getImgPosition({ img_position: states.row._question.img_position, img_src }),
                    img_name
                }
            }})
        },
        changePosition: (e) => {
            const img_position = e.currentTarget.value
            dispatchStates({type: 'row', row: {
                ...states.row,
                _question: {
                    ...states.row._question,
                    img_position
                }
            }})
        },
        close: () => {
            dispatchStates({type: 'show', show: initialState.show})
        }
    }

    const handleTabs = {
        change: (index) => {
            dispatchStates({type: 'tabIndex', tabIndex: index})
        },
        close: async () => {
            propsEdit.onClose()
        },
        update: {
            question: () => {
                const { _question } = states.row

                const { survey_question_no } = _question
                const question = refQuestion.current.value
                const confirm = refConfirm.current.value
                
                const inputQuestion = {
                    survey_question_no, 
                    question,
                    confirm,
                    img_src: _question.img_src,
                    img_poster: _question.img_poster,
                    img_position: _question.img_position,
                    img_name: _question.img_name,
                    is_change_module_type: Boolean(edit.change_type)
                }

                return inputQuestion
            },
            _00: async() => {
                const inputQuestion = handleTabs.update.question()
    
                const variables = {
                    survey_no,
                    inputQuestion
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion00({ variables })
            },
            _01: async() => {
                const { _question, _modules, _option } = states.row

                const { survey_question_no } = _question

                if (_modules.length === 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.21.error.zeroChoice'})
                    })
    
                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }
    
                // refExamples 보기중 빈값이 있는지 체크하자
                if (Array.from(refExamples.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.01.example'})
                    })
    
                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }
    
                const inputQuestion = handleTabs.update.question()
    
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        answer: refExamples.current[i].value,
                        is_comment: c.is_comment
                    })
                })
    
                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    random: _option.random,
                    except_last_random: _option.except_last_random,
                    layout: Number(_option.layout),
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion01({ variables })
            },
            _02: async() => {
                const { _question, _modules, _option } = states.row

                const { survey_question_no } = _question

                if (_modules.length === 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.21.error.zeroChoice'})
                    })
    
                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })
                    
                    return
                }

                // refExamples 보기중 빈값이 있는지 체크하자
                if (Array.from(refExamples.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.02.example'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }

                if (_option.required && (_option.required_start === 0 ||  _option.required_end === 0)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.02.required'})
                    })

                    handleTabs.change(1)
                    
                    return
                }


                const inputQuestion = handleTabs.update.question()


                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        answer: refExamples.current[i].value,
                        is_comment: c.is_comment
                    })
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    required_start: _option.required_start,
                    required_end: _option.required_end,
                    badge: _option.badge,
                    random: _option.random,
                    except_last_random: _option.except_last_random,
                    layout: Number(_option.layout),
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion02({ variables })
            },
            _03: async() => {
                const { _question, _modules, _answers, _option } = states.row

                const { survey_question_no } = _question
         

                // refColumns 행중 빈값이 있는지 체크하자
                if (Array.from(refColumns.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.03.column'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }


                // refAnswers 행중 빈값이 있는지 체크하자
                if (Array.from(refAnswers.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.03.answer'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 1
                    })

                    return
                }


                const inputQuestion = handleTabs.update.question()

                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        column: refColumns.current[i].value
                    })
                })

                const inputModuleAnswers = _answers.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_answer_no: typeof c.survey_module_answer_no === 'string' ? 0 : c.survey_module_answer_no,
                        answer: refAnswers.current[i].value
                    })
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    random_column: _option.random_column,
                    except_last_random_column: _option.except_last_random_column,
                    random_answer: _option.random_answer,
                    except_last_random_answer: _option.except_last_random_answer,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value,
                    mobile_matrix: _option.mobile_matrix
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputModuleAnswers,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion03({ variables })
            },
            _04: async() => {
                const { _question, _modules, _answers, _option } = states.row
    
                const { survey_question_no } = _question
           

                // refColumns 행중 빈값이 있는지 체크하자
                if (Array.from(refColumns.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.04.column'})
                    })
    
                    handleTabs.change(0)
    
                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })
    
                    return
                }
    
                // refAnswers 행중 빈값이 있는지 체크하자
                if (Array.from(refAnswers.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.04.answer'})
                    })
    
                    handleTabs.change(0)
    
                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 1
                    })
    
                    return
                }
                
                if (_option.required && (_option.required_start === 0 ||  _option.required_end === 0)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.04.required'})
                    })
    
                    handleTabs.change(1)
                    return
                }
    
                const inputQuestion = handleTabs.update.question()
    
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        column: refColumns.current[i].value
                    })
                })
    
                const inputModuleAnswers = _answers.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_answer_no: typeof c.survey_module_answer_no === 'string' ? 0 : c.survey_module_answer_no,
                        answer: refAnswers.current[i].value
                    })
                })
    
                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    required_start: _option.required_start,
                    required_end: _option.required_end,
                    badge: _option.badge,
                    random_column: _option.random_column,
                    except_last_random_column: _option.except_last_random_column,
                    random_answer: _option.random_answer,
                    except_last_random_answer: _option.except_last_random_answer,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value,
                    mobile_matrix: _option.mobile_matrix
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputModuleAnswers,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion04({ variables })
            },
            _05: async() => {
                const { _question, _modules, _option } = states.row

                const { survey_question_no } = _question

                if (!_option.nps) {
                    if (!refLabels.current[0].value) {
                        dispatch({ 
                            type: ALERTSNACKBARSHOW, 
                            variant: 'error', 
                            message: f({id: 'container.Survey.Edit.05.label.0'})
                        })
    
                        handleTabs.change(0)
    
                        dispatchStates({ 
                            type: 'subTabIndex', 
                            subTabIndex: 0
                        })
    
                        return
                    }
    
                    if (!refLabels.current[1].value) {
                        dispatch({ 
                            type: ALERTSNACKBARSHOW, 
                            variant: 'error', 
                            message: f({id: 'container.Survey.Edit.05.label.1'})
                        })
    
                        handleTabs.change(0)
    
                        dispatchStates({ 
                            type: 'subTabIndex', 
                            subTabIndex: 0
                        })
    
                        return
                    }
                }
                
                const inputQuestion = handleTabs.update.question()

                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다

                const moduleLen = _modules.length

                let inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        indexs: getScaleIndex(moduleLen, i, _option.zero_number, _option.reverse),
                        point: Number(replaceNotNumber(refExamples.current[i].innerText))
                    })
                })

                // 범위를 풀다운르로 선택하다보니, 응답값이 있는상태에서 사용자가 범위를 줄엿다가 다시 원복해도 기존 키값이 날라가는 문제가있다
                // 최종적으로 기존키값에다가 매칭시켜준다
                inputModules = getMatchOriginalScaleRows({ 
                    newRows: inputModules, 
                    newReverse: _option.reverse,
                    oringinRows: propsFetchQuestion.data.surveyQuestionModule._modules,
                    originReverse: propsFetchQuestion.data.surveyQuestionModule._option.reverse,
                    type: 'one'
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    left_label: refLabels.current[0].value || '',
                    right_label: refLabels.current[1].value || '',
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value,
                    reverse: _option.reverse,
                    zero_number: _option.zero_number,
                    nps: _option.nps
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion05({ variables })
            },
            _06: async() => {
                const { _question, _modules, _answers, _option } = states.row

                const { survey_question_no } = _question

                if (!_option.nps) {
                    if (!refLabels.current[0].value) {
                        dispatch({ 
                            type: ALERTSNACKBARSHOW, 
                            variant: 'error', 
                            message: f({id: 'container.Survey.Edit.06.label.0'})
                        })
    
                        handleTabs.change(0)
    
                        dispatchStates({ 
                            type: 'subTabIndex', 
                            subTabIndex: 0
                        })
    
                        return
                    }
    
                    if (!refLabels.current[1].value) {
                        dispatch({ 
                            type: ALERTSNACKBARSHOW, 
                            variant: 'error', 
                            message: f({id: 'container.Survey.Edit.06.label.1'})
                        })
    
                        handleTabs.change(0)
    
                        dispatchStates({ 
                            type: 'subTabIndex', 
                            subTabIndex: 0
                        })
    
                        return
                    }
                }
               

                // refColumns 행중 빈값이 있는지 체크하자
                if (Array.from(refColumns.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.06.column'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 1
                    })

                    return
                }

                const inputQuestion = handleTabs.update.question()
                
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        column: refColumns.current[i].value
                    })
                })

                const answerLen = _answers.length
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                let inputModuleAnswers = _answers.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_answer_no: typeof c.survey_module_answer_no === 'string' ? 0 : c.survey_module_answer_no,
                        indexs: getScaleIndex(answerLen, i, _option.zero_number, _option.reverse),
                        point: Number(replaceNotNumber(refAnswers.current[i].innerText))
                    })
                })

                 // 범위를 풀다운르로 선택하다보니, 응답값이 있는상태에서 사용자가 범위를 줄엿다가 다시 원복해도 기존 키값이 날라가는 문제가있다
                // 최종적으로 기존키값에다가 매칭시켜준다

                if (!Boolean(edit.change_type)) {
                    inputModuleAnswers = getMatchOriginalScaleRows({ 
                        newRows: inputModuleAnswers, 
                        newReverse: _option.reverse,
                        oringinRows: propsFetchQuestion.data.surveyQuestionModule._answers,
                        originReverse: propsFetchQuestion.data.surveyQuestionModule._option.reverse,
                        type: 'matrix'
                    })
                }

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    left_label: refLabels.current[0].value || '',
                    right_label: refLabels.current[1].value || '',
                    random_column: _option.random_column,
                    except_last_random_column: _option.except_last_random_column,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value,
                    reverse: _option.reverse,
                    zero_number: _option.zero_number,
                    nps: _option.nps
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputModuleAnswers,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion06({ variables })
            },
            _07: async() => {
                const { _question, _modules, _option } = states.row

                const { survey_question_no } = _question
    
    
                // refExamples 보기중 빈값이 있는지 체크하자
                if (Array.from(refExamples.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.07.example'})
                    })
    
                    handleTabs.change(0)
                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })
                    return
                }
    
                const inputQuestion = handleTabs.update.question()
    
                const moduleLen = _modules.length
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        answer: refExamples.current[i].value,
                        indexs: getScaleIndex(moduleLen, i, _option.zero_number, _option.reverse),
                        point: Number(replaceNotNumber(refScales.current[i].innerText))
                    })
                })
    
                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value,
                    reverse: _option.reverse,
                    zero_number: _option.zero_number
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion07({ variables })
            },
            _08: async() => {
                const { _question, _modules, _answers, _option } = states.row

                const { survey_question_no } = _question

                // refExamples 척도중 빈값이 있는지 체크하자
                if (Array.from(refAnswers.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.08.answer'})
                    })
    
                    handleTabs.change(0)
    
                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })
                    return
                }
    
                // refColumns 행중 빈값이 있는지 체크하자
                if (Array.from(refColumns.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.08.column'})
                    })
    
                    handleTabs.change(0)
    
                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 1
                    })
    
                    return
                }
    
                const inputQuestion = handleTabs.update.question()
    
                 // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        column: refColumns.current[i].value
                    })
                })

                const answerLen = _answers.length
    
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModuleAnswers = _answers.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_answer_no: typeof c.survey_module_answer_no === 'string' ? 0 : c.survey_module_answer_no,
                        answer: refAnswers.current[i].value,
                        indexs: getScaleIndex(answerLen, i, _option.zero_number, _option.reverse),
                        point: Number(replaceNotNumber(refScales.current[i].innerText))
                    })
                })
    
                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    random_column: _option.random_column,
                    except_last_random_column: _option.except_last_random_column,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value,
                    reverse: _option.reverse,
                    zero_number: _option.zero_number
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputModuleAnswers,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion08({ variables })
            },
            _09: async() => {
                const { _option } = states.row
    
                // 선택된값만 변경해줄거기 때문에 data에서 받아온 초기값을 저장한다
                let inputOption = {}
                switch(states.row._option.type) {
                    case 'base':
                        const baseMaxLength = getMaxlength('component.Survey.Edit.09.TypeBase.max')
                        // 최소길이 또는 최대길이 값이 비었을때
                        if (refBases.current[0].value ==='' || refBases.current[1].value === '') {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.base.0'})
                            })
    
                            await dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })

                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })
    
                            return
                        }
    
                        // 최대길이 255 초과로 입력시
                        if (Number(refBases.current[0].value) > baseMaxLength || Number(refBases.current[1].value) > baseMaxLength) {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.base.1'}, {max: baseMaxLength})
                            })
    
                            await dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })

                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })
    
                            return
                        }
    
                        // max 길이가 0일때
                        if (Number(refBases.current[1].value) === 0) {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.base.2'})
                            })
    
                            dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })

                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })

                            return
                        }
    
                        // min 값이 max보다 클때
                        if (Number(refBases.current[0].value) > Number(refBases.current[1].value)) {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.base.3'})
                            })
    
                            dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })
                            
                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })

                            return
                        }
    
                        inputOption = {
                            min_length: Number(refBases.current[0].value),
                            max_length: Number(refBases.current[1].value)
                        }
                        break
                    case 'phone':
                        if (_option.national_flag.length === 0) {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.phone'})
                            })
    
                            dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })

                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })
    
                            return
                        }
    
                        inputOption = {
                            national_flag: _option.national_flag.map(c => c.code),
                            only_mobile: _option.only_mobile
                        }
                        break
                    case 'number':
                        const numberMax = getMaxlength('component.Survey.Edit.09.TypeNumber.max')
    
                        // 최소값 또는 최대값이 비었을때
                        if (refNumbers.current[0].value ==='' || refNumbers.current[1].value === '') {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.number.0'})
                            })
    
                            dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })

                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })
    
                            return
                        }
    
                        // 최대값 4억 초과로 입력시
                        if (Number(refNumbers.current[0].value) > numberMax || Number(refNumbers.current[1].value) > numberMax) {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.number.1'}, {max: numberFormat(numberMax)})
                            })
    
                            dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })
                            
                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })

                            return
                        }
    
                        // max 길이가 0일때
                        if (Number(refNumbers.current[1].value) === 0) {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.number.2'})
                            })
    
                            dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })

                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })
    
                            return
                        }
    
                        // min 값이 max보다 클때
                        if (Number(refNumbers.current[0].value) > Number(refNumbers.current[1].value)) {
                            dispatch({ 
                                type: ALERTSNACKBARSHOW, 
                                variant: 'error', 
                                message: f({id: 'container.Survey.Edit.09.number.3'})
                            })
    
                            dispatchStates({ 
                                type: 'tabIndex', 
                                tabIndex: 0
                            })

                            dispatchStates({ 
                                type: 'subTabIndex', 
                                subTabIndex: 0
                            })
    
                            return
                        }
    
                        inputOption = {
                            min_number: Number(refNumbers.current[0].value),
                            max_number: Number(refNumbers.current[1].value),
                            unit: refNumbers.current[2].value,
                            number_format: _option.number_format,
                            unit_position: _option.unit_position,
                            minus: _option.minus,
                            decimald: _option.decimald
                        }
                        break
                    case 'date':
                        inputOption = {
                            date_format_type: _option.date_format_type,
                            date_range: _option.date_range,
                            date_start: _option.date_start
                        }
                        break
                    case 'email':
                        break
                    default:
                        return
                }

                const inputQuestion = handleTabs.update.question()
    
                const inputOptiond = {
                    ..._option,
                    unit_position: inputOption.unit_position || false,
                    decimald: inputOption.decimald || false,
                    minus: inputOption.minus || false,
                    date_format_type: inputOption.date_format_type || '',
                    date_range: inputOption.date_range || '',
                    date_start: inputOption.date_start || '',
                    national_flag: inputOption.national_flag || [], 
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    badge: _option.badge,
                    type: _option.type,
                    ...inputOption
                }

                if (inputOptiond.__typename) delete inputOptiond.__typename
                if (inputOptiond.survey_question_no) delete inputOptiond.survey_question_no
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputOption: inputOptiond
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion09({ variables })
            },
            _10: async() => {
                const { _option } = states.row

                const inputQuestion = handleTabs.update.question()

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion10({ variables })
            },
            _11: async() => {
                const { _question, _answers, _option } = states.row

                const { survey_question_no } = _question
    
                // refAnswers 보기중 빈값이 있는지 체크하자
                if (Array.from(refAnswers.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.11.answer'})
                    })
    
                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })
    
                    return
                }
    
                const inputQuestion = handleTabs.update.question()

                const inputModules = {
                    survey_question_no,
                    len: _answers.length
                }
    
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModuleAnswers = _answers.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_answer_no: typeof c.survey_module_answer_no === 'string' ? 0 : c.survey_module_answer_no,
                        answer: refAnswers.current[i].value
                    })
                })
    
                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    badge: _option.badge,
                    random_answer: _option.random_answer,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder:  refComment.current.value
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputModuleAnswers,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion11({ variables })
            },
            _12: async() => {
                const { _question, _modules, _option } = states.row

                const { survey_question_no } = _question

                if (states.row._modules.find(c => !c.src)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.12.src'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }

                const inputQuestion = handleTabs.update.question()

                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        answer: refExamples.current[i].value,
                        src: c.src,
                        is_comment: c.is_comment
                    })
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    random: _option.random,
                    except_last_random: _option.except_last_random,
                    mobile_one: _option.mobile_one,
                    icon_type: _option.icon_type,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion12({ variables })
            },
            _13: async() => {
                const { _question, _modules, _option } = states.row

                const { survey_question_no } = _question
            
                if (states.row._modules.find(c => !c.src)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.13.src'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }

                if (_option.required && (_option.required_start === 0 ||  _option.required_end === 0)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.13.required'})
                    })

                    handleTabs.change(1)
                    
                    return
                }

                const inputQuestion = handleTabs.update.question()

                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        answer: refExamples.current[i].value,
                        src: c.src,
                        is_comment: c.is_comment
                    })
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    required_start: _option.required_start,
                    required_end: _option.required_end,
                    badge: _option.badge,
                    random: _option.random,
                    except_last_random: _option.except_last_random,
                    mobile_one: _option.mobile_one,
                    icon_type: _option.icon_type,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion13({ variables })
            },
            _14: async() => {
                const { _question, _modules, _option } = states.row

                const { survey_question_no } = _question

                const inputQuestion = handleTabs.update.question()
        
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        answer: refExamples.current[i].value,
                        point: Number(replaceNotNumber(refScales.current[i].innerText))
                    })
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    icon_type: _option.icon_type,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion14({ variables })
            },
            _15: async() => {
                const { _question, _modules, _answers, _option } = states.row

                const { survey_question_no } = _question
              
                // refColumns 행중 빈값이 있는지 체크하자
                if (Array.from(refColumns.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.15.column'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 1
                    })

                    return
                }
                
                const inputQuestion = handleTabs.update.question()

                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        column: refColumns.current[i].value
                    })
                })

                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModuleAnswers = _answers.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_answer_no: typeof c.survey_module_answer_no === 'string' ? 0 : c.survey_module_answer_no,
                        answer: refAnswers.current[i].value,
                        point: Number(replaceNotNumber(refScales.current[i].innerText))
                    })
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    icon_type: _option.icon_type,
                    random_column: _option.random_column,
                    except_last_random_column: _option.except_last_random_column,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputModuleAnswers,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion15({ variables })
            },
            _16: async() => {
                const { _question, _modules, _option } = states.row

                const { survey_question_no } = _question
    
                const inputQuestion = handleTabs.update.question()
    
                // 동의 버튼은 보기가 한개인데... 일단 몰라서 배열로 처리하는거다...
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        answer: refExamples.current[i].value
                    })
                })
    
                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: true,
                    required_message: refAgreeRequiredMessage.current.value,
                    icon_type: _option.icon_type
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion16({ variables })
            },
            _17: async() => {
                const { _question, _answers, _option } = states.row

                const { survey_question_no } = _question
    
                // refAnswers 보기중 빈값이 있는지 체크하자
                if (Array.from(refAnswers.current).filter(c => c).filter(c => !c.value).length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.17.answer'})
                    })
    
                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })
    
                    return
                }

                if (_option.required && (_option.required_start === 0 ||  _option.required_end === 0)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.17.required'})
                    })

                    handleTabs.change(1)
                    
                    return
                }
    
                const inputQuestion = handleTabs.update.question()

                const inputModules = {
                    survey_question_no,
                    len: _answers.length
                }
    
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModuleAnswers = _answers.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_answer_no: typeof c.survey_module_answer_no === 'string' ? 0 : c.survey_module_answer_no,
                        answer: refAnswers.current[i].value,
                        is_comment: c.is_comment
                    })
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    required_start: _option.required_start,
                    required_end: _option.required_end,
                    badge: _option.badge,
                    random_answer: _option.random_answer,
                    except_last_random_answer: _option.except_last_random_answer,
                    icon_type: _option.icon_type,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputModuleAnswers,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion17({ variables })
            },
            _18: async() => {
                const { _question, _answers, _option } = states.row

                const { survey_question_no } = _question
    
                if (states.row._answers.find(c => !c.src)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.13.src'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }

                if (_option.required && (_option.required_start === 0 ||  _option.required_end === 0)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.17.required'})
                    })

                    handleTabs.change(1)
                    
                    return
                }
    
                const inputQuestion = handleTabs.update.question()

                const inputModules = {
                    survey_question_no,
                    len: _answers.length
                }
    
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
                const inputModuleAnswers = _answers.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_answer_no: typeof c.survey_module_answer_no === 'string' ? 0 : c.survey_module_answer_no,
                        answer: refAnswers.current[i].value,
                        src: c.src,
                        is_comment: c.is_comment
                    })
                })

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    required_start: _option.required_start,
                    required_end: _option.required_end,
                    badge: _option.badge,
                    random_answer: _option.random_answer,
                    except_last_random_answer: _option.except_last_random_answer,
                    mobile_one: _option.mobile_one,
                    icon_type: _option.icon_type,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputModuleAnswers,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion18({ variables })
            },
            _19: () => {
                const { _option } = states.row
    
                // 확장자 미  선택 시,
                if (_option.exts.length === 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.19.exts'})
                    })
    
                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }
    
                const inputQuestion = handleTabs.update.question()
    
                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    exts: _option.exts,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion19({ variables })
            },
            _20: () => {
                const { _option } = states.row

                const inputQuestion = handleTabs.update.question()

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    required_address2: _option.required_address2
                }

                const variables = {
                    survey_no,
                    inputQuestion,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion20({ variables })
            },
            _21: async() => {
                const { _question, _modules, _option } = states.row
    
                const { survey_question_no } = _question
                const { kind } = _option

                if (_modules.length === 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.21.error.zeroChoice'})
                    })
                
                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })
                    
                    return
                }

                // 보기 제한에서 벗어날경우 저장 못하게 한다
                const { enterError, wordError } = getDropdownChoiceError(kind, _modules.map(c => c.answer).join("\n"))
                const error = Boolean(enterError[0] === true || wordError[0] === true)

                if (error) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Edit.21.error.choice'})
                    })

                    handleTabs.change(0)

                    dispatchStates({ 
                        type: 'subTabIndex', 
                        subTabIndex: 0
                    })

                    return
                }

                if (kind) {
                    if (_option.required && (_option.required_start === 0 || _option.required_end === 0 || _option.required_end > _modules.length)) {
                        dispatch({ 
                            type: ALERTSNACKBARSHOW, 
                            variant: 'error', 
                            message: f({id: 'container.Survey.Edit.02.required'})
                        })
        
                        handleTabs.change(1)
                        
                        return
                    }
                }
    

                const inputQuestion = handleTabs.update.question()
    
    
                // survey_module_no가 string 이면 새로추가된 값이다 임의값 0으로 바꿔준다
                // value 값은 refExamples에서 가져온다
    
                const inputModules = _modules.map((c, i) => {
                    return ({
                        survey_question_no,
                        survey_module_no: typeof c.survey_module_no === 'string' ? 0 : c.survey_module_no,
                        answer: c.answer
                    })
                })
                

                // 단일선택에서는 requred_start=1, required_end=modules.length 로 임이로 해준다
                let requiredStart = _option.required_start
                let requiredEnd = _option.required_end

                if (!kind) {
                    requiredStart = 1
                    requiredEnd = inputModules.length
                }

                const inputOption = {
                    survey_option_no: _option.survey_option_no,
                    required: _option.required,
                    required_start: requiredStart,
                    required_end: requiredEnd,
                    badge: _option.badge,
                    random: _option.random,
                    except_last_random: _option.except_last_random,
                    is_comment: _option.is_comment,
                    is_comment_required: _option.is_comment_required,
                    is_comment_placeholder: refComment.current.value,
                    label: refLabels.current[0].value || '',
                    kind: _option.kind
                }
    
                const variables = {
                    survey_no,
                    inputQuestion,
                    inputModules,
                    inputOption
                }
                dispatch({ type: BACKDROPSHOW })
                updateSurveyQuestion21({ variables })
            }
        }
       
    }

    const handleExample = {
        append: () => {
            return new Promise(async(resolve, reject) => {
                const survey_module_no = uuidv4()
                await dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _modules: [
                        ...states.row._modules,
                        {survey_question_no, survey_module_no, answer: '', is_comment: false}
                    ]
                }})

                // 이미지 포커스 추가 보기에 자동으로 가게처리
                await dispatchStates({type: 'showImageUpload', showImageUpload: survey_module_no})

                resolve()
            })
        },
        appendDropdown: (type, words) => {
            return new Promise(async(resolve, reject) => {
                let key = '_modules'
                let id = 'survey_module_no'

                if (type === 'matrix') {
                    key = '_answers'
                    id = 'survey_module_answer_no'
                }

                const originRows = states.row[key]

                const wordsArray = String(words).replace(/\r\n/g, "\n").split("\n").filter(c => c)
                const len = wordsArray.length

                const newRows = []
                for (let i=0; i<len; i++) {
                    const newAnswer = wordsArray[i]
                    if (originRows[i]) {
                        newRows.push({
                            ...originRows[i],
                            answer: newAnswer
                        })
                        continue
                    }

                    newRows.push({ survey_question_no, [id]: uuidv4(), answer: newAnswer})
                }

                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    [key]: newRows,
                }})

                resolve()
            })
        },
        enter: async(index) => {
            // 마지막 모듈에서 엔터시 모듈추가
            if (states.row._modules.length - 1 === index) {
                await handleExample.append()
            }

            dispatchStates({ type: 'focus', focus: { type: 'examples', indexs: (index + 1) } })
        },
        changeScale: (type, e) => {
            const { value } = e.target
            const { row } = states
            const { _option } = row
            const { zero_number, reverse } = _option

            const rows = type === 'one' ? row._modules : row._answers

            // 현재 스케일 범위
            const moduleLen = rows.length
            // 선택한 스케일 범위
            const baseLen = zero_number ? value + 1 : value

            if (baseLen === moduleLen) return

            if (baseLen > moduleLen) {
                const newRows = getScalePlusRows({ survey_question_no, states, reverse, zero_number, type, guideLen: baseLen })

                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    ...newRows
                }})

                return
            }

            if (baseLen < moduleLen) {
                const newRows = getScaleMinusRows({ survey_question_no, states, reverse, zero_number, type, guideLen: baseLen })
            
                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    ...newRows
                }})

                return
            }
        },
        appendPoint: () => {
            const scale = new ScaleClass({ 
                survey_question_no, 
                data: states.row, 
                reverse: states.row._option.reverse,
                zero_number: states.row._option.zero_number,
                type: 'one'
            })

            const _modules = scale.addAnswer()
    
            return new Promise((resolve, reject) => {

                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _modules
                }})

                resolve()
            })
        },
        enterPoint: async(index) => {
            // 마지막 모듈에서 엔터시 모듈추가
            if (states.row._option.zero_number === true && states.row._modules.length === 11) return
            if (states.row._option.zero_number === false && states.row._modules.length === 10) return
            if (states.row._option.zero_number === undefined && states.row._modules.length === 10) return

            if (states.row._modules.length - 1 === index) {
                await handleExample.appendPoint()
            }

            dispatchStates({ type: 'focus', focus: { type: 'examples', indexs: (index + 1) } })
        },
        remove: async(id) => {
            const _modules = states.row._modules.filter(({survey_module_no}) =>  survey_module_no !== id)

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules
            }})

        },
        removeImage: async(id) => {
            // 이미지 포커스가 되어있는 아이템을 삭제하면 바로 전 index에 포커스주자
        
            if (id === states.showImageUpload) {
                const index = states.row._modules.findIndex(({survey_module_no}) =>  survey_module_no === id)
                await dispatchStates({
                    type: 'showImageUpload', 
                    showImageUpload:  states.row._modules[(index - 1)].survey_module_no
                })
            }
            
            const _modules = states.row._modules.filter(({survey_module_no}) =>  survey_module_no !== id)

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules
            }})

        },
        removeImageMulti: async (id) => { // 중복선택 모듈 삭제

            if (id === states.showImageUpload) {
                const index = states.row._modules.findIndex(({survey_module_no}) =>  survey_module_no === id)
                await dispatchStates({
                    type: 'showImageUpload', 
                    showImageUpload:  states.row._modules[(index - 1)].survey_module_no
                })
            }
            
            const _modules = states.row._modules.filter(({survey_module_no}) =>  survey_module_no !== id)

            let required_start = states.row._option.required_start
            let required_end = states.row._option.required_end

            // 필수응답 체크값보다 보기수가 줄어든다면 필수응답값을 None 처리한다. 저장할때 필수응답값 다시 선택하라고 안내할거임
            if (_modules.length < states.row._option.required_start || _modules.length < states.row._option.required_end) {
                required_start = 0
                required_end = 0
            }

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules,
                _option: {
                    ...states.row._option,
                    required_start,
                    required_end
                }
            }})
        },
        removePoint: (id) => {
            const scale = new ScaleClass({ 
                survey_question_no, 
                data: states.row, 
                reverse: states.row._option.reverse,
                zero_number: states.row._option.zero_number,
                type: 'one'
            })

            const _modules = scale.removeAnswer(id)

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules
            }})
        },
        removeMulti: async (id) => { // 중복선택 모듈 삭제
            const _modules = states.row._modules.filter(({survey_module_no}) =>  survey_module_no !== id)


            let required_start = states.row._option.required_start
            let required_end = states.row._option.required_end

            // 필수응답 체크값보다 보기수가 줄어든다면 필수응답값을 None 처리한다. 저장할때 필수응답값 다시 선택하라고 안내할거임
            if (_modules.length < states.row._option.required_start || _modules.length < states.row._option.required_end) {
                required_start = 0
                required_end = 0
            }

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules,
                _option: {
                    ...states.row._option,
                    required_start,
                    required_end
                }
            }})
        },
        changeIsComment: (survey_module_no, is_comment) => {
            const _modules = states.row._modules.map(c => c.survey_module_no === survey_module_no ? {...c, is_comment: !is_comment} : c)

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules
            }})
        },
        showImageUpload: (survey_module_no) => {
            if (states.showImageUpload === survey_module_no) return
            dispatchStates({type: 'showImageUpload', showImageUpload: survey_module_no})
        },
        changeImage: survey_module_no => props => {
            const { img_src } = props

            const _modules = states.row._modules.map(c => c.survey_module_no === survey_module_no ? {...c, src: img_src} : c)
            
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules
            }})
        },
        changeOrder: result => {
            if (!result.destination) {
                return
            }
            
            const _modules = handleDnD(result, states.row._modules)
            
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules
            }})
        }
    }

    // 행, 열 subTab
    const handleSubTabs = {
        change: (e, subTabIndex) => {
            dispatchStates({ type: 'subTabIndex', subTabIndex })
        }
    }


    // 행
    const handleColumn = {
        append: () => {
            return new Promise((resolve, reject) => {
                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _modules: [
                        ...states.row._modules,
                        {survey_question_no, survey_module_no: uuidv4(), column: ''}
                    ]
                }})

                resolve()
            })
        },
        enter: async(index) => {
            // 마지막 모듈에서 엔터시 모듈추가
            if (states.row._modules.length - 1 === index) {
                await handleColumn.append()
            }
            dispatchStates({ type: 'focus', focus: { type: 'columns', indexs: (index + 1) } })
        },
        remove: async (id) => {
            const _modules = states.row._modules.filter(({survey_module_no}) =>  survey_module_no !== id)


            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules
            }})

        },
        changeOrder: result => {
            if (!result.destination) {
                return
            }
            
            const _modules = handleDnD(result, states.row._modules)
            
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _modules
            }})
        }
    }

    // 열
    const handleAnswer = {
        append: (args={}) => {
            return new Promise(async(resolve, reject) => {
                const survey_module_answer_no = uuidv4()

                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _answers: [
                        ...states.row._answers,
                        {survey_question_no, survey_module_answer_no, answer: '', ...args}
                    ]
                }})

                // 이미지 포커스 추가 보기에 자동으로 가게처리
                await dispatchStates({type: 'showImageUpload', showImageUpload: survey_module_answer_no})

                resolve()
                
            })
        },
        enter: async(index, args={}) => {
            // 마지막 모듈에서 엔터시 모듈추가
            if (states.row._answers.length - 1 === index) {
                await handleAnswer.append(args)
            }
            dispatchStates({ type: 'focus', focus: { type: 'answers', indexs: (index + 1) } })
        },
        enterImg: async(index) => {
            // 마지막 모듈에서 엔터시 모듈추가
            if (states.row._answers.length - 1 === index) {
                await handleAnswer.append({ src: '', is_comment: false })
            }
            dispatchStates({ type: 'focus', focus: { type: 'answers', indexs: (index + 1) } })
        },
        appendPoint: () => {
            const scale = new ScaleClass({ 
                survey_question_no, 
                data: states.row, 
                reverse: states.row._option.reverse,
                zero_number: states.row._option.zero_number,
                type: 'matrix'
            })

            const _answers = scale.addAnswer()
    
            return new Promise((resolve, reject) => {

                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _answers
                }})

                resolve()
            })
        },
        enterPoint: async(index) => {
            // 마지막 모듈에서 엔터시 모듈추가
            if (states.row._option.zero_number === true && states.row._answers.length === 11) return
            if (states.row._option.zero_number === false && states.row._answers.length === 10) return
            if (states.row._option.zero_number === undefined && states.row._answers.length === 10) return

            if (states.row._answers.length - 1 === index) {
                await handleAnswer.appendPoint()
            }
            dispatchStates({ type: 'focus', focus: { type: 'answers', indexs: (index + 1) } })
        },
        changeIsComment: (survey_module_answer_no, is_comment) => {
            const _answers = states.row._answers.map(c => c.survey_module_answer_no === survey_module_answer_no ? {...c, is_comment: !is_comment} : c)

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _answers
            }})
        },
        remove: async (id) => {
            const _answers = states.row._answers.filter(({survey_module_answer_no}) =>  survey_module_answer_no !== id)


            dispatchStates({ type: 'row', row: {
                ...states.row,
                _answers
            }})

        },
        removePoint: (id) => {
            const scale = new ScaleClass({ 
                survey_question_no, 
                data: states.row, 
                reverse: states.row._option.reverse,
                zero_number: states.row._option.zero_number,
                type: 'matrix'
            })

            const _answers = scale.removeAnswer(id)

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _answers
            }})
        },
        removeMulti: async (id) => {
            const _answers = states.row._answers.filter(({survey_module_answer_no}) =>  survey_module_answer_no !== id)

            let required_start = states.row._option.required_start
            let required_end = states.row._option.required_end

            // 필수응답 체크값보다 보기수가 줄어든다면 필수응답값을 None 처리한다. 저장할때 필수응답값 다시 선택하라고 안내할거임
            if (_answers.length < states.row._option.required_start || _answers.length < states.row._option.required_end) {
                required_start = 0
                required_end = 0
            }

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _answers,
                _option: {
                    ...states.row._option,
                    required_start,
                    required_end
                }
            }})

        },
        showImageUpload: (survey_module_answer_no) => {
            if (states.showImageUpload === survey_module_answer_no) return
            dispatchStates({type: 'showImageUpload', showImageUpload: survey_module_answer_no})
        },
        changeImage: survey_module_answer_no => props => {
            const { img_src } = props

            const _answers = states.row._answers.map(c => c.survey_module_answer_no === survey_module_answer_no ? {...c, src: img_src} : c)
            
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _answers
            }})
        },
        changeOrder: result => {
            if (!result.destination) {
                return
            }
            
            const _answers = handleDnD(result, states.row._answers)
            
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _answers
            }})
        }
    
    }


    const handleOption = {
        changeBadge: (checked) => {
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    badge: checked
                }
            }})
        },
        changeRequired: (e) => {
            const { checked } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    required: checked
                }
            }})
        },
        changeRequiredAddress: (e) => {
            const { checked } = e.target

            const option = checked ? {} : { required_address2: false }

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    required: checked,
                    ...option
                }
            }})
        },
        changeCheckbox: (e) => { // 단순하게 체크박스 처리할때
            const { name, checked } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    [name]: checked
                }
            }})
        },
        changeZeroNumber: (type, zero_number) => {
            const { row } = states
            const { _option } = row

            const scale = new ScaleClass({ 
                survey_question_no, 
                data: states.row, 
                reverse: states.row._option.reverse,
                zero_number,
                type
            })

            const rows = scale.getZeroNumberRows()
            const { key } = scale.getTypeValue()

            let newRows = { [key]: [...rows] }

            // 1시작에서 0으로 변경될경우 마지막에 한개 추가한다.
            // 0에서 1로 변경일 경우 마지막 배열 삭제한다.
            if (!_option.zero_number && zero_number) {
                newRows = getScalePlusRows({ survey_question_no, states, reverse: _option.reverse, zero_number, type, guideLen: (rows.length + 1) })
            } else if (_option.zero_number && !zero_number) {
                newRows = getScaleMinusRows({ survey_question_no, states, reverse: _option.reverse, zero_number, type, guideLen: (rows.length - 1) })
            }

            dispatchStates({ type: 'row', row: {
                ...states.row,
                ...newRows,
                _option: {
                    ...states.row._option,
                    zero_number
                }
            }})
        },
        changeReverse: (type, reverse) => {
            const scale = new ScaleClass({ 
                survey_question_no, 
                data: states.row, 
                reverse,
                zero_number: states.row._option.zero_number,
                type
            })

            const rows = scale.getReverseRows()
            const { key } = scale.getTypeValue()

            dispatchStates({ type: 'row', row: {
                ...states.row,
                [key]: [...rows],
                _option: {
                    ...states.row._option,
                    reverse
                }
            }})
        },
        changeMobileMatrix: (e) => {
            const { value } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    mobile_matrix: value === 'true'
                }
            }})
        },
        changeRandom: (e) => {
            const { checked } = e.target

            // 체크 true시 기존 DB값을 준다
            const originData = getDefaultData({ change_type: edit.change_type, module_type: edit.module_type, data: propsFetchQuestion.data.surveyQuestionModule})

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    random: checked,
                    except_last_random: !checked ? false : originData._option.except_last_random
                }
            }})
        },
        changeExceptLastRandom: (e) => {
            const { checked } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    except_last_random: checked
                }
            }})
        },
        changeLayout: (e) => {
            const { checked } = e.target
            
            // 체크 true시 기존 DB값을 준다
            const originData = getDefaultData({ change_type: edit.change_type, module_type: edit.module_type, data: propsFetchQuestion.data.surveyQuestionModule})

            let layout = 1
            // 체크했을때. 기존값을 다시 셋팅해주는데. 기존값이 1이라면 2로해준다. (2개부터 열수 설정을 할수가있다)
            if (checked) layout = originData._option.layout < 2 ? 2 :  originData._option.layout

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    layout
                }
            }})
        },
        changeLayoutNum: (e) => {
            const { value } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    layout: value
                }
            }})
        },
        openIsComment: (e) => { // 01, 02 모듈에서 사용
            const { checked } = e.target

            dispatchStates({type: 'openIsComment', openIsComment: checked})


            // 체크 true시 기존 DB값을 준다
            const originData = getDefaultData({ change_type: edit.change_type, module_type: edit.module_type, data: propsFetchQuestion.data.surveyQuestionModule})

            let is_comment_placeholder = originData._option.is_comment_placeholder
            let is_comment_required = originData._option.is_comment_required
            
            if (!checked) {
                is_comment_placeholder = ''
                is_comment_required = false
            }

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    is_comment_required
                }
            }})

            refComment.current.value = is_comment_placeholder
            on._changeOption('is_comment_placeholder', refComment.current.value)
        },
        changeRequiredMulti: (e, count) => {
            const { checked } = e.target

            // 체크 설정시 기존 DB값을 준다
            const originData = getDefaultData({ change_type: edit.change_type, module_type: edit.module_type, data: propsFetchQuestion.data.surveyQuestionModule})
            const { _option } = originData
            const { required_start: originRequiredStart, required_end: originRequiredEnd } = _option

            let required_start = 0
            let required_end = 0

            if (checked) {
                // 설정된 범위내에 보기수가 있다면 기존값 보여주고 아니면 둘다 None처리 한다.
                if (count >= originRequiredStart && count >= originRequiredEnd) {
                    required_start = originData._option.required_start
                    required_end = originData._option.required_end
                }
            }

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    required: checked,
                    required_start,
                    required_end
                }
            }})
        },
        changeRequiredMultiStart: (e) => {
            const required_start = e.target.value
            const required_end = states.row._option.required_end

            if ((required_start > required_end) && required_end !== 0) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Survey.Edit.02.required_start.0'})
                })

                return
            }

            // None 선택은 못하게한다
            if (required_start === 0) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Survey.Edit.02.required_start.1'})
                })

                return
            }
            
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    required_start
                }
            }})
        },
        changeRequiredMultiEnd: (e) => {
            const required_end = e.target.value
            const required_start = states.row._option.required_start

            if ((required_end < required_start) && required_start !== 0) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Survey.Edit.02.required_end.0'})
                })

                return
            }

            // None 선택은 못하게한다
            if (required_end === 0) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Survey.Edit.02.required_end.1'})
                })

                return
            }
            
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    required_end
                }
            }})
        },
        changeRequiredRank: (e) => {
            const required_end = e.target.value
            
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    required_start: required_end,
                    required_end
                }
            }})
        },
        changeIsCommentRequired: (e) => {
            const { checked } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    is_comment_required: checked
                }
            }})
        },
        changeMobileOne: (e) => {
            const { checked } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    mobile_one: checked
                }
            }})
        },
        changeRandomColumn: (e) => {
            const { checked } = e.target

            // 체크 true시 기존 DB값을 준다
            const originData = getDefaultData({ change_type: edit.change_type, module_type: edit.module_type, data: propsFetchQuestion.data.surveyQuestionModule})

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    random_column: checked,
                    except_last_random_column: !checked ? false : originData._option.except_last_random_column
                }
            }})
        },
        changeExceptLastRandomColumn: (e) => {
            const { checked } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    except_last_random_column: checked
                }
            }})
        },
        changeRandomAnswer: (e) => {
            const { checked } = e.target

            // 체크 true시 기존 DB값을 준다
            const originData = getDefaultData({ change_type: edit.change_type, module_type: edit.module_type, data: propsFetchQuestion.data.surveyQuestionModule})

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    random_answer: checked,
                    except_last_random_answer: !checked ? false : originData._option.except_last_random_answer
                }
            }})
        },
        changeExceptLastRandomAnswer: (e) => {
            const { checked } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    except_last_random_answer: checked
                }
            }})
        },
        changeIsComment: (e) => {
            const { checked } = e.target

             // 체크 true시 기존 DB값을 준다
             const originData = getDefaultData({ change_type: edit.change_type, module_type: edit.module_type, data: propsFetchQuestion.data.surveyQuestionModule})

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    is_comment: checked,
                    is_comment_required: !checked ? false : originData._option.is_comment_required
                }
            }})
        },
        changeIconType: (icon_type) => {
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    icon_type
                }
            }})
        },
        changeExts: useCallback((exts) => {
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    exts: [...new Set(exts)]
                }
            }})
        }, [states.row]),
        change09Date: useCallback((name, value) => {
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    [name]: value
                }
            }})
        }, [states.row]),
        changeNps: (type, nps) => {
            // 해제일 경우는 nps만 false 해주면 된다.
            if (!nps) {
                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _option: {
                        ...states.row._option,
                        nps: false
                    }
                }})

                return
            }

            const reverse = false
            const zero_number = true

            const newRows = getScalePlusRows({ survey_question_no, states, reverse, zero_number, type, guideLen: 11 })

            dispatchStates({ type: 'row', row: {
                ...states.row,
                ...newRows,
                _option: {
                    ...states.row._option,
                    reverse,
                    zero_number,
                    nps: true
                }
            }})
        },
        changeDropdownKind: (e) => {
            const { value } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    kind: value === 'true'
                }
            }})
        }
    }


    const change09Type = type => {
        
        if (type === states.row._option.type) return

        dispatchStates({ type: 'row', row: {
            ...states.row,
            _option: {
                ...states.row._option,
                type
            }
        }})   
    }

    
    const handleTypePhone = {
        change: (target, code) => {
            if (target === 'left') {
                dispatchStates({ 
                    type: 'nationalLists', 
                    nationalLists: states.nationalLists.map(c => c.code === code ? {...c, checked: !c.checked} : c) 
                })
            } else {
                dispatchStates({ 
                    type: 'row', 
                    row: {
                        ...states.row,
                        _option: {
                            ...states.row._option,
                            national_flag: states.row._option.national_flag.map(c => c.code === code ? {...c, checked: !c.checked} : c)
                        }
                    }
                })
            }
        },
        changeAll: e => {
            const { name, checked } = e.target

            dispatchStates({ 
                type: 'typePhoneAllChecked', 
                typePhoneAllChecked: {
                    ...states.typePhoneAllChecked,
                    [name]: checked
                }
            })
            
            if (name === 'left') {
                // 체크 상태 전체 번경
                dispatchStates({ 
                    type: 'nationalLists', 
                    nationalLists: states.nationalLists.map(c => ({ ...c, checked }))
                })
            } else {
                // 체크 상태 전체 번경
                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _option: {
                        ...states.row._option,
                        national_flag: states.row._option.national_flag.map(c => ({ ...c, checked })) 
                    }
                }})
            }
        },
        move: (target) => {
            let _nationalLists = ''
    
            if (target === 'left') { // 좌측에서 우측으로 이동
    
                // 체크된 리스트 안보이게 처리
                _nationalLists = states.nationalLists.map(c => c.checked ? {...c, visible: false} : c)
    
                dispatchStates({ 
                    type: 'nationalLists', 
                    nationalLists: _nationalLists
                })
    
                // 체크된 리스트 우측에다가 저장 
                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _option: {
                        ...states.row._option,
                        national_flag: _nationalLists.filter(c => !c.visible).map(c => ({code: c.code, name: c.name, checked: false}))
                    }
                }})
            } else {
                // 선택된 데이터의 국가코드만 담자
                const codes = states.row._option.national_flag.filter(c => c.checked).map(c => c.code)
    
                // 코드값이 있다면 visible true 처리한다
                _nationalLists = states.nationalLists.map(c => codes.includes(c.code) ? {...c, visible: true, checked: false} : c)
    
                dispatchStates({ 
                    type: 'nationalLists', 
                    nationalLists: _nationalLists
                })
    
    
                // codes 없는값만 남겨둔다
                dispatchStates({ type: 'row', row: {
                    ...states.row,
                    _option: {
                        ...states.row._option,
                        national_flag: states.row._option.national_flag.filter(c =>  !codes.includes(c.code)) 
                    }
                }})
            }

            // 전체 상태는 false로 처리한다
            dispatchStates({ 
                type: 'typePhoneAllChecked', 
                typePhoneAllChecked: {
                    left: false,
                    right: false
                }
            })
        },
        changeOnlyMobile: () => {
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    only_mobile: !states.row._option.only_mobile
                }
            }})
        }
    }

    const handleTypeNumber = {
        changeCheckbox: (e) => {
            const { name, checked } = e.target

            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    [name]: checked
                }
            }})
        },
        changeUnitPosition: (val) => {
            dispatchStates({ type: 'row', row: {
                ...states.row,
                _option: {
                    ...states.row._option,
                    unit_position: val
                }
            }})
        }
    }

    // propsFetchQuestion.data 값으로 rows 값을 초기화 해준다
    const changeRowsInit = useCallback(() => {
        if (propsFetchQuestion.data) {
            const row = getDefaultData({ change_type: edit.change_type, module_type: edit.module_type, data: propsFetchQuestion.data.surveyQuestionModule})
 
            switch (edit.module_type) {
                case '_01':
                case '_02':
                case '_12':
                case '_13':
                case '_17':
                case '_18':
                    dispatchStates({type: 'row', row})
                    const { _option:{is_comment_required, is_comment_placeholder} } = row
                    dispatchStates({type: 'openIsComment', openIsComment: is_comment_required || is_comment_placeholder ? true : false})
                    break
                case '_09':
                    const national_flag = row._option.national_flag.map(d => ({
                        code: d,
                        name: countryList.getName(d),
                        checked: false
                    }))
        
                    dispatchStates({type: 'row', row: {
                        ...row,
                        _option: {
                            ...row._option,
                            national_flag
                        }
                    }})
        
                    // nationalLists: national_flag가 있을경우 visible false 처리한다
                    dispatchStates({ 
                        type: 'nationalLists', 
                        nationalLists: initialState.nationalLists.map(d => {
                            return (
                                row._option.national_flag.includes(d.code) ? {...d, visible: false} : d
                            )
                        })
                    })
                    break
                default:
                    dispatchStates({type: 'row', row})
                    break
            }

            // 상위콤퍼넌트에 초기값 전달
            on.init(row)
            
            // 컴퍼넌트 unmount를 위해 
            dispatchStates({type: 'init', init: true})
        }
    }, [propsFetchQuestion.data, on, edit])

    useEffect(() => {
        changeRowsInit()
    }, [changeRowsInit])

    // 초기화 실행하고 나서 바로 상태값 바꿔서 내용보여준다
    useEffect(() => {
        if (states.init) dispatchStates({type: 'init', init: false})
    }, [states.init])

    useEffect(() => {
        // 처음 화면 열릴때는 changeRowsInit 여기서 초기값 전달하므로 여기서는 안하고 그후 state 변경시 전달한다
        // 미리보기를 변경을 위해 row값을 상위 컴포넌트에 전달 
        // 여기서 호출한값중에서 ref 로 이뤄진 값들은 제외하고 저장할거다 (ref는 랜더링때문에 ㅅㅂ 해당 스테이트에는 최신값이 없다 ㅅㅂ)
        if (!states.init && !isEqual(states.row, prevRow)) setTimeout(() => on.change(states.row), 1)

    }, [states.row, prevRow, states.init, on])

    useEffect(() => {
        function scrollBottom() {
            scroller.scrollTo(scrolls.bottom, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
                containerId: scrolls.id
            })
        }

        // 보기 추가시 스크롤 Bottom
        if (typeof prevRow === 'object') {
            if (typeof states.row._modules === 'object') {
                
                if (prevRow._modules.length > 0 && states.row._modules.length > prevRow._modules.length) {
                    scrollBottom()
                    return
                }
            }

            if (typeof states.row._answers === 'object') {
                if (prevRow._answers.length > 0 && states.row._answers.length > prevRow._answers.length) {
                    scrollBottom()
                    return
                }
            }
            
        }
        
    }, [states.row, prevRow, scrolls])

    /*
    const isTop = typeof states.row.__typename === 'string' && states.show !== 'image' && states.tabIndex === 0 && (
        edit.module_type === '_00' ? false 
        : edit.module_type === '_05' &&  states.subTabIndex === 0 ? false 
        : edit.module_type === '_06' &&  states.subTabIndex === 0 ? false 
        : edit.module_type === '_09' ? false 
        : edit.module_type === '_10' ? false 
        : edit.module_type === '_20' ? false 
        : true
    )*/

    const isTop = true
    
    // 인풋텍스트 박스는 state가 아니기때문에 초기화를 위해 언마운트가 필요하다 ㅜ.ㅜ
    // 초기화 실행시 null로 언마운트하고 바로 다시 보여준다
    if (states.init) return null
    
    return (
            <>
            <SurveyEditComponent show={states.show}>
                <ChangeModuleTypeContext.Provider value={{onChangeModuleType: on.changeModuleType, changeType: Boolean(edit.change_type)}}>
                    <ScrollContext.Provider value={{id: scrolls.id}}>
                        <PropsContext.Provider value={{
                            edit,
                            states,
                            refQuestion,
                            refExamples,
                            refColumns,
                            refAnswers,
                            refLabels,
                            refScales,
                            refBases,
                            refNumbers,
                            refComment,
                            refAgreeRequiredMessage,
                            refConfirm,
                            onQuestion: handleQuestion,
                            onImageAttach: handleImageAttach,
                            onTabs: handleTabs,
                            onExample: handleExample,
                            onOption: handleOption,
                            onSubTabs: handleSubTabs,
                            onColumn: handleColumn,
                            onAnswer: handleAnswer,
                            onTypePhone: handleTypePhone,
                            onTypeNumber: handleTypeNumber,
                            onEdit: on,
                            change09Type
                        }}>
                            {
                                typeof states.row.__typename === 'string' && (
                                    edit.module_type === '_00' ?  <SurveyEdit00Container method={method}/> :
                                    edit.module_type === '_01' ?  <SurveyEdit01Container method={method}/> :
                                    edit.module_type === '_02' ?  <SurveyEdit02Container method={method}/> :
                                    edit.module_type === '_03' ?  <SurveyEdit03Container method={method}/> :
                                    edit.module_type === '_04' ?  <SurveyEdit04Container method={method}/> : 
                                    edit.module_type === '_05' ?  <SurveyEdit05Container method={method}/> :
                                    edit.module_type === '_06' ?  <SurveyEdit06Container method={method}/> :
                                    edit.module_type === '_07' ?  <SurveyEdit07Container method={method}/> : 
                                    edit.module_type === '_08' ?  <SurveyEdit08Container method={method}/> : 
                                    edit.module_type === '_09' ?  <SurveyEdit09Container method={method}/> : 
                                    edit.module_type === '_10' ?  <SurveyEdit10Container method={method}/> :
                                    edit.module_type === '_11' ?  <SurveyEdit11Container method={method}/> :
                                    edit.module_type === '_12' ?  <SurveyEdit12Container method={method}/> : 
                                    edit.module_type === '_13' ?  <SurveyEdit13Container method={method}/> : 
                                    edit.module_type === '_14' ?  <SurveyEdit14Container method={method}/> : 
                                    edit.module_type === '_15' ?  <SurveyEdit15Container method={method}/> :
                                    edit.module_type === '_16' ?  <SurveyEdit16Container method={method}/> : 
                                    edit.module_type === '_17' ?  <SurveyEdit17Container method={method}/> : 
                                    edit.module_type === '_18' ?  <SurveyEdit18Container method={method}/> : 
                                    edit.module_type === '_19' ?  <SurveyEdit19Container method={method}/> : 
                                    edit.module_type === '_20' ?  <SurveyEdit20Container method={method}/> : 
                                    edit.module_type === '_21' ?  <SurveyEdit21Container method={method}/> : 
                                    edit.module_type === '_22' ?  <SurveyEdit22Container method={method}/> : null
                                )
                            }
                        </PropsContext.Provider>
                    </ScrollContext.Provider>
                </ChangeModuleTypeContext.Provider>
            </SurveyEditComponent>
            {
                isTop && <SurveyEditTopComponent scrolls={scrolls}/>
            }
            { 
                states.show === 'image' && (
                    // show contents 인 이오는 contents만이 show true이기때문에 강제적으로 준거다
                    <SurveyEditComponent show="contents">
                        <SurveyEditImageAttachContainer
                            titleFloat={
                                <>
                                    <ErrorOutlineIcon/>
                                    <Typography variant="caption">{f({id: 'container.Survey.Edit.Container.titleFloat'})}</Typography>  
                                </>
                            }
                            titleSplit={
                                <>
                                    <ErrorOutlineIcon/><Typography variant="caption">{f({id: 'container.Survey.Edit.Container.titleSplit'})}</Typography>  
                                </>
                            }
                            onlyImage={false}
                            show={states.show} 
                            position={states.row._question.img_position} 
                            on={handleImageAttach}
                            img_src={states.row._question.img_src}
                            img_name={states.row._question.img_name}
                        /> 
                    </SurveyEditComponent>
                )
            }
            </>
    )
}

export default withSurveyFetchQuestion()(withSurveyEdit(withPrevState(Component)))