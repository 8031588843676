import { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { SvgIcon  } from '@material-ui/core'
import PropsContext from 'context/props'

const Icon = withStyles({
    root: (props) => {
        const { selected, disabled, width, soft, answer_button } = props

        let bgcolor = answer_button

        if (Boolean(soft)) {
            const [ r, g, b, a ] = answer_button.split(',')
            const customA = Number(a) > .7 ? .7 : a
            bgcolor = `${r},${g},${b},${customA}`
        }

        const color = disabled ? '181, 183, 182, 1' : selected ? bgcolor : bgcolor 
        let style = { color: `rgba(${color})` }

        if (width) style = {...style, fontSize: width}

        return style
    }
})((props) => {
    const { children, ...other } = props
    return <SvgIcon {...other}>{children}</SvgIcon>
})

export default (props) => {
    const { size="medium", width, kind='answer_button', soft=0, children, ...other } = props
    const { design } = useContext(PropsContext)
    const { answer, answer_button, answer_sbutton } = design // answer: 보기, answer_button: 보기선택 아이콘, asnwer_sbutton: 버튼

    let color = answer_button
    if (kind === 'answer') color = answer
    else if (kind === 'answer_sbutton') color = answer_sbutton

    return <Icon {...other} fontSize={size} width={width} soft={soft} answer_button={color}>{children}</Icon>
}
