import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_ANALYSIS_USER } from 'gql/survey_analysis_users'

export default mode => WrappedComponent => props => {
    const { token } = props

    const params = useParams()

    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    const dispatch = useDispatch()

    const { data } = useQuery(GET_SURVEY_ANALYSIS_USER, {
        variables: { survey_no, token, mode },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    if (!data) return null

    return <WrappedComponent surveyAnalysisUserRow={data.surveyAnalysisUser} {...props}/>
}