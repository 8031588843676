import { useContext, useCallback, useEffect, useState } from 'react'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { VerifyIsJust } from '../01/Component'
import { WrapperStyled } from 'styled/Survey/Img'
import ItemsComponent from './Items'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import useJustDebounce from 'hooks/useJustDebounce'

const Component = (props) => {
    const { mode } = useContext(AccessMethodContext)
    const { mbox, option, format, refComments12, design } = useContext(PropsContext)
    const { method } = mbox
    const { just } = option

    const { answer: answerColor } = design

    const {  lists, icon_type, mobile_one, survey_question_no, is_comment_placeholder, reply } = props
    
    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ debounce, onChangDebounce ] = useJustDebounce()

    const [ newReply, setNewReply ] = useState(reply)

    const handleChange = useCallback(async(survey_module_no, click_comment) => {
  
        // 답체크
        // 미체크시 체크, 체크시 미체크
        // 체크된 상태에서 코멘트 클릭시 유지

        // 이미 체크된 값이 있는지 체크 (선택한값)
        const otherChecked = newReply.find(c => c.survey_module_no && c.survey_module_no !== survey_module_no)  ? true : false

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c => c.survey_module_no === survey_module_no) ? true : false

        //체크 상태인데 코멘트 클릭시에는 리턴
        if (selfChecked && click_comment) return

        // 바로 다음문항으로 이동하기 여부
        const verifyIsJust = new VerifyIsJust({ mode, method, just })
        const isJust = verifyIsJust._01(lists, survey_module_no)

        if (debounce) return

        onChangDebounce(isJust)

        const reply = format._12({ survey_question_no, survey_module_no, isJust, comment: '' })


        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        // 전체 통합작업
        const newReplys = selfChecked  ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no) ] :
                          otherChecked ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no), reply ]                     
                                       : [ ...memoryReply.rows, reply ] 
                            
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [ mode, method, just, lists, survey_question_no, newReply, format, debounce, onChangeReplys, onChangDebounce ])


    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])


    return (
        <WrapperStyled mobile_one={mobile_one}>
            <ItemsComponent
                lists={lists}
                reply={newReply}
                icon_type={icon_type}
                mobile_one={mobile_one}
                is_comment_placeholder={is_comment_placeholder}
                refComment={refComments12}
                answerColor={answerColor}
                onChange={handleChange}
            />
        </WrapperStyled>
    )
}

export default Component