export const LOGIN = 'auth/LOGIN'
export const LOGOUT = 'auth/LOGOUT'

const initState = { 
    token: ''
}

export default (state = initState, action) => {
    switch(action.type) {
        case LOGIN:
            return { token: action.token }
        case LOGOUT:
            return initState
        default:
            return state
    }
}