import { memo, useContext, useMemo, useCallback, useState } from 'react'
import oc from 'open-color'
import { useIntl } from 'react-intl'
import { getMimePicture } from 'utils/survey'
import { handleDisabledRightClick } from 'ts-utils'
import { cookies, downloadFile } from 'utils'
import { Typography, Divider } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import summaryContext from 'context/summary'
import moment from 'moment'
import { getRGBA } from 'utils/analysis'
import withSurveyPopoverImageNew from 'hoc/Survey/PopoverImageNew'
import { useStyles } from './SummaryCommentItem'

const getCookieName = (survey_analysis_users_no, src) => {
    const name = `reportFile-${survey_analysis_users_no}-${src.replace(process.env.REACT_APP_CF, '')}`
    return name
}

// 만료된 쿠키인지 체크
const isCookieExpired = (value) => {
    // 값이 없으면 만료된거나 마찬가지
    if (!value) return true

    const [ _, time ] = value.split('&')

    const diff = moment().diff(moment(time), 'seconds');

    // 1분 지나면 만료
    return Number(diff) > 60   
}

function SummaryFileItemComponent(props) {
    const { name, survey_analysis_users_no, filename, src, wdate, levels, onPopoverImageOpen } = props

    const { viewMode, design } = useContext(summaryContext)

    const [ loading, setLoading ] = useState(false)

    const { formatMessage: f } = useIntl()

    const color = getRGBA({color: design.question, opacity: 1})

    const classes = useStyles({ color, fontFamily: design.font_family })

    const fullSrc = `${process.env.REACT_APP_LISTO_CF}${src}`

    const cookieName = getCookieName(survey_analysis_users_no, src)

    const handleClick = useCallback(async() => {
        const getFile = async() => {
            const blob = await fetch(fullSrc).then(response => {
                if (response.status !== 200) return null
                return response.blob()
            })
    
            downloadFile({ filename: `${name}-${survey_analysis_users_no}-${filename}`, data: blob })
        }

        setLoading(true)

        if (viewMode === 'report') {
            const cookieValue = cookies.get(cookieName)

            if (isCookieExpired(cookieValue)) cookies.remove({ name: cookieName, domain: `.${process.env.REACT_APP_DOMAIN}` })

            let count = 1

            if (cookieValue) {
                const [ c ] = cookieValue.split('&')
           
                count = Number(c) + 1
            }

            if (count > 3) {
                setLoading(false)
                window.alert(f({id: 'styled.Survey.Analysis.SummaryFileItem.downloadAlert'}))
                return
            }

            const value = `${count}&${moment().format('YYYY-MM-DD HH:mm:ss')}`;

            cookies.set({ value: `${cookieName}=${value}`, maxAge: 60 })

            await getFile()
            setLoading(false)
            return
        }

        await getFile()
        setLoading(false)
       
    }, [f, cookieName, fullSrc, name, survey_analysis_users_no, filename, viewMode])

    const mime = getMimePicture(fullSrc)
    
    return (
        <div >
            {
                useMemo(() => {
                    return (
                        <>
                            <div>
                               
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {
                                        mime === 'image'? (
                                            <img 
                                                src={fullSrc} 
                                                style={{ maxWidth: 70, maxHeight: 70, borderRadius: 5, marginBottom: 8, cursor: 'pointer' }} 
                                                alt="listovey" 
                                                loading="lazy"
                                                onClick={() => onPopoverImageOpen(fullSrc)}
                                                onContextMenu={handleDisabledRightClick}
                                            />
                                        ) : (
                                            <Typography variant="body2" display="block" className={`${classes.typography} ${classes.comment}`}>
                                                {filename}
                                            </Typography>
                                        )
                                    }
                                    {
                                        viewMode === 'report' ? (
                                            (levels === 'level10' || levels === 'level100'|| levels === 'level1000') ? (
                                                <>    
                                                {
                                                    loading ? <GetAppIcon style={{color: oc.gray[4]}} /> : <GetAppIcon style={{ color: `rgba(${design.bar})`, cursor: 'pointer'}} onClick={handleClick}/>
                                                }
                                                </>
                                            ) : null
                                        ) : (
                                            <>
                                            {
                                                loading ? <GetAppIcon style={{color: oc.gray[4]}} /> : <GetAppIcon style={{ color: `rgba(${design.bar})`, cursor: 'pointer'}} onClick={handleClick}/>
                                            }
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                viewMode === 'view' && (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="body2" style={{fontSize: 12}} display="block" color="textSecondary" className={classes.typography}>
                                            {f({id: 'styled.Survey.Analysis.SummaryFileItem.id'}, { id: survey_analysis_users_no })}
                                        </Typography>
                                        <Typography variant="body2" style={{fontSize: 12}} display="block" color="textSecondary" className={`${classes.typography} ${classes.wdate}`}>
                                            {Number.isNaN(Number(wdate)) ? moment(wdate).format('YYYY.MM.DD HH:mm:ss') : moment(Number(wdate)).format('YYYY.MM.DD HH:mm:ss')}
                                        </Typography>
                                    </div>
                                  
                                )
                            }
                            <Divider light className={classes.divider}/>
                      </>  
                    )
                  
                }, [f, loading, levels, classes.typography, classes.comment, classes.divider, classes.wdate, viewMode, wdate, filename, fullSrc, design.bar, mime, survey_analysis_users_no, onPopoverImageOpen, handleClick])
            }
            
        </div>
    )
}

export default withSurveyPopoverImageNew(memo(SummaryFileItemComponent))