/**
 * 완료율, 완료수 상태값
 */
 export const MOUNT = 'loginMount/MOUNT'
 
 const initState: { mount: number } = {
    mount: 0
 }
 
 interface ActionProps {
     type: string;
     mount: number;
 }
 
 export default (state = initState, action: ActionProps) => {
     switch(action.type) {
         case MOUNT:
             return {
                mount: action.mount
             }
         default:
             return state
     }
 }