/**
 * ip 중복 미허용일경우 응답자 체크해서 리턴
 * 설문 종료 날짜 체크
 */
import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import queryString from 'query-string'
import { GET_SURVEY_POLICY_EMAIL } from 'gql/survey_analysis_users'

export default mode => WrappedComponent => props => {
    const dispatch = useDispatch()

    const location = useLocation()
    const { email='' } = queryString.parse(location.search)
    
    const survey_no = Number(props.survey_no)

    const { data } = useQuery(GET_SURVEY_POLICY_EMAIL, {
        variables: { 
            survey_no,
            email
        },
        onCompleted: (data) => {
           
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    if (!data) return null

    return <WrappedComponent policyType={data.surveyCheckPolicyEmail} {...props}/>
}