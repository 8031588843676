import { makeStyles } from '@material-ui/core/styles'
import { getColorConcentration } from 'utils'

export const useStepIconClass = makeStyles(theme => ({
    root: (props) => {
        const { backgroundcolor } = props

        // 색 농도 
        const concent = getColorConcentration(backgroundcolor)

        return {
            backgroundColor: `rgba(${backgroundcolor})`,
            zIndex: 0,
            color: concent ? 'white' : 'black',
            minWidth: 28,
            width: '100%',
            height: 28,
            display: 'flex',
            borderRadius: '3px',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    active: (props) => {
        const { backgroundcolorActive } = props

        // 색 농도 
        const concent = getColorConcentration(backgroundcolorActive)

        return {
            minWidth: 28,
            height: 28,
            backgroundColor: `rgba(${backgroundcolorActive})`,
            color: concent ? 'white' : 'black'
        }
    }
}))