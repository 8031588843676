import gql from 'graphql-tag'

export const GET_USER_ALARM = gql`
    query UserAlarm {
        userAlarm {
            policy
            newsletter
        }
    }
`

export const UPDATE_USER_ALARM_POLICY = gql`
    mutation UpdateUserAlarmPolicy($policy: Boolean!) {
        updateUserAlarmPolicy(policy: $policy)
    }
`

export const UPDATE_USER_ALARM_NEWSLETTER = gql`
    mutation UpdateUserAlarmNewsletter($newsletter: Boolean!) {
        updateUserAlarmNewsletter(newsletter: $newsletter)
    }
`