/**
 * 설문 응답에서 사용되는 에러페이지
 * 언어값은 설문 언어값 기준이된다.
 */
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { LockTwoTone as LockTwoToneIcon, ErrorTwoTone as ErrorTwoToneIcon } from '@material-ui/icons'
import {
    MyContainer,
    Wrapper,
    StyledContainer,
    StyledContent,
    Logo,
    MessageTypography,
    Button
} from './common/ErrorPage'


const Component = () => {
    const { show, f, message, logoHide, iconType, logoHref, buttonName, buttonHref, buttonHide } = useSelector(state => state.answerErrorPage)

    const _logoHref = logoHref || process.env.REACT_APP_LANDING
    const _buttonHref = buttonHref || process.env.REACT_APP_LANDING

    // intl 값이 없을경우 
    const { formatMessage: defaultF } = useIntl()

    const customF = f || defaultF

    if (!show) return null

    return (
        <Wrapper>
            <MyContainer>
                {
                    !logoHide && <Logo href={_logoHref}/>
                }
                <StyledContainer>
                    <StyledContent>
                    {
                        iconType === 'private' ? <LockTwoToneIcon color="secondary" fontSize="large"/> : <ErrorTwoToneIcon color="secondary" fontSize="large"/>                        
                    }
                    </StyledContent>
                    <StyledContent>
                        <MessageTypography>{message || customF({id: 'ccomponent.AnswerErrorPage.message'})}</MessageTypography>
                    </StyledContent>
                    {
                        !buttonHide && (
                            <Button href={_buttonHref}>{buttonName || customF({id: 'ccomponent.AnswerErrorPage.close'})}</Button>
                        )
                    }
                </StyledContainer>
            </MyContainer>
        </Wrapper>
    )
}

export default Component