import { useIntl } from 'react-intl'
import { fileSize } from 'ts-utils'
import Typography from "@material-ui/core/Typography"
import LinearProgress from '@material-ui/core/LinearProgress'
import { Storage } from 'gql/storage'
import useTokenInfo from 'hooks/useTokenInfo'
import { getPercent } from './Component'

interface Props {
    size: Storage['myPhoto'];
}

function MyPhotoComponent(props: Props) {
    const { size } = props

    const token = useTokenInfo()

    const { formatMessage: f } = useIntl()

    return null

    return (
        <>
        <Typography gutterBottom>{f({id: 'component.MyAccount.Storage.MyPhoto.title'})}</Typography>
        <LinearProgress variant="determinate" color="secondary" value={getPercent(size, token.limit_file_size)} style={{maxWidth: 450}}/>
        <Typography component="span" variant="body2">{fileSize(size, 2)} / {fileSize(token.limit_file_size, {round: 0} )}</Typography>
        </>
    )
}

export default MyPhotoComponent