/**
 * 설정이 완료된 필터 데이터 상태 값
 */
import { QuestionOption } from "component/Survey/Create/NewAnalysis/Filter/Component"

export const CHANGE = 'analysis/filter/saveData/CHANGE' // 값 변경시
export const INIT = 'analysis/filter/saveData/INIT'     // 초기 쿠키에서 값 가져올때 component/Survey/Create/NewAnalysis/Filter/Component 컴포넌트에서 최초 로딩

type Type = {
    data: QuestionOption | null; // null 이면, 아직 필터 로딩 안된거라 봐야한다.
}

// order: answer => 보기순, count => 응답순
// pointType: point => 가중치로 계산, count => 응답수로 계산 
const initState: Type = {
    data: null
}

interface ActionProps {
    type: string;
    data: QuestionOption | null;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                data: action.data
            }
        case INIT:
            return initState
        default:
            return state
    }
}