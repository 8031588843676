import { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { 
	CheckCircle as CheckCircleIcon,
	Error as ErrorIcon,
	Info as InfoIcon,
	Warning as WarningIcon
} from '@material-ui/icons'
import { Snackbar, SnackbarContent, Typography, Button } from  '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { HIDE } from 'reducer/alertSnackbar'


const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
}

const useStyles = makeStyles(theme => ({
    iconVariant: props => {
        const { variant } = props
        
        return {
            color: theme.palette[variant].light,
            marginRight: theme.spacing(1)
        }
        
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap'
    }
}));

function MySnackbarContentWrapper(props) {
  
    const { f, className, message, onClose, variant, actions, ...other } = props
    const Icon = variantIcon[variant]

    const classes = useStyles({ variant })

    return (
        <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
            <span id="client-snackbar" className={classes.message}>
                <Icon className={classes.iconVariant}/>
                <Typography variant="body2">
                    {message.split('\n').map((c, i) => (<Fragment key={i}>{c}<br/></Fragment>))}
                </Typography>
            </span>
        }
        action={[
            ...actions,
           
             <Button key="close"  color="secondary"  onClick={onClose}>
                <Typography variant="button">{f({id: 'ccomponent.AlertSnackbar.close'})}</Typography>
            </Button>
        
        ]}
        {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

export default () => {
    const alertSnackbar = useSelector(state => state.alertSnackbar, [])
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return

        dispatch({type: HIDE})
    }

    return (
        <>
        <Snackbar
            anchorOrigin={alertSnackbar.anchor}
            open={alertSnackbar.show}
            autoHideDuration={alertSnackbar.duration}
            onClose={handleClose}
        >
            <MySnackbarContentWrapper
            f={f}
            onClose={handleClose}
            variant={alertSnackbar.variant}
            message={alertSnackbar.message}
            actions={alertSnackbar.actions}
            />
        </Snackbar>
        </>
    );
}
