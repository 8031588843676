import { memo } from 'react'
import Answer from 'styled/Custom/Answer'
import Radio from 'styled/Custom/Radio'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'

function MobileAnswerItemComponent(props) {
    const { selected, answer } = props

    const { formatMessage: f } = useIntl()

    return (
        <>    
        <div><Radio checked={selected}/></div>
        <div>{<Answer>{answer || f({id: 'component.Survey.Modules.03.answer'})}</Answer>}</div>
        </>
    )
}

export default memo(MobileAnswerItemComponent, (prev, next) => {
    return  isEqual(prev.selected, next.selected) && 
            isEqual(prev.answer, next.answer)
})