import { forwardRef } from 'react'
import oc from 'open-color'
import { Bar, Doughnut, Line } from '@reactchartjs/react-chart.js'

function hexToRgbA(hex) {
    let c
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('')
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('')
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)'
    }
    throw new Error('Bad Hex')
}

class chartData {
    constructor(props) {
        const { chartType, data, labels, title, legendPosition=false } = props
        this.data = data
        this.labels = labels
        this.title = title
        this.legendPosition = legendPosition
        this.chartType = chartType

        this.colors = [
            hexToRgbA(oc.blue[4]),
            hexToRgbA(oc.pink[4]),
            hexToRgbA(oc.yellow[4]),
            hexToRgbA(oc.lime[4]),
            hexToRgbA(oc.violet[4]),
            hexToRgbA(oc.indigo[4]),
            hexToRgbA(oc.cyan[4]),
            hexToRgbA(oc.grape[4]),
            hexToRgbA(oc.green[4]),
            hexToRgbA(oc.red[4]),
            hexToRgbA(oc.orange[4]),
            hexToRgbA(oc.teal[4]),
            hexToRgbA(oc.gray[4])
        ]

        this.options = {
            legend: {
                display: legendPosition ? true : false,
                position: legendPosition
            },
            maintainAspectRatio: false,
            responsive: true,
            title: {
                display: title ? true : false,
                text: title
            }
        }
    }

    // 데이터중 제일 큰갑 찾기
    getMax() {
        return Math.max(...this.data.map(c => Number(c)))
    }

    // 데이터 값
    getDatas(backgroundColor) {
        return {
            datasets: [{
                data: this.data,
                backgroundColor
            }],
            labels: this.labels
        }
    }

    // 축 설정
    optionScale() {
        const suggestedMax = this.getMax()
        return {
            scales: {
                yAxes: [{
                    ticks: {
                        suggestedMin: 0,
                        suggestedMax: suggestedMax < 10 ? 10 : suggestedMax
                    }
                }],
                xAxes: [{
                    ticks: {
                        callback: function(value) {
                            return value.substr(0, 10)
                        }
                    }
                }]
            }
            
        }
        
    }

    pie() {
        const backgroundColor = this.data.map((c, i) => this.colors[i])
 
        const datas = this.getDatas(backgroundColor)

        const options = { ...this.options }

        return { datas, options }
    }

    bar() {
   
        const backgroundColor = new Array(this.data.length).fill(this.colors[0])

        const datas = this.getDatas(backgroundColor)

        const options = { 
            ...this.options,
            ...this.optionScale() 
        }

        return { datas, options }
    }

    get() {
        switch(this.chartType) {
            case 'doughnut':
            case 'pie':
                return this.pie()
            default:
                return this.bar()
        }
    }
}

export default forwardRef((props, refs) => {
    const { chartType, id, ...other } = props

    const componentsMap = { bar: Bar, doughnut: Doughnut, line: Line }

    const DynamicComponent = componentsMap[chartType];

    const _chartData = new chartData({chartType, ...other})
    const { datas, options } = _chartData.get()

    return (
        <DynamicComponent id={id} data={datas} options={options}/>
    )
})