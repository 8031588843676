import { Checkbox, TableCell, TableSortLabel } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const WhiteCheckbox = withStyles({
    root: {
      color: grey[400]
    }
})(props => <Checkbox color="default" {...props} />)


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 14,
        fontFamily: "Roboto"
    },
    body: {
        fontSize: 15
    },
}))(TableCell)


const StyledTableSortLabel = withStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        fontSize: 14,
        '&:hover': {
            color: theme.palette.common.white,
        }
    },
    active: {
        color: 'white !important',
    },
    icon: {
        color: 'white !important'
    }
}))(TableSortLabel)


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750
    },
    tableWrapper: {
        overflowX: 'auto'
    }
}))


export {
    WhiteCheckbox,
    StyledTableCell,
    StyledTableSortLabel,
    useStyles
}