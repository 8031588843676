import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { CREATE_OTP, VERIFY_OTP } from 'gql/otp'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { parseHtmlIntl } from 'ts-utils'
import { replaceNotNumber, postMessage } from 'utils'
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Paper,
    TextField
} from '@material-ui/core'
import { PaperProps } from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import useTokenInfo from 'hooks/useTokenInfo'
import useUpdateToken from 'hooks/useUpdateToken'
import { Open, OnClose } from './Otp'


interface Props {
    open: Open;
    qrcode: string;
    goal: boolean;
    onClose: OnClose;
    refetch: () => void;
    breakpoints: BreakPointsProps;
}

export const QrGridItem = withStyles(theme => ({
    root:(props: { kind: string }) => {
        const { kind } = props

        return {
            paddingLeft: 30,
            paddingTop: kind === 'content' ? 10 : 0,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 12,
                paddingTop: 20
            }
        }
    }
}))(Grid)

interface QrPaperProps extends PaperProps {
    qrcode: string
    id: string
}

export const QrPaper = withStyles({
    root: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    }
})((props: QrPaperProps) => {
    const { qrcode, id, ...other } = props

    return (
        <Paper {...other}>
            <img src={qrcode} id={id} alt='qrcode' style={{maxWidth: '100%', display: 'block'}}/>
        </Paper>
    )
})

function OtpQrModalComponent(props: Props) {
    const { open, qrcode, goal, refetch, onClose, breakpoints } = props

    const { email } = useTokenInfo()

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const updateToken = useUpdateToken()

    const { xs } = breakpoints

    const [ code, setCode ] = useState<number | ''>('')

    const [ error, setError ] = useState(false)

    const [ createOtp ] = useMutation(CREATE_OTP, {
        onCompleted: () => {
            refetch()
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ verifyOtp ] = useMutation(VERIFY_OTP, {
        onCompleted: (data) => {
            updateToken()
            
            if (!data.verifyOtp) {
                setError(true)
                return
            }

            refetch()
            onClose()
            
            // 아이콘 클릭 하고 들어오면 흰색배경으로 다시 바꿔주기
            if (goal) postMessage({ push: '#code=info' })
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.target

        const newValue = replaceNotNumber(value)
        setCode(newValue)

        if (error) setError(false)
    }

    function handleVerify() {
        verifyOtp({
            variables: {
                code: Number(code)
            }
        })
    }

    useEffect(() => {
        if (open && !qrcode) {
            createOtp()
        }
    }, [open, qrcode, createOtp])

    useEffect(() => {
        if (!open) setCode('')
    }, [open])
    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={xs}>
            <DialogTitle id="myaccount-change-password-google">{f({id: 'component.MyAccount.Info.OtpQrModal.title'})}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sm={4} md={4}>
                        {
                            qrcode && <QrPaper id="qrcode" qrcode={qrcode}/>
                        }
                   </Grid>
                   <QrGridItem kind="create" item xs={12} sm={8} md={8}>
                        <ol>
                            <li>
                                {parseHtmlIntl(f({id: 'component.MyAccount.Info.OtpQrModal.list.0'}))}
                            </li>
                            <li style={{marginTop: 10}}>
                                구글 OTP 앱 실행 후 QR 코드 스캔을 이용하여 현재 화면 좌측의 QR 이미지를 스캔해주세요
                            </li>
                            <li style={{marginTop: 10}}>
                                {parseHtmlIntl(f({id: 'component.MyAccount.Info.OtpQrModal.list.1'}, {email}))}
                            </li>
                        </ol>
                        <TextField 
                            error={error}
                            fullWidth 
                            placeholder={f({id: 'component.MyAccount.Info.OtpQrModal.placeholder'})}
                            value={code} 
                            inputProps={{
                                inputMode: 'numeric',
                                maxLength: 6
                            }} 
                            style={{marginTop: 10}}
                            onChange={handleChange}
                            helperText={error ? f({id: 'component.MyAccount.Info.OtpQrModal.error'}) : ''}
                        />
                   </QrGridItem>
               </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={String(code).length !== 6} onClick={handleVerify} color="primary">
                    <Typography variant="button">{f({id: 'component.MyAccount.Info.OtpQrModal.button.save'})}</Typography>
                </Button>
                <Button onClick={onClose} color="inherit">
                    <Typography variant="button">{f({id: 'component.MyAccount.Info.OtpQrModal.button.close'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default withBreakPoints(OtpQrModalComponent)