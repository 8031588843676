import { memo } from 'react'
import { useIntl } from 'react-intl'
import ConfirmComponent from 'component/Survey/Edit/Confirm'
import {
    SurveyEditTabsComponent,
    SurveyEditTabComponent,
    SurveyEditQuestionComponent,
    SurveyEdit01ExampleComponent, 
    SurveyEdit01OptionComponent,
    SurveyEditSubTabsComponent
} from 'component'

const Component = (props) => {
    const { method } = props

    const { formatMessage: f } = useIntl()
    const tabs = [f({id: 'container.Survey.Edit.01.tabs.0'}), f({id: 'container.Survey.Edit.01.tabs.1'})]
    const subTabs = [f({id: 'container.Survey.Edit.01.subTabs.0'})]
    if (method === 'one') subTabs.push(f({id: 'container.Survey.Edit.01.subTabs.1'}))

    return (
        <>
            <SurveyEditTabsComponent tabs={tabs}/>
            <SurveyEditTabComponent index={0}>
                <SurveyEditQuestionComponent/>
                <SurveyEditSubTabsComponent subTabs={subTabs}/>
                <SurveyEdit01ExampleComponent/>
                <ConfirmComponent index={1}/>
            </SurveyEditTabComponent>
            <SurveyEditTabComponent index={1}>
                <SurveyEdit01OptionComponent/>
            </SurveyEditTabComponent>
        </>
    )
}

export default memo(Component)