import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import { DELETE_SURVEY_ANALYSIS_USER_FILES_USER } from 'gql/survey_analysis_users'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    open: boolean;
    refetch: () => void;
    onClose: () => void;
    breakpoints: BreakPointsProps;
}

function FileRemoveDialogComponent(props: Props) {
    const { open, refetch, onClose, breakpoints } = props

    const { xs } = breakpoints

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ deleteFilesUser ] = useMutation(DELETE_SURVEY_ANALYSIS_USER_FILES_USER, {
        onCompleted: () => {
            refetch()
            onClose()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            onClose()
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleDelete() {
        dispatch({ type: BACKDROPSHOW })
        deleteFilesUser()
    }

    return (
        <Dialog open={Boolean(open)} fullScreen={xs} onClose={onClose}>
            <DialogTitle>{f({id: 'component.MyAccount.Storage.FileRemoveDialog.title'})}</DialogTitle>
            <DialogContent>
                <Typography component="div">
                {parseHtmlIntl(f({id: 'component.MyAccount.Storage.FileRemoveDialog.content'}))}
                </Typography>

            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleDelete}>
                    <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.button.0'})}</Typography>
                </Button>
                <Button onClick={onClose}>
                    <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.button.1'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default memo(withBreakPoints(FileRemoveDialogComponent))