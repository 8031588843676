import { memo } from 'react'
import { SurveyMboxDesignProps } from 'gql/survey_mbox_design'
import { SurveyMboxCoverProps } from 'gql/survey_mbox_cover'
import { Levels } from 'ts-policy'
import useTokenInfo from 'hooks/useTokenInfo'
import withSurveyFetchData from 'hoc/Survey/FetchData'
import CoverComponent, { Pos } from 'component/Survey/Modules/Cover'
import MyContainer from './My'
import TemplateContainer from './Template'


interface Props {
    open: boolean;
    onClose: () => void;
    template: boolean;
    propsFetchData: {
        mboxDesign: SurveyMboxDesignProps;
        mboxCover: SurveyMboxCoverProps;
    };
}

// 미리보기에 들어가는 커버
function CoverContainer(props: Props) {
    const {  propsFetchData, open, onClose, template, ...other } = props
    const { mboxDesign, mboxCover } = propsFetchData
    const { used, src, animation } = mboxCover
    const token = useTokenInfo()
    const { levels } =  token

    return (
        <>
        {
            template ? (
                <TemplateContainer open={open} onClose={onClose} {...other}/> 
            ) : (
                <>
                <CoverComponent levels={levels} pos={Pos.Preview} brand={{ used, src, animation }} design={mboxDesign}/>
                <MyContainer open={open} onClose={onClose} {...other}/>
                </>
            ) 
        }
        </>
    )
}

const fetchDataArgs = {
    mboxDesign: true,
    mboxCover: true
}

export default withSurveyFetchData('live')(fetchDataArgs)(false)(memo(CoverContainer))