import { fileSize } from 'ts-utils'
import styled from 'styled-components'
import { 
    AssignmentTurnedIn as AssignmentTurnedInIcon, 
    Publish as PublishIcon, 
    ContactSupport as ContactSupportIcon
} from '@material-ui/icons'
import { Button, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import Tooltip from 'styled/Custom/Tooltip'

const Row2Styled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin-top: 3px;

    & > div:first-child > * + * {
        margin-left: .5em;
    } 

    & > div:last-child {
        align-self: center;
    }   
`

function ImageComponent({acceptExts, imageOneSize, fileOneSize, onImage, isAppend}) {
    const { formatMessage: f } = useIntl()

    return (
        <Row2Styled>
            <div>
                {
                    isAppend && (
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            startIcon={<AssignmentTurnedInIcon/>}
                            onClick={onImage.append}
                        >
                            <Typography variant="button">
                                {f({id: 'component.Image.Component.Button.append'})}
                            </Typography>
                        </Button>
                    )
                }
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={<PublishIcon/>}
                    component="label"
                >
                    <Typography variant="button">
                        {f({id: 'component.Image.Component.Button.upload'})}
                    </Typography>
                    
                    <input accept={acceptExts} type='file' onChange={(e) => onImage.create(e)} style={{display: 'none'}}/> 
                </Button>
            </div>
            <div>
                <Tooltip isMobileClick={true} title={
                    `${f({id: 'component.Image.Component.imageOneSize'})}: <b>${fileSize(imageOneSize)}</b>
                    ${f({id: 'component.Image.Component.fileOneSize'})}: <b>${fileSize(fileOneSize)}</b>`
                }>
                    <ContactSupportIcon color="action" style={{display: 'block'}}/>
                </Tooltip>
            </div>
        </Row2Styled>          
    )
}

export default ImageComponent