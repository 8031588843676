// 에디트 편집부분에서도 체크값이 표기되야해서 hoc로 
import { useState, useCallback } from 'react'
import moment from 'moment'

const initialState = {
    replyComments: [],
    ending: false,
    time: moment()
}

export default WrappedComponent => props => {
    
    // 코멘트답변값(보기별 코멘트 제외, 질문에 붙는 코멘트)
    const [ replyComments, setReplyComments ] = useState([])

    // 완료일때는 ending 보여준다
    const [ ending, setEnding ] = useState(undefined)

    //  설문 문항 or 페이지가 바뀔때마다 time값을 넣어준다
    const [ time, setTime ] = useState(initialState.time)
    
    const changeTime = useCallback(() => {
        setTime(moment())
    }, [])

    const propsSetState = {
        replyComments,
        ending,
        time,
        setReplyComments,
        setEnding,
        changeTime
    }

    return <WrappedComponent propsSetState={propsSetState}  {...props}/>
}