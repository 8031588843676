import ReactCountryFlag from 'react-country-flag'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    flag: {
        borderRadius: '2px'
    }
}))

export default (props) => {

    const { code, ...other } = props

    const classes = useStyles()

    return (
        <ReactCountryFlag
            countryCode={code}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={code}
            className={classes.flag}
            {...other}
        /> 
    )
}