import { useLocation } from 'react-router-dom'
import DrawerOuter from 'styled/DrawerOuter'
import { Button, Typography, ListItemText as MuiListItemText } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'

// 공통으로 사용되는 컴포넌트 모음
export const ListItemText = withStyles({
    primary: {
        wordBreak: 'break-all'
    },
    secondary: (props) => {
        const { secondary_margin_right=100 } = props
        return {
            wordBreak: 'break-all',
            marginRight: secondary_margin_right
        }
    }
})(MuiListItemText)


export const CustomButton = withStyles(theme => ({
    root: {
        width: 130
    }
}))(props => (
    <Button variant="contained" size="small" {...props}>
        <Typography variant="button">
            {props.children}
        </Typography>
    </Button>
))

export const SecondaryTypography = withStyles({
    root: {
        fontWeight: 500
    }
})(props => <Typography variant="body2" color="textSecondary" component="span" {...props}/>)

export const useCommonStyles = makeStyles(theme => ({
    icon: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))

const Component = () => {
    const location = useLocation()

    return (
        <DrawerOuter kind="myaccount" src={`/myaccount/frame${location.hash}`}/>
    )
}

export default Component