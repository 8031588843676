// 결제페이지는 타입스크립트를 사용하는데 현재 컴포넌트에서 에러가 나서 타입을 지정해줌
// 추후 온전한 tsx 파일로 변경하자

import { useMemo, useEffect, useCallback, ReactNode, ReactChildren, ReactChild } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingLine } from 'ccomponent'
import { OuterComponent } from 'component'
import UpdateLangTimeAreaContainer from './UpdateLangTimeArea'


interface Props {
    toolbar: ReactNode, 
    toolbarLine: Boolean | undefined, 
    backgroundcolor: string, 
    color: string, 
    children: ReactChild | ReactChildren
}

export default (props: Props) => {
    const { toolbar, toolbarLine, backgroundcolor='white', color, children } = props

    const history = useHistory()
   
    const handleLocation = useCallback((path) => {
        setTimeout(() => history.push(path), 500)
    }, [history])

    useEffect(() => {
        document.body.style.backgroundColor = backgroundcolor
    }, [backgroundcolor])

    return (
        <>
        {
            useMemo(() => {
                return (
                    <OuterComponent toolbar={toolbar} toolbarLine={toolbarLine} color={color} onLocation={handleLocation}>
                        <LoadingLine/>
                        {children}
                    </OuterComponent>
                )
            }, [children, toolbar, toolbarLine, color, handleLocation])
        }
        <UpdateLangTimeAreaContainer/>
        </>
    )
}
    
