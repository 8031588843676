import { useEffect, useCallback } from 'react'
import { 
    PermIdentity as PermIdentityIcon,
    MailOutline as MailOutlineIcon,
    GTranslate as GTranslateIcon,
    Payment as PaymentIcon,
    NotInterested as NotInterestedIcon,
    CloudUpload as CloudUploadIcon
} from '@material-ui/icons'
import { useIntl } from 'react-intl'
import Drawer from 'styled/Drawer'

function getSubHeaders(f) {
    return [
        f({id: 'component.MyAccount.Frame.header.account'}),
        f({id: 'component.MyAccount.Frame.header.function'}),
        f({id: 'component.MyAccount.Frame.header.payment'}),
        ''
    ]
}

function getItems(f) {
    return [
        [
            {
                key: 'info',
                primary: f({id: 'component.MyAccount.Frame.primary.info'}),
                icon: PermIdentityIcon,
                isPadding: true
            }
        ],
        [
            {
                key: 'alarm',
                primary: f({id: 'component.MyAccount.Frame.primary.alarm'}),
                icon: MailOutlineIcon,
                isPadding: true
            },
            {
                key: 'language',
                primary: 'Language',
                icon: GTranslateIcon,
                isPadding: true
            },
            {
                key: 'storage',
                primary: f({id: 'component.MyAccount.Frame.primary.storage'}),
                icon: CloudUploadIcon,
                isPadding: true
            }
        ],
        [  
            {
                key: 'paylist',
                primary: f({id: 'component.MyAccount.Frame.primary.paylist'}),
                icon: PaymentIcon,
                isPadding: true
            }
        ],
        [
            {
                key: 'fired',
                secondary: f({id: 'component.MyAccount.Frame.secondary.fired'}),
                icon: NotInterestedIcon,
                isPadding: true
            }
        ]
    ]
}

const Component = props => {
    const { selected, onChange, children } = props

    const { formatMessage: f } = useIntl()

    const subheaders = getSubHeaders(f)

    const items = getItems(f)

    const isKey = useCallback(() => {
        const length = items.length
        let flag = false
        for (let i=0; i<length; i++) {
            if (items[i].find(c => c.key ===selected)) {
                flag = true
                break
            }
        }

        return flag
    }, [items, selected])


    useEffect(() => {
        if (!isKey()) {
            onChange()
        }
    }, [isKey, onChange])

    return <Drawer subheaders={subheaders} items={items} selected={selected} onChange={onChange}>{children}</Drawer>
}

export default Component