export const CHANGE = 'survey/create/open/CHANGE'
export const INIT = 'survey/create/open/INIT'

const initState = {
    open: false,
    end_date: '',
    end_date_used: false
}

export default (state = initState, action: { type: string, open: boolean, end_date: number | '', end_date_used: boolean }) => {
    switch(action.type) {
        case CHANGE:
            return {
                open: action.open,
                end_date: action.end_date,
                end_date_used: action.end_date_used
            }
        case INIT:
            return initState
        default:
            return state
    }
}