import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const ListItemSub = withStyles({
    root: {
        background: 'white',
        borderRadius: 10,
        padding: 10,
        width: '100%'
    }
})(props => {
    const { open, ...other } = props
    return (
        <div style={{
            display: open ? 'block' : 'none',
            padding: '0 22px 0 13px', 
            marginBottom: 10
        }}>
            <Box {...other}/>
        </div>
    )
})