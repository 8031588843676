import { useCallback } from "react"

const domain = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_LANDING_DOMAIN}`

const useLandingPage = () => {

    const move = useCallback(() => {
        return domain
    }, [])
    
    return move
}
 
export default useLandingPage
