import { memo, useContext, useMemo } from 'react'
import Answer from 'styled/Custom/Answer'
import { Rowscontex } from './Base'

interface Props {
    str: string;
}

/**
 * 로우 열
 */
function RowsComponent(props: Props) {
    const { str } = props

    const { scrollPos, answerConcent } = useContext(Rowscontex)

    let minWidth = str.length * 20

    if (minWidth > 100) minWidth = 100
    else if (minWidth < 5) minWidth = 5

    const styles = scrollPos === 0 ? {
        backgroundColor: 'transparent'
    } : {
        backgroundColor: answerConcent ? 'rgba(180, 180, 180, 1)' : 'rgba(150, 150, 150, 1)',
        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
    }

    return (
        <div style={{
            minWidth,
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            ...styles
        }}>
            {
                useMemo(() => {
                    return <Answer>{str}</Answer>
                }, [str])
            }
        </div>

    )
}

export default memo(RowsComponent)