import styled from 'styled-components'

export const Circul = styled.div<{ width: string, height: string, color: string }>`
    width: ${props => props.width};
    height: ${props => props.height};
    border: 1px solid ${props => props.color};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`