import { memo, useEffect } from 'react'
import useOptionButtons from '../17/OptionButtons'
import { getReplysKeyOfValue } from '../01/Component'
import { handleChangeOrders } from '../17/Items'
import ItemComponent from './Item'
import { WrapperStyled } from 'styled/Survey/Img'

function ItemsComponent(props) {
    const { survey_question_no, answers, ranks, reply, is_comment_placeholder, mobile_one, required, required_end, refComment, answerColor, onChange, onChangeReset } = props

    const [ OptionButtonsComponent, { order, reset } ] = useOptionButtons(survey_question_no, answerColor)

    // survey_module_answer_no를 key로 사용
    const ranksKeyOfValue = getReplysKeyOfValue('survey_module_no', ranks)

    // survey_module_answer_no를 key로 사용
    const replysKeyOfValue = getReplysKeyOfValue('survey_module_answer_no', reply)

    // 정렬된값
    const rows = handleChangeOrders(order, ranks, answers, reply)

    useEffect(() => {
        if (reset) {
            onChangeReset()
        }
    }, [reset, onChangeReset])

    return (
        <>
        <OptionButtonsComponent/>
        <WrapperStyled mobile_one={mobile_one}>
            {
                rows.map((c, i) => {
                    const { answer, src, survey_module_answer_no, is_comment } = c
                    
                    const myReply = replysKeyOfValue[survey_module_answer_no]

                    return (
                        <div key={`_18-${survey_module_answer_no}`}>
                            <ItemComponent
                               key={survey_module_answer_no}
                               survey_module_answer_no={survey_module_answer_no} 
                               is_comment={is_comment}
                               reply={reply}
                               ranksKeyOfValue={ranksKeyOfValue}
                               myReply={myReply}
                               ranks={ranks}
                               answer={answer}
                               src={src} 
                               mobile_one={mobile_one}
                               index={i} 
                               is_comment_placeholder={is_comment_placeholder}
                               required={required}
                               required_end={required_end}
                               refComment={refComment}
                               answerColor={answerColor}
                               onChange={onChange}
                            />
                        </div>
                    )
                })
            }    
        </WrapperStyled>
        </>
    )
}

export default memo(ItemsComponent)