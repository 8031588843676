import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Tooltip from 'styled/Custom/Tooltip'
import { useIntl } from 'react-intl'
import { SHOW as  ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { isMobile } from 'react-device-detect'
import { withPrevState, withBreakPoints } from 'hoc'

function RequiredButtonComponent(props) {
    const { required, onChange, usePrevState, breakpoints } = props

    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const prevRequired = usePrevState(required)

    const message = f({id: `component.Survey.Preview.Component.required.${required ? 0 : 1}`})

    useEffect(() => {
        if (isMobile) {
            if (prevRequired !== undefined && required !== prevRequired) {
                dispatch({type: ALERTSNACKBARSHOW, variant: 'info', message, duration: 4000 })
            }
        }
    }, [message, required, prevRequired, dispatch])

    return (
        <Tooltip title={message}>
            <Button 
                size={xs ? 'small' : 'medium'}
                style={{ marginRight: 21, marginTop: xs ? 0 : 0 }}
                variant="contained" 
                disableElevation
                startIcon={required ? <CheckBoxIcon fontSize="small" color="primary" /> : <CheckBoxOutlineBlankIcon fontSize="small" />} 
                onClick={onChange}
            >
                {f({id: 'component.Survey.Preview.Component.button.vaild'})}
            </Button>
        </Tooltip>        
    )
}

export default withBreakPoints(withPrevState(RequiredButtonComponent))