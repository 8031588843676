import { useEffect, useContext } from 'react'
import PropsContext from 'context/props'

export default root => WrappedComponent => props => {
    const { states, refExamples, refAnswers, refColumns } = useContext(PropsContext)

    useEffect(() => {
        if (states.focus.type === 'examples' && root === 'examples') {
            refExamples.current[states.focus.indexs].focus()
        } else if (states.focus.type === 'answers' && root === 'answers') {
            refAnswers.current[states.focus.indexs].focus()
        } else if (states.focus.type === 'columns' &&  root === 'columns') {
            refColumns.current[states.focus.indexs].focus()
        } else if (states.focus.type === 'answers' && root === 'examples') { // 이미지 순위형
            refAnswers.current[states.focus.indexs].focus()
        }

    }, [states.focus, refExamples, refAnswers, refColumns])
    
    return <WrappedComponent {...props}/>
}