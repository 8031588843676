/**
 * 응답페이지 개인별 응답 데이터
 */
export const CHANGE = 'analysis/rows/personal/CHANGE' // 값 변경시
export const INIT = 'analysis/rows/personal/INIT'

export type StandardAnswer = {[key: string]: {
    choice: string[][],
    comment: object,
    typename: string
}}

export type StandardFile = {[key: string]: {
    src: string; 
    filename: string; 
    survey_analysis_users_no: number; 
    survey_question_no: number;
}[]}

type Type = {
    answer: StandardAnswer | null,
    files: StandardFile;
}

// order: answer => 보기순, count => 응답순
// pointType: point => 가중치로 계산, count => 응답수로 계산 
const initState: Type = {
    answer: null,
    files: {}
}
 
interface ActionProps {
    type: string;
    answer: Type['answer'];
    files: StandardFile;
}
 
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                answer: action.answer,
                files: action.files
            }
        case INIT:
            return initState
        default:
            return state
    }
}