import { memo } from 'react'
import { getMaxlength } from 'policy'
import ItemComponent from './Item'
import { CommnetStyled } from 'styled/Survey/Img'
import { isEqual } from 'lodash-es'
import { InputRef } from '../01/Item'

function ItemsComponent(props) {
    const { lists, reply, icon_type, mobile_one, is_comment_placeholder, refComment, answerColor, onChange } = props

    return (
        <>
            {
                lists.map(c => {
                    
                    const { answer, src, survey_module_no, is_comment } = c

                    const selfReply = reply.find(d => d.survey_module_no === survey_module_no)
                            
                    let selected = false
                    let comment = ''
                    let isJust = false

                    if (selfReply) {
                        selected = true
                        comment = selfReply.comment
                        isJust = selfReply.isJust
                    }

                    return (
                        <div key={`_12-${survey_module_no}`}>
                            <ItemComponent
                                answer={answer}
                                src={src}
                                icon_type={icon_type}
                                mobile_one={mobile_one}
                                selected={selected}
                                isJust={isJust}
                                answerColor={answerColor}
                                survey_module_no={survey_module_no} 
                                onChange={onChange}
                            />
                            {is_comment && (
                                <CommnetStyled>
                                    <InputRef
                                        defaultValue={comment}
                                        maxLength={getMaxlength('component.Survey.Modules.12.comment')}
                                        disabled={!selected}  
                                        placeholder={is_comment_placeholder}
                                        ref={ref => refComment.current[survey_module_no] = ref}
                                    />
                                </CommnetStyled>
                                
                            )} 
                        </div>
                    )
                })
            }
        </>
    )
}

export default memo(ItemsComponent, (prev, next) => {
    return  isEqual(prev.lists, next.lists) && 
            isEqual(prev.reply, next.reply) && 
            isEqual(prev.icon_type, next.icon_type) && 
            isEqual(prev.mobile_one, next.mobile_one) && 
            isEqual(prev.is_comment_placeholder, next.is_comment_placeholder) && 
            isEqual(prev.refComment, next.refComment) && 
            isEqual(prev.answerColor, next.answerColor) &&
            isEqual(prev.onChange, next.onChange)
})