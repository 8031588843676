import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { getRGBA } from 'utils/analysis'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardActionArea, CardActions, CardContent, CardMedia, IconButton, Divider } from '@material-ui/core'
import { ZoomIn as ZoomInIcon } from '@material-ui/icons'
import Answer from 'styled/Custom/Answer'
import IconComponent from 'styled/Custom/Icon'

const useStyles = makeStyles(theme => ({
    root: (props) => {
        const { answerColor } = props

        const backgroundColor = getRGBA({ color: answerColor, opacity: .01 })
        const borderColor = getRGBA({ color: answerColor, opacity: .1 })

        return {
            width: '100%',
            height: '100%',
            backgroundColor,
            backdropFilter: 'blur(20px)',
            borderColor,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }        
    },
    media: (props) => {
        const { mobile_one } = props

        return {
            backgroundSize: 'contain',
            width: '100%',
            height: 250,
            borderRadius: 3,
            [theme.breakpoints.down('xs')]: {
                height: mobile_one ? 250 : 180
            }
        }
    },
    divider: (props) => {
        const { answerColor } = props

        const backgroundColor = getRGBA({ color: answerColor, opacity: .03 })

        return {
            backgroundColor
        }

    },
    cardActionArea: ({ disabled }) => {

        return {
            cursor: disabled ? 'default' : 'pointer'
        }
    },
    cardContent: {
        padding: '8px 5px 0 10px',
        height: '100%'
    },
    cardCActions: () => {
        return {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,
            margin: 0,
            backgroundColor: 'transpatent'
        }

    }
}))

const CommnetStyled = styled.div`
    margin-top: .3em;
`
const WrapperStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    --gap: .6em;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    & > * {
        margin: var(--gap) 0 0 var(--gap);
    }

    & > div {
        display: flex;
        flex-direction: column;
        width: calc((100% / 2) - var(--gap));
        min-width: 190px;

        @media only screen and (max-width: 960px) {
            width: calc((100% / 2) - var(--gap));
            min-width: initial;
        
        }
        @media only screen and (max-width: 600px) {
            /* rows1 개씩 보여줄때 사용 */
            ${({ mobile_one }) => mobile_one && css`
                width: 100%;
                justify-content: center;  
                min-width: initial;
            `};
        
        }
    }
`
const ContentLongStyled = styled.div`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
`



const defaultImage = 'https://images.unsplash.com/photo-1531685250784-7569952593d2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNjgwMTR8MHwxfHNlYXJjaHw2NHx8d2hpdGV8ZW58MHwwfHx8MTYyMjE4MzIyMw&ixlib=rb-1.2.1&q=80&w=1080'

const CardStyled = (props) => {
    const { src, mobile_one, answer, disabled=false, onChange, onChangeZoomImage, children, className, answerColor } = props

    const classes = useStyles({ disabled, mobile_one, answerColor })

    const _src = src || defaultImage

    const handleChangeZoomImage = useCallback(event => {
        event.stopPropagation()
        onChangeZoomImage(_src)
    }, [onChangeZoomImage, _src])

    return (
        <Card className={`${classes.root} ${className}`} variant="outlined" onClick={onChange}>
            <CardActionArea className={classes.cardActionArea}>
            <CardMedia component="div" className={classes.media} image={_src} src="img"/>
            <Divider className={classes.divider}/>
            <CardContent className={classes.cardContent}>
                <ContentLongStyled>
                    <Answer style={{wordBreak: 'break-all'}}>
                        {answer || '    '}
                    </Answer>
                </ContentLongStyled>
            </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardCActions}>
                <IconButton>
                    {children}
                </IconButton>
                <IconButton onClick={handleChangeZoomImage}>
                    <IconComponent size="small"><ZoomInIcon/></IconComponent>
                </IconButton>
            </CardActions>
        </Card>
    )
}
export {
    CommnetStyled,
    WrapperStyled,
    CardStyled,
    defaultImage
}