import { memo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import ItemComponent from './Item'
import { isEqual } from 'lodash-es'

function getItemStyle(draggableStyle, isDragging) {
    return {
        ...draggableStyle,
        ...(isDragging && {
            
        })
    }
}

function Items(props) {
    const { answersOrder, ranks, reply, onChange } = props

    return (
        <div>
        {
            answersOrder.map((c, i) => {
                return (
                    <Draggable key={`${c.survey_module_answer_no}`} draggableId={`draggableId-${c.survey_module_answer_no}`} index={i}>
                        {(providrag, snapshot) => {

                            return (
                                <div ref={providrag.innerRef} {...providrag.draggableProps} {...providrag.dragHandleProps} style={getItemStyle(providrag.draggableProps.style, snapshot.isDragging)}>
                                    <ItemComponent 
                                        key={c.survey_module_answer_no} 
                                        reply={reply} 
                                        ranks={ranks} 
                                        answer={c} 
                                        index={i} 
                                        onChange={onChange}
                                    />                
                                </div>
                            )
    
                        }}
                    </Draggable>
                )
            })    
        }
        </div>
    )
}

export default memo(Items, (prev, next) => {
    return  isEqual(prev.answersOrder, next.answersOrder) &&
            isEqual(prev.ranks, next.ranks) &&
            isEqual(prev.reply, next.reply) &&
            isEqual(prev.onChange, next.onChange)
})
