import { SurveyLiveMultiContainer } from 'container'
import { withSurveyFetchMboxWithRelation, withSurveyFetchQuestions, withSurveyFetchAnalysisUser, withSurveyFetchQuestionsWithJumpMulti } from 'hoc'
import { getPreviewMode } from 'utils/survey'

const mode = getPreviewMode()

const Contents = (props) => (
    <SurveyLiveMultiContainer {...props}/>    
)

export default withSurveyFetchAnalysisUser(mode)
               (withSurveyFetchMboxWithRelation({mode, fetchPolicy: 'network-only'})(true)
               (withSurveyFetchQuestions(mode)
               (withSurveyFetchQuestionsWithJumpMulti({mode, loading: false})(Contents))))