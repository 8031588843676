import htmlParser from 'react-html-parser'
import { ko, ja } from 'date-fns/locale'

export function checkCorporateRegistrationNumber(value:number | string | ''):boolean {
    const numberMap = String(value).replace(/-/gi, '').split('').map(function (d){
		return parseInt(d, 10);
	});
	
	if(numberMap.length === 10){
		const keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
		let chk = 0;
		
		keyArr.forEach(function(d, i){
			chk += d * numberMap[i];
		});
		
		chk += parseInt((keyArr[8] * Number(numberMap[8]))/ 10, 10);

		return Math.floor(numberMap[9]) === ( (10 - (chk % 10) ) % 10);
	}
	
	return false;
}

// 주민 번호 체크 (- 는 빼고 보내야 한다)
export function checkJumin(value: number): boolean {
    const juminRule = /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))[1-8][0-9]{6}$/

    return juminRule.test(String(value))
}

// 생일 유효성 체크
export function checkBirthday(value:number | ''):boolean {
    return /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/.test(String(value))
}

// intl html 적용
export function parseHtmlIntl(msg: any) {
    return htmlParser(msg.replace(/<%/g, '<').replace(/%>/g, '>'))
}

// getIntlLocale
export function getIntlLocale(val: string) {
    if (!val) return 'ko'

    const [ locale ] = val.split('-')

    return locale
}


// 특정 컬럼을 키로 잡는다
export function getKeyOfArray(id: string, rows: any[]) {
    const ids = id.split('.')

    if (!rows) return rows

    return rows.reduce((acc, c) => {
        let key = null
        for (let i=0; i<ids.length; i++) {
            key = key === null ? c[ids[i]] : key[ids[i]]
        }
        
        // 이터럴이 안될경우 빈배열을 준다
        const _acc = acc[key] || []

        return { 
            ...acc,   
            [key]: [
                ..._acc,
                c
            ]
       
        }
    }, {})
}

export const delay = (time: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, time)
    })
}

export function fileSize(bytes: number, pos=0) {
    const Byte = Math.abs(Number(bytes))
    const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB']
    if (Byte === 0) return '0 Byte'

    const i = Math.floor(Math.log(Byte) / Math.log(1024))

    const result = Number(Byte / Math.pow(1024, i)).toFixed(pos) + ' ' + sizes[i]

    return "1000 MB" === result ? "1 GB" : result
}

// 간단한 암호화
export function ListoEncrypt(theText: string) {
	let output = ''
	let Temp = []
	let Temp2 = []
    let rnd = 0
	const TextSize = theText.length

	for (let i = 0; i < TextSize; i++) {
		rnd = Math.round(Math.random() * 122) + 68;
		Temp[i] = theText.charCodeAt(i) + rnd;
		Temp2[i] = rnd;
	}
	for (let i = 0; i < TextSize; i++) {
		output += String.fromCharCode(Temp[i], Temp2[i]);
	}
	return output;
}

export function handleDisabledRightClick(e: Event) {
    e.preventDefault()
}

// { a: 17, b: 13, c: 16} value 기준으로 정렬
export function sortObject(props: { type?: string, obj: object }) {
    const { type='asc', obj } = props

    let sorted = Object.entries(obj).sort(([, a], [, b]) => a - b)
    
    if (type === 'desc') sorted = sorted.reverse()

    return sorted.reduce((r, [k, v]) => ({
        ...r,
        [k]: v
    }),{})
}

// ip4 validate
export const validationIP = (ip: string) => {
    const ipv4_regex = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm

    return ipv4_regex.test(ip)
}

// 인앱 브라우저 인지 체크
export const isInAppBrowser = () => {
    if (!navigator) return false
    if (navigator.userAgent.indexOf('KAKAO') >= 0) return true
    if (navigator.userAgent.indexOf('[FB') >= 0) return true
    if (navigator.userAgent.indexOf('Instagram') >= 0) return true
    if (navigator.userAgent.indexOf('trill') >= 0) return true

    return false
}

export const getLocale = (locale: string) => {
    return locale.search('ko') === 0 ? ko : locale.search('ja') === 0 ? ja : ''
}


export const convertRgbaToRgb = (rgba: string, rgb: string) => {
    const [ r, g, b, a ]: any = rgba.split(',')
    const [ r2, g2, b2 ]: any = rgb.split(',')
    
    const r3 = Math.round(((1 - a) * r2) + (a * r))
    const g3 = Math.round(((1 - a) * g2) + (a * g))
    const b3 = Math.round(((1 - a) * b2) + (a * b))

    return `${r3}, ${g3}, ${b3}, 1`
}

// 파일명 구하기
export const getFilename = (path: string) => {
    if (!path) return ''

    const pathname = String(path).split(/[\\/]/).pop()
    const [ filename ] = pathname ? pathname.split('?') : [ '' ]
    
    return filename
}

// 경로 구하기
export const getPathname = (path: string) => {
    if (!path) return ''
    
    return path.substr(0, path.lastIndexOf("/"))
}
