import { useContext, useMemo, memo } from 'react'
import { Typography, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import summaryContext from 'context/summary'
import { getRGBA } from 'utils/analysis'

const useStyles = makeStyles(theme => ({
    root: props => {
        const { isFirst, color, fontFamily } = props

        return {
            marginTop: isFirst ? 0 : '2em',
            marginBottom: 0,
            color,
            '& *': {
                fontFamily
            }            
        }
    },
    cols: {
        textAlign: 'right',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    }
}))

function Component(props) {
    const { text, cols="", isFirst=true } = props
    const { design } = useContext(summaryContext)

    const color = getRGBA({color: design.question, opacity: .9})

    const classes = useStyles({ isFirst, color, fontFamily: design.font_family })

    // text 주석 처리
    return (
        <>
        {
            useMemo(() => {
                return (
                    <ListItem disableGutters className={classes.root}>
                        <ListItemText><Typography variant="body1" style={{fontWeight: 700}}>{text}</Typography></ListItemText>
                        <ListItemText className={classes.cols}><Typography variant="caption">{cols}</Typography></ListItemText>
                    </ListItem>
                )
            }, [text, cols, classes.root, classes.cols])
        }
        </>
    )
}

export default memo(Component)