import { useContext, useMemo, memo } from 'react'
import PropsContext from 'context/props'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
    message: string
}

const useStyles = makeStyles({
    alert: {
        marginBottom: 5,
        background: 'black'
    },
    message: (props: { font_family: string }) => {
        const { font_family } = props
        return {
            fontFamily: font_family
        }
    }
})

function CautionComponent(props: Props) {
    const { message } = props
    const { design }:any = useContext(PropsContext)
    const { font_family } = design

    const classes = useStyles({ font_family })

    return (
        <>
        {
            useMemo(() => {
                return (
                    <Collapse in={Boolean(message)}>
                        <Alert severity="error" className={classes.alert} elevation={1} variant="filled">
                            <span className={classes.message}>{message}</span>
                        </Alert>
                    </Collapse>
                )
            }, [message, classes.alert, classes.message])
        }
        </>
    )
}

export default memo(CautionComponent)