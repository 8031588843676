export const SHOW = 'confirm/SHOW'
export const HIDE = 'confirm/HIDE'

const initState = {
    show: false,
    fullScreen: false,
    detectiveFullScreen: null,
    title: '',
    subtitle: '',
    content: '',
    child: null,
    closeName: '',
    maxWidth: 'sm',      // xs sm md lg xl
    onClose: '',        // 닫기 후 액션이 있을경우 여기에 function을 담는다
    onActions: []       // 추가적인 액션을 담는다 {on: function, name: string, hide: boolean}
}

export default (state = initState, action) => {

    const fullScreen = action.fullScreen || initState.fullScreen
    const detectiveFullScreen = action.detectiveFullScreen || initState.detectiveFullScreen
    const title = action.title || initState.title
    const subtitle = action.subtitle || initState.subtitle
    const content = action.content || initState.content
    const child = action.child || initState.child
    const closeName = action.closeName || initState.closeName
    const maxWidth = action.maxWidth || initState.maxWidth
    const onClose = action.onClose || initState.onClose
    const onActions = action.onActions || initState.onActions

    switch(action.type) {
        case SHOW:
            return {
                ...state,
                show: true,
                fullScreen,
                detectiveFullScreen,
                title,
                subtitle,
                content,
                child,
                closeName,
                maxWidth,
                onClose,
                onActions
            }
        case HIDE:
            return {
                ...state,
                show: false
            }
        default:
            return state
    }
}