import { memo } from 'react'
import { withSurveyPopoverImage } from 'hoc'
import { ImgStyled } from 'styled/Survey/Analysis/Summary'

function WrapperBarImgMatrixSrcComponent(props) {
    const { src, popoverImageProps } = props
    const { on }= popoverImageProps
    const { open: onOpen } = on
   
    return (
        <ImgStyled src={src} onClick={() => onOpen(src)}/>
    )
}

export default withSurveyPopoverImage(memo(WrapperBarImgMatrixSrcComponent))