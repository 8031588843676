/* 
    sns 공유 버튼
*/
import IconComponent from './Icon'
import { Helmet } from 'react-helmet'

const Component = (props) => {
    return (
        <>
        {
            // 이거 없어도 카카오스토리 공유가 되긴한다.....
        }
        <Helmet>
            <script src="//developers.kakao.com/sdk/js/kakao.min.js"></script>
        </Helmet>
        <IconComponent {...props}/>
        </>
    )
}
 
export default Component
