import { Typography  } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const TitleTypography = withStyles({
    root: {
        fontWeight: 700,
        padding: 0,
        margin: 0

    },
    button: {
        padding: 0,
        margin: 0,
        lineHeight: '1px',
        display: 'inline'
    }
})(props => <Typography variant="button" noWrap={true} {...props}></Typography>)