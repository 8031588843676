import styled from 'styled-components'
import Answer from 'styled/Custom/Answer'

const WrapperStyled = styled.div`
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    overflow-x: auto;
    overflow-y: hidden;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
`

const ListStyled = styled.div`
    display: table;
    width: 100%;

    & > div {
        display: table-row;
    }

    & > div > div {
        display: table-cell;
        word-break: break-all;
        vertical-align: middle;
        height: 4em;
    }

    & > div > div:first-child {
        white-space: pre;
    }

    & > div > div:last-child {
        width: 100%;
    }
`

const LabelStyled = styled.div`
    display: grid;
    align-items: center;
    margin-top: 8px;

    & > div.left-text {
        grid-area: leftText;
        padding-left: ${props => props.pc ? '8px' : 0};
    }

    & > div.right-text {
        grid-area: rightText;
        text-align: right;
        padding-right: ${props => props.pc ? '16px' : 0};
    }

    grid-template-areas: "leftText rightText";
    grid-template-columns: 1fr 1fr;
    gap: 5px;
`

const LabelComponent = (props) => {
    const { pc=false, left_label, right_label } = props
    
    return (
        <LabelStyled pc={pc}>
            <div className="left-text"><Answer variant="body2" style={{lineHeight: '110%', fontSize: 12}}>{left_label}</Answer></div>
            <div className="right-text"><Answer variant="body2" style={{lineHeight: '110%', fontSize: 12}}>{right_label}</Answer></div>
        </LabelStyled>
    )
}

export {
    WrapperStyled,
    LabelComponent,
    ListStyled
}