import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ImageList } from '@material-ui/core'
import withBreakPoints from 'hoc/BreakPoints'
import ItemComponent from './Item'
import ImgItemContext from 'context/imgItem'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflowY: 'hidden',
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        width: '100%',
        height: '100%'
    }
}))

const Component = (props) => {
    const { tabValue, cols: _cols, rows, selected, onChange, onDeleteFile, breakpoints } = props

    const { xs } = breakpoints

    const classes = useStyles()
    
    const cols = _cols ? _cols : xs ? 2 : 3
    const rowHeight = xs ? 171 : 193.16
    
    return (
        <div className={classes.root}>
            <ImgItemContext.Provider value={{onChange}}>
                <ImageList cols={cols} rowHeight={rowHeight} className={classes.gridList} gap={0}>
                    {
                        useMemo(() => {
                            return rows.map(c => {
                            
                                return (
                                    <li key={c.no}>
                                        <ItemComponent {...c} tabValue={tabValue} selected={selected} onDeleteFile={onDeleteFile}/>
                                    </li>
                                )
                            })
                        }, [rows, tabValue, selected, onDeleteFile])
                        
                    }
                </ImageList>
            </ImgItemContext.Provider>
            
        </div>
    )    
}

export default withBreakPoints(Component)