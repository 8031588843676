import { memo, useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import { MyBox } from '../14/Item'
import { ColumnBox, AnswerBox } from '../06/Item'
import { RatingAction } from 'styled/Survey/RatingIcon'
import Column from 'styled/Custom/Column'
import useMount from '../14/useMount'

const defaultValue = { indexs: 0, answer: '' }

function ItemComponent(props) {
    const { 
        index,
        survey_question_no, 
        survey_module_no, 
        column, 
        answers, 
        icon_type,
        mode,
        reply,
        hoverAnswer,
        onChange
    } = props

    const { formatMessage: f } = useIntl()

    const max = answers.length

    // 내용이 한개라도 있느지 체크
    const is_answer = answers.find(c => c.answer)

    const { indexs, answer } = reply.length > 0 ? answers.find(d => {
        const survey_module_answer_no = d.survey_module_answer_no
        return reply.find(e => survey_module_no === e.survey_module_no && survey_module_answer_no === e.survey_module_answer_no) ? true : false
    }) || defaultValue : defaultValue

    let hoverAnswerSelected = ''

    const is = hoverAnswer.find(d => d.survey_module_no === survey_module_no)
    
    if (is) hoverAnswerSelected = is.answer

    const [ _answer, setAnswer ] = useState(answer)

    const mount = useMount(max)

    const handleChange = useCallback((currentIndexs) => {
        const { survey_module_answer_no } = answers.find(c => c.indexs === currentIndexs)
        
        onChange(survey_module_no, survey_module_answer_no)
    }, [answers, survey_module_no, onChange])

    useEffect(() => {
        setAnswer(answer)
    }, [answer])

    if (!mount) return null

    return (
        <>
            <ColumnBox index={index}>
                <Column>{column || f({id: 'component.Survey.Modules.15.column'})}</Column>
            </ColumnBox>
            <AnswerBox>
                <RatingAction
                    key={`${survey_question_no}-${survey_module_no}`}
                    name={`${survey_question_no}-${survey_module_no}-${mode}`}
                    value={indexs}
                    precision={1}
                    max={max}
                    onClick={handleChange}
                    onChangeActive={(indexs) => {
                        if (indexs >= 0) {
                            let newAnswer = ''
                            
                            const is = answers.find(c => c.indexs === indexs)

                            if (is) newAnswer = is.answer

                            setAnswer(newAnswer)
                        } else setAnswer(answer)

                    }}
                    iconType={icon_type}
                />
                
            </AnswerBox>
            <MyBox is_answer={is_answer}>
                {hoverAnswerSelected || _answer}
            </MyBox>
        </>
    )
}

export default memo(ItemComponent, (prev, next) => {
    return  isEqual(prev.index, next.index) && 
            isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.survey_module_no, next.survey_module_no) && 
            isEqual(prev.column, next.column) && 
            isEqual(prev.answers, next.answers) && 
            isEqual(prev.icon_type, next.icon_type) && 
            isEqual(prev.mode, next.mode) && 
            isEqual(prev.reply, next.reply) && 
            isEqual(prev.hoverAnswer, next.hoverAnswer) && 
            isEqual(prev.onChange, next.onChange)
})