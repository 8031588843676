/**
 * survey를 제외한 유저결제 정보 등의 데이터를 가져올때 사용
 */
import { useLazyQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_USER_PAYMENTS, GET_USER_PAYMENT_NON_APPLY } from 'gql/user_payment'
import { UserPaymentsProps, UserPaymentsVariables, UserPaymentNonApplyProps } from 'types/user_payment'

function useUserData() {
    const dispatch = useDispatch()

    const [getUserPayments, { data: dataUserPayments, refetch: refetchUserPayments, loading: loadingUserPayments }] = useLazyQuery<{userPayments: UserPaymentsProps[]}, UserPaymentsVariables>(GET_USER_PAYMENTS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: () => {
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getUserPaymentNonApply, { data: dataUserPaymentNonApply, refetch: refetchUserPaymentNonApply }] = useLazyQuery<{userPaymentNonApply: UserPaymentNonApplyProps | null}>(GET_USER_PAYMENT_NON_APPLY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: () => {
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    return {
        getUserPayments,
        dataUserPayments: dataUserPayments ? dataUserPayments.userPayments : [],
        refetchUserPayments,
        loadingUserPayments,
        getUserPaymentNonApply,
        dataUserPaymentNonApply: dataUserPaymentNonApply ? dataUserPaymentNonApply.userPaymentNonApply : null,
        refetchUserPaymentNonApply
    }
}

export default useUserData