import { memo } from 'react'
import { handleDisabledRightClick } from 'ts-utils'
import styled from 'styled-components'

interface Props {
    blob: Blob | string;
    name: string;
}

interface ImgProps {
    onContextMenu: (e: any) => void
}

const Img = styled.img<ImgProps>`
    max-width: 100%;
    max-height: 350px;
    border-radius: 3px;
    display: block;
`

function Picture(props: Props) {
    const { blob, name } = props

    return (
        <Img src={String(blob)} alt={name} onContextMenu={handleDisabledRightClick}/>
    )
}

export default memo(Picture)