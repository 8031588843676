/**
 * 주소찾기 모듈
 */
import { memo, ChangeEvent, useEffect, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import { getMaxlength } from 'policy'
import Input from 'styled/Custom/Input'
import useValue from '../09/useValue'
import { getIsDisabled } from '../09/Base'
import ConfirmComponent from './Confirm'
import PostCodeComponent from './PostCode'

export interface Props {
    survey_question_no: number;
    isConfirm: boolean;
    originZipcode: string | null;
    originAddress1: string | null;
    zipcode: string | null;
    address1: string | null;
    address2: string | null;
    onChange: (
        { zipcode, address1, address2}: 
        { zipcode: string | null, address1: string | null, address2: string | null }
    ) => void;
    refComment: [HTMLInputElement];
}

export type OnChangeFocus = () => void;

const getAddressValue = (zipcode: string | null, address1: string | null, address2: string | null) => {
    let str = ''
    if (zipcode) str = `${zipcode}${address1}`
    if (address2) str = str ? `${str}${address2}` : address2
    return str
}

function Item(props: Props) {
    const { survey_question_no, isConfirm, originZipcode, zipcode, originAddress1, address1, address2, onChange, refComment } = props

    const { formatMessage: f } = useIntl()

    const [ focus, setFocus ] = useState<number | null>(null)

    const handleChange = (val: string) => {
        onChange({ zipcode, address1, address2: val })
    }

    const [ value, onChangeValue ] = useValue(address2, handleChange)

    const handleChangeFocus = useCallback(() => {
        setFocus(Math.random())
    }, []) 

    useEffect(() => {
        if (focus) {
            if (refComment) {
                if (refComment.current[survey_question_no]) {
                    refComment.current[survey_question_no].focus()
                }
            }
        }
    }, [focus, survey_question_no, refComment])

    return (
        <>
        <PostCodeComponent 
            survey_question_no={survey_question_no}
            zipcode={zipcode} 
            address1={address1}
            address2={address2}
            onChange={onChange}
            onChangeFocus={handleChangeFocus}
        />
        <Input
            multiline
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeValue(e.target.value)}
            inputProps={{maxLength: getMaxlength('component.Survey.Modules.20.comment')}}
            placeholder={f({id: "component.Survey.Modules.20.Item.placeholder"})}
            inputRef={(ref: any) => refComment.current[survey_question_no] = ref}
        />
        {
            isConfirm && (
                <ConfirmComponent 
                    survey_question_no={survey_question_no} 
                    zipcode={zipcode} 
                    address1={address1}
                    address2={value} 
                    isEqualAnswer={getIsDisabled(getAddressValue(String(zipcode), address1, value), getAddressValue(String(originZipcode), String(originAddress1 || ''), address2))}
                />
            )
        }
        </>
    )
}

export default memo(Item, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.isConfirm, next.isConfirm) && 
            isEqual(prev.originZipcode, next.originZipcode) && 
            isEqual(prev.originAddress1, next.originAddress1) && 
            isEqual(prev.zipcode, next.zipcode) && 
            isEqual(prev.address1, next.address1) && 
            isEqual(prev.address2, next.address2) && 
            isEqual(prev.onChange, next.onChange) && 
            isEqual(prev.refComment, next.refComment)
})