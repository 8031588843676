import { MouseEvent, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { Draggable } from 'react-beautiful-dnd'
import oc from 'open-color'
import { 
    DELETE_SURVEY_TEMPLATE_CATEGORY, 
    UPDATE_SURVEY_TEMPLATE_CATEGORY_OPEN,
    DeleteSurveyTemplateCategoryVariables,
    UpdateSurveyTemplateCategoryOpenVariables
} from 'gql/survey_template'
import { SurveyTemplateCategoryProps } from 'gql/survey_template'
import { SHOW as POPOVERSHOW } from 'reducer/popover'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import UpdateCategoryNameComponent from './UpdateCategoryName'

export interface Props extends SurveyTemplateCategoryProps {
    index: number
    refetch: () => void
}

const IconBox = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center', 
        '& > *': {
            width: 17,
            height: 17,
            color: theme.palette.grey[700]
        }
    }
}))(Box)

function CategoryManageItemComponent(props: Props) {
    const { survey_template_category_no, name, open, refetch, indexs } = props
    
    const dispatch = useDispatch()

    const [ updateOpen ] = useMutation<UpdateSurveyTemplateCategoryOpenVariables>(UPDATE_SURVEY_TEMPLATE_CATEGORY_OPEN, {
        onCompleted: () => {
            refetch()
        }
    })

    const [ remove ] = useMutation<DeleteSurveyTemplateCategoryVariables>(DELETE_SURVEY_TEMPLATE_CATEGORY, {
        onCompleted: () => {
            refetch()
        }
    })

    function handleOpenName(e: MouseEvent) {
        dispatch({ 
            type: POPOVERSHOW, 
            component: (
                <UpdateCategoryNameComponent 
                    survey_template_category_no={survey_template_category_no}
                    name={name}
                    refetch={refetch} 
                />
            ),
            anchorEl: e.currentTarget,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            isClose: false
        })
    }


    function handleChangeOpen(e: MouseEvent, open: boolean) {
        e.stopPropagation()
        if (window.confirm(`${open ? '공개' : '비공개'}로 전환하시겠습니까?`)) {
            updateOpen({
                variables: { survey_template_category_no, open }
            })
        }
    }

    function handleRemove(e: MouseEvent) {
        e.stopPropagation()
        if (window.confirm('정말로 삭제하시겠습니까?')) {
            remove({
                variables: { survey_template_category_no }
            })
        }
    }

    return (
        <Draggable key={survey_template_category_no} draggableId={`categoryId-${survey_template_category_no}`} index={indexs}>
             {(providrag, snapshot) => {
                return (
                    <div ref={providrag.innerRef} {...providrag.draggableProps} {...providrag.dragHandleProps}>
                        <div style={{border: `1px solid ${oc.gray[6]}`, borderRadius: 5, padding: 5}}>
                            <OpenWithIcon color="action" fontSize="small"/>
                            <Button 
                                disableElevation 
                                variant="contained" 
                                endIcon={
                                    <div style={{marginLeft: 100}}>
                                    <IconBox>
                                        {
                                            !open ? <LockIcon fontSize="large" onClick={(e) => handleChangeOpen(e, true)}/> : <LockOpenIcon fontSize="large" onClick={(e) => handleChangeOpen(e, false)}/>
                                        }
                                        <DeleteIcon fontSize="large" style={{marginLeft: 10}} onClick={handleRemove}/>
                                    </IconBox>
                                    </div>

                                } 
                                onClick={handleOpenName}
                                fullWidth
                            >
                                {name}
                            </Button>
                        </div>
                    </div>
                )
            }}
    
        </Draggable>
    )
}

export default memo(CategoryManageItemComponent)