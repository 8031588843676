import { memo, createContext, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Randomstring from 'randomstring'
import { useQuery, useLazyQuery } from "@apollo/client"
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_WORKSPACE_GROUPS, GET_SURVEY_WORKSPACE_GROUP_SELECTED, SurveyWorkspaceGroupProps, SurveyWorkspaceGroupSelectedProps, SurveyWorkspaceGroupSelectedVariables } from 'gql/survey_workspace_group'
import { Typography } from "@material-ui/core"
import useTokenInfo from 'hooks/useTokenInfo'
import LayoutContainer from "./Layout"

export interface GroupContextProps {
    data?: { surveyWorkspaceGroups: SurveyWorkspaceGroupProps[] };
    refetch?: () => void;
    survey_workspace_group_no: number;
    onChange: (a: number) => void;
}

export const GroupContext = createContext<GroupContextProps>({
    data: undefined,
    refetch: undefined,
    survey_workspace_group_no: 0,
    onChange: (num) => {}
})

// 로컬스토리지에 저장된 작업함그룹번호
export const getSurveyWorkspaceGroupNo = (user_no: number) => {
    const name = `survey_workspace_group_no-${user_no}`
    return localStorage.getItem(name) ? Number(localStorage.getItem(name)) : null
}

function GroupContainer() {
    const info = useTokenInfo()
    const { user_no } = info

    const dispatch = useDispatch()

    const cookieName = `survey_workspace_group_no-${user_no}`

    const { data, refetch } = useQuery<{ surveyWorkspaceGroups: SurveyWorkspaceGroupProps[] }>(GET_SURVEY_WORKSPACE_GROUPS, {
        fetchPolicy: 'network-only',
        onError: () => {
            dispatch({ type: ERRORORPAGESHOW })
        }
    })

    const [getData, { data: dataSelected, refetch: refetchSelected }] = useLazyQuery<{surveyWorkspaceGroupSelected: SurveyWorkspaceGroupSelectedProps}, SurveyWorkspaceGroupSelectedVariables>(GET_SURVEY_WORKSPACE_GROUP_SELECTED, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            localStorage.setItem(cookieName, String(data.surveyWorkspaceGroupSelected))
        }
    })

    const handleChange:GroupContextProps['onChange'] = useCallback((survey_workspace_group_no) => {
        getData({
            variables: {
                survey_workspace_group_no,
                dummy: Randomstring.generate(8)
            }
        })
    }, [getData])

    // 처음 마운트 시,
    useEffect(() => {
        getData({
            variables: {
                survey_workspace_group_no: getSurveyWorkspaceGroupNo(user_no),
                dummy: Randomstring.generate(8)
            }
        })
    }, [user_no, getData])

    if (!dataSelected || !refetchSelected) return <Typography variant="body2" style={{padding: 20}} color="textSecondary">...Loading</Typography>

    return (
        <GroupContext.Provider value={{ 
            data,
            refetch,
            survey_workspace_group_no: dataSelected.surveyWorkspaceGroupSelected,
            onChange: handleChange
        }}>
            <LayoutContainer survey_workspace_group_no={dataSelected.surveyWorkspaceGroupSelected}/>
        </GroupContext.Provider>
    )

}

export default memo(GroupContainer)