import gql from 'graphql-tag'

export const GET_SURVEY_PAGE = gql`
    query SurveyPage($survey_no: Int!) {
        surveyPage(survey_no: $survey_no) {
            survey_page_no
            indexs
        }
    }
`

// 설문페이지 토큰값 전달
export const GET_SURVEY_PAGE_TOKEN = gql`
    query SurveyPage($survey_no: Int!, $token: ID) {
        surveyPage(survey_no: $survey_no, token: $token) {
            survey_page_no
        }
    }
`

// 다음 페이지 구하기
export const GET_SURVEY_PAGE_JUMP_NEXT = gql`
    query SurveyPageJumpNext($survey_no: Int!, $token: ID!, $survey_page_no: Int!, $method: String, $id: Int, $mode: Mode!) {
        surveyPageJumpNext(survey_no: $survey_no, token: $token, survey_page_no: $survey_page_no, method: $method, id: $id, mode: $mode)
    }
`

// 이전 페이지 구하기
export const GET_SURVEY_PAGE_CLASSIC_BUTTON = gql`
    query SurveyPageClassicButton($survey_no: Int!, $survey_page_no: Int!) {
        surveyPageClassicButton(survey_no: $survey_no, survey_page_no: $survey_page_no) {
            classic_button_name
        }
    }
`

// 이전 페이지 구하기
export const GET_SURVEY_PAGE_CLASSIC_BUTTONS = gql`
    query SurveyPageClassicButtons($survey_no: Int!) {
        surveyPageClassicButtons(survey_no: $survey_no) {
            survey_page_no
            classic_button_name
        }
    }
`

export const UPDATE_SURVEY_PAGE_SORT = gql`
    mutation UpdateSurveyPageSort($input: UpdateSurveyPageSort!) {
        updateSurveyPageSort(input: $input)
    }
`

export const DELETE_SURVEY_PAGE = gql`
    mutation DeleteSurveyPage($survey_no: Int!, $survey_page_no: Int!) {
        deleteSurveyPage(survey_no: $survey_no, survey_page_no: $survey_page_no)
    }
`

export const CREATE_SURVEY_PAGE = gql`
    mutation CreateSurveyPage($survey_no: Int!) {
        createSurveyPage(survey_no: $survey_no)
    }
`


export const CREATE_SURVEY_PAGE_CLASSIC_BUTTON = gql`
    mutation CreateSurveyPageClassicButton($survey_no: Int!, $survey_page_no: Int!, $classic_button_name: String!) {
        createSurveyPageClassicButton(survey_no: $survey_no, survey_page_no: $survey_page_no, classic_button_name: $classic_button_name)
    }
`

export interface SurveyPageProps {
    survey_page_no: number;
    indexs: number;
}

export interface SurveyPageClassicButtonProps {
    survey_page_no: number;
    classic_button_name: string;
}