export const SHOW = 'backdrop/SHOW'
export const HIDE = 'backdrop/HIDE'

const initState = {
    show: false,
    background: '',
    msg: ''
}

const background = {
    dark: 'rgba(0, 0, 0, .92)'
}

export default (state = initState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
                background: action.background ? background[action.background] : '',
                msg: action.msg
            }
        case HIDE:
            return initState
        default:
            return initState
    }
}