import styled from 'styled-components'
import { Container } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const WrapperStyled = styled.div`
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2em;
    padding-bottom: 5em;

    & > div {
        
        width: 720px;
        
        @media only screen and (max-width: 960px) {
            
            width: 100%;
        }
    }
`

export const QuestionStyled = withStyles(theme => ({
    root: {
        padding: '10px 20px',
        marginBottom: 0
    }
}))(Container)
