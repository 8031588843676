import gql from 'graphql-tag'
    
export const schemaSurveyAnalysisModule = `
    ... on Module_00 {
        survey_analysis_users_no
        survey_question_no
    }

    ... on Module_01 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        comment
    }

    ... on Module_02 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        comment
    }

    ... on Module_03 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
    }

    ... on Module_04 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
    }

    ... on Module_05 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
    }

    ... on Module_06 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
    }

    ... on Module_07 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
    }

    ... on Module_08 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
    }

    ... on Module_09 {
        survey_analysis_users_no
        survey_question_no
        answer
        national_flag
    }

    ... on Module_10 {
        survey_analysis_users_no
        survey_question_no
        answer
    }

    ... on Module_11 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
    }

    ... on Module_12 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        comment
    }

    ... on Module_13 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        comment
    }

    ... on Module_14 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
    }

    ... on Module_15 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
    }

    ... on Module_16 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
    }

    ... on Module_17 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
        comment
    }

    ... on Module_18 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
        comment
    }

    ... on Module_19 {
        survey_analysis_users_no  
        survey_question_no        
        src                       
        filename                  
        filetype                  
        filesize                  
    }

    ... on Module_20 {
        survey_analysis_users_no
        survey_question_no
        zipcode
        address1
        address2
    }

    ... on Module_21 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
    }

    ... on Module_22 {
        survey_analysis_users_no
        survey_question_no
        survey_module_no
        survey_module_answer_no
    }
`

export const schemaSurveyAnalysisEnding = `
    survey_analysis_module_ending
    survey_analysis_users_no
    survey_ending_no
    wdate
`
export const GET_SURVEY_ANALYSIS_MODULES = gql`
    query SurveyAnalysisModules($survey_no: Int!, $token: ID!, $survey_page_no: Int!, $mode: Mode!) {
        surveyAnalysisModules(survey_no: $survey_no, token: $token, survey_page_no: $survey_page_no, mode: $mode) {        
            ${schemaSurveyAnalysisModule}
        }
    }
`

export const GET_SURVEY_ANALYSIS_QUESTION_MODULES = gql`
    query SurveyAnalysisQuestionModules($survey_no: Int!, $token: ID!, $survey_question_no: Int!, $mode: Mode!) {
        surveyAnalysisQuestionModules(survey_no: $survey_no, token: $token, survey_question_no: $survey_question_no, mode: $mode) {        
            ${schemaSurveyAnalysisModule}
        }
    }
`

export const GET_SURVEY_ANALYSIS_QUESTION_MODULES_THEN_SUBMIT = gql`
    query SurveyAnalysisQuestionModulesThenSubmit($survey_no: Int!, $token: ID!, $survey_ending_no: Int, $mode: Mode!) {
        surveyAnalysisQuestionModulesThenSubmit(survey_no: $survey_no, token: $token, survey_ending_no: $survey_ending_no, mode: $mode) {        
            rows {
                ${schemaSurveyAnalysisModule}
            }
            survey_ending_no
        }
    }
`

export const GET_SURVEY_ANALYSIS_QUESTION_MODULES_THEN_PREV = gql`
    query SurveyAnalysisQuestionModulesThenPrev($survey_no: Int!, $token: ID!, $survey_question_no: Int!, $mode: Mode!) {
        surveyAnalysisQuestionModulesThenPrev(survey_no: $survey_no, token: $token, survey_question_no: $survey_question_no, mode: $mode) {        
            rows {
                ${schemaSurveyAnalysisModule}
            }
            survey_question_no
        }
    }
`

export const GET_SURVEY_ANALYSIS_MODULE = gql`
    query SurveyAnalysisModule($survey_no: Int!, $token: ID!, $survey_question_no: Int!, $mode: Mode!) {
        surveyAnalysisModule(survey_no: $survey_no, token: $token, survey_question_no: $survey_question_no, mode: $mode) {        
            ${schemaSurveyAnalysisModule}
        }
    }
`

export const GET_SURVEY_ANALYSIS_MODULES_ALL = gql`
    query SurveyAnalysisModulesAll($survey_no: Int!, $mode: Mode!) {
        surveyAnalysisModulesAll(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyAnalysisModule}
        }
    }
`

export const GET_SURVEY_ANALYSIS_ENDING_ALL = gql`
    query SurveyAnalysisEndingAll($survey_no: Int!, $mode: Mode!) {
        surveyAnalysisEndingAll(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyAnalysisEnding}
        }
    }
`

export const GET_SURVEY_ANALYSIS_MODULE19_INFO = gql`
    query surveyAnalysisModule19Info($key: String!, $mode: Mode!, $random: String!) {
        surveyAnalysisModule19Info(key: $key, mode: $mode, random: $random) {
            src
            filename
        }
    }
`

export const CREATE_SURVEY_ANALYSIS = gql`
    mutation CreateSurveyAnalysis($input: CreateSurveyAnalysis!) {
        createSurveyAnalysis(input: $input) {
            datas
            comments
        }
    }
`

export const CREATE_SURVEY_ANALYSIS_ONE = gql`
    mutation CreateSurveyAnalysisOne($input: CreateSurveyAnalysisOne!) {
        createSurveyAnalysisOne(input: $input) {
            datas
            comments
        }
    }
`

export const DELETE_SURVEY_ANALYSIS_MODULE_19 = gql`
    mutation DeleteSurveyAnalysisModule19($survey_no: Int!, $token: ID!, $survey_question_no: Int!, $mode: Mode!) {
        deleteSurveyAnalysisModule19(survey_no: $survey_no, token: $token, survey_question_no: $survey_question_no, mode: $mode)
    }
`