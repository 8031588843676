import { memo } from 'react'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import AlbumIcon from '@material-ui/icons/Album'
import VideocamIcon from '@material-ui/icons/Videocam'
import IconComponent from 'styled/Custom/Icon'

interface Props {
    type: string
}

function Icon(props: Props) {
    const { type } = props

    return (
        <div style={{marginTop: 10, marginBottom: 10}}>
            <IconComponent width={100} kind="answer" soft={1}>
                {
                    /audio/.test(type) ? <AlbumIcon/> : 
                    /video/.test(type) ? <VideocamIcon/> : <InsertDriveFileIcon/>
                }
            </IconComponent>
        </div>
    )
}

export default memo(Icon)