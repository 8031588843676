import { useMemo, useContext } from 'react'
import { FormControlElement } from 'styled/Survey/MatrixTable'
import { Context } from './Component'
import MobileAnswerChildItemComponent from './MobileAnswerChildItem'

function MobileAnswerItemComponent(props) {
    const { survey_module_no, survey_module_answer_no, answer, selected } = props

    const { onChange, reply, getDisabled } = useContext(Context)
    const countReply = reply.filter(d => d.survey_module_no === survey_module_no).length

    const disabled = getDisabled(countReply, selected)

    return (
        <>
        {
            useMemo(() => {
                return (
                    <FormControlElement key={survey_module_answer_no} className={selected ? 'selected' : ''} onClick={() => onChange(survey_module_no, survey_module_answer_no, disabled)}>
                        <MobileAnswerChildItemComponent answer={answer} selected={selected} disabled={disabled}/>
                    </FormControlElement>
                )
            }, [survey_module_answer_no, survey_module_no, disabled, selected, answer, onChange])
        }
        </>
    )
}

export default MobileAnswerItemComponent