/**
 * 응답자 마다 엔딩페이지가 다르다.
 */
import { ComponentType, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { GET_SURVEY_TEMPLATE_CATEGORY, SurveyTemplateCategoryProps as Props } from 'gql/survey_template'
 
export interface SurveyTemplateCategoryProps {
    surveyTemplateCategory: {
        data: Props[]
        refetch: () => void
    }
}

export default (fetchPolicy?: WatchQueryFetchPolicy) => (isLoading: boolean) => (WrappedComponent: ComponentType<SurveyTemplateCategoryProps>) => (props: any) => { 
    const dispatch = useDispatch()
 
    const { data, refetch, loading } = useQuery(GET_SURVEY_TEMPLATE_CATEGORY, {
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: (fetchPolicy || 'cache-first')
    })

    useEffect(() => {
        if (isLoading) {
            if (loading) {
                dispatch({type: BACKDROPSHOW})
            }
        }
    })

    if (!data) return null

    return <WrappedComponent surveyTemplateCategory={{ data: data.surveyTemplateCategory, refetch }} {...props}/>
}