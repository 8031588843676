import { useContext } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropsContext from 'context/props'

const useStyles = makeStyles({
    root: {
        wordBreak: 'break-all',
        fontSize: 17
    }
})

export default (props) => {
    const { style={}, children, variant="body1", ...other } = props
    const { design } = useContext(PropsContext)
    const { answer: color, font_family } = design

    let _style = {...style}

    const classes = useStyles()

    if (color) _style = {..._style, color: `rgba(${color})`, fontFamily: font_family }

    return <Typography className={classes.root}  style={_style} {...other} variant={variant}>{children}</Typography>
} 