import { useContext } from 'react'
import styled from 'styled-components'
import oc from 'open-color-js'
import { MenuItem } from '@material-ui/core'
import { useIntl } from 'react-intl'
import Answer from 'styled/Custom/Answer'
import InputSelect from 'styled/Custom/InputSelect'
import PropsContext from 'context/props'
import { getColorConcentration } from 'utils'

const OptionStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    border: ${props => {
        const [ r, g, b ] = props.bordercolor.split(',')
        return `1px solid rgba(${r}, ${g}, ${b}, .5)`
    }};
    padding: 5px;
    margin-top: ${props => props.index > 0 ? '8px' : 0 };

    & > div:first-child {
        width: 64px;
    }

    & > div:last-child {
        width: calc(100% - 76px);
        word-break: break-all;
    }
    & > div:nth-child(2) {
        width: 6px;
    }
    & > div:nth-child(3) {
        width: 6px;
      
    }
`
export default (props) => {
    const { ranks, answer, reply, index, onChange } = props
    const { design } = useContext(PropsContext)
    const { background_color, background_image, answer_button } = design

    const { formatMessage: f } = useIntl()

    // 배경색에 따라 라인선 변경
    const lineColor = (!getColorConcentration(background_color) || background_image) ? oc.gray3  : 'white'

    // 선택된값
    const selectedReply = reply.find(c => c.survey_module_answer_no === answer.survey_module_answer_no) || {survey_module_no: '', survey_module_answer_no: ''}

    // reply에서 선택된값을 제외한값을 담는다
    const other = reply.filter(c => c.survey_module_no !== selectedReply.survey_module_no).map(c => c.survey_module_no)

    // 랭크 셀렉트 (해당보기에 선택된값 과 아직 선택이 안된값만 담는다)
    const _ranks = ranks.filter(c => !other.includes(c.survey_module_no))

    return (
        <OptionStyled index={index} lineColor={lineColor} bordercolor={answer_button}>
            <div>
                {
                   <InputSelect
                   size="small" 
                   label={f({id: 'component.Survey.Modules.11.ranks'})} 
                   variant="outlined" 
                   value={selectedReply.survey_module_no} 
                   onChange={(e)=> onChange(e.target.value, answer.survey_module_answer_no)}>
                       <MenuItem value=''><em>None</em></MenuItem>
                       {
                           _ranks.map(c => <MenuItem key={c.survey_module_no} value={c.survey_module_no}>{c.indexs}</MenuItem>)
                       }
                   </InputSelect>
                }
                
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div><Answer>{answer.answer ? answer.answer : f({id: 'component.Survey.Modules.11.answer'})}</Answer></div>
        </OptionStyled>
    )
}