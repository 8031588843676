import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'

export default WrappedComponent => props => {
    const { refetchQuestions, propsFetchQuestion, on } = props

    const [ status, setStatus ] = useState(null)

    const dispatch = useDispatch()

    const onClose = () => {
        dispatch({type: BACKDROPSHOW})
        on.close('close')
    }
    
    useEffect(() => {
        const success = async() => {
            on.close('save')
            refetchQuestions()
        }

        const fail = async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }

        if (status === true) success()
        else if (status === false) fail()

    }, [status, dispatch, on, refetchQuestions])

    useEffect(() => {
        if (propsFetchQuestion.loading) dispatch({ type: BACKDROPSHOW })
        else dispatch({ type: BACKDROPHIDE })
    }, [propsFetchQuestion.loading, dispatch])
    
    const propsEdit = {
        changeStatus: setStatus,
        onClose
    }
    return <WrappedComponent propsEdit={propsEdit} {...props}/>
}