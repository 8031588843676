/**
 * 설정 중인 필터 데이터 상태 값
 */
import { QuestionOption } from "component/Survey/Create/NewAnalysis/Filter/Component"

export const CHANGE = 'analysis/filter/data/CHANGE' // 값 변경시
export const INIT = 'analysis/filter/data/INIT'     // 초기 쿠키에서 값 가져올때


type Type = {
    data: QuestionOption
}

// order: answer => 보기순, count => 응답순
// pointType: point => 가중치로 계산, count => 응답수로 계산 
const initState: Type = {
    data: {}
}

interface ActionProps {
    type: string;
    data: QuestionOption;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                data: action.data
            }
        case INIT:
            return initState
        default:
            return state
    }
}