import { ListItem, ListItemText, ListItemIcon, Paper, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { 
    Add as AddIcon, 
    Textsms as TextsmsIcon, 
    RateReview as RateReviewIcon, 
    Widgets as WidgetsIcon
} from '@material-ui/icons'
import IconButton from 'styled/Custom/IconButton'


export const Icon = (props) => {
    const { shape, ...other } = props
    
    const component = {
        add: AddIcon,
        text: TextsmsIcon,
        rate: RateReviewIcon,
        widget: WidgetsIcon
    }
    
    const IconComponent = component[shape]

    return <IconComponent {...other}/>
}

export const MyColor = withStyles((theme) => ({
    root: (props) => {
        const { color } = props

        return {
            width: 25,
            height: 25,
            cursor: 'pointer',
            background: `rgba(${color})`
        }
    }
}))(props => <Paper {...props}/>)

export const MyPosition = withStyles((theme) => ({
    root: (props) => {
        const { selected } = props

        let styles = {
            width: 40,
            height: 35,
            cursor: 'pointer',
            position: 'relative'
        }

        if (selected) styles = { ...styles, border: `1px solid ${theme.palette.primary.light}` }

        return styles
    }
}))((props) => <Paper variant="outlined" {...props}/>)

export const MyListItemIcon =  withStyles((theme) => ({
    root: {
        justifyContent: 'flex-end',
        '& > * + *': {
            marginLeft: '.5em'
        }
    }
}))(ListItemIcon)

export const MyIconButton = (props) => {
    const { children, ...other } = props

    return (
        <IconButton {...other} size="small" backgroundcolor='rgba(0, 0, 0, .1)' backgroundcolorhover='rgba(0, 0, 0,.2)'>
            {children}
        </IconButton>
    )
}

export const MyCodeItem = (props) => {
    const { align="center", onClick=() => {}, children } = props

    return (
        <ListItem style={{textAlign: align, paddingTop: '1em'}}>
            <ListItemText>
                <Button variant="contained" size="medium" color="default" onClick={onClick}> 
                    <Typography>{children}</Typography>
                </Button>
            </ListItemText>
        </ListItem>
    )
}
