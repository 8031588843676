import { CircularProgress } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

const MyProgress = styled(CircularProgress)(({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}))

export default () => {
  return <MyProgress/>
}