import { IconButton, Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from '@material-ui/icons'
import { AntTabs, AntTab } from 'styled/AntTab'
import { useIntl } from 'react-intl'
import Tooltip from 'styled/Custom/Tooltip'

const MyGrid = withStyles({
    root: {
        alignItems: 'center'
    }
})(Grid)

const MyAntTabs = withStyles(theme => ({
    root: {
        maxWidth: '100%',
        marginBottom: theme.spacing(2)
    }
}))(AntTabs)


export default (props) => {    
    const { hideType, isVideo, isAppendUrl, tabValue, onChange, onClose } = props

    // 펙셀 막혔다
    const newIsVideo = false

    const { formatMessage: f } = useIntl()

    const itemSize = typeof onClose === 'function' ? [{xs: 2, sm: 1, md: 1}, {xs: 10, sm: 11, md: 11}] 
                                                   : [{xs: 0, sm: 0, md: 0}, {xs: 12, sm: 12, md: 12}]
    return (
        <MyGrid container>  
            {
                typeof onClose === 'function' && (
                    <Grid item xs={itemSize[0].xs} sm={itemSize[0].sm} md={itemSize[0].md}>
                        <IconButton onClick={onClose}>
                            <Tooltip 
                                title={hideType === 'undo' ? f({id: 'component.Image.Tabs.title.0'}) : f({id: 'component.Image.Tabs.title.1'})}>
                                {hideType === 'undo' ? <ArrowBackIcon/> : <CloseIcon/>}
                            </Tooltip>
                        </IconButton>
                    </Grid>
                )
            }
            <Grid item xs={itemSize[1].xs} sm={itemSize[1].sm} md={itemSize[1].md}>
                <MyAntTabs variant="scrollable" scrollButtons="auto" value={tabValue} onChange={onChange} aria-label="example-tab">
                    <AntTab value="unsplash" label={<Typography variant="body2">{f({id: 'component.Image.Tabs.tab.unsplash'})}</Typography>}/>                
                    {newIsVideo && <AntTab value="pexels" label={<Typography variant="body2">{f({id: 'component.Image.Tabs.tab.pexels'})}</Typography>}/>}
                    <AntTab value="my" label={<Typography variant="body2">{f({id: 'component.Image.Tabs.tab.my'})}</Typography>}/>
                    {isAppendUrl && <AntTab value="link" label={<Typography variant="body2">{f({id: `component.Image.Tabs.tab.${isVideo ? 'youtubeLink' : 'link'}`})}</Typography>}/>}
                </MyAntTabs>
            </Grid>
        </MyGrid>
    )
}