import { useContext } from 'react'
import PropsContext from 'context/props'
import CommentItem from './CommentItem'

export default (props) => {
    const { refCommentsWith } = useContext(PropsContext)

    const { survey_question_no, is_comment, is_comment_placeholder, comment, disabled=false, marginTop, marginBottom } = props

    return (
        <>
        {
            is_comment && (
                <CommentItem
                    survey_question_no={survey_question_no}
                    disabled={disabled}
                    comment={comment}  
                    is_comment_placeholder={is_comment_placeholder}
                    refComment={refCommentsWith}
                    marginTop={marginTop}
                    marginBottom={marginBottom}
                />
            )
        }
        </>
    )
}

