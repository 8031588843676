import { memo, useContext, ReactChildren, useMemo } from 'react'
import { Avatar } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { SurveyMboxDesignProps } from 'gql/survey_mbox_design'
import { getColorConcentration } from 'utils'
import PropsContext from 'context/props'

interface Props {
    disabled: boolean;
    children: ReactChildren
}

const RankAvatar = withStyles({
    root: ({ answer_button, font_family, disabled }) => {
        const concent = getColorConcentration(answer_button) 

        const [ r, g, b, a ] = answer_button.split(',')

        const customA = Number(a) < .5 ? a : .5

        return {
            backgroundColor: disabled ? 'rgba(0, 0, 0, .1)' : `rgba(${r}, ${g}, ${b}, ${customA})`,
            color: concent ? 'white' : 'black',
            fontWeight: 700,
            fontFamily: font_family
        }
    }
})(props => <Avatar variant="rounded" {...props}/>)

function AvatarComponent(props: Props) {
    const { disabled, children } = props

    const { design } = useContext<{design: SurveyMboxDesignProps}>(PropsContext)
    const { answer_button, font_family } = design

    return (
        <>
        {
            useMemo(() => (
                <RankAvatar disabled={disabled} answer_button={answer_button} font_family={font_family}>
                    {children}
                </RankAvatar>
            ), [answer_button, font_family, disabled, children])
        }
        </>
    )

}

export default memo(AvatarComponent)