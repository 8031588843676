import { useState, useCallback, ComponentType } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { handleDisabledRightClick } from 'ts-utils'
import { Dialog, Box } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { useTheme, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'

export type OnOpen = (a: string) => void
export type OnClose = () => void

interface OnPopoverImageProps {
    on: {
        open: OnOpen,
        close: OnClose
    }
}

const ImgBox = styled.div`
    display: table;
    min-width: 100%;
    min-height: calc(100% - 31px);

    & > div {
        display: table-row;
    }

    & > div > div {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
`

const ButtonBox = withStyles(theme => ({
    root: {
        display: 'none',
        position: 'sticky',
        bottom: 0,
        left: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            gap: 10
        }
    }
}))(Box)

const MyButton = withStyles({
    root: {
        borderRadius: 0
    }
})((props: ButtonProps) => <Button {...props} disableElevation fullWidth variant="contained"/>)

const Img = styled.img`
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    max-width: ${(props: {zoom: boolean}) => props.zoom ? '200%' : '100%'};
`

const initialState = { open: false, src: '' }

export default (WrappedComponent: ComponentType<{popoverImageProps: OnPopoverImageProps}>) => (props: any) => { 
    const { formatMessage: f } = useIntl()

    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('xs'))

    const [ state, setState ] = useState(initialState)
    const [ zoom, setZoom ] = useState(false)

    const on = {
        open: useCallback(async(src) => {
            await setZoom(false)
            setState({ open: true, src })
        }, []),
        close: useCallback(() => { 
            setState(prevState => ({...prevState, open: false})) 
        }, [])
    }

    function handleChangeZoom() {
        setZoom(prevState => !prevState)
    }
    
    const popoverImageProps = { on }

    return (
        <>
        <Dialog fullScreen={xs} maxWidth="md" open={Boolean(state.open)} onClose={on.close}>
            <ImgBox>
                <div>
                    <div>
                        <Img zoom={zoom} src={state.src} alt="" onContextMenu={handleDisabledRightClick}/>
                    </div>
                </div>
            </ImgBox>
            <ButtonBox>
                <MyButton 
                    startIcon={
                        zoom ? <ZoomOutIcon color="action" fontSize="small"/> : <ZoomInIcon color="action" fontSize="small"/>
                    }
                    style={{borderTopRightRadius: 3}}  
                    onClick={handleChangeZoom}
                >
                    {f({id: `hoc.Survey.PopoverImage.button.view.${zoom ? 1 : 0}`})}
                </MyButton>
                <MyButton 
                    startIcon={<CloseIcon color="action" fontSize="small"/>}
                    style={{borderTopLeftRadius: 3}} 
                    onClick={on.close}
                >
                    {f({id: 'hoc.Survey.PopoverImage.button.close'})}
                </MyButton>
            </ButtonBox>
        </Dialog>
        
        <WrappedComponent popoverImageProps={popoverImageProps} {...props}/>
        </>
    )
}