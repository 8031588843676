import styled from 'styled-components'
import { Button as MuiButton, Link, Typography, Container } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import oc from 'open-color-js'
import ReactHtmlParser from 'react-html-parser'
import FaceLogo from 'styled/FaceLogo'

function CustomLink(props) {
    const { href, children } = props
    const isHttp = /^http/.test(href)

    return (
        <>
        {
            isHttp ? (
                <div onClick={() => {
                    window.location.assign(href)
                }}>
                    {children}
                </div>
            ) : (
                <Link href={href}>
                    {children}
                </Link>
            )
        }
        </>
    )
}

export const MyContainer = withStyles({
    root: {
        position: 'relative',
        height: '100vh'
    }
})(Container)

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${oc.gray0};
    z-index: 10000;
`
export const StyledContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
export const StyledContent = styled.div`
    display: flex;
    margin-bottom: 20px;
    text-align: center;
`

const LogoStyled = styled.div`
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
`

export const Logo = withStyles(theme => ({

}))(props => {
    const { href='/workspace' } = props
    return (
        <CustomLink href={href}>
            <LogoStyled>
                <FaceLogo style={{maxWidth: 45, maxHeight: 45}}/>
            </LogoStyled>
        </CustomLink>
    )
})

export const MessageTypography = withStyles(theme => ({
    root: {
        fontSize: 20,
        [theme.breakpoints.down('xs')]: {
            fontSize: 17
        }
    }
}))(props => {
    const { children, ...other } = props
    return <Typography {...other}>{ReactHtmlParser(children)}</Typography>
})

export const Button = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1.2),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(1.2),
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2)
        }
    }
}))(props => {
    const { href='/workspace', children, ...other } = props
    return (
        <CustomLink href={href}>
            <MuiButton variant="contained" disableElevation color="primary" {...other}>
                <Typography>{children}</Typography>
            </MuiButton>
        </CustomLink>
    )
})