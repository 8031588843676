// backdrop 무조건 숨긴다
// dialog 창
export const PLAY = 'backdropHide/PLAY'
export const STOP = 'backdropHide/STOP'

interface InitProps {
    action: boolean;
}

const initState: InitProps = {
    action: false,
}

interface ActionProps {
    type: string;    
    action: Pick<InitProps, 'action'>;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case PLAY:
            return {
                action: true
            }
        case STOP:
            return {
                action: false
            }
        default:
            return state
    }
}