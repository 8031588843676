import { ComponentType, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { parseGraphqlError } from 'utils'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { GET_SURVEY_ANALYSIS_ROWS, SurveyAnalysisRowsProps, SurveyAnalysisRowsVariables, SurveyAnalysisRowsPagingVariables } from 'gql/survey_analysis_rows'
import { sort, getKey } from 'hoc/Survey/RowsPaging'
import useRowsDownloadError from 'hooks/useRowsDownloadError'
import { getOrderKey } from 'component/Survey/Create/NewAnalysis/Rows/Component'

export interface Props extends Omit<SurveyAnalysisRowsVariables ,'mode'> {
    action: number;
    isRefresh?: boolean;
    questions?: SurveyQuestionModuleProps[];
    order: SurveyAnalysisRowsPagingVariables['input']['order'];
    etc: any;
}

export interface RowsProps {
    rowsData: {
        complete: boolean;
        loading: boolean;
        rows: SurveyAnalysisRowsProps; 
    }
}

let timer: ReturnType<typeof setTimeout>

export default (mode: SurveyAnalysisRowsVariables['mode']) => (isLoading: boolean) => (WrappedComponent: ComponentType<{rowsData: RowsProps['rowsData']}>) => (props: Props) => {
    const { action, isRefresh=false, survey_no, order, input } = props
    const { conditionsPlay, step, date, search } = input

    const prevAction = useRef(action)

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ onError ] = useRowsDownloadError()

    const [ complete, setComplete ] = useState(false)

    const [getData, { data, loading, refetch }] = useLazyQuery<{surveyAnalysisRows: SurveyAnalysisRowsProps}, SurveyAnalysisRowsVariables>(GET_SURVEY_ANALYSIS_ROWS, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            setComplete(true)
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'limit') {
                onError()
                dispatch({ type: BACKDROPHIDE })
            }
        }
    })

    useEffect(() => {
        if (action > 0) {
            // action 과 이전action 값다면, 다른 상태값이 변경된거지 다운로드 액션을 취한건 아니라 리턴한다.
            if (Number(action) === Number(prevAction.current)) return

            // 데이터 다운로드 이후 새로고침 이후 다시 가져올때 사용된다.
            if (isRefresh && refetch) {
                setComplete(false)
                refetch()
                return
            }

            // 맨 처음 다움로드 클릭할때나, 다시 다운로드 누를때 사용된다 (새로고침 버튼 안누를시)
            setComplete(false)
            getData({
                variables: {
                    survey_no, 
                    mode,
                    input: {
                        conditionsPlay, 
                        step: Number(step),
                        date, 
                        search
                    },
                    key: getKey(survey_no)
                }
            })

            // 한번 다운로드 한상태에서 다시 누를때는 onComplete를 안 타기때문에 임의로 complete를 해준다.
            if (data && refetch) {
                clearTimeout(timer)
                timer = setTimeout(() => {
                    setComplete(true)
                }, 100)
            }
        }
    }, [ 
        loading,
        action,
        isRefresh,
        survey_no, 
        conditionsPlay, 
        step,
        date, 
        search,
        prevAction,
        data,
        getData,
        refetch
    ])

    useEffect(() => {
        if (isLoading && loading) {
            dispatch({type: BACKDROPSHOW, msg: f({id: 'hoc.Survey.Rows.loadingMsg'}) })
        }
    }, [loading, dispatch, f])
   
    // action 이전 값 저장
    useEffect(() => {
        prevAction.current = action
    }, [action])


    // 언마운트 이후 들어와서, 새로고침 아이콘 누르고 다운로드시, 안되는 문제 
    useEffect(() => {
        if (!complete) {
            if (action > 0 && isRefresh && data && refetch) {
                refetch()
            }
        }
    }, [data, refetch, complete, isRefresh, action])

    if (!data) return null

    sort({ key: getOrderKey(order.key, step), value: order.value }, data.surveyAnalysisRows.answers)

    return <WrappedComponent {...props} rowsData={{ complete, loading, rows: data.surveyAnalysisRows }}/>
}