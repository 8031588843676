/* 
** password type password text로 구분
*/
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core'
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { getMaxlength } from 'policy'
import randomString from 'randomstring'
import pixelWidth from 'string-pixel-width'

const Input = withStyles(theme => ({
    input: {
        [theme.breakpoints.down('xs')]: {
            '&::placeholder': {
                fontSize: 11
            }
        }
    }
}))(OutlinedInput)


// margin: dense, normal, none
const Component = (props) => {   
    const { 
        variant="outlined", 
        margin='normal', 
        fullWidth=true, 
        label, 
        placeholder, 
        name, 
        value, 
        error=false, 
        autoComplete="off",
        onChange 
    } = props

    const { lang } = useSelector(state => state.lang)

    const [ showPassword, setShowPassword ] = useState(false)
    
    const handleShowPassword = useCallback(() => {
        setShowPassword(prevState => !prevState)
    }, [])

    const id = randomString.generate(5)
    const labelWidth = pixelWidth(label, { font: 'Arial', size: lang === 'en' ? 16 : 30})
    
    return (
        <FormControl  margin={margin} fullWidth={fullWidth} variant={variant}>
            <InputLabel htmlFor={id} error={error}>{label}</InputLabel>
            <Input
                id={id}
                name={name}
                placeholder={placeholder}
                type={showPassword ? 'text' : 'password'}
                value={value}
                error={error}
                onChange={onChange}
                inputProps={{
                    autoComplete,
                    maxLength: getMaxlength('common.password')
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                        >
                        {showPassword ? <VisibilityIcon fontSize="small"/> : <VisibilityOffIcon fontSize="small"/>}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={labelWidth}
            />
        </FormControl>
    )
}
 
export default Component
