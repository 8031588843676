import { Grid, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import oc from 'open-color'
import { numberFormat } from 'utils'
import { useIntl } from 'react-intl'

interface Props {
    appCardName: string,
    cardNum: string,
    vbankDate: string,
    order_name: string,
    price: number
}

const GridContainer = withStyles({
    root: {
        border: `1px solid ${oc.gray[1]}`,
        borderRadius: 3
    }
})(props => <Grid container {...props}/>)

const DepositBox = withStyles({
    root: (props:{last?:number}) => {
        const { last } = props
        return {
            display: 'flex',
            width: '100%',
            "& > div": {
                display: 'flex',
                alignItems: 'center',
                height: 60,
                
            },
            "& > div:first-child": {
                width: '80px',
                background: oc.gray[1],
                justifyContent: 'center',
                fontWeight: 'bold',
                borderBottom: `1px solid ${last === 1 ? oc.gray[1] : oc.gray[2]}`
            },
            "& > div:last-child": {
                flexGrow: 1,
                paddingLeft: 15,
                borderBottom: `1px solid ${oc.gray[1]}`
            }
        }
    }
})(Box)

function DepositBoxComponent(props: Props) {
    const { appCardName, cardNum, order_name, price, vbankDate } = props

    const { formatMessage: f } = useIntl()

    const expire = `${vbankDate.substring(0, 4)}.${vbankDate.substring(4, 6)}.${vbankDate.substring(6, 8)}`

    return (
        <GridContainer>
            <Grid item xs={12} sm={6} md={6}>
                <DepositBox>
                    <div>{f({id: 'component.Payment.Receipt.DepositBox.column.bank'})}</div>
                    <div>{appCardName}</div>
                </DepositBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DepositBox>
                    <div>{f({id: 'component.Payment.Receipt.DepositBox.column.number'})}</div>
                    <div>{cardNum}</div>
                </DepositBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DepositBox>
                    <div>{f({id: 'component.Payment.Receipt.DepositBox.column.order_name'})}</div>
                    <div>{order_name}</div>
                </DepositBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DepositBox>
                    <div>{f({id: 'component.Payment.Receipt.DepositBox.column.price'})}</div>
                    <div>{f({id: 'component.Payment.Receipt.DepositBox.answer.price'}, {price: numberFormat(price)})}</div>
                </DepositBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <DepositBox last={1}>
                    <div>{f({id: 'component.Payment.Receipt.DepositBox.column.expire'})}</div>
                    <div>{f({id: 'component.Payment.Receipt.DepositBox.answer.expire'}, {expire})}</div>
                </DepositBox>
            </Grid>
        </GridContainer>
    )
}

export default DepositBoxComponent