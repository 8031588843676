import { memo, createContext, useMemo } from 'react'
import { Format06 } from 'hoc/Survey/SetReplys'
import { _06Module, _06Answer } from 'gql/survey_question_modules'
import { Overflow } from '../05/Overflow'
import ItemComponent from './Item'

interface Props {
    left_label: string;
    right_label: string;
    reply: Format06[]; 
    columns: _06Module[]; 
    answers: _06Answer[];
    reverse: boolean;
    zero_number: boolean;
    onChange: (a:number, b:number) => void;
}

export const ItemContext = createContext<Props>({
    left_label: '',
    right_label: '',
    reply: [],
    columns: [],
    answers: [],
    reverse: false,
    zero_number: true,
    onChange: () => {}
})

function OverflowComponent(props: Props) {
    const { left_label, right_label, reply, columns, answers, reverse, zero_number, onChange } = props

    return (
        <ItemContext.Provider value={{left_label, right_label, reply, columns, answers, zero_number, reverse, onChange}}>
            {
                useMemo(() => {
                    return (
                        <Overflow paddingBottom="10px">
                            <ItemComponent/>
                        </Overflow>
                    )
                }, [])
            }
        </ItemContext.Provider>
        
    )
}

export default memo(OverflowComponent)