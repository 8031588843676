import { useMemo, useContext, memo } from 'react'
import { getColorConcentration } from 'utils'
import PropsContext from 'context/props'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

interface Props {
    height?: number | string;
    children: JSX.Element | JSX.Element[];
}

const MyBox = withStyles({
    root: ({answer_button, background_color}: {answer_button: string, background_color: string}) => ({
        border: `1px solid rgba(${answer_button})`,
        borderRadius: 3,
        '& > div:first-child': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            borderBottom: `1px dashed rgba(${answer_button})`,
            padding: 15,
            minHeight: 100
        },
        '& > div:last-child': {
            padding: '15px 15px 10px 15px',
            background: getColorConcentration(background_color) ? 'rgba(255, 255, 255, .1)' : 'rgba(126, 126, 126, .1)' 
        }
    })
})(Box)


function FileUploadBoxComponent(props: Props) {
    const { children } = props
    const { design } = useContext<{answer_button: string}>(PropsContext)
    const { answer_button, background_color } = design

    return (
        <>
        {
            useMemo(() => (
                <MyBox answer_button={answer_button} background_color={background_color}>
                    {children}
                </MyBox>
            ), [answer_button, background_color, children])
        }
        </>
    )
}

export default memo(FileUploadBoxComponent)
