export const SHOW = 'alertSnackbar/SHOW'
export const HIDE = 'alertSnackbar/HIDE'

const initState = {
    show: false,
    anchor: {
        vertical: 'top',
        horizontal: 'center'
    },
    variant: 'warning',  // error, info, success, warning
    message: '',
    actions: [],
    duration: 2500
}

export default (state = initState, action) => {
    const anchor = action.anchor || initState.anchor
    const variant = action.variant || initState.variant
    const duration = action.duration || initState.duration
    const actions = action.actions || initState.actions

    switch(action.type) {
        case SHOW:
            return {
                ...state,
                show: true,
                variant,
                anchor,
                actions,
                message: action.message,
                duration
            }
        case HIDE:
            return {
                ...state,
                show: false
            }
        default:
            return state
    }
}