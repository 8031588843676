import { useIntl } from 'react-intl'

const initialState = {
    design: {
        survey_designs_no: '',
        title: '',
        question: '0,0,0,1',
        answer: '0,0,0,1',
        answer_button: '117,117,117,1',
        answer_sbutton: '63,81,181,1',
        background_color: '255,255,255,1',
        line_color: '222,226,230,1',
        background_image: '',
        background_filter: '',
        background_size: 'cover',
        background_opacity: .5,
        font_family: 'sans-serif',
        numbering: '63,81,181,1'
    }
}

export default WrappedComponent => props => {    
    const { formatMessage: f } = useIntl()
    
    const propsSurveyDefaultDesign = {
        design: {
            ...initialState.design,
            title: f({id: 'hoc.Survey.DefaultDesign.title'})
        }
    }
    return <WrappedComponent propsSurveyDefaultDesign={propsSurveyDefaultDesign} {...props}/>
}