import { Stepper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const TransparentStepper = withStyles(theme => ({
    root: (props) => {
        const { padding_bottom=0 } = props
        return {
            background: 'transparent',
            padding: 0,
            paddingBottom: padding_bottom,
           
        }
    },
    horizontal: (props) => {
        const { is_mobile } = props

        if (!is_mobile) return {

        }
        
        return {
            display: 'grid',
            width: '100%',
            gridTemplateColumns: 'repeat( auto-fit, minmax(28px, 1fr) )',
            gap: 5,
            padding: 0,
            [theme.breakpoints.down('xs')]: {
                gap: 2.7
            }
        }
     
    }
}))(props => <Stepper {...props} />)

export default (props) => {
    return <TransparentStepper {...props}/>
}