import { useContext } from 'react'
import { Typography } from '@material-ui/core'
import PropsContext from 'context/props'
import clsx from 'clsx'
import { Check as CheckIcon } from '@material-ui/icons'
import { useStepIconClass } from './Class/StepIcon'
import { getNumber } from './StepIconText'


export default (props) => {
    const { reverse, zero_number, len, index, active, flash, onClick } = props

    const { design } = useContext(PropsContext)

    const classes = useStepIconClass({ backgroundcolor: design.answer_button, backgroundcolorActive: design.answer_button })

    return (
        <div className={clsx(classes.root, flash, { [classes.active]: active })} style={{cursor: 'pointer'}} onClick={onClick}>
            {active ? <CheckIcon/> : <Typography>{getNumber({ reverse, zero_number, len, index: (index + 1)})}</Typography>}
        </div>
    )  
}