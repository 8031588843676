/**
 * save 이벤트시 빈값이 잇을경우 해당값을 펼침하고 포커스준다.
 */

import { ID, JumpNo } from 'gql/jump'

export const NULL = 'survey/create/logic/jumpFocus/NULL'

// 빈값이 있을경우 해당 id kind 저장
export const CHANGE = 'survey/create/logic/jumpFocus/CHANGE'

type Section = 'default' | 'rules';

interface Props {
    no: JumpNo;
    id: ID;
    section: Section;
}

const initState: Props = {
    no: '',
    id: 0,
    section: 'default'
}
 
interface ActionProps {
    type: string;
    id: ID;
    no: JumpNo;
    section: Section;
}
 
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case NULL:
            return initState
        case CHANGE: 
            return {
                id: action.id,
                no: action.no,
                section: action.section
            }     
        default:
             return state
    }
}