// 오리진데이터랑 현재값이랑 같은지 판단 (./Lists/ButtonSave isEqaul에서 발행)

export const CHANGE = 'survey/create/logic/jumpEqaul/CHANGE'
export const INIT = 'survey/create/logic/jumpEqaul/INIT'

const initState: { is: boolean } = {
    is: true // 같다면 true
}

interface ActionProps {
    type: string;
    is: boolean;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                is: action.is
            }
        case INIT:
            return initState
        default:
            return state
    }
}