import { memo } from 'react'
import { useIntl } from 'react-intl'
import CloseIcon from '@material-ui/icons/Close'
import MyIconButton from 'styled/Custom/IconButton'
import Tooltip from 'styled/Custom/Tooltip'
import { Props as PrentProps } from './RequireLanguage'


interface Props {
    onClose: PrentProps['onClose'];
}

function CloseComponent(props: Props) {
    const { onClose } = props

    const { formatMessage: f } = useIntl()

    return (
        <MyIconButton edge="start" color="inherit" onClick={onClose} aria-label="close"> 
            <Tooltip title={f({id: 'component.Survey.Preview.Component.close'})}>
                <CloseIcon color="action"/>
            </Tooltip>        
        </MyIconButton>
    )
}

export default memo(CloseComponent)