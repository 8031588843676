/**
 * 응답 완료 후 새로고침해도 완료 페이지 바로 보여준다.
 */
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getTokenname, getStartsTokenname } from 'utils'
import { withSurveyFetchData } from 'hoc'
import withSurveyFetchAnalysisEnding from 'hoc/Survey/FetchAnalysisEnding'
import PropsContext from 'context/props'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SurveyModulesEndingComponent } from 'component'
import FreeLogoComponent from 'component/Survey/Live/FreeLogo' 

export function restartSurvey(props) {
    const { survey_no, pathname } = props
    
    const tokenname = getTokenname(survey_no)
    const startsTokenname = getStartsTokenname(survey_no)

    // ro로 올경우 (재시작이 디폴트인 링크)
    // 현재 사용되지 않으니까 조만간 삭제하자
    if (/^\/ro\//.test(pathname)) {
        localStorage.removeItem(tokenname)
        sessionStorage.removeItem(startsTokenname)
        
        window.location.reload()

        return true
    }

    return false
}

function EndingComponent(props) {

    const { levels, mode, rowEnding, propsFetchData } = props 
    const { mbox, mboxDesign } = propsFetchData

    const { survey_no, restart } = mbox

    const location = useLocation()
    const { pathname } = location

    const [ view, setView ] = useState(false)

    const dispatch = useDispatch()

    // 재시작 설정, 이메일 응답이 아닐경우
    useEffect(() => {
        // 재시작 값이 있구, 완료 후 다시 열거나 새로고침으로 올경우, 이메일을 제외한 아이피기준 응답일경우 쿠키값 삭제하여 다시 설문할수잇게 한다.
        if (restartSurvey({ survey_no, pathname }) === false) {
            setView(true)
            dispatch({ type: BACKDROPHIDE })
        }
    }, [restart, survey_no, pathname, dispatch])

    if (!view) return null

    return (
        <>
        <PropsContext.Provider value={{ survey_no, levels, mbox, design: mboxDesign }}>
            <SurveyModulesEndingComponent survey_no={mbox.survey_no} row={rowEnding}/>
        </PropsContext.Provider>
        <FreeLogoComponent mode={mode} levels={levels} isPage={false}/>
        </>
    )
}

export default withSurveyFetchData('live')({ mbox: true, mboxDesign: true })(false)(withSurveyFetchAnalysisEnding(false)(EndingComponent))