export const SHOW = 'survey/create/menu/ending/menu/SHOW'
export const HIDE = 'survey/create/menu/ending/menu/HIDE'

interface Props {
    survey_ending_no: number;
    label: string;
    anchorEl: null | SVGSVGElement;
    isRemove: boolean; // true => 삭제버튼 보여준다
}
const initState: Props = {
    survey_ending_no: 0,
    label: '',
    anchorEl: null,
    isRemove: false
}

interface ActionProps extends Props {
    type: string;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case SHOW:
            return {
                survey_ending_no: action.survey_ending_no,
                label: action.label,
                anchorEl: action.anchorEl,
                isRemove: action.isRemove
            }
        case HIDE:
            return initState
        default:
            return state
    }
}