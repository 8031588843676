import { useState, useEffect, memo } from 'react'
import { useDispatch } from 'react-redux'
import sortArray from 'sort-array'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { GET_SURVEY_MBOX_IN_TEMPLATE_LISTS, SurveyMboxInTemplateListsProps } from 'gql/survey_template'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { ParentProps } from './Component'
import { Selected, Edit } from './Publish'
import AddComponent from "./Add"
import ItemComponent from "./Item"

interface Props {
    categorys: ParentProps['data']
    selected: Selected
    edit: Edit
    survey_workspace_no?: number
}

const MyBox = withStyles({
    root: {
        overflowY: 'auto',
        height: '100%'
    }
})(Box)


const WrapperStyled = styled.div`
    display: flex;
    margin-top: 8px;
    padding-bottom: 1em;
    flex-wrap: wrap;
    --gap: 1.5em;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));

    & > * {
        --rows: 3;

        @media only screen and (max-width: 1280px) {
            --rows: 2;
        }

        @media only screen and (max-width: 600px) {
            --rows: 1;
        }

        margin: var(--gap) 0 0 var(--gap);
        width: calc(100% / var(--rows) - var(--gap));
    }
`

function getData(data: SurveyMboxInTemplateListsProps[], edit: Edit, selected: Selected) {
    const newData = data.filter(c => {
        if (c.survey_template_category_no === selected) {
            // 사용자 선택 화면에서는 공개된것만 보여준다.
            if (!edit) return Boolean(c.open)

            return true
        }

        return false
    })

    sortArray(newData, {
        by: 'survey_no',
        order: 'desc'
    }) 

    return newData
}

function ItemsComponent(props: Props) {
    const { survey_workspace_no, categorys, selected, edit } = props

    const dispatch = useDispatch()

    const { data, refetch, loading } = useQuery<{surveyMboxInTemplateLists: SurveyMboxInTemplateListsProps[]}>(GET_SURVEY_MBOX_IN_TEMPLATE_LISTS, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    })

    const [ rows, setRows ] = useState(data ? getData(data.surveyMboxInTemplateLists, edit, selected) : [])


    useEffect(() => {
        if (data) {
            setRows(getData(data.surveyMboxInTemplateLists, edit, selected))
        }
    }, [selected, edit, data])

    useEffect(() => {
        if (loading) dispatch({ type: BACKDROPSHOW })
        else dispatch({ type: BACKDROPHIDE })
    }, [dispatch, loading])

    return (
        <MyBox>
            <WrapperStyled>
                {
                    edit && <AddComponent selected={categorys.find(c => c.survey_template_category_no === selected) ? selected : 0} refetch={refetch}/>
                }
                { 
                    rows.map(c => (
                        <ItemComponent 
                            key={c.survey_no} 
                            {...c} 
                            categorys={categorys} 
                            selected={selected} 
                            refetch={refetch} 
                            edit={edit} 
                            survey_workspace_no={survey_workspace_no}
                        />
                    ))
                }
            </WrapperStyled>
        </MyBox>    
    )
}

export default memo(ItemsComponent)