// iframe에 dialog가 있을경우 전체를 보여줘야할경우 사용한다.
// props로 넘기기 힘드니 자체 컴포넌트에서 구현가능한 dialog만 처리한다.
import { Suspense, lazy } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { HIDE } from 'reducer/iframeDialog'
import { Dialog } from '@material-ui/core'
import withBreakPoints from 'hoc/BreakPoints'

const SurveyCreateShareSnsCachePopup = lazy(() => import('component/Survey/Create/Share/SnsCachePopup'))
const SurveyCreateShareThumbnailExplainPopup = lazy(() => import('component/Survey/Create/Share/ThumbnailExplainPopup'))

function DialogComponent(props) {
    
    const { breakpoints } = props 
    const { xs } = breakpoints

    const iframeDialog = useSelector(state => state.iframeDialog)

    const dispatch = useDispatch()

    const onClose = () => {
        dispatch({type: HIDE})
    }

    const components = {
        SurveyCreateShareSnsCachePopup: SurveyCreateShareSnsCachePopup,
        SurveyCreateShareThumbnailExplainPopup: SurveyCreateShareThumbnailExplainPopup
    }

    const Component = components[iframeDialog.name]

    return (
        
        <Suspense fallback="">
            <Dialog
                fullScreen={xs}
                open={iframeDialog.show}
                onClose={onClose}
                aria-labelledby="responsive-iframeDialog"
            >
                {
                    iframeDialog.name && <Component onClose={onClose}/>
                }
            </Dialog>
        </Suspense>
    )
}

export default withBreakPoints(DialogComponent)