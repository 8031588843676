import { memo } from 'react'
import { useIntl } from 'react-intl'
import ConfirmComponent from 'component/Survey/Edit/Confirm'
import {
    SurveyEditTabsComponent,
    SurveyEditTabComponent,
    SurveyEditSubTabsComponent,
    SurveyEditQuestionComponent,
    SurveyEdit06ColumnComponent, 
    SurveyEdit06Component,
    SurveyEdit06OptionComponent
} from 'component'

const Component = (props) => {
    const { method } = props
    const { formatMessage: f } = useIntl()

    const tabs = [f({id: 'container.Survey.Edit.06.tabs.0'}), f({id: 'container.Survey.Edit.06.tabs.1'})]
    const subTabs = [
        f({id: 'container.Survey.Edit.06.subTabs.0'}), 
        f({id: 'container.Survey.Edit.06.subTabs.1'})
    ]

    if (method === 'one') subTabs.push(f({id: 'container.Survey.Edit.06.subTabs.2'}))

    return (
        <>
            <SurveyEditTabsComponent tabs={tabs}/>
            <SurveyEditTabComponent index={0}>
                <SurveyEditQuestionComponent/>
            
                <SurveyEditSubTabsComponent subTabs={subTabs}/>
                <SurveyEdit06Component/>
                <SurveyEdit06ColumnComponent/>
                <ConfirmComponent index={2}/>
            </SurveyEditTabComponent>
            <SurveyEditTabComponent index={1}>
                <SurveyEdit06OptionComponent/>
            </SurveyEditTabComponent>
        </>
    )
}

export default memo(Component)