import { useCallback, memo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import { Levels } from 'ts-policy'
import Box, { BoxProps } from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { FileUploadLimitSize } from 'ts-policy'
import { verify, TokenTypes } from 'hooks/useTokenInfo'
import withSurveyFetchData from 'hoc/Survey/FetchData'
import FileUploadBox from 'styled/Custom/FileUploadBox'
import Answer from 'styled/Custom/Answer'
import { OnChange } from './Component'
import { OnChangeReject } from './Dropzone'

const getLimitSize = (level: Levels) => {
    
    // 미리보기에서 사용
    if (process.env.REACT_APP_ADMIN_DOMAIN === window.location.hostname) {
        const token: TokenTypes | '' = verify(localStorage.getItem('authorization'))

        if (!token) return FileUploadLimitSize[Levels.Level0]

        return FileUploadLimitSize[token.levels]
    }

    // 라이브에서 사용
    if (!FileUploadLimitSize[level]) return FileUploadLimitSize[Levels.Level0]
 
    return FileUploadLimitSize[level]
}

interface Props {
    exts: string[];
    mode: string;
    levels: Levels;
    onChange: OnChange;
    onChangeReject: OnChangeReject;
    propsFetchData: {
        surveyAnalysis19IsPossibleFileUpload: boolean;
    }
}

interface MyPossibleMessageProps extends BoxProps {
    is_view: number;
}

// 중복 확장자 간단하게 처리
export function getExtName(exts:string[]) {
    return exts.reduce((acc, c, i) => {

        if (/,/.test(c)) {
            if (/jpg/.test(c)) return [...acc, 'jp(e)g']
            else {
                const [ file, _ ] = c.split(',')
                 return [...acc, `${file}(x)`]
            }
        }
  
   		 return [...acc, c]
    }, [])
}

const MyPossibleMessage = withStyles({
    root: (props: MyPossibleMessageProps) => {
        const { is_view } = props
        return {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, .9)',
            color: 'white',
            fontSize: 16,
            borderRadius: 5,
            zIndex: 1,
            display: is_view ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
})((props: MyPossibleMessageProps) => <Box {...props}/>)

function FileUploadComponent(props: Props) {
    const { exts, mode, levels, onChange, onChangeReject, propsFetchData } = props
    const { surveyAnalysis19IsPossibleFileUpload } = propsFetchData
    
    const { formatMessage: f } = useIntl() 

    const limitSize = getLimitSize(levels)

    const accept = exts.reduce((acc, c) => {
        return [...acc, ...c.split(',')]
    }, []).map(c => `.${c.trim()}`).join(', ')

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(function(myFile: File) {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
      
                onChange(myFile)
     
            }
            reader.readAsArrayBuffer(myFile)
        })
        if (acceptedFiles.length > 0) onChangeReject('')
    }, [onChange, onChangeReject])

    const onDropRejected = useCallback(rejectFiles => {
        const [{ errors }] = rejectFiles
        const { code, message } = errors[0]

        let customMessage = message
        switch(code) {
            case 'file-invalid-type': 
                customMessage = f({id: 'component.Survey.Modules.19.FileUpload.fileInvalidType'}, {accept: accept.toUpperCase().replace(/\./g, '')})
                break
            case 'file-too-large': 
                customMessage = f({id: 'component.Survey.Modules.19.FileUpload.fileTooLarge'}, {size: (limitSize / 1024 / 1024)})
                break
            default:
        }

        if (customMessage) onChangeReject(customMessage)
    }, [accept, f, limitSize, onChangeReject])
    
    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        multiple: false,
        maxSize: limitSize,
        minSize: 0,
        accept
    })

    return (
        <div style={{position: 'relative'}} {...getRootProps()}>
            <input {...getInputProps()}/>
            <FileUploadBox>
                <div>
                    <Answer>{f({id: 'component.Survey.Modules.19.FileUpload.placeholder'})}</Answer>
                </div>
                <div>
                    <Answer style={{fontSize: 12}}>{parseHtmlIntl(f({id: 'component.Survey.Modules.19.FileUpload.tip.title.0'}))}</Answer>
                    <Answer style={{fontSize: 11}}>{parseHtmlIntl(f({id: 'component.Survey.Modules.19.FileUpload.tip.explain.0'}, {exts: getExtName(exts).join(', ').toUpperCase()}))}</Answer>
                    <Answer style={{fontSize: 12, marginTop: 8}}>{parseHtmlIntl(f({id: 'component.Survey.Modules.19.FileUpload.tip.title.1'}))}</Answer>
                    <Answer style={{fontSize: 11}}>{parseHtmlIntl(f({id: 'component.Survey.Modules.19.FileUpload.tip.explain.1'}, {size: (limitSize / 1024 / 1024)}))}</Answer>
                </div>
            </FileUploadBox>           
            <MyPossibleMessage is_view={(mode === 'live' && levels === Levels.Level0 && !surveyAnalysis19IsPossibleFileUpload) ? 1 : 0} onClick={(e) => e.stopPropagation()}>
                {parseHtmlIntl(f({id: 'component.Survey.Modules.19.FileUpload.not'}))}
            </MyPossibleMessage>
        </div>
    )
}

export default withSurveyFetchData('live')({
    surveyAnalysis19IsPossibleFileUpload: true,
    _fetchPolicy: {
        surveyAnalysis19IsPossibleFileUpload: 'cache-first'
    }
})(false)(memo(FileUploadComponent))