import { memo } from 'react'
import AutoSizer from "react-virtualized-auto-sizer"
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Answer from 'styled/Custom/Answer'
import IconComponent from 'styled/Custom/Icon'
import Tooltip from 'styled/Custom/Tooltip'
import { OnChange } from './Component'
import FileNameGrindContainer from './FileNameGridContainer'

interface Props {
    name: string;
    onRemove: OnChange
}

function FileName(props: Props) {
    const { name, onRemove } = props

    return (
        <FileNameGrindContainer>
            <Grid item xs={10} md={10}>
                <Tooltip title={name} isMobileClick={true}>
                    <div style={{height: 16}}>
                        <AutoSizer>
                            {({ width }: {width: number}) => {
                                return (       
                                    <Answer 
                                        style={{ 
                                            width, 
                                            minWidth: 100,
                                            fontSize: 13,
                                            textOverflow: 'ellipsis',
                                            display: 'inline-block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {name}
                                    </Answer>
                                )
                            }}
                        </AutoSizer>
                    </div>
                </Tooltip>
            </Grid>
            <Grid item xs={2} md={2}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', paddingLeft: 10}}>
                    <IconButton size="small" onClick={() => onRemove('')}>
                        <IconComponent kind='answer_sbutton' size="small">
                            <DeleteIcon/>
                        </IconComponent>
                    </IconButton>
                </div>
            </Grid>
        </FileNameGrindContainer>
    )
}

export default memo(FileName)