import { combineReducers } from 'redux'
import alert from './alert'
import iframeDialog from './iframeDialog'
import alertSnackbar from './alertSnackbar'
import confirm from './confirm'
import dialog from './dialog'
import popover from './popover'
import loading from './loading'
import loadingLine from './loadingLine'
import backdrop from './backdrop'
import backdropHide from './backdropHide'
import errorPage from './errorPage'
import answerErrorPage from './answerErrorPage'
import auth from './auth'
import loginMount from './loginMount'
import lang from './lang'
import summary from './summary'
import analysisSummaryTargetUser from './analysis/summary/targetUser'
import analysisSummaryRefresh from './analysis/summary/refresh'
import analysisSummaryOrder from './analysis/summary/order'
import analysisRowsOrder from './analysis/rows/order'
import analysisRowsChoice from './analysis/rows/choice'
import analysisRowsSearch from './analysis/rows/search'
import analysisRowsPersonal from './analysis/rows/personal'
import analysisRowsPersonalIds from './analysis/rows/personalIds'
import analysisRowsData from './analysis/rows/data'
import analysisRowsExpandRow from './analysis/rows/expandRow'
import analysisSummaryRows from './analysis/summary/rows'
import analysisFilterData from './analysis/filter/data'
import analysisFilterSaveData from './analysis/filter/saveData'
import surveyCreateMenu from './survey/create/menu'
import surveyCreateMenuEndingMenu from './survey/create/menu/ending/menu'
import surveyCreateMenuEndingRedirect from './survey/create/menu/ending/redirect'
import surveyCreateModuleTip from './survey/create/moduleTip'
import surveyCreateOpen from './survey/create/open'
import surveyCreateModuleCopy from './survey/create/module/copy'
import surveyCreateModuleIsEdit from './survey/create/module/isEdit'
import surveyCreateLogicMenuAlarm from './survey/create/logic/menuAlarm'
import surveyCreateLogicMenuSelected from './survey/create/logic/menuSelected'
import surveyCreateLogicJump from './survey/create/logic/jump'
import surveyCreateLogicJumpNull from './survey/create/logic/jumpNull'
import surveyCreateLogicJumpFocus from './survey/create/logic/jumpFocus'
import surveyCreateLogicJumpCollapse from './survey/create/logic/jumpCollapse'
import surveyCreateLogicJumpEqual from './survey/create/logic/jumpEqual'
import surveyCreateLogicJumpReset from './survey/create/logic/jumpReset'
import surveyCreateLogicJumpPoint from './survey/create/logic/jumpPoint'
import surveyCreateLogicJumpPointText from './survey/create/logic/jumpPointText'
import surveyCreateLogicJumpPointSave from './survey/create/logic/jumpPointSave'
import surveyCreateLogicJumpPointError from './survey/create/logic/jumpPointError'
import surveyCreateLogicJumpPointInit from './survey/create/logic/jumpPointInit' 
import surveyCreateLogicJumpPointAllPoint from './survey/create/logic/jumpPointAllPoint'
import surveyCreateLogicJumpExampleCount from './survey/create/logic/jumpExampleCount'
import surveyCreateLogicJumpExampleCountText from './survey/create/logic/jumpExampleCountText'
import surveyCreateLogicJumpExampleCountInit from './survey/create/logic/jumpExampleCountInit'
import surveyCreateLogicJumpEnding from './survey/create/logic/jumpEnding'
import surveyWorkspaceOrder from './survey/workspace/order'
import surveyWorkspaceResponseCount from './survey/workspace/responseCount'
import surveyWorkspaceLeftUtilityDownloadIng from './survey/workspace/left/utility/download/ing'
import surveyLiveMulti from './survey/live/multi'
import surveyLiveCaution from './survey/live/caution'
import surveyLiveConfirm09 from './survey/live/confirm09'
import surveyModulesCover from './survey/modules/cover'
import paymentChoicePay from './payment/choice/pay'

const rootReducer = combineReducers({
    alert,
    iframeDialog,
    alertSnackbar,
    confirm,
    dialog,
    popover,
    loading,
    loadingLine,
    backdrop,
    backdropHide,
    errorPage,
    answerErrorPage,
    auth,
    loginMount,
    lang,
    summary,
    analysisSummaryTargetUser,
    analysisSummaryRefresh,
    analysisSummaryOrder,
    analysisSummaryRows,
    analysisRowsOrder,
    analysisRowsChoice,
    analysisRowsSearch,
    analysisRowsPersonal,
    analysisRowsPersonalIds,
    analysisRowsData,
    analysisRowsExpandRow,
    analysisFilterData,
    analysisFilterSaveData,
    surveyCreateMenu,
    surveyCreateMenuEndingMenu,
    surveyCreateMenuEndingRedirect,
    surveyCreateModuleTip,
    surveyCreateOpen,
    surveyCreateModuleCopy,
    surveyCreateModuleIsEdit,
    surveyCreateLogicMenuAlarm,
    surveyCreateLogicMenuSelected,
    surveyCreateLogicJump,
    surveyCreateLogicJumpNull,
    surveyCreateLogicJumpFocus,
    surveyCreateLogicJumpCollapse,
    surveyCreateLogicJumpEqual,
    surveyCreateLogicJumpReset,
    surveyCreateLogicJumpPoint,
    surveyCreateLogicJumpPointText,
    surveyCreateLogicJumpPointSave,
    surveyCreateLogicJumpPointError,
    surveyCreateLogicJumpPointInit,
    surveyCreateLogicJumpExampleCount,
    surveyCreateLogicJumpExampleCountText,
    surveyCreateLogicJumpExampleCountInit,
    surveyCreateLogicJumpPointAllPoint,
    surveyCreateLogicJumpEnding,
    surveyWorkspaceOrder,
    surveyWorkspaceResponseCount,
    surveyWorkspaceLeftUtilityDownloadIng,
    surveyLiveMulti,
    surveyLiveCaution,
    surveyLiveConfirm09,
    surveyModulesCover,
    paymentChoicePay
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>