// 인사이트 데이터 가져오기 (device별)

import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/react-hooks'
import { getName } from 'country-list'
import { parseDomain, ParseResultType } from "parse-domain"
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_ANALYSIS_INSIGHT } from 'gql/survey_analysis_users'
import { secondsToTime } from 'utils/survey'
import { sortObject } from 'ts-utils'
import { parseGraphqlDate, parseGraphqlDeviceType } from 'ts-utils/summary'
import { getDate } from 'styled/Survey/Analysis/Calendar'

const [ ListoDomain ] = process.env.REACT_APP_DOMAIN.split('.')

function getReferer(data) {
    const obj = { domains: {}, conturys: {}, citys: {} }

    function counting(kind, key) {
        let customKey = key
        if (key === null) customKey = 'etc'

        if (typeof obj[kind][customKey] === 'undefined') obj[kind][customKey] = 1
        else obj[kind][customKey] += 1
    }

    for (let i=0; i<data.length; i++) {
        const { url, contury, city } = data[i]

        // 국가, 도시 카운트
        counting('conturys', contury ? getName(contury) || contury : null)
        counting('citys', city)

        try {
            const { hostname } = new URL(url ? `https://${url}` : null)
            const parseResult = parseDomain(hostname)

            // Check if the domain is listed in the public suffix list
            if (parseResult.type === ParseResultType.Listed) {
                const { domain, subDomains } = parseResult
         
                const isListo = domain.indexOf(ListoDomain) !== -1

                let key = domain
                if (subDomains.length > 0) {
                    const subDomain = subDomains.join('.')

                    // www 도메인은 없는셈 치자
                    if (subDomain === 'www') key = domain
                    else key = `${subDomains.join('.')}.${domain}`
                }
                       
                // 도메인 카운트
                counting('domains', isListo ? 'Direct' : key) 
            } else {
                // 도메인을 못찾을경우 ii 카운트 한다
                counting('domains', 'Direct')
            }
        } catch(e) {
            // 도메인을 못찾을경우 etc로 카운트 한다
            counting('domains', 'Direct')
        }
    }

    return {...obj}
}

const initialState = {
    connectCount: 0, 
    startCount: 0, 
    completeAvg: 0,
    completeCount: 0,
    completeAvgTime: '00:00:00',
    deviceConnectCount: {
        browser: 0,
        tablet: 0,
        mobile: 0,
        other: 0
    },
    groupDayConnectCount: {
        _00: 0, _01: 0, _02: 0, _03: 0, _04: 0, _05: 0, _06: 0, _07: 0, _08: 0, _09: 0,_10: 0, _11: 0, 
        _12: 0, _13: 0, _14: 0, _15: 0, _16: 0, _17: 0, _18: 0, _19: 0, _20: 0, _21: 0, _22: 0,_23: 0
    },
    groupWeekDayConnectCount: {
        _0: 0, _1: 0, _2: 0, _3: 0, _4: 0, _5: 0, _6: 0
    },
    groupTimeConnectCount: {
        _0: 0, _1: 0, _2: 0, _3: 0, _4: 0, _5: 0, _6: 0
    },
    refererDomainCount: {},
    refererConturyCount: {},
    refererCityCount: {}
}

function parsing(data) {
    if (!data) return initialState

    const { surveyAnalysisInsight: insightData } = data

    const { 
        connectCount, 
        startCount, 
        completeCount, 
        completeInterval, 
        deviceConnectCount, 
        groupDayConnectCount,
        groupWeekDayConnectCount,
        groupTimeConnectCount,
        refererConnectCount
    } = insightData

    const { domains, conturys, citys } = getReferer(refererConnectCount)

    // 접속수
    const connectSumCount = connectCount

    const startSumCount = startCount

    const completeSumCount = completeCount

    // 완료율
    const completeAvg = (completeSumCount === 0 || startSumCount === 0) ? 0 : ((completeSumCount / startSumCount) * 100).toFixed(2)

    // 완료평균시간
    const completeAvgTime= secondsToTime(completeInterval)

    /*
    ** 접속수: connectSumCount, 시작수: startSumCount, 완료율: completeAvg, 완료 평균 시간: completeAvgTime
    ** 디바이스별 접속수: deviceConnectCount, 7일간 접속수: groupDayConnectCount, 
    ** 요일별 접속수 groupWeekDayConnectCount, 시간별 접속수: groupTimeConnectCount
    */
    return {
        connectCount: connectSumCount,
        startCount: startSumCount,
        completeAvg,
        completeAvgTime,
        completeCount: completeSumCount,
        deviceConnectCount, 
        groupDayConnectCount,
        groupWeekDayConnectCount,
        groupTimeConnectCount,
        refererDomainCount: sortObject({obj: domains, type: 'desc'}),
        refererConturyCount: sortObject({obj: conturys, type: 'desc'}),
        refererCityCount: sortObject({obj: citys, type: 'desc'})
    }
}


const useInsightData = (props) => {

    const { survey_no } = props
 
    const dispatch = useDispatch()
    
    const [ insight, setInsight ] = useState(initialState)

    const [ getInsight, { loading, refetch } ] = useLazyQuery(GET_SURVEY_ANALYSIS_INSIGHT, {
        onCompleted: (res) => {
            setInsight(parsing(res))
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true
    })

    const getData = useCallback((props) => {
        const { date, deviceType } = props
        
        const { sdate, edate } = getDate(date)
        const parseDate = parseGraphqlDate(sdate, edate)
         
        const variables = {
            survey_no,
            sdate: parseDate.sdate, 
            edate: parseDate.edate,
            deviceType: parseGraphqlDeviceType(deviceType)
        }

        getInsight({ variables }) 
    }, [ survey_no, getInsight ]) 

    return [ insight, getData, { loading, refetch } ]
}
 
export default useInsightData
