import gql from 'graphql-tag'

export const CREATE_USER_TAX_COMPANY = gql`
    mutation CreateUserTaxCompany($input: CreateUserTaxCompany!) {
        createUserTaxCompany(input: $input)
    }
`

export const CREATE_USER_TAX_PERSONAL = gql`
    mutation CreateUserTaxPersonal($input: CreateUserTaxPersonal!) {
        createUserTaxPersonal(input: $input)
    }
`

export const CREATE_USER_TAX_NONE = gql`
    mutation CreateUserTaxNone($input: CreateUserTaxNone!) {
        createUserTaxNone(input: $input)
    }
`

export const GET_USER_TAX_RECENT = gql`
    query UserTaxRecent {
        userTaxRecent {
            company {
                id_no
                name
                email
                company_name
            }
            personal {
                id_no
                name
                email
                mobile_no
            }
        }
    }
`