import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { SurveyLiveContainer } from 'container'
import { withSurveyCheckPolicyEmail }  from 'hoc'
import { SHOW as ANSWERERRORPAGESHOW } from 'reducer/answerErrorPage'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { useIntl } from 'react-intl'
import { getTokenname } from 'utils'
import { getDevice } from 'utils/survey'
import { CREATE_SURVEY_ANALYSIS_USERS_EMAIL } from 'gql/survey_analysis_users'
import { getMessage } from './Ip'

const method = 'email'
const mode = 'live'
const device = getDevice()

const Component = (props) => {
    const { survey_no, levels, policyType } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const location = useLocation()
    const history = useHistory()
    const { args } = useParams()

    const { email, name } = queryString.parse(location.search)

    const tokenname = getTokenname(survey_no, 'email')

    const [ token, setToken ] = useState(localStorage.getItem(tokenname))


    const [ createSurveyAnalysisUsers_EMAIL ] = useMutation(CREATE_SURVEY_ANALYSIS_USERS_EMAIL, {
        onCompleted: async (data) => {
            const _token = data.createSurveyAnalysisUsers_EMAIL.token

            localStorage.setItem(tokenname, _token)
            setToken(_token)
        },
        onError: (e) => {
            dispatch({ type: ERRORPAGESHOW, f, logoHref: process.env.REACT_APP_LANDING, buttonHref: process.env.REACT_APP_LANDING})
        }
    })


    useEffect(() => {
        async function createUser() {
            createSurveyAnalysisUsers_EMAIL({ variables: { survey_no, email, name, device, mode }})
        }

        // 이메일 응답은 이메일 기준이다. PC 기준이 아니다. 같은 이메일 값이 있다면 어느 PC에서도 동일하게 보여야한다 (토큰값이 어느 PC에서도 동일하다)

        if ((policyType.type === 'success' || policyType.type === 'answered_end') && survey_no && !token) createUser()

    }, [policyType, createSurveyAnalysisUsers_EMAIL, survey_no, token, email, name, args, history])

    
    useEffect(() => {

        switch(policyType.type) {
            case 'response':
            case 'max_response':
            case 'open':
            case 'end_date':
            case 'cut_people':
            case 'ip_blocks':
            case 'died':
                dispatch({ 
                    type: ANSWERERRORPAGESHOW, 
                    message: getMessage({...policyType, f}),
                    logoHide: true,
                    buttonName: policyType.button,
                    buttonHref: policyType.link,
                    f,
                    iconType: 'private'
                })
                break
            default:
                dispatch({ type: BACKDROPSHOW })
                break
        }
    }, [policyType,  f, dispatch])

    return (
        <>                                
        {        
            ((policyType.type === 'success' || policyType.type === 'answered_end') && survey_no && token) && (
                <SurveyLiveContainer policyType={policyType.type} method={method} survey_no={survey_no} levels={levels} token={token}/>
            )
        }
        </>
    )
}

export default withSurveyCheckPolicyEmail()(Component)