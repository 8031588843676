import { useMemo, useContext } from 'react'
import Answer from 'styled/Custom/Answer'
import Checkbox from 'styled/Custom/Checkbox'
import { useIntl } from 'react-intl'
import { Context } from './Component'

function MobileAnswerItemComponent(props) {
    const { selected, disabled, answer } = props

    const { getClass } = useContext(Context)

    const { formatMessage: f } = useIntl()

    const className = getClass(selected, disabled)

    return (
        <> 
        {
            useMemo(() => {
                return (
                    <>
                    <div><Checkbox checked={selected} disabled={disabled}/></div>
                    <div className={className}>{<Answer>{answer || f({id: 'component.Survey.Modules.04.answer'})}</Answer>}</div>
                    </>
                )
            }, [selected, disabled, answer, className, f])
        }   
        </>
    )
}

export default MobileAnswerItemComponent