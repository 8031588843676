import { MouseEvent } from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'
import { withStyles } from '@material-ui/styles'

interface MyBoxProps extends BoxProps {
    selected: boolean;
    className?: string;
    onClick: (e: MouseEvent<HTMLElement>) => void
}

export const AgreeBox = withStyles({
    root: (props) => {
        const { selected } = props

        return {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'all .3s',
            backgroundColor: selected ? 'rgba(0, 0, 0, .2)' : 'transparent',
            padding: selected ? '5px 8px 5px 5px' : '5px 1px 5px 1px',
            borderRadius: 5,
            '& img, & svg': {
                display: 'block'
            },
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, .2)',
                padding: '5px 8px 5px 5px'
            },
            '& > div + div': {
                marginLeft: 10
            },
            '& > div:last-child': {
                marginTop: 1,
                webkitMarginTop: 1
            }
        }
    }
})(({className="", onClick, ...other}: MyBoxProps) => <Box {...other} className={className} onClick={onClick}/>)