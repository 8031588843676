// 작업함 정렬
import jwt from 'jsonwebtoken'
import { getSearchCookie, setSearchCookie } from 'ts-utils/summary'

const getCookieName = () => {
    const authorization = localStorage.getItem('authorization')
    if (!authorization) return null

    const token:any = jwt.decode(authorization)

    return `workspace-order-${token.user_no}`
}

export const CHANGE = 'survey/workspace/change'

interface InitProps {
    value: number;
}

const initState: InitProps = {
    value: 0
}

interface ActionProps {
    type: string;    
    value: number;
}

export default (state = initState, action: ActionProps) => {
    const cookieName = getCookieName()
    switch(action.type) {
        case CHANGE:
            if (cookieName) setSearchCookie(cookieName, action.value)

            return {
                value: action.value
            }
        default:
            const value = cookieName ? getSearchCookie(cookieName) : initState.value

            return {
                ...state,
                value: value || initState.value
            }
    }
}