import { lazy, Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AuthRoute } from 'react-router-auth'
import { LangComponent } from 'component'
import { Alert, AlertSnackbar, Confirm, Popover, Loading, ErrorPage, BackDrop, AnswerErrorPage, IframeDialog, ModuleTipPopup, Dialog } from 'ccomponent'
import * as googleConfig from 'config/google.json'
import TagManager from 'react-gtm-module'
import FileDownloadContainer from 'container/Survey/Create/NewAnalysis/Rows/FileDownload'

const ObstacleContainer = lazy(() => import('container/Obstacle/Container'))
const LoginContainer = lazy(() => import('container/Login/Container'))
const LoginIngContainer = lazy(() => import('container/Login/Ing'))
const LoginRedirectContainer = lazy(() => import('container/Login/Redirect'))
const LoginNewEmailAuthContainer = lazy(() => import('container/Login/NewEmailAuth'))
const MyAccountContainer = lazy(() => import('container/MyAccount/Container'))
const MyAccountContextContainer = lazy(() => import('container/MyAccount/Context'))
const SurveyCreateContainer = lazy(() => import('container/Survey/Create/Container'))
const SurveyWorkspaceContainer = lazy(() => import('container/Survey/Workspace/Container'))
const SurveyCreateShareFrameContainer = lazy(() => import('container/Survey/Create/Share/Frame'))
const SurveyLiveGuardContainer = lazy(() => import('container/Survey/Live/Guard'))
const SurveyLiveSampleContainer = lazy(() => import('container/Survey/Live/Sample'))
const SurveyPreviewIpContainer = lazy(() => import('container/Survey/Preview/Ip'))
const SurveyAnalysisSummaryReportLangContainer = lazy(() => import('container/Survey/Create/NewAnalysis/Summary/ReportLang'))
const SurveyAnalysisSummaryPrintContainer = lazy(() => import('container/Survey/Create/NewAnalysis/Summary/Print'))
const PaymentContainer = lazy(() => import('container/Payment/Container'))
const PaysetmentPaysetContainer = lazy(() => import('container/Payment/Payset'))
const SurveyAnalysisContainer = lazy(() => import('container/Survey/Analysis/Container'))

const Page404 = lazy(() => import('component/Page404'))
const ApplicationGoogleSheetCalbackComponent = lazy(() => import('component/Application/GoogleSheet/Callback'))
const ApplicationSlackPushCalbackComponent = lazy(() => import('component/Application/SlackPush/Callback'))
const ErrorComponent = lazy(() => import('component/Error/Component'))

const TossCallComponent = lazy(() => import('component/Payment/Choice/api/TossCall'))
const TossSuccessComponent = lazy(() => import('component/Payment/Choice/api/TossSuccess'))
const TossCloseComponent = lazy(() => import('component/Payment/Choice/api/TossClose'))

const SLoginContainer = lazy(() => import('container/SLogin'))

const suspenseMsg = ``

const redirectTo = '/logining'

const App = () =>  {

    const isAuth = localStorage.getItem('authorization') ? true : false

    return (
        <>
        <Router>
            <Switch>
            {
                /*
                ** 장애 발생 시 사용자에에게 안내를 보여야할때 주석을 해제한다. 
                <Route path='/' component={props => <Suspense fallback={suspenseMsg}><ObstacleContainer {...props}/></Suspense>}/>         
                */
            }
            <Route exact path='/secrect001001301102/login' component={props => <Suspense fallback={suspenseMsg}><SLoginContainer {...props}/></Suspense>}/>            

            <Route exact path='/login' component={props => <Suspense fallback={suspenseMsg}><LoginContainer  {...props}/></Suspense>}/>
            <Route exact path='/logining' component={props => <Suspense fallback="Logining..."><LoginIngContainer  {...props}/></Suspense>}/>
            <Route exact path='/redirect' component={props => <Suspense fallback="Redirect..."><LoginRedirectContainer  {...props}/></Suspense>}/>
            <Route exact path='/(rs|r|c)/:args' component={props => <Suspense fallback={suspenseMsg}><SurveyLiveGuardContainer root="ip" {...props}/></Suspense>}/>
            <Route exact path='/(ro|o)/:args' component={props => <Suspense fallback={suspenseMsg}><SurveyLiveGuardContainer root="ip" {...props}/></Suspense>}/>
            <Route exact path='/(re|e)/:args' component={props => <Suspense fallback={suspenseMsg}><SurveyLiveGuardContainer root="email" {...props}/></Suspense>}/>

            <Route 
                exact 
                path='/sample/:args' 
                component={props => (
                    <Suspense fallback={suspenseMsg}>
                        <SurveyLiveSampleContainer>
                            <SurveyLiveGuardContainer root="ip" {...props}/>
                        </SurveyLiveSampleContainer>
                    </Suspense>
                )}
            />
            
            <Route exact path='/ts/:args' component={props => <Suspense fallback={suspenseMsg}><SurveyPreviewIpContainer {...props}/></Suspense>}/>

            <Route exact path='/(preport|p)/:analysisArgs' component={props => <Suspense fallback={suspenseMsg}><SurveyAnalysisSummaryReportLangContainer {...props}/></Suspense>}/>
            <Route exact path='/new-email-auth/:no' component={props => <Suspense fallback="Logining..."><LoginNewEmailAuthContainer  {...props}/></Suspense>}/>
            
            <AuthRoute exact path='/' component={props => <Suspense fallback={suspenseMsg}><SurveyWorkspaceContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth} />

            <AuthRoute path='/myaccount' exact component={props => <Suspense fallback={suspenseMsg}><MyAccountContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth} />
            <AuthRoute path='/myaccount/frame' exact component={props => <Suspense fallback={suspenseMsg}><MyAccountContextContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth} />
            
            <AuthRoute path='/myaccount' exact component={props => <Suspense fallback={suspenseMsg}><MyAccountContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth} />

            <AuthRoute 
                path='/application/googlesheet/callback/:survey_no' 
                exact 
                component={props => <Suspense fallback={suspenseMsg}><ApplicationGoogleSheetCalbackComponent {...props}/></Suspense>} 
                redirectTo={redirectTo} 
                authenticated={isAuth} 
            />

            <AuthRoute 
                path='/application/slack/callback/:survey_no' 
                exact 
                component={props => <Suspense fallback={suspenseMsg}><ApplicationSlackPushCalbackComponent {...props}/></Suspense>} 
                redirectTo={redirectTo} 
                authenticated={isAuth} 
            />

            <AuthRoute 
                path='/error/:kind' 
                exact 
                component={props => <Suspense fallback={suspenseMsg}><ErrorComponent {...props}/></Suspense>} 
                redirectTo={redirectTo} 
                authenticated={isAuth} 
            />

            <AuthRoute path='/workspace' exact component={props => <Suspense fallback={suspenseMsg}><SurveyWorkspaceContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth} />

            <AuthRoute path='/analysis/:survey_workspace_no/:survey_no' exact component={props => <Suspense fallback={suspenseMsg}><SurveyAnalysisContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth} />

            <AuthRoute path='/survey/form/:step(create|share|analysis|intergration)/:survey_no' exact component={props => <Suspense fallback={suspenseMsg}><SurveyCreateContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth}/> 
            <AuthRoute path='/survey/create/share/frame/:survey_no' exact component={props => <Suspense fallback={suspenseMsg}><SurveyCreateShareFrameContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth}/>

            <AuthRoute path='/payment/call' exact component={props => <Suspense fallback={suspenseMsg}><TossCallComponent {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth}/>
            <AuthRoute path='/payment/success' exact component={props => <Suspense fallback={suspenseMsg}><TossSuccessComponent {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth}/>
            <AuthRoute path='/payment/close' exact component={props => <Suspense fallback={suspenseMsg}><TossCloseComponent {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth}/>

            <AuthRoute path='/payment/:step' exact component={props => <Suspense fallback={suspenseMsg}><PaymentContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth}/>

            <AuthRoute path='/payset' exact component={props => <Suspense fallback={suspenseMsg}><PaysetmentPaysetContainer {...props}/></Suspense>} redirectTo={redirectTo} authenticated={isAuth}/>
            <AuthRoute 
                exact 
                path='/print/summary/:survey_no' 
                component={props => <Suspense fallback={suspenseMsg}><SurveyAnalysisSummaryPrintContainer {...props}/></Suspense>}  
                redirectTo={redirectTo} 
                authenticated={isAuth}
            />
            
            <Route exact path='/file/download' component={props => <Suspense fallback={suspenseMsg}><FileDownloadContainer {...props}/></Suspense>}/>
          


            <Route component={props => <Suspense fallback={suspenseMsg}><Page404 {...props}/></Suspense>}/>
            </Switch>
        </Router>
        <Alert/>
        <AlertSnackbar/>
        <Confirm/>
        <Dialog/>
        <Loading/>
        <BackDrop/>
        <ErrorPage/>
        <AnswerErrorPage/>
        <Popover/>
        <IframeDialog/>
        <ModuleTipPopup/>
        </>
    )
}

export default () => {
    const { lang } = useSelector(state => state.lang)
    const _ = useSelector(state => state.loginMount)

    useEffect(() => {
        TagManager.initialize({ gtmId: googleConfig.gtm })
    }, [])
   
    return (
        <LangComponent lang={lang}>
            <App/>
        </LangComponent>
    )
}