import { memo } from 'react'
import Checkbox from 'styled/Custom/Checkbox'
import { isEqual } from 'lodash-es'

function RadioComponent(props) {
    const { survey_module_no, selected, disabled } = props
    
    return (
        <Checkbox checked={selected} disabled={disabled} key={`_02-${survey_module_no}`} id={`_02-${survey_module_no}`}/>
    )
}

export default memo(RadioComponent, (prev, next) => {
    return  isEqual(prev.survey_module_no, next.survey_module_no) &&
    isEqual(prev.selected, next.selected) && 
    isEqual(prev.disabled, next.disabled) 
})
