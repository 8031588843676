/**
 * args로 survey_no 구하기
 */
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { useLocation, useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_NO } from 'gql/survey_mbox'

export default mode => WrappedComponent => props => {
    const dispatch = useDispatch()

    const params = useParams()
    const location = useLocation()

    const { args } = params

    const { data } = useQuery(GET_SURVEY_NO, {
        variables: { 
            args,
            custom: location.pathname.search('/c/') === 0
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: mode || 'network-only'
    })

    if (!data) return null

    const { survey_no, levels } = data.surveyNo

    return <WrappedComponent survey_no={survey_no} levels={levels} {...props}/>
}