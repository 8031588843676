import { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import Answer from 'styled/Custom/Answer'
import { MyBox } from '../03/AnswerItem'

function AnswerItemComponent(props) {
    const { answer } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        {
            useMemo(() => {
                return (
                    <div className="center">
                        <MyBox>
                            <Answer>{answer || f({id: 'component.Survey.Modules.04.answer'})}</Answer>
                        </MyBox>
                    </div>
                )
            }, [answer, f])
        }
        </>
    )
}

export default memo(AnswerItemComponent, (prev, next) => {
    return  isEqual(prev.answer, next.answer)
})