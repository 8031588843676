import { useIntl } from 'react-intl'
import { Kind, Levels, Month } from 'ts-policy'

interface Props {
    kind: Kind | null;
    level: Levels;
    month: Month | 0;

}

function useProductName(props: Props) {
    const { kind, level, month } = props
    const { formatMessage: f } = useIntl()

    if (kind === null) return ''

    let product_name = ''

    if (kind === 0 || kind === 3) {
        product_name = `${f({id: `policy.GetLevels.${level}.name`})} (${f({id: `policy.GetMonth.name.${month}`})})`
    } else if (kind === 1 || kind === 2) {
        product_name = f({id: `policy.GetKind.kind${kind}.${level}`})
    }

    return product_name
}

export default useProductName