// 로직이 있을경우 
export const ADD = 'survey/live/confirm09/ADD'
export const REMOVE = 'survey/live/confirm09/REMOVE'

const initState: { rows: number[] } = {
    rows: []
}

export default (state = initState, action: { type: string, survey_question_no: number}) => {
    switch(action.type) {
        case ADD:
            const is = state.rows.find((c) => c === action.survey_question_no)
            return { rows: is ? state.rows : [ ...state.rows, action.survey_question_no ] }
        case REMOVE:
            return { rows: state.rows.filter((c) => c !== action.survey_question_no) }
        default:
            return state
    }
}