import { ChangeEvent } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { GetPayResult, PayResult } from 'ts-policy'
import { ParentPayResult } from './Component'

interface Props {
    date: number;
    payResult: ParentPayResult;
    onChangeDate: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangePayResult: (e: ChangeEvent<HTMLInputElement>) => void;

}

const SearchFormControl = withStyles({
    root: {
        minWidth: 120,
        '& + &': {
            marginLeft: 10
        }
    }
})(FormControl)

function getYear() {
    const arr = []
    for (let i=0; i<3; i ++) {
        const date = Number(moment().subtract(i, 'y').format('YYYY'))

        if (date >= 2022) arr.push(date)
    }
    return arr    
}

function SearchComponent(props: Props) {
    const { date, payResult, onChangeDate, onChangePayResult } = props 
    
    const { formatMessage: f } = useIntl()

    const payResults = GetPayResult()

    return (
        <>
            <SearchFormControl>
                <InputLabel>{f({id: 'component.MyAccount.Pay.Search.Select.year.label'})}</InputLabel>
                <Select value={date} onChange={onChangeDate}>
                    {
                        getYear().map((c) => {
                            return <MenuItem key={c} value={c}>{c}</MenuItem>
                        })
                    }
                </Select>
            </SearchFormControl>
            <SearchFormControl>
                <InputLabel>{f({id: 'component.MyAccount.Pay.Search.Select.status.label'})}</InputLabel>
                <Select value={payResult} onChange={onChangePayResult}>
                <MenuItem value="all">{f({id: 'component.MyAccount.Pay.Search.Select.status.menuItem.all'})}</MenuItem>
                    {
                        Object.keys(payResults).map((key:PayResult) => {
                            return <MenuItem key={key} value={key}>{payResults[key]}</MenuItem>
                        })
                    }
                </Select>
            </SearchFormControl>
        </>
    )
    
}

export default SearchComponent