import { useState, useCallback } from 'react'
import { Typography, Tooltip, ClickAwayListener } from '@material-ui/core'
import { parseHtmlIntl } from 'ts-utils'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile } from "react-device-detect"

const useStylesBootstrap = makeStyles(theme => ({
    tooltip: {
        maxWidth: 600,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    }
}))


/**
 * isMobileClick : 모바일에서 클릭시 툴팁 보이게 하는 이벤트
 * isMobileView : isMobileClick=true 상태에서 true이면 작동하고 false이면 작동안한다
 * delayCloseTime : 모바일에서 닫히는 속도 제어
 */
export default (props) => {
    const { 
        title="", 
        open=false, 
        placement='bottom', 
        children, 
        variant="caption", 
        isMobileClick=false,
        isMobileView=true,
        delayCloseTime, 
        ...other
    } = props

    const [stateOpen, setStateOpen] = useState(open)

    const handleTooltipClose = useCallback(() => {
        setStateOpen(false)
    }, [])

    const handleTooltipOpen = () => {
        setStateOpen(true);
    }

    const classes = useStylesBootstrap()

    let customTitle = ''
    if (typeof title === 'string' && title !== '') {
        customTitle = <Typography variant={variant} display="inline">{parseHtmlIntl(title.replace(/\n/g, '<br />'))}</Typography>
    } else if (typeof title === 'number') {
        customTitle = <Typography variant={variant} display="inline">{`${String(title)}`}</Typography>
    }
    
    if (!customTitle) return (
        <div>{children}</div>
    )

    // 빈값이면 아예 안띄운다
    if (title === "") return null
    
    return (
        <>
        {
            (isMobileClick && isMobile && isMobileView) ? (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        onClose={handleTooltipClose}
                        open={stateOpen}
                        disableFocusListener={true}
                        disableHoverListener={true}
                        disableTouchListener={true}
                        {...other} 
                        classes={classes} 
                        arrow 
                        title={customTitle}
                        placement={placement}
                    >
                        <div onClick={() => {
                            handleTooltipOpen()
                            if (delayCloseTime > 0) {
                                setTimeout(handleTooltipClose, delayCloseTime)
                            }
                        }}>{children}</div>
                    </Tooltip>
                </ClickAwayListener>
            ) : (
                <Tooltip 
                    {...other} 
                    classes={classes} 
                    arrow 
                    title={customTitle}
                    disableFocusListener={false}
                    disableHoverListener={false}
                    disableTouchListener={false}
                    placement={placement}
                >
                    {children}
                </Tooltip>
            )
        }
        </>
    )
}