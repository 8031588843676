import { useMemo, useContext } from 'react'
import { Context } from './Component'
import ColumnAnswerChildItemComponent from './ColumnAnswerChildItem'

function ColumnAnswerItemComponent(props) {
    const { survey_module_answer_no, survey_module_no, selected } = props
    
    const { onChange } = useContext(Context)

    return (
        <>
        {
            useMemo(() => {
                return (
                    <div key={survey_module_answer_no} 
                        className="center input" 
                        onClick={() => onChange(survey_module_no, survey_module_answer_no)}>
                            <ColumnAnswerChildItemComponent selected={selected}/>
                    </div>
                )
            }, [survey_module_answer_no, survey_module_no, selected, onChange])
        }
        </>
  
    )
}

export default ColumnAnswerItemComponent