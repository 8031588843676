import { memo, useContext, useEffect } from 'react'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import ItemComponent from './Item'
import { getReplysKeyOfValue } from '../01/Component'
import useOptionButtons from './OptionButtons'

export function handleChangeOrders(order, ranks, answers, reply) {
    // 보가별 정리
    if (order === 'answer') return answers

    // 순위별 정렬
    // 보기를 삭제해야해서 새로 담고 시작
    let _answers = [ ...answers ]

    let data = []

    // 순위별로 처음부터 
    for (let i=0; i<ranks.length; i++) {
        const { survey_module_no } = ranks[i]
        
        // 순위에 해당하는 답변이 있다면
        for (let j=0; j<reply.length; j++) {
            const { survey_module_no: selected_survey_module_no, survey_module_answer_no: selected_survey_module_answer_no } = reply[j]
            if (survey_module_no === selected_survey_module_no) {
                // 해당 답변의 보기를 data에 담고 삭제
                for (let k=0; k<_answers.length; k++) {

                    if (_answers[k].survey_module_answer_no === selected_survey_module_answer_no) {
                        data.push(_answers[k])
                        _answers.splice(k, 1)
                    }
                }
            }
        }
    }

    // 순위지정된거를 먼저 담고 순위 미지정돼서 남은 보기를 담는다
    data = [...data, ..._answers]

    return data
}

function Items(props) {
    const { survey_question_no, answers, ranks, reply, is_comment_placeholder, required, required_end, refComment, onChange, onChangeReset } = props

    const { design } = useContext(PropsContext)

    const { answer: answerColor } = design

    const [ OptionButtonsComponent, { order, reset } ] = useOptionButtons(survey_question_no, answerColor)

    // survey_module_answer_no를 key로 사용
    const ranksKeyOfValue = getReplysKeyOfValue('survey_module_no', ranks)

    // survey_module_answer_no를 key로 사용
    const replysKeyOfValue = getReplysKeyOfValue('survey_module_answer_no', reply)

    // 정렬된값
    const rows = handleChangeOrders(order, ranks, answers, reply)

    useEffect(() => {
        if (reset) {
            onChangeReset()
        }
    }, [reset, onChangeReset])

    return (
        <div>
        <OptionButtonsComponent/>
        {
            rows.map((c, i) => {
                const { answer, survey_module_answer_no, is_comment } = c

                const myReply = replysKeyOfValue[survey_module_answer_no]

                return (
                    <ItemComponent 
                        key={survey_module_answer_no}
                        survey_module_answer_no={survey_module_answer_no} 
                        is_comment={is_comment}
                        reply={reply}
                        ranksKeyOfValue={ranksKeyOfValue}
                        myReply={myReply}
                        ranks={ranks}
                        answer={answer} 
                        index={i} 
                        is_comment_placeholder={is_comment_placeholder}
                        required={required}
                        required_end={required_end}
                        refComment={refComment}
                        onChange={onChange}
                    />          
                )
            })    
        }
        </div>
    )
}

export default memo(Items, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) &&
            isEqual(prev.answers, next.answers) &&
            isEqual(prev.ranks, next.ranks) &&
            isEqual(prev.reply, next.reply) &&
            isEqual(prev.is_comment_placeholder, next.is_comment_placeholder) && 
            isEqual(prev.required, next.required) && 
            isEqual(prev.required_end, next.required_end) && 
            isEqual(prev.refComment, next.refComment) &&
            isEqual(prev.onChange, next.onChange) &&
            isEqual(prev.onChangeReset, next.onChangeReset)
})
