/**
 * 관리자에서 알림 울리면 사용자에서 감지하다가 이벤트발동
 */
import { useState } from 'react'
import { useSubscription } from '@apollo/react-hooks'
import { SUBSCRIPTION_ADMIN_TAX_ALARM } from 'gql/admin_info'

const useAdminAlarm = () => {

    const [ alarm, setAlarm ] = useState<string | null>(null)

    useSubscription(SUBSCRIPTION_ADMIN_TAX_ALARM, {
        variables: {
            token: localStorage.getItem('authorization')
        },
        onSubscriptionData: (data) => {
            const { subscriptionData: { data: { adminTaxtAlarm } } } = data

            setAlarm(adminTaxtAlarm)
        },
        fetchPolicy: 'network-only'
    })
    
    return alarm    
}
 
export default useAdminAlarm
