import { makeStyles } from '@material-ui/core/styles'
import { Paper, InputBase } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    }
}))

export default (props) => {
    const { tabValue, refSearch, on } = props

    const { formatMessage: f } = useIntl()
    
    const classes = useStyles()

    return (
        <Paper variant="outlined" className={classes.root}>
            <InputBase
                placeholder={f({id: `component.Image.Search.placeholder.${tabValue}`})}
                className={classes.input}
                inputRef={refSearch} 
                onChange={on.change}
            />
            <SearchIcon color="action" />
        </Paper>
    )
}
