import { useEffect, useState } from "react"

function useMount(max:number) {
    const [ mount, setMount ] = useState(true) 

    useEffect(() => {
        setMount(false)
    }, [max])

    useEffect(() => {
        if (!mount) setMount(true)
    }, [mount])

    return mount
}

export default useMount