/**
 * summary, rows refetch
 */
export const CHANGE = 'analysis/summary/refresh/CHANGE'
export const INIT = 'analysis/summary/refresh/INIT'

export type RefreshProps = {
    refresh: number, 
    disabled: boolean
}

const initState: RefreshProps = {
    refresh: 0,
    disabled: false
}

interface ActionProps {
    type: string;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                disabled: true,
                refresh: Math.random()
            }
        case INIT:
            return initState
        default:
            return state
    }
}