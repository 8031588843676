import { useSelector, useDispatch } from 'react-redux'
import { HIDE } from 'reducer/confirm'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import oc from 'open-color-js'
import { DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { StyledDialog, StyledButton } from 'styled/Modal'
import withBreakPoints from 'hoc/BreakPoints'

function getDetectiveFullScreen(breakpoints, detectiveFullScreen) {
    const { xs, sm, md, lg, xl } = breakpoints

    if (detectiveFullScreen === 'xs' && xs) return true
    if (detectiveFullScreen === 'sm' && sm) return true
    if (detectiveFullScreen === 'md' && md) return true
    if (detectiveFullScreen === 'lg' && lg) return true
    if (detectiveFullScreen === 'xl' && xl) return true
    
    return false
}

function ConfirmComponent(props) {
    const { breakpoints } = props

    const state = useSelector(state => state.confirm)
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const onClose = () => {
        dispatch({type: HIDE})
        
        typeof state.onClose === 'function' && state.onClose()
    }

    const detectiveFullScreen = getDetectiveFullScreen(breakpoints, state.detectiveFullScreen)
    
    return (
        <>
        <StyledDialog
            fullScreen={state.fullScreen || detectiveFullScreen}
            open={state.show}
            onClose={onClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            maxWidth={state.maxWidth}

        >
            <DialogTitle id="scroll-dialog-title">{state.title}</DialogTitle>
             
            <DialogContent>
                { state.subtitle && <DialogContentText style={{color: oc.pink7}}>{parseHtmlIntl(state.subtitle)}</DialogContentText> }
                { state.content && <DialogContentText style={{color: oc.gray7}}>{parseHtmlIntl(state.content)}</DialogContentText> }
                { state.child  }                
            </DialogContent>
            
            <DialogActions>
            {
                state.onActions.map((c, i) => <StyledButton key={i} onClick={() => {
                    if (c.hide === true) dispatch({type: HIDE}) 
                    
                    c.on()
                }} color="primary">{c.name}</StyledButton>)
            }
            <StyledButton onClick={onClose}>{ state.closeName || f({id: 'ccomponent.Confirm.closeName'}) }</StyledButton>
            </DialogActions>
        </StyledDialog>
        </>
    )
}

export default withBreakPoints(ConfirmComponent)