import { useContext, useMemo } from 'react'
import summaryContext from 'context/summary'
import styled from 'styled-components'
import { getRGBA } from 'utils/analysis'
import { Typography } from '@material-ui/core'

//  게이지 바 포인트 적용 (우선순위) 랩퍼
const WrapperBarPointHoriStyled = styled.div`
    display: flex;
    flex-direction: column;

    & > div:last-child {
        width: 100%;
        margin-top: 3px;
        
        & > div {
          text-align: center;
            width: 100%;
            padding: 3px 3px 1px 3px;
            border-radius: 3px;
            background: ${({ bg }) => bg};
            color: ${({ color }) => color};
            font-family: ${({ font_family }) => font_family};
        }
    }
`

export default (props) => {
    const { conts, children } = props
    const { design } = useContext(summaryContext)

    const bg = getRGBA({color: design.bar, opacity: .1})
    const color = getRGBA({color: design.bar, opacity: 1})

    return (
        <WrapperBarPointHoriStyled bg={bg} color={color} font_family={design.font_family}>
            <div>
                {children}
            </div>
            <div>
                <div>
                    {
                        useMemo(() => (
                            <Typography style={{ fontFamily: design.font_family, fontSize: 15, fontWeight: 500 }}>{conts}</Typography>
                        ), [design.font_family, conts])
                    }
                </div>
            </div>
        </WrapperBarPointHoriStyled>
    )
}