import gql from 'graphql-tag'

export const GET_USER_PAYBLOCK_IN_USER = gql`
    query UserPayblockInUser {
        userPayblockInUser {
            content    
            wdate
        }
    }
`

export interface UserPayblockInUserProps {
    content: string;
    wdate: string;
}