import { useContext } from 'react'
import { Typography, Button } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons'
import summaryContext from 'context/summary'
import styled from 'styled-components'
import { getRGBA } from 'utils/analysis'
import  { useIntl } from 'react-intl'

// 코멘트 스타일
const WrapperStyled = styled.div`
    text-align: center;
`

export default (props) => {
    const { collapse, onChange } = props
    const { design } = useContext(summaryContext)

    const { formatMessage: f } = useIntl()

    const collapseValue = collapse ? { text: f({id: 'component.Survey.Create.Analysis.Summary.Item.comment.close'}), icon: ExpandLessIcon } : { text: f({id: 'component.Survey.Create.Analysis.Summary.Item.comment.open'}), icon: ExpandMoreIcon }
    const CommentIcon = collapseValue.icon

    const color = getRGBA({color: design.question, opacity: 1})
    const iconColor = getRGBA({color: design.question, opacity: .8})

    return (
        <WrapperStyled>
            <Button style={{color: iconColor}} variant="text" color="default" size="small" disableElevation startIcon={<CommentIcon/>} onClick={onChange}>
                <Typography variant="body2" style={{color, fontFamily: design.font_family, fontWeight: 900, fontSize: 13}}>{collapseValue.text}</Typography>
            </Button>
        </WrapperStyled>
    )
}