/**
 * 서베이 검증 관련
 */
import gql from 'graphql-tag'

export const GET_VERIFY_SURVEY_QUESTION_CHANGE_MODULE_TYPE = gql`
    query VerifySurveyQuestionChangeModuleType($survey_no: Int!, $survey_question_no: Int!) {
        verifySurveyQuestionChangeModuleType(survey_no: $survey_no, survey_question_no: $survey_question_no) {
            status {
                open
                is_response
                is_logic
                is_point
                is_example_count
                is_googlesheet
            }
            can
        }
    }
`

export interface VerifySurveyQuestionChangeModuleTypeProps {
    status: {
        open: boolean;
        is_response: boolean;
        is_logic: boolean;
        is_point: boolean;
        is_example_count: boolean;
        is_googlesheet: boolean;
    }
    can: boolean;
}