import { memo, useContext } from 'react'
import { isDesktop, isTablet } from 'react-device-detect'
import { ItemContext } from './Overflow'
import StepLabel from '@material-ui/core/StepLabel'
import { LabelComponent } from 'styled/Survey/Scale'
import Step from 'styled/Custom/Step'
import StepIcon from 'styled/Custom/StepIcon'
import StepIconText from 'styled/Custom/StepIconText'
import Stepper from 'styled/Custom/Stepper'
import DividerComponent from '../06/Divdier'

// 바로이동 (isJust일때 사용되는 애니메이션)
export function getAnimationClassName(bool) {
    return bool ? "animate__animated animate__flash" : ""
}

export function getViewType(len) {
    return (isTablet || isDesktop) && len <= 12
}

export function getValue(reply, lists) {
    if (reply.length === 0) return null

    const index = lists.findIndex(c => c.survey_module_no === reply[0].survey_module_no)

    return index > -1 ? index : null
}

function ItemComponent() {
    const { left_label, right_label, reply, reverse, zero_number, lists, onChange } = useContext(ItemContext)

    // 한개만 선택하는거라 배열은 무조건 0 이다
    const value = getValue(reply, lists)

    let isJust = false

    const len = lists.length

    const isValue = typeof value === 'number'

    return (
        <>
        {
            (getViewType(len)) ? (
                <>
                <div style={{height: 10}}/>
                <Stepper nonLinear={false} activeStep={value}>
                {
                    lists.map((c, i) => {
                        if (isValue) {
                            isJust = Boolean(reply[0].isJust) && reply[0].survey_module_no === c.survey_module_no
                        }
                        
                        return (
                            <Step key={c.survey_module_no} className={getAnimationClassName(isJust)}>
                                <StepLabel StepIconComponent={(props) => <StepIconText zero_number={zero_number} reverse={reverse} len={len} {...props}/>} onClick={() =>  !isJust && onChange(c.survey_module_no)}/>
                            </Step>
                        )
                    })
                }
                </Stepper>
                <LabelComponent pc={true} left_label={left_label} right_label={right_label}/>
                </>
            ) : (
                <>
                <div style={{height: 10}}/>
                <Stepper nonLinear={false} activeStep={value} is_mobile={1}>
                    {
                        lists.map((c, i) => {
                            if (value) {
                                isJust = Boolean(reply[0].isJust) && reply[0].survey_module_no === c.survey_module_no
                            }
                            
                            return (
                                <StepIcon key={c.survey_module_no} zero_number={zero_number} reverse={reverse} len={len} flash={getAnimationClassName(isJust)} onClick={() =>  !isJust && onChange(c.survey_module_no)}/>
                            )
                        })
                    }
                </Stepper>
                <LabelComponent left_label={left_label} right_label={right_label}/>
                <DividerComponent isLast={true}/>
                </>
            )
        }
       
        </>
    )
}

export default memo(ItemComponent)