import { useContext, useMemo } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import summaryContext from 'context/summary'
import styled from 'styled-components'
import { getRGBA } from 'utils/analysis'
import SummaryWrapperBarImgMatrixSrcComponent from './SummaryWrapperBarImgMatrixSrc'

const MyTypography = withStyles({
    root: {
        wordBreak: 'break-all'
    }
})(Typography)

// 게이지 바 스타일
const WrapperBarMatrixStyled = styled.div`
    width: 100%;
    border-radius: 3px;
    
    & > div:first-child {
        margin-bottom: 15px;
    }

    & > div:last-child {
       
    }

    & + & {
        margin-top: 2.7em;
    }
`

const AnswerStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .2em;

    & > div:first-child {
        width: 4rem;
        height: 4rem;
    }

    & > div:last-child {
        width: calc(100% - 4rem);
    }

    & div + div {
        margin-left: 1rem;
    }
`

function WrapperBarImgMatrixComponent(props) {
    const { answer, src, children } = props
    const { design } = useContext(summaryContext)

    const color = getRGBA({color: design.question, opacity: .9})

    const backgroundColor = getRGBA({color: design.background_color, opacity: .8})

    return (
        <WrapperBarMatrixStyled backgroundColor={{backgroundColor}}>
        {
            useMemo(() => (
                <div>
                    <AnswerStyled>
                        <div><SummaryWrapperBarImgMatrixSrcComponent src={src}/></div>
                        <div><MyTypography variant="body2" style={{color, fontFamily: design.font_family}}>{answer}</MyTypography></div>
                    </AnswerStyled>
                </div>
            ), [color, design.font_family, answer, src])
        }
        <div>{children}</div>
        </WrapperBarMatrixStyled>
    )
}

export default WrapperBarImgMatrixComponent