
import { forwardRef } from 'react'
import { Dialog, Slide }from '@material-ui/core'
import withBreakPoints from 'hoc/BreakPoints'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function DialogComponent(props) {
    const { breakpoints, fullScreen=true, isMobileFullScreen=false, children, ...other } = props

    const { xs } = breakpoints
    
    let cFullScreen = fullScreen

    // fullScreen 과 상관없이, 모바일이면 FULLSCREEN
    if (isMobileFullScreen && xs) cFullScreen = true

    return <Dialog fullScreen={cFullScreen} {...other} TransitionComponent={Transition}>{children}</Dialog>
}

export default withBreakPoints(DialogComponent)