import gql from 'graphql-tag'
import { Elements } from 'react-flow-renderer'

export const CREATE_JUMP_FLOW = gql`
    mutation CreateJumpFlow($survey_no: Int!, $instance: JSONObject!) {
        createJumpFlow(survey_no: $survey_no, instance: $instance)
    }
`

export const DELETE_JUMP_FLOW = gql`
    mutation DeleteJumpFlow($survey_no: Int!) {
        deleteJumpFlow(survey_no: $survey_no)
    }
`

export const GET_JUMP_FLOW = gql`
    query JumpFlow($survey_no: Int!) {
        jumpFlow(survey_no: $survey_no) {
            jump_flow_no
            survey_no
            instance
        }
    }
`

// 기본 디폴트
export interface JumpFlowProps {
    jump_flow_no: number; 
    survey_no: number;     
    instance: Elements | null;
}