import { List, ListItem, ListItemText as MuiListItemText, ListItemIcon as MuiListItemIcon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Folder as FolderIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import oc from 'open-color-js'

const ListItemText = withStyles({
    root: {
        color: oc.pink7
    }
})(MuiListItemText)

const ListItemIcon = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))(MuiListItemIcon)


const Component = () => {

    const { formatMessage: f } = useIntl()

    return (
        <List dense>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText primary={f({id: 'component.MyAccount.Fired.Explain.lists.0'})}/>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText primary={f({id: 'component.MyAccount.Fired.Explain.lists.1'})}/>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText primary={f({id: 'component.MyAccount.Fired.Explain.lists.2'})}/>
            </ListItem>
        </List>
    )
}

export default Component