import { memo } from 'react'
import HtmlParser from 'react-html-parser'
import { _21Module } from 'gql/survey_question_modules'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Props as ParentProps, OnChange } from './Items'
import { DropdownListItemIcon } from './Nothing'

interface Props {
    survey_module_no: _21Module['survey_module_no'];
    answer: _21Module['answer'];
    kind: ParentProps['kind'];
    disabled: boolean;
    selected: boolean;
    autoFocus: boolean;
    answer_color: ParentProps['answer_color'];
    line_color: ParentProps['line_color'];
    font_family: ParentProps['font_family'];
    onChange: OnChange;
}

function ItemComponent(props: Props) {
    const { survey_module_no, answer, kind, selected, disabled, autoFocus, answer_color, line_color, font_family, onChange } = props

    const newDisabled = disabled && !selected

    return (
        <MenuItem selected={selected} disabled={newDisabled} autoFocus={autoFocus} onClick={() => onChange(survey_module_no)}>
            <DropdownListItemIcon>
                {
                    !kind ? (
                        selected ? <RadioButtonCheckedIcon style={{color: `rgba(${line_color})`}}/> : <RadioButtonUncheckedIcon style={{color: `rgba(${line_color})`}}/>
                    ) : (
                        selected ? <CheckBoxIcon style={{color: `rgba(${line_color})`}}/> : <CheckBoxOutlineBlankIcon style={{color: `rgba(${line_color})`}}/>
                    )
                }
            </DropdownListItemIcon>
            <ListItemText>
                <Typography 
                    style={{
                        whiteSpace: 'normal', 
                        wordBreak: 'break-all', 
                        lineHeight: '120%', 
                        maxWidth: 600, 
                        fontSize: 16,
                        color: `rgba(${answer_color})`,
                        fontFamily: font_family
                    }}>
                    {HtmlParser(answer)}
                </Typography>

            </ListItemText>
        </MenuItem>
    )
}

export default memo(ItemComponent)