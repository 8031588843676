import { useContext, useMemo } from 'react'
import { getColorConcentration } from 'utils'
import PropsContext from 'context/props'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'

interface Props {
    children: JSX.Element | JSX.Element[];
}

const MyBox = withStyles(theme => ({
    root: (props: { background_color: string }) => {
        const { background_color } = props

        return {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: 300,
            border: `1px solid ${getColorConcentration(background_color) ? 'rgba(255, 255, 255, .1)' : 'rgba(0, 0, 0, .1)'}`,
            borderRadius: 3,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%'
            }
        }
    }
}))(Box)

function PreviewMyBox(props: Props) {
    const { children } = props
    const { design } = useContext<{background_color: string}>(PropsContext)
    const { background_color } = design

    return (
        <>
        {
            useMemo(() => {
                return (
                    <MyBox background_color={background_color}>
                        {children}
                    </MyBox>
                )
            }, [background_color, children])
        }
        </>
    )

}

export default PreviewMyBox