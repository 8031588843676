import { useSelector } from 'react-redux'
import { ErrorTwoTone as ErrorTwoToneIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import {
    MyContainer,
    Wrapper,
    StyledContainer,
    StyledContent,
    Logo,
    MessageTypography,
    Button
} from './common/ErrorPage'

const Component = () => {
    const { show, f, logoHref='/workspace', buttonHref='/workspace' } = useSelector(state => state.errorPage)

    const { formatMessage: defaultF } = useIntl()

    const customF = f || defaultF

    if (!show) return null

    return (
        <Wrapper>
            <MyContainer>
                <Logo href={logoHref}/>
                <StyledContainer>
                    <StyledContent>
                        <ErrorTwoToneIcon color="secondary" fontSize="large"/>
                    </StyledContent>
                    <StyledContent>
                        <MessageTypography>{customF({id: 'ccomponent.ErrorPage.message'})}</MessageTypography>
                    </StyledContent>
                    <Button href={buttonHref}>{customF({id: 'ccomponent.ErrorPage.login'})}</Button>
                </StyledContainer>
            </MyContainer>
        </Wrapper>
    )
}

export default Component