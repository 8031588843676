import { useEffect, useState, memo, useContext } from 'react'
import { ItemContext } from './Overflow'
import ItemComponent from './Item'

function ItemsComponent(props) {
    const { survey_question_no, mode, columns, answers, is_answer, icon_type, reply, onChange } = useContext(ItemContext)

    const [ hoverAnswer, setHoverAnswer ] = useState([])

    useEffect(() => {
        setHoverAnswer(columns.map(c => ({ survey_module_no: c.survey_module_no, answer: '' })))
    }, [ columns ])

    return (
        <>
            {
                columns.map((c, i) => {
                    const { survey_module_no, column } = c

                    return (
                        <ItemComponent
                            key={survey_module_no}
                            index={i}
                            survey_question_no={survey_question_no}
                            survey_module_no={survey_module_no}
                            column={column}
                            answers={answers}
                            is_answer={is_answer}
                            icon_type={icon_type}
                            mode={mode}
                            reply={reply}
                            hoverAnswer={hoverAnswer}
                            onChange={onChange}
                        />
                    )
                })
            }
        </>
    )
}

export default memo(ItemsComponent)