import styled, { css } from 'styled-components'
import oc from 'open-color'

// 이미지형에서 사용
const ContainerStyled = styled.div`
    ${props => props.imageView === true && css `
        position: absolute;

        top: -1px;
        right: 0;
        bottom: 0;
        left: -5px;
        background: ${oc.gray[1]};
        
        width: 101%;
        height: initial;
        padding: 0;
        z-index: 1000;

        & > div {    
            
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 10px;
            overflow-y: auto;
            margin-top: 120px;
        }
    `}
`

// 이미지형에서 사용
const WrapperStyled = styled.div`
    padding: 0;

    border-radius: 5px;

    & > div:last-child {
        margin-top: 10px;
        margin-bottom: 30px;
        text-align: center;
    }

    & > div:first-child {
  
    }

    ${props => props.imageView === true && css `
        border: 1px solid ${oc.gray[2]};
        padding: 25px 10px 5px 10px;
        background: white;
        overflow-y: auto;
        max-height: 270px;

        @media only screen and (max-width: 1280px) {
            max-height: 400px;
        }

        @media only screen and (max-width: 960px) {
            max-height: 280px;
        }

        @media only screen and (max-width: 600px) {
            max-height: 200px;
        }
    `}
`

// 이미지형에서 사용
const BoxStyled = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 40px 1fr 101px 35px;
    gap: 5px;
    align-items: center;

    & + & {
        margin-top: 10px;
    }

    & > div:first-child {
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;

        .check {
            position: absolute;
            top: -2px;
            left: 0;
            border-radius: 12px;
            background-color: ${oc.pink[6]};
            z-index: 1;
            padding: 2px;

            & > svg {
                display: block;
            }
        }
    }

    & > div:nth-child(3) {
        font-size: 12px;
        text-align: center;
    } 
`

// 이미지형 감사완료글에서 사용
const ImageUploadStyled = styled.div`
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    background: white;
    display: none;

    &.show {
        display: block;
        border: 1px solid ${oc.gray[2]};
        padding: .2em 1.3em 1.3em 1.3em;
        border-radius: 3px;

        ${({ marginTop='20px', marginBottom='20px' }) => {
            return (
                `
                margin-top: ${marginTop};
                margin-bottom: ${marginBottom};
                `
            )
        }}
    }
`

export {
    ContainerStyled,
    WrapperStyled,
    BoxStyled,
    ImageUploadStyled
}