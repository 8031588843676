import { useContext, useState, useCallback, useEffect } from 'react'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod' 
import { Format19 } from 'hoc/Survey/SetReplys'
import { isEqual } from 'lodash-es'
import { getExt } from 'utils'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import Dropzone from './Dropzone'
import CommentComponent from '../Comment'

interface Props {
    survey_question_no: number;
    exts: string[];
    is_comment: boolean;
    is_comment_placeholder: string;
    replyComment: string;
    reply: Format19[];
}

export type OnChange = (file:File | '') => void;

function Component (props: Props) {
    const { survey_question_no, exts, is_comment, is_comment_placeholder, replyComment, reply } = props
    const { mbox, format, levels, token }:any= useContext(PropsContext)
    const { mode } = useContext(AccessMethodContext)
    const { survey_no, method } = mbox

    const [ newReply, setNewReply ] = useState(reply)

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const handleChange: OnChange = useCallback((file) => {

        let newReplys = null
        if (file) {
            const { name, type, size } = file

            // hwp, hwpx는 확장자가 안잡히네;;
            let Type = type
            if (!Type) {
                Type = getExt(name)
            }

            const reply = format._19({ survey_question_no, src: file, filename: name, filetype: Type, filesize: size })

             // 체크상태이면 미체크로변경, 미체크이면 체크로변경
            newReplys =[...memoryReply.rows, reply]

        } else {
            newReplys = [...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no) ]
        }
       
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys
 
        // 해당 문항의 값만 넣자
        setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)

    }, [survey_question_no, format, onChangeReplys])

    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <div>
        <Dropzone survey_no={survey_no} mode={mode} method={method} token={token} levels={levels} exts={exts} reply={newReply} onChange={handleChange}/>
        <CommentComponent 
            survey_question_no={survey_question_no} 
            is_comment={is_comment}
            is_comment_placeholder={is_comment_placeholder}
            comment={replyComment}
            disabled={newReply.length > 0 ? false : true}
            marginTop="10px" 
        />
        </div>
    )
}

export default Component