import gql from 'graphql-tag'

export const GET_SURVEY_ANALYSIS_PAGE_VIEWS = gql`
    query SurveyAnalysisPageViews($survey_no: Int!, $sdate: Date, $edate: Date, $order: PageViewOrder, $orderBy: Boolean!, $deviceType: Device) {
        surveyAnalysisPageViews(survey_no: $survey_no, sdate: $sdate, edate: $edate, order: $order, orderBy: $orderBy, deviceType: $deviceType) {
            survey_page_no
            indexs
            views
            delays
        }
    }
`

export const GET_SURVEY_ANALYSIS_PAGE_VIEWS_ONE = gql`
    query SurveyAnalysisPageViewsOne($survey_no: Int!, $sdate: Date, $edate: Date, $order: PageViewOrder, $orderBy: Boolean!, $deviceType: Device) {
        surveyAnalysisPageViewsOne(survey_no: $survey_no, sdate: $sdate, edate: $edate, order: $order, orderBy: $orderBy, deviceType: $deviceType) {
            survey_question_no
            pure_question
            module_type
            question_type
            indexs
            number
            views
            delays
        }
    }
`

export const GET_SURVEY_ANALYSIS_COMPLETE_INTERVAL_LISTS = gql`
    query SurveyAnalysisCompleteIntervalLists($survey_no: Int!, $sdate: Date, $edate: Date, $deviceType: Device) {
        surveyAnalysisCompleteIntervalLists(survey_no: $survey_no, sdate: $sdate, edate: $edate, deviceType: $deviceType) {
            device
            ip
            email
            wdate
            einterval
        }
    }
`


export const GET_SURVEY_ANALYSIS_DELAY_TIME_LISTS = gql`
    query SurveyAnalysisDelayTimeLists($survey_no: Int!, $survey_page_no: Int!, $delay_time: Int!, $sdate: Date, $edate: Date, $deviceType: Device) {
        surveyAnalysisDelayTimeLists(survey_no: $survey_no, survey_page_no: $survey_page_no, delay_time: $delay_time, sdate: $sdate, edate: $edate, deviceType: $deviceType) {
            device
            ip
            email
            wdate
            rdate
            delay_time
        }
    }
`

export const GET_SURVEY_ANALYSIS_DELAY_TIME_LISTS_ONE = gql`
    query SurveyAnalysisDelayTimeListsOne($survey_no: Int!, $survey_question_no: Int!, $delay_time: Int!, $sdate: Date, $edate: Date, $deviceType: Device) {
        surveyAnalysisDelayTimeListsOne(survey_no: $survey_no, survey_question_no: $survey_question_no, delay_time: $delay_time, sdate: $sdate, edate: $edate, deviceType: $deviceType) {
            device
            ip
            email
            wdate
            rdate
            delay_time
        }
    }
`