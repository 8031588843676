import gql from 'graphql-tag'

export const GET_SUMMARY_FILE_DOWNLOAD_LOG = gql`
    query SummaryFileDownloadLog($survey_no: Int!, $survey_question_no: Int!) {
        summaryFileDownloadLog(survey_no: $survey_no, survey_question_no: $survey_question_no) {
            wdate
        }
    }
`

export const CREATE_SUMMARY_FILE_DOWNLOAD_LOG = gql`
    mutation CreateSummaryFileDownloadLog($survey_no: Int!, $survey_question_no: Int!) {
        createSummaryFileDownloadLog(survey_no: $survey_no, survey_question_no: $survey_question_no)
    }
`

export interface SummaryFileDownloadLogVariables {
    survey_no: number;
    survey_question_no: number;
}

export interface SummaryFileDownloadLogProps {
    wdate: string;
}

export interface CreateSummaryFileDownloadLogVariables {
    survey_no: number;
    survey_question_no: number;
}