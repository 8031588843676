import { List, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExplainComponent from './Explain'
import TextareaComponent from './Textarea'

const useStyles = makeStyles({
    divider: {
        marginTop: 10,
        marginBottom: 15
    }
})

const Component = () => {

    const classes = useStyles()

    return (
        <List>
            <ExplainComponent/>
            <Divider className={classes.divider}/>
            <TextareaComponent/>
        </List>
    )
}

export default Component