import { RemoveCircleOutline as RemoveCircleOutlineIcon, AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import oc from 'open-color-js'
import styled from 'styled-components'

const RemoveIcon = withStyles({
    root: {
        color: oc.gray4,
        cursor: 'pointer',
        transition: 'all .3s',
        fontSize: '38px',

        '&:hover': {
            color: oc.gray6
        }
    }
})(RemoveCircleOutlineIcon)

const AddIcon = withStyles({
    root: {
        color: oc.gray4,
        cursor: 'pointer',
        transition: 'all .3s',
        fontSize: '38px',
        '&:hover': {
            color: oc.gray6
        }
    }
})(AddCircleOutlineIcon)



const IconButtonStyled = styled.div`
    background: ${oc.gray0};
    border-radius: 100%;
`

export {
    RemoveIcon,
    AddIcon,
    IconButtonStyled
}