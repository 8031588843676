import { SurveyEditImageAttachComponent, SurveyEditImageAttachPositionComponent } from 'component'
import { ImageContainer } from 'container'
import styled from 'styled-components'
import { withBreakPoints } from 'hoc'

const TitleStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    & > * + * {
        margin-left: .3em;
    }
`
const Component = (props) => {
    const { show, onlyImage, position, on, titleFloat='', titleSplit='', img_src, img_name='', breakpoints } = props

    const { xs } = breakpoints

    return (
        <SurveyEditImageAttachComponent show={show}>
            <div>
                <ImageContainer height={xs ? 'calc(100vh - 34.8em)' : ''} layout="horizontal" onlyImage={onlyImage} isVideo={true} onClose={on.close} onAppendUrl={on.append} onChange={on.append}/>
            </div>
            <div>
                <SurveyEditImageAttachPositionComponent 
                    titleFloat={titleFloat && <TitleStyled>{titleFloat}</TitleStyled>}
                    titleSplit={titleSplit && <TitleStyled>{titleSplit}</TitleStyled>}
                    position={position}
                    img_src={img_src}
                    img_name={img_name}
                    on={on} 
                />
            </div>
        </SurveyEditImageAttachComponent>
    )
}

export default withBreakPoints(Component)