/* 
    비밀번호, 새로운비밀번호 새로운비밀번호 확인 정상 비정상 판단
*/
import { useCallback } from 'react'
import { cookies } from 'utils'
import useLandingPage from 'hooks/useLandingPage'

const useLogout = () => {

    const getLandingPage = useLandingPage()

    const logout = useCallback((redirect='') => {
        localStorage.removeItem('authorization')
        cookies.remove({name: 'Logined', domain: `.${process.env.REACT_APP_DOMAIN}`})
        
        const url = getLandingPage()
        window.top.location.assign(redirect ? `${url}?${redirect}` : url)
    }, [getLandingPage])

    return logout
}
 
export default useLogout
