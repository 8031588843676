import { memo, useContext } from 'react'
import { useIntl } from 'react-intl'
import HtmlParser from 'react-html-parser'
import summaryContext from 'context/summary'
import { numberFormat } from 'utils'
import Typography from '@material-ui/core/Typography'

interface Props {
    answer?: string;
    len?: number;
}

function SummaryCommentResponseCountComponent(props: Props) {
    const { answer, len } = props

    const { formatMessage: f } = useIntl()
    
    const lenStr = typeof len === 'undefined' ? HtmlParser('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;') : f({id: 'styled.Survey.Analysis.SummaryCommentResponseCount.response'}, { count: numberFormat(len) })

    const { design } = useContext(summaryContext)
    const { question, font_family } = design
    
    return (
        <>
        <Typography 
                style={{ 
                    wordBreak: 'break-all',
                    display: 'block',  
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',   
                    width: '90%',
                    color: `rgba(${question})`,
                    fontFamily: font_family
                }}
                variant="body2" 
                color="textSecondary" 
            >
                {HtmlParser(answer || '&nbsp;')}
            </Typography>
            <Typography 
                variant="caption" 
                color="textSecondary" 
                style={{ 
                    color: `rgba(${question})`, 
                    fontFamily: font_family, 
                    whiteSpace: 'nowrap', 
                    marginLeft: 20 
                }}
            >
                {lenStr as string}
            </Typography>
        </>
    )
}

export default memo(SummaryCommentResponseCountComponent)