import { memo, createContext, useMemo } from 'react'
import { Format14 } from 'hoc/Survey/SetReplys'
import { _14Module } from 'gql/survey_question_modules'
import { Overflow } from '../05/Overflow'
import ItemComponent from './Item'

interface Props {
    survey_question_no: number;
    mode: string;
    lists: Format14[];
    is_answer: boolean;
    icon_type: string;
    reply: _14Module[]
    onChange: (a:number) => void;
}

export const ItemContext = createContext<Props>({
    survey_question_no: 0,
    mode: '',
    lists: [],
    is_answer: false,
    icon_type: '',
    reply: [],
    onChange: () => {}
})

function OverflowComponent(props: Props) {
    const { survey_question_no, mode, lists, is_answer, icon_type, reply, onChange } = props

    return (
        <ItemContext.Provider value={{
            survey_question_no, 
            mode, 
            lists, 
            is_answer, 
            icon_type, 
            reply, 
            onChange
        }}>
            {
                useMemo(() => {
                    return (
                        <Overflow paddingBottom="10px">
                            <ItemComponent/>
                        </Overflow>
                    )
                }, [])
            }
        </ItemContext.Provider>
        
    )
}

export default memo(OverflowComponent)