/**
 * address1 삭제
 */
import { memo, useContext, useMemo } from 'react'
import PropsContext from 'context/props'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'

type Props<T> = {
    onClick: (arg: T) => void;
};

function ClearIconComponent<T>(props: Props<T>) {
    const { onClick } = props

    const { design } = useContext<any>(PropsContext)
    const { question } = design

    return (
        useMemo(() => (
            <IconButton size='small' style={{color: `rgba(${question})`}} onClick={onClick}>
                <ClearIcon fontSize='small'/>
            </IconButton>
        ), [question, onClick])
    )
}

export default memo(ClearIconComponent)
