import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_QUESTION_MODULE } from 'gql/survey_question_modules'

export default root => WrappedComponent => props => {
    const { survey_no, survey_question_no } = props

    const dispatch = useDispatch()

    const { data, loading } = useQuery(GET_SURVEY_QUESTION_MODULE, {
        variables: {
            survey_no: Number(survey_no),
            survey_question_no: Number(survey_question_no)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    const propsFetchQuestion = {
        data,
        loading
    }

    return <WrappedComponent propsFetchQuestion={propsFetchQuestion} {...props}/>
}