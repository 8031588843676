import gql from 'graphql-tag'

export const CREATE_SURVEY_DESIGNS = gql`
    mutation CreateSurveyDesigns($input: CreateSurveyDesigns!) {
        createSurveyDesigns(input: $input)
    }
`

export const UPDATE_SURVEY_DESIGNS = gql`
    mutation UpdateSurveyDesigns($input: UpdateSurveyDesigns!) {
        updateSurveyDesigns(input: $input)
    }
`

export const GET_SURVEY_DESIGNS = gql`
    query SurveyDesigns($survey_no: Int!, $survey_designs_no: Int!, $template: Boolean!) {
        surveyDesigns(survey_no: $survey_no, survey_designs_no: $survey_designs_no, template: $template) {
            survey_designs_no
            title
            question         
            answer           
            answer_button    
            answer_sbutton   
            background_color 
            line_color 
            background_image 
            background_filter
            background_size
            background_opacity
            font_family
            numbering
        }
    }
`

export const GET_SURVEY_DESIGNS_ALL = gql`
    query SurveyDesignsAll($survey_no: Int!) {
        surveyDesignsAll(survey_no: $survey_no) {
            survey_designs_no
            title
            question         
            answer           
            answer_button    
            answer_sbutton   
            background_color 
            line_color
            background_image 
            background_size
            background_opacity
            background_filter
            font_family
            numbering
        }
    }
`

export const GET_SURVEY_DESIGNS_TEMPLATE_ALL = gql`
    query SurveyDesignsTemplateAll {
        surveyDesignsTemplateAll {
            survey_designs_no
            title
            question         
            answer           
            answer_button    
            answer_sbutton   
            background_color 
            line_color
            background_image 
            background_size
            background_opacity
            background_filter
            font_family
            numbering
        }
    }
`

export const CREATE_SURVEY_DESIGNS_COPY = gql`
    mutation CreateSurveyDesignsCopy($survey_no: Int!, $survey_designs_no: Int!) {
        createSurveyDesignsCopy(survey_no: $survey_no, survey_designs_no: $survey_designs_no)
    }
`

export const DELETE_SURVEY_DESIGNS = gql`
    mutation DeleteSurveyDesigns($survey_no: Int!, $survey_designs_no: Int!) {
        deleteSurveyDesigns(survey_no: $survey_no, survey_designs_no: $survey_designs_no)
    }
`

export interface SurveyDesignsProps {
    survey_designs_no: number;
    title: string;
    question: string;   
    answer: string;
    answer_button: string;  
    answer_sbutton: string;
    background_color: string;
    line_color: string;
    background_image: string;
    background_size: string;
    background_filter: '' | 'blur' | 'brightness' | 'saturate';
    background_opacity: number;
    font_family: string;
    numbering: string;
}