// point 일괄적으로 변경
export const INIT = 'survey/create/logic/jumpPointAllPoint/INIT'
export const CHANGE = 'survey/create/logic/jumpPointAllPoint/CHANGE'

const initState: { point: number | '' } = {
    point: ''
}

interface ActionProps {
    type: string;
    point: number | '';
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case INIT:     
            return initState
        case CHANGE:     
            return {
                point: action.point
            }
        default:
            return state
    }
}