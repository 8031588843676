/**
 * save 이벤트시 빈값이 잇을경우 해당값을 펼침하고 포커스준다.
 */

import { ID, Kind } from 'gql/jump'

export const NULL = 'survey/create/logic/jumpNull/NULL'

// 빈값이 있을경우 해당 id kind 저장
export const CHANGE = 'survey/create/logic/jumpNull/CHANGE'

interface Props {
    id: ID;
    kind: Kind | null;
}

const initState: Props = {
    id: 0,
    kind: null
}
 
interface ActionProps {
    type: string;
    id?: ID;
    kind?: Kind;
}
 
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case NULL:
            return initState
        case CHANGE: 
            return {
                id: action.id,
                kind: action.kind
            }     
        default:
             return state
    }
}