import { memo, createContext, useMemo } from 'react'
import { Format15 } from 'hoc/Survey/SetReplys'
import { _15Module, _15Answer } from 'gql/survey_question_modules'
import { Overflow } from '../05/Overflow'
import ItemsComponent from './Items'

interface Props {
    survey_question_no: number;
    columns: _15Module[]; 
    answers: _15Answer[];
    mode: string;
    reply: Format15[]; 
    icon_type: string;
    is_answer: boolean;
    onChange: (a:number, b:number) => void;
}

export const ItemContext = createContext<Props>({
    survey_question_no: 0,
    columns: [],
    answers: [],
    mode: '',
    reply: [],
    is_answer: false,
    icon_type: '',
    onChange: () => {}
})

function OverflowComponent(props: Props) {
    const { survey_question_no, columns, answers, mode, reply, is_answer, icon_type, onChange } = props

    return (
        <ItemContext.Provider value={{
            survey_question_no, 
            columns,
            answers, 
            mode, 
            is_answer, 
            icon_type, 
            reply, 
            onChange
        }}>
            {
                useMemo(() => {
                    return (
                        <Overflow paddingBottom="10px">
                            <ItemsComponent/>
                        </Overflow>
                    )
                }, [])
            }
        </ItemContext.Provider>
        
    )
}

export default memo(OverflowComponent)