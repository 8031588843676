import { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import summaryContext from 'context/summary'
import moment from 'moment'
import { getRGBA } from 'utils/analysis'

//  코멘트박스 스타일
export const useStyles = makeStyles(theme => ({
    typography: {
        wordBreak: 'break-all'
    },
    comment: props => {
        const { color, fontFamily } = props
        return {
            color,
            fontFamily
        }
    },
    wdate: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    divider: {
        marginBottom: 25,
        marginTop: 1
    }
}))

export default (props) => {
    const { idd, comment, wdate } = props

    const { formatMessage: f } = useIntl()
    
    const { viewMode, design } = useContext(summaryContext)

    const color = getRGBA({color: design.question, opacity: 1})

    const classes = useStyles({ color, fontFamily: design.font_family })

    return (
        <div>
            {
                useMemo(() => {
                    return (
                        <>
                            <Typography variant="body1" display="block" className={`${classes.typography} ${classes.comment}`}>
                                {comment}
                            </Typography>
                            {
                                viewMode === 'view' && (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="body2" style={{fontSize: 12}} display="block" color="textSecondary" className={classes.typography}>
                                            {f({id: 'styled.Survey.Analysis.SummaryFileItem.id'}, { id: idd })}
                                        </Typography>
                                        <Typography variant="body2" style={{fontSize: 12}} display="block" color="textSecondary" className={`${classes.typography} ${classes.wdate}`}>
                                            {Number.isNaN(Number(wdate)) ? moment(wdate).format('YYYY.MM.DD HH:mm:ss') : moment(Number(wdate)).format('YYYY.MM.DD HH:mm:ss')}
                                        </Typography>
                                    </div>
                                )
                            }
                            <Divider light className={classes.divider}/>
                        </>  
                    )
                    
                }, [classes.typography, classes.comment, classes.wdate, classes.divider, viewMode, wdate, idd, comment])
            }
            
        </div>
    )
}