import { validation } from 'utils'
import { getPathname } from 'ts-utils'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import mime from 'mime'
import youtubeUrl from 'youtube-url'
import { isBrowser, isTablet, isMobileOnly } from 'react-device-detect'
import { withStyles } from '@material-ui/core/styles'
import { 
    RadioButtonCheckedOutlined as RadioButtonCheckedOutlinedIcon,
    CheckBoxOutlined as CheckBoxOutlinedIcon, 
    GridOn as GridOnIcon,
    LinearScale as LinearScaleIcon,
    BorderColor as BorderColorIcon,
    ChatBubbleOutline as ChatBubbleOutlineIcon,
    PhotoSizeSelectActual as PhotoSizeSelectActualIcon,
    FormatListNumbered as FormatListNumberedIcon,
    StarHalf as StarHalfIcon,
    Chat as ChatIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    ArtTrack as ArtTrackIcon,
    PlaylistAddCheck as PlaylistAddCheckIcon,
    MeetingRoom as MeetingRoomIcon,
    Attachment as AttachmentIcon,
    LocationOn as LocationOnIcon,
    ArrowDropDownCircleRounded as ArrowDropDownCircleRoundedIcon,
    Person as PersonIcon,
    Dns as DnsIcon,
    AccessTime as AccessTimeIcon,
    AccessAlarms as AccessAlarmsIcon,
    ExposurePlus1 as ExposurePlus1Icon
} from '@material-ui/icons'
import moment from 'moment'
import randomstring from 'randomstring'
import striptags from 'striptags'
import htmlParser from 'react-html-parser'
import { replaceNotNumber, getExt } from 'utils'
import { parseHtmlIntl } from 'ts-utils'
import { getKind as getFilterKind, getFiltername } from 'component/Survey/Create/Design/Custom/Filter/Component'

const LinearScaleVerticalIcon = withStyles(theme => ({
    root: {
        transform: `rotate(90deg)`,
    }
}))(LinearScaleIcon)


// 문항내용, 시작글, 완료글에서 html 태그 삭제
export const getQuestionParsing = (text) => {
    
    let o = striptags(text, [
        'a', 
        'i', 
        'strong', 
        'u', 
        'p', 
        's',
        'sup',
        'sub',
        'br', 
        'span', 
        'ul', 
        'ol', 
        'li', 
        'h1', 
        'h2', 
        'h3', 
        'h4', 
        'h5', 
        'h6', 
        'code',
        'pre'
    ])
    const length = o.length

    // 마지막에 p 태그는 삭제한다
    if (o.substring(length, length - 4) === '</p>') {
        o = o.substring(0, length - 4)
    }

    // p 태그를 br로 변경해준다
    o = o.replace(/<p>/g, '')
    o = o.replace(/<\/p>/g, '<br />')

    if (!o) return ''

    return htmlParser(o.split('\n').map(c => `${c}<br />`).join(''))
}

export const getExcelParsing = (text) => {
    
    let o = striptags(text, ['p'])
    const length = o.length

    // 마지막에 p 태그는 삭제한다
    if (o.substring(length, length - 4) === '</p>') {
        o = o.substring(0, length - 4)
    }

    // p 태그를 br로 변경해준다
    o = o.replace(/<p>/g, '')
    o = o.replace(/<\/p>/g, "\r\n")

    if (!o) return ''

    return htmlParser(o)
}


// device 값
export const getDevice = () => {
    if (isBrowser) return 'browser'
    else if (isTablet) return 'tablet'
    else if (isMobileOnly) return 'mobile'
    else return 'other'
}

// used: insight
export const secondsToTime = secs => {
    
    const hours = Math.floor(secs / (60 * 60))

    const divisor_for_minutes = secs % (60 * 60)
    const minutes = Math.floor(divisor_for_minutes / 60)

    const divisor_for_seconds = divisor_for_minutes % 60
    const seconds = Math.ceil(divisor_for_seconds)

    const h = hours < 10 ? `0${hours}` : hours
    const m = minutes < 10 ? `0${minutes}` : minutes
    const s = seconds < 10 ? `0${seconds}` : seconds

    return `${h}:${m}:${s}`
}

// used: insight
export const getDiffSeconds = (props) => {
    const { now='', then } = props

    const _now = now ? new Date(now) : new Date()

    const diff = moment.duration(then.diff(moment(_now)))

    return diff._milliseconds < 1000 ? 0 : Math.round(diff._milliseconds / 1000)
}


// 설문 참여 스크롤러 설정값
export const scrollerObj = () => ({
    style: {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden'

    },
    scrollTo: {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -80
    },
    id: randomstring.generate(7),
    target: 'caution',
    targetTop: 'top'
})


// 설문 링크주소
export const getShareLink = (props) => {
    const { domain='', args, analysisArgs } = props

    return {
        survey: {
            web: `${domain}/r/${args}`,
            email: `${domain}/e/${args}`,
            offline: `${domain}/o/${args}`,
            preview: `/ts/${args}`,
            custom: `${domain}/c/${args}`,
        },
        analysis: `${domain}/p/${analysisArgs}`
    }
}

export const getModuleIcon = (f) => {
    return [
        {
            type: '_00',
            name: f({id: 'component.Survey.Create.Module.Left.modules.0'}),
            icon: ChatIcon,
            kind: 'explain'
        },
        {
            type: '_16',
            name: f({id: 'component.Survey.Create.Module.Left.modules.16'}),
            icon: AssignmentTurnedInIcon,
            kind: 'explain'
        },
        {
            type: '_01',
            name: f({id: 'component.Survey.Create.Module.Left.modules.1'}),
            icon: RadioButtonCheckedOutlinedIcon,
            kind: 'choice'
        },
        {
            type: '_02',
            name: f({id: 'component.Survey.Create.Module.Left.modules.2'}),
            icon: CheckBoxOutlinedIcon,
            kind: 'choice'
        },
        {
            type: '_21',
            name: f({id: 'component.Survey.Create.Module.Left.modules.21'}),
            icon: ArrowDropDownCircleRoundedIcon,
            kind: 'choice'
        },
        {
            type: '_03',
            name: f({id: 'component.Survey.Create.Module.Left.modules.3'}),
            icon: GridOnIcon,
            kind: 'choice'
        },
        {
            type: '_04',
            name: f({id: 'component.Survey.Create.Module.Left.modules.4'}),
            icon: GridOnIcon,
            kind: 'choice'
        },
        {
            type: '_05',
            name: f({id: 'component.Survey.Create.Module.Left.modules.5'}),
            icon: LinearScaleIcon,
            kind: 'scale'
        },
        {
            type: '_06',
            name: f({id: 'component.Survey.Create.Module.Left.modules.6'}),
            icon: LinearScaleIcon,
            kind: 'scale'
        },
        {
            type: '_07',
            name: f({id: 'component.Survey.Create.Module.Left.modules.7'}),
            icon: LinearScaleVerticalIcon,
            kind: 'scale'
        },
        {
            type: '_08',
            name: f({id: 'component.Survey.Create.Module.Left.modules.8'}),
            icon: LinearScaleVerticalIcon,
            kind: 'scale'
        },
        {
            type: '_09',
            name: f({id: 'component.Survey.Create.Module.Left.modules.9'}),
            icon: BorderColorIcon,
            kind: 'text'
        },
        {
            type: '_10',
            name: f({id: 'component.Survey.Create.Module.Left.modules.10'}),
            icon: ChatBubbleOutlineIcon,
            kind: 'text'
        },
        {
            type: '_20',
            name: f({id: 'component.Survey.Create.Module.Left.modules.20'}),
            icon: LocationOnIcon,
            kind: 'text'
        },
        {
            type: '_11',
            name: f({id: 'component.Survey.Create.Module.Left.modules.11'}),
            icon: FormatListNumberedIcon,
            kind: 'rank'
        },
        {
            type: '_17',
            name: f({id: 'component.Survey.Create.Module.Left.modules.17'}),
            icon: PlaylistAddCheckIcon,
            kind: 'rank'
        },
        {
            type: '_18',
            name: f({id: 'component.Survey.Create.Module.Left.modules.18'}),
            icon: ArtTrackIcon,
            kind: 'rank'
        },        
        {
            type: '_12',
            name: f({id: 'component.Survey.Create.Module.Left.modules.12'}),
            icon: PhotoSizeSelectActualIcon,
            kind: 'choice'
        },
        {
            type: '_13',
            name: f({id: 'component.Survey.Create.Module.Left.modules.13'}),
            icon: PhotoSizeSelectActualIcon,
            kind: 'choice'
        },
        {
            type: '_14',
            name: f({id: 'component.Survey.Create.Module.Left.modules.14'}),
            icon: StarHalfIcon,
            kind: 'scale'
        },
        {
            type: '_15',
            name: f({id: 'component.Survey.Create.Module.Left.modules.15'}),
            icon: StarHalfIcon,
            kind: 'scale'
        },
        {
            type: '_19',
            name: f({id: 'component.Survey.Create.Module.Left.modules.19'}),
            icon: AttachmentIcon,
            kind: 'file'
        },
        {
            type: '_ending',
            name: f({id: 'component.Survey.Create.Module.Left.ending'}),
            icon: MeetingRoomIcon,
            kind: 'ending'
        },
        {
            type: 'id',
            name: 'ID',
            icon: PersonIcon,
            kind: 'rows'
        },
        {
            type: 'ip',
            name: 'IP',
            icon: DnsIcon,
            kind: 'rows'
        },
        {
            type: 'accesstime',
            name: 'Accestime',
            icon: AccessTimeIcon,
            kind: 'rows'
        },
        {
            type: 'einterval',
            name: 'Submittime',
            icon: AccessAlarmsIcon,
            kind: 'rows'
        },
        {
            type: '_point',
            name: 'Point',
            icon: ExposurePlus1Icon,
            kind: 'rows'
        }
    ]
}

export const getPreviewMode = () => {
    let mode = 'preview'

    if (window.location.pathname.indexOf('/ts/') !== -1) mode = 'previewTs'
    
    return mode
}

export const getBackgroundImageOpacity = (props) => {

    const { background_opacity, background_image } = props

    if (background_image) {
        const color = background_opacity > .5 ? '255, 255, 255' : '0, 0, 0'
        const opacity = Math.abs(background_opacity - .5)
    
        return `linear-gradient(rgba(${color}, ${opacity}), rgba(${color}, ${opacity})), url(${background_image})`
    }

    return ''
}

// 백그라운드 이미지 주소 구하기
export const getBackgroundPath = (props) => {
    const { background_image, background_filter } = props

    let backgroundImage = background_image

    if (background_filter) {
        const kind = getFilterKind(background_image)
        const filename = getFiltername(background_image, background_filter, kind)
        backgroundImage = kind === 'unsplash' ? `${process.env.REACT_APP_CF_UNSPLASH}/${filename}` : `${getPathname(background_image)}/${filename}`
    }

    return backgroundImage
}

// 백그라운드 설정
export const getBackground = (props) => {
    const { background_color, background_image, background_filter, background_size, background_opacity } = props

    let style = {}

    if (background_image) {
        const backgroundImage = getBackgroundPath({ background_image, background_filter })

        style = {
            ...style,
            backgroundImage: `${getBackgroundImageOpacity({ background_opacity, background_image: backgroundImage })}`,
            backgroundSize: background_size,
            backgroundRepeat: background_size === 'contain' ? 'no-repeat' : 'repeat',
            backgroundPosition: 'center'
        }
    }

    style = {
        ...style,
        backgroundColor: `rgba(${background_color})`,
        backgroundAttachment: 'fixed'
    }

    return style
}

// 파일명을 확인해서 이미지 인지 영상인지 영상이면 일반인지 유트비인지 체크
export const getMimePicture = (src) => {
    if (!src) return ''

    const mimeType = mime.getType(src)

    // hwp 문서는 mime 타입에 안잡혀서 임의로 준다.
    if (!mimeType) {
        const ext = getExt(src)
        if (ext === 'hwp' || ext === 'hwpx') return 'file'
    }

    const youtubeType = youtubeUrl.valid(src)
    const unsplashType = src.indexOf('unsplash') !== -1
    const vimeoType = src.indexOf('vimeo') !== -1

    return (
        !mimeType ? (
            youtubeType ? 'youtube' : 
            unsplashType ? 'image' :
            vimeoType ? 'video' : 'image'
        ) : (
            mimeType.indexOf('image') !== -1 ? 'image' : 
            mimeType.indexOf('video') !== -1 ? 'videdo' : 'file'
        )
    )
}



// 응답값
export const getReply = (props) => {
    const { replys, question } = props
    
    return replys.filter(reply => reply.survey_question_no === question._question.survey_question_no)
}



// 코멘트값
export const getComment = (props) => {
    const { replyComments, question } = props
    
    const replyComment = replyComments.find(c => c.survey_question_no === question._question.survey_question_no)
    return replyComment ? replyComment.comment : ''
}



// 문항 선택시 질문내용에 html 제거하고... html은잇는데 텍스트가 없을경우 다른문구로 대처한다
export const getDefaultQuestion = (props) => {
    const {f, number, question, question_type } = props
 
    let str = question ? question : f({id: 'component.Survey.Modules.Common.Subject.question'})

    return parseHtmlIntl(f({id: `utils.survey.getDefaultQuestion.title.${question_type}`}, {number, str}))
}

// 이미지 포지션 설정 (유투브 인데 포지션이 split면 float로 변경한다)
export const getImgPosition = ({ img_position, img_src }) => {
    const youtubeType = youtubeUrl.valid(img_src)
    const mimeType = getMimePicture(img_src)

    if (youtubeType && img_position === 'split') return 'float'
    if (mimeType === 'file') return 'stack'

    return img_position
}

// 설문 저장시 코멘트값 저장 (state로 처리하는게 아니라서 ㅜ.ㅜ)
export class saveComment {
    //reply comment값을 data에 저장
    append_01(c, comments) {
        return {
            ...c,
            comment: typeof comments.current[c.survey_module_no] === 'undefined' ? '' : comments.current[c.survey_module_no].value
        }
    }

    append_02(c, comments) {
        return {
            ...c,
            comment: typeof comments.current[c.survey_module_no] === 'undefined' ? '' : comments.current[c.survey_module_no].value
        }
    }

    append_09(c, answers, type) {
        return {
            ...c,
            answer: typeof answers.current[c.survey_question_no] === 'undefined' ? '' : answers.current[c.survey_question_no].value
        }
    }

    append_10(c, answers ) {
        return {
            ...c,
            answer: typeof answers.current[c.survey_question_no] === 'undefined' ? '' : answers.current[c.survey_question_no].value
        }
    }

    append_11(c, answers ) {
        return {
            ...c,
            answer: typeof answers.current[c.survey_question_no] === 'undefined' ? '' : answers.current[c.survey_question_no].value
        }
    }

    append_12(c, comments) {
        return {
            ...c,
            comment: typeof comments.current[c.survey_module_no] === 'undefined' ? '' : comments.current[c.survey_module_no].value
        }
    }

    append_13(c, comments) {
        return {
            ...c,
            comment: typeof comments.current[c.survey_module_no] === 'undefined' ? '' : comments.current[c.survey_module_no].value
        }
    }

    append_17(c, comments) {
        return {
            ...c,
            comment: typeof comments.current[c.survey_module_answer_no] === 'undefined' ? '' : comments.current[c.survey_module_answer_no].value
        }
    }

    append_20(c, answers ) {
        return {
            ...c,
            address2: typeof answers.current[c.survey_question_no] === 'undefined' ? '' : answers.current[c.survey_question_no].value
        }
    }
    
    append_with(survey_question_no, comments, module_type) {
        return {
            survey_question_no,
            comment: typeof comments.current[survey_question_no] === 'undefined' ? '' : comments.current[survey_question_no].value,
            module_type: module_type.replace('Module', '')
        }
    }

    //코멘트값을 state값에 저장
    saveAnswerComment({questions, rows, refComments}) {
        return rows.map(c => {
            const { module_type } = c
            
            // 보기에 있는 comment copy로 저장
            // 입력형 경우 답변 자체가 코멘트임
            switch (module_type) {
                case '_01':
                    return this.append_01(c, refComments.refComments01)
                case '_02':
                    return this.append_02(c, refComments.refComments02)
                case '_09':
                    // type이 number 일경우 , 를 제거하기위해... type값을 넘겨준다
                    const { type } = questions.find(d => d._question.survey_question_no === c.survey_question_no)._option
                    return this.append_09(c, refComments.refComments09, type)
                case '_10':
                    return this.append_10(c, refComments.refComments10)
                case '_12':
                    return this.append_12(c, refComments.refComments12)
                case '_13':
                    return this.append_13(c, refComments.refComments13)
                case '_17':
                    return this.append_17(c, refComments.refComments17)
                case '_18':
                    return this.append_17(c, refComments.refComments18)
                case '_20':
                    return this.append_20(c, refComments.refComments20)
                default:
                    return c
            }
        })
    }

    //코멘트값을 state값에 저장
    saveQuestionComment({ questions, refCommentsWith }) {
        return questions.filter(c => {
            switch (c.__typename) {
                case 'Module_03':
                case 'Module_04':
                case 'Module_05':
                case 'Module_06':
                case 'Module_07':
                case 'Module_08':
                case 'Module_11':
                case 'Module_14':
                case 'Module_15':
                case 'Module_19':
                case 'Module_21':
                    return true
                default:
                    return false
            }
        }).filter(c => c._option.is_comment).map(c => {
            return this.append_with(c._question.survey_question_no, refCommentsWith, c.__typename)
        })
    }
}





// 필수응답 체크
export class checkRequired {
    constructor() {
        this.obj = {}
        
        this.questions = []
        this.replys = []
        this.replyComments = []
        this.result = []
        this.confirm09Rows = []
        this._passComment=false // 코맨트withs 필수 응답 체크 안할때 true
    }

    set passComment(bool) {
        this._passComment = bool
    }

    init(props) {
        const { 
            replys, 
            questions, replyComments, confirm09Rows,  } = props

        this.questions = [...questions]
        this.replys = [...replys]
        this.replyComments = [...replyComments]
        this.result = [true]
        this.confirm09Rows = confirm09Rows || []
    }

    // 중복으로 선탣된 값이 있는지 체크 (순위형에서 이상한 버그가 생겨서 순위를선택한 값이 있는지 체크한다.)
    isOverlapValue(values) {
        const len = values.length
        const isValue = []
        for (let i=0; i<len; i++) {
          const value = values[i]
          if (isValue.includes(value)) {
            return true
          }
          
          isValue.push(value)
        }
        
        return false
    }

    _01(props) {
        const { reply, modules, option } = props
        
        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.01']

        // 답변의 코멘트가 필수인데 코멘트가 입력안된 데이터 찾기
        const isReplyComment = reply.filter(c => !c.comment).filter(c => {
            const { survey_module_no } = c

            const { is_comment } = modules.find(d => d.survey_module_no === survey_module_no)

            return is_comment
        }).length > 0 ? true : false 

        if (option.is_comment_required && isReplyComment) return [false, 'comment.01']

        return [true]
    }

    _02(props) {
        const { reply, modules, option } = props
        
        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && !(option.required_start <= isReply.length && option.required_end >= isReply.length)) return [false, 'answer.02']

        // 답변의 코멘트가 필수인데 코멘트가 입력안된 데이터 찾기
        const isReplyComment = reply.filter(c => !c.comment).filter(c => {
            const { survey_module_no } = c

            const { is_comment } = modules.find(d => d.survey_module_no === survey_module_no)

            return is_comment
        }).length > 0 ? true : false 

        if (option.is_comment_required && isReplyComment) return [false, 'comment.02']

        return [true]
    }

    _03(props) {
        const { reply, replyComment, modules, option } = props
        
        // modulses에 잇는 행값이 reply에 다 있는지 체크, reply에 없는것만 담는다
        const isModules = modules.filter(c => {
            const { survey_module_no } = c
            return !reply.find(d => d.survey_module_no === survey_module_no)
        })
      
        // 없는값만 담은것이므로 길이 1이상이란느건 선택안된 행이 있다는거다
        if (option.required && isModules.length > 0) return [false, 'answer.03']

        // 필수응답이 아니더라도 한개의 행이라도 값을 선택햇다면 나머지도 선택하게 한다 (모든행 다선택하던지 모든행 다선택안하던지)
        if (isModules.length !== modules.length && isModules.length > 0) return [false, 'answer.r03']
        
        const isReply = reply.filter(c => c.survey_module_answer_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.03']

        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.03']
        } 

        return [true]
    }
    
    _04(props) {
        const { reply, replyComment, modules, option } = props
        
        // modulses에 잇는 행값이 reply에 다 있는지 체크, reply에 없는것만 담는다
        const isModules = modules.filter(c => {
            const { survey_module_no } = c
            return !reply.find(d => d.survey_module_no === survey_module_no)
        })

        // 없는값만 담은것이므로 길이 1이상이란느건 선택안된 행이 있다는거다
        if (option.required && isModules.length > 0) return [false, 'answer.04']

        // 필수응답이 아니더라도 한개의 행이라도 값을 선택햇다면 나머지도 선택하게 한다 (모든행 다선택하던지 모든행 다선택안하던지)
        if (isModules.length !== modules.length && isModules.length > 0) return [false, 'answer.r04']

        // 답변값이 있는지 체크
        if (option.required) {
            // 행번호로 그룹바이
            const survey_module_nos = reply.reduce((acc, c) => {
                const { survey_module_no } = c
                return acc.includes(survey_module_no) ? acc : [ ...acc, survey_module_no ]
            }, [])

            // 행별로 답변필수 개수에 맞는지 체크하고 안맞는게 잇다면 return 시킨다
            // isNotReply에 값이 있단거는 답변개수가 안맞는 행이 있다는거다
            const isNotReply = survey_module_nos.find(c => {
                const isAnswer = reply.filter(d => {
                    return d.survey_module_no === c && d.survey_module_answer_no
                })

                return !(option.required_start <= isAnswer.length && option.required_end >= isAnswer.length)
            })

            if (isNotReply) return [false, 'answer.04']
        }

        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.04']
        }

        return [true]
    }

    _05(props) {
        const { reply, replyComment, option } = props
        
        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.05']

        // 코멘트 입력이 필수인데 입력안할시
        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.05']
        }

        return [true]
    }

    _06(props) {
        const { reply, replyComment, modules, option } = props
        
        // modulses에 잇는 행값이 reply에 다 있는지 체크, reply에 없는것만 담는다
        const isModules = modules.filter(c => {
            const { survey_module_no } = c
            return !reply.find(d => d.survey_module_no === survey_module_no)
        })

        // 없는값만 담은것이므로 길이 1이상이란느건 선택안된 행이 있다는거다
        if (option.required && isModules.length > 0) return [false, 'answer.06']

        // 필수응답이 아니더라도 한개의 행이라도 값을 선택햇다면 나머지도 선택하게 한다 (모든행 다선택하던지 모든행 다선택안하던지)
        if (isModules.length !== modules.length && isModules.length > 0) return [false, 'answer.r06']

        const isReply = reply.filter(c => c.survey_module_answer_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.06']

        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.06']
        }

        return [true]
    }

    _07(props) {
        const { reply, replyComment, option } = props
        
        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.07']

        // 코멘트 입력이 필수인데 입력안할시
        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.07']
        }

        return [true]
    }

    _08(props) {
        const { reply, replyComment, modules, option } = props
        
        // modulses에 잇는 행값이 reply에 다 있는지 체크, reply에 없는것만 담는다
        const isModules = modules.filter(c => {
            const { survey_module_no } = c
            return !reply.find(d => d.survey_module_no === survey_module_no)
        })

        // 없는값만 담은것이므로 길이 1이상이란느건 선택안된 행이 있다는거다
        if (option.required && isModules.length > 0) return [false, 'answer.08']

        // 필수응답이 아니더라도 한개의 행이라도 값을 선택햇다면 나머지도 선택하게 한다 (모든행 다선택하던지 모든행 다선택안하던지)
        if (isModules.length !== modules.length && isModules.length > 0) return [false, 'answer.r08']

        const isReply = reply.filter(c => c.survey_module_answer_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.08']

        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.08']
        }

        return [true]
    }

    _09(props) {
        const { reply, option } = props
        const len = reply.length
        const answered = len === 0 ? '' : reply[0].answer
        const national_flaged = len === 0 ? '' : reply[0].national_flag
        const survey_question_no = len === 0 ? '' : reply[0].survey_question_no

        // 필수 응답 체크
        if (option.required && answered === '') return [false, 'answer.09.required']

        switch(option.type) {
            case 'base':
                if ( answered && !(option.min_length <= answered.length && option.max_length >= answered.length) ) return [false, 'answer.09.base']
                break
            case 'number':
                const answeredNumber = Number(replaceNotNumber(answered, option.minus, option.decimald))
                if ( !(Number(option.min_number) <= answeredNumber && Number(option.max_number) >= answeredNumber ) ) return [false, 'answer.09.number']
                break
            case 'email':
                if ( answered && !validation.email(answered) ) return [false, 'answer.09.email']
                break
            case 'phone':
                if ( answered && !national_flaged) return [false, 'answer.09.national_flag']
                if ( answered ) {
                    const vaildPhone = parsePhoneNumberFromString(answered, national_flaged)

                    if (typeof vaildPhone === 'undefined') return [false, 'answer.09.phone_null']
                    if (!vaildPhone.isValid()) return [false, 'answer.09.phone_null']

                    if (option.only_mobile && vaildPhone.getType().indexOf('MOBILE') === -1) return [false, 'answer.09.phone_mobile']
                }
                
                break
            default:
        }

        // 멀티형 + 해당문항에대한 점프로직 설정이이 있을경우 확인버튼을 반드시 눌러야한다. (누르지 않는다면 눌르라고 안내한다.)
        if (this.confirm09Rows.includes(survey_question_no)) {
            return [false, 'answer.confirm']
        }

        return [true]
    }

    _10(props) {
        const { reply, option } = props
        const len = reply.length
        const answered = len === 0 ? '' : reply[0].answer
        const survey_question_no = len === 0 ? '' : reply[0].survey_question_no

        // 필수 응답 체크
        if (option.required && !answered) return [false, 'answer.10']

          // 멀티형 + 해당문항에대한 점프로직 설정이이 있을경우 확인버튼을 반드시 눌러야한다. (누르지 않는다면 눌르라고 안내한다.)
          if (this.confirm09Rows.includes(survey_question_no)) {
            return [false, 'answer.confirm']
        }

        return [true]
    }

    _11(props) {
        const { reply, replyComment, modules, option } = props


        // modulses에 잇는 행값이 reply에 다 있는지 체크, reply에 없는것만 담는다
        const isModules = modules.filter(c => {
            const { survey_module_no } = c
            return !reply.find(d => d.survey_module_no === survey_module_no)
        })

        // 없는값만 담은것이므로 길이 1이상이란느건 선택안된 행이 있다는거다
        if (option.required && isModules.length > 0) return [false, 'answer.11.required']

        // 필수응답 아니더라도 한개이상 선택된 값이 있다면 나머지도 순위체크하게 하자
        if (reply.length > 0 && isModules.length > 0) return [false, 'answer.11.rest']

        // 중복으로 순위가 선택된게있는지 체크 (스크립트에서 오류가 발생한건지 sunny 사용자에게서 17번 클릭형 순위형에서 발견하였는데 여기도 모르니.. 일단 넣음)
        if (this.isOverlapValue(reply.map(c => c.survey_module_no))) {
            return [false, 'answer.11.overlap']
        }

        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.11']
        }

        return [true]
    }

    _12(props) {
        const { reply, modules, option } = props
        
        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.12']

        // 답변의 코멘트가 필수인데 코멘트가 입력안된 데이터 찾기
        const isReplyComment = reply.filter(c => !c.comment).filter(c => {
            const { survey_module_no } = c

            const { is_comment } = modules.find(d => d.survey_module_no === survey_module_no)

            return is_comment
        }).length > 0 ? true : false 

        if (option.is_comment_required && isReplyComment) return [false, 'comment.12']

        return [true]
    }

    _13(props) {
        const { reply, modules, option } = props
        
        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && !(option.required_start <= isReply.length && option.required_end >= isReply.length)) return [false, 'answer.13']

        // 답변의 코멘트가 필수인데 코멘트가 입력안된 데이터 찾기
        const isReplyComment = reply.filter(c => !c.comment).filter(c => {
            const { survey_module_no } = c

            const { is_comment } = modules.find(d => d.survey_module_no === survey_module_no)

            return is_comment
        }).length > 0 ? true : false 

        if (option.is_comment_required && isReplyComment) return [false, 'comment.13']

        return [true]
    }

    _14(props) {
        const { reply, replyComment, option } = props
        
        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.14']

        // 코멘트 입력이 필수인데 입력안할시
        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.14']
        }

        return [true]
    }

    _15(props) {
        const { reply, replyComment, modules, option } = props
        
        // modulses에 잇는 행값이 reply에 다 있는지 체크, reply에 없는것만 담는다
        const isModules = modules.filter(c => {
            const { survey_module_no } = c
            return !reply.find(d => d.survey_module_no === survey_module_no)
        })

        // 없는값만 담은것이므로 길이 1이상이란느건 선택안된 행이 있다는거다
        if (option.required && isModules.length > 0) return [false, 'answer.15']

        // 필수응답이 아니더라도 한개의 행이라도 값을 선택햇다면 나머지도 선택하게 한다 (모든행 다선택하던지 모든행 다선택안하던지)
        if (isModules.length !== modules.length && isModules.length > 0) return [false, 'answer.r15']

        const isReply = reply.filter(c => c.survey_module_answer_no) 
        if (option.required && isReply.length === 0) return [false, 'answer.15']

        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.15']
        }

        return [true]
    }

    _16(props) {
        const { reply, option } = props
        const { required, required_message } = option

        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (required && isReply.length === 0) return [false, 'answer.16', required_message]

        return [true]
    }

    _17(props) {
        const { reply, answers, option } = props

        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && !(option.required_start <= isReply.length && option.required_end >= isReply.length)) return [false, 'answer.17.required']

        // 중복으로 순위가 선택된게있는지 체크 (스크립트에서 오류가 발생한건지 sunny 사용자에게서 발견)
        if (this.isOverlapValue(reply.map(c => c.survey_module_no))) {
            return [false, 'answer.17.overlap']
        }

        // 답변의 코멘트가 필수인데 코멘트가 입력안된 데이터 찾기
        const isReplyComment = reply.filter(c => !c.comment).filter(c => {
            const { survey_module_answer_no } = c

            const { is_comment } = answers.find(d => d.survey_module_answer_no === survey_module_answer_no)

            return is_comment
        }).length > 0 ? true : false 

        if (option.is_comment_required && isReplyComment) return [false, 'comment.17']

        return [true]
    }

    _19(props) {
        const { reply, option, replyComment } = props
        const len = reply.length
        const answered = len === 0 ? '' : reply[0].filename

        if (option.required && !answered) return [false, 'answer.19']

        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.19']
        }

        return [true]
    }

    _20(props) {
        const { reply, option } = props
        const len = reply.length
        const { survey_question_no, zipcode, address1, address2 } = len === 0 ? { survey_question_no: '', zipcode: '', address1: '', address2: '' } : reply[0]
        
        // 상세주소 필수 이면 address2 입력필수, 아니면 zipcode, address1
        const isAnswered = option.required_address2 ? (zipcode && address1 && address2) : (zipcode && address1)

        // 필수 응답 체크
        if (option.required && !isAnswered) return [false, 'answer.10']

          // 멀티형 + 해당문항에대한 점프로직 설정이이 있을경우 확인버튼을 반드시 눌러야한다. (누르지 않는다면 눌르라고 안내한다.)
          if (this.confirm09Rows.includes(survey_question_no)) {
            return [false, 'answer.confirm']
        }

        return [true]
    }

    _21(props) {
        const { reply,option, replyComment } = props
        
        const kindNumber = !option.kind ? 'one' : 'multi'
        
        // 답변값이 있는지 체크
        const isReply = reply.filter(c => c.survey_module_no) 
        if (option.required && !(option.required_start <= isReply.length && option.required_end >= isReply.length)) return [false, `answer.21.${kindNumber}`]

        if (!this._passComment) {
            if (option.is_comment && option.is_comment_required && !replyComment) return [false, 'comment.21']
        }

        return [true]
    }


    // 질문에있는  코멘트
    getComment(survey_question_no) {
        const is_comment = this.replyComments.find(c => c.survey_question_no === survey_question_no)
        
        return is_comment ? is_comment.comment : ''
    }

    async do() {
        await this.questions.some(c => {
            
            const { _question, _modules,  _option } = c
            const reply = this.replys.filter(reply => reply.survey_question_no === _question.survey_question_no)

            let bool = ''
            let code = ''
            let message = ''

            switch(_question.module_type) {
                case '_01':
                    [ bool, code ] = this._01({reply, modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_02': 
                    [ bool, code ] = this._02({reply, modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_03': 
                    [ bool, code ] = this._03({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_04': 
                    [ bool, code ] = this._04({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_05':
                    [ bool, code ] = this._05({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_06':
                    [ bool, code ] = this._06({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }
    
                    break
                case '_07':
                    [ bool, code ] = this._07({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_08':
                    [ bool, code ] = this._08({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }
    
                    break
                case '_09':
                        [ bool, code ] = this._09({reply, option: _option})
    
                        if (!bool) {
                            this.result = [ bool, _question.survey_question_no, code ]
                            return true
                        }
        
                        break
                case '_10':
                    [ bool, code ] = this._10({reply, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }
    
                    break
                case '_11': 
                    [ bool, code ] = this._11({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_12':
                    [ bool, code ] = this._12({reply, modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_13': 
                    [ bool, code ] = this._13({reply, modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_14':
                    [ bool, code ] = this._14({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_15':
                    [ bool, code ] = this._15({reply, replyComment: this.getComment(_question.survey_question_no), modules: _modules, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }
    
                    break
                case '_16':
                 
                    [ bool, code, message ] = this._16({reply, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code, message ]
                        return true
                    }

                    break

                case '_17': 
                case '_18': 
                    [ bool, code ] = this._17({reply, answers: c._answers, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }

                    break
                case '_19':     
                    [ bool, code ] = this._19({reply, replyComment: this.getComment(_question.survey_question_no), option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }
                    break
                case '_20':
                    [ bool, code ] = this._20({reply, option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }
                    
                    break
                case '_21': 
                    [ bool, code ] = this._21({reply, replyComment: this.getComment(_question.survey_question_no), option: _option})

                    if (!bool) {
                        this.result = [ bool, _question.survey_question_no, code ]
                        return true
                    }
                break
                default:
                    break
            }
            return false
        })

        return this.result
    }
}