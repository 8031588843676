import { memo } from 'react'
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getModuleIcon } from 'utils/survey'
import Tooltip from 'styled/Custom/Tooltip'
import { useIntl } from 'react-intl'
import oc from 'open-color'

const useStyles = makeStyles({
    typography: ({ lineClamp }) => {
        return {
            display: '-webkit-box',
            wordWrap: 'break-word', 
            '-webkitLineClamp': lineClamp,
            '-webkitBoxOrient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }
})

const Component = (props) => {

    const { question, number, questionType, moduleType, alignItems="center", iconColor=oc.gray[5], color=oc.gray[7], variant="body1", lineClamp=1 } = props
    const { formatMessage: f } = useIntl()

    const classes = useStyles({ lineClamp })
    const icons = getModuleIcon(f)

    const icon = icons.find(c => c.type === moduleType)

    const Icon = icon.icon
    const tooltip = icon.name

    const customNumber  = questionType === 'question' ? `${number}.` : `(${number})`

    const _question = number > 0 ? `${customNumber} ${question}` : question
    
    return (
        <ListItem dense disableGutters alignItems={alignItems}>
            <ListItemIcon style={{ minWidth: 35}}> 
                <Tooltip title={tooltip} isMobileClick={true}>
                    <Icon fontSize="small" style={{color: iconColor}}/>
                </Tooltip>
            </ListItemIcon>
            <ListItemText style={{paddingTop: alignItems === 'flex-start' ? 2 : 0}}>
                <Typography style={{color}} variant={variant} className={classes.typography} >{_question}</Typography>
            </ListItemText>
        </ListItem>
    )
}

export default memo(Component)