import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { ADD, REMOVE } from 'reducer/survey/live/confirm09'
import CheckIcon from '@material-ui/icons/Check'
import { parseHtmlIntl } from 'ts-utils'
import Button from 'styled/Custom/Button'
import useChangeReplys from '../useChangeReplys'
import { memoryReply } from 'container/Survey/Live/Container'

interface Props {
    survey_question_no: number;
    value: string;
    isEqualAnswer: boolean;
}

function getRows(survey_question_no:number, answer:string) {
    memoryReply.rows =  memoryReply.rows.map(c => c.survey_question_no === survey_question_no ? { ...c, answer } : c)
    return memoryReply.rows
}

function ConfirmComponent(props: Props) {
    const { survey_question_no, value, isEqualAnswer } = props
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    useEffect(() => {
        if (!isEqualAnswer) dispatch({ type: ADD, survey_question_no })
        else dispatch({ type: REMOVE, survey_question_no })
    }, [ isEqualAnswer, survey_question_no, dispatch])

    return (
        <Button 
            size="medium"
            startIcon={<CheckIcon/>} 
            style={{marginTop: '1em'}} 
            disabled={isEqualAnswer} 
            onClick={() => onChangeReplys(getRows(survey_question_no, value))}
        >
            {parseHtmlIntl(f({id: 'component.Survey.Modules.09.Confirm.button'}))}
        </Button>
    )
}

export default memo(ConfirmComponent)