import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const GridContainer = withStyles(theme => ({
    root: props => {
        const { index, module_number } = props
        
        const padding  = module_number === 15 ? '15px 10px 5px 10px' : '15px 10px'

        return {
            background: index % 2 === 0 ? 'rgba(0, 0, 0, .1)' : 'transparent',
            padding,
            borderRadius: '5px',
            [theme.breakpoints.down('xs')]: {
                background: 'transparent',
                minWidth: 'initial',
                padding: '15px 0',
            }
        }
    }
}))((props) => {
    const { index, ...other } = props

    return (
        <Grid {...other} container index={index} alignItems="center"/>
    )
})

export const GridColumn = withStyles(theme => ({
    root: props => {
        const { module_number } = props
        return {
            marginBottom: module_number === 15 ? 3 : 10
        }
    }
}))((props) => {
    const { ...other } = props
    return (
        <Grid {...other} item xs={12} sm={12} md={12}/>
    )
})

export const GridAnswer = withStyles({})((props) => {
    
    return (
        <Grid {...props} item xs={12} sm={12} md={12}/>
    )
})

export default (props) => {
    const { index, module_number, children } = props

    return (
        <GridContainer index={index} module_number={module_number}>
            {children}
        </GridContainer>
    )
}