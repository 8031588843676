import { useState } from 'react'
import { useIntl } from 'react-intl'
import { numberFormat } from 'utils'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Typography from "@material-ui/core/Typography"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Storage } from 'gql/storage'
import { getPercent } from './Component'

interface Props {
    rows: Storage['response']
}

const MyBox = withStyles({
    root: {
        maxWidth: 450,
        '& + &': {
            marginTop: 18
        }
    }
})(Box)

const MyExp = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
})(Box)

function MyResponseComponent(props: Props) {
    const { rows } = props

    const { formatMessage: f } = useIntl()

    const [ more, setMore ] = useState(false)

    return (
        <>
        <Typography gutterBottom>{f({id: 'component.MyAccount.Storage.MyResponse.title'})}</Typography>
        {
            rows.filter((c, i) => {
                if (!more && i >= 12) return false
                return true
            }).map((c, i) => (
                <MyBox key={i}>
                    <LinearProgress variant="determinate" color="primary" value={getPercent(c.hit, c.total)}/>
                    <MyExp>
                        <Typography component="span" variant="body2">{numberFormat(c.hit)} / {numberFormat(c.total)}</Typography>
                        <Typography component="span" variant="body2">~{c.ldate}</Typography>
                    </MyExp>
                </MyBox>
            ))
        }
        {
            (!more && rows.length >= 12) && (
                <IconButton size="small" onClick={() => setMore(true)}>
                    <ArrowDropDownIcon/>
                </IconButton>
            )
        }
        </>
    )
}

export default MyResponseComponent