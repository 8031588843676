/**
 * Date 형식에 사용되는 Calendar
 */
import { useState, memo, useContext, useMemo, useCallback, useEffect } from "react"
import { injectIntl, useIntl, IntlShape } from 'react-intl'
import moment, { Moment } from 'moment'
import PropsContext from 'context/props'
import { getColorConcentration } from 'utils'
import { getRGBA } from 'utils/analysis'
import { getLocale } from 'ts-utils'
import oc from 'open-color'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { createTheme, ThemeProvider  } from '@material-ui/core/styles'
import DateFnsUtils  from '@date-io/date-fns'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { Props as ParentProps, OnChange, OnClose, AnchorEl } from './Date'

interface Props {
	survey_question_no: ParentProps['survey_question_no'];
    anchorEl: AnchorEl;
	value: string;
	intl: IntlShape;
	date_start: ParentProps['date_start'];
	date_range: ParentProps['date_range'];
	onChange: OnChange;
	onClose: OnClose;
}

const thisYear = moment().format('YYYY')

// moment는 YYYY-MM-DD 형식만 제공된다.
const replaceMoment = (value: string) => {
	if (!value) return ''

	let dot = '-'
	if (value.indexOf('.') >= 0) dot = '.'
	else if (value.indexOf('/') >= 0) dot = '/'

	const [ d1, d2, d3 ] = value.split(dot)

	if (d1.length === 4) return `${d1}-${d2}-${d3}`
	
	return `${d3}-${d1}-${d2}`
}

const Calendar = (props: Props) => {
    const { 
		survey_question_no, 
		anchorEl, 
		value, 
		intl, 
		date_start,
		date_range,
		onClose, 
		onChange 
	} = props
	
	// 사용자가 range를 설정했을 경우

	const [ minDate, maxDate ] = date_range ? date_range.split('~') : [ '1900-01-01', `${Number(thisYear) + 70}-12-31` ]
	const { design } = useContext<any>(PropsContext)
    const { answer_sbutton } = design

	const { formatMessage: f } = useIntl()

	// 배경색
	const backgroundColor = `rgba(${answer_sbutton})`

	// 배격생에 따라 color 색을 white black으로
    const concent = getColorConcentration(answer_sbutton)
	const color = concent ? 'white' : 'black'

	// 년도 색상은 opacity 1로 했을기준으로 한다.
	const yearColor = getRGBA({color: answer_sbutton, opacity: 1})
	const yearConcent = getColorConcentration(yearColor.replace('rgba(', '').replace(')', ''))
	const yearSelectedColor = yearConcent ? yearColor : oc.gray[9]

    const [ date, setDate ] = useState<Moment | Date | ''>(value ? moment(replaceMoment(value)) : '')

	// date 있으면 date 없으면 date_start	
	let finalDate = date ? moment(date).format('YYYY-MM-DD') : date_start || moment().format('YYYY-MM-DD')

	// 지정범위가 있고 그 범위를 벗어났다면 지정범위 최소 날짜로 한다.
	if (date_range) {
		const [ s, e ] = date_range.split('~')
		
		if (finalDate < s || finalDate > e) {
			finalDate = s
		}
	}
	
	// 날짜변경시 업데이트
	const handleChange = useCallback((val: Date) => {
		setDate(val)
	}, [])

	// 확인 버튼 누를때
	const handleConfirm = useCallback(() => {
		onChange(moment(finalDate))
		onClose()
	}, [finalDate, onChange, onClose])

    const materialTheme = createTheme({
        overrides: {
          	MuiPickersToolbar: {
            	toolbar: {
            		backgroundColor
            	}
          	},
			MuiPickersYear: {
				yearSelected: {
					color: yearSelectedColor
				},
				yearDisabled: {
					color: 'gray'
				}
				
			},
          	MuiPickersDay: {
            	daySelected: {
              		backgroundColor,
					boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
					color,
              		'&:hover': {
                		backgroundColor
              		}
            	},
            	dayDisabled: {
            		color: oc.gray[5]
            	}
          	}
		}
    })

	const handleEl = useCallback((ele: Element) => {
		// 달력 툴바 내용
		const ele1 = ele.getElementsByTagName('h6')[0]
		const ele2 = ele.getElementsByTagName('h4')[0]

		if (!ele1 || !ele2) return
		
		const mark = '<span style="font-size: 13px;"> ▼</span>'
		ele1.innerHTML = `${ele1.innerHTML.replace(mark, '')}${mark}`
		ele1.style.color = color
		ele2.style.color = color
	}, [color])

    const open = Boolean(anchorEl)
    const id = open ? `date-calendar-${survey_question_no}` : undefined

	useEffect(() => {
		if (open) {
			const ymd = value ? moment(replaceMoment(value)) : ''
			setDate(ymd)
		}
	}, [open, value])

    return (
		useMemo(() => {
			return (
				<Popover id={id} open={open} anchorEl={anchorEl} getContentAnchorEl={handleEl} onClose={onClose}>
					<ThemeProvider theme={materialTheme}>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(intl.locale)}>
							<DatePicker 
								autoOk
								variant="static" 
								orientation="portrait"
								maxDate={maxDate}
								minDate={minDate}
								value={finalDate} 
								onChange={handleChange}
							/>         
						</MuiPickersUtilsProvider>
					</ThemeProvider>
					<DialogActions>
						<Button style={{color: yearSelectedColor}} onClick={handleConfirm}>
							{f({id: 'component.Survey.Modules.09.Calendar.button.confirm'})}
						</Button>
						<Button style={{color: oc.gray[6]}} onClick={onClose}>
							{f({id: 'component.Survey.Modules.09.Calendar.button.close'})}  
						</Button>
					</DialogActions>
				</Popover>
			)
		}, [finalDate, maxDate, minDate, id, open, anchorEl, materialTheme, yearSelectedColor, f, handleChange, handleEl, handleConfirm, onClose, intl.locale])
     
    )
}

export default memo(injectIntl(Calendar))
