import styled from 'styled-components'

interface Props {
    src: string;
    id?: string;
    style: object;
}
const IframeBoxStyled = styled.div`
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const IframeStyled = styled.iframe`
    position:absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
`

function ReactIframeComponent(props: Props) {
    const { src, id='iframe', style={} } = props

    return (
        <IframeBoxStyled>
            <IframeStyled id={id} style={style} src={src}/>
        </IframeBoxStyled>
    )
}

export default ReactIframeComponent