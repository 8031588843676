/**
 * 응답페이지 데이터 (페이징)
 */
import { SurveyAnalysisRowsProps } from 'gql/survey_analysis_rows'

export const CHANGE = 'analysis/rows/data/CHANGE' // 값 변경시
export const INIT = 'analysis/rows/data/INIT'     // 초기 쿠키에서 값 가져올때

type Type = {
    data: SurveyAnalysisRowsProps;
}

// order: answer => 보기순, count => 응답순
// pointType: point => 가중치로 계산, count => 응답수로 계산 
const initState: Type = {
    data: {
        columns: [],
        answers: [],
        files: []
    }
}
 
interface ActionProps {
    type: string;
    data: Type['data'];
}
 
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                data: action.data
            }
        case INIT:
            return initState
        default:
            return state
    }
}