import { useContext, useMemo } from 'react'
import { getColorConcentration } from 'utils'
import Grid from '@material-ui/core/Grid'
import PropsContext from 'context/props'
import { withStyles } from '@material-ui/core/styles'

interface Props {
    children: JSX.Element | JSX.Element[];
}

const GridContainer = withStyles({
    root: (props: { background_color: string }) => {
        const { background_color } = props

        const bg = getColorConcentration(background_color) ? 'rgba(255, 255, 255, .1)' : 'rgba(0, 0, 0, .1)'

        return {
            borderTop: `1px solid ${bg}`,
            paddingLeft: 10,
            paddingRight: 5,
            minWidth: 150,
            height: 34
        }
    }
})(props => <Grid container alignItems="center" justifyContent="space-between" spacing={0} {...props}/>)

function FileNameGrindContainer(props: Props) {
    const { children } = props
    const { design } = useContext<{background_color: string}>(PropsContext)
    const { background_color } = design

    return (
        <>
        {
            useMemo(() => {
                return (
                    <GridContainer background_color={background_color}>
                        {children}
                    </GridContainer>
                )
            }, [background_color, children])
        }
        </>
    )

}

export default FileNameGrindContainer