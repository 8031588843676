import { useMemo, useContext, memo } from 'react'
import PropsContext from 'context/props'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

interface Props {
    height?: number | string;
    children: JSX.Element | JSX.Element[];
}

const MyBox = withStyles({
    root: ({answer_button}: {answer_button: string}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: `1px solid rgba(${answer_button})`,
        borderRadius: 3
    })
})(Box)


function FileUploadBoxComponent(props: Props) {
    const { children } = props
    const { design } = useContext<{answer_button: string}>(PropsContext)
    const { answer_button } = design

    return (
        <>
        {
            useMemo(() => (
                <MyBox answer_button={answer_button}>
                    {children}
                </MyBox>
            ), [answer_button, children])
        }
        </>
    )
}

export default memo(FileUploadBoxComponent)
