import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { ListItem, ListItemAvatar, ListItemSecondaryAction, Avatar, Switch } from '@material-ui/core'
import { Update as UpdateIcon } from '@material-ui/icons'
import { ListItemText, SecondaryTypography, useCommonStyles } from '../Component'
import { useIntl } from 'react-intl'
import { UPDATE_USER_ALARM_NEWSLETTER } from 'gql/user_alarm'
import { SHOW as BACKGROPSHOW, HIDE as BACKGROPHIDE } from 'reducer/backdrop'
import { postMessage } from 'utils'

const Component = (props) => {
    const { newsletter, refetch } = props

    const commonClasses = useCommonStyles()

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ updateUserAlarmNewsletter ] = useMutation(UPDATE_USER_ALARM_NEWSLETTER, {
        onCompleted: async() => {
            refetch()
        },
        onError: (e) => {
            dispatch({ type: BACKGROPHIDE })

            postMessage({ 
                dispatch: { type: ERRORPAGESHOW } 
            })
        }
    })

    const handleChange = useCallback(async(e) => {
        const { checked } = e.target
        
        await dispatch({ type: BACKGROPSHOW })
        updateUserAlarmNewsletter({ variables: { newsletter: checked }})
    }, [dispatch, updateUserAlarmNewsletter])

    return (
        <>
        <ListItem>
            <ListItemAvatar className={commonClasses.icon}>
                <Avatar><UpdateIcon/></Avatar>
            </ListItemAvatar>
            <ListItemText 
                primary={f({id: 'component.MyAccount.Alarm.Newsletter.primary'})} 
                secondary={<SecondaryTypography>{f({id: 'component.MyAccount.Alarm.Newsletter.secondary'})}</SecondaryTypography>}
                secondary_margin_right={15}
            />
            <ListItemSecondaryAction>
                <Switch
                    checked={newsletter}
                    onChange={handleChange}
                    color="primary"
                    name="newsletter"
                    inputProps={{ 'aria-label': 'newsletter' }}
                />
            </ListItemSecondaryAction>
        </ListItem>
        </>
    )
}

export default Component