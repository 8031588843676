import { useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { OutlinedInput, FormHelperText } from '@material-ui/core'
import PropsContext from 'context/props'

const useHelperStyles = makeStyles({
    helper: (props) => {
        const { answer, font_family } = props

        return {
            color: `rgba(${answer})`,
            marginLeft: 10,
            fontFamily: font_family
        }
    }
})


// 통상적으로 쓰이는 스타일
const InputStyled = withStyles({
    root: (props) => {
        const { answer_button, backgroundcolor='transparent', boxshadow=0 } = props
        return {
            width: '100%',
            background: backgroundcolor,
            borderRadius: 4,
            boxShadow: boxshadow ? `rgba(0, 0, 0, 0.24) 0px 2px 4px` : 'none',
            "& $notchedOutline": {
                border: `1px solid rgba(${answer_button})`
            },
            "&:hover $notchedOutline": {
                border: `1px solid rgba(${answer_button}) !important`
            },
            "&$focused $notchedOutline": {
                border: `1px solid rgba(${answer_button})`
            }
        } 
    },
    input: (props) => {
        const { answer, font_color, font_family } = props
        
        return {
            paddingLeft: 10,
            backgroundColor: 'transparent !important',
            border: 'none',
            color: font_color || `rgba(${answer})`,
            fontFamily: font_family,
            
        }
    },
    inputMultiline: (props) => {
        const { answer, font_color, font_family } = props
        
        return {
            paddingLeft: 0,
            border: 'none',
            color: font_color || `rgba(${answer})`,
            fontFamily: font_family,
        }
    },
    focused: () => ({}),
    disabled: ({ background_color }) => {
        return {
            background: 'rgba(236, 236, 236, .5)' ,
            color: 'rgba(50, 50, 50, .5)',
            boxShadow: 'none',
            "& $notchedOutline": {
                border: `none`
            },
            "&:hover $notchedOutline": {
                border: `none !important`
            },
            "&$focused $notchedOutline": {
                border: `none`
            }
        }
    },
    notchedOutline: () => ({})
})(props => {
    const { answer, helper, font_family, ...other} = props

    const classes = useHelperStyles({answer, font_family})
    
    return (
        <>
        <OutlinedInput font_family={font_family} {...other} margin='dense'/>
        {helper && <FormHelperText className={classes.helper}>{helper}</FormHelperText>}
        </>
    )
})

export default (props) => {
    const { design } = useContext(PropsContext)
    const { answer, answer_button, font_family, background_color } = design


    return <InputStyled {...props} font_family={font_family} answer_button={answer_button} answer={answer}  background_color={background_color}/>
}
