import { memo, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import StepLabel from '@material-ui/core/StepLabel'
import { ColumnBox } from '../06/Item'
import { MyAnswer } from '../07/Item'
import Step from 'styled/Custom/Step'
import StepIconText from 'styled/Custom/StepIconText'
import StepConnector from 'styled/Custom/StepConnector'
import Stepper from 'styled/Custom/Stepper'
import Column from 'styled/Custom/Column'
import VerticalScaleBox from 'styled/Custom/VerticalScaleBox'
import { getValue } from '../06/Item'

const orientation = 'vertical'

function ItemComponent(props) {
    const { columns, answers, reverse, zero_number, reply, onChange } = props

    const { formatMessage: f } = useIntl()

    const alen = answers.length

    return (
        <div>
        {
            columns.map((c, i) => {
                // 선택한값중에서 해당 값이 있는지 체크        
                const activeStep = getValue(c.survey_module_no, reply, answers)

                return (
                    <Fragment key={c.survey_module_no}>
                        <ColumnBox index={i} marginTop={35}>
                            <Column>{c.column ? c.column : f({id: 'component.Survey.Modules.08.column'})}</Column>
                        </ColumnBox>
                        <VerticalScaleBox marginTop={5} checked={typeof activeStep === 'number'}>
                            <Stepper  nonLinear activeStep={activeStep} connector={<StepConnector orientation={orientation} />}  orientation={orientation}>
                                {
                                    answers.map(d => {
                                        return (
                                            <Step key={d.survey_module_answer_no}>
                                                <StepLabel  StepIconComponent={(props) => <StepIconText zero_number={zero_number} reverse={reverse} len={alen} {...props}/>} onClick={() => onChange(c.survey_module_no, d.survey_module_answer_no)}>
                                                    <MyAnswer>{d.answer? d.answer : f({id: 'component.Survey.Modules.08.answer'})}</MyAnswer>
                                                </StepLabel>
                                            </Step>
                                        )
                                    })
                                }
                            </Stepper>
                        </VerticalScaleBox>
                    </Fragment>
                )
            })
        }
        </div>
    )
}

export default memo(ItemComponent, (prev, next) => {
    return  isEqual(prev.reply, next.reply) && 
            isEqual(prev.columns, next.columns) && 
            isEqual(prev.answers, next.answers) && 
            isEqual(prev.onChange, next.onChange)
})