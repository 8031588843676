import { useState } from 'react'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from 'styled/Survey/Live/Dialog'
import Component from './Component'
import useTokenInfo from 'hooks/useTokenInfo'

function MakerComponent() {
    const [ open, setOpen ] = useState(false)

    const token = useTokenInfo()

    if (token.maker !== true) return null
    
    return (
        <>
        <Button style={{marginRight: 10}} color="secondary" onClick={() => setOpen(true)}>템플릿만들기</Button>
        <Dialog open={open}>
            <Container style={{position: 'relative'}}>
                <IconButton style={{position: 'absolute', right: 0, top: 0}}>
                    <CloseIcon onClick={() => setOpen(false)}/>
                </IconButton>
            </Container>
            <DialogContent>
                <Component/>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default MakerComponent