import { Paper, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroll-component'

const MyPaper = styled(Paper)(props => {
    const { height } = props

    return {
        position: 'relative',
        padding: '.5em',
        background: 'white',
        height: `calc(${height} + 4em)`,
        overflow: 'auto'
    }
})

const MyLoading = styled(Typography)(() => {
    return {
       marginTop: 5
    }
})

export default (props) => {
    const { rows, sMore, height, loading, onMore, children } = props

    // 데이터를 새로 가져올때(more말구)는 스크롤때문에 more호출이 된다 호춣이 안되게 새롭게 마운트 건다
    if (loading) return <MyPaper height={height}/>

    return (
        <MyPaper id="scrollableDiv" height={height}>
         <InfiniteScroll
        dataLength={rows.length} //This is important field to render the next data
        next={onMore}
        hasMore={sMore}
        loader={<MyLoading variant="caption"></MyLoading>}
        scrollableTarget="scrollableDiv"
        >
        {children}
        </InfiniteScroll>
    </MyPaper>
    )
    
}