import { memo } from 'react'
import Input from '@material-ui/core/Input'

interface Props {
    autoFocus: boolean;
}

function FontFamilyFocusComponent(props: Props) {
    const { autoFocus } = props

    return <Input readOnly autoFocus={autoFocus} style={{width: 0, height: 0}}/>
}

export default memo(FontFamilyFocusComponent)