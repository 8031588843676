import { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { CREATE_SURVEY_COPY_ALL } from 'gql/survey_mbox'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { CHANGE as CHANGEORDER } from 'reducer/survey/workspace/order'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import useCheckCreateSurvey from 'hooks/useCheckCreateSurvey'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    survey_no: number
    survey_workspace_no?: number
    breakpoints: BreakPointsProps
}

const MyButton = withStyles(theme => ({
    root: {
        position: 'fixed',
        right: 20,
        bottom: 70,
        zIndex: 1,
        fontWeight: 900,

    }
}))(Button)

function CopyComponent(props: Props) {
    const { survey_no, survey_workspace_no, breakpoints } = props

    const { xs, sm } = breakpoints

    const history = useHistory()

    const dispatch = useDispatch()
    
    const { formatMessage: f } = useIntl()

    const [ onCheckCreateSurvey ] = useCheckCreateSurvey('cache-first')

    const [ create ] = useMutation(CREATE_SURVEY_COPY_ALL, {
        onCompleted: (data) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: CHANGEORDER, value: 0 })
            

            setTimeout(() => {
                const loc = `/survey/form/create/${data.createSurveyCopyAll}`
                history.push(loc)
            }, 100)
        }
    })

    function handleCreate() {
        const res = onCheckCreateSurvey()

        if (!res) return 

        dispatch({ type: BACKDROPSHOW })
        setTimeout(() => {
            create({
                variables: { survey_no, target_survey_workspace_no: survey_workspace_no, template: true }
            })
        }, 100)
    }

    if (!survey_workspace_no) return null
    
    const size = xs ? 'small' : sm ? 'medium' : 'large'

    return (
        <MyButton variant="contained" color="primary" size={size} onClick={handleCreate}>{f({id: 'container.Survey.Preview.CopySurvey.button'})}</MyButton>
    )
}

export default memo(withBreakPoints(CopyComponent))