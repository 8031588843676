import gql from 'graphql-tag'
import { Step, Mode } from './survey_analysis_summary'

export const GET_SURVEY_ANALYSIS_ROWS = gql`
    query SurveyAnalysisRows($survey_no: Int!, $mode: Mode!, $input: SurveyAnalysisRowsInput!, $key: String) {
        surveyAnalysisRows(survey_no: $survey_no, mode: $mode, input: $input, key: $key)
    }
`

export const GET_SURVEY_ANALYSIS_ROWS_SOCKET = gql`
    query SurveyAnalysisRowsSocket($survey_no: Int!, $mode: Mode!, $input: SurveyAnalysisRowsInput!) {
        surveyAnalysisRowsSocket(survey_no: $survey_no, mode: $mode, input: $input)
    }
`

export const GET_SURVEY_ANALYSIS_ROWS_PAGING = gql`
    query SurveyAnalysisRowsPaging($survey_no: Int!, $mode: Mode!, $input: SurveyAnalysisRowsPagingInput!, $key: String!) {
        surveyAnalysisRowsPaging(survey_no: $survey_no, mode: $mode, input: $input, key: $key) {
            userCount
            fileCount
            rows
        }
    }
`

export interface SurveyAnalysisRowsVariables {
    survey_no: number;
    mode: Mode;
    input: {
        conditionsPlay?: object;
        step: Step;
        date: string | null;
        search: string | null;
    }
    key?: string;
}

export interface SurveyAnalysisRowsPagingVariables {
    survey_no: SurveyAnalysisRowsVariables['survey_no'];
    mode: SurveyAnalysisRowsVariables['mode'];
    input: {
        conditionsPlay: SurveyAnalysisRowsVariables['input']['conditionsPlay'];
        step: SurveyAnalysisRowsVariables['input']['step'];
        date: SurveyAnalysisRowsVariables['input']['date'];
        search: SurveyAnalysisRowsVariables['input']['search'];
        order: {
            key: 'id' | 'einterval' | 'accesstime' | 'ip';
            value: boolean;
        };
        startpoint: number;
        offset: number;
    }
    key: string;
}

export interface SurveyAnalysisRowsProps {
    answers: any[];
    columns: any[];
    files: {
        survey_analysis_users_no: number;
        survey_question_no: number;
        number: string;
        src: string;
        filename: string;
        filesize: number;
    }[]
}

export type SurveyAnalysisRowsPropsSocket = string


export interface SurveyAnalysisPagingRowsProps {
    rows: SurveyAnalysisRowsProps;
    userCount: number;
    fileCount: number;
}