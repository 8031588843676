export const SHOW = 'survey/create/moduleTip/SHOW'
export const HIDE = 'survey/create/moduleTip/HIDE'

const initState = {
    index: null,
    styles: {}
}

export default (state = initState, action: { type: string, index: number | null, styles: object }) => {
    switch(action.type) {
        case SHOW:
            return {
                index: action.index,
                styles: action.styles
            }
        case HIDE:
            return initState
        default:
            return initState
    }
}