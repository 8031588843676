import { useContext, useCallback, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { VerifyIsJust } from '../01/Component'
import ItemComponent from './Item'
import CommentComponent from '../Comment'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import useJustDebounce from 'hooks/useJustDebounce'

export const MyBox = withStyles({
    root: (props) => {
        const { method } = props
        return {
            marginBottom: method === 'one' ? 10 : 0
        }
    }
})(Box)

function Component(props) {
    const { mode } = useContext(AccessMethodContext)
    const { mbox, option, format } = useContext(PropsContext)
    const { method } = mbox
    const { just } = option
    
    const { 
        survey_question_no, 
        lists, 
        reply,
        is_comment, 
        is_comment_placeholder,
        replyComment,
        reverse,
        zero_number
    } = props

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ debounce, onChangDebounce ] = useJustDebounce()

    const [ newReply, setNewReply ] = useState(reply)
    
    const handleChange = useCallback(async(survey_module_no) => {
        // 답체크
        // 미체크시 체크, 체크시 미체크
        // 체크된 상태에서 코멘트 클릭시 유지

        // 이미 체크된 값이 있는지 체크 (선택한값)
        const otherChecked = newReply.find(c => c.survey_module_no && c.survey_module_no !== survey_module_no)  ? true : false

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c => c.survey_module_no === survey_module_no) ? true : false

        // 바로 다음문항으로 이동하기 여부
        const verifyIsJust = new VerifyIsJust({ mode, method, just })
        const isJust = verifyIsJust._05(survey_module_no, is_comment)

        if (debounce) return

        onChangDebounce(isJust)

        const reply = format._07({ survey_question_no, survey_module_no, isJust })

        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        const newReplys = selfChecked ? [...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no) ] :
                        otherChecked ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no), reply ]                     
                                     : [ ...memoryReply.rows, reply ] 
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [ mode, method, just, is_comment, survey_question_no, newReply, format, debounce, onChangeReplys, onChangDebounce ])


    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <MyBox method={method}>
            <ItemComponent reply={newReply} lists={lists} zero_number={zero_number} reverse={reverse} onChange={handleChange}/>
            <CommentComponent 
                survey_question_no={survey_question_no} 
                is_comment={is_comment}
                is_comment_placeholder={is_comment_placeholder}
                comment={replyComment}
                disabled={newReply.length > 0 ? false : true}
                marginTop="15px"
                marginBottom="0px"
            />
        </MyBox>
    )
}

export default Component