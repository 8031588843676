import { createContext } from 'react'

// 편집리스트 화면, 디자인 화면에서 사용
// customDesign: 디자인 페이지 화면일때는 true
const editDesignContext = createContext({
    viewPicture: false, 
    editOpen: false, 
    customDesign: false
})

export default editDesignContext