import { JumpNo, JumpProps } from 'gql/jump'

// 전체 데이터 저장
export const INIT = 'survey/create/logic/jump/INIT'

// 개별문항 및 페이지의 row 추가 삭제 변경시 저장
export const CHANGE = 'survey/create/logic/jump/CHANGE'

const initState: { rows: JumpProps[] } = {
    rows: []
}

interface ActionProps {
    type: string;
    O_INIT: {
        rows: JumpProps[]; // 점프로직 전체 데이터 저장
    },
    O_CHANGE: {
        jump_no: JumpNo;
        row: JumpProps; // 문항 및 페이지별 rows 데이터값이다 변경 (추가, 삭제, Else 값 변경시 사용)
    }
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case INIT:
            const { O_INIT } = action

            return {
                rows: O_INIT.rows
            }
        case CHANGE:
            const { O_CHANGE } = action

            let newRows = state.rows.map(c => {
                if (O_CHANGE.jump_no !== c.jump_no) return c

                return O_CHANGE.row
            })
          
            return {
                rows: newRows
            }
            
        default:
            return state
    }
}