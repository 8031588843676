import { memo, MouseEvent, useState, useCallback } from 'react'
import moment, { Moment } from 'moment'
import { _09Option } from 'gql/survey_question_modules'
import { InputAdornment } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { isEqual } from 'lodash-es'
import Input from 'styled/Custom/Input'
import ClearIconComponent from 'styled/Custom/ClearIcon'
import withPrevState, { UsePrevStateProps } from 'hoc/PrevState'
import useValue from './useValue'
import { getIsDisabled } from './Base'
import ConfirmComponent from './Confirm'
import CalendarComponent from './Calendar'

export interface Props extends UsePrevStateProps {
    survey_question_no: number;
    classes: any;
    refComment: any;
    isConfirm: boolean;
    originAnswered: string;
    date_format_type: _09Option['date_format_type'];
    date_start: _09Option['date_start'];
    date_range: _09Option['date_range'];
    onChange: (a: string) => void;
}

export type OnChange = (a: Moment | Date | '') => void;
export type OnClose = () => void;
export type AnchorEl = HTMLButtonElement | null

function DateComponent(props: Props) {
    const { 
        survey_question_no, 
        classes, 
        refComment, 
        isConfirm, 
        originAnswered, 
        date_format_type,
        date_start,
        date_range,
        onChange
    } = props

    const [ value, onChangeValue ] = useValue(originAnswered, onChange)

    const [ anchorEl, setAnchorEl ] = useState<AnchorEl>(null)

    // 포멧 타입 값이 없으면 기본형태
    const dateFormatType = date_format_type || 'YYYY-MM-DD'

    // 편집 페이지에서 date_format type 변경되면 기존에 입력된 값을 바꿔준다. (사용자들이 햇갈릴수 있어서...)
    // const prevDateFormatType = usePrevState(dateFormatType)

    // 달력 모달창 열기
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    // 달력 모달창 닫기
    const handleClose: OnClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    // 날짜값 변경
    const handleChange: OnChange = useCallback((value) => {
        const date = value ? moment(value).format(dateFormatType) : ''
        refComment.current[survey_question_no].value = String(date)
        onChangeValue(String(date))
    }, [refComment, survey_question_no, dateFormatType, onChangeValue])

    // 날짜값 삭제
    const handleClear = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        refComment.current[survey_question_no].value = ''
        onChangeValue('')
    }, [refComment, survey_question_no, onChangeValue])
    
    return (
        <>
        <Input
            value={value}
            inputProps={{readOnly: true}}
            onClick={handleClick}
            inputRef={ref => refComment.current[survey_question_no] = ref}
            startAdornment={<InputAdornment position="start" className={classes.icon}><CalendarTodayIcon/></InputAdornment>}
            endAdornment={
                value ? <ClearIconComponent onClick={handleClear}/> : null
            }
        />
        {
            isConfirm && (
                <ConfirmComponent survey_question_no={survey_question_no} value={value || ''} isEqualAnswer={getIsDisabled(value, originAnswered)}/>
            )
        }
        <CalendarComponent 
            survey_question_no={survey_question_no} 
            date_start={date_start}
            date_range={date_range}
            value={value || ''} 
            anchorEl={anchorEl} 
            onClose={handleClose} 
            onChange={handleChange}
        />
        </>
    )
}


export default withPrevState(memo(DateComponent, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.isConfirm, next.isConfirm) && 
            isEqual(prev.originAnswered, next.originAnswered) && 
            isEqual(prev.classes, next.classes) && 
            isEqual(prev.refComment, next.refComment) &&
            isEqual(prev.date_format_type, next.date_format_type) &&
            isEqual(prev.date_start, next.date_start) &&
            isEqual(prev.date_range, next.date_range) &&
            isEqual(prev.onChange, next.onChange)
}))