/*
**    저장버튼에서 구독 
** questionItemsSplit에서 포인트변경, 추가 삭제시 dispatch
*/
import { JumpExampleCountProps } from 'gql/jump_example_count'

// 전체 데이터 저장
export const INIT = 'survey/create/logic/jumpExampleCount/INIT'

// 개별문항 및 페이지의 row 추가 삭제 변경시 저장
export const CHANGE = 'survey/create/logic/jumpExampleCount/CHANGE'

const initState: { rows: JumpExampleCountProps[] } = {
    rows: []
}

interface ActionProps {
    type: string;
    O_INIT: {
        rows: JumpExampleCountProps[]; // 점프로직 포인트 초기 데이터 저장
    },
    O_CHANGE: {
        survey_question_no: number;
        rows: JumpExampleCountProps[]; // 문항 및 페이지별 rows 데이터값이다 변경 (추가, 삭제, Else 값 변경시 사용)
    }
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case INIT:
            const { O_INIT } = action

            return {
                rows: O_INIT.rows
            }
        case CHANGE:
            const { O_CHANGE } = action

            return {
                rows: [
                    ...state.rows.filter(c => c.survey_question_no !== O_CHANGE.survey_question_no),
                    ...O_CHANGE.rows
                ]
            }
            
        default:
            return state
    }
}