import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Popover, Typography } from '@material-ui/core'
import { HIDE } from 'reducer/popover'

export default () => {
    const state = useSelector(state => state.popover)
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const onClose = () => {
        dispatch({type: HIDE})
        
        typeof state.onClose === 'function' && state.onClose()
    }

    return (
        <>
          <Popover
            id='popover'
            anchorEl={state.anchorEl}
            onClose={onClose}
            anchorOrigin={state.anchorOrigin}
            transformOrigin={state.transformOrigin}
            open={state.show}
        >
            {state.title && <DialogTitle id="scroll-dialog-title" style={{fontSize: '22px'}}>{state.title}</DialogTitle>}
             
            {state.message && (
                <DialogContent>
                    <DialogContentText component="div">
                        <Typography color={state.messageColor}>{state.message}</Typography>
                    </DialogContentText>
                </DialogContent>)
            }

            {state.component}
            
            { (state.onActions.length > 0 || state.isClose) && (
                <DialogActions>
                {
                    state.onActions.map((c, i) => <Button key={i} onClick={() => {
                        // hide true이면 popovers 닫는다
                        if (c.hide === true) dispatch({type: HIDE})
                        
                        c.on()
                    }} color={c.color || 'primary'}><Typography variant="button">{c.name}</Typography></Button>)
                }
                {state.isClose && <Button onClick={onClose}><Typography variant="button">{state.closeName || f({id: 'ccomponent.Confirm.closeName'})}</Typography></Button>}
                </DialogActions>
                )
            }
        </Popover>
        </>
    );
}