import { memo } from 'react'
import { SurveyModulesQuestionComponent} from 'component'

// 문항 포멧
function Question(props) {
    const { question, mark_required, required } = props
    
    return <SurveyModulesQuestionComponent question={question} markRequired={mark_required && required}/>
}

export default memo(Question)