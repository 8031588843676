import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { 
    ThumbUpOutlined as ThumbUpOutlinedIcon,
    ThumbUp as ThumbUpIcon,
    FavoriteBorder as FavoriteBorderIcon,
    Favorite as FavoriteIcon,
    StarBorderOutlined as StarBorderOutlinedIcon,
    Star as StarIcon,
    RadioButtonUncheckedOutlined as RadioButtonUncheckedOutlinedIcon,
    RadioButtonChecked as RadioButtonCheckedIcon,
    CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
    CheckBox as CheckBoxIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    InsertEmoticon as InsertEmoticonIcon,
    EmojiEmotions as EmojiEmotionsIcon,
    CloudQueue as CloudQueueIcon,
    Cloud as CloudIcon,
    CloudDone as CloudDoneIcon,
    EmojiObjectsOutlined as EmojiObjectsOutlinedIcon,
    EmojiObjects as EmojiObjectsIcon,
    NotificationsOutlined as NotificationsOutlinedIcon,
    NotificationsActive as NotificationsActiveIcon
} from '@material-ui/icons'
import { Rating } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { SvgIcon } from '@material-ui/core'
import PropsContext from 'context/props'

const component = {
    thumbup: ThumbUpOutlinedIcon,
    favorite: FavoriteBorderIcon,
    star: StarBorderOutlinedIcon,
    radio: RadioButtonUncheckedOutlinedIcon,
    checkbox: CheckBoxOutlineBlankOutlinedIcon,
    emoticon: InsertEmoticonIcon,
    cloud: CloudQueueIcon,
    cloudDone: CloudQueueIcon,
    emoji: EmojiObjectsOutlinedIcon,
    notifi: NotificationsOutlinedIcon,
    assign: CheckBoxOutlineBlankOutlinedIcon
}

const componentSelected = {
    thumbup: ThumbUpIcon,
    favorite: FavoriteIcon,
    star: StarIcon,
    radio: RadioButtonCheckedIcon,
    checkbox: CheckBoxIcon,
    emoticon: EmojiEmotionsIcon,
    cloud: CloudIcon,
    cloudDone: CloudDoneIcon,
    emoji: EmojiObjectsIcon,
    notifi: NotificationsActiveIcon,
    assign: AssignmentTurnedInIcon
}

function getSize(len, isMobile) {
    const size = isMobile ? 47 - (len * 1.5) : 47
    return {
        width: size,
        height: size,
    }
}

const MySvgIcon = withStyles(theme => ({
    root: (props) => {
        const { max } = props
        return {
            ...getSize(max),
            [theme.breakpoints.down('xs')]: {
                ...getSize(max, true)
            }
        }
    }
}))(SvgIcon)

// 아이콘 액션
const Action = withStyles(theme => ({
    icon: (props) => {
        const { answer_button, max } = props
        return {
            color: `rgba(${answer_button})`,
            ...getSize(max),
            [theme.breakpoints.down('xs')]: {
                ...getSize(max, true)
            }
        }
    },
    sizeLarge: (props) => {
        const { max } = props
        return {
            ...getSize(max),
            [theme.breakpoints.down('xs')]: {
                ...getSize(max, true)
            }
        }
    },
    iconFilled: (props) => {
        const { answer_button } = props
        return {
            color: `rgba(${answer_button})`,
        }
    },
    iconHover: (props) => {
        const { answer_button } = props
        return {
            color: `rgba(${answer_button})`
        }
    }
}))(props => {
    const { iconType, onClick, onChangeActive, value, max, className="", ...other } = props
    const Icon = component[iconType]
    const IconSelected = componentSelected[iconType]

    return <Rating
        value={value}
        max={max}
        emptyIcon={<MySvgIcon max={max}><Icon/></MySvgIcon>}
        icon={<MySvgIcon  max={max} className={className}><IconSelected/></MySvgIcon>} 
        onClick={(e) => {
            const id = e.target.getAttribute('for')
            if (id) {
                const currentValue = Number(document.getElementById(id).value)
                if (typeof onClick === 'function') {
                    onClick(currentValue)
                }

                if (isMobile) {
                    if (value !== currentValue) e.preventDefault()
                } else {
                    e.preventDefault()
                }
            }
        }}
        onChangeActive={(e, newValue) => onChangeActive(newValue)}
        {...other}
        />
})

export const RatingAction = (props) => {
    const { design } = useContext(PropsContext)
    const { answer_button, answer_sbutton } = design

    return <Action {...props} answer_button={answer_button} answer_sbutton={answer_sbutton}/>
}

// 아이콘 종류 
export default (props) => {
    const { icon_type, selected=true } = props
    
    const Icon = selected ? componentSelected[icon_type] : component[icon_type]

    return <Icon/>
}