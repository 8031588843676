// 커버

// 로고 변경
export const CHANGE_LOGO = 'survey/modules/cover/CHANGE_LOGO'
// 애니메이션 변경
export const CHANGE_ANIMATION = 'survey/modules/cover/CHANGE_ANIMATION'
// 모두 변경
export const CHANGE = 'survey/modules/cover/CHANGE'

const initState: { src: string; animation: string } = {
    src: '',
    animation: ''
}

export default (state = initState, action: { type: string, src?: string, animation?: string }) => {
    switch(action.type) {
        case CHANGE_LOGO:
            return { ...state, src: action.src }
        case CHANGE_ANIMATION:
            return { ...state, animation: action.animation }
        case CHANGE:
            return { src: action.src, animation: action.animation }
        default:
            return state
    }
}