import gql from 'graphql-tag'

export const CREATE_SURVEY_QUESTION_COPY = gql`
    mutation CreateSurveyQuestionCopy($input: CreateSurveyQuestionCopy!) {
        createSurveyQuestionCopy(input: $input)
    }
`

export const CREATE_SURVEY_QUESTION_APPEND = gql`
    mutation CreateSurveyQuestionAppend($input: CreateSurveyQuestionAppend!) {
        createSurveyQuestionAppend(input: $input)
    }
`

export const UPDATE_SURVEY_QUESTIONS_SORT = gql`
    mutation UpdateSurveyQuestionsSort($survey_no: Int!, $survey_question_nos: [Int!]!) {
        updateSurveyQuestionsSort(survey_no: $survey_no, survey_question_nos: $survey_question_nos)
    }
`

export const DELETE_SURVEY_QUESTION = gql`
    mutation DeleteSurveyQuestion($survey_no: Int!, $survey_question_no: Int!, $module_type: ModuleType!) {
        deleteSurveyQuestion(survey_no: $survey_no, survey_question_no: $survey_question_no,  module_type: $module_type,)
    }
`

export const CREATE_SURVEY_QUESTION = gql`
    mutation CreateSurveyQuestion($survey_no: Int!, $module_type: ModuleType!, $survey_page_no: Int!) {
        createSurveyQuestion(survey_no: $survey_no, module_type: $module_type, survey_page_no: $survey_page_no)
    }
`
// 다음 페이지 구하기
export const GET_SURVEY_QUESTION_JUMP_NEXT = gql`
    query SurveyQuestionJumpNext($survey_no: Int!, $token: ID!, $survey_question_no: Int!, $method: String, $id: Int, $mode: Mode!) {
        surveyQuestionJumpNext(survey_no: $survey_no, token: $token, survey_question_no: $survey_question_no, method: $method, id: $id, mode: $mode)
    }
`

export const GET_SURVEY_QUESTION_ALL = gql`
    query SurveyQuestionAll($survey_no: Int!) {
        surveyQuestionAll(survey_no: $survey_no) {
            survey_question_no
            question
            pure_question
            survey_no
            indexs
            number
            survey_page_no
            module_type
            question_type
            visible
        }
    }
`

export const GET_SURVEY_QUESTION_SLACK = gql`
    query SurveyQuestionSlack($survey_no: Int!) {
        surveyQuestionSlack(survey_no: $survey_no) {
            questions {
                survey_question_no
                pure_question
            }
            jumpPointCount
            endingCount
        }
    }
`


// 공개 비공개 설정
export const UPDATE_SURVEY_QUESTION_SUMMARY_QUESTION_OPEN = gql`
    mutation UpdateSurveyQuestionSummaryQuestionOpen($survey_no: Int!, $survey_question_no: Int!, $summary_question_open: Boolean!) {
        updateSurveyQuestionSummaryQuestionOpen(survey_no: $survey_no, survey_question_no: $survey_question_no, summary_question_open: $summary_question_open)
    }
`

export const UPDATE_SURVEY_QUESTION_SUMMARY_COMMENT_OPEN = gql`
    mutation UpdateSurveyQuestionSummaryCommentOpen($survey_no: Int!, $survey_question_no: Int!, $summary_comment_open: Boolean!) {
        updateSurveyQuestionSummaryCommentOpen(survey_no: $survey_no, survey_question_no: $survey_question_no, summary_comment_open: $summary_comment_open)
    }
`

export const UPDATE_SURVEY_QUESTION_VISIBLE = gql`
    mutation UpdateSurveyQuestionVisible($survey_no: Int!, $survey_question_no: Int!, $visible: Boolean!) {
        updateSurveyQuestionVisible(survey_no: $survey_no, survey_question_no: $survey_question_no, visible: $visible)
    }
`
