import { useIntl } from 'react-intl'
import HeadTitleComponent from 'styled/HeadTitle'

const Component = () =>  {
    const { formatMessage: f } = useIntl()

    return (
        <HeadTitleComponent>
            {f({id: 'component.MyAccount.Head.subject'})}
        </HeadTitleComponent>
    )
}

export default Component
