import gql from 'graphql-tag'
import { ModuleType } from './survey_question_modules'
import { JumpNo } from './jump'

export const CREATE_JUMP_EXAMPLE_COUNT = gql`
    mutation CreateJumpExampleCount($survey_no: Int!, $jumpExampleCount: JSONObject!, $jumpExampleCountText: JSONObject!) {
        createJumpExampleCount(survey_no: $survey_no, jumpExampleCount: $jumpExampleCount, jumpExampleCountText: $jumpExampleCountText)
    }
`

export const GET_JUMP_EXAMPLE_COUNT_WITH_RELATION = gql`
    query JumpExampleCountWithRelation($survey_no: Int!, $mode: Mode) {
        jumpExampleCountWithRelation(survey_no: $survey_no, mode: $mode) {
            jumpExampleCountText {
                jump_example_count_text_no 
                survey_question_no     
                value
            }
            jumpExampleCount {
                jump_example_count_no
                jump_example_count_text_no          
                survey_question_no     
                survey_module_no       
                survey_module_answer_no
                module_type               
                survey_ending_nos       
            }
         
        }
    }
`

export const DELETE_JUMP_EXAMPLE_COUNT_WITH_TEXT = gql`
    mutation DeleteJumpExampleCountWithText($survey_no: Int!) {
        deleteJumpExampleCountWithText(survey_no: $survey_no)
    }
`

// 기본 디폴트
export interface JumpExampleCountTextProps {
    jump_example_count_text_no: JumpNo; 
    survey_question_no: number;     
    value: string;
}

// 기본 디폴트
export interface JumpExampleCountProps {
    jump_example_count_no: JumpNo;
    jump_example_count_text_no: JumpNo | null;           
    survey_question_no: number;
    survey_module_no: number | null;       
    survey_module_answer_no: number | null;
    module_type: ModuleType;                         
    survey_ending_nos: number[];
}