import Typography from '@material-ui/core/Typography'
import { PayMethod } from 'ts-policy'
import { useIntl } from 'react-intl'
import { UserPaymentsProps } from 'types/user_payment'
import VbankDepositComponent from 'component/Payment/Receipt/VbankDepositBox'
import NbankDepositComponent from 'component/Payment/Receipt/NbankDepositBox'

type Props = Pick<UserPaymentsProps, 'pay_method' | 'appCardName' | 'cardNum' | 'vbankDate' | 'order_name' | 'amt'>

function DepositComponent(props: Props) {
    const { pay_method, appCardName, cardNum, vbankDate, order_name, amt } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        <Typography variant="h6" style={{marginTop: 50}} gutterBottom>{f({id: 'component.Payment.Receipt.Component.deposit.title'})}</Typography>
        {
            /* 가상계좌, 무통장입금만 보여준다 */
            pay_method === PayMethod.Vbank ? (
                <VbankDepositComponent
                    appCardName={appCardName || ''} 
                    cardNum={cardNum || ''}
                    vbankDate={vbankDate || ''}
                    order_name={order_name || ''} 
                    price={amt}
                />
            ) : pay_method === PayMethod.Nbank && (
                <NbankDepositComponent
                    nbankDate={vbankDate || ''}
                    order_name={order_name || ''} 
                    price={amt}
                />
            )
        }
        </>
    )
}

export default DepositComponent