import { memo } from 'react'
import Radio from 'styled/Custom/Radio'
import { isEqual } from 'lodash-es'

function ColumnAnswerChildItemComponent(props) {
    const { selected } = props

    return (
        <>
        <Radio checked={selected}/>
        <div className={`selected ${selected && 'checked'}`}></div>
        </>
    )
}

export default memo(ColumnAnswerChildItemComponent, (prev, next) => {
    return  isEqual(prev.selected, next.selected)
})