import { memo, useContext, useEffect } from 'react'
import PropsContext from 'context/props'
import { Format00 } from 'hoc/Survey/SetReplys'
import useChangeReplys from '../useChangeReplys'
import { memoryReply } from 'container/Survey/Live/Container'

interface Props {
    reply: Format00[];
    survey_question_no: number;
}

function Module00Component(props: Props) {
    const { reply, survey_question_no } = props
    const { format } = useContext<any>(PropsContext)

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)


    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        if (reply.length === 0) {
            const newReply = format._00({ survey_question_no })
            memoryReply.rows = [ ...memoryReply.rows, newReply ]
     
            onChangeReplys(memoryReply.rows)
        }
  
    }, [survey_question_no, reply, format, onChangeReplys])

    return null
}

export default memo(Module00Component)