import countryList from 'country-list'
import hash from 'object-hash'
import moment from 'moment-timezone'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

// 형식 체크
export const validation = {
    email: (email) => {
        const exp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,20}$/i
        return exp.test(email)
    },
    password: (password) => {
        // 한글을 제외한 글자가 8~15로 되어있다면 true
        const is = /[^ㄱ-ㅎ가-힣]{8,15}$/.test(password)
        
        if (!is) return false

        // 영문이 한글자 이상 있는지 판단
        return /[a-zA-Z]+/.test(password)
    },
    tel: (tel, types) => {
        const mobile = /^01([016789])([1-9]{1})([0-9]{2,3})([0-9]{4})$/;

        const area = /^(02|031|032|033|041|042|043|044|051|052|053|054|055|061|062|063|064)([1-9]{1})([0-9]{2,3})([0-9]{4})$/;
    
        const key = /^1([1-9]{3})([0-9]{4})$/;
    
        const internet = /^070([0-9]{7,8})$/;
    
        let flag_mobile = true, flag_area = true, flag_key = true, flag_internet = true;
    
    
        /*
        ** 휴대폰 형식
        */
        if ( !mobile.test(tel) )
        {
            flag_mobile = false;
        }
    
        /*
        ** 일반전화 형식
        */
        if ( !area.test(tel) )
        {
            flag_area = false;
        }
    
        /*
        ** 대표전화번호 형식
        */
        if ( !key.test(tel) )
        {
            flag_key = false;
        }
    
        /*
        ** 인터넷 번호 형식
        */
        if ( !internet.test(tel) )
        {
            flag_internet = false;
        }
    
    
    
        /*
        ** type 별 검사영역
        */
        if (typeof(types) === 'undefined' || types.length < 1)
        {
            /*
            ** 지정한 타입이 없다면 모든 타입중 하나만 맞으면 return true
            */
            if (flag_mobile === false && flag_area === false && flag_key === false && flag_internet === false)
            {
                return false;
            }
            else
            {
                return true;
            }
        }
        else
        {
    
            const type = types.join(',');
    
            /*
            ** 지정한 타입중 하나만 맞으면 retun true
            */
            if (type.indexOf('mobile') !== '-1')
            {
                if (flag_mobile === true )
                {
                    return true
                }
            }
    
            if (type.indexOf('area') !== '-1')
            {
                if (flag_area === true )
                {
                    return true
                }
            }
    
            if (type.indexOf('key') !== '-1')
            {
                if (flag_key === true )
                {
                    return true
                }
            }
    
    
            if (type.indexOf('internet') !== '-1')
            {
                if (flag_internet === true )
                {
                    return true
                }
            }
    
            return false
        }
    },
    url: (val) => {
        return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(val)
    }
}

// number format
export const numberFormat = (x) => {
    if (!x) return 0
    
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
} 

export const decimalFormat = (x) => {
    if (!x) return 0
    
    // 소숫점이 있을경우 소수점은 number format제외
	const [i, d] = String(x).split('.')
    
    const val = i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    
    return d !== undefined ? `${val}.${d}` : val
} 

// 확장자 추룰
export const getExt = (name) => {    
    const len = name.length
    
	//substring 메서드는 start에서 end까지(end는 포함 안 함) 부분 문자열을 포함하는 문자열을 반환합니다. 
    const lastDot = name.lastIndexOf('.')
    const ext = name.substring(lastDot + 1, len)
    
	return ext.toLowerCase()
}

// getPercent 
export const getPercent = (count, total) => {
    const _count = Number(count)
    const _total = Number(total)

    return total > 0 ? _count / _total * 100 : 0
}

// 숫자이외에 문자 삭제
export const replaceNotNumber = (str, minus, decimal) => {
    // 숫자만 허용
    function number() {
        return str.replace(/[^0-9]/g, '')
    }

    // 마이너스 소주점 둘다 허용
    function otherNumber() {
        // 숫자와 . 제외하고 삭제
        const verifyValue = str.replace(/[^0-9.-]/g, '')

        let newValue = ''         // 새롭게 문자를 담을 배열
        let decimalFlag = false   // .이 있다면..true로 변경

        for (let i=0; i<verifyValue.length; i++) {
            const d = verifyValue.substring(i, i+1)

            // 마이너스 체크
            if (d === '-') {
                if (!minus) continue

                if (i === 0) {
                    newValue += d
                }

                continue
            }
            
            // 소수점 체크
            if (d === '.') {
                if (!decimal) continue
                
                if (decimalFlag === false) {
                    newValue += d
                    decimalFlag = true
                }
                continue
            }
           
            newValue += d
        }
        return newValue
    }

    // 숫자만 허용일때
    if (!decimal && !minus) {
        return number()
    } 

    return otherNumber()
}

// 숫자 와 -
export const replaceNotPhone = (str) => {
    return str.replace(/[^0-9*-]/g, '')
}

// 한글 삭제
export function replaceHangul(str) {
    return str.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '')
}
 
// 무색인지 유색인지 판단 유색이면 true 무색이면 false
export const getColorConcentration = (rgba)  => {
    const [ r, g, b, a ] = rgba.split(',')

    if (Number(a) < 0.5 ) return false
    if (Number(r) + Number(g) + Number(b) > 460) return false

    return true
}

// color hex to rgb
export const getColorHexToRGB = (hex) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))

    return [r, g, b]
}

// 쿠키
export const cookies = {
    set: (props) => {
        const { value, expires, maxAge, domain=`.${process.env.REACT_APP_DOMAIN}` } = props
        let cookie = `${value};path=/;domain=${domain};`
        if (!isNaN(maxAge)) cookie = `${cookie}max-age=${maxAge};`
        if (expires) {
            const date = new Date()
            date.setDate(date.getDate()+expires)

            cookie = `${cookie}expires=${date.toUTCString()};`
        }
    
        document.cookie = cookie
    },
    get: (name) => {
        let x, y
        const val = document.cookie.split(';');

        for (let i = 0; i < val.length; i++) {
            x = val[i].substr(0, val[i].indexOf('='))
            y = val[i].substr(val[i].indexOf('=') + 1)
            x = x.replace(/^\s+|\s+$/g, '')
            if (x === name) {
                return unescape(y)
            }
        }   
    },
    remove: (props) => {
        const { name, domain } = props
        const expireDate = new Date();

        //어제 날짜를 쿠키 소멸 날짜로 설정한다.
        expireDate.setDate( expireDate.getDate() - 1 )

        document.cookie = `${name}=; expires=${expireDate.toGMTString()}; path=/; domain=${domain}`
    }
}


export const getCountrys = () => {
    // 국가 코드
    const nationalLists = ["KR", "JP", "SG", "MY", "HK", "TW", "PH", "CN", "VN", "US", "AU", "CA", "CH", "CZ", "DE", "FR", "GB", "IT", "NL", "NZ", "SE"]

    return nationalLists.map(c => {
        return countryList.getData().find(d => d.code === c)
    })
}

export const getTokenname = (survey_no, type='ip') => hash({foo: `listodsf-dsfdsf${survey_no}-dsfdsrrdfgv-${type}`}) 

export const getStartsTokenname = (survey_no) => {
    return getTokenname(survey_no, 'starts')
}

export const equalArray  = (array1, array2) => {
    return (array1.sort().join(',') === array2.sort().join(',')) 
}

//레이아웃 정보
export const getPptLayout = () => {
    return {
        layout: 'LAYOUT_WIDE',
        defineSlideMaster: {
            title: '',
            background: { color: "FFFFFF" },
            objects: [
                { rect: { x: 0, y: '95%', w: "100%", h: '5%', fill: { color: "f1f1f1" } } },
                //{ image: { x: '95%', y: "95.75%", w: 0.27, h: 0.27, path: require('static/images/_common/logo.png') } },
            ]
        }
    }
}

// 그래프 큐에일 에러메시지 파싱
export const parseGraphqlError = (e) => {
    return e.message.replace('GraphQL error: ', '')
}

// iframe내에서 부모창으로 호출
export const postMessage = (val, target='parent') => {
    if (window[target]) window[target].postMessage(val, '*')
}

export const childPostMessage = (val, target='ifrmae') => {
    if (document.getElementById(target)) {
        document.getElementById(target).contentWindow.postMessage(val, '*')
    }    
}

// 한국 시각과의 차이가 0~60인거면 true
export function isCanService() {
    return true
    /*
    const timearea = moment.tz.guess()
    
    const other = moment.tz("2014-10-01 12:00:00", timearea).utc()
    const korea = moment.tz("2014-10-01 12:00:00", "Asia/Seoul").utc()

    const diff = Math.abs(moment.duration(korea.diff(other)).asMinutes())
    
    return (diff >= 0 && diff <= 60)
    */
}


// lang cookie 값이 없을경우 (사용자가 임의로 지우지 않을경우 이런경운 음따.)
export const getTimezoneLang = () => {
    const timezone = moment().format('Z')
    const timearea = moment.tz.guess()

    
    let lang = ''
    
    if (isCanService()) {
        if (!timearea) lang = 'ko'
        else if (timearea === 'Asia/Seoul' || timearea === 'Etc/GMT-9') lang = 'ko'
        else if (timearea === 'Asia/Tokyo') lang = 'jp'
        else lang = 'en' 
    } else lang = 'ko'

    return {
        timezone,
        timearea,
        lang
    }
}


// 파일 다운로드 (파일명, 데이터)
export const downloadFile = ({ filename, data }) => {
    const zip = new JSZip()

    zip.file(filename, data)

    zip.file(filename).async('blob').then(function (contents) {
        FileSaver.saveAs(contents, filename)
    })            
}

// 이미지 색상 알아보기
export function getImageAverageRGB(imgEl) {
    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = {
            r: 255,
            g: 255,
            b: 255
        }, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {
            r: 0,
            g: 0,
            b: 0
        },
        count = 0;
  
    if (!context) {
      return defaultRGB;
    }
    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
    context.drawImage(imgEl, 0, 0);
  
    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        /* security error, img on diff domain */
        return defaultRGB;
    }
    length = data.data.length;
    while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }
    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);
  
    return rgb;
}