import { memo, useMemo, useContext, useRef } from 'react'
import TableStyled from 'styled/Survey/MatrixTable'
import { isEqual } from 'lodash-es'
import { Overflow } from '../03/Overflow'
import { getReplysMatrixKeyOfValue } from '../03/Component'
import { Rowscontex, getIsScroll } from '../03/Base'
import useScrollPos from '../03/useScrollPos'
import useScrollScreen from '../03/useScrollScreen'
import ScrollTipComponent from '../03/ScrollTip'
import { Context } from './Component'
import AnswerItemsComponent from './AnswerItems'
import ColumnItemComponent from './ColumnItem'

function BaseComponent(props) {
    const { columns, answers, background_concent, answer_concent } = props

    const { reply } = useContext(Context)

    const replys = getReplysMatrixKeyOfValue(reply)

    const divRef = useRef()

    // 스크롤 이동 위치 가져온다
    const scrollPos = useScrollPos(divRef)

    // 스크롤이 있는지 판단, 스크롤이 있으면서 끝이 아닌거 판단
    const [ isScroll, possibleSlide ] = useScrollScreen(divRef, scrollPos)

    const className = getIsScroll(possibleSlide)

    return (

        <Rowscontex.Provider value={{ scrollPos, answerConcent: answer_concent }}>
        <Overflow paddingBottom="10px" backgroundConcent={background_concent} ref={ref => divRef.current = ref} className={className}>
            <TableStyled rows={(answers.length + 1)}>
                <AnswerItemsComponent answers={answers}/>
                {
                    useMemo(() => {
                        return (
                            columns.map(c => {
                                return (
                                    <div key={c.survey_module_no}>
                                        <ColumnItemComponent
                                            survey_module_no={c.survey_module_no}
                                            column={c.column}
                                            answers={answers}
                                            replys={replys}
                                        />
                                    </div>
                                )                    
                            })
                        )
                    }, [answers, columns, replys])
                    
                }
            </TableStyled>
            <ScrollTipComponent isScroll={isScroll}/>
        </Overflow>
        </Rowscontex.Provider>
    )
}

export default memo(BaseComponent, (prev, next) => {
    return  isEqual(prev.columns, next.columns) &&
            isEqual(prev.answers, next.answers) &&
            isEqual(prev.background_concent, next.background_concent) && 
            isEqual(prev.answer_concent, next.answer_concent)
})