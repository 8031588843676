import { useIntl } from 'react-intl'
import nbank from 'config/nbank.json'
import oc from 'open-color'
import moment from 'moment'
import { numberFormat } from 'utils'
import { getIntlLocale, parseHtmlIntl } from 'ts-utils'
import { UserPaymentsProps } from 'types/user_payment'
import { GetPayResult, getPayResultType, GetPayMethodName, PayMethod, PayResult } from 'ts-policy'
import DialogContent from '@material-ui/core/DialogContent'
import Grid, { GridProps } from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'
import DepositComponent from './Deposit'
import TaxComponent from './Tax'

interface Props extends UserPaymentsProps {
    originName: string;
    onClose: () => void;
}

const GridContainer = withStyles({ 
    root: {
        border: `1px solid ${oc.gray[1]}`,
        borderRadius: 5
    }
})((props: GridProps) => <Grid container {...props}/>)


const GridItem = withStyles({ 
    root: {
        borderBottom: `1px solid ${oc.gray[1]}`,
        height: 50
    }
})((props: GridProps) => <Grid item {...props}/>)


const Content = withStyles({
    root: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        '& > div': {
            padding: 10
        },
        '& > div:first-child': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: oc.gray[2],
            width: 100,
            height: '100%'
        },
        '& > div:last-child': {
            flexGrow: 1,
            paddingLeft: 15
        }
    }
})(Box)

function parseDate(date:string | null) {
    if(!date) return ''

    return moment(Number(date)).format('YYYY-MM-DD HH:mm:ss')
}

function GetCardColumn(pay_method: PayMethod, card_name: string | null, nbank_name: string) {
    const { formatMessage: f } = useIntl()

    const cardNames = {
        card: f({id: 'component.MyAccount.Pay.Detail.GetCardColumn.card'}),
        acard: f({id: 'component.MyAccount.Pay.Detail.GetCardColumn.card'}),
        epay: f({id: 'component.MyAccount.Pay.Detail.GetCardColumn.card'}),
        trans: f({id: 'component.MyAccount.Pay.Detail.GetCardColumn.bank'}),
        vbank: f({id: 'component.MyAccount.Pay.Detail.GetCardColumn.bank'}),
        nbank: f({id: 'component.MyAccount.Pay.Detail.GetCardColumn.bank'})
    }

    const cardValue = pay_method === PayMethod.Nbank ? nbank_name : card_name

    return {
        cardName: cardNames[pay_method],
        cardValue
    }
}


// 이노페이 매입전표 (카드,앱카드 외 나머지)
/*
function getInnopayUrl(pay_method: PayMethod, tid: string | null, pay_result: PayResult) {
    // 무통장은 매입전표가 없다
    if (pay_method === PayMethod.Nbank) return null

    // 미승인처리한것도 매입전표가 없다.
    if (pay_result === PayResult.Non_Apply) return null

    let url = 'https://pg.innopay.co.kr/ipay/issue/TransIssue.jsp'


    if (pay_method === PayMethod.Card || pay_method === PayMethod.Acard) {
        url = 'https://pg.innopay.co.kr/pay/issue/CardIssue.jsp'
    }

    return `${url}?TID=${tid}` 


}
*/

function DetailComponent(props: Omit<Props, 'open' | 'user_payset_no' | 'levels' | 'month' | 'discount' | 'add_amt'>) {

    const { originName, kind, user_payment_no, wdate, amt, appCardName, cardNum, vbankDate, order_name, pay_method, adate, cdate, tax_in_innopay, receiptUrl, user_tax, user_payset, onClose } = props

    const { content } = user_payset || { content: '' }

    const { formatMessage: f, locale } = useIntl()

    const _nbank = nbank[getIntlLocale(locale)]


    const { cardName, cardValue } = GetCardColumn(pay_method, appCardName, _nbank.bank)


    const payResult:{[s: string]: string} = GetPayResult()

    const payResultType = getPayResultType(adate, cdate)

    // 결제완료 전인 가상계좌 무통장입금은 입금정보 보여준다.
    const isDeposit = payResultType === PayResult.Non_Apply && (pay_method === PayMethod.Vbank || pay_method === PayMethod.Nbank)

    // 매입전표 입금전에는 매입전표가 없다.  현재 미사용
    // const innopayUrl = getInnopayUrl(pay_method, tid, payResultType)

    return (
        <>
        <DialogContent>
            <Typography variant="h6" gutterBottom>{f({id: 'component.MyAccount.Pay.Detail.title'})}</Typography>
            <GridContainer>
                <GridItem xs={12} sm={6}>
                    <Content>
                        <div>{f({id: 'component.MyAccount.Pay.Detail.column.level'})}</div>
                        <div>{originName}</div>
                    </Content>
                </GridItem>
                {
                    kind === 4 && (
                        <GridItem xs={12}  sm={6}>
                            <Content>
                                <div>{f({id: 'component.MyAccount.Pay.Detail.column.content'})}</div>
                                <div>{parseHtmlIntl(content.replace(/\n/g, '<br />'))}</div>
                            </Content>
                        </GridItem>
                    )
                }
                <GridItem xs={12}  sm={6}>
                    <Content>
                        <div>{f({id: 'component.MyAccount.Pay.Detail.column.price'})}</div>
                        <div>{f({id: 'component.MyAccount.Pay.Item.amt'}, {amt: numberFormat(amt)})}</div>
                    </Content>
                </GridItem>
                <GridItem xs={12}  sm={6}>
                    <Content>
                        <div>{f({id: 'component.MyAccount.Pay.Detail.column.status'})}</div>
                        <div>{payResult[payResultType]}</div>
                    </Content>
                </GridItem>
                <GridItem xs={12}  sm={6}>
                    <Content>
                        <div>{f({id: 'component.MyAccount.Pay.Detail.column.pay_method'})}</div>
                        <div>{GetPayMethodName(pay_method)}</div>
                    </Content>
                </GridItem>
                <GridItem xs={12}  sm={6}>
                    <Content>
                        <div>{cardName}</div>
                        <div>{cardValue}</div>
                    </Content>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Content>
                        <div>{f({id: 'component.MyAccount.Pay.Detail.column.wdate'})}</div>
                        <div>{parseDate(wdate)}</div>
                    </Content>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Content>
                        <div>{f({id: 'component.MyAccount.Pay.Detail.column.adate'})}</div>
                        <div style={{textDecoration: cdate ? 'line-through' : 'none'}}>{parseDate(adate)}</div>
                    </Content>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Content>
                        <div>{f({id: 'component.MyAccount.Pay.Detail.column.cdate'})}</div>
                        <div>{parseDate(cdate)}</div>
                    </Content>
                </GridItem>
                {
                    (receiptUrl) && (

                        <GridItem xs={12} sm={6}>
                            <Content>
                                <div>{f({id: 'component.MyAccount.Pay.Detail.column.card_slip'})}</div>
                                <div><Button color="primary" onClick={() => window.open(receiptUrl)}>{f({id: 'component.MyAccount.Pay.Detail.column.card_slip_button'})}</Button></div>
                            </Content>
                        </GridItem>
                    ) 
                }
               
            </GridContainer>
            {
                isDeposit && (
                    <DepositComponent
                        pay_method={pay_method} 
                        appCardName={appCardName} 
                        cardNum={cardNum} 
                        vbankDate={vbankDate}
                        order_name={order_name} 
                        amt={amt}
                    />
                )
            }
            {
                (!cdate && pay_method !== PayMethod.Card) && (
                    <TaxComponent
                        user_payment_no={user_payment_no}
                        tax_in_innopay={tax_in_innopay} 
                        user_tax={user_tax}
                    />
                )
            }
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
                <Button onClick={onClose} variant="contained"  size="small" disableElevation>{f({id: 'component.MyAccount.Pay.Detail.close'})}</Button>
            </div>
        </DialogContent>
       
      
        </>
    )    
}

export default DetailComponent