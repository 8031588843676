import { memo } from 'react'
import ConfirmComponent from 'component/Survey/Edit/Confirm'
import {
    SurveyEditTabsComponent,
    SurveyEditTabComponent,
    SurveyEditQuestionComponent
} from 'component'
import { useIntl } from 'react-intl'

// 서술형이나 소개글은 탭이 없어서, 1문1답일때는 0, 아닐때는 100(안보여주기위한 임의값)
export function getConfirmIndex(method) {
    return method === 'one' ? 0 : 100
}

const Component = (props) => {    
    const { method } = props

    const { formatMessage: f } = useIntl()
    const tabs = [f({id: 'container.Survey.Edit.00.tabs.0'})]

    return (
        <>
        <SurveyEditTabsComponent tabs={tabs}/>
        <SurveyEditTabComponent index={0}>
            <SurveyEditQuestionComponent/>
            <ConfirmComponent index={getConfirmIndex(method)}/>
        </SurveyEditTabComponent>
        </>
    )
}

export default memo(Component)