import { useContext } from 'react'
import { isBrowser } from 'react-device-detect'
import PropsContext from 'context/props'
import styled, { css } from 'styled-components'

const FormControl = styled.div`
    display: table;
    width: 100%;
    position: relative;
    padding: 10px;
    border: 1px dashed ${({ answer_button}) => `rgba(${answer_button})`};
    border-radius: 3px;

    & + & { 
        margin-top: 5px;
    }
    
    & > div {
        display: table-cell;
    }

    & > div:first-child {
        // grow, shrink basis 순
        width: 2.8em;
        padding-bottom: .2em;
    }

    & > div:nth-child(2) {

        vertical-align: middle;
      
    }

    & > div:last-child {
        min-width: 5em;
    }

    & > div:last-child.disabled {
        opacity: .5;
    }

    &.selected {
        border: 1px solid ${({ answer_button}) => `rgba(${answer_button})`};
    }
`

export function FormControlElement(props) {
    const { children, ...other } = props

    const { design } = useContext(PropsContext)
    const { answer_button } = design
    return <FormControl answer_button={answer_button} {...other}>{children}</FormControl>
}

const TableStyled = styled.div`
    display: table;
    width: 100%;
    border-spacing: 0 3px;
    
    & > div {
        display: table-row;
    }

    & > div > div {
        position: relative;
        max-width: ${props => `calc(100% / ${props.rows})`};
        min-width: 60px;
        display: table-cell;
        vertical-align: middle;
        font-size: 1rem;
        word-break: break-all;
        z-index: 1;
    }

    & > div > div {
        padding: 10px 8px;
    }

    & > div > div:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    & > div > div:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    & > div > div > div.selected {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        border-radius: 4px; 
        transition: all .3s;
        z-index: 1;
        cursor: pointer;
        
        ${isBrowser && css`
            &:hover {
                background: rgba(0, 0, 0, .1);
            }
        `}
    }

    & > div > div > div.selected.checked {
        background: rgba(0, 0, 0, .2);
    }

    & > div > div > div.selected.disabled {
        cursor: default;
        background: transparent;
    }

    & > div:first-child > div {
        cursor: default;

    }
    
    & > div > div:first-child {
        cursor: default;
    }
    
    & > div:nth-child(2n) {
        background: transparent;
    }
    
    & > div:not(:first-child) {
        background: rgba(0, 0, 0, .1);
    }

    & > div > div:nth-child(1) {
        position: sticky;
        left: 0;
        z-index: 2;
    }

    & > div:nth-child(1) > div:first-child {
        background: transparent;
    }

    .center {
        text-align: center;
    }

    .hide {
        opacity: 0;
        transition: opacity .3s;
    }

    .input.disabled {
        opacity: .5;
        
        &:hover {
            background: transparent;
        }
    }
    .content {
        width: 100%;
        padding: 10px;
        margin: 0 20px;
        font-size: 18px;
    }
`

export default (props) => {
    const { children } = props

    return (

        <TableStyled>
            {children}
        </TableStyled>

    )
}