/**
 * 미리보기에서는 redirect to url을 안하고 안내 화면을 보여준다
 */
import Box from '@material-ui/core/Box'
import Question from 'styled/Custom/Question'
import { withStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

export const MyRedirectBox = withStyles({
    root: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10
    }
})(Box)

function RedirectMessageComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <MyRedirectBox>
            <Box>
                <Question style={{fontWeight: 900}}>
                    {f({id: 'component.Survey.Modules.Ending.redirect.message'})}
                </Question>
            </Box>
        </MyRedirectBox>
    )
}

export default RedirectMessageComponent