import { Storage } from 'gql/storage'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import withUserFetchData from 'hoc/User/FetchData'
import MyPhotoComponent from "./MyPhoto"
import MyFileuploadComponent from "./MyFileupload"
import MyResponseComponent from "./MyResponse"

interface Props {
    propsFetchData: {
        storage: Storage;
        refetchStorage: () => void;
    }
}

const MyDivider = withStyles({
    root: {
        marginTop: 25,
        marginBottom: 25
    }
})(Divider)

export function getPercent(used: number, total: number) {
    const value = used / total * 100

    return value > 100 ? 100 : value
}

function StorageComponent(props: Props) {
    const { propsFetchData } = props
    const { storage, refetchStorage } = propsFetchData

    const { myPhoto, fileupload, response } = storage

    return (
        <>
        <MyPhotoComponent size={myPhoto}/>
        <MyDivider/>
        <MyFileuploadComponent size={fileupload} refetch={refetchStorage}/>
        <MyDivider/>
        <MyResponseComponent rows={response}/>
        </>
    )
}

export default withUserFetchData({storage: true})(false)(StorageComponent)