import { useContext, useState, useCallback, useEffect } from 'react'
import PropsContext from 'context/props'
import { withSurveyLayout, withBreakPoints } from 'hoc'
import { isEqual } from 'lodash-es'
import { Wrapper } from 'styled/Survey/Base'
import { getReplysKeyOfValue } from '../01/Component'
import ItemComponent from './Item'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import CheckCountComponent from './CheckCount'

const Component = (props) => {
    const { format, refComments02 } = useContext(PropsContext)

    const { boxRows, boxCells, layout, is_comment_placeholder, required=false, required_end=0, survey_question_no, reply } = props

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState(reply)

    // survey_module_no를 key로 사용
    const replysKeyOfValue = getReplysKeyOfValue('survey_module_no', newReply)

    const handleChange = useCallback(async(e, survey_module_no, click_comment, disabled) => {
        e.stopPropagation()
        e.preventDefault()

        // 답체크
        // 미체크시 체크, 체크시 미체크
        // 체크된 상태에서 코멘트 클릭시 유지

        if (disabled) return

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c => c.survey_module_no === survey_module_no) ? true : false

        //체크 상태인데 코멘트 클릭시에는 리턴
        if (selfChecked && click_comment) return

        const reply = format._02({ survey_question_no, survey_module_no, comment: '' })

        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        const newReplys = selfChecked ? [ ...memoryReply.rows.filter(c => !(c.survey_question_no === survey_question_no && c.survey_module_no === survey_module_no)) ] 
                                      : [ ...memoryReply.rows, reply ] 
    

       // 랜더링 안되게 일반 변수로 저장
       memoryReply.rows = newReplys

       // 해당 문항의 값만 넣자
       await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

       onChangeReplys(newReplys)
    }, [ survey_question_no, newReply, format, onChangeReplys ])

    
    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <>
        <Wrapper layout={(100 / layout)}>    
            {
                boxRows.map((d, i) => (
                    <div key={i}>
                        {
                            boxCells[i].map(c => {
                                const { survey_module_no, answer, is_comment } = c

                                const isReply = replysKeyOfValue[survey_module_no]
 
                                const selected = isReply !== undefined
                                const comment = isReply ? isReply.comment : ''
    
                                // required_end 만큼 체크가 됏으면 나머지는 disabled 처리한다. (선택된값은 해제도 해야하기때문 disabled 처리안한다)
                                const disabled = newReply.length > 0 ? 
                                                !selected && required && required_end > 0 && newReply.length >= required_end ? true : false : false


                                return (
                                    <ItemComponent 
                                        key={survey_module_no}
                                        survey_module_no={survey_module_no}
                                        selected={selected}
                                        comment={comment}
                                        disabled={disabled}
                                        answer={answer}
                                        is_comment={is_comment}
                                        is_comment_placeholder={is_comment_placeholder}
                                        required={required}
                                        required_end={required_end}
                                        refComment={refComments02}
                                        onChange={handleChange}
                                    />
                                )
                            })
                        }
                    </div>
                ))
            }
        </Wrapper>
        <CheckCountComponent count={newReply.length}/>
        </>
    )
}

export default withBreakPoints(withSurveyLayout(Component))