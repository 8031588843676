// 현재값이 필요해서 해당 문항에서만 state를 변경한다
import { useState, useCallback, useEffect } from 'react'

let time: ReturnType<typeof setTimeout>

function useValue(value: string | null, onChange: (val:string) => void): [string | null, (val: string) => void] {
    const [ state, setState ] = useState<string | null>(value)

    const handleChange = useCallback((value) => {    
        setState(value)

        clearTimeout(time)
        time = setTimeout(() => onChange(value), 300)
    }, [onChange])

    useEffect(() => {
        setState(value)
    }, [value])

    return [ state, handleChange]
}

export default useValue