/**
 * 마이 메뉴
 */

import { memo, MouseEvent, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { RootState } from 'reducer'
import { handleDisabledRightClick } from 'ts-utils'
import {
	MenuItem, 
	Menu,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Typography,
    Avatar,
    Divider
} from '@material-ui/core'
import { 
    PaymentTwoTone as PaymentTwoToneIcon, 
    ExitToApp as ExitToAppIcon, 
    AccountCircle as AccountCircleIcon,
    ChatTwoTone as ChatTwoToneIcon,
    FiberNew as FiberNewIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import useChannelIO from 'hooks/useChannel'
import useLogout from 'hooks/useLogout'
import { TokenTypes } from 'hooks/useTokenInfo'
import LangButton from 'styled/LangButton'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    user_no: TokenTypes['user_no'];
    img_src: TokenTypes['img_src'];
    email: TokenTypes['email'];
    nickname: TokenTypes['nickname'];
}

const useStyles = makeStyles(theme => ({
    avatar: {
        cursor: 'pointer',
        width: 34,
        height: 34,
        [theme.breakpoints.down('xs')]: {
            width: 30,
            height: 30
        }
    },
    menuItemAvatar: {
        width: theme.spacing(5),
        height: theme.spacing(5)
    }
}))

function MyComponent(props: Props) {
    const { user_no, img_src, email, nickname } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const states = useSelector((states: RootState) => states.surveyCreateModuleIsEdit)
    const { is: isEdit } = states

    const history = useHistory()

    const logout = useLogout()

    // 메뉴
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    // channel open
    const [openChannel, setOpenChannel ] = useState(false)

    const open = Boolean(anchorEl)

    const channelIO = useChannelIO({ user_no, email })

    const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setOpenChannel(false) // 메뉴가 열리면 채팅창은 닫힌상태값으로 한다 
    }

    // 채널 보이기
    const handleShowChannel = () => {
        setAnchorEl(null)
        setOpenChannel(true)
    }

    
    // 메뉴 열기 닫기 등..
    const handleAvataClose = () => {
        setAnchorEl(null)
    }

    const handleAvataMyAccount = () => {
     
        if (isEdit) {
            if (window.confirm(f({id: 'component.Outer.Back.isEditMessage'}))) {
                setAnchorEl(null)
                history.push('/myaccount')
            }
        } else {
            setAnchorEl(null)
            history.push('/myaccount')
        }
    }

    const handleAvataLogout = () => {
        if (isEdit) {
            window.alert(f({id: 'component.Outer.My.isEditLogout'}))
            return
        } 
        
        setAnchorEl(null)
        logout()
    }

    const handleChangeLangCallback = () => {
        setAnchorEl(null)
    }

    const handlePayment = () => {
        if (isEdit) {
            if (window.confirm(f({id: 'component.Outer.Back.isEditMessage'}))) history.push('/payment/choice')
        } else history.push('/payment/choice')
    }

    const handleUpdate = () => {
        window.open('https://listovey-update.notion.site/listovey-update/1daebc93d1ad48a3a52ae132d42ed2bc?v=80918422d7f64047be5ee2a3482796bd', '_blank')
        setAnchorEl(null)
    }

    // 메뉴가 닫히고 채널이 open이라면 채널창을 연다
    useEffect(() => {
        async function _do() {

            if (anchorEl === null && openChannel) {
                const ChannelIO = await channelIO()
                ChannelIO('showMessenger')
            }
        }

        _do()
        
    }, [channelIO, anchorEl, openChannel])

    return (
        <>
        <Avatar className={classes.avatar} src={img_src} onClick={handleMenu} onContextMenu={handleDisabledRightClick}>{nickname}</Avatar>
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={open}
            onClose={handleAvataClose}
        >
            <MenuItem button={false} dense>
                <ListItemAvatar>
                    <Avatar className={classes.menuItemAvatar} src={img_src}  onContextMenu={handleDisabledRightClick}>{nickname}</Avatar>
                </ListItemAvatar>
                <ListItemText>
                    <Typography color="textSecondary" gutterBottom>{email}</Typography>
                    {
                        !isEdit && <LangButton callback={handleChangeLangCallback} timeout={300}/>
                    }
                </ListItemText>
            </MenuItem>

            <MenuItem  selected={true}  onClick={handleAvataMyAccount}>
                <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                <ListItemText primary={f({id: 'component.Outer.Component.profile'})}/>
            </MenuItem>
            <MenuItem  onClick={handleShowChannel}>
                <ListItemIcon><ChatTwoToneIcon/></ListItemIcon>
                <ListItemText primary={f({id: 'component.Outer.Component.chat'})}/>
            </MenuItem>
            <MenuItem  onClick={handlePayment}>
                <ListItemIcon><PaymentTwoToneIcon/></ListItemIcon>
                <ListItemText primary={f({id: 'component.Outer.Component.planView'})}/>
            </MenuItem>
            <MenuItem  onClick={handleUpdate}>
                <ListItemIcon><FiberNewIcon/></ListItemIcon>
                <ListItemText primary={f({id: 'component.Outer.Component.update'})}/>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={handleAvataLogout}>
                <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                <ListItemText 
                    primary={
                        
                        <Typography color="secondary" variant="body2">
                            {f({id: 'component.Outer.Component.logout'})}
                        </Typography>
                    } 
                />
            </MenuItem>
        </Menu>
        </>
    )
}

export default memo(MyComponent)

