import { useEffect, useState, useContext, useCallback, createContext } from 'react'
import {useDispatch } from 'react-redux'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { SHOW as ANSWERERRORPAGESHOW } from 'reducer/answerErrorPage'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { HIDE as CAUTIONHIDE } from 'reducer/survey/live/caution'
import { SurveyLiveMultiComponent } from 'component'
import { scroller } from 'react-scroll'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { useIntl } from 'react-intl'
import { getDiffSeconds } from 'utils/survey'
import { getStartsTokenname, parseGraphqlError } from 'utils'
import { withSurveySetReplys, withSurveySetState, withPrevState } from 'hoc'
import moment from 'moment'
import { GET_SURVEY_ANALYSIS_MODULES, CREATE_SURVEY_ANALYSIS } from 'gql/survey_analysis_modules'
import { UPDATE_SURVEY_ANALYSIS_START, UPDATE_SURVEY_ANALYSIS_STATUS } from 'gql/survey_analysis_users'
import { GET_SURVEY_PAGE_JUMP_NEXT } from 'gql/survey_page'
import { GET_SURVEY_ANALYSIS_COMMENTS } from 'gql/survey_analysis_comment'
import { CREATE_SURVEY_ANALYSIS_ENDING } from 'gql/survey_ending'
import { Method } from 'gql/jump'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import useVerifySaveReply from 'hooks/useVerifySaveReply'
import { memoryReply } from './Container'
import { checkRequireAnswered } from './One'
import StartContainer from './Start'
import EndingaContainer from './Endinga'
import SurveyLiveRestartComponent from 'component/Survey/Live/Restart'
import FreeLogoComponent from 'component/Survey/Live/FreeLogo'
import { Progress } from 'ccomponent/BackDrop'

// 파일 업로드시 로딩 박스
export const FileUploadLoadingBox = withStyles({
    root: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, .5)',
        color: 'white',
        textAlign: 'center',
        fontSize: 13,
        lineHeight: '185%'
    }
})(props => (
    <Box {...props}>
        <Progress />
        <span style={{ marginTop: 10, color: 'white', textAlign: 'center', fontSize: 13, lineHeight: '185%' }} />
    </Box>
))

export const showFileUploadLoading = (id, display, msg) => {

    const myDiv = document.getElementById(id);

    // Assuming the <div> has a <span> child element
    const mySpan = myDiv.querySelector("span");

    mySpan.innerHTML = msg.replaceAll('<%', '<').replaceAll('%>', '>')
    myDiv.style.display = display
}

// 페이지 컴포넌트에서 사용되는 콘텍스트
export const PageContext = createContext({
    target: {
        method: null,
        id: null,
        pmethod: null,
        pid: null
    }
})

// save 클릭 이벤트 true: 가능 false: 불가능
let saveDo = true

// 현재 페이지
let Page = 0 

// 현재 페이지가 마지막 페이지인지 체크
function isLastPage(pages, page) {
    const { indexs: lastPage } = pages[(pages.length-1)]

    // indexs === page +1 이 같은 페이지이다.
    return lastPage === (page + 1)
}


// 초기 DB에 저장된 값 저장 변수
let initData = []
const Live =  (props) => {
    const { 
        mbox, 
        levels,
        option, 
        design,
        start,
        required=true, 
        loadingQuestions, 
        token, 
        firstPage,
        propsSetReplys, 
        propsSetState, 
        usePrevState, 
        rowsJump,
        getFilterJumpQuestions,
        questions: propsQuestions,
        prevQuestions: prevPageQuestions,
        target,
        surveyAnalysisUserRow,
        survey_no: propsSurveyNo,
        pages: propsPages,
        page: propsPage,
        onChangePage
    } = props
    const { 
        format, 
        replys, 
        refComments01,
        refComments02,
        refComments09,
        refComments10,
        refComments12,
        refComments13,
        refComments17,
        refComments18,
        refComments20,
        refCommentsWith,
        handleChangeReplys
    } = propsSetReplys
    const { replyComments, ending, setReplyComments, setEnding } = propsSetState

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const accessMethod = useContext(AccessMethodContext)
    const { scrollerObj } = useContext(PropsContext)

    const [ onVerifySaveReply ] = useVerifySaveReply('multi')

    const { start: userStart } = surveyAnalysisUserRow

    const startsTokenname = getStartsTokenname(propsSurveyNo)

    // propsQuestions 는 처음가져올때만 analysis랑 패치한다. 그에대한 구분값
    const [ isQuestions, setIsQuestions ] = useState(false)

    // 완료글 저장하고 updateStatus까지 완료 되면 완료글을 보여준다 (redirect url할때 혹여나 updateStatus가 안되었는데 이동되면 상태값이 ing로 되니까..)
    const [ endingView, setEndingView ] = useState(false)


    // replys는 있는데 question이 없다면 [] => question이 많을경우 state 변경이 느리게 작동해서 어긋나는 현상이 생긴다 ㅜㅜ
    const prevReplys = usePrevState(replys)
    const prevPage = usePrevState(propsPage)
    const prevQuestions = usePrevState(propsQuestions)

    const [ getDataAnlysis ] = useLazyQuery(GET_SURVEY_ANALYSIS_MODULES, {
        variables: {
            survey_no: propsSurveyNo,
            token,
            survey_page_no: propsPage,
            mode: accessMethod.mode
        },
        onCompleted: async(data) => {
            initData = data.surveyAnalysisModules

            // replys 기본값 셋팅
            const replys = data.surveyAnalysisModules.map(c => {
                const { survey_question_no, survey_module_no, survey_module_answer_no,  __typename } = c

                switch(__typename) {
                    case 'Module_00':
                        return format._00({ survey_question_no })
                    case 'Module_01':
                        return format._01({ survey_question_no, survey_module_no, comment: c.comment })
                    case 'Module_02':
                        return format._02({ survey_question_no, survey_module_no, comment: c.comment })
                    case 'Module_03':
                        return format._03({ survey_question_no, survey_module_no, survey_module_answer_no })
                    case 'Module_04':
                        return format._04({ survey_question_no, survey_module_no, survey_module_answer_no })
                    case 'Module_05':
                        return format._05({ survey_question_no, survey_module_no })
                    case 'Module_06':
                        return format._06({ survey_question_no, survey_module_no, survey_module_answer_no })
                    case 'Module_07':
                        return format._07({ survey_question_no, survey_module_no })
                    case 'Module_08':
                        return format._08({ survey_question_no, survey_module_no, survey_module_answer_no })
                    case 'Module_09':
                        return format._09({ survey_question_no, answer: c.answer, national_flag: c.national_flag })
                    case 'Module_10':
                        return format._10({ survey_question_no, answer: c.answer })
                    case 'Module_11':
                        return format._11({ survey_question_no, survey_module_no, survey_module_answer_no })
                    case 'Module_12':
                        return format._12({ survey_question_no, survey_module_no, comment: c.comment })
                    case 'Module_13':
                        return format._13({ survey_question_no, survey_module_no, comment: c.comment })
                    case 'Module_14':
                        return format._14({ survey_question_no, survey_module_no })
                    case 'Module_15':
                        return format._15({ survey_question_no, survey_module_no, survey_module_answer_no })
                    case 'Module_16':
                        return format._16({ survey_question_no, survey_module_no })
                    case 'Module_17':
                        return format._17({ survey_question_no, survey_module_no, survey_module_answer_no, comment: c.comment })
                    case 'Module_18':
                        return format._18({ survey_question_no, survey_module_no, survey_module_answer_no, comment: c.comment })
                    case 'Module_19':
                        return format._19({ survey_question_no, src: c.src, filename: c.filename, filetype: c.filetype, filesize: c.filesize })
                    case 'Module_20':
                        return format._20({ survey_question_no, zipcode: c.zipcode, address1: c.address1, address2: c.address2 })
                    case 'Module_21':
                        return format._21({ survey_question_no, survey_module_no })
                    case 'Module_22':
                        return format._22({ survey_question_no, survey_module_no, survey_module_answer_no })
                    default:
                        return null
                }
            })  

            await handleChangeReplys(replys)

            // 재런더링 방지를 위해 memoryReply rows에 담는다
            memoryReply.rows = replys

            // 다음 누를시 값이 없어지는게 보여서 좀 늦게 state로 변경한다
            setTimeout(() => {
                scroller.scrollTo(scrollerObj.targetTop, {
                    ...scrollerObj.scrollTo,
                    containerId: scrollerObj.id
                }) 

                saveDo = true
            }, 100)
            
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        },
        fetchPolicy: 'network-only'
    })

    const [ getPageJumpNext ] = useLazyQuery(GET_SURVEY_PAGE_JUMP_NEXT, {
        onCompleted: async (c) => {
            // target.id가 없으면 다음 페이지 번호로 가져온다.
            const movePage = c.surveyPageJumpNext

          

            // 종료일때는 완료글에대한 응답값을 저장한다

            // 타겟이 지정되었다면 해당타겟으로 저장
            if (target.method === Method.End) {
                handleEndingSave(movePage)
            } else if (target.method === Method.Move && target.id === 1) {
                // method move이고 id가 1이면 시작글로 보내는거다.
                onChangePage(0)
            } else if (isLastPage(propsPages, Page) && !movePage) {
                // 현재 섹션이 마지막 섹션이면서, movePage가 없다는건 이동하는 타겟이 없는것이므로 종료한다.
                // 점프설정값이 있어도, 완료설정값이 있을수도있구, 그게 아니더라도 마지막페이지 마지막 문항 완료글 응답저장으로 넘긴다.
                handleEndingSave()
            } else {
                onChangePage(movePage)
            }
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        },
        fetchPolicy: 'network-only'
    })

    const [ getComment ] = useLazyQuery(GET_SURVEY_ANALYSIS_COMMENTS, {
        variables: {
            survey_no: propsSurveyNo,
            token,
            survey_page_no: propsPage,
            mode: accessMethod.mode
        },
        onCompleted: async (data) => {
            
            // replys 기본값 셋팅
            const replyComments = data.surveyAnalysisComments.map(c => ({ survey_question_no: c.survey_question_no, comment: c.comment }))

            // 다음 누를시 값이 없어지는게 보여서 좀 늦게 state로 변경한다
            setTimeout(() => {
                setReplyComments(replyComments)
            }, 100)
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        },
        fetchPolicy: 'network-only'
    })

    
    const [ createSurveyAnalysis ] = useMutation(CREATE_SURVEY_ANALYSIS, {
        onCompleted: async(data) => {
            if (!onVerifySaveReply(memoryReply.currentData, memoryReply.currentWithComments, data.createSurveyAnalysis)) {
                
                memoryReply.rows = memoryReply.currentData
                handleChangeReplys(memoryReply.currentData)
                
                setTimeout(() => {
                    setReplyComments(memoryReply.currentWithComments)
                    dispatch({type: CAUTIONHIDE})
                }, 100)

                return
            }

            if (required) {
                const requiredValue = await checkRequireAnswered({
                    questions: prevPageQuestions, 
                    replys, 
                    comments: [], 
                    passComment: [],
                    confirm09Rows: []
                }) 

                if (requiredValue[0] === false) {   
                    // 저장이 안되었다면 해당문항으로 강제로 보낸다
                    const self = prevPageQuestions.find(question => question._question.survey_question_no === requiredValue[1])
                    if (self) {
                        onChangePage(self._question.survey_page_no)
                        return
                    }
        
                }
            }


            if (rowsJump.length > 0) {
                getPageJumpNext({ variables: {
                    survey_no: propsSurveyNo,
                    token,
                    survey_page_no: propsPage,
                    method: target.method,
                    id: target.id,
                    mode: accessMethod.mode
                }})
            } else {
                // 점프설정은 없을때, 다음페이지가 완료일경우 완료글 응답저장 호출, 아니면 페이지 이동으로 호출
                if (isLastPage(propsPages, Page)) handleEndingSave()
                else handleNext(Page)
            }

            // 이전으로 돌아갈때 caution이 남아있기때문에 없애준다.
            setTimeout(() => {
                dispatch({type: CAUTIONHIDE})
            }, 500)
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'No User') {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.noUser'})})
            } else {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
            }
        }
    })


    const [ updateSurveyAnalysisStart ] = useMutation(UPDATE_SURVEY_ANALYSIS_START, {
        onError: (e) => {
            if (parseGraphqlError(e) === 'No User') {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.noUser'})})
            } else {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
            }
        }
    })

    const [ updateSurveyAnalysisStatus ] = useMutation(UPDATE_SURVEY_ANALYSIS_STATUS, {
        onCompleted: async (data) => {
            sessionStorage.setItem(startsTokenname, moment())

            //완료된 상태라면 setEndingView를 true
            if (ending !== undefined) setEndingView(true)
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'No User') {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.noUser'})})
            } else {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
            }
        }
    })

    // 설문 완료글 응답값 저장
    const [ createSurveyAnalysisEnding ] = useMutation(CREATE_SURVEY_ANALYSIS_ENDING, {
        onCompleted: async (data) => {
            setEnding(data.createSurveyAnalysisEnding)
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
            dispatch({ type: BACKDROPHIDE })
        }
    })

    // 완료글 응답 저장
    const handleEndingSave = useCallback((survey_ending_no) => {
        createSurveyAnalysisEnding({ variables: {
            survey_no: propsSurveyNo,
            token,
            survey_ending_no: survey_ending_no || null,
            mode: accessMethod.mode
        } })
    }, [propsSurveyNo, token, accessMethod.mode, createSurveyAnalysisEnding])

    // 다음글 저장
    const handleNext = useCallback((index) => {
        // pages.indexs는 1부터 시작하고, index는 0부터 시작해서리... 다음페이지는 index+2를 한다.
        const nextPage = index + 2

        const { survey_page_no } = propsPages.find(c => c.indexs === nextPage)
        onChangePage(survey_page_no)
    }, [propsPages, onChangePage])

    const handleSave = useCallback((page, data, comments) => {
        return new Promise(async(resolve, reject) => {
            const input = {
                token,
                survey_no: propsSurveyNo,
                mode: accessMethod.mode,
                method: accessMethod.method,
                data,
                comments,
                survey_page_no: propsPage,
                required
            }

            memoryReply.currentData = data
            memoryReply.currentWithComments = comments
            
            try {
                Page = page
                await createSurveyAnalysis({variables: { input }})
                resolve()
            } catch(e) {
                reject()
            } finally {
                await setIsQuestions(false)
            }
        })
    }, [
        propsSurveyNo,
        propsPage,
        required,
        accessMethod,
        token,
        createSurveyAnalysis
    ])

    const handlePrev = useCallback((index) => {
        function getPrevPageIndex() {
            // pages.indexs는 1부터 시작하고, index는 0부터 시작해서리... 이전페이지는 index 그대로 하면 이전페이지로 이동한다
            const prevPage = propsPages.find(c => c.indexs === index)

            if (!prevPage) return propsPages[0].survey_page_no
            else return prevPage.survey_page_no
        }

        if (!saveDo) return

        saveDo = false
        
        if (index === -1) {
            // 시작글로 보낸다
            onChangePage(0)
        }   
        else if (rowsJump.length === 0) {
            onChangePage(getPrevPageIndex())
        } else {
            if (target.pmethod === Method.Move && target.pid) {
                onChangePage(target.pid)
            } else {
                onChangePage(getPrevPageIndex())
            }
        }

        setIsQuestions(false)
    }, [
        onChangePage,
        propsPages,
        rowsJump,
        target,
        setIsQuestions
    ])

    const handleChangeUserStart = useCallback(() => {
        updateSurveyAnalysisStart({variables: { 
            survey_no: propsSurveyNo,
            token,
            mode: accessMethod.mode
        }})
    }, [propsSurveyNo, token, accessMethod, updateSurveyAnalysisStart])

    function handleChangeStart() {
        onChangePage(firstPage)

        handleChangeUserStart()
    }

    useEffect(() => {
        const func = async() => {
            await setIsQuestions(true)
            getDataAnlysis()
            getComment()
        }

        // 질문을 가져오면 => 지금값과 이전값이 달라야한다.
        // 응답값 , 질문의 코멘트값 가져오고 요구사항 안내가 콤퍼넌트 초기화하자
        if (!isQuestions && propsQuestions !== prevQuestions) func()
        
    }, [isQuestions, propsQuestions, prevQuestions, getDataAnlysis, getComment])

    useEffect(() => {
        // replys 값이 이전값이랑 다르고, questions가 로딩후
        if (propsQuestions.length >= 0 && isQuestions && (replys !== prevReplys || propsPage !== prevPage)) {
            getFilterJumpQuestions(replys)
        }
    }, [isQuestions, propsQuestions, getFilterJumpQuestions, replys, prevReplys, propsPage, prevPage])

    // 페이지가 바뀌어 문항내용이 바뀌면...
    useEffect(() => {
        if (prevQuestions !== propsQuestions) {
            if (propsQuestions.length > 0) {
                dispatch({type: BACKDROPHIDE}) 
            }
        }
    }, [propsQuestions, prevQuestions, dispatch])

    // 페이지이동액션을했는데 문항 내용이 없다면 다음페이지로 넘긴다.
    useEffect(() => {
        if (propsQuestions.length === 0 && prevPage !== propsPage) {
            let prevPageIndex = undefined
            let currentPageIndex = undefined
            for (let i=0; i<propsPages.length; i++) {
                if (propsPages[i].survey_page_no === prevPage) prevPageIndex = i
                if (propsPages[i].survey_page_no === propsPage) currentPageIndex = i

                if (prevPageIndex >=0 && currentPageIndex >=0) break
            }

            // 다음을 클릭했을 경우이다. , 시작글에서 1페이지로 왔을때는 currentPageIndex=0, prevPageIndex===undefined이다.
            if (currentPageIndex > prevPageIndex || (currentPageIndex === 0 && prevPageIndex === undefined)) {
                handleSave(currentPageIndex, [], [])
            } else if (currentPageIndex < prevPageIndex) {
                saveDo=true
                handlePrev(currentPageIndex)
            }
        }
    }, [propsQuestions, propsPages, prevPage, propsPage, handleSave, handlePrev])

    useEffect(() => {
        if ((propsPage > 0 && prevPage !== propsPage) || ending) {
            updateSurveyAnalysisStatus({
                variables: {
                    survey_no: propsSurveyNo,
                    survey_page_no: propsPage,
                    step: ending ? 'end' : 'ing',
                    token,
                    einterval: getDiffSeconds({ now: sessionStorage.getItem(startsTokenname), then: moment() }),
                    mode: accessMethod.mode
                }
            })
        }
    }, [accessMethod.mode, propsSurveyNo, propsPage, prevPage, ending, startsTokenname, updateSurveyAnalysisStatus, token])

    // 시작글이 미사용이면 1페이지로 이동한다.
    useEffect(() => {
        if (!ending && propsPage === 0 && !start.used) {
            onChangePage(firstPage)
        }
    }, [ending, propsPage, start.used, firstPage, onChangePage])

    // Element는 ref 적용이 안된다......;;; 
    // 설명문과 문항은 height 값이 다르다.. 이유는.. 하단에 페이지 컴포넌트 유무
    if (ending || propsPage === 0) {
        document.getElementById(scrollerObj.id).style.height = '100%'
    } else if (propsPage > 0) {
        document.getElementById(scrollerObj.id).style.height = 'calc(100% - 48px)'
    }

    const isView = Boolean(ending !== undefined || propsPage > 0)

    const fileuploadLoadingId = `fileupload-multi-id-${propsSurveyNo}`

    return (
        <>
        <PropsContext.Provider value={{
            survey_no: propsSurveyNo,
            initData,
            token,
            levels,
            mbox,
            option,
            fileuploadLoadingId,
            questions: propsQuestions, 
            pages: propsPages, 
            page: propsPage, 
            startUsed: start.used,
            userStart,
            rowsJump,
            replys, 
            replyComments, 
            onChangeReplys: handleChangeReplys,
            refComments01,
            refComments02,
            refComments09,
            refComments10,
            refComments12,
            refComments13,
            refComments17,
            refComments18,
            refComments20,
            refCommentsWith,
            format,
            required,
            design, 
            onNext: handleSave,
            onPrev: handlePrev,
            scrollerObj
        }}>
            
        {
            (ending !== undefined && endingView) ? (
                <>
                <EndingaContainer survey_no={propsSurveyNo} row={ending} mode={accessMethod.mode}/>
  
                <SurveyLiveRestartComponent isView={isView}/>
                <FreeLogoComponent mode={accessMethod.mode} levels={levels} isPage={false}/>
                </>
            ) : (propsPage === 0 && start.used) ? (
                <>
                <StartContainer survey_no={propsSurveyNo} onChange={handleChangeStart}/> 
                <SurveyLiveRestartComponent isView={isView}/>
                <FreeLogoComponent mode={accessMethod.mode} levels={levels} isPage={false}/>
                </>
            ) : (propsPage > 0) ? (
                <>
                <PageContext.Provider value={{target}}>
                    <SurveyLiveMultiComponent loading={loadingQuestions}/>
                </PageContext.Provider>
                <SurveyLiveRestartComponent isView={isView}/>
                <FreeLogoComponent mode={accessMethod.mode} levels={levels} isPage={true}/>
                </>
            ) : null 
        }
       
        </PropsContext.Provider>
        <FileUploadLoadingBox id={fileuploadLoadingId} />
        </>
    )    
}

export default withPrevState(withSurveySetState(withSurveySetReplys(Live)))