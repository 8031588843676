import { useSelector } from 'react-redux'
import { Snackbar } from '@material-ui/core'

export default () => {
    const loading = useSelector(state => state.loading)

    return (
        <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={loading.show}
        autoHideDuration={4000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{loading.message}</span>}
        />
    )
}