import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import oc from 'open-color-js'

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '115px',
        background: 'white'
    },
    appBar: {
        top: '64px',
        borderTop: `3px solid ${oc.gray4}`
    },
    tab: {
        fontSize: '15px',
        fontWeight: 900
    }
}));

export default function ScrollableTabsButtonAuto({ menus, menu, indexState, onMove }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
        <AppBar position="fixed" color="default" className={classes.appBar}>
            <Tabs
            value={indexState}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={onMove.move}
            >
            {
                menus[menu].map((c, i) => (
                    <Tab key={i} className={classes.tab} label={c.name} {...a11yProps(i)} />
                ))
            }
            </Tabs>
        </AppBar>
       
        </div>
    );
}
