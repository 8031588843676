import { memo, createContext, useMemo } from 'react'
import styled from 'styled-components'
import { Format05 } from 'hoc/Survey/SetReplys'
import { _05Module } from 'gql/survey_question_modules'
import ItemComponent from './Item'

interface Props {
    left_label: string;
    right_label: string;
    reply: Format05[];
    lists: _05Module[];
    reverse: boolean;
    zero_number: boolean;
    onChange: (a: number) => void;
}

interface OverflowProps {
    paddingBottom?: string;
    backgroundConcent?: string;
}

export const ItemContext = createContext<Props>({
    left_label: '',
    right_label: '',
    reply: [],
    lists: [],
    reverse: false,
    zero_number: true,
    onChange: () => {}
})

export const Overflow = styled.div<OverflowProps>`
    position: relative;
    display: grid;
    overflow-x: auto;
    padding-bottom: ${props => {
        return props.paddingBottom || 0
    }};
    grid-template-columns: minmax(100px, 1fr);

    /* 스크롤 스타일 줄때*/ 
    ::-webkit-scrollbar {
        width: 1px;
        height: 20px;
    }
        
    ::-webkit-scrollbar-thumb {
        background-color: ${props => {
            const rgba = props.backgroundConcent ? '220, 220, 220, .3' : '0, 0, 0, .3'
            return `rgba(${rgba})`
        }};
        outline: 0;
    }
`

function OverflowComponent(props: Props) {
    const { left_label, right_label, reply, reverse, zero_number, lists, onChange } = props

    return (
        <ItemContext.Provider value={{left_label, right_label, reply, reverse, zero_number, lists, onChange}}>
            {
                useMemo(() => {
                    return (
                        <Overflow paddingBottom="10px">
                            <ItemComponent/>
                        </Overflow>
                    )
                }, [])
            }
        </ItemContext.Provider>
        
    )
}

export default memo(OverflowComponent)