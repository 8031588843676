/**
 * 타입형 (전화번호 국가선택, 우선순위 문항에서 사용)
 */
import { useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { getColorConcentration } from 'utils'
import { InputBase, Select, FormControl, FormHelperText } from '@material-ui/core'
import PropsContext from 'context/props'

const useStyles = makeStyles({
    helper: (props) => {
        const { answer_button } = props

        return {
            color: `rgba(${answer_button})`,
            marginLeft: 10
        }
    },
    form: {
        width: '100%',
    }
})

const getBackgroundColor = (rgba) => {
    const [ r, g, b, a ] = rgba.split(',')

    const customA = Number(a) < .5 ? a : .5

    return `${r}, ${g}, ${b}, ${customA}`

}

// 통상적으로 쓰이는 스타일
const InputStyled = withStyles(theme => ({
    root: (props) => {
        return {}
    },
    focused: (props) => {
        const { answer_button } = props

        const rgba = getBackgroundColor(answer_button)

        return {
            backgroundColor: `rgba(${rgba})`,
            borderRadius: 4
        }

    },
    input: (props) => {
        const { answer_button, padding='9px 0 7px 11px', font_family } = props

        const concent = getColorConcentration(answer_button) 

        const rgba = getBackgroundColor(answer_button)

        return {
            borderRadius: 4,
            position: 'relative',
            color: concent ? 'white' : 'black',
            backgroundColor: `rgba(${rgba})`,
            fontSize: 16,
            fontWeight: 700,
            fontFamily: font_family,
            padding,
            boxSizing: 'border-box',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderRadius: 4,
              
            }
        }
    }
}))(InputBase)

const SelectStyled = withStyles(theme => ({
    icon: (props) => {
        const { answer_button } = props

        const concent = getColorConcentration(answer_button) 

        return {
            color: concent ? 'white' : 'black'
        }
    }
}))(Select)

export default (props) => {
    const { children, label, variant="outlined", margin="dense", padding, helper, value, ...other } = props
    const { design } = useContext(PropsContext)

    const { answer_button, font_family } = design

    const classes = useStyles(design)

    return (
        <FormControl variant={variant} className={classes.form}>
            <SelectStyled 
                {...other} 
                value={value} 
                margin={margin} 
                answer_button={answer_button} 
                input={<InputStyled value={value} answer_button={answer_button} font_family={font_family} padding={padding}/>}
            >
                {children}
            </SelectStyled>
            {helper && <FormHelperText className={classes.helper}>{helper}</FormHelperText>}
        </FormControl>
    )

}
