import { useEffect, useContext, useCallback, useState } from 'react'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { Kind } from 'gql/jump'
import AccessMethodContext from 'context/accessMethod'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import ItemComponent from './Item'

export default (props) => {
    const { format, onChangeReplys, refComments20, mbox, rowsJump } = useContext(PropsContext)

    const { method } = mbox
    
    const { mode } = useContext(AccessMethodContext)
    const { survey_question_no, reply } = props

    // originZipcode, originAddress1 은 state로 처리되고, originAddress2는 ref로 사용된다.
    const { zipcode: originZipcode, address1: originAddress1, address2: originAddress2 } = reply.length === 0 ? { zipcode: '', address1: '', address2: '' } : reply[0]
    
    const [ newReply, setNewReply ] = useState(reply)

    const [ _, onChangeUserStart ] = useChangeReplys(survey_question_no)

    // 1문 1답은 전체 랜더링, 클래식모드는 현재 문항만 랜더링
    const handleChangeState = useCallback(async(data) => {
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = data

        await setNewReply(data.filter(c => c.survey_question_no === survey_question_no))
        
        if (method === 'one') onChangeReplys(data)
    }, [method, survey_question_no, onChangeReplys])


    const handleChange = useCallback(args => {
        // 주소찾기, 상세주소가 분리되어있다
        const { zipcode, address1, address2 } = args
        
        const isData = memoryReply.rows.find(c => c.survey_question_no === survey_question_no)
        
        // 한개라도 값이 있다면..
        const isValue = zipcode || address1 || address2

        if (!isValue && isData) {
            handleChangeState([...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no)])
        } else if ( isValue && !isData ) {
            const reply = format._20({ survey_question_no, zipcode, address1, address2 })
            handleChangeState([...memoryReply.rows, reply])
        } else if ( isValue && isData ) {
            let newReplys = memoryReply.rows.map(row => {
                return row.survey_question_no === survey_question_no ? {
                    ...row,
                    zipcode, 
                    address1, 
                    address2
                } : row
            })

            handleChangeState(newReplys)
        }

        // 클릭하는 순간 어떤경우에도 시작 true
        onChangeUserStart()

    }, [survey_question_no, format, handleChangeState, onChangeUserStart])

    // 상세주소는 직접 쓰는거라 ref에 담는다
    const answered = mode === 'edit' ? '' : newReply.length === 0 ? '' : newReply[0].address2

    useEffect(() => {
        if (answered) refComments20.current[survey_question_no].value = answered
    }, [ answered, survey_question_no, refComments20 ])
    

    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    // 해당문항에 점프값이 있다면..
    const isConfirm = (method === 'multi' && rowsJump.find(c => c.kind === Kind.Question && c.id === survey_question_no))

    const { zipcode, address1 } = newReply.length === 0 ? { zipcode: '', address1: '' } : newReply[0] 

    return (
        <ItemComponent 
            survey_question_no={survey_question_no} 
            isConfirm={isConfirm}
            originZipcode={originZipcode}
            originAddress1={originAddress1}
            zipcode={zipcode}
            address1={address1}
            address2={originAddress2}
            onChange={handleChange} 
            refComment={refComments20}
        />
    )
}