import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { numberFormat } from 'utils'
import { SHOW as ALETSNACKBARSHOW } from 'reducer/alertSnackbar'
import { Button, Paper, Typography } from '@material-ui/core'
import styled from 'styled-components'

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: .2em;

    & > div {
        flex-basis: 0;
    }
    & > div.input {
        flex-grow: 1;
    }
    & > div.button {
        flex-grow: 4.5em;
        align-self: center;
    }

`

const InputStyled = styled.input.attrs(c => ({
    type: 'text',
    placeholder: c.placeholder
}))`
    width: 100%;
    height: 45px;
    border: 0;
    outline: 0;
    padding: 0 10px;
    font-size: 14px;
    font-family: NotoKrR;
`

export default (props) => {
    const { url, isVideo, hideAppendUrl, on } = props

    const dispatch = useDispatch()
    const { formatMessage: f } = useIntl()

    const maxlength = getMaxlength('component.Image.OutLink')

    function handleAppen() {
        const len = url ? url.length : 0

        if (len > maxlength) {
            dispatch({ 
                type: ALETSNACKBARSHOW, 
                message: f({id: 'component.Image.OutLink.Input.maxlength'},{ maxlength: numberFormat(maxlength), len: numberFormat(len) }), 
                variant: 'error',
                duration: 5000
            })
            return
        }

        on.append()
    }

    return (
        <>
        <Paper variant="outlined" style={{marginTop: 0}} >
            <WrapperStyled>
                <div className="input">
                    <InputStyled
                        value={url}
                        maxLength={maxlength}
                        onChange={(e) => on.change(e)} 
                        onKeyPress= {(e) => (e.key === 'Enter' && !hideAppendUrl) && handleAppen()} 
                        placeholder={f({id: `component.Image.OutLink.Input.url.${isVideo ? 1 : 0}`})}
                    />
                </div>
                {
                    !hideAppendUrl && (
                        <div className="button">
                            <Button  color="secondary" size="medium" component="span" onClick={handleAppen}>
                                <Typography variant="button">{f({id: 'component.Image.OutLink.Button.append'})}</Typography>
                            </Button>
                        </div>
                    )
                }
            </WrapperStyled>
        </Paper>
        </>
    )
}
