import gql from 'graphql-tag'

export const schemaSurveyQuestionModule = `
    _question {
        survey_question_no
        question
        pure_question
        survey_no
        img_src
        img_poster
        img_position
        img_name
        indexs
        survey_page_no
        number
        module_type
        question_type
        summary_question_open
        summary_comment_open
        visible
        confirm
    }

    ... on Module_01 {
        _modules {
            survey_module_no
            survey_question_no
            answer
            is_comment 
            indexs           
        }

        _option {
            survey_option_no       
            survey_question_no     
            required    
            random                 
            except_last_random     
            layout                 
            is_comment_required    
            is_comment_placeholder 
        }
    }


    ... on Module_02 {
        _modules {
            survey_module_no
            survey_question_no
            answer
            is_comment 
            indexs 
        }

        _option {
            survey_option_no
            survey_question_no
            required
            required_start
            required_end
            badge
            random
            except_last_random
            layout
            is_comment_required    
            is_comment_placeholder 
        }
    }

    ... on Module_03 {
        _modules {
            survey_module_no
            survey_question_no
            column
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            answer
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            random_column
            except_last_random_column
            random_answer
            except_last_random_answer
            is_comment
            is_comment_required    
            is_comment_placeholder
            mobile_matrix
        }
    }

    ... on Module_04 {
        _modules {
            survey_module_no
            survey_question_no
            column
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            answer
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            required_start
            required_end
            badge
            random_column
            except_last_random_column
            random_answer
            except_last_random_answer
            is_comment
            is_comment_required    
            is_comment_placeholder
            mobile_matrix
        }
    }

    ... on Module_05 {
        _modules {
            survey_module_no
            survey_question_no
            point
            indexs 
        }

        _option {
            survey_option_no
            survey_question_no
            required
            left_label
            right_label
            is_comment
            is_comment_required
            is_comment_placeholder
            reverse
            zero_number
            nps
        }
    }

    ... on Module_06 {
        _modules {
            survey_module_no
            survey_question_no
            column
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            point
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            left_label
            right_label
            random_column        
            except_last_random_column
            is_comment
            is_comment_required
            is_comment_placeholder
            reverse
            zero_number
            nps
        }
    }

    ... on Module_07 {
        _modules {
            survey_module_no
            survey_question_no
            answer
            point
            indexs 
        }

        _option {
            survey_option_no
            survey_question_no
            required
            is_comment
            is_comment_required
            is_comment_placeholder
            reverse
            zero_number
        }
    }

    ... on Module_08 {
        _modules {
            survey_module_no
            survey_question_no
            column
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            answer
            point
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            random_column        
            except_last_random_column
            is_comment
            is_comment_required
            is_comment_placeholder
            reverse
            zero_number
        }
    }

    ... on Module_09 {
        _option {
            survey_option_no
            survey_question_no
            required      
            badge    
            type              
            min_length        
            max_length        
            min_number        
            max_number        
            unit              
            national_flag     
            only_mobile
            number_format
            unit_position
            minus
            decimald
            date_format_type
            date_range
            date_start
        }
    }

    ... on Module_10 {
        _option {
            survey_option_no
            survey_question_no
            required          
        }
    }

    ... on Module_11 {
        _modules {
            survey_module_no
            survey_question_no
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            answer
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            badge
            random_answer
            is_comment
            is_comment_required    
            is_comment_placeholder 
        }
    }

    ... on Module_12 {
        _modules {
            survey_module_no
            survey_question_no
            answer
            src
            is_comment 
            indexs           
        }

        _option {
            survey_option_no       
            survey_question_no     
            required    
            random                 
            except_last_random     
            icon_type                 
            is_comment_required    
            is_comment_placeholder
            mobile_one
        }
    }


    ... on Module_13 {
        _modules {
            survey_module_no
            survey_question_no
            answer
            src
            is_comment 
            indexs 
        }

        _option {
            survey_option_no
            survey_question_no
            required
            required_start
            required_end
            badge
            random
            except_last_random
            icon_type
            is_comment_required    
            is_comment_placeholder
            mobile_one
        }
    }

    ... on Module_14 {
        _modules {
            survey_module_no
            survey_question_no
            answer
            point
            indexs 
        }

        _option {
            survey_option_no
            survey_question_no
            required
            icon_type
            is_comment
            is_comment_required
            is_comment_placeholder
        }
    }

    ... on Module_15 {
        _modules {
            survey_module_no
            survey_question_no
            column
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            answer
            point
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            icon_type
            random_column        
            except_last_random_column
            is_comment
            is_comment_required
            is_comment_placeholder
        }
    }

    ... on Module_16 {
        _modules {
            survey_module_no
            survey_question_no
            answer
        }

        _option {
            survey_option_no
            survey_question_no
            required
            required_message
            icon_type
        }
    }

    ... on Module_17 {
        _modules {
            survey_module_no
            survey_question_no
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            answer
            is_comment
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            required_start
            required_end
            badge
            random_answer
            except_last_random_answer
            is_comment_required    
            is_comment_placeholder 
        }
    }

    ... on Module_18 {
        _modules {
            survey_module_no
            survey_question_no
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            answer
            src
            is_comment
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            required_start
            required_end
            badge
            random_answer
            except_last_random_answer
            is_comment_required    
            is_comment_placeholder
            mobile_one
        }
    }

    ... on Module_19 {
        _option {
            survey_option_no
            survey_question_no
            required
            exts
            is_comment
            is_comment_required    
            is_comment_placeholder 
        }
    }

    ... on Module_20 {
        _option {
            survey_option_no
            survey_question_no
            required
            required_address2
        }
    }

    ... on Module_21 {
        _modules {
            survey_module_no
            survey_question_no
            answer
            indexs 
        }

        _option {
            survey_option_no
            survey_question_no
            required
            required_start
            required_end
            badge
            random
            except_last_random
            is_comment
            is_comment_required    
            is_comment_placeholder
            label
            kind
        }
    }

    ... on Module_22 {
        _modules {
            survey_module_no
            survey_question_no
            column
            indexs 
        }

        _answers {
            survey_module_answer_no
            survey_question_no
            answer
            indexs
        }

        _option {
            survey_option_no
            survey_question_no
            required
            required_start
            required_end
            badge
            random_column
            except_last_random_column
            random_answer
            except_last_random_answer
            is_comment
            is_comment_required    
            is_comment_placeholder
            label
            kind
        }
    }
`

export const GET_SURVEY_QUESTIONS_MODULE_ALL = gql`
    query SurveyQuestionsModuleAll($survey_no: Int!, $mode: Mode) {
        surveyQuestionsModuleAll(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyQuestionModule}
        }
    }
`

export const GET_SURVEY_QUESTION_MODULE = gql`
    query SurveyQuestionModule($survey_no: Int!, $survey_question_no: Int!) {
        surveyQuestionModule(survey_no: $survey_no, survey_question_no: $survey_question_no) {
            ${schemaSurveyQuestionModule}
        }
    }
` 


export const GET_SURVEY_QUESTIONS_MODULE = gql`
    query SurveyQuestionsModule($survey_no: Int!, $token: ID, $mode: Mode) {
        surveyQuestionsModule(survey_no: $survey_no, token: $token, mode: $mode) {
            ${schemaSurveyQuestionModule}
        }
    }
` 

export const GET_SURVEY_QUESTIONS_MODULE_ALL_ONE = gql`
    query SurveyQuestionsModuleAllOne($survey_no: Int!, $token: ID!, $mode: Mode) {
        surveyQuestionsModuleAllOne(survey_no: $survey_no, token: $token, mode: $mode) {
            ${schemaSurveyQuestionModule}
        }
    }
` 

export const GET_SURVEY_QUESTIONS_MODULE_SAMPLE = gql`
    query SurveyQuestionsModuleSample {
        surveyQuestionsModuleSample {
            ${schemaSurveyQuestionModule}
        }
    }
` 

export const GET_SURVEY_QUESTIONS_MODULE_All_PAGE_JUMP = gql`
    query SurveyQuestionsModuleAll($survey_no: Int!) {
        surveyQuestionsModuleAll(survey_no: $survey_no) {
            _question {
                survey_question_no
                pure_question
                survey_no
                indexs
                survey_page_no
                number
                module_type
            }
            
            ... on Module_01 {
                _modules {
                    survey_module_no
                    survey_question_no
                    answer
                    indexs           
                }
            }

            ... on Module_05 {
                _modules {
                    survey_module_no
                    survey_question_no
                    indexs 
                }
            }

            ... on Module_07 {
                _modules {
                    survey_module_no
                    survey_question_no
                    answer
                    indexs 
                }
            }

            ... on Module_12 {
                _modules {
                    survey_module_no
                    survey_question_no
                    answer
                    src
                    indexs           
                }
            }

            ... on Module_14 {
                _modules {
                    survey_module_no
                    survey_question_no
                    answer
                    indexs 
                }

                _option {
                    icon_type
                }
            }
        }
    }
`

export const UPDATE_SURVEY_QUESTION_00 = gql`
    mutation UpdateSurveyQuestion00($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!) {
        updateSurveyQuestion00(survey_no: $survey_no, inputQuestion: $inputQuestion)
    }
`

export const UPDATE_SURVEY_QUESTION_01 = gql`
    mutation UpdateSurveyQuestion01($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule01!]!, $inputOption: UpdateSurveyOption01!) {
        updateSurveyQuestion01(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_02 = gql`
    mutation UpdateSurveyQuestion02($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule02!]!, $inputOption: UpdateSurveyOption02!) {
        updateSurveyQuestion02(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_03 = gql`
    mutation UpdateSurveyQuestion03($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule03!]!, $inputModuleAnswers: [UpdateSurveyModuleAnswer03!]!, $inputOption: UpdateSurveyOption03!) {
        updateSurveyQuestion03(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_04 = gql`
    mutation UpdateSurveyQuestion04($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule04!]!, $inputModuleAnswers: [UpdateSurveyModuleAnswer04!]!, $inputOption: UpdateSurveyOption04!) {
        updateSurveyQuestion04(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_05 = gql`
    mutation UpdateSurveyQuestion05($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule05!]!, $inputOption: UpdateSurveyOption05!) {
        updateSurveyQuestion05(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_06 = gql`
    mutation UpdateSurveyQuestion06($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule06!]!, $inputModuleAnswers: [UpdateSurveyModuleAnswer06!]!, $inputOption: UpdateSurveyOption06!) {
        updateSurveyQuestion06(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_07 = gql`
    mutation UpdateSurveyQuestion07($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule07!]!, $inputOption: UpdateSurveyOption07!) {
        updateSurveyQuestion07(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_08 = gql`
    mutation UpdateSurveyQuestion08($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule08!]!, $inputModuleAnswers: [UpdateSurveyModuleAnswer08!]!, $inputOption: UpdateSurveyOption08!) {
        updateSurveyQuestion08(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`


export const UPDATE_SURVEY_QUESTION_09 = gql`
    mutation UpdateSurveyQuestion09($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputOption: UpdateSurveyOption09!) {
        updateSurveyQuestion09(survey_no: $survey_no, inputQuestion: $inputQuestion, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_10 = gql`
    mutation UpdateSurveyQuestion10($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputOption: UpdateSurveyOption10!) {
        updateSurveyQuestion10(survey_no: $survey_no, inputQuestion: $inputQuestion, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_11 = gql`
    mutation UpdateSurveyQuestion11($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: UpdateSurveyModule11!, $inputModuleAnswers: [UpdateSurveyModuleAnswer11!]!, $inputOption: UpdateSurveyOption11!) {
        updateSurveyQuestion11(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`


export const UPDATE_SURVEY_QUESTION_12 = gql`
    mutation UpdateSurveyQuestion12($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule12!]!, $inputOption: UpdateSurveyOption12!) {
        updateSurveyQuestion12(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_13 = gql`
    mutation UpdateSurveyQuestion13($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule13!]!, $inputOption: UpdateSurveyOption13!) {
        updateSurveyQuestion13(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_14 = gql`
    mutation UpdateSurveyQuestion14($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule14!]!, $inputOption: UpdateSurveyOption14!) {
        updateSurveyQuestion14(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_15 = gql`
    mutation UpdateSurveyQuestion15($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule15!]!, $inputModuleAnswers: [UpdateSurveyModuleAnswer15!]!, $inputOption: UpdateSurveyOption15!) {
        updateSurveyQuestion15(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_16 = gql`
    mutation UpdateSurveyQuestion16($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule16!]!, $inputOption: UpdateSurveyOption16!) {
        updateSurveyQuestion16(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_17 = gql`
    mutation UpdateSurveyQuestion17($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: UpdateSurveyModule17!, $inputModuleAnswers: [UpdateSurveyModuleAnswer17!]!, $inputOption: UpdateSurveyOption17!) {
        updateSurveyQuestion17(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_18 = gql`
    mutation UpdateSurveyQuestion18($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: UpdateSurveyModule18!, $inputModuleAnswers: [UpdateSurveyModuleAnswer18!]!, $inputOption: UpdateSurveyOption18!) {
        updateSurveyQuestion18(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_19 = gql`
    mutation UpdateSurveyQuestion19($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputOption: UpdateSurveyOption19!) {
        updateSurveyQuestion19(survey_no: $survey_no, inputQuestion: $inputQuestion, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_20 = gql`
    mutation UpdateSurveyQuestion20($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputOption: UpdateSurveyOption20!) {
        updateSurveyQuestion20(survey_no: $survey_no, inputQuestion: $inputQuestion, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_21 = gql`
    mutation UpdateSurveyQuestion21($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule21!]!, $inputOption: UpdateSurveyOption21!) {
        updateSurveyQuestion21(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputOption: $inputOption)
    }
`

export const UPDATE_SURVEY_QUESTION_22 = gql`
    mutation UpdateSurveyQuestion22($survey_no: Int!, $inputQuestion: UpdateSurveyQuestion!, $inputModules: [UpdateSurveyModule22!]!, $inputModuleAnswers: [UpdateSurveyModuleAnswer22!]!, $inputOption: UpdateSurveyOption22!) {
        updateSurveyQuestion22(survey_no: $survey_no, inputQuestion: $inputQuestion, inputModules: $inputModules, inputModuleAnswers: $inputModuleAnswers, inputOption: $inputOption)
    }
`


export type ModuleType = '_00' | '_01' | '_02' | '_03' | '_04' | '_05' | '_06' | '_07' | '_08' | '_09' | '_10' | '_11' | '_12' | 
                  '_13' | '_14' | '_15' | '_16' | '_17' | '_18' | '_19' | '_20' | '_21' | '_22'
export type ModuleTypeName = 'Module_00' | 'Module_01' | 'Module_02' | 'Module_03' | 'Module_04' | 'Module_05' | 'Module_06' | 'Module_07' | 'Module_08' | 'Module_09' | 'Module_10' | 
                'Module_11' | 'Module_12' | 'Module_13' | 'Module_14' | 'Module_15' | 'Module_16' | 'Module_17' | 'Module_18' | 'Module_19' | 'Module_20' | 'Module_21' | 'Module_22'


export type ModuleTypeQuestionName = Omit<ModuleTypeName, "Module_00" | "Module_16">

export type _01Module = {
    survey_module_no: number;
    survey_question_no: number;
    answer: string;
    is_comment: boolean; 
    indexs: number;           
}
export type _02Module = {
    survey_module_no: number;
    survey_question_no: number;
    answer: string;
    is_comment: boolean; 
    indexs: number;           
}
export type _03Module = {
    survey_module_no: number;
    survey_question_no: number;
    column: string;
    indexs: number;
}
export type _04Module = {
    survey_module_no: number;
    survey_question_no: number;
    column: string;
    indexs: number;
}
export type _05Module = {
    survey_module_no: number;
    survey_question_no: number;
    point: number;
    indexs: number; 
}
export type _06Module = {
    survey_module_no: number;
    survey_question_no: number;
    column: string;
    indexs: number;
}
export type _07Module = {
    survey_module_no: number;
    survey_question_no: number;
    answer: string;
    point: number;
    indexs: number;
}
export type _08Module = {
    survey_module_no: number;
    survey_question_no: number;
    column: string;
    indexs: number; 
    
}
export type _11Module = {
    survey_module_no: number;
    survey_question_no: number;
    indexs: number; 
}
export type _12Module = {
    survey_module_no: number;
    survey_question_no: number;
    answer: string;
    src: string;
    is_comment: boolean; 
    indexs: number;               
}
export type _13Module = {
    survey_module_no: number;
    survey_question_no: number;
    answer: string;
    src: string;
    is_comment: boolean; 
    indexs: number;               
}
export type _14Module = {
    survey_module_no: number;
    survey_question_no: number;
    answer: string;
    point: number;
    indexs: number; 
}
export type _15Module = {
    survey_module_no: number;
    survey_question_no: number;
    column: string;
    indexs: number; 
}
export type _17Module = {
    survey_module_no: number;
    survey_question_no: number;
    indexs: number; 
}
export type _18Module = {
    survey_module_no: number;
    survey_question_no: number;
    indexs: number; 
}

export type _21Module = {
    survey_module_no: number;
    survey_question_no: number;
    answer: string;
    indexs: number;           
}

export type _22Module = {
    survey_module_no: number;
    survey_question_no: number;
    column: string;
    indexs: number;
}

export type _03Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    answer: string;
    indexs: number;
}
export type _04Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    answer: string;
    indexs: number;
}
export type _06Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    point: number;
    indexs: number;
}
export type _08Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    answer: string;
    point: number;
    indexs: number;
}
export type _11Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    answer: string;
    indexs: number;
}
export type _15Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    answer: string;
    point: number;
    indexs: number;
}
export type _17Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    answer: string;
    is_comment: boolean;
    indexs: number;
}
export type _18Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    answer: string;
    src: string;
    is_comment: boolean;
    indexs: number;
}

export type _22Answer = {
    survey_module_answer_no: number;
    survey_question_no: number;
    answer: string;
    indexs: number;           
}

export type _01Option = {
    survey_option_no: number;       
    survey_question_no: number;     
    required: boolean; 
    random: boolean;                 
    except_last_random: boolean;     
    layout: number;                 
    is_comment_required: boolean;    
    is_comment_placeholder: string; 
}
export type _02Option = {
    survey_option_no: number; 
    survey_question_no: number; 
    required: boolean;
    required_start: number;
    required_end: number;
    random: boolean;
    except_last_random: boolean;
    layout: number;
    is_comment_required: boolean;    
    is_comment_placeholder: string;
}
export type _03Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    random_column: boolean;
    except_last_random_column: boolean;
    random_answer: boolean;
    except_last_random_answer: boolean;
    is_comment: boolean;
    is_comment_required: boolean;    
    is_comment_placeholder: string;
    mobile_matrix: boolean;
}
export type _04Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    required_start: number;
    required_end: number;
    random_column: boolean;
    except_last_random_column: boolean;
    random_answer: boolean;
    except_last_random_answer: boolean;
    is_comment: boolean;
    is_comment_required: boolean;   
    is_comment_placeholder: string; 
    mobile_matrix: boolean;
}
export type _05Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    left_label: string;
    right_label: string;
    is_comment: boolean;
    is_comment_required: boolean;
    is_comment_placeholder: string;
    nps: boolean;
    reverse: boolean;
    zero_number: boolean;
}
export type _06Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    left_label: string;
    right_label: string;
    random_column: boolean;        
    except_last_random_column: boolean;
    is_comment: boolean;
    is_comment_required: boolean;
    is_comment_placeholder: string;
    reverse: boolean;
    zero_number: boolean;
}
export type _07Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    is_comment: boolean;
    is_comment_required: boolean;
    is_comment_placeholder: string;
    reverse: boolean;
    zero_number: boolean;
}
export type _08Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    random_column: boolean;        
    except_last_random_column: boolean;
    is_comment: boolean;
    is_comment_required: boolean;
    is_comment_placeholder: string;
    reverse: boolean;
    zero_number: boolean;
}
export type _09Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;          
    type: string;              
    min_length: number;        
    max_length: number;        
    min_number: number;        
    max_number: number;        
    unit: string;              
    national_flag: any;     
    only_mobile: boolean;
    number_format: boolean;
    unit_position: boolean;
    minus: boolean;
    decimald: boolean;
    date_format_type: string;
    date_range: string;
    date_start: string;
}
export type _10Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
}
export type _11Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    random_answer: boolean;
    is_comment: boolean;
    is_comment_required: boolean;    
    is_comment_placeholder: string; 
}
export type _12Option = {
    survey_option_no: number;       
    survey_question_no: number;     
    required: boolean;    
    random: boolean;                 
    except_last_random: boolean;     
    icon_type: string;                 
    is_comment_required: boolean;    
    is_comment_placeholder: string; 
    mobile_one: boolean;
}
export type _13Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    required_start: number;
    required_end: number;
    random: boolean;
    except_last_random: boolean;
    icon_type: string;
    is_comment_required: boolean;    
    is_comment_placeholder: string;
    mobile_one: boolean;
}
export type _14Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    icon_type: string;
    is_comment: boolean;
    is_comment_required: boolean;
    is_comment_placeholder: string;
}
export type _15Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    icon_type: string;
    random_column: boolean;        
    except_last_random_column: boolean;
    is_comment: boolean;
    is_comment_required: boolean;
    is_comment_placeholder: string;
}
export type _17Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    required_start: number;
    required_end: number;
    random_answer: boolean;
    except_last_random_answer: boolean;
    is_comment_required: boolean;    
    is_comment_placeholder: string; 
}
export type _18Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    required_start: number;
    required_end: number;
    random_answer: boolean;
    except_last_random_answer: boolean;
    is_comment_required: boolean;    
    is_comment_placeholder: string;
    mobile_one: boolean;
}

export type _19Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    exts: string[] | null;
    is_comment: boolean;
    is_comment_required: boolean;
    is_comment_placeholder: string;
}

export type _20Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    required_address2: boolean;
}


export type _21Option = {
    survey_option_no: number; 
    survey_question_no: number; 
    required: boolean;
    required_start: number;
    required_end: number;
    random: boolean;
    except_last_random: boolean;
    is_comment: boolean;
    is_comment_required: boolean;    
    is_comment_placeholder: string;
    label: string;
    kind: boolean;
}

export type _22Option = {
    survey_option_no: number;
    survey_question_no: number;
    required: boolean;
    required_start: number;
    required_end: number;
    random_column: boolean;
    except_last_random_column: boolean;
    random_answer: boolean;
    except_last_random_answer: boolean;
    is_comment: boolean;
    is_comment_required: boolean;   
    is_comment_placeholder: string; 
    label: string;
    kind: boolean;
}

export type Answer = _03Answer | _04Answer | _06Answer | _08Answer | _11Answer | _15Answer | _17Answer | _18Answer | _22Answer

export type Module =  _01Module | _02Module | _03Module | _04Module | _05Module | _06Module | _07Module | _08Module | 
                _11Module | _12Module | _13Module | _14Module | _15Module | _17Module | _18Module | _21Module | _22Module

export type ModuleBase = _01Module | _02Module |  _07Module  | _14Module | _21Module
export type ModuleImg = _12Module | _13Module
export type ModuleMatrix = _03Module | _04Module | _08Module | _15Module | _22Module
export type ModuleRank = _11Module | _17Module | _18Module

export type AnswerMatrix = _03Answer | _04Answer | _08Answer | _15Answer | _22Answer
export type AnswerRank = _11Answer | _17Answer
export type AnswerRankImg = _18Answer

type Option = _01Option | _02Option | _03Option | _04Option | _05Option | _06Option | _07Option | _08Option | _09Option | 
              _10Option | _11Option | _12Option | _13Option |  _14Option |  _15Option |  _17Option |  _18Option | _19Option | _20Option | _21Option | _22Option 

export enum QuestionType {
    Question='question',
    Explain='explain'
}

export interface SurveyQuestionModuleProps {
    _question: {
        survey_question_no: number;
        question: string;
        pure_question: string;
        survey_no: number;
        img_src: string;
        img_poster: string;
        img_position: string;
        img_name: string;
        indexs: number;
        survey_page_no: number;
        number: number;
        module_type: ModuleType;
        question_type: QuestionType;
        summary_question_open: boolean;
        summary_comment_open: boolean;
        visible: boolean;
        confirm: string | boolean;
    };
    _modules: Module[];
    _answers: Answer[];
    _option: Option;
    __typename: ModuleTypeName;
}