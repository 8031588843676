import fixedWidthString from 'fixed-width-string'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import moment from 'moment'
import sortArray from 'sort-array'
import { getPercent, numberFormat, getPptLayout } from 'utils'
import { getMimePicture } from 'utils/survey'
import ordinal from 'ordinal'
import oc from 'open-color'
import youtubeEmbed from 'youtube-embed'
import pptxgen from 'pptxgenjs'
import { getScaleInsight } from 'styled/Survey/Analysis/SummaryNpsCalcu'

export const getRGBA = (props) => {
    const { color, opacity } = props

    const [ r, g, b ] = color.split(',')

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const getSummaryTotal = {
    count: data => data.map(c => c.count).reduce((a, b) => a + b, 0),
    point: data => data.map(c => c.point).reduce((a, b) => a + b, 0)
}

export const changeOrder = {
    count: (props) => {
        const { data, order } = props

        const arg = order === 'answer' ? { by: 'i', order: 'asc' } : { by: 'count', order: 'desc' }
        sortArray(data, arg)
    },
    point: (props) => {
        const { data, order } = props

        const arg = order === 'answer' ? { by: 'i', order: 'asc' } : { by: 'point', order: 'desc' }
        sortArray(data, arg)
    },
    comment: (props) => {
        const { data, order } = props

        const arg = order === 'wdate' ? { by : 'wdate', order: 'desc' } : { by: 'comment', order: 'asc' }
        sortArray(data, arg)
    }
} 

// 모듈 별 타입
export const componentType = {
	_01: 'base',
	_02: 'base',
	_03: 'matrix',
	_04: 'matrix',
	_05: 'base',
	_06: 'matrix',
	_07: 'base',
	_08: 'matrix',
	_09: 'text',
	_10: 'text',
	_11: 'rank',
	_12: 'base',
	_13: 'base',
	_14: 'base',
	_15: 'matrix',
	_17: 'rank',
	_18: 'rank',
    _19: 'file',
	_20: 'text',
    _21: 'base'
}

// 문항종류 별로 섹션내용이 다르다 (일반형, 매트릭스형 )
export const getSection = props => {
    const { f, __typename } = props

    let text = []
    switch(__typename)  {
        case '_01':
        case '_02':
        case '_05':
        case '_07':
        case '_09':
        case '_10':
        case '_12':
        case '_13':
        case '_14':
        case '_21':
        case '_ending':
            text = [f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.text.0`})]
            break
        case '_03':
        case '_04':
        case '_06':
        case '_08':
        case '_11':
        case '_17':
        case '_18':
        case '_15':
            text = [f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.text.0`}), f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.text.1`})]
            break
        default:
    }

    let cols = []
    switch(__typename) {
        case '_01':
        case '_02':
        case '_12':
        case '_13':
        case '_21':
            cols = [f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.cols.0`})]
            break
        case '_05':
        case '_07':
        case '_14':
            cols = [`${f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.cols.0`})}(P)`]
            break
        case '_03':
        case '_04':
        case '_11':
        case '_17':
        case '_18':
            cols = [f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.cols.0`}), f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.cols.1`})]
            break
        case '_06':
        case '_08':
        case '_15':
            cols = [`${f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.cols.0`})}(P)`, `${f({id: `component.Survey.Create.Analysis.Summary.Item.Section.${__typename}.cols.1`})}(P)`]
            break
        default:
    }

    return { text, cols }
}

export class ppts {
    constructor() {
        this.method = 'one' // 헌개의 문항 다운로드, 전체 다운로드
        this.zip = new JSZip()
        this.pres = new pptxgen()

        this.options = {
            header: [{ align: 'left', fontSize: 9, fill: oc.gray[6], color: 'ffffff', margin: 5 }, { align: 'right', margin: 5, fontSize: 9, fill: oc.gray[6], color: 'ffffff' }],
            example: [{ align: 'left', fontSize: 9, margin: 5}, { align: 'right', fontSize: 9, margin: 5}],
            point: [{ align: 'left', fontSize: 9}, { align: 'right', fontSize: 9}],
            pointHeader: [{ align: 'left', fontSize: 9, fill: oc.gray[6], color: 'ffffff' }, { align: 'right', fontSize: 9, fill: oc.gray[6], color: 'ffffff' }],
            column: [{ align: 'left', fontSize: 12, bold: true, color: '000000', bullet: true}, { align: 'left', fontSize: 9}, { align: 'right', fontSize: 9}],
            pointColumn: [{ align: 'left', fontSize: 9, colspan: 5, fill: 'ffffff', color: '000000', margin: [10, 0, 5, 0]}, { align: 'left', fontSize: 9}, { align: 'right', fontSize: 9}],
            image: {  x: '25%', y: '25%', w: '50%', h: '50%' }
        }
    }

    init(props) {
        const { f, title, question, img_src, number, modules, answers, comments, order, section, option, __typename, questionType } = props
    
        this.f = f
        this.title = title
        this.question = question
        this.img_src = img_src
        this.img_mime = '' // image youtube video
        this.number = number
        this.modules = modules
        this.answers = answers
        this.comments = comments
        this.order = order
        this.__typename = __typename
        this.questionType = questionType
        this.section = section
        this.option = option
    }

    getIsImage(src) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(true)
            img.onerror = () => resolve(false)
            img.src = src
        })
    }

    makeNoMessage(props) {
        const { slide, type } = props
        const text = {
            image: 'NO IMAGE',
            youtube: 'Supports YouTube only'
        }

        slide.addText(text[type], { fontSize: 60, x: 1, y: '47%', w: 11.5, align: 'center' })
    }

    getOptionTable(props) {
        const { x, y, type='base', autoPageHeaderRows=1 } = props

        const colW = type === 'base' ? [9.1, 1.2, 1.2] : 
                     type === 'rank' ? [7.9, 1.2, 1.2, 1.2] : 
                     type === 'point' ? [6.7, 1.6, 1.6, 1.6] : 
                     type === 'label' ? [0.8, 4.95, 0.8, 4.95] : 
                     type === 'image' ? [1.3, 7.8, 1.2, 1.2] : 
                     type === 'scoreNpsPoint' ? [1, 2.6, 1, 2.6, 1.8, 2.5] : [9.1, 1.2, 1.2]
      
        return {
            x, y, w: 11.5, valign: 'm', fill: "fafafa", rowH: 0.3, colW,
            autoPage: true, autoPageRepeatHeader: true, autoPageHeaderRows, newSlideStartY: 1, autoPageLineWeight: 1,
            border: {type: 'solid', color: 'ffffff', pt: 1}
        }
    }

    getAnswer(props) {
        const { answer, cut=90 } = props
        return answer ? fixedWidthString(answer.replace(/\r\n/g, '').replace(/\n/g, ''), cut) : ''
    }

    makeQuestionNumber(props) {
        const { slide, number } = props
        let options = { x: 3.27, y: '3%', color: '000000', align: 'right', fontSize: 11 }
        slide.addText(`Question ${number}.`, options)
    }

    makeScorePoint(props) {
        const { slide, sum, avg, nps, option, x, y } = props
        const { detractors, promoters } = nps

        const nameOption = { align: 'left', fill: 'ffffff', color: '000000', fontSize: 12}
        const valueOption = { align: 'left', fill: 'ffffff', color: '000000', fontSize: 16, bold: true, padding: [0, 0, 0, 5]}

        const column = [
            {text: this.f({id: 'styled.Survey.Analysis.SummaryNpsCalcu.column.sum'}), options: nameOption},
            {text: sum === 0 ? '0' : numberFormat(sum), options: valueOption},
            {text: this.f({id: 'styled.Survey.Analysis.SummaryNpsCalcu.column.avg'}), options: nameOption},
            {text: sum === 0 ? '0' : avg, options: valueOption},
            {text: option.nps ? 'Net Promoter Score®' : '', options: nameOption},
            {text: option.nps ? sum === 0 ? '0' : (promoters[1] - detractors[1] === 0 ? '0' : (promoters[1] - detractors[1]).toFixed(1)) : '', options: valueOption}
        ]

        slide.addTable([column], this.getOptionTable({x, y, type: 'scoreNpsPoint'}))
    }

    makeScoreNps(props) {
        const { slide, nps, x, y } = props
        const { detractors, passives, promoters } = nps

        const nameOption = { align: 'left', fill: 'ffffff', color: '000000', fontSize: 10}
        const valueOption = { align: 'left', fill: 'ffffff', color: '000000', fontSize: 14, padding: [0, 0, 0, 5]}

        const detractorsValue = `${detractors[1]}% (${numberFormat(detractors[0])})`
        const passivesValue = `${passives[1]}% (${numberFormat(passives[0])})`
        const promotersValue = `${promoters[1]}% (${numberFormat(promoters[0])})`

        const column = [
            {text: this.f({id: 'styled.Survey.Analysis.SummaryNps.titleOnly.0'}), options: nameOption},
            {text: detractorsValue, options: valueOption},
            {text: this.f({id: 'styled.Survey.Analysis.SummaryNps.titleOnly.1'}), options: nameOption},
            {text: passivesValue, options: valueOption},
            {text: this.f({id: 'styled.Survey.Analysis.SummaryNps.titleOnly.2'}), options: nameOption},
            {text: promotersValue, options: valueOption}
        ]

        slide.addTable([column], this.getOptionTable({x, y, type: 'scoreNpsPoint'}))
    }

    getLabel() {
        let leftLabel = ''
        let rightLabel = ''

        if (this.__typename === '_05' || this.__typename === '_06') {
            leftLabel = this.option.left_label || (this.option.nps ? this.f({id: 'component.Survey.Modules.05.labelNps.0'}) : '')
            rightLabel = this.option.right_label || (this.option.nps ? this.f({id: 'component.Survey.Modules.05.labelNps.1'}) : '')
        }


        return [ leftLabel, rightLabel ]
    }

    async makeTitle(props) {
        const { masterName, slide, y=1 } = props
        const { question, number, img_src, img_mime } = this

        const x = 1

        let options = { x, y, color: '000000', bold: true, fontSize: 22 }

        if (masterName === 'ending') slide.addText(this.f({id: 'utils.analysis.header._ending.title'}), options)
        else slide.addText(`Question ${number}.`, options)

        options = { 
            x, y: (y + 0.4), w: 11.5, color: '000000', align: 'left', fontSize: 15, autoFit: true, breakLine: true, isTextBox: true, valign: 'top',
        }
        slide.addText(question, options)

        if (img_mime) {
            if (img_mime === 'image')  {
                const _slide = this.pres.addSlide({ masterName })
                
                if (img_src) _slide.addImage({...this.options.image, path: img_src })
                else  this.makeNoMessage({ slide: _slide, type: 'image' })
            } else if (img_mime === 'youtube') {
                const _slide = this.pres.addSlide({ masterName })

                const embed = youtubeEmbed(img_src)

                _slide.addMedia({...this.options.image, type: "online", link: `https:${embed}`})
            } else if (img_mime === 'video') {
                const _slide = this.pres.addSlide({ masterName })
                this.makeNoMessage({ slide: _slide, type: 'youtube' })   
            }
        }

        //slide.addText(fixedWidthString(text.replace(/\r\n/g, ' ').replace(/\n/g, ' '), cut), options)
    }

    makeSection(props) {
        const { slide, text, y } = props
        const options = { x: 0.94, y, color: '363636', align: 'left', fontSize: 12, bold: true }
        slide.addText(text, options)
    }

    makeCols(props) {
        const { slide, text, x=2.5, y } = props
        const options = { x, y, color: '4d4d4d', align: 'right', fontSize: 8 }
        slide.addText(text, options)
    }

    makeBar(props) {
        const { slide, data, header, x, y } = props
        
        const total = getSummaryTotal.count(data)

        changeOrder.count({ data, order: this.order })

        const _header = header.map((c, i) => ({
            text: c,
            options: this.options.header[i === 0 ? 0 : 1]
        }))

        const rows = data.map(c => {
            const per = getPercent(c.count, total)
            const answer = this.getAnswer({answer: c.answer, cut: 180})

            return [
                {
                    text: answer, 
                    options: this.options.example[0]
                },
                {
                    text: `${per.toFixed(1)}%`,
                    options: this.options.example[1]
                },
                {
                    text: c.count > 0 ? numberFormat(c.count) : '0',
                    options: this.options.example[1]
                }
            ]
        })

        slide.addTable([_header, ...rows], this.getOptionTable({x, y}))
    }

    makeBarMatrix(props) {
        const { masterName, slide, data, header, x, y } = props

        let _slide = slide
        
        let i =0
        for (const c of data) {
            i++
            let _y = y

            if (i > 1) {
                _slide = this.pres.addSlide({ masterName })
                _y = 1
            }
            
            const options = {
                ...this.options.column[0], x, y: _y
            }

            _slide.addText(this.getAnswer({answer: c.column}), options)

            const _header = header.map((c, i) => ({
                text: c,
                options: this.options.header[i === 0 ? 0 : 1]
            }))

            const total = getSummaryTotal.count(c.answers)

            changeOrder.count({ data: c.answers, order: this.order })

            const answers = c.answers.map(d => {
                const per = getPercent(d.count, total)
                const answer = this.getAnswer({answer: d.answer})
                return [
                    {
                        text: answer, 
                        options: this.options.column[1]
                    },
                    {
                        text: `${per.toFixed(1)}%`,
                        options: this.options.column[2]
                    },
                    {
                        text: d.count > 0 ? numberFormat(d.count) : '0',
                        options: this.options.column[2]
                    }
                ]
            })

            const rows = [_header, ...answers]
            
            _slide.addTable(rows, this.getOptionTable({x, y: (_y + .3), autoPageHeaderRows: 1}))
        }
    }

    makeBarPoint(props) {
        const { slide, data, header, x, y } = props

        const total = getSummaryTotal.count(data)

        // changeOrder[this.pointType]({ data, order: this.order })

        const _header = [
            [
                {
                    text: header[0],
                    options: this.options.pointHeader[0]
                },
                {
                    text: header[1],
                    options: this.options.pointHeader[1]
                },
                {
                    text: header[2],
                    options: this.options.pointHeader[1]
                },
                {
                    text: header[4],
                    options: this.options.pointHeader[1]
                }
            ]
        ]

        const [ leftLabel, rightLabel ] = this.getLabel()
        const rowsLen = data.length
        const rows = data.map((c, i) => {
            const per = getPercent(c.count, total)

            let answer = `[${c.indexs}]   ${c.answer || ''}`
            answer = this.getAnswer({answer: answer, cut: 60})

            if (this.__typename === '_05' || this.__typename === '_06') {
                if (i === 0) answer = `[${c.indexs}] ${leftLabel}`
                else if (i === rowsLen - 1) answer = `[${c.indexs}] ${rightLabel}`
            }

            return [
                {
                    text: answer, 
                    options: this.options.point[0]
                },
                {
                    text: `${per.toFixed(1)}%`,
                    options: this.options.point[1]
                },
                {
                    text: c.count > 0 ? numberFormat(c.count) : '0',
                    options: this.options.point[1]
                },
                {
                    text: c.point > 0 ? `${numberFormat(c.indexs * c.count)}` : '0',
                    options: this.options.point[1]
                }
            ]
        })

        slide.addTable([..._header, ...rows], this.getOptionTable({x, y, type: 'point'}))
    }

    makeBarPointMatrix(props) {
        const { masterName, slide, option, data, header, x, y } = props

        let _slide = slide
     
        let i =0

        let customOption = { ...option }

        for (const c of data) {
            i++
            let _y = y

            if (i > 1) {
                _slide = this.pres.addSlide({ masterName })
                _y = 1
            }
            
            const options = {
                ...this.options.column[0], x, y: _y
            }

            const { sum, avg, nps } = getScaleInsight(c.answers, customOption.nps)

            _slide.addText(this.getAnswer({answer: c.column}), options)

            this.makeScorePoint({ slide: _slide, sum, avg, nps, option: customOption, x: 1, y: _y + .3 })

            // 테이블 컬럼높이
            let columnY = .8
 
            if (customOption.nps) {
                this.makeScoreNps({ slide: _slide, nps, x: 1, y: _y + .7 })
                columnY = 1.2
            }
            

            const _header = [
                {
                    text: header[0],
                    options: this.options.pointHeader[0]
                },
                {
                    text: header[1],
                    options: this.options.pointHeader[1]
                },
                {
                    text: header[2],
                    options: this.options.pointHeader[1]
                },
                {
                    text: header[4],
                    options: this.options.pointHeader[1]
                }
            ]
            

            const total = getSummaryTotal.count(c.answers)

            // changeOrder[this.pointType]({ data: c.answers, order: this.order })


            const answersLen = c.answers.length

            const [leftLabel, rightLabel] = this.getLabel()

            const answers = c.answers.map((d, i) => {
                const per = getPercent(d.count, total)
                let answer = `[${d.indexs}] ${d.answer || ''}`
                
                if (this.__typename === '_05' || this.__typename === '_06') {
                    if (i === 0) answer = `[${d.indexs}] ${leftLabel}`
                    else if (i === answersLen - 1) answer = `[${d.indexs}] ${rightLabel}`
                }

                answer = this.getAnswer({answer})

                return [
                    {
                        text: answer, 
                        options: this.options.pointColumn[1]
                    },
                    {
                        text: `${per.toFixed(1)}%`,
                        options: this.options.pointColumn[2]
                    },
                    {
                        text: d.count > 0 ? numberFormat(d.count) : '0',
                        options: this.options.pointColumn[2]
                    },
                    {
                        text: d.point > 0 ? `${numberFormat(d.count * d.indexs)}` : '0',
                        options: this.options.pointColumn[2]
                    }
                ]
            })

            const rows = [_header, ...answers]
            
            _slide.addTable(rows, this.getOptionTable({x, y: (_y + columnY), autoPageHeaderRows: 1, type: 'point'}))
        }
    }


    makeBarRank(props) {
        const { slide, header, data, x, y } = props
        
        const total = getSummaryTotal.point(data)

        changeOrder.point({ data, order: this.order })

        const _header = header.map((c, i) => ({
            text: c,
            options: this.options.header[i === 0 ? 0 : 1]
        }))

        const rows = data.map((c, i) => {
            const per = getPercent(c.point, total)
            const answer = this.getAnswer({answer: c.answer})
            
            let obj = [
                {
                    text: answer, 
                    options: this.options.example[0]
                },
                {
                    text: `${per.toFixed(1)}%`,
                    options: this.options.example[1]
                },
                {
                    text: c.point > 0 ? `${numberFormat(c.point)}P` : '0P',
                    options: this.options.example[1]
                },
                {
                    text: c.deviation > 0 ? c.deviation.toFixed(1) : '0',
                    options: this.options.example[1]
                }
            ]

            // 이미지 Pick 순위형
            if (c.src !== undefined) {
                obj = [ { text: `IMAGE-${(i + 1)}`, options: this.options.example[0] }, ...obj ]
            }

            return obj
        })

        slide.addTable([_header, ...rows], this.getOptionTable({x, y, type: 'rank'}))
    }

    makeBarRankMatrix(props) {
        const { masterName, slide, data, header, x, y } = props
        
        let _slide = slide
     
        let i = 0
        for (const c of data) {
            i++
            let _y = y

            if (i > 1) {
                _slide = this.pres.addSlide({ masterName })
                _y = 1
            }
            
            const options = {
                ...this.options.column[0], x, y: _y
            }

            // 이미지 Pick 순위형은 이미지가 있다
            const answer = c.src !== undefined ? `IMAGE-${i} ${c.answer}` : c.answer

            _slide.addText(this.getAnswer({answer, cut: 80}), options)

            const _header = header.map((c, i) => ({
                text: c,
                options: this.options.header[i === 0 ? 0 : 1]
            }))

            const total = getSummaryTotal.count(c.ranks)

            changeOrder.count({ data: c.ranks, order: this.order })

            const answers = c.ranks.map(d => {
                const per = getPercent(d.count, total)
                const answer = ordinal(d.indexs)
                return [
                    {
                        text: answer, 
                        options: this.options.column[1]
                    },
                    {
                        text: `${per.toFixed(1)}%`,
                        options: this.options.column[2]
                    },
                    {
                        text: d.count > 0 ? numberFormat(d.count) : '0',
                        options: this.options.column[2]
                    }
                ]
            })
            
            const rows = [_header, ...answers]
            
            _slide.addTable(rows, this.getOptionTable({x, y: (_y + .3), autoPageHeaderRows: 1}))
        }
    }

    makeBarImage(props) {
        const { slide, data, header, x, y } = props
        
        const total = getSummaryTotal.count(data)

        changeOrder.count({ data, order: this.order })

        const _header = header.map((c, i) => ({
            text: c,
            options: this.options.header[i < 2 ? 0 : 1]
        }))

        const rows = data.map((c, i) => {
            const per = getPercent(c.count, total)
            const answer = this.getAnswer({answer: c.answer, cut: 180})

            return [
                {
                    text: `IMAGE-${(i + 1)}`, 
                    options: this.options.example[0]
                },
                {
                    text: answer, 
                    options: this.options.example[0]
                },
                {
                    text: `${per.toFixed(1)}%`,
                    options: this.options.example[1]
                },
                {
                    text: c.count > 0 ? numberFormat(c.count) : '0',
                    options: this.options.example[1]
                }
            ]
        })

        slide.addTable([_header, ...rows], this.getOptionTable({x, y, type: 'image'}))
    }

    makeImageModules(props) {
        const { masterName, slide, data } = props
        
        let _slide = slide
        let i = 0

        for (const c of data) {

            if (i > 0) {
                _slide = this.pres.addSlide({ masterName })
            }

            if (c.src) _slide.addImage({...this.options.image, path: c.src })
            else this.makeNoMessage({ slide: _slide, type: 'image' })

           _slide.addText(`IMAGE-${(i + 1)}`, { fontSize: 22, bold: true, x: 1, y: 1 })

           _slide.addText(this.getAnswer({answer: c.answer, cut: 150}), { fontSize: 12, x: 1, y: '82%', w: 11.5, align: 'center' })
            
            i++
        }
    }

    async changeImage() {
        // 질문내용에 들어가는 이미지 
        this.img_mime = getMimePicture(this.img_src)
        if (this.img_mime === 'image') {
            if (this.img_src.search('https://graphql') === 0) {
                this.img_src = ''
            }
            else {
                this.img_src = await(await this.getIsImage(this.img_src)) ? this.img_src.replace(process.env.REACT_APP_CF, process.env.REACT_APP_LISTO_CF) : ''
            } 
        }
     }
 
    async changeImageModules() {
        const modules = []

        for await (const c of this.modules) {
            if (c.src) {
                const isImage = await this.getIsImage(c.src)
                const obj = isImage ? c : {...c, src: ''}
                modules.push(obj)
            } else {
                modules.push(c)
            }
        }

        this.modules = [...modules]
    }

    async writeFile(name) {
      
        const pptBlob = await this.pres.write('blob')
        
        this.zip.file(`${name}.pptx`, pptBlob)

        return true 
    }


    archiveZip(name) {

        // 다운로드할 파일 개수 (1개이면 그냥 받고 이상이면 압축해서 받는다)
        const filenames = Object.keys(this.zip.files)

        if (filenames.length === 0) return

        if (filenames.length === 1) {
      

            this.zip.file(filenames[0]).async('blob').then(function (data) {

                FileSaver.saveAs(data, filenames[0])
            })


        } else {
            this.zip.generateAsync({type: 'blob'}).then(function(content) {
                FileSaver.saveAs(content, `${name}.zip`)
            })
        }
    }

    getHeader() {
        const { __typename, f } = this

        switch(__typename) {
            case '_01':
            case '_02':
            case '_21':
            case '_ending':
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`})
                    ]
                }
            case '_03':
            case '_04': 
                return {
                    column: [
                        f({id: `utils.analysis.header.${__typename}.column.0`}),
                        f({id: `utils.analysis.header.${__typename}.column.1`}),
                        f({id: `utils.analysis.header.${__typename}.column.2`})
                    ],
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`})
                    ]
                }
            case '_05':
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`}),
                        f({id: `utils.analysis.header.${__typename}.answer.4`})
                    ]
                }
            case '_06':
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`}),
                        f({id: `utils.analysis.header.${__typename}.answer.4`})
                    ],
                    column: [
                        f({id: `utils.analysis.header.${__typename}.column.0`}),
                        f({id: `utils.analysis.header.${__typename}.column.1`}),
                        f({id: `utils.analysis.header.${__typename}.column.2`}),
                        f({id: `utils.analysis.header.${__typename}.column.3`}),
                        f({id: `utils.analysis.header.${__typename}.column.4`})
                    ]
                }
            case '_07':
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`}),
                        f({id: `utils.analysis.header.${__typename}.answer.4`})
                    ]
                }
            case '_08':
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`}),
                        f({id: `utils.analysis.header.${__typename}.answer.4`})
                    ],
                    column: [
                        f({id: `utils.analysis.header.${__typename}.column.0`}),
                        f({id: `utils.analysis.header.${__typename}.column.1`}),
                        f({id: `utils.analysis.header.${__typename}.column.2`}),
                        f({id: `utils.analysis.header.${__typename}.column.3`}),
                        f({id: `utils.analysis.header.${__typename}.column.4`})
                    ]
                }
            case '_11': 
            case '_17': 
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`})
                    ],
                    column: [
                        f({id: `utils.analysis.header.${__typename}.column.0`}),
                        f({id: `utils.analysis.header.${__typename}.column.1`}),
                        f({id: `utils.analysis.header.${__typename}.column.2`})
                    ]
                }
            case '_18': 
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`}),
                        f({id: `utils.analysis.header.${__typename}.answer.4`})
                    ],
                    column: [
                        f({id: `utils.analysis.header.${__typename}.column.0`}),
                        f({id: `utils.analysis.header.${__typename}.column.1`}),
                        f({id: `utils.analysis.header.${__typename}.column.2`})
                    ]
                }
            case '_12':
            case '_13':
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`})
                    ]
                }
            case '_14':
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`}),
                        f({id: `utils.analysis.header.${__typename}.answer.4`})
                    ]
                }

            case '_15':
                return {
                    answer: [
                        f({id: `utils.analysis.header.${__typename}.answer.0`}),
                        f({id: `utils.analysis.header.${__typename}.answer.1`}),
                        f({id: `utils.analysis.header.${__typename}.answer.2`}),
                        f({id: `utils.analysis.header.${__typename}.answer.3`}),
                        f({id: `utils.analysis.header.${__typename}.answer.4`})
                    ],
                    column: [
                        f({id: `utils.analysis.header.${__typename}.column.0`}),
                        f({id: `utils.analysis.header.${__typename}.column.1`}),
                        f({id: `utils.analysis.header.${__typename}.column.2`}),
                        f({id: `utils.analysis.header.${__typename}.column.3`}),
                        f({id: `utils.analysis.header.${__typename}.column.4`})
                    ]
                }
    

            default:
            
        }
    }

    layoutIntro(props) {
        const { masterName } = props

        const { layout, defineSlideMaster } = getPptLayout()

        this.pres.layout = layout
        this.pres.defineSlideMaster({ ...defineSlideMaster, title: masterName  })
    }

    layoutQuestion(props) {
        const { masterName } = props // type => intro, question
        const { number, questionType } = this
        
        const { layout, defineSlideMaster } = getPptLayout()

        this.pres.layout = layout

        const text = questionType === 'explain' ? `Explain ${number}.` : questionType === 'question' ? `Question ${number}.` : 'Ending'

        this.pres.defineSlideMaster({
            ...defineSlideMaster,
            title: masterName,
            objects: [
                
                ...defineSlideMaster.objects,
                { text: { text, options: { x: .2, y: "97.67%", fontSize: 10 } } },
            ],
            slideNumber: { x: '90%', y: "96%", fontSize: 10 }
        })
    }

    // question{number} 슬라이드 추가
    addSlideTitle() {
        const { number, questionType } = this

        const masterName = questionType === 'explain' ? `explain${number}` : questionType === 'question' ? `question${number}` : 'ending'

        this.layoutQuestion({ masterName })
        
        let slide = this.pres.addSlide({ masterName })
 
        this.makeTitle({ masterName, slide })

        return masterName
    }

    async bar(masterName) {
        const { modules, section } = this
        
        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], x: 1, y: 1 })
        
        let header = this.getHeader()
        this.makeBar({ slide, data: modules, header: header.answer, x: 1, y: 1.4})
        
        return true
    }

    async barEnding(masterName) {
        const { modules, section } = this
        
        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], x: 1, y: 1 })
        
        let header = this.getHeader()
        
        this.makeBar({ slide, data: modules.map(c => ({ ...c, answer: c.pure_message })), header: header.answer, x: 1, y: 1.4})
        
        return true
    }

    async barMatrix(masterName) {
        const { modules, answers, section } = this
        
        const header = this.getHeader()

        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], x: 1, y: 1 })
        this.makeBarMatrix({ masterName, slide, data: modules, header: header.column, x: 1, y: 1.4 })


        slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[1], y: 1 })
        this.makeBar({ slide, data: answers, header: header.column, x: 1, y: 1.4 })
      
        return true
    }

    async barPoint(masterName) {
        const { modules, section, option } = this
        
        const header = this.getHeader()

        const { sum, avg, nps } = getScaleInsight(modules, false)

        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], y: 1 })
        this.makeScorePoint({ slide, sum, avg, nps, option: {...option}, x: 1, y: 1.4 })

        this.makeBarPoint({ slide, data: modules, header: header.answer, x: 1, y: 2 })
        
        return true
    }

    async barPointLabel(masterName) {
        const { modules, option, section } = this

        const header = this.getHeader()

        const { sum, avg, nps } = getScaleInsight(modules, option.nps)

        
        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], y: 1 })
        this.makeScorePoint({ slide, sum, avg, nps, option, x: 1, y: 1.4 })

        if (option.nps) {
            this.makeScoreNps({ slide, nps, x: 1, y: 1.8 })
            this.makeBarPoint({ slide, data: modules, header: header.answer, x: 1, y: 2.3 })
        } else {
            this.makeBarPoint({ slide, data: modules, header: header.answer, x: 1, y: 2 })
        }
        
        return true
    }

    async barPointMatrix(masterName) {
        const { modules, answers, section } = this

        const header = this.getHeader()

        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], y: 1 })
        this.makeBarPointMatrix({ masterName, slide, data: modules, header: header.answer, x: 1, y: 1.4 })

        slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[1], y: 1 })
        this.makeBarPoint({ slide, data: answers, header: header.column, x: 1, y: 1.4 })

        return true
    }

    async barPointMatrixLabel(masterName) {
        const { modules, answers, option, section } = this

        const header = this.getHeader()

        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], y: 1 })
   
        this.makeBarPointMatrix({ masterName, slide, option, data: modules, header: header.answer, x: 1, y: 1.4 })

        slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[1], y: 1 })

        this.makeBarPoint({ slide, data: answers, header: header.column, x: 1, y: 1.4 })
        
        return true
    }

    async barRank(masterName) {
        const { modules, answers, section } = this

        const header = this.getHeader()

        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], y: 1 })
        this.makeBarRank({ slide, header: header.answer, data: modules, x: 1, y: 1.4 })

        slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[1], y: 1 })
        this.makeBarRankMatrix({ masterName, slide, data: answers, header: header.column, x: 1, y: 1.4 })

        return true
    }

    async barDropdown(masterName) {
        const { modules, section } = this
        
        let slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], x: 1, y: 1 })
        
        let header = this.getHeader()
        this.makeBar({ slide, data: modules, header: header.answer, x: 1, y: 1.4})

        return true
    }


    async barImage(masterName) {
        const { modules, section } = this
        
        let slide = this.pres.addSlide({ masterName })
        this.makeImageModules({ masterName, slide, data: modules })
        
        const header = this.getHeader()

        slide = this.pres.addSlide({ masterName })
        this.makeSection({ slide, text: section[0], y: 1 })

        this.makeBarImage({ slide, data: modules, header: header.answer, x: 1, y: 1.4})
        
        return true
    }

    // 이미지 Pick 우선순위형에서 사용
    async barRankImage(masterName) {
        const { modules } = this

        let slide = this.pres.addSlide({ masterName })
        this.makeImageModules({ masterName, slide, data: modules })
    }

    intro() {
        
        const masterName = 'intro'

        this.layoutIntro({ masterName })

        let slide = this.pres.addSlide({ masterName })

        let options = { x: 1, y: 1, color: '000000', bold: true, fontSize: 23 }
        slide.addText('Summary', options)
        options = { x: 1, y: 1.5, color: '000000', bold: true, fontSize: 22, valign: 'top' }

        slide.addText(this.title, options)

        const today = moment().format('YYYY.MM.DD')
        options = { x: 1.2, y: 6, color: '000000', fontSize: 14 }
        slide.addText(today, options)
    }
    
    async doOne(name) {
        this.method = 'one'
        this.zip = new JSZip()
        this.pres = new pptxgen()

        await this.do()
        
        if (componentType[this.__typename] !== 'text') {
            await this.writeFile(name)
        }
    
        this.archiveZip(name)

        return true
    }


    async doAll(props) {
        this.method = 'all'

        const { f, title, items, order } = props

        this.intro()

        for (const item of items) {
            const { common, modules, answers, comments, option } = item
          
            const { question, question_type, img_src, number, module_type: __typename } = common

            if (__typename === '_ending') {
                if (modules.length  < 2) continue
            }
            
            const { text: sectionText } = getSection({ f, __typename })

            this.init({ f, title, question, questionType: question_type, img_src, number, modules, answers, comments, option, __typename, order, section: sectionText })
            await this.do()
        }

        const name = getDownloadTitle({ title })

        await this.writeFile(name)

        this.archiveZip(name)

        return Promise.resolve({ 
            count: Object.keys(this.zip.files).length 
        })
    }

    async do() {
        await this.changeImage()

        const masterName = this.addSlideTitle()

        switch(this.__typename) {
            case '_01':
            case '_02':
                this.bar(masterName)
                return true
            case '_03':
            case '_04':
                this.barMatrix(masterName)
                return true
            case '_05':
                this.barPointLabel(masterName)
                return true
            case '_07':
            case '_14':
                this.barPoint(masterName)
                return true
            case '_06':
                this.barPointMatrixLabel(masterName)
                return true
            case '_08':
            case '_15':
                this.barPointMatrix(masterName)
                return true
            case '_11':   
                this.barRank(masterName)
                return true
            case '_17':    
                this.barRank(masterName)
                return true
            case '_18':    
                this.barRankImage(masterName)
                this.barRank(masterName)
                return true
            case '_12':
            case '_13':
                await this.changeImageModules()
                await this.barImage(masterName)
                return true
            case '_21':
                this.barDropdown(masterName)
                return true
            case '_ending':
                this.barEnding(masterName)
                return true
            default:
        }
    }  
}

// 다운로드 시  파일 명
export function getDownloadTitle(props) {
    const { title, type } = props

    if (title && type) return `${title}_${type}`

    if (title) return title

    if (type) return type

    return type
}