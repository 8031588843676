/**
 * 언어 변경시 user_info에 저장한다. 언어값과 타입area값 저장한다. (해당값은 관리자 결제페이지 승인 취소 안내메일 보낼때 해당값에 맞게 시간 언어를 변경하여 보낸다)
 */
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { RootState } from 'reducer/types'
import { getTimezoneLang } from 'utils'
import { UPDATE_USER_INFO_LANG_TIMEAREA } from 'gql/user_info'

function UpdateLangTimeAreaComponent() {
    const language = useSelector((state:RootState) => state.lang)
    const { timearea } = getTimezoneLang()

    const [ updateUserInfoLangTimearea ] = useMutation(UPDATE_USER_INFO_LANG_TIMEAREA)
    
    useEffect(() => {
        function delay() {
            return new Promise((resolve, reject) => {
                setTimeout(resolve, 500)
            })
        }

        async function _do() {
            const { lang } = language 

            await delay()
        
            updateUserInfoLangTimearea({
                variables: {
                    lang,
                    timearea
                }
            })
        }

        if (language && timearea) {
            _do()
        }

    }, [language, timearea, updateUserInfoLangTimearea])

    return null
}

export default UpdateLangTimeAreaComponent