import { useContext } from 'react'
import { Paper, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import summaryContext from 'context/summary'
import { getRGBA } from 'utils/analysis'

// 문항 Box
const QuestionPaper = withStyles(theme => ({
    root: (props) => {
        const { index, background_color } = props

        return {
            backgroundColor: background_color || 'transparent',
            marginTop: index === 0 ? 0 : 25,
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 10
        }
    }
}))(props => <Paper {...props}/>)

const Mybox = withStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        maxWidth: 720,
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
           width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))(Box)

// viewMode : view => 요약페이지, edit: 배포페이지 수정화면, report: 사용자에게 공개하는 페이지
export default (props) => {
    const { index, children } = props
    const { viewMode, design } = useContext(summaryContext)

    const background_color = design.background_color ? getRGBA({color: design.background_color, opacity: (viewMode === 'view' ? 1 : 0)}) : ''

    return (
        <QuestionPaper index={index} background_color={background_color} elevation={0}>
            <Mybox>{children}</Mybox>
        </QuestionPaper>
    )
}