/**
 * 편집 페이지에서 수정된 이력이 있을경우, 이탈할때 한번더 묻는다.
 */

export const DO = 'survey/create/module/isEdit/DO'
export const INIT = 'survey/create/module/isEdit/INIT'
 
export interface Props {
    type: string;
    is: boolean;
}
 
const initState:Omit<Props, 'type'>  = {
    is: false
}
 
export default (state = initState, action:Omit<Props, 'is'>) => {
    switch(action.type) {
        case DO:
            return {
                is: true
            }
        case INIT:
            return {
                is: false
            }
        default:
            return state
    }
}