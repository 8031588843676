import Typography from '@material-ui/core/Typography'
import MuiListItem from '@material-ui/core/ListItem'
import MuiListItemIcon from '@material-ui/core/ListItemIcon'
import { withStyles } from '@material-ui/core/styles'

export const Title = withStyles(theme => ({
    root: {
        fontSize: 22,
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            fontWeight: 700
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            fontWeight: 700
        }
    }
}))(props => <Typography {...props}/>)


export const ListItem = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
            paddingLeft: 0
        }
    }
}))(MuiListItem)

export const ListItemIcon = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))(MuiListItemIcon)