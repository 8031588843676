export const SHOW = 'answerErrorPage/SHOW'
export const HIDE = 'answerErrorPage/HIDE'

const initState = {
    show: false,
    message: '',
    f: '',
    logoHide: false,
    iconType: '', // 아이콘 보여주고싶으면 입력 : private, error
    logoHref: '',
    buttonName: '',
    buttonHref: '',
    buttonHide: false
}

export default (state = initState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
                message: action.message,
                f: action.f,
                iconType: action.iconType || 'error',
                logoHide: typeof action.logoHide === 'boolean' ? action.logoHide : state.logoHide, 
                logoHref: action.logoHref,
                buttonName: action.buttonName,
                buttonHref: action.buttonHref,
                buttonHide: action.buttonHide || initState.buttonHide
            }
        case HIDE:
            return initState
        default:
            return state
    }
}