import { SurveyQuestionModuleProps } from "gql/survey_question_modules"
export const CHANGE = 'survey/create/module/copy/CHANGE'

type Number = number | ''

export interface Props {
    type: string;
    questions: SurveyQuestionModuleProps['_question'][];
    other_survey_no: Number;
    survey_page_no: Number;
    survey_question_no: Number;
}

const initState:Omit<Props, 'type'> = {
    questions: [],
    other_survey_no: '',
    survey_page_no: '',
    survey_question_no: '',
}

export default (state = initState, action: Props) => {
    switch(action.type) {
        case CHANGE:
            return {
                questions: action.questions,
                other_survey_no: action.other_survey_no,
                survey_page_no: action.survey_page_no,
                survey_question_no: action.survey_question_no,
            }
        default:
            return state
    }
}