import { useContext } from 'react'
import PropsContext from 'context/props'
import styled, { css } from 'styled-components'
import oc from 'open-color'
import { withBreakPoints } from 'hoc'
import { SurveyWorkspaceLeftHeadComponent, SurveyWorkspaceLeftListsComponent, SurveyWorkspaceLeftHelpComponent } from 'component'
import InfoComponent from 'component/Survey/Workspace/Left/Info/Component'
import ResultComponent from 'component/Survey/Workspace/Left/Utility/Result/Component'

const WrapperStyled = styled.div`
    /* 
    PC 일경우 레프트 메뉴가 기본적으로 보이기 때문(height 전체) height=defaultHeight 적용
    모바일(sm) 아래일경우 메뉴리스트가 안보이고 헤드만 보인다. height=initial
    모바일 메뉴 보이기 클릭시 다시 height높이를 전체로한다 height=
    */

    display: flex;
    height: ${props => props.defaultHeight};
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid ${oc.gray[3]};

    @media only screen and (max-width: 960px) {
        border-right: none;
        border-bottom: 1px solid ${oc.gray[2]};

        ${props => !props.openMobileLeft && css`
            height: initial;
        `}
    }
`

 // 선택된 selected가 있어도, 작업함이 변경되면 값이 없는게 되는거다
export const getSurveyWorkspaceNo = (rows, selected) => {
    if (rows.length === 0) return ''
    const is = rows.find(c => c.survey_workspace_no === selected)

    return  is ? selected : rows[0].survey_workspace_no
}


const Component = (props) => {
    const { openMobileLeft, workspaceRows, selected } = useContext(PropsContext)
    const { defaultHeight, breakpoints } = props
    const { sm } = breakpoints

    return (
        <WrapperStyled defaultHeight={defaultHeight} openMobileLeft={openMobileLeft}>
            {
                (!sm || (sm && openMobileLeft)) ? (
                    <>
                    <SurveyWorkspaceLeftListsComponent/>
                    <div>
                        <InfoComponent/>
                        <ResultComponent survey_workspace_no={getSurveyWorkspaceNo(workspaceRows, selected)}/>
                        <SurveyWorkspaceLeftHelpComponent/>
                    </div>
                    </>
                ) : (
                    <SurveyWorkspaceLeftHeadComponent/>
                )
            }
        </WrapperStyled>
    )
}

export default withBreakPoints(Component)