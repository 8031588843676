import { forwardRef, memo } from 'react'
import { Row1, Row2 } from 'styled/Survey/Base'
import Input from 'styled/Custom/Input'
import { getMaxlength } from 'policy'
import Answer from 'styled/Custom/Answer'
import RadioComponent from './Radio'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'

// comment일경우 랜더링 때문에 ref로 등록한다
export const InputRef = forwardRef((props, ref) => {
    const { disabled, placeholder, defaultValue, maxLength } = props
    return (
        <Input
            disabled={disabled}
            defaultValue={defaultValue}  
            placeholder={placeholder}
            boxshadow={1}
            inputRef={ref}
            inputProps={{maxLength}}
            multiline
            maxRows={5}
        />
    )
})

// 바로이동 (isJust일때 사용되는 애니메이션)
export function getAnimationClassName(bool) {
    return bool ? "animate__animated animate__headShake" : ""
}

function Item(props) {
    const { 
        survey_module_no, 
        answer, 
        is_comment, 
        selected,
        comment,
        is_comment_placeholder, 
        refComment,
        isJust,
        onChange 
    } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        <div key={`_01-${survey_module_no}`} className={`${getAnimationClassName(isJust)} ${selected ? 'selected' : ''}`}>
        <Row1 
            onClick={(e) => !isJust && onChange(e, survey_module_no, false)}
        >
            <div className="row1">
                <RadioComponent selected={selected} survey_module_no={survey_module_no}/>
            </div>
            <div className="row2">
                <label htmlFor={`_01-label-${survey_module_no}`}>
                    <Answer>{answer ? answer : f({id: 'component.Survey.Modules.01.answer'})}</Answer>
                </label>
            </div>
        </Row1>
    
        {is_comment && (
            <Row2> 
                <InputRef
                defaultValue={comment}
                maxLength={getMaxlength('component.Survey.Modules.01.comment')}
                disabled={!selected}  
                placeholder={is_comment_placeholder}
                ref={ref => refComment.current[survey_module_no] = ref}
                />
            </Row2>
        )} 
        </div>
        </>
    )
}

export default memo(Item, (prev, next) => {
    return  isEqual(prev.survey_module_no, next.survey_module_no) &&
            isEqual(prev.selected, next.selected) &&
            isEqual(prev.comment, next.comment) && 
            isEqual(prev.answer, next.answer) &&
            isEqual(prev.is_comment, next.is_comment) &&
            isEqual(prev.is_comment_placeholder, next.is_comment_placeholder) &&
            isEqual(prev.refComment, next.refComment) &&
            isEqual(prev.isJust, next.isJust) &&
            isEqual(prev.onChange, next.onChange)
})