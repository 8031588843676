import { memo, useCallback, useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { CHANGE as CHANGEORDER } from 'reducer/survey/workspace/order'
import { useQuery, useMutation } from '@apollo/react-hooks'
import sortArray from 'sort-array'
import { SurveyPreviewContainer } from 'container'
import { 
    SurveyWorkspaceBodyListComponent, 
    SurveyWorkspaceBodyListItemComponent, 
    SurveyWorkspaceBodyTitleComponent,
    SurveyWorkspaceBodyRemoveWorkspaceComponent, 
    SurveyWorkspaceBodyCreateSurveyComponent,
    SurveyWorkspaceBodyCopySurveyComponent,
    SurveyWorkspaceBodyMoveSurveyComponent,
    SurveyWorkspaceBodyUpdateSurveyTitleComponent,
    SurveyWorkspaceBodyRemoveSurveyComponent
} from 'component'
import { useIntl } from 'react-intl'
import { Container } from '@material-ui/core'
import { UPDATE_SURVEY_WORKSPACE_TITLE, DELETE_SURVEY_WORKSPACE } from 'gql/survey_workspace'
import { 
    GET_SURVEY_MBOX_DESIGNS_IN_WORKSPACE, 
    CREATE_SURVEY, 
    UPDATE_SURVEY_MBOX_TITLE, 
    DELETE_SURVEY_MBOX, 
    UPDATE_SURVEY_MBOX_WORKSPACE_NO,
    CREATE_SURVEY_COPY_ALL
} from 'gql/survey_mbox'

const initialState = {
    openCreateSurvey: false,
    openUpdateSurveyTitle: { open: false, survey_no: '', title: '' },
    openRemoveWorkspace: false,
    openRemoveSurvey: { open: false, survey_no: '' },
    openCopySurvey: { open: false, survey_no: '' },
    openMoveSurvey: { open: false, survey_no: '' },
    openPreview: { open: false, survey_no: '' }
}

function getOrderRows(rows, order) {
    const newRows = [...rows]

    let arg = {
        by: 'wdate',
        order: 'desc'
    }

    if (Number(order) === 1) {
        arg = {
            by: ['today_end_count', 'wdate'], 
            computed: {
                today_end_count: row => row.survey_analysis_user.today_end_count
            },
            order: ['desc', 'desc']
        }
    }

    sortArray(newRows, arg)

    return newRows
}

let updateSurveyWorkspaceTitleTimer = null

const Component = (props) => {
    const { workspaceRows, selected, onChangeSelected, refetchSurveyWorkspaces } = props
    
    const dispatch = useDispatch()

    const { lang } = useSelector(state => state.lang)
    const { value: order } = useSelector(state => state.surveyWorkspaceOrder)

    const history = useHistory()

    const { formatMessage: f } = useIntl()

    const [ openCreateSurvey, setOpenCreateSurvey ] = useState(initialState.openCreateSurvey)
    const [ openUpdateSurveyTitle, setOpenUpdateSurveyTitle ] = useState(initialState.openUpdateSurveyTitle)
    const [ openRemoveWorkspace, setOpenRemoveWorkspace ] = useState(initialState.openRemoveWorkspace)
    const [ openRemoveSurvey, setOpenRemoveSurvey ] = useState(initialState.openRemoveSurvey)
    const [ openCopySurvey, setOpenCopySurvey ] = useState(initialState.openCopySurvey)
    const [ openMoveSurvey, setOpenMoveSurvey ] = useState(initialState.openMoveSurvey)
    const [ openPreview, setOpenPreview ] = useState(initialState.openPreview)
    const [ rows, setRows ] = useState([])

    const { loading, refetch } = useQuery(GET_SURVEY_MBOX_DESIGNS_IN_WORKSPACE, {
        variables: { survey_workspace_no: selected },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setRows(getOrderRows(data.surveyMboxDesignsInWorkspace, order))
        }
    })

    // 설문 만들기
    const [ createSurvey ] = useMutation(CREATE_SURVEY, {
        onCompleted: (data) => {
            const survey_no = data.createSurvey
            dispatch({type: BACKDROPHIDE})
            dispatch({
                type: CHANGEORDER,
                value: 0
            })
            handleLocation({type: 'create', survey_no})
        },
        onError: async() => {
            dispatch({type: BACKDROPHIDE})
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    

    // 기존 설문 제목 변경
    const [ updateSurveyMboxTitle ] = useMutation(UPDATE_SURVEY_MBOX_TITLE, {
        onCompleted: (data) => {
            refetch()
            handleChangeOpenUodateSurveyTitle(initialState.openUpdateSurveyTitle)
            dispatch({type: BACKDROPHIDE})
        },
        onError: async() => {
            dispatch({type: BACKDROPHIDE})
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    // 타이틀 저장 graphql
    const [ updateSurveyWorkspaceTitle ] = useMutation(UPDATE_SURVEY_WORKSPACE_TITLE, {
        onCompleted: async () => {
            clearTimeout(updateSurveyWorkspaceTitleTimer)

            updateSurveyWorkspaceTitleTimer = setTimeout(() => {
                refetchSurveyWorkspaces() 
            }, 100)
        },
        onError: async() => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })



    // 작업함 삭제 graphql
    const [ deleteSurveyWorkspace ] = useMutation(DELETE_SURVEY_WORKSPACE, {
        onCompleted: async (data) => {
            setTimeout(() => {
                refetchSurveyWorkspaces()
            }, 100)
            setTimeout(() => {
                handleChangeOpenRemoveWorkspace(false)
                onChangeSelected(data.deleteSurveyWorkspace)             
            }, 200)            
        },
        onError: async() => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })



    // 설문 삭제 graphql
    const [ deleteSurveyMobx ] = useMutation(DELETE_SURVEY_MBOX, {
        onCompleted: (data) => {
            handleChangeOpenRemoveSurvey(initialState.openRemoveSurvey)
            refetchSurveyWorkspaces() 
            refetch() 
        },
        onError: async() => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    // 설문 복사
    const [ createSurveyCopyAll ] = useMutation(CREATE_SURVEY_COPY_ALL, {
        onCompleted: async(data) => {
            await Promise.all([
                handleChangeOpenCopySurvey(initialState.openCopySurvey),
                refetchSurveyWorkspaces(),
                refetch() 
            ])

            dispatch({
                type: CHANGEORDER,
                value: 0
             })

            dispatch({
               type: ALERTSNACKBARSHOW, variant: 'success', 
               message: f({id: 'container.Survey.Workspace.Body.copySurvey.success'})
            })
        },
        onError: async() => {
            dispatch({type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    // 작업함 이동
    const [ updateSurveyMboxWorkspaceNo ] = useMutation(UPDATE_SURVEY_MBOX_WORKSPACE_NO, {
        onCompleted: async(data) => {
            await Promise.all([
                handleChangeOpenMoveSurvey(initialState.openMoveSurvey),
                refetchSurveyWorkspaces(),
                refetch() 
            ])

            dispatch({
                type: ALERTSNACKBARSHOW, variant: 'success', 
                message: f({id: 'container.Survey.Workspace.Body.moveSurvey.success'})
            })
        },
        onError: () => {
            dispatch({type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    // 페이지 이동
    const handleLocation = useCallback((props) => {
        const { type, survey_no, hash } = props

        let loc = `/survey/form/${type}/${survey_no}`
        if (hash) loc = `${loc}#${hash}`
        
        history.push(loc)
    }, [history])


    // 타이틀 저장 함수
    const handleChangeSurveyWorkspaceTitle = useCallback((props) => {
        const { title, survey_workspace_no } = props
        updateSurveyWorkspaceTitle({
            variables: { title, survey_workspace_no }   
        })
    }, [updateSurveyWorkspaceTitle])


    // 추가 모달 (survey)
    const handleChangeOpenCreateSurvey = useCallback((open) => {
        setOpenCreateSurvey(open)
    }, [])

    // 추가 실행
    const handleCreateSurvey = useCallback((props) => {
        const { title, method } = props
        dispatch({type: BACKDROPSHOW})
        createSurvey({
            variables: { title, method, survey_workspace_no: selected, lang }
        })
    }, [dispatch, selected, lang, createSurvey])


    // 제목 변경 모달 (survey)
    const handleChangeOpenUodateSurveyTitle = useCallback((props) => {
        const { open, survey_no, title } = props
        setOpenUpdateSurveyTitle({ open, survey_no, title })
    }, [])

    // 제목 변경 실행
    const handleUpdateSurveyTitle = useCallback((props) => {
        const { survey_no, title } = props
        dispatch({type: BACKDROPSHOW})
        updateSurveyMboxTitle({
            variables: { survey_no, title }
        })
    }, [dispatch, updateSurveyMboxTitle])




    // 삭제 모달 open (workspace)
    const handleChangeOpenRemoveWorkspace = useCallback((open) => {
        setOpenRemoveWorkspace(open)
    }, [])

    // 삭제실행
    const handleRemoveWorkspace = useCallback(() => {
        deleteSurveyWorkspace({
            variables: { survey_workspace_no: selected }
        })
    }, [selected, deleteSurveyWorkspace])

   


    // 삭제 모달 (survey)
    const handleChangeOpenRemoveSurvey = useCallback((props) => {
        const { open, survey_no } = props

        setOpenRemoveSurvey({ open, survey_no })
    }, [])

    // 삭제 실행
    const handleRemoveSurvey = useCallback(async(survey_no) => {
        await dispatch({type: BACKDROPSHOW})
        deleteSurveyMobx({
            variables: { survey_no }
        })
    }, [dispatch, deleteSurveyMobx])




    // 미리보기 열기
    const handleChangeOpenPreview = useCallback((survey_no) => {
        setOpenPreview({open: true, survey_no})
    }, [])

    // 미리보기 닫기
    const handleChangeClosePreview = useCallback(() => {
        setOpenPreview(initialState.openPreview)
    }, [])


    // 설문 복사 모달
    const handleChangeOpenCopySurvey = useCallback(async(props) => {
        const { open, survey_no } = props
        setOpenCopySurvey({ open, survey_no})
    }, [])

    const handleCopySurvey = useCallback(async (props) => {
        const { survey_no, target_survey_workspace_no } = props
        await dispatch({type: BACKDROPSHOW })
        createSurveyCopyAll({
            variables: { survey_no, target_survey_workspace_no }
        })
    }, [createSurveyCopyAll, dispatch])


    // 설문 이동 모달
    const handleChangeOpenMoveSurvey = useCallback(async(props) => {
        const { open, survey_no } = props
        setOpenMoveSurvey({ open, survey_no})
    }, [])

    const handleMoveSurvey = useCallback(async(props) => {
        const { survey_no, target_survey_workspace_no } = props

        await dispatch({type: BACKDROPSHOW })
        
        updateSurveyMboxWorkspaceNo({
            variables: {
                survey_no, target_survey_workspace_no
            }
        })
    }, [updateSurveyMboxWorkspaceNo, dispatch])

    // 정렬값 바뀔때..
    useEffect(() => {
        setRows(prevState => {
            const newRows = getOrderRows(prevState, order)
            return newRows
        })
    }, [order])

    useEffect(() => {
        if (loading) dispatch({type: BACKDROPSHOW})
        else dispatch({type: BACKDROPHIDE}) 
    }, [loading, dispatch])


    const { title: defaultTitle, base, mbox_count } = workspaceRows.find(c => c.survey_workspace_no === selected) || { title: '', base: true, mbox_count: 0 }

    return (
        <>
        <Container>
            {
                useMemo(() => {
                    return (
                        <SurveyWorkspaceBodyTitleComponent 
                            defaultTitle={defaultTitle}
                            base={base}
                            selected={selected} 
                            onChangeSurveyWorkspaceTitle={handleChangeSurveyWorkspaceTitle}
                            onChangeOpenRemoveWorkspace={handleChangeOpenRemoveWorkspace}
                            onChangeSelected={onChangeSelected}
                            refetchSurveyWorkspaces={refetchSurveyWorkspaces}
                        />
                    )
                }, [defaultTitle, base, selected, handleChangeSurveyWorkspaceTitle, handleChangeOpenRemoveWorkspace, onChangeSelected, refetchSurveyWorkspaces])
            }
            
            {
                useMemo(() => {
                    return (
                        <SurveyWorkspaceBodyListComponent 
                            survey_workspace_no={selected}
                            mbox_count={mbox_count}
                            onChangeOpenCreateSurvey={handleChangeOpenCreateSurvey}
                            refetchSurveyWorkspaces={refetchSurveyWorkspaces}
                        >
                        {
                            rows.length === 0 ? null : rows.map(c => {
                                return (
                                    <SurveyWorkspaceBodyListItemComponent 
                                        key={c.survey_no} 
                                        survey_no={c.survey_no}
                                        title={c.title} 
                                        wdate={c.wdate}
                                        open={c.open}
                                        end_date={c.end_date}
                                        end_date_used={c.end_date_used}
                                        survey_mbox_design={c.survey_mbox_design}
                                        survey_analysis_user={c.survey_analysis_user}
                                        onLocation={handleLocation} 
                                        onChangeOpenPreview={handleChangeOpenPreview}
                                        onChangeOpenUodateSurveyTitle={handleChangeOpenUodateSurveyTitle}
                                        onChangeOpenRemoveSurvey={handleChangeOpenRemoveSurvey}
                                        onChangeOpenCopySurvey={handleChangeOpenCopySurvey}
                                        onChangeOpenMoveSurvey={handleChangeOpenMoveSurvey}
                                    />
                                )
                            })
                        }
                        </SurveyWorkspaceBodyListComponent>
                    )
                }, [
                    rows, 
                    selected,
                    workspaceRows,
                    handleChangeOpenCreateSurvey,
                    handleChangeOpenPreview, 
                    handleLocation, 
                    handleChangeOpenUodateSurveyTitle, 
                    handleChangeOpenRemoveSurvey, 
                    handleChangeOpenCopySurvey,
                    handleChangeOpenMoveSurvey
                ])
            }           
            {
                useMemo(() => {
                    return (
                        <SurveyWorkspaceBodyRemoveWorkspaceComponent 
                            open={openRemoveWorkspace} 
                            onClose={() => handleChangeOpenRemoveWorkspace(false)} 
                            onRemove={handleRemoveWorkspace}
                        />
                    )
                }, [openRemoveWorkspace, handleChangeOpenRemoveWorkspace, handleRemoveWorkspace])
            }
            {
                useMemo(() => {
                    return (
                        <SurveyWorkspaceBodyCreateSurveyComponent 
                            open={openCreateSurvey} 
                            onClose={() => handleChangeOpenCreateSurvey(false)} 
                            onCreate={handleCreateSurvey}
                        />
                    )
                }, [openCreateSurvey, handleChangeOpenCreateSurvey, handleCreateSurvey])
            }
            {
                useMemo(() => {
                    return (
                        <SurveyPreviewContainer open={openPreview.open} survey_no={openPreview.survey_no} onClose={handleChangeClosePreview}/>
                    )
                }, [openPreview, handleChangeClosePreview])
            }
            {
                useMemo(() => {
                    return (
                        <SurveyWorkspaceBodyUpdateSurveyTitleComponent
                            open={openUpdateSurveyTitle.open} 
                            survey_no={openUpdateSurveyTitle.survey_no}
                            title={openUpdateSurveyTitle.title}
                            onClose={() => handleChangeOpenUodateSurveyTitle(initialState.openUpdateSurveyTitle)} 
                            onUpdate={handleUpdateSurveyTitle}
                        />
                    )
                }, [openUpdateSurveyTitle, handleChangeOpenUodateSurveyTitle, handleUpdateSurveyTitle])
            }
            {
                useMemo(() => {
                    return (
                        <SurveyWorkspaceBodyRemoveSurveyComponent 
                            open={openRemoveSurvey.open} 
                            survey_no={openRemoveSurvey.survey_no}
                            onClose={() => handleChangeOpenRemoveSurvey(initialState.openRemoveSurvey)} 
                            onRemove={handleRemoveSurvey}
                        />
                    )
                }, [openRemoveSurvey, handleChangeOpenRemoveSurvey, handleRemoveSurvey])
            }
            {
                useMemo(() => {
                    return (
                        <SurveyWorkspaceBodyCopySurveyComponent 
                            workspaceRows={workspaceRows}
                            selected={selected}
                            open={openCopySurvey.open} 
                            survey_no={openCopySurvey.survey_no}
                            onClose={() => handleChangeOpenCopySurvey(initialState.openCopySurvey)} 
                            onCopy={handleCopySurvey}
                        />
                    )
                }, [workspaceRows, selected, openCopySurvey, handleChangeOpenCopySurvey, handleCopySurvey])
            }
            {
                useMemo(() => {
                    return (
                        <SurveyWorkspaceBodyMoveSurveyComponent 
                            workspaceRows={workspaceRows}
                            selected={selected}
                            open={openMoveSurvey.open} 
                            survey_no={openMoveSurvey.survey_no}
                            onClose={() => handleChangeOpenMoveSurvey(initialState.openMoveSurvey)} 
                            onMove={handleMoveSurvey}
                        />
                    )
                }, [workspaceRows, selected, openMoveSurvey, handleChangeOpenMoveSurvey, handleMoveSurvey])
            }
        </Container>
        </>
    )
}

export default memo(Component)