import { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { CREATE_SURVEY_IN_CATEGORY, Method } from 'gql/survey_mbox'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CreateSurveyComponent from 'component/Survey/Workspace/Body/CreateSurvey'
import { Selected } from './Publish'

interface Props {
    selected: Selected
    refetch: () => void
}

const MyCard = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
})(Card)

function AddComponent(props: Props) {
    const { selected, refetch } = props

    const history = useHistory()

    const [ open, setOpen ] = useState(false)

    const { lang } = useSelector((state: RootState) => state.lang)

    const [ create ] = useMutation(CREATE_SURVEY_IN_CATEGORY, {
        onCompleted: (data) => {
            refetch()

            setTimeout(() => {
                const loc = `/survey/form/create/${data.createSurveyInCategory}`
                history.push(loc)
            }, 100)
        }
    })

    const handleOpen = useCallback(() => {
        if (selected === 0) {
            window.alert('카테고리 선택하세요')
            return
        }
        
        setOpen(true)
    }, [selected])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleCreate = useCallback((props: { title: string, method: Method}) => {
        const { title, method } = props
        create({
            variables: { title, method, survey_template_category_no: selected, lang }
        })
    }, [create, selected, lang])

    return (
        <>
        <MyCard variant="outlined" onClick={handleOpen}>
            <IconButton>
                <AddIcon fontSize="large"/>
            </IconButton>
        </MyCard>
        <CreateSurveyComponent open={open} onCreate={handleCreate} onClose={handleClose}/>
        </>
        
    )
}

export default AddComponent