import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import withFetchSurveyTemplateCategory, { SurveyTemplateCategoryProps } from 'hoc/Survey/FetchTemplateCategory'
import CategoryManageComponent from "./CategoryManage"
import PublishComponent from './Publish'

export interface ParentProps {
    data: SurveyTemplateCategoryProps['surveyTemplateCategory']['data']
    refetch: SurveyTemplateCategoryProps['surveyTemplateCategory']['refetch']
}

function ListComponent(props: SurveyTemplateCategoryProps) {
    const { surveyTemplateCategory } = props
    const { data, refetch } = surveyTemplateCategory

    return (
        <>
        <Container maxWidth="md">
            <CategoryManageComponent data={data} refetch={refetch}/>
        </Container>
        <Divider  style={{marginTop: 50, marginBottom: 50}}/>
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <PublishComponent data={data} edit={true}/>
            </Grid>
        </Container>
        </>
    )
}

export default withFetchSurveyTemplateCategory('cache-first')(false)(ListComponent)

