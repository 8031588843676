import { memo } from 'react'
import { useMutation } from '@apollo/client'
import { Method } from 'gql/survey_mbox'
import { DELETE_SURVEY_ANALYSIS_MODULE_19 } from 'gql/survey_analysis_modules'
import FileUploadPreviewBox from 'styled/Custom/FileUploadPreviewBox'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { Format19 } from 'hoc/Survey/SetReplys'
import { deleteFile } from './S3'
import { OnChange } from './Component'
import PreviewMyBox from './PreviewMyBox'
import PictureComponent from './Picture'
import IconComponent from './Icon'
import FileNameComponent from './FileName'

interface Props {
    survey_no: number;
    mode: string; 
    method: Method;
    token: string;
    reply: Format19;
    onRemove: OnChange;
}

const Wrapper = withStyles(theme => ({
    root: {
        width: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: 5
        }
    }
}))(Box)

function Preview(props: Props) {
    const { survey_no, mode, method, token, reply, onRemove } = props
    const { survey_question_no, src, filename, filetype } = reply

    const [ remove ] = useMutation(DELETE_SURVEY_ANALYSIS_MODULE_19, {
        onCompleted: () => {
            deleteFile({ mode, survey_no, srcs: [src] })
        }
    })

    const customSrc = typeof src === 'string' ? src : src === null ? '' : URL.createObjectURL(Object.assign(src))

    const handleRemove = async() => {
        // 1문 1답이면서, 이미 저장된 값이 있다면 db, file을 지운다
        if (method === Method.One) {
            if (typeof src === 'string') {
                remove({
                    variables: {
                        survey_no,
                        mode,
                        token,
                        survey_question_no
                    }
                })
            }
        } 

        onRemove("")
    }

    return (
        <FileUploadPreviewBox>
            <Wrapper>
                <PreviewMyBox>
                    <div style={{padding: 1}}>
                    { 
                        /image/.test(filetype) ? <PictureComponent blob={customSrc} name={filename}/> : <IconComponent type={filetype}/>
                    }
                    </div>
                    <FileNameComponent name={filename} onRemove={handleRemove}/>
                </PreviewMyBox>
            </Wrapper>
        </FileUploadPreviewBox>
    )
}

export default memo(Preview)