import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SurveyStartProps } from 'gql/survey_start'
import { withSurveyFetchData } from 'hoc'
import { SurveyModulesStartComponent } from 'component'

interface Props {
    onChange: () => void;
    propsFetchData: {
        start: SurveyStartProps     
    }
}

function StartComponent(props: Props) {
    const { propsFetchData, onChange } = props
    const { start } = propsFetchData

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: BACKDROPHIDE })
    }, [dispatch])
    
    return <SurveyModulesStartComponent row={start} onChange={onChange}/>
}

export default withSurveyFetchData('live')({ start: true })(false)(StartComponent)