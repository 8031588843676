import { ChromePicker, SwatchesPicker } from 'react-color'

// 좌측 메뉴 다음에 나오는 서브메뉴 or 편집화면에 들어가는 ㅋ
export default (props) => {
    const { type='picker', color, onChange, onChangeComplete } = props
    
    return (
        <>
        {
            type === 'picker' ? (
                <ChromePicker color={color} onChange={onChange} onChangeComplete={onChangeComplete}/> 
            ) : (
                <div style={{padding: 10}}>
                    <SwatchesPicker color={color} onChange={onChange} onChangeComplete={onChangeComplete}/>
                </div>
            )
        }
        </>
        
    )
}