import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import oc from 'open-color'

const WrapperStyled = styled.div`
    
    & > div.main {
        padding: 3px 10px;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
    }

    & > div.main.customDesign {
        padding: 6px 10px;
    }

    & > div.main > div:first-child {
        width: 100%;
    }
  
    & > div.sub {
        height: 0;
        overflow: hidden;

        opacity: 0;
    }
    & > div.sub.open {
        background: ${oc.gray[1]};
        padding: 3px 10px 3px 40px;
        opacity: 1;
    }

    & > div.sub.open.last {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    & > div.line {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid ${oc.gray[2]};
    }

    & > div.sub.open.random {
        display: flex;
        align-items: center;
        height: 100%;
    }

    & > div.sub.layout {
        display: flex;
        align-items: center;
        height: 100%;
    }
    
    & > div.sub.open.iconType {
        display: flex;
        align-items: center;
        padding: 10px 0 10px 8px;
        height: 100%;
    }

    & > div.sub.open.buttonGroup {
        display: flex;
        align-items: center;
        padding: 10px 0 10px 20px;
        height: 100%;
    }

    & > div.sub.open.design {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1em;
    }

    & > div.sub.isComment {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        & > div:last-child {
            margin-top: 1px;
            margin-bottom: 10px;
        }
    }


    & > div.sub.required {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 10px;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    & > div.sub.open.branding {
        display: flex;
        align-items: center;
        height: 100%;
    }

`
export default ({children}) => (
    <Paper variant='outlined'>
        <WrapperStyled>
            {children}
        </WrapperStyled>
    </Paper>
)