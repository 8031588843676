import { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CREATE_USER_ADMIN_ALARM_COOKIE, GET_USER_ADMIN_ALARM, CreareUserAdminAlarmCookieVariables } from 'gql/admin_alarm'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import { withStyles } from '@material-ui/core/styles'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone'
import styled from 'styled-components'
import HtmlParser from 'react-html-parser'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    breakpoints: BreakPointsProps;
}

interface AlarmProps {
    link: string | null;
}

const MyBox = withStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: 35,
        right: 55,
        zIndex: 10000000,
        maxWidth: 350,
        minHeight: 100,
        [theme.breakpoints.down('xs')]: {
            bottom: 15,
            right: 15
        }
    }
}))(Box)

const SubBox = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *:nth-child(2)': {
            marginTop: 25,
            marginRight: 10
        }
    }
})(Box)

const Alarm = styled.div<AlarmProps>`
    position: relative;
    background: rgba(173, 181, 189, .9);
    border-radius: .4em;
    padding: 15px;
    padding-top: 24px;
    color: black;
    font-size: 14px;
    cursor: ${props => props.link ? 'pointer' : 'text'};
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top-color: rgba(173, 181, 189, .9);
        border-bottom: 0;
        border-left: 0;
        margin-left: -10px;
        margin-bottom: -20px;
    }
`

function AlarmComponent(props: Props) {
    const { breakpoints } = props
    const { xs } = breakpoints

    const [ open, setOpen ] = useState(false)

    const [ createUserAdminCookie ] = useMutation<CreareUserAdminAlarmCookieVariables>(CREATE_USER_ADMIN_ALARM_COOKIE, {
        onCompleted: () => {
            setOpen(false)
        },
        onError: () => {
            setOpen(false)
        }
    })
    
    const [getUserAdminCookie, { data }] = useLazyQuery(GET_USER_ADMIN_ALARM, {
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        setTimeout(() => {
            getUserAdminCookie()
        }, 300)
    }, [getUserAdminCookie])

    useEffect(() => {
        setOpen(Boolean(data))
    }, [data])

    if (!data) return null

    const { userAdminAlarm } = data
    
    if (!userAdminAlarm) return null

    const { admin_alarm_no, message, link } = userAdminAlarm

    function handleClick() {
        if (link) {
            window.open(link, '_blank')
            createUserAdminCookie({
                variables: {
                    admin_alarm_no
                }
            })
        }
    }

    function handleClose(e: React.MouseEvent<unknown>) {
        e.stopPropagation()
        createUserAdminCookie({
            variables: {
                admin_alarm_no
            }
        })
    }

    // alram 쿠키값이 없으면 open 이지만, 모바일(핸드폰) 사이즈라면 안보여준다.
    return (
        <Fade in={open && !xs}>
        <MyBox>
            <SubBox>
                <Alarm link={link} onClick={handleClick}> 
                    <CancelPresentationTwoToneIcon 
                        style={{position: 'absolute', top: -2, right: -1, cursor: 'pointer'}} 
                        fontSize="small" 
                        color="primary"
                        onClick={handleClose}
                    />
                    <span>{HtmlParser(message)}</span>
                </Alarm>
                <RecordVoiceOverIcon color="action"/>
            </SubBox>
        </MyBox>
        </Fade>
    )
}

export default withBreakPoints(AlarmComponent)