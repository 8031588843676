import { getModuleIcon } from 'utils/survey'
import { useIntl } from 'react-intl'

// 문항 종류 (큰분류)
const kinds = [ 'explain', 'choice', 'scale', 'text', 'rank', 'file' ]

export default (root) => (WrappedComponent) => (props) => {
    
    const { formatMessage: f } = useIntl()

    const modules = getModuleIcon(f)

    const moduleLists = kinds.map(kind => {
        return {
            kind,
            modules: modules.filter(module => module.kind === kind)
        }    
    })

    return <WrappedComponent moduleLists={moduleLists} {...props}/>
}