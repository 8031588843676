import { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { fileSize } from 'ts-utils'
import { Storage } from 'gql/storage'
import Typography from "@material-ui/core/Typography"
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { withStyles } from '@material-ui/core/styles'
import useTokenInfo from 'hooks/useTokenInfo'
import Tooltip from 'styled/Custom/Tooltip'
import { getPercent } from './Component'
import FileRemoveDialogComponent from './FileRemoveDialog'

interface Props {
    size: Storage['fileupload'];
    refetch: () => void;
}

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        '& div': {
            width: '100%',
            maxWidth: 450
        }
    }
})(Box)

function MyFileuploadComponent(props: Props) {
    const { size, refetch } = props

    const [ open, setOpen ] = useState(false)

    const token = useTokenInfo()

    const { formatMessage: f } = useIntl()

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
        <MyBox>
            <div>
                <Typography gutterBottom>{f({id: 'component.MyAccount.Storage.MyFileupload.title'})}</Typography>
                <LinearProgress variant="determinate" color="secondary" value={getPercent(size, token.limit_fileupload_size)}/>
                <Typography component="span" variant="body2">{fileSize(size, 2)} / {fileSize(Number(token.limit_fileupload_size))}</Typography>
            </div>
            {
                /** 일단 미사용
                 * <Tooltip title={f({id: 'component.MyAccount.Storage.MyFileupload.tooltip'})}>
                        <IconButton disabled={size === 0} color="secondary" size="small" onClick={() => setOpen(true)}>
                            <DeleteForeverIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                 * 
                 */
            }
            

        </MyBox>
        <FileRemoveDialogComponent open={open} refetch={refetch} onClose={handleClose}/>
        </>
    )
}

export default MyFileuploadComponent