import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { SurveyLiveContainer } from 'container'
import { withSurveyCheckPolicy }  from 'hoc'
import { SHOW as ANSWERERRORPAGESHOW } from 'reducer/answerErrorPage'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { useIntl } from 'react-intl'
import { getTokenname } from 'utils'
import { getDevice } from 'utils/survey'
import { CREATE_SURVEY_ANALYSIS_USERS_IP } from 'gql/survey_analysis_users'

const method = 'ip'
const mode = 'live'
const device = getDevice()


export function getMessage(props) {
    const { type, message, f } = props
    return message ? (message.split('\n').map(c => `${c}<br />`).join('')) : f({id: `container.Survey.Live.Ip.${type}`})
}

const Component = (props) => {
    const { survey_no, levels, policyType } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const tokenname = getTokenname(survey_no)

    const [ token, setToken ] = useState(localStorage.getItem(tokenname))

    const [ createSurveyAnalysisUsers_IP ] = useMutation(CREATE_SURVEY_ANALYSIS_USERS_IP, {
        onCompleted: async (data) => {
            const _token = data.createSurveyAnalysisUsers_IP.token

            localStorage.setItem(tokenname, _token)
            setToken(_token)
        },
        onError: (e) => {
            dispatch({ type: ERRORPAGESHOW, f, logoHref: process.env.REACT_APP_LANDING, buttonHref: process.env.REACT_APP_LANDING})
        }
    })


    useEffect(() => {
        async function createUser() {
            createSurveyAnalysisUsers_IP({ variables: { survey_no, device, mode, referer: document.referrer || null }})
        }

        if (survey_no && !token && policyType.type === 'success') createUser()
    }, [policyType, createSurveyAnalysisUsers_IP, survey_no, token])
 
    useEffect(() => {
        switch(policyType.type) {
            case 'response':
            case 'max_response':
            case 'open':
            case 'end_date':
            case 'cut_people':
            case 'allow_ips':
            case 'ip_blocks':
            case 'died':
                dispatch({ 
                    type: ANSWERERRORPAGESHOW, 
                    logoHide: true,
                    message: getMessage({...policyType, f}),
                    buttonName: policyType.button,
                    buttonHref: policyType.link,
                    f,
                    iconType: 'private'
                })
                break
            default:
                dispatch({ type: BACKDROPSHOW })
                break
        }
    }, [policyType, f, dispatch])
    
    return (
        <>
        {
            ((policyType.type === 'success' || policyType.type === 'answered_end') && survey_no && token) && (
                <SurveyLiveContainer policyType={policyType.type} method={method} survey_no={survey_no} levels={levels} token={token}/>
            )
        }
        </>
    )
}

export default withSurveyCheckPolicy()(Component)