import { useContext, useMemo } from 'react'
import summaryContext from 'context/summary'
import { getMimePicture } from 'utils/survey'
import { handleDisabledRightClick } from 'ts-utils'
import htmlParser from 'react-html-parser'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { withSurveyPopoverImage } from 'hoc'
import Video from 'styled/Survey/Live/Video'
import Youtube from 'styled/Survey/Live/Youtube'
import { useStyles } from './SummaryQuestion'


export const MyTypography = withStyles({
    root: (props: { fcolor: string, fontFamily: string }) => {
        const { fcolor, fontFamily } = props
        return {
            color: `rgba(${fcolor})`,
            fontFamily,
            marginBottom: 20
        }
    }
})(props => <Typography display="block" variant="h5" gutterBottom {...props}/>)

function Component (props) {
    const { img_src, question, number, popoverImageProps } = props
    const { on } = popoverImageProps

    const classes = useStyles()

    const { design } = useContext(summaryContext)

    const mime = getMimePicture(img_src) 

    return (
        <>
        {
            useMemo(() => {
                return (
                    <MyTypography fcolor={design.question} fontFamily={design.font_family}>
                        {`Q ${number}.`}
                    </MyTypography>
                )
            }, [design.question, design.font_family, number])
        }
        {
            (mime === 'image') ? <img src={img_src} onClick={() => on.open(img_src)} onContextMenu={handleDisabledRightClick} alt="listovey" className={classes.img} /> : 
            (mime === 'youtube') ? <div className={`video ${classes.video}`}><Youtube src={img_src} autoplay={0} mute={0} control={1} screen="stack" /></div> : 
            (mime === 'video') && <div className={`video ${classes.video}`}><Video src={img_src} autoplay={0} mute={0} control={1} screen="stack"/></div> 
        }
        {
            useMemo(() => {
                return (
                    <div>
                        <Typography variant="h6" style={{wordBreak: 'break-all', fontWeight: 700, color: `rgba(${design.question})`, fontFamily: design.font_family }}>
                            {htmlParser(question.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />'))}
                        </Typography>
                    </div>
                )
            }, [ question, design.question, design.font_family ])
        }
        </>
    )
}

export default withSurveyPopoverImage(Component)