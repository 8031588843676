import gql from 'graphql-tag'

export const GET_SURVEY_MBOX_MESSAGE = gql`
    query SurveyMboxMessage($survey_no: Int!, $mode: Mode) {
        surveyMboxMessage(survey_no: $survey_no, mode: $mode) {
            survey_no
	        open
	        allow_ips
	        end_date
	        cut_people
            ip_blocks
        }
    }
`

export const UPDATE_SURVEY_MBOX_MESSAGE = gql`
    mutation UpdateSurveyMboxMessage($survey_no: Int!, $type: MboxMessageType!, $value: String!) {
        updateSurveyMboxMessage(survey_no: $survey_no, type: $type, value: $value)
    }
`