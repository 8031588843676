import gql from 'graphql-tag'

export const CREATE_SURVEY_MBOX_ARGS= gql`
    mutation CreateSurveyMboxArgs($survey_no: Int!, $custom_args: String!) {
        createSurveyMboxArgs(survey_no: $survey_no, custom_args: $custom_args)
    }
`

export const GET_SURVEY_MBOX_ARGS = gql`
    query SurveyMboxArgs($survey_no: Int!) {
        surveyMboxArgs(survey_no: $survey_no) {
            custom_args
        }
    }
`

export interface SurveyMboxArgsProps {
    custom_args: string;
}