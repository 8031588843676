/**
 * 외부사이트로 이동 창
 */
import { SurveyEndingProps } from "gql/survey_ending"

export const SHOW = 'survey/create/menu/ending/redirect/SHOW'
export const HIDE = 'survey/create/menu/ending/redirect/HIDE'

interface Props {
    survey_no: SurveyEndingProps['survey_ending_no'];
    survey_ending_no: SurveyEndingProps['survey_ending_no'];
    indexs:  SurveyEndingProps['indexs'];
    redirect_url:  SurveyEndingProps['redirect_url'];
    redirect_label:  SurveyEndingProps['redirect_label'];
}
const initState: Props = {
    survey_no: 0,
    survey_ending_no: 0,
    indexs: 0,
    redirect_url: '',
    redirect_label: ''
}

interface ActionProps extends Props {
    type: string;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case SHOW:
            return {
                survey_no: action.survey_no,
                survey_ending_no: action.survey_ending_no,
                indexs: action.indexs,
                redirect_url: action.redirect_url,
                redirect_label: action.redirect_label,
            }
        case HIDE:
            return initState
        default:
            return state
    }
}