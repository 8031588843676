import { useSelector } from 'react-redux'
import { parseHtmlIntl } from 'ts-utils'
import { Backdrop, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import oc from 'open-color'

const useStyles = makeStyles(theme => ({
    backdrop: (props) => {
        const { background } = props

        let styles = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: theme.zIndex.drawer + 1000000,
            color: '#fff'
        }

        if (background) {
            styles = {
                ...styles,
                background
            }
        }
        
        return styles
    },
    typography: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        }
    }
}))

const TextStyled = styled.div`
    margin-top: 15px;
    text-align: center;
    text-shadow: 1px 1px ${oc.gray[7]};
    color: ${oc.gray[1]};
`

export const Progress = styled.progress`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    border: none;
    border-radius: 50%;
    padding: 0.25em;
    width: 2.5em;
    height: 2.5em;
    color: white;
    background-color: transparent;
    font-size: 16px;
    overflow: hidden;
    
    &::-webkit-progress-bar {
        background-color: transparent;
    }

    /* Indeterminate */
    &:indeterminate {
        -webkit-mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
        mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
        animation: pure-material-progress-circular 6s infinite cubic-bezier(0.3, 0.6, 1, 1);
    }

    :-ms-lang(x), &:indeterminate {
        animation: none;
    }

    &:indeterminate::before,
    &:indeterminate::-webkit-progress-value {
        content: "";
        display: block;
        box-sizing: border-box;
        margin-bottom: 0.25em;
        border: solid 0.16em transparent;
        border-color: currentColor;
        border-radius: 50%;
        width: 100% !important;
        height: 100%;
        background-color: transparent;
        animation: pure-material-progress-circular-pseudo 0.75s infinite linear alternate;
    }

    &:indeterminate::-moz-progress-bar {
        box-sizing: border-box;
        border: solid 0.16em transparent;
        border-color: currentColor;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background-color: transparent;
        animation: pure-material-progress-circular-pseudo 0.75s infinite linear alternate;
    }

    &:indeterminate::-ms-fill {
        animation-name: -ms-ring;
    }

    @keyframes pure-material-progress-circular {
        0% {
            transform: rotate(0deg);
        }
        12.5% {
            transform: rotate(180deg);
            animation-timing-function: linear;
        }
        25% {
            transform: rotate(630deg);
        }
        37.5% {
            transform: rotate(810deg);
            animation-timing-function: linear;
        }
        50% {
            transform: rotate(1260deg);
        }
        62.5% {
            transform: rotate(1440deg);
            animation-timing-function: linear;
        }
        75% {
            transform: rotate(1890deg);
        }
        87.5% {
            transform: rotate(2070deg);
            animation-timing-function: linear;
        }
        100% {
            transform: rotate(2520deg);
        }
    }

    @keyframes pure-material-progress-circular-pseudo {
        0% {
            transform: rotate(-30deg);
        }
        29.4% {
            border-left-color: transparent;
        }
        29.41% {
            border-left-color: currentColor;
        }
        64.7% {
            border-bottom-color: transparent;
        }
        64.71% {
            border-bottom-color: currentColor;
        }
        100% {
            border-left-color: currentColor;
            border-bottom-color: currentColor;
            transform: rotate(225deg);
        }
    }
`
export default () => {
    const { backdrop, backdropHide }= useSelector(state => { 
        return {
            backdrop: state.backdrop,
            backdropHide: state.backdropHide
        }
    })

    const classes = useStyles({ background: backdrop.background })

    const show = backdrop.show && !backdropHide.action

    return (
        <Backdrop className={classes.backdrop} open={show}>
           <Progress/>
          
            <TextStyled>
                {
                    backdrop.msg ? (
                        <Typography variant="h5" className={classes.typography}>{parseHtmlIntl(backdrop.msg)}</Typography>
                    ) : (
                        <Typography variant="body2">{parseHtmlIntl('Listovey')}</Typography>
                    )
                }
            </TextStyled>
        </Backdrop>
    )
}
