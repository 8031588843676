import { memo, useContext, Fragment } from 'react'
import { useIntl } from 'react-intl'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { ItemContext } from './Overflow'
import StepLabel from '@material-ui/core/StepLabel'
import Step from 'styled/Custom/Step'
import StepIcon from 'styled/Custom/StepIcon'
import StepIconText from 'styled/Custom/StepIconText'
import StepConnector from 'styled/Custom/StepConnector'
import Stepper from 'styled/Custom/Stepper'
import Column from 'styled/Custom/Column'
import { LabelComponent } from 'styled/Survey/Scale'
import { getViewType } from '../05/Item'
import DivdierComponent from './Divdier'

export const ColumnBox = withStyles({
    root: (props) => {
        const { index, marginTop=25 } = props
        
        return {
            position: 'sticky', 
            left: 0,
            marginTop: index === null ? 0 : index === 0 ? 0 : marginTop
        }
    }
})(Box)

export const AnswerBox = withStyles({
    root: (props) => {
        const { marginTop=5 } = props
        return {
            marginTop
        }
    }
})(Box)

export function getValue(survey_module_no, reply, answers) {
    const self = reply.find(d => survey_module_no === d.survey_module_no)

    if (!self) return null

    const index = answers.findIndex(c => c.survey_module_answer_no === self.survey_module_answer_no)

    return index > -1 ? index : null
}

function ItemComponent(props) {
    const { left_label, right_label, columns, answers, reverse, zero_number, reply, onChange } = useContext(ItemContext)

    const { formatMessage: f } = useIntl()

    const alen = answers.length
    const clen = columns.length

    return (
        <>
        {
            columns.map((c, i) => {
                    // 선택한값중에서 해당 값이 있는지 체크
                const activeStep = getValue(c.survey_module_no, reply, answers)
                
                const column = c.column || f({id: 'component.Survey.Modules.06.column'})

                return (
                    <Fragment key={c.survey_module_no}>
                        {
                            getViewType(alen) ? (
                                <>
                                <ColumnBox index={i}>
                                    <Column>{column}</Column>
                                </ColumnBox>
                                <AnswerBox >
                                <Stepper activeStep={activeStep} connector={<StepConnector/>}>
                                    {
                                        answers.map(d => {
                                            return (
                                                <Step key={d.survey_module_answer_no}>
                                                    <StepLabel StepIconComponent={(props) => <StepIconText zero_number={zero_number} reverse={reverse} len={alen} {...props}/>} onClick={() => onChange(c.survey_module_no, d.survey_module_answer_no)}/>
                                                </Step>
                                            )
                                        })
                                    }
                                </Stepper>
                                </AnswerBox>
                                <LabelComponent pc={true} left_label={left_label} right_label={right_label}/>
                                </>
                            ) : (
                                <>
                                <ColumnBox index={i} marginTop={10}>
                                    <Column>{column}</Column>
                                </ColumnBox>
                                <AnswerBox >
                                <Stepper nonLinear activeStep={activeStep} connector={<StepConnector/>} is_mobile={1}>
                                {
                                    answers.map(d => {
                                        return (
                                            <StepIcon key={d.survey_module_answer_no} zero_number={zero_number} reverse={reverse} len={alen} onClick={() => onChange(c.survey_module_no, d.survey_module_answer_no)}/>
                                        )
                                    })
                                }
                                </Stepper>
                                <LabelComponent left_label={left_label} right_label={right_label}/>
                                <DivdierComponent isLast={clen-1 === i}/>
                                </AnswerBox >
                                </>
                            )
                        }
                    </Fragment>
                )
            })
        }
        </>
    )
}

export default memo(ItemComponent)