/* 
    open update scribe 기능 (헤더와 본문에서 update가 발생하면 자동으로 업데이트)
*/
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useSubscription, useQuery } from '@apollo/react-hooks'
import { SUBSCRIBE_SURVEY_MBOX_TITLE, GET_SURVEY_MBOX } from 'gql/survey_mbox'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'

const useSurveyTitle = (_survey_no) => {
    const params = useParams()
    const survey_no = _survey_no || Number(params.survey_no)

    const [ title, setTitle ] = useState('')

    const dispatch = useDispatch()

    useQuery(GET_SURVEY_MBOX, {
        variables: { survey_no, mode: 'preview' },
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            const { surveyMbox } = res

            setTitle(surveyMbox.title)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    useSubscription(SUBSCRIBE_SURVEY_MBOX_TITLE, {
        variables: { survey_no, token: localStorage.getItem('authorization') },
        onSubscriptionData: (data) => {
            const { subscriptionData: { data: { surveyMboxTitle }} } = data

            setTitle(surveyMboxTitle)
        },
        fetchPolicy: 'network-only'
    })

    return [ title ]
 }
 
export default useSurveyTitle
