import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { GetLevels } from 'ts-policy'
import oc from 'open-color'
import queryString from 'query-string'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet'
import {
	AppBar, 
	Toolbar,
	CssBaseline,
    Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useTokenInfo from 'hooks/useTokenInfo'
import Logo from 'styled/FaceLogoNew'
import AlarmComponent from './Alarm'
import TemlateMakerComponent from './Template/Maker'
import MfaComponent from './Mfa'
import BackComponent from './Back'
import UpgradeComponent from './Upgrade'
import MyComponent from './My'

const useStyles = makeStyles(theme => ({
    logo: {
        maxWidth: 28
    },
    listovey: {
        fontWeight: 600, 
        color: '#303F9F'
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        height: 64
    }
}))

const OutStyled = styled.div`
    width: 100%;
`
const ToolbarStyled = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    gap: 1em; 


    & > div {
        align-self: center;
        display: block;
    }

    & > div.logo {
        grid-area: logo;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    
    & > div.back {
        grid-area: back;
    }

    & > div.toolbar {
        grid-area: toolbar;      
        display: none;
        padding-right: 1em;
        border-right: ${props => props.toolbarLine ? `${oc.gray[2]} 1px solid` : 'none'};
    }

    & > div.upgrade {
        grid-area: upgrade;
    }
    & > div.auth {
        grid-area: auth;
        display: none;
    }

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
    grid-template-areas:
        "logo upgrade";

    // 헤더에 툴바가 있으면 로고를 가리고 백버튼이 보이고, 툴바가 없으면 로고가 보이고 백버튼 가린다.
    ${props => {
        const { isToolbar } = props

        return isToolbar ? css`
            grid-template-columns: auto 1fr auto auto;
            grid-template-rows: 100%;
            grid-template-areas:
                "back toolbar upgrade auth";
                
            & > div.logo {
               display: none;
            }
            & > div.back {
               display: block;
            }
            & > div.toolbar {
                display: block;
            }
            & > div.upgrade {
                display: block;
            }
            & > div.auth {
                display: block;
            }

            // 콘텐츠가 잇을경우 기니까 .... upgrade 가린다
            @media only screen and (max-width: 600px) {
                grid-template-columns: auto 1fr auto;
                grid-template-rows: 1fr;
                grid-template-areas: 
                    "back toolbar auth";

                & > div.logo {
                    display: none;
                }
                & > div.back {
                    display: block;
                }
                & > div.toolbar {
                    display: block;
                    padding-right: 0;
                    border-right: none;
                }
                & > div.upgrade {
                    display: none;
                }
                & > div.auth {
                    display: block;
                }
            }
        ` :  css`
            grid-template-columns: 1fr auto auto;
            grid-template-rows: 100%;
            grid-template-areas:
                "logo upgrade auth";

            & > div.logo {
               display: flex;
            }
            & > div.back {
                display: none;
            }
            & > div.toolbar {
                display: none;
            }
            & > div.upgrade {
                display: block;
            }
            & > div.auth {
                display: block;
            }

            // 콘텐츠가 잇을경우 기니까 .... upgrade 가린다
            @media only screen and (max-width: 600px) {
                grid-template-columns: 1fr auto;
                grid-template-rows: 5em;
                grid-template-areas: 
                    "logo upgrade auth";

                & > div.logo {
                    display: flex;
                }
                & > div.back {
                    display: none;
                }
                & > div.toolbar {
                    display: none;
                }
                & > div.auth {
                    display: block;
                }
            }
        `
    }}
`

function getTitleName(location) {
    const { pathname, hash } = location

    if (/workspace/.test(pathname) || /\/$/.test(pathname)) {
        return 'workspace'
    } else if (/myaccount/.test(pathname)) {
        return 'myaccount'
    } else if (/survey\/form\/create/.test(pathname)) {
        return 'create'
    } else if (/survey\/form\/share/.test(pathname)) {
        return 'share'
    } else if (/survey\/form\/analysis/.test(pathname)) {
        const _hash = queryString.parse(hash)

        if (!_hash.tab || _hash.tab === 'insight') return 'analysis.insight'
        else if (_hash.tab === 'summary') return 'analysis.summary'
        else if (_hash.tab === 'rows') return 'analysis.rows'
        else if (_hash.tab === 'integration') return 'analysis.integration'

    }  else if (/survey\/form\/intergration/.test(pathname)) {
        return 'intergration'
    } else if (/payment\/choice/.test(pathname)) {
        return 'payment.choice'
    } else if (/payment\/receipt/.test(pathname)) {
        return 'payment.receipt'
    }  else if (/analysis\/[0-9]+\/[0-9]+/.test(pathname)) {
        return 'analysisMoum'
    } else {
        return pathname.replace('/', '')
    }
}

/*
toolbar: 헤더에 다른 내용이 들어가질때
toolbarLine: 툴바내용 끝에 라인
*/
function Component({toolbar, toolbarLine=false, color="inherit", children}) {
    const classes = useStyles()

    const location = useLocation()  

    const token = useTokenInfo()

    const { name } = GetLevels(token.levels)

    const { formatMessage: f } = useIntl()

    const titleName = getTitleName(location)   

    return (
        <>
        <Helmet>
            <title>{f({id: `component.Outer.Component.title.${titleName}`})}</title>
            {
                process.env.REACT_APP_DOMAIN !== 'listovey.com' && <meta name="ROBOTS" content="NOINDEX, NOFOLLOW"/>
            }
        </Helmet>
        <CssBaseline/>
        <OutStyled>
            <div style={{height: 64}}>
            <AppBar position="fixed" color={color} elevation={1}>
                <Toolbar variant="dense" className={classes.toolbar}>
                    <ToolbarStyled isToolbar={toolbar ? true : false} toolbarLine={toolbarLine}>
                        <div className="logo">
                            <Logo className={classes.logo}/>
                            <Typography variant="body1" color="primary" className={classes.listovey}>Listovey</Typography>
                        </div>
                        <div className="back">
                            <BackComponent/>
                        </div>
                        <div className="toolbar">{toolbar}</div>
                        <div className="upgrade">
                            <TemlateMakerComponent/>
                            <MfaComponent/>
                            <UpgradeComponent levels={token.levels} name={name}/>
                        </div>
                        <div className="auth">
                            <MyComponent user_no={token.user_no} img_src={token.img_src} email={token.email} nickname={token.nickname}/>
                        </div>
                    </ToolbarStyled>
                </Toolbar>
            </AppBar>
            </div>
            
            {children}
        </OutStyled>
        <AlarmComponent/>
        </>
    )
}

export default Component