// 로우데이터 가져오기, 삭제하기 소켓통신으로 확인 파일 받기 Hooks

let timer: ReturnType<typeof setTimeout>
let timerChild: ReturnType<typeof setTimeout>
let startCheck = 0

function useCheckSocketFile(props: { maxCheck: number, intervalTime: number }) {
    const { maxCheck, intervalTime } = props
    
    const handleCheckFile = (fileSrc: string) => {
        return new Promise((resolve, reject) => {
            timer = setTimeout(async function request() {
                startCheck++

                // aws 비용 나가니까 maxCheck 까지만 하고 에러 메세지 뱉어낸다.
                if (startCheck === maxCheck) {
                
                    clearTimeout(timer)
                    clearTimeout(timerChild)
                    reject(0)
                    return
                }

                console.log(`${process.env.REACT_APP_S3_LOG}/${fileSrc}`)

                const json = await fetch(`${process.env.REACT_APP_S3_LOG}/${fileSrc}`).then(response => {
                    if (response.status !== 200) return null
                    return response.json()
                })
    
                if (json) {
                    clearTimeout(timer)
                    clearTimeout(timerChild)
                    resolve(json)
                    return
                } 
                
                timerChild = setTimeout(request, intervalTime)
            }, intervalTime)
        })
    }

    return [ handleCheckFile ]
}

export default useCheckSocketFile