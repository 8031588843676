import { useContext, useEffect } from 'react'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { Box }from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getBackground } from 'utils/survey'
import { Element } from 'react-scroll'

import { useIntl } from 'react-intl'
import RequireLanguageComponent from './RequireLanguage'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles({
    box: {
        position: 'fixed',
        top: 10,
        right: isMobile ? 10 : 20,
        zIndex: 4
    }
})

const Component = (props) => {
    const { design, children, refetchMboxWithRelation, required, onChangeRequired } = props
    const { scrollerObj, open, onClose=() => {} } = useContext(PropsContext)
    const { mode } = useContext(AccessMethodContext)
    
    const style = getBackground(design)
    
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    // 백그라운드 디자인 설정
    const _style = {
        ...scrollerObj.style,
        ...style,
        backgroundAttachment: 'scroll',
    }

    useEffect(() => {
        if (open) refetchMboxWithRelation()
    }, [open, refetchMboxWithRelation])

    return (
        <Element id={scrollerObj.id} style={_style}>
            <Element name={scrollerObj.targetTop}>
                <div></div>
            </Element>
            <Box>
                {
                    (mode === 'preview' || mode === 'live') && (
                        <Box className={classes.box}>
                            <RequireLanguageComponent required={required} onChange={onChangeRequired} onClose={onClose}/>
                        </Box>
                    )
                }
                {children}
            </Box>
        </Element>
    )
}


export default Component