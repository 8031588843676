import { SurveyLiveOneContainer } from 'container'
import { withSurveyFetchMboxWithRelation, withSurveyFetchQuestionsOne, withSurveyFetchAnalysisUser, withSurveyFetchQuestionsWithJumpOne } from 'hoc'

const mode = 'live'

const Contents = (props) => (
    <SurveyLiveOneContainer {...props}/>
)

export default withSurveyFetchAnalysisUser(mode)
               (withSurveyFetchMboxWithRelation({mode, fetchPolicy: 'network-only'})(true)
               (withSurveyFetchQuestionsOne(mode)
               (withSurveyFetchQuestionsWithJumpOne({mode, loading: false})(Contents))))