import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { useMutation } from '@apollo/react-hooks'
import { TabComponent } from 'component'
import { withRouter } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { CREATE_SURVEY } from 'gql/survey_mbox'
import { CREATE_SURVEY_MBOX_OPTION } from 'gql/survey_mbox_option'
import { CREATE_SURVEY_MBOX_DESIGN } from 'gql/survey_mbox_design'
import { CREATE_SURVEY_MBOX_ANALYSIS_DESIGN } from 'gql/survey_mbox_analysis_design'
import { CREATE_SURVEY_PAGE } from 'gql/survey_page'
import { CREATE_SURVEY_START } from 'gql/survey_start'
import { CREATE_SURVEY_ENDING } from 'gql/survey_ending'

const Container = ({menu, index, history}) => {
    const { formatMessage: f } = useIntl()
    const dispatch = useDispatch()

    const [ createSurveyMbox ] = useMutation(CREATE_SURVEY)
    const [ createSurveyMboxOption ] = useMutation(CREATE_SURVEY_MBOX_OPTION)
    const [ createSurveyMboxDesign ] = useMutation(CREATE_SURVEY_MBOX_DESIGN)
    const [ createSurveyMboxAnalysisDesign ] = useMutation(CREATE_SURVEY_MBOX_ANALYSIS_DESIGN)
    const [ createSurveyPage ] = useMutation(CREATE_SURVEY_PAGE)
    const [ createSurveyEnding ] = useMutation(CREATE_SURVEY_ENDING)
    const [ createSurveyStart ] = useMutation(CREATE_SURVEY_START)
    

    const handleCallback = {
        createSurvey: async () => {
            try {
                dispatch({ type: BACKDROPSHOW })
                const { data: { createSurveyMbox: survey_no } } = await createSurveyMbox()
                
                await Promise.all([
                    createSurveyMboxOption({variables: { survey_no }}),
                    createSurveyMboxDesign({variables: { survey_no }}),
                    createSurveyMboxAnalysisDesign({variables: { survey_no }}),
                    createSurveyPage({variables: { survey_no }}),
                    createSurveyEnding({variables: { survey_no }}),
                    createSurveyStart({variables: { survey_no }})
                ])

                history.push(`/survey/form/create/${survey_no}`)
            } catch(e) {
                throw e
            }
        }
    }
    
    const menus = {
        group: [
            {
                name:  f({id: 'container.Tab.Container.menus.group.0'}),
                route: '/group'
            },
            {
                name: f({id: 'container.Tab.Container.menus.group.1'}),
                route: '/pannel' 
            },
            {
                name: f({id: 'container.Tab.Container.menus.group.2'}),
                route: '/pannel/create' 
            },
            {
                name: f({id: 'container.Tab.Container.menus.group.3'}),
                route: '/field' 
            },      
        ],
        survey: [
            {
                name:  f({id: 'container.Tab.Container.menus.survey.0'}),
                route: '/survey/category'
            },
            {
                name:  f({id: 'container.Tab.Container.menus.survey.1'}),
                callback: 'createSurvey'
            },
            {
                name:  f({id: 'container.Tab.Container.menus.survey.2'}),
                route: '/survey/copy'
            }
        ]
    }

    const [ indexState, setIndexState ] = useState(index)

    const handleMove = {
        move: (e, newIndex) => {
            setIndexState(newIndex)
    
            const { route, callback } = menus[menu][newIndex]
            
            if (typeof route === 'string') setTimeout(() => history.push(route), 350)
            else {
                if (callback === 'createSurvey') handleCallback.createSurvey() 
            }
        }
    }

    return (
        <TabComponent menus={menus} menu={menu} indexState={indexState} onMove={handleMove}/>
    )
}

export default withRouter(Container)
