import { ChangeEvent, useState } from "react"
import { useDispatch } from "react-redux"
import { useMutation } from "@apollo/client"
import { HIDE as POPOVERHIDE } from 'reducer/popover'
import { 
    UPDATE_SURVEY_TEMPLATE_CATEGORY_NAME,
    UpdateSurveyTemplateCategoryNameVariables
} from 'gql/survey_template'
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Box from '@material-ui/core/Box'
import { withStyles } from "@material-ui/core/styles"
import { Props as ParentProps } from './CategoryManageItem'

interface Props {
    survey_template_category_no: ParentProps['survey_template_category_no']
    name: ParentProps['name']
    refetch: ParentProps['refetch']
}

const MyBox = withStyles({
    root: {
        padding: 20
    }
})(Box)

function UpdateCategoryNameComponent(props: Props) {
    const { survey_template_category_no, name, refetch } = props
    const [ cname, setCname ] = useState(name)

    const dispatch = useDispatch()

    const [ save ] = useMutation<UpdateSurveyTemplateCategoryNameVariables>(UPDATE_SURVEY_TEMPLATE_CATEGORY_NAME, {
        onCompleted: function() {
            dispatch({ type: POPOVERHIDE })
            refetch()
        }
    })

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.target
        setCname(value)
    }

    function handleSave() {
        save({
            variables: {
                survey_template_category_no,
                name: cname
            }
        })
    }

    return (
        <MyBox>
            <TextField value={cname} onChange={handleChange}/>
            <Button onClick={handleSave}>저장</Button>
        </MyBox>

    )
}

export default UpdateCategoryNameComponent