import { useContext, useCallback, useEffect, useState } from 'react'
import PropsContext from 'context/props'
import { withPrevState } from 'hoc'
import { isEqual } from 'lodash-es'
import sortArray from 'sort-array'
import { memoryReply } from 'container/Survey/Live/Container'
import ItemsComponent from './Items'
import useChangeReplys from '../useChangeReplys'

// 순서에따른 값을 재정렬
export function getFixData(props) {
    const { ranks, reply, survey_question_no, survey_module_answer_no, format } = props
    // 1순위부터 정렬
    let _ranks = [ ...ranks ]
    sortArray(_ranks, { by: 'indexs', order: 'asc' })

    // 선택된 보기의 기존 순위값을 알아낸다.
    const isReply = reply.find(c => {
        return c.survey_module_answer_no === survey_module_answer_no
    })
    
    let fixData = []
    // 값이없다면 선택된거라 추가하고 있다면 기존에 선택된거를 해지하는거다

    if (isReply) {

        // 해당문항의 값만 뽑아 내자 (다시 셋팅하기 위해)
        let selfData = reply.filter(c => {
            return c.survey_module_answer_no !== survey_module_answer_no
        })

        // 해당 순위보다 작은 순위의 값을 위값으로 올려준다.
        sortArray(selfData, { by: 'survey_module_no', order: 'asc' })

        selfData = selfData.map((c, i) => ({
            ...c,
            survey_module_no: _ranks[i].survey_module_no
        })) 
        
        fixData = [...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no), ...selfData]
    } else {
     
          // 기존에 선택된 순위 갯수
        let len = reply.length

        fixData = [...memoryReply.rows, format({ survey_question_no, survey_module_no: _ranks[len].survey_module_no, survey_module_answer_no, comment: '' }) ]
    }

    return fixData
}

const Component = props => {
    const { 
        survey_question_no, 
        ranks, 
        answers, 
        reply, 
        is_comment_placeholder,
        required,
        required_end,
        usePrevState
    } = props

    const { format, refComments17 } = useContext(PropsContext)

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState(reply)

    const answersLength = answers.length
    const prevAnswersLength = usePrevState(answersLength)

    // 1문 1답은 전체 랜더링, 클래식모드는 현재 문항만 랜더링
    const handleChangeState = useCallback(async(data) => {
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = data

        await setNewReply(data.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(data)
    }, [survey_question_no, onChangeReplys])

    
    const handleChange = useCallback((survey_module_answer_no, disabled) => {
        if (disabled) return

        const fixData = getFixData({ ranks, reply: newReply, survey_question_no, survey_module_answer_no, format: format._17 })

        handleChangeState(fixData)
        
    }, [survey_question_no, format, ranks, newReply, handleChangeState])

    // resetChange 
    const handleChangeReset = useCallback(() => {
        const resetData = memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no)
        handleChangeState(resetData)
    }, [survey_question_no, handleChangeState])

    // 편집페이지에서 보기.추가 삭제시 reply 리셋해준다 (실제 응답에서는 상관없다)
    useEffect(() => {
        if (prevAnswersLength) {
            if (answersLength !== prevAnswersLength) {
                handleChangeReset()
            }
        }
    }, [ answersLength, prevAnswersLength, handleChangeReset ])

    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])
    
    return (
        <ItemsComponent 
            survey_question_no={survey_question_no}
            answers={answers}
            ranks={ranks} 
            reply={newReply}
            is_comment_placeholder={is_comment_placeholder}
            required={required}
            required_end={required_end}
            refComment={refComments17}
            onChange={handleChange}
            onChangeReset={handleChangeReset}
        />
    )
}

export default withPrevState(Component)