import { ReactChildren } from 'react'
// dialog 창
export const SHOW = 'dialog/SHOW'
export const HIDE = 'dialog/HIDE'

interface InitProps {
    show: boolean;
    fullScreen: boolean;
    fullWidth: boolean;
    content: ReactChildren | null;
}

const initState: InitProps = {
    show: false,
    fullScreen: false,
    fullWidth: true,
    content: null
}

interface ActionProps {
    type: string;    
    fullScreen?: Pick<InitProps, 'fullScreen'>;
    fullWidth?: Pick<InitProps, 'fullWidth'>;
    content?: Pick<InitProps, 'content'>;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
                fullScreen: action.fullScreen,
                fullWidth: action.fullWidth,
                content: action.content
            }
        case HIDE:
            return {
                ...state,
                show: false
            }
        default:
            return state
    }
}