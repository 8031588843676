/**
 * 한개씩 보여지는 컴포넌트
 */
import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_MBOX } from 'gql/survey_mbox'
import { GET_SURVEY_MBOX_OPTION } from 'gql/survey_mbox_option'
import { GET_SURVEY_MBOX_DESIGN } from 'gql/survey_mbox_design'

export default mode => _fetchPolicy => WrappedComponent => props => {
    const params = useParams()
    const dispatch = useDispatch()

    const fetchPolicy = _fetchPolicy || {mbox: 'network-only', mboxOption: 'network-only', mboxDesign: 'network-only'}

    //const survey_no = (mode === 'live' || mode === 'previewTs') ? Number(props.survey_no) : Number(params.survey_no)
    //const survey_no = typeof props.survey_no === 'number' ? Number(props.survey_no) : Number(params.survey_no)
    
    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    
    const [getSurveyMbox, { data, refetch: refetchMbox }] = useLazyQuery(GET_SURVEY_MBOX, {
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.mbox
    })

    const [getSurveyMboxOption, { data: dataOption, refetch: refetchMboxOption, loading: loadingMboxOption }] = useLazyQuery(GET_SURVEY_MBOX_OPTION, {
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.mboxOption
    })

    const [getSurveyMboxDesign, { data: dataDesign, refetch: refetchMboxDesign }] = useLazyQuery(GET_SURVEY_MBOX_DESIGN, {
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.mboxDesign
    })

    useEffect(() => {
     
        if (survey_no) {
            getSurveyMbox({ variables: { survey_no, mode } })
            getSurveyMboxOption({ variables: { survey_no, mode } })
            getSurveyMboxDesign({ variables: { survey_no, mode } })
        }
    }, [survey_no, getSurveyMbox, getSurveyMboxOption, getSurveyMboxDesign])
    
    if (!data || !dataOption || !dataDesign) return null

    const args = {
        mbox: data.surveyMbox,
        option: dataOption.surveyMboxOption,
        design: dataDesign.surveyMboxDesign
    }
    
    return (
        <WrappedComponent 
            {...args}
            refetchMbox={refetchMbox}
            refetchMboxOption={refetchMboxOption}
            refetchMboxDesign={refetchMboxDesign}
            loadingMboxOption={loadingMboxOption}
            {...props}
        />
    )
}