import { useIntl } from 'react-intl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectStyled } from 'styled/Input'

interface Props {
    type: number;
    onChange: (event: React.ChangeEvent<{ value: number }>) => void;
}

function TypeComponent(props: Props) {
    const { type, onChange } = props

    const { formatMessage: f } = useIntl()

    return (
        <Select value={type} onChange={onChange} input={<SelectStyled fontSize={13} padding="8px 12px 8px 8px" backgroundcolor="white"/>}>
            <MenuItem value={0}>
                {f({id: 'component.Image.Type.item.0'})}
            </MenuItem>
            <MenuItem value={1}>
                {f({id: 'component.Image.Type.item.1'})}
            </MenuItem>
            <MenuItem value={2}>
                {f({id: 'component.Image.Type.item.2'})}
            </MenuItem>
        </Select>
    )
}

export default TypeComponent