/**
 * 상태값 바뀌면 메뉴 컴포넌트에 호출
 */

import randomString from "randomstring"

// 빈값이 있을경우 해당 id kind 저장
export const CHANGE = 'survey/create/logic/menuAlarm/CHANGE'

interface Props {
    page_random: string | null;
    question_random: string | null;
    jump: string | null;
    questionAll: string | null;
}

const initState: Props = {
    page_random: null,
    question_random: null,
    jump: null,
    questionAll: null
}

interface ActionProps {
    type: string;
    kinds: ['page_random', 'question_random', 'jump', 'questionAll'];
}
  
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE: 
            return {
                page_random: action.kinds.includes('page_random') ? randomString.generate(4) : state.page_random,
                question_random: action.kinds.includes('question_random') ? randomString.generate(4) : state.question_random,
                jump: action.kinds.includes('jump') ? randomString.generate(4) : state.jump,
                questionAll: action.kinds.includes('questionAll') ? randomString.generate(4) : state.questionAll,
            }     
        default:
            return state
    }
}