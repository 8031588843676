/**
 * 결과 - 요약, 로우 카테고리에서 데이터 한번에 가져오기
 */
import { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { schemaSurveyAnalysisUserAll } from 'gql/survey_analysis_users'
import { schemaSurveyAnalysisModule, schemaSurveyAnalysisEnding } from 'gql/survey_analysis_modules'
import { schemaSurveyQuestionModule } from 'gql/survey_question_modules'
import { schemaSurveyAnalysisCommentsAll } from 'gql/survey_analysis_comment'
import gql from 'graphql-tag'

export const GET_SURVEY_ANALYSIS_ON_ALL = gql`
    query SurveyAnalysisOnAll($survey_no: Int!, $mode: Mode!) {
        surveyAnalysisOnAll(survey_no: $survey_no, mode: $mode) {
            surveyAnalysisUserAll {
                ${schemaSurveyAnalysisUserAll}
            }
            surveyAnalysisModulesAll {
                ${schemaSurveyAnalysisModule}
            }
            surveyAnalysisEndingsAll {
                ${schemaSurveyAnalysisEnding}
            }
            surveyQuestionsModuleAll {
                ${schemaSurveyQuestionModule}
            }
            surveyAnalysisCommentsAll {
                ${schemaSurveyAnalysisCommentsAll}
            }
            surveyEndingsAll {
                survey_ending_no
                pure_message
                img_src
                indexs
            }
        }
    }
`

export default args => isLoading => WrappedComponent => props => {
    const params = useParams()
    const dispatch = useDispatch()

    const { mode, fetchPolicy='network-only' } = args
    
    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    if (!survey_no) return null

    const { data, loading, refetch } = useQuery(GET_SURVEY_ANALYSIS_ON_ALL, {
        variables: { survey_no, mode },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy
    })

    useEffect(() => {
        if (isLoading && loading) dispatch({type: BACKDROPSHOW})
    }, [loading, dispatch])
    
    if (!data) return null
    
    return (
        <WrappedComponent 
            propsFetchAnalysisOnAllData={{
                ...data.surveyAnalysisOnAll,
                refetchSurveyAnalysisOnAll: refetch
            }}
            {...props}
        />
    )
}