import { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: props => {
        const { screen } = props

        return screen === 'split' ? {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
            display: 'block',
            [theme.breakpoints.down('md')]: {
                maxHeight: '50vh'
            },
            outline: 0
        } : screen === 'stack' ? {
            width: '100%',
            height: '100%',
            maxHeight: '30em',
            borderRadius: '3px',
            objectFit: 'cover',
            display: 'block',
            outline: 0
        } : {
            width: '100%',
            height: '100%',
            borderRadius: '3px',
            display: 'block',
            objectFit: 'cover',
            outline: 0,
            [theme.breakpoints.down('md')]: {
                objectFit: 'contain',
                maxHeight: '50vh'
            }
        }

    }
}))

export default (props) => {
    // screen: stack float split
    const { src, poster, autoplay, mute, control, screen="split" } = props

    const classes = useStyles({ screen })
     
    const refVideo = useRef()
    const refSource = useRef()

    useEffect(() => {
        refSource.current.setAttribute('src', src)
        refVideo.current.load()
    }, [ src ])
    
    return (
        <video ref={ref => refVideo.current = ref} poster={poster} className={classes.root} autoPlay={autoplay} muted={mute} controls={control}>
            <source ref={ref => refSource.current = ref}/>
        </video>
    )
}