// 검색창

import { memo, useState, useEffect, ChangeEvent, useRef } from "react"
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { Props as ParentProps, OnChangeSearch, ScrollZeroTop } from './Items'

interface Props {
    open: boolean;
    answer_color: ParentProps['answer_color'];
    line_color: ParentProps['line_color'];
    font_family: ParentProps['font_family'];
    scrollZeroTop: ScrollZeroTop;
    onChange: OnChangeSearch;
}

let timer: ReturnType<typeof setTimeout> 

function SearchComponent(props: Props) {
    const { open, answer_color, line_color, font_family, scrollZeroTop, onChange } = props

    const searchRef = useRef<null | HTMLInputElement>(null)

    const [ search, setSearch ] = useState('')

    const handleFocus = () => {
        if (searchRef.current) searchRef.current.focus()
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setSearch(String(value).replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim, ""))

        clearTimeout(timer)

        timer = setTimeout(() => {
            onChange(value)
        }, 500) 
    }

    const handleRemove = () => {
        setSearch('')
        onChange('')
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])

    useEffect(() => {
        if (open) {
            setSearch('')
            onChange('')
        }
    }, [open, onChange])


    let paddingTop = 5
    if (!scrollZeroTop) paddingTop = 10

    return (
        <MenuItem 
            dense
            button={false} 
            style={{
                paddingTop,
                transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
            }}>
            <InputBase 
                inputRef={searchRef}
                value={search}
                startAdornment={<SearchIcon onClick={handleFocus} style={{ marginRight: 20, color: `rgba(${line_color})` }}/>} 
                endAdornment={
                    <>
                    {
                        search && (
                            <IconButton size="small" onClick={handleRemove}>
                                <CloseIcon color="action" style={{ width: 25, height: 25, cursor: 'pointer', color: `rgba(${line_color})`}} fontSize="small"/>
                            </IconButton>
                        )

                    }
                    </>
                } 
                fullWidth
                onChange={handleChange}
                style={{
                    borderBottom: `1px solid rgba(${line_color})`, 
                    borderRadius: 0, 
                    fontFamily: font_family,
                    color: `rgba(${answer_color})`
                }}
            />
        </MenuItem>
    )
}

export default memo(SearchComponent)