import { memo, Fragment, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Collapse } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import oc from 'open-color'
import TipComponent from './Tip'

const MyList = withStyles({
    root: {
        '& > * + *': {
            marginTop: 6
        }
    }
})(List)

const MyListItemText = withStyles({
    secondary: {
        color: oc.gray[7]   
    },
})(ListItemText)

// 디폴트 타입에 따라 kind open
const handleKindInType = (moduleLists, selectedType) => {
    if (selectedType === undefined) return ''
    
    const len = moduleLists.length

    for (let i=0; i<len; i++) {
        const { kind, modules } = moduleLists[i]

        const moduleLen = modules.length

        for (let j=0; j<moduleLen; j++) {
            const { type } = modules[j]

            if (type === selectedType) return kind
        }
    }

    return ''
}

const ListsComponent = (props) => {
    const { moduleLists, selectedType, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(handleKindInType(moduleLists, selectedType))

    useEffect(() => {
        if (selectedType !== undefined) setOpen(handleKindInType(moduleLists, selectedType))
    }, [moduleLists, selectedType])

    return (
        moduleLists.map((rows) => {
            const { kind, modules } = rows

            return (
                <Fragment key={rows.kind}>
                    <ListItem onClick={() => setOpen(prev => prev === rows.kind ? '' : rows.kind)} style={{cursor: 'pointer'}}>
                        <MyListItemText secondary={f({id: `component.Survey.Create.Menu.ModuleLists.kind.${kind}`})} />
                        {open === rows.kind ? <ExpandLess  color="action"/> : <ExpandMore  color="action"/>}
                    </ListItem>
                    <Collapse in={open === rows.kind} timeout="auto">
                        <MyList component="div"  dense style={{background: oc.gray[0], paddingRight: 0}}>
                        {
                            modules.filter(c => c.type !== '_16').map(module => {
                                const IconComponent = module.icon
                                const index = Number(module.type.replace('_', ''))

                                return (
                                    <ListItem button key={module.type} selected={selectedType === module.type} onClick={() => onChange(module.type)}>
                                        <ListItemIcon style={{minWidth: 48}}><IconComponent fontSize="small"/></ListItemIcon>
                                        <ListItemText primary={module.name}/>
                                        <ListItemSecondaryAction>
                                            <TipComponent index={index}/>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                        }
                        </MyList>
                    </Collapse>
                </Fragment>
            )
        })
    )
}

export default memo(ListsComponent)