import gql from 'graphql-tag'

export const CREATE_SURVEY_MBOX_ANALYSIS_DESIGN = gql`
    mutation CreateSurveyMboxAnalysisDesign($survey_no: Int!) {
        createSurveyMboxAnalysisDesign(survey_no: $survey_no)
    }
`


export const UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_COLOR = gql`
    mutation UpdateSurveyMboxAnalysisDesignColor($input: UpdateSurveyMboxAnalysisDesignColor!) {
        updateSurveyMboxAnalysisDesignColor(input: $input)
    }
`

export const UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_IMAGE = gql`
    mutation UpdateSurveyMboxAnalysisDesignImage($input: UpdateSurveyMboxAnalysisDesignImage!) {
        updateSurveyMboxAnalysisDesignImage(input: $input)
    }
`

export const UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_COVER_OPACITY = gql`
    mutation UpdateSurveyMboxAnalysisDesignCoverOpacity($survey_no: Int!, $background_cover_opacity: Float!) {
        updateSurveyMboxAnalysisDesignCoverOpacity(survey_no: $survey_no, background_cover_opacity: $background_cover_opacity)
    }
`

export const UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_OPEN = gql`
    mutation UpdateSurveyMboxAnalysisDesignOpen($survey_no: Int!, $open: Boolean!) {
        updateSurveyMboxAnalysisDesignOpen(survey_no: $survey_no, open: $open)
    }
`

export const UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_RESPONSE = gql`
    mutation UpdateSurveyMboxAnalysisDesignResponse($survey_no: Int!, $response: Boolean!) {
        updateSurveyMboxAnalysisDesignResponse(survey_no: $survey_no, response: $response)
    }
`

export const UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_VIEWTYPE = gql`
    mutation UpdateSurveyMboxAnalysisDesignViewType($survey_no: Int!, $view_type: MboxAnalysisDesignViewType!) {
        updateSurveyMboxAnalysisDesignViewType(survey_no: $survey_no, view_type: $view_type)
    }
`


export const schemaSurveyMboxAnalysisDesign = `
    survey_no
    args
    open
    view_type
    response         
    question  
    background_color
    bar   
    background_cover
    background_cover_opacity
    background_logo
    font_family
`

export const GET_SURVEY_MBOX_ANALYSIS_DESIGN = gql`
    query SurveyMboxAnalysisDesign($survey_no: Int!, $mode: Mode!) {
        surveyMboxAnalysisDesign(survey_no: $survey_no, mode: $mode) {
           ${schemaSurveyMboxAnalysisDesign}
        }
    }
`

export const GET_ANALYSYS_SURVEY_NO = gql`
    query AnalysisSurveyNo($args: String!) {
        analysisSurveyNo(args: $args)
    }
`