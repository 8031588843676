import { useState, useCallback, useEffect, Fragment } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { ListItem, ListItemIcon, ListItemAvatar, ListItemSecondaryAction, Avatar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MailOutline as MailOutlineIcon } from '@material-ui/icons'
import Tooltip from 'styled/Custom/Tooltip'
import { ListItemText, CustomButton, SecondaryTypography, useCommonStyles } from '../Component'
import EmailModalComponent from './EmailModal'
import { useIntl } from 'react-intl'
import { GET_USER_NEW_EMAIL } from 'gql/user_new_email'
import oc from 'open-color-js'
import reactHtmlParser from 'react-html-parser'
import { postMessage } from 'utils'

const useStyles = makeStyles(theme => ({
    newEmail: {
        background: oc.gray1
    }
}))

function getChangeNewEmailValue(f, onlyGoogle) {
    if (onlyGoogle) return { tooltip: f({id: 'component.MyAccount.Info.Email.disabled'}), disabled: true }
    
    return { tooltip: '', disabled: false }
}

const Component = (props) => {
    const { token } = props

    const classes = useStyles()
    const commonClasses = useCommonStyles()

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)

    const [getUserNewEmail, { data: dataUserNewEmail }] = useLazyQuery(GET_USER_NEW_EMAIL, {
        onError: (e) => {
            postMessage({ 
                dispatch: { type: ERRORPAGESHOW } 
            })
        },
        fetchPolicy: 'network-only'
    })

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleRefetch = useCallback(() => {
        getUserNewEmail()
    }, [getUserNewEmail])


    // 인증완료후에 토큰값이 변경되고 그것을 반영되야하기때문에 useLazyQuery 사용
    useEffect(() => {
        getUserNewEmail()
    }, [token.email, getUserNewEmail])

    if (!dataUserNewEmail) return null

    const { tooltip, disabled } = getChangeNewEmailValue(f, token.onlyGoogle)

    return (
        <>
        <ListItem>
            <ListItemAvatar className={commonClasses.icon}>
                <Avatar><MailOutlineIcon/></Avatar>
            </ListItemAvatar>
            <ListItemText 
                primary={f({id: 'component.MyAccount.Info.Email.primary'})} 
                secondary={  <Fragment><SecondaryTypography>{token.email}</SecondaryTypography></Fragment>}
            />
            <ListItemSecondaryAction>
                <Tooltip title={tooltip}>
                    <span>
                        <CustomButton onClick={handleOpen} disabled={disabled}>
                            {f({id: 'component.MyAccount.Info.Email.button'})}
                        </CustomButton>
                    </span>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
        {
            dataUserNewEmail.userNewEmail && (
                <ListItem className={classes.newEmail} dense>
                    <ListItemIcon  className={commonClasses.icon}>
                    </ListItemIcon>
                    <ListItemText 
                        secondary={
                            <Typography variant="body2" color="primary">
                                {reactHtmlParser(f({id: 'component.MyAccount.Info.Email.newEmailAuth'}, {email: dataUserNewEmail.userNewEmail}))}
                            </Typography>
                        } 
                    />
                </ListItem>
            )
        }
        <EmailModalComponent open={open} onRefetch={handleRefetch} onClose={handleClose}/>
        </>
    )
}

export default Component