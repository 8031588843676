import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'

interface OverflowProps {
    paddingBottom?: string;
    backgroundConcent?: string;
}

export const Overflow = styled.div<OverflowProps>`
    position: relative;
    display: grid;
    overflow-x: auto;
    padding-bottom: ${props => {
        return props.paddingBottom || 0
    }};
    grid-template-columns: minmax(100px, 1fr);

    &.is-scroll {
        box-shadow: ${props => {
            const rgba = props.backgroundConcent ? '120, 120, 120, .3' : '0, 0, 0, .2'
            return `rgba(${rgba}) -25px 0px 15px -15px inset`
        }};
    }
    ${props => isMobile ? 
        css`
            ::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            ::-webkit-scrollbar-thumb {
                background-color: transparent;
                outline: 0;
            }
            
        ` : 
        css `
            ::-webkit-scrollbar {
                width: 1px;
                height: 10px;
            }
            ::-webkit-scrollbar-thumb {
                background-color: rgba(${props.backgroundConcent ? '220, 220, 220, .3' : '0, 0, 0, .3'});
                outline: 0;
            }
        `
    }
`