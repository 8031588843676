import { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import summaryContext from 'context/summary'
import { MyTypography } from './SummaryQuestionReport'

function Component () {
    const { design } = useContext<any>(summaryContext)

    const { formatMessage: f } = useIntl()

    return (
        <>
        {
            useMemo(() => {
                return (
                    <MyTypography fcolor={design.question} fontFamily={design.font_family}>
                        {f({id: 'component.Survey.Create.Analysis.Summary.DataEnding.title'})}
                    </MyTypography>
                )
            }, [design.question, design.font_family, f])
        }
        </>
    )
}

export default Component