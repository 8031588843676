import { useState, useCallback, useMemo } from 'react'
import { ListItem, ListItemAvatar, ListItemSecondaryAction, Avatar } from '@material-ui/core'
import { VpnKey as VpnKeyIcon } from '@material-ui/icons'
import { ListItemText, CustomButton, SecondaryTypography, useCommonStyles } from '../Component'
import PasswordModalComponent from './PasswordModal'
import PasswordGoogleModalComponent from './PasswordGoogleModal'
import { useIntl } from 'react-intl'

const Component = (props) => {
    const { token } = props

    const commonClasses = useCommonStyles()

    const [ open, setOpen ] = useState(false)
    const [ openGoogle, setOpenGoogle ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleOpen = useCallback(() => {
        if (token.onlyGoogle) setOpenGoogle(true)
        else setOpen(true)
    }, [token])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])


    const handleCloseGoogle = useCallback(() => {
        setOpenGoogle(false)
    }, [])

    return (
        <>
        <ListItem>
            <ListItemAvatar className={commonClasses.icon}>
                <Avatar><VpnKeyIcon/></Avatar>
            </ListItemAvatar>
            <ListItemText 
                primary={f({id: 'component.MyAccount.Info.Password.primary'})} 
                secondary={<SecondaryTypography>{`●●●●●●●●●●`}</SecondaryTypography>}
            />
            <ListItemSecondaryAction>
                <CustomButton onClick={handleOpen}>
                    {f({id: `component.MyAccount.Info.Password.button.${token.onlyGoogle ? 'google' : 'listovey'}`})}
                </CustomButton>
            </ListItemSecondaryAction>
        </ListItem>
        {
            useMemo(() => (
                <PasswordModalComponent open={open} onClose={handleClose}/>
            ), [open, handleClose])
        }
        {
            useMemo(() => (
                <PasswordGoogleModalComponent open={openGoogle} onClose={handleCloseGoogle}/>
            ), [openGoogle, handleCloseGoogle])
        }
        </>
    )
}

export default Component