import { useContext, useMemo } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SubtitlesIcon from '@material-ui/icons/Subtitles'
import summaryContext from 'context/summary'
import styled from 'styled-components'
import { getRGBA } from 'utils/analysis'

const MyTypography = withStyles({
    root: {
        wordBreak: 'break-all'
    }
})(Typography)

// 게이지 바 스타일
const WrapperBarMatrixStyled = styled.div`
    width: 100%;
    border-radius: 3px;
    
    & > div:first-child {
        margin-bottom: .5rem;
    }

    & > div:last-child {
       
    }

    & + & {
        margin-top: 2.7em;
    }
`

const AnswerStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: .2em;

    & > div:first-child {
        width: 35px;
        
    }

    & > div:last-child {
        width: calc(100% - 2.5em);
    }
`

export default (props) => {
    const { answer, children } = props
    const { design } = useContext(summaryContext)

    const color = getRGBA({color: design.question, opacity: .9})
    const iconColor = getRGBA({color: design.question, opacity: .7})

    const backgroundColor = getRGBA({color: design.background_color, opacity: .8})

    return (
        <WrapperBarMatrixStyled backgroundColor={{backgroundColor}}>
        {
            useMemo(() => (
                <div>
                    <AnswerStyled>
                        <div><SubtitlesIcon style={{color: iconColor, padding: 0}} fontSize='small'/></div>
                        <div><MyTypography variant="body1" style={{color, fontFamily: design.font_family}}>{answer}</MyTypography></div>
                    </AnswerStyled>
                </div>
            ), [iconColor, color, design.font_family, answer])
        }
        <div>{children}</div>
        </WrapperBarMatrixStyled>
    )
}