import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_PAGE } from 'gql/survey_page'
import { GET_SURVEY_QUESTION_ALL } from 'gql/survey_question'

export default root => WrappedComponent => props => {
    const dispatch = useDispatch()
    const params = useParams()
    const survey_no = props.other_survey_no ? Number(props.other_survey_no) : Number(params.survey_no) 

    const { defaults } = props

    const [ val, setVal ] = useState({survey_page_no: '', survey_question_no: ''})
    const [ pages, setPages ] = useState([])
    const [ questionAll, setQuestionAll ] = useState([])
    const [ questions, setQuestions ] = useState([])

    const { refetch: refetchPage } = useQuery(GET_SURVEY_PAGE, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setPages(data.surveyPage)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const { refetch: refetchQuestion } = useQuery(GET_SURVEY_QUESTION_ALL, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setQuestionAll(data.surveyQuestionAll)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const change = async (props) => {
        const { survey_page_no, survey_question_no='', ...other } = props
        
        await setVal({ ...val, survey_page_no, survey_question_no, ...other })
         setQuestions(questionAll.filter(c => c.survey_page_no === survey_page_no))
    }

    const propsFetchQuestionInPage = {
        change,
        val,
        pages,
        questions,
        questionAll,
        refetchPage, 
        refetchQuestion
    }

    useEffect(() => {
        const a = async() => {
            if (defaults) {
                const { survey_page_no } = defaults
                await setQuestions(questionAll.filter(c => c.survey_page_no === survey_page_no))
                setVal(defaults)
            } 
        }
        a()
    }, [defaults, questionAll])

    useEffect(() => {
        if (defaults.survey_page_no) {
            refetchPage() 
            refetchQuestion()
        }
    }, [defaults, refetchPage, refetchQuestion])

   

    return <WrappedComponent propsFetchQuestionInPage={propsFetchQuestionInPage} {...props}/>
}