/**
 * 외부링크 클릭
 */
import { useIntl } from 'react-intl'
import { SurveyEndingProps } from 'gql/survey_ending'
import Button from 'styled/Custom/Button'
import { moveOutLink } from './Ending'
import { MyRedirectBox } from './RedirectMessage'

interface Props {   
    redirect_label: SurveyEndingProps['redirect_label'];
    getUrl: () => void;
}

function RedirectLabelComponent(props: Props) {
    const { redirect_label, getUrl } = props

    const { formatMessage: f } = useIntl()

    return (
        <MyRedirectBox>
            <Button onClick={() => moveOutLink(getUrl())}>
                {redirect_label || f({id: 'component.Survey.Modules.RedirectLabel.button'})}
            </Button>
        </MyRedirectBox>
    )
}

export default RedirectLabelComponent