import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKGROPSHOW, HIDE as BACKGROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { handleDisabledRightClick } from 'ts-utils'
import { ListItem, ListItemAvatar, ListItemSecondaryAction, Avatar } from '@material-ui/core'
import { ListItemText, CustomButton, SecondaryTypography } from '../Component'
import { useIntl } from 'react-intl'
import moment from 'moment'
import PopupImage from 'styled/PopupImage'
import { UPDATE_USER_INFO_IMGSRC } from 'gql/user_info'
import { getMimePicture } from 'utils/survey'
import { postMessage } from 'utils'
import useUpdateToken from 'hooks/useUpdateToken'

const Component = (props) => {
    const { token } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ open, setOpen ] = useState(false)
    const [ image, setImage ] = useState('')

    const updateToken = useUpdateToken()

    const [ updateUserInfoImgSrc ] = useMutation(UPDATE_USER_INFO_IMGSRC, {
        onCompleted: async (data) => {
            await updateToken()
            dispatch({ type: BACKGROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKGROPHIDE })
            postMessage({ 
                dispatch: { type: ERRORPAGESHOW } 
            })
        }
    })

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleChangeImage = useCallback(props => {
        const { img_src } = props
        setImage(img_src)
    }, [])

    const handleSave = useCallback(async() => {
        const mime = getMimePicture(image)

        if (mime !== 'image') {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({id: 'container.Image.Container.alert.appendUrl.nothing'})
            })

            return
        }
            
        const img = document.createElement('img');
        img.src = image

        img.onload = function() {
            dispatch({ type: BACKGROPSHOW })
            updateUserInfoImgSrc({ variables: { img_src: image } })
            handleClose()
        }

        img.onerror = function() {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({id: 'container.Image.Container.alert.appendUrl.image'})
            })
        }
    }, [f, image, updateUserInfoImgSrc, handleClose, dispatch])

    return (
        <>
        <ListItem>
            <ListItemAvatar>
                <div onContextMenu={handleDisabledRightClick}>
                    <Avatar src={token.img_src} >{token.nickname}</Avatar>
                </div>
            </ListItemAvatar>
            <ListItemText 
                primary={f({id: 'component.MyAccount.Info.Avatar.primary'})} 
                secondary={<SecondaryTypography>{moment(token.wdate).format('YYYY.MM.DD')}</SecondaryTypography>}
            />
            <ListItemSecondaryAction>
                <CustomButton onClick={handleOpen}>
                    {f({id: 'component.MyAccount.Info.Avatar.button'})}
                </CustomButton>
            </ListItemSecondaryAction>
        </ListItem>
        <PopupImage 
            title={f({id: 'component.MyAccount.Info.Avatar.button'})}
            open={open}
            onSave={handleSave}
            onClose={handleClose}
            onAppendUrl={handleChangeImage}
            onChangeImage={handleChangeImage}
        />
        </>
    )
}

export default Component