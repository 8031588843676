
import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_SURVEY_STARTS_TOKEN } from 'gql/log'
import { getUA, deviceType } from 'react-device-detect'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { scrollerObj } from 'utils/survey'
import { getStartsTokenname } from 'utils'
import { isInAppBrowser } from 'ts-utils'
import { withSurveyFetchMboxWithRelation } from 'hoc'
import HeadTag from 'styled/HeadTag'
import { SurveyLiveComponent, SurveyLiveMultiDataComponent, SurveyLiveOneDataComponent } from 'component'
import EndingContainer from './Ending'

const mode = 'live'

class MemoryReply {
    constructor() {
        // 현재 문항 포함 그 아래 문항까지 다 저장
        this.data = []

        // 현재 섹션 withCommentcs 저장
        this._currentWithComments = {}

        // 현재 섹션 데이터 저장
        this._currentData = []

    }

    get rows() {
        return this.data
    }

    set rows(_data) {
        this.data = _data
    }

    get currentWithComments() {
        return this._currentWithComments
    }

    set currentWithComments(_data) {
        this._currentWithComments = _data
    }

    get currentData() {
        return this._currentData
    }

    set currentData(_data) {
        this._currentData = _data
    }
}

export const memoryReply = new MemoryReply()

const Component = (props) => {
    const { policyType, mbox, method, survey_no, levels, token } = props

    const location = useLocation()

    const startsTokenname = getStartsTokenname(survey_no)

    const [ createSurveyStartsToken ] = useMutation(CREATE_SURVEY_STARTS_TOKEN)

    if (!sessionStorage.getItem(startsTokenname)) {
        // interval 저장시 startsTokenname 값이 없어서 1977년도 값이 저장되는 문제
        let now = new Date()

        const msg = `${getUA}:::${deviceType}:::${now}`

        // now 값이 순간적으로 안찍히는 건지 체크
        if (typeof now !== 'object' || !now) {

            createSurveyStartsToken({ variables: {
                msg, type: 1
            } })

            // 다시 한번 now 값을 찍어본다
            now = new Date()
        }

        sessionStorage.setItem(startsTokenname, now)

        // 세션값이 저장안되는건지 체크
        if (!sessionStorage.getItem(startsTokenname)) {
            createSurveyStartsToken({ variables: {
                msg, type: 2
            } })

            // 다시한번 저장해본다
            sessionStorage.setItem(startsTokenname, now)
        }
    }

    // 인앱 브라우저 안에서는 beforeUnload 미사용
    const inApp = isInAppBrowser()
    return (
        <AccessMethodContext.Provider value={{mode, method, beforeUnload: inApp ? false : true }}>
            <PropsContext.Provider value={{scrollerObj: scrollerObj()}}>
                <HeadTag type="survey" title={mbox.title} url={`${process.env.REACT_APP_ADMIN}${location.pathname}`}/>
                <SurveyLiveComponent {...props}>
                {
                    policyType === 'answered_end' ? (
                        <EndingContainer survey_no={survey_no} levels={levels} mode={mode} token={token}/> 
                    ) : mbox.__typename && survey_no && token && mbox.method === 'multi' ? (
                        <SurveyLiveMultiDataComponent survey_no={survey_no}  levels={levels} token={token}/>
                    ) : mbox.__typename && survey_no && token && mbox.method === 'one' && (
                        <SurveyLiveOneDataComponent survey_no={survey_no}  levels={levels} token={token}/>
                    )
                }
                </SurveyLiveComponent>
            </PropsContext.Provider>
        </AccessMethodContext.Provider>
    )
}

export default withSurveyFetchMboxWithRelation({
    mode,
    fetchPolicy: 'cache-first'
})(false)(Component)