import { memo } from 'react'
import { getMaxlength } from 'policy'
import styled from 'styled-components'
import { InputRef } from './01/Item'
import { isEqual } from 'lodash-es'

const WrapperStyled = styled.div`
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
`

function CommentItem(props){  
    const { survey_question_no, is_comment_placeholder, comment, disabled, refComment, marginTop="5px", marginBottom="0" } = props

    return (
        <WrapperStyled marginTop={marginTop} marginBottom={marginBottom}>
            <InputRef
                maxLength={getMaxlength('component.Survey.Modules.Comment.comment')}
                disabled={disabled}
                defaultValue={comment}  
                placeholder={is_comment_placeholder}
                ref={ref => refComment.current[survey_question_no] = ref}
            />
        </WrapperStyled>
    )
}

export default memo(CommentItem, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.is_comment_placeholder, next.is_comment_placeholder) && 
            isEqual(prev.comment, next.comment) && 
            isEqual(prev.disabled, next.disabled) && 
            isEqual(prev.refComment, next.refComment)
})