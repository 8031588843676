import { useContext } from 'react'
import { getColorConcentration } from 'utils'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropsContext from 'context/props'

const useStyles = makeStyles(theme => ({
    root: (props) => {

        const { style, color, font_family, background_color } = props
        return {
            ...style,
            lineHeight: '140%',
            color: `rgba(${color})`, 
            fontFamily: font_family,
            '& *': {
                fontFamily: font_family,
            },
            '& pre': {
                border: getColorConcentration(background_color) ? '1px solid rgba(255, 255, 255, .4)' : '1px solid rgba(0, 0, 0, .1)',
                borderRadius: 2,
                display: 'block',
                padding: 10,
                wordBreak: 'break-all',
                whiteSpace: 'normal',
                background: getColorConcentration(background_color) ? 'rgba(255, 255, 255, .1)' : 'rgba(126, 126, 126, .1)'
            },
            '& p': {
                margin: 0,
                padding: 0,
            },
            '& a': {
                color: `rgba(${color})`
            },
            '& ul, & ol': {
                marginLeft: 19,
                textAlign: 'left'
            },
            '& .text-tiny': {
                fontSize: '.7em',
            },
            '& .text-small': {
                fontSize: '.85em'
            },
            '& .text-big': {
                fontSize: '1.2em'
            },
            '& .text-huge': {
                fontSize: '1.6em'
            }
        }
    }
}))

function Question(props) {
    const { style={}, variant="h6", children, ...other } = props
    const { design } = useContext(PropsContext)
    const { question: color, font_family, background_color } = design

    const classes = useStyles({ style, color, font_family, background_color })

    

    return <Typography {...other} variant={variant} className={classes.root} component="div">{children}</Typography>
}

export default Question