import { memo } from 'react'
import { GridColumn } from 'styled/Survey/Matrix'
import Column from 'styled/Custom/Column'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'

function MobileAnswerItemComponent(props) {
    const { column } = props

    const { formatMessage: f } = useIntl()

    return (
        <GridColumn><Column>{column || f({id: 'component.Survey.Modules.03.column'})}</Column></GridColumn>
    )
}

export default memo(MobileAnswerItemComponent, (prev, next) => {
    return  isEqual(prev.column, next.column)
})