import gql from 'graphql-tag'
import { SurveyWorkspaceProps } from 'gql/survey_workspace'

export const GET_SURVEY_WORKSPACE_GROUPS = gql`
    query SurveyWorkspaceGroups {
        surveyWorkspaceGroups {
            survey_workspace_group_no      
            title
        }
    }
`

export const GET_SURVEY_WORKSPACES_GROUP_BYS = gql`
    query SurveyWorkspacesGroupBys {
        surveyWorkspacesGroupBys {
            survey_workspace_group_no      
            title
            survey_workspaces {
                survey_workspace_no
                title
                base
            }
        }
    }
`

export const GET_SURVEY_WORKSPACE_GROUP_SELECTED = gql`
    query SurveyWorkspaceGroupSelected($survey_workspace_group_no: Int, $dummy: String!) {
        surveyWorkspaceGroupSelected(survey_workspace_group_no: $survey_workspace_group_no, dummy: $dummy)
    }
`

export const GET_SURVEY_WORKSPACE_GROUP_NO_IN_SURVEY_NO = gql`
    query SurveyWorkspaceGroupNoInSurveyNo($survey_no: Int!) {
        surveyWorkspaceGroupNoInSurveyNo(survey_no: $survey_no)
    }
`

export const CREATE_SURVEY_WORKSPACE_GROUP = gql`
    mutation CreateSurveyWorkspaceGroup($title: String!) {
        createSurveyWorkspaceGroup(title: $title)
    }
`

export const UPDATE_SURVEY_WORKSPACE_GROUP_NAME = gql`
    mutation UpdateSurveyWorkspaceGroupName($survey_workspace_group_no: Int!, $title: String!) {
        updateSurveyWorkspaceGroupName(survey_workspace_group_no: $survey_workspace_group_no,  title: $title)
    }
`

export const UPDATE_SURVEY_WORKSPACE_GROUP_SORTING = gql`
    mutation UpdateSurveyWorkspaceGroupSorting($survey_workspace_group_nos: [Int!]!) {
        updateSurveyWorkspaceGroupSorting(survey_workspace_group_nos: $survey_workspace_group_nos)
    }
`

export const DELETE_SURVEY_WORKSPACE_GROUP = gql`
    mutation DeleteSurveyWorkspaceGroup($survey_workspace_group_no: Int!) {
        deleteSurveyWorkspaceGroup(survey_workspace_group_no: $survey_workspace_group_no)
    }
`

export interface SurveyWorkspaceGroupProps {
    survey_workspace_group_no: number;
    title: string;      
    wdate: string;
    indexs: number;
}

export interface SurveyWorkspacesGroupBys extends SurveyWorkspaceGroupProps {
    survey_workspaces: SurveyWorkspaceProps[];
}


export type SurveyWorkspaceGroupSelectedProps = number;

export interface SurveyWorkspaceGroupSelectedVariables {
    survey_workspace_group_no: number | null;
    dummy: string;
}