import gql from 'graphql-tag'

export const CREATE_SURVEY_MBOX_THUMBNAIL = gql`
    mutation CreateSurveyMboxThumbnail($survey_no: Int!, $src: String!) {
        createSurveyMboxThumbnail(survey_no: $survey_no, src: $src)
    }
`

export const DELETE_SURVEY_MBOX_THUMBNAIL = gql`
    mutation DeleteSurveyMboxThumbnail($survey_no: Int!) {
        deleteSurveyMboxThumbnail(survey_no: $survey_no)
    }
`

export const GET_SURVEY_MBOX_THUMBNAIL_SRC = gql`
    query SurveyMboxThumbnailSrc($survey_no: Int!, $mode: Mode!) {
        surveyMboxThumbnailSrc(survey_no: $survey_no, mode: $mode)
    }
`

export type Src = string | null