import { IntlProvider } from 'react-intl'
import flatten from 'flat'
import ko from 'locale/ko.json'
import en from 'locale/en.json'
import jp from 'locale/jp.json'
import vi from 'locale/vi.json'
import ru from 'locale/ru.json'

export const getLangMessage = (lang) => {
    let messages = ''

    switch(lang) {
        case 'ko':
            messages = ko
            break
        case 'en':
            messages = en
            break
        case 'jp':
            messages = jp
            break
        case 'vi':
            messages = vi
            break
        case 'ru':
            messages = ru
            break
        default:
            messages = ko
    }

    return flatten(messages)
}

export const getLocale = (lang) => {
    const locales = {
        ko: 'ko-KR',
        en: 'en-US',
        jp: 'ja-JP',
        vi: 'vi-VN',
        ru: 'ru-RU'
    }

    return locales[lang] || 'ko-KR'
}

export default (props) => {
    const { lang: clang, children } = props

    const lang = clang || 'ko'
    
    return (
        <IntlProvider locale={getLocale(lang)} messages={getLangMessage(lang)}>
            {children}
        </IntlProvider>
    )
}