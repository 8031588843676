import { memo, useEffect, createContext, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import useUserData from 'hooks/useUserData'
import useAdminAlarm from 'hooks/useAdminAlarm'
import { ParentPayResult } from './Component'
import ItemComponent from './Item'

interface Props {
    date: number;
    payResult: ParentPayResult;
}

export const PropsContext = createContext<{onRefetch: () => void}>({
	onRefetch: () => {}
})

function ItemsComponent(props: Props) {
    const { date, payResult } = props

    const dispatch = useDispatch()

    const { getUserPayments, dataUserPayments, refetchUserPayments } = useUserData()

    const adminAlarm = useAdminAlarm()
 
	const [ selected, setSelected ] = useState(0)

	const handleChangeSelected = useCallback((val) => {
		setSelected(val)
	}, [])

    const handleRefetch = useCallback(() => {
        if (refetchUserPayments) refetchUserPayments()
    }, [refetchUserPayments])


	useEffect(() => {
        getUserPayments({ 
            variables: { 
                s_date: moment(new Date(`${date}-01-01`)).format('YYYY-01-01 00:00:00'),
                e_date: moment(new Date(`${date}-12-31`)).format('YYYY-12-31 23:59:59'),
                pay_result: payResult === 'all' ? null : payResult 
            }
        })
	}, [date, payResult, getUserPayments, dispatch])

    // 관리자에서 세금계산서 수정권한 수정시 여기서 알림 받다가 상태값 자동으로 바꿔준다,
    useEffect(() => {
        if (adminAlarm) {
            window.location.reload()
        }
    }, [adminAlarm])

    return (
        <PropsContext.Provider value={{onRefetch: handleRefetch}}>
        {
            dataUserPayments.map((row) => (
                <ItemComponent key={row.user_payment_no} {...row} selected={selected === row.user_payment_no} onChangeSelected={handleChangeSelected}/>
            ))
        }
        </PropsContext.Provider>
    )
}

export default memo(ItemsComponent)