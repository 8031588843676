import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import useTokenInfo from 'hooks/useTokenInfo'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    breakpoints: BreakPointsProps;
}

const pathnames = ['/', '/workspace']

function MfaComponent(props: Props) {
    const { breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const location = useLocation()

    const history = useHistory()

    const token = useTokenInfo()

    // 작업함에서만 보여준다.
    if (!pathnames.includes(location.pathname)) return null

    // mfa가 true라면 안보여준다
    if (token.mfa === true) return null

    return (
        <>
        {
            xs ? (
                <Tooltip title={f({id: 'component.Outer.Component.mfa'})}>
                    <IconButton 
                        onClick={() => history.push('/myaccount#code=info&goal=mfa')}
                        className="animate__animated animate__heartBeat animate__repeat-1 animate__delay-1s"
                        style={{marginRight: 15, color: oc.red[9]}}
                    >
                        <VerifiedUserIcon/>
                    </IconButton>
                </Tooltip>
            ) : (
                <Button 
                    size="small" 
                    color="secondary" 
                    variant="outlined" 
                    disableElevation
                    style={{marginRight: 15}} 
                    startIcon={ <VerifiedUserIcon/>}
                    onClick={() => history.push('/myaccount#code=info&goal=mfa')}
                >
                    {f({id: 'component.Outer.Mfa.button.move'})}
                </Button>
            )
        }
        </>
       
    )
}

export default withBreakPoints(MfaComponent)