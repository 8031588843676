import { useEffect, useContext, useRef } from 'react'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone'
import styled, { css } from 'styled-components'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { getMimePicture } from 'utils/survey' 
import Picture from 'styled/Survey/Picture'
import { withSurveyPopoverImage } from 'hoc'
import MyIconButton from 'styled/Custom/IconButton'
import TopLogoComponent from 'component/Survey/Live/TopLogo'

const OuterStyled = styled.div`
    width: 100%;
    display: grid;

    & > div.question {
        grid-area: question;
        width: 100%;

         /**
        ** stack 포지션일때는 전체스크롤 아닐때는 question 스크롤
        */
        ${({ img_position }) => (
            img_position === 'stack' ?  (
                css`
                    height: auto;
                    overflow-y: hidden;
                `
            ) : (
                css`
                    height: ${({ thanks }) => (!thanks ? '100vh' : '100vh')};
                    overflow-y: auto;
                `
            )
        )}
        
        // 모바일일때는 전체스크롤이 들어가기 때문에 스크롤 히든
        @media only screen and (max-width: 1280px) {
            overflow-y: hidden;
            height: auto;
        };

    }

    & > div.image {
        grid-area: image;
        position: relative;
        width: 100%;
        max-height: ${({ thanks }) => {
            return !thanks ? '100vh' : '100vh'}
        };
        background-image: ${({img_src}) => `url(${img_src})`};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        align-items: center;
        justify-content: center;
       

        /**
        ** float 포지션일때는 img 태그를 사용해야 해서 background-image값을 취소한다
        */
        ${({ img_position, img_src }) => (
           (img_src && img_position === 'float') ? (
                css`
                    background-image: url('');
                    padding: 5em;
                    
                `
            ) : css``
        )};

        background-color: rgba(0, 0, 0, .1);

        /*
        ** 모바일일때는 상단에 이미지를 보여준다
        */ 
        @media only screen and (max-width: 1280px) {
            background-color: rgba(0, 0, 0, 0);

            ${({ img_src, img_position }) => (
                img_src && img_position === 'float' && (
                    css`
                        padding: 1em;
                        padding-bottom: 0;
                        
                    `
                )
            )};
        }
    }

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    /*
    ** stack일때는 이미지가 내용안으로 들어가서 image div를 아예 안보여줘야한다
     */
    ${({ img_src, img_position }) => (
        img_src && img_position !== 'stack' ? (
            css`
                grid-template-areas: "question image";
                @media only screen and (max-width: 1280px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: ${img_position === 'float' ? 'auto 1fr' : 'minmax(220px, 520px) 1fr'};
                    grid-template-areas:
                        "image"
                        "question";

                    @media only screen and (max-width: 960px) {
                        grid-template-rows: ${img_position === 'float' ? 'auto 1fr' : 'minmax(200px, 400px) 1fr'};
                    };

                    @media only screen and (max-width: 600px) {
                        grid-template-rows: ${img_position === 'float' ? 'auto 1fr' : 'minmax(200px, 220px) 1fr'};
                    };
                };
            ` 
        ) : (
            css`
                grid-template-areas: "question question";
                @media only screen and (max-width: 1280px) {
                    grid-template-areas: "question question";
                };
            `
        )
    )};



    ${({ mode, thanks }) => {
        // 편집모드에서는 height 값을 줄인다 (상단 bar 땜시)
        // 응답모드에서는 질문모드일경우 하단에 페이징바 땜시 -48px 해주고 시작엔딩은 100vh로한다

        return mode === 'editing' ? (
            css`
                min-height: calc(100vh - 64px);

                & > div.question {
                    height: calc(100vh - 64px);
                    overflow-y: auto;
                }

                & > div.image {
                    max-height: calc(100vh - 64px);
                }
            `
        ) : (
            !thanks ? (
                css`
                    min-height: calc(100vh - 48px);
                `
            ) : (
                css`
                    min-height: 100vh;
                `
            )
            
        )
    }}
`

export const QuestionStyled = styled.div`
    display: table;
    width: 100%;
    position: relative;
    
    max-width: 720px;
    
    height: 100%;
    margin: 0 auto;

    & > div {
        display: table-row;
    }
    & > div > div {
        display: table-cell;
        padding: 2em 20px 8em 20px;
        vertical-align: middle;
    }
`

/* 
** 클래식 모드에서는 무조건 보여준다.
** PC 사이즈에서는 무조건 보여준다.
** img_position이 stack 이면 무조건 보여준다.
** img_position이 stack이 아니면서 테블릿 사이즈이하라면 가린다.
*/
const LogoStackStyled = styled.div`
    display: table;
    width: 100%;
    position: relative;
    max-width: 720px;
    margin: 0 auto;

    @media only screen and (max-width: 960px) {
        display: ${props => props.img_position !== 'stack' ? 'none' : 'table'};
    };
`

/* 
** img_position이 Split, 1문1답모드 이면서 테블릿 이하 사이즈라면 보여준다,
*/
const LogoSplitStyled = styled.div`
    display: none;
    width: 100%;
    position: absolute;
    top: 0;
    @media only screen and (max-width: 960px) {
        display: ${props => props.img_position === 'split' ? 'block' : 'none'};
    };
`

/* 
** img_position이 float, 1문1답모드 이면서 테블릿 이하 사이즈라면 보여준다,
*/
const LogoFloatStyled = styled.div`
    display: none;
    width: 100%;
    position: relative;
    @media only screen and (max-width: 960px) {
        display: ${props => props.img_position === 'float' ? 'block' : 'none'};
    };
`

const IconButtonBox = withStyles(theme => ({
    root: {
        position: 'absolute', 
        [theme.breakpoints.up('lg')]: {
            top: 10, 
            left: 10,
        },
        [theme.breakpoints.down('lg')]: {
            bottom: 5, 
            right: 5,
        }
    }
}))(Box)

function OneComponent (props) {
    // viewPicture: 편집시 문항보기 리스트보기 변환 기존 재생하는거 막기위한상태값, 실제 라이브나 프리뷰에서는 무조건 true이다

    const { no='', thanks=false, viewPicture=true, img_poster, img_src, img_position, children, popoverImageProps } = props
    const { mode } = useContext(AccessMethodContext)
    const { survey_no, mbox, contentID } = useContext(PropsContext)
   
    const { on: onChangeZoomImage } = popoverImageProps

    const refQuestion = useRef()

    useEffect(() => {
        refQuestion.current.scrollTo(0, 0)
    }, [no])

    // 조사형태가 multi일경우 이미지 없다고 처리 (감사글 말구 질문만)
    const _img_src = !thanks && mbox.method === 'multi' ? '' : img_src

    const mime = getMimePicture(_img_src)

    return (
        <>
        <LogoFloatStyled method={mbox.method} img_position={img_position}>
            <TopLogoComponent survey_no={survey_no} img_position="float"/>
        </LogoFloatStyled>

        <OuterStyled mode={mode} img_src={_img_src} img_position={img_position} thanks={thanks}>
            <div className="question" ref={ref => refQuestion.current = ref} id={contentID || ""}>
                <LogoStackStyled method={mbox.method} img_position={img_position}>
                    <TopLogoComponent survey_no={survey_no} img_position={img_position}/>
                </LogoStackStyled>
                {children}
            </div>
            <div className="image">
                <LogoSplitStyled method={mbox.method} img_position={img_position}>
                    <TopLogoComponent survey_no={survey_no} img_position="split"/>
                </LogoSplitStyled>
                {
                    (viewPicture && img_position !== 'stack') && (
                        <>
                        <Picture mime={mime} thanks={thanks} method={mbox.method} img_src={_img_src} img_poster={img_poster} img_position={img_position} location="split"/>
                        </>
                    )
                }
                {
                    (mime === 'image' && img_position === 'split') && (
                        <IconButtonBox>
                            <MyIconButton edge="start" 
                                onClick={() => onChangeZoomImage.open(_img_src)} 
                                backgroundcolor='rgba(0, 0, 0, .4)' 
                                backgroundcolorhover='rgba(0, 0, 0, .5)'
                                size="small"
                            >
                                <ZoomInTwoToneIcon style={{color: 'white', width: 15, height: 15}} fontSize="small"/>
                            </MyIconButton>
                        </IconButtonBox>
                    )
                }
            </div>
        </OuterStyled>    
        </>   
    )
}

export default withSurveyPopoverImage(OneComponent)