import { memo, useState, useEffect, useContext, useCallback } from 'react'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getAnimationClassName } from '../01/Item'
import { ItemContext } from './Overflow'
import { RatingAction } from 'styled/Survey/RatingIcon'
import Answer from 'styled/Custom/Answer'
import useMount from './useMount'

export const MyBox = withStyles({
    root: (props) => {
        const { is_answer } = props

        return {
            position: 'sticky',
            left: 0,
            wordBreak: 'break-all', 
            paddingLeft: 13,
            height: is_answer ? 20 : 0
        }

    }
})(props => {
    const { is_answer, children, ...other } = props
    return (
        <Box is_answer={is_answer} {...other}>
            <Answer style={{fontSize: 14, fontWeight: 700}}>{children}</Answer>
        </Box>
    )
})

function ItemComponent(props) {
    const { survey_question_no, mode, lists, is_answer, icon_type, reply, onChange } = useContext(ItemContext)
    
    const max = lists.length

    let indexs = 0;
    let answer = ''
    if (reply.length > 0) {
        const is = lists.find(c => c.survey_module_no === reply[0].survey_module_no)

        if (is) {
            indexs = is.indexs
            answer = is.answer
        }
    }

    const [ _answer, setAnswer ] = useState(answer)

    const mount = useMount(max)

    const handleChange = useCallback((currentIndexs) => {
        const { survey_module_no } = lists.find(c => c.indexs === currentIndexs) || { survey_module_no: ''}
        onChange(survey_module_no)
    }, [lists, onChange])

    useEffect(() => {
        setAnswer(answer)
    }, [answer])

    let isJust = false
    if (reply.length > 0) {
        isJust = Boolean(reply[0].isJust)
    }

    if(!mount) return null

    return (
        <>
        <Box>
            <RatingAction
                name={`${survey_question_no}-${mode}`}
                className={getAnimationClassName(isJust)}
                value={indexs}
                precision={1}
                max={max}
                onClick={ !isJust && handleChange}
                onChangeActive={(indexs) => {
                    if (indexs >= 0) {
                        const { answer: newAnswer } = lists.find(c => c.indexs === indexs) || { answer: ' ' }
                        setAnswer(newAnswer)
                    } else setAnswer(answer)

                }}
                iconType={icon_type}
            />
        </Box>    
        <MyBox is_answer={is_answer ? 1 : 0}>{_answer}</MyBox>
        </>
    )
}

export default memo(ItemComponent)