import { ChangeEvent, memo } from 'react'
import { useIntl } from 'react-intl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import { ParentProps } from './Component'
import { Selected, Edit, OnChange } from './Publish'

interface Props {
    data: ParentProps['data']
    edit: Edit
    selected: Selected
    onChange: OnChange
    breakpoints: BreakPointsProps
}

function CategoryComponent(props: Props) {
    const { data, edit, selected, breakpoints, onChange } = props

    const { formatMessage: f } = useIntl()

    const { xs } = breakpoints

    const cData = edit ? data : data.filter(c => c.open === true)

    return (
        <>
        {
            xs ? (
                <Select fullWidth value={selected} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(Number(e.target.value))}>
                    {
                        cData.map((c, i) => (
                            <MenuItem key={c.survey_template_category_no} value={c.survey_template_category_no}>
                                <ListItemText>{c.name}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
            ) : (
                <List 
                    dense
                    component="nav"
                    disablePadding
                    style={{  overflowY: 'auto', height: '100%'}}
                    subheader={
                        <ListSubheader component="div" disableGutters style={{background: 'white', padding: '0 0 10px 10px'}}>
                            {f({id: 'component.Outer.Template.Category.header'})}
                        </ListSubheader>
                    }
                >
                    {
                        cData.map((c, i) => (
                            <ListItem button key={c.survey_template_category_no} selected={selected === c.survey_template_category_no} onClick={() => onChange(c.survey_template_category_no)}>
                                <ListItemText>{c.name}</ListItemText>
                            </ListItem>
                        ))
                    }
                </List>
            )
        }
        </>
    )
}

export default withBreakPoints(memo(CategoryComponent))