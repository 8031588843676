import { useMemo, useContext } from 'react'
import { Context } from './Component'
import ColumnAnswerChildItemComponent from './ColumnAnswerChildItem'

function ColumnAnswerItemComponent(props) {
    const { survey_module_answer_no, survey_module_no, selected, countReply  } = props
    
    const { onChange, getDisabled } = useContext(Context)

    // required_end 만큼 체크가 됏으면 나머지는 disabled 처리한다. (선택된값은 해제도 해야하기때문 disabled 처리안한다)
    const disabled = getDisabled(countReply, selected)

    return (
        <>
        {
            useMemo(() => {
                return (
                    <div key={survey_module_answer_no} 
                        className="center input"
                        onClick={() => onChange(survey_module_no, survey_module_answer_no, disabled)}>
                            <ColumnAnswerChildItemComponent selected={selected} disabled={disabled}/>
                    </div>
                )
            }, [survey_module_answer_no, survey_module_no, selected, disabled, onChange])
        }
        </>
  
    )
}

export default ColumnAnswerItemComponent