import gql from 'graphql-tag'

export const GET_SLACK_USER_CHANNEL_LIST = gql`
    query SlackUserChannelList($survey_no: Int!) {
        slackUserChannelList(survey_no: $survey_no) {
            users
            channels
        }
    }
`


export const UPATE_SLACK_USER_CHANNEL_LIST = gql`
    mutation UpdateSlackUserChannelList($survey_no: Int!) {
        updateSlackUserChannelList(survey_no: $survey_no)
    }
`

export type User = {
    id: string;
    team_id: string;
    name: string;
}

export type Channel = {
    id: string;
    name: string;
}

export interface SlackUserChannelListProps {
    users: User[];
    channels: Channel[];
}