/**
 * 요약페이지 rows 값을 감는다
 */
import { SurveyAnalysisSummaryProps } from 'gql/survey_analysis_summary'

export const CHANGE = 'analysis/summary/rows/CHANGE' // 값 변경시
  
// order: answer => 보기순, count => 응답순
// pointType: point => 가중치로 계산, count => 응답수로 계산 
const initState: { rows: SurveyAnalysisSummaryProps[], response: number } = {
    rows: [],
    response: 0
}
  
interface ActionProps {
    type: string;
    rows: SurveyAnalysisSummaryProps[];
    response: number;
}
  
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                rows: action.rows,
                response: action.response
            }
        default:
            return state
    }
}