/* 
    비밀번호, 새로운비밀번호 새로운비밀번호 확인 정상 비정상 판단
*/
import { validation } from 'utils'

function getIsPassword(password) {
    if (!password) return true

    return validation.password(password)
}

function getIsNewPasswordConfirm(newPasswordConfirm, newPassword) {
    if (!newPasswordConfirm) return true

    return validation.password(newPasswordConfirm) && newPasswordConfirm === newPassword
}

const useInPassword = () => {
    
    return [ getIsPassword, getIsNewPasswordConfirm ]
}
 
export default useInPassword
