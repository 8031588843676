/* 
    sns 공유 버튼
*/
import { makeStyles } from '@material-ui/core/styles'
import {
    FacebookShareButton,
    LineShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookIcon,
    LineIcon,
    LinkedinIcon,
    TwitterIcon
} from 'react-share'
import { KakaoLinkDefault } from 'react-kakao-link'
import { key as kakao } from 'config/kakao.json'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import kakaotalkLogo from 'static/images/icon/kakaolink.png'

const useStyles = makeStyles({
    sns: {
        outline: 0
    },
    snsIcon: {
        borderRadius: 3
    }
})

function getKakaolinkTemplate(props) {
    const { f, title, url, thumbnail } = props

    return {
        objectType: "feed",
        content: {
            title,
            imageUrl: thumbnail || '',
            link: {
                mobileWebUrl: url,
                webUrl: url
            }
        },
        buttons: [
            {
                title: f({id: 'styled.SnsIcon.Icon.kakaolink.button'}),
                link: {
                    mobileWebUrl: url,
                    webUrl: url
                }
            }
        ]
    }
}

const ImgStyled = styled.img`
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    cursor: ${props => props.url ? 'pointer' : 'default'};
`

const Component = (props) => {
    const { title, url, size=35, thumbnail } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()
    
    const kakolinkTemplate = getKakaolinkTemplate({ f, title, url, thumbnail })

    return (
        <>
        {
            url ? (
                <>
                <KakaoLinkDefault className="template" template={kakolinkTemplate} jsKey={kakao}>
                    <ImgStyled src={kakaotalkLogo} size={size} url={url}/>
                </KakaoLinkDefault>
                <FacebookShareButton url={url} className={classes.sns} quote={title}>
                    <FacebookIcon size={size} className={classes.snsIcon}/>
                </FacebookShareButton>
                <LineShareButton url={url} className={classes.sns} title={title}>
                    <LineIcon size={size} className={classes.snsIcon}/>
                </LineShareButton>
                <TwitterShareButton url={url} className={classes.sns} title={title}>
                    <TwitterIcon size={size} className={classes.snsIcon}/>
                </TwitterShareButton>
                <LinkedinShareButton url={url} className={classes.sns} title={title}>
                    <LinkedinIcon size={size} className={classes.snsIcon}/>
                </LinkedinShareButton>
               
                </>
            ) : (
                <>
                <ImgStyled src={kakaotalkLogo} size={size} url={url}/>
                <FacebookIcon size={size} className={classes.snsIcon}/>
                <LineIcon size={size} className={classes.snsIcon}/>
                <TwitterIcon size={size} className={classes.snsIcon}/>
                <LinkedinIcon size={size} className={classes.snsIcon}/>
                </>
            )
        }
        </>
    )
}
 
export default Component
