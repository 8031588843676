import { useEffect } from 'react'

// beforeUnload 
function BeforeUnload() {
    useEffect(() => {
        function warning(event: BeforeUnloadEvent) {
            // 명세에 따라 preventDefault는 호출해야하며, 기본 동작을 방지합니다.
            event.preventDefault()

            // 대표적으로 Chrome에서는 returnValue 설정이 필요합니다.
            event.returnValue = ''
        }

        window.removeEventListener('beforeunload', warning)

        try {
            // iframe내가 아닐때만 한다
            if (window.self === window.top) {
                window.addEventListener('beforeunload', warning)
            }
        } catch(e) {

        }

        return () => {
            window.removeEventListener('beforeunload', warning)
        }
    }, [])

    return null
    
}

export default BeforeUnload