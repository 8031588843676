import oc from 'open-color'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { OutlinedInput, FormHelperText, InputBase } from '@material-ui/core'

// select 콤포넌트 input에 집어넣는다
const SelectStyled = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        },
    },
    input:(props)  => {
        const { 
            fontSize=16, 
            backgroundcolor="transparent", 
            padding='10px 26px 10px 12px', 
            border='1px solid #ced4da',
            borderradius=4,
            color='black'
        } = props

        return {
            backgroundColor: backgroundcolor,
            borderRadius: borderradius,
            position: 'relative',
            border,
            fontSize,
            color,
            textAlign: 'left',
            padding,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderRadius: 4,
            }
        }
        
    }
}))(props => <InputBase {...props}/>)



// 통상적으로 쓰이는 스타일
const InputStyled = withStyles({
    root: {
        width: '100%',
        borderRadius: '5px',
        height: 38.5,
        "& $notchedOutline": {
            border: `1px solid ${oc.gray[4]}`
        },
        "&:hover $notchedOutline": {
            border: `1px solid ${oc.gray[4]} !important`
        },
        "&$focused $notchedOutline": {
            border: `1px solid ${oc.gray[4]} !important`
        }
    },
    input: {
      paddingLeft: 10
    },
    focused: {},
    notchedOutline: {},
    error: {}
})(props => {
    const { helper, ...other} = props
    return (
        <>
        <OutlinedInput {...other} margin='dense'/>
        {helper && <FormHelperText style={{marginLeft: 10}}>{helper}</FormHelperText>}
        </>
    )
})

// survet edit comment 에서 쓰이는 스타일
const SurveyEditCommentStyled = (props) => <InputStyled style={{background: 'white'}} {...props}/>


const InputUnBorderStyled = withStyles({
    root: {
        width: '100%',
        "& $notchedOutline": {
            borderWidth: 0
        },
        "&:hover $notchedOutline": {
            borderWidth: 0
        },
        "&$focused $notchedOutline": {
            borderWidth: 0
        }
    },
    input: {
      paddingLeft: 10
    },
    focused: {},
    notchedOutline: {}
})(props => {
    const { helper } = props
    
    return (
        <>
        <OutlinedInput {...props} margin='dense'/>
        {helper && <FormHelperText style={{marginLeft: 10}}>{helper}</FormHelperText>}
        </>
    )
})

// select 
const SelectBaseStyled = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            }
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '8.5px 26px 9px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            fontFamily: [
                'Roboto',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#ced4da',
                backgroundColor: theme.palette.background.paper,
                boxShadow: 'none',
            },
        },
  })
)(InputBase)

export {
    SelectStyled,
    SelectBaseStyled,
    InputStyled,
    SurveyEditCommentStyled,
    InputUnBorderStyled
}