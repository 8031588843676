import { memo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { replaceNotPhone } from 'utils'
import { InputAdornment, MenuItem } from '@material-ui/core'
import { PhoneIphoneOutlined as PhoneIphoneOutlinedIcon } from '@material-ui/icons'
import styled from 'styled-components'
import { isEqual } from 'lodash-es'
import Input from 'styled/Custom/Input'
import CountryFlag from 'styled/CountryFlag'
import InputSelect from 'styled/Custom/InputSelect'
import { memoryReply } from 'container/Survey/Live/Container'
import useValue from './useValue'
import { getIsDisabled } from './Base'
import ConfirmComponent from './Confirm'

const PhoneStyled = styled.div`
    
`
const PhoneNationalFlagStyled = styled.div`
    display: flex;
    
    & > div:first-child {
    
    }
    & > div:last-child {
        flex-grow: 1;
    }
    & > div + div {
        margin-left: 8px;
    }
`

const MenuStyled = styled.div`
    display: flex;
    align-items: center;

    & > div:first-child {

        margin: 0 10px;
    }
`

function PhoneComponent(props) {
    const { survey_question_no, national_flag, format, reply, refComment, isConfirm, originAnswered, onChange, onChangeState, classes } = props

    const national_flaged = reply.length === 0 ? '' : reply[0].national_flag

    const { formatMessage: f } = useIntl()

    const [ value, onChangeValue ] = useValue(originAnswered, onChange)
    
    // 전화번호 국가 변경
    const handleChangeNationalFlag = useCallback((e) => {
        const { value: national_flag } = e.target

        // 기존에 데이터가 있는지 체크
        const isData = memoryReply.rows.find(c => c.survey_question_no === survey_question_no)
        if (isData) {
            let newReplys = memoryReply.rows.map(c => c.survey_question_no === survey_question_no ? {...c, national_flag } : c)
            onChangeState(newReplys)
        } else {
            const reply = format({ survey_question_no, answer: '', national_flag })

            let newReplys = [...memoryReply.rows, reply]
            onChangeState(newReplys)
        }

    }, [ survey_question_no, format, onChangeState ])

    return (
        <>
        {
            national_flag.length > 1 ? (
                <PhoneNationalFlagStyled>   
                <div>
                    <InputSelect
                    value={ national_flaged }
                    style={{width: '100px'}}
                    padding="11px 0 10px 0"
                    border={1}
                    helper={f({id: 'component.Survey.Modules.09.phone.label'}) }
                    onChange={handleChangeNationalFlag}
                    >
                    {
                        national_flag.map(c => (
                            <MenuItem key={c} value={c}>
                                <MenuStyled>
                                    <div>
                                        <CountryFlag code={c}/>
                                    </div>
                                    <div>{c}</div>
                                </MenuStyled>   
                            </MenuItem>
                        ))
                    }
                    </InputSelect>
                </div>
                <div>
                    <Input
                        value={value}
                        onChange={(e) => onChangeValue(replaceNotPhone(e.target.value))}
                        inputProps={{inputMode: 'decimal'}} 
                        inputRef={ref => refComment.current[survey_question_no] = ref}
                        startAdornment={<InputAdornment position="start" className={classes.icon}><PhoneIphoneOutlinedIcon/></InputAdornment>}
                    />
                </div>
                </PhoneNationalFlagStyled>
            ) : (
                <PhoneStyled>   
                    <Input
                    value={value}
                    onChange={(e) => onChangeValue(replaceNotPhone(e.target.value))}
                    inputProps={{inputMode: 'decimal'}} 
                    inputRef={ref => refComment.current[survey_question_no] = ref}
                    startAdornment={<InputAdornment position="start" className={classes.icon}><PhoneIphoneOutlinedIcon/></InputAdornment>}/>
                </PhoneStyled>
            )
        }
        {
            isConfirm && (
                <ConfirmComponent survey_question_no={survey_question_no} value={value} isEqualAnswer={getIsDisabled(value, originAnswered)}/>
            )
        }
        </>
    )

}

export default memo(PhoneComponent, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.isConfirm, next.isConfirm) && 
            isEqual(prev.originAnswered, next.originAnswered) && 
            isEqual(prev.national_flag, next.national_flag) && 
            isEqual(prev.reply, next.reply) &&
            isEqual(prev.format, next.format) && 
            isEqual(prev.refComment, next.refComment) && 
            isEqual(prev.onChange, next.onChange) && 
            isEqual(prev.onChangeState, next.onChangeState) && 
            isEqual(prev.classes, next.classes)
})