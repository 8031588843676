/**
 * 요약페이지 정렬값
 */
import { getSearchCookie, setSearchCookie } from 'ts-utils/summary'

export const CHANGE = 'analysis/summary/order/CHANGE' // 값 변경시
export const INIT = 'analysis/summary/order/INIT'     // 초기 쿠키에서 값 가져올때

type InitState = {
    order: 'answer' | 'count',
    pointType: 'point' | 'count'
}

const getCookieName = (survey_no: number) => {
    return `summary-order-${survey_no}`
}

// order: answer => 보기순, count => 응답순
// pointType: point => 가중치로 계산, count => 응답수로 계산 
const initState: InitState = {
    order: 'count',
    pointType: 'point'
}

const initStateLive: InitState = {
    order: 'answer',
    pointType: 'point'
}
 
interface ActionProps {
    type: string;
    survey_no: number;
    live?: boolean;
    order?: 'answer' | 'count';
    pointType: 'point' | 'count';
}
 
export default (state = initState, action: ActionProps) => {
    if (action.type === CHANGE) {
        const order = typeof action.order === 'undefined' ? state.order : action.order
        const pointType = typeof action.pointType === 'undefined' ? state.pointType : action.pointType

        const cookieName = getCookieName(action.survey_no)
        setSearchCookie(cookieName, JSON.stringify({ order, pointType }))

        return {
            order,
            pointType
        }
    } else if (action.type === INIT) { // 초기값은 스토리지에서 가져온다.
        const cookieName = getCookieName(action.survey_no)
        const value = getSearchCookie(cookieName)
        const { order, pointType } = value ? JSON.parse(value) : action.live ? { ...initStateLive } : { ...initState }

        return { 
            order, 
            pointType
        }
    } else return state
}