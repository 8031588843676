/* 
    open update scribe 기능 (헤더와 본문에서 update가 발생하면 자동으로 업데이트)
*/
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'reducer'
import { postMessage, childPostMessage } from 'utils'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_SURVEY_MBOX_OPEN_RETURN_SURVEY_MBOX, GET_SURVEY_MBOX } from 'gql/survey_mbox'
import { getPolicyOpen } from 'policy'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { CHANGE } from 'reducer/survey/create/open'
import moment from 'moment'

const useSurveyOpen = (_survey_no) => {
    const params = useParams()
    const survey_no = _survey_no || Number(params.survey_no)

    const state = useSelector((state: RootState) => state.surveyCreateOpen)


    const dispatch = useDispatch()

    const changeState = useCallback(props => {
        const value = {
            type: CHANGE,
            open: getPolicyOpen({ open: props.open, end_date: props.end_date, end_date_used: props.end_date_used }),
            end_date: props.end_date ? Number(props.end_date) : '', 
            end_date_used: props.end_date_used
        }

        dispatch(value)

        postMessage({ 
            dispatch: value
        })

        childPostMessage({ 
            dispatch: value
        }, 'share')

    }, [dispatch])

    useQuery(GET_SURVEY_MBOX, {
        variables: { survey_no, mode: 'preview' },
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            const { surveyMbox } = res

            changeState(surveyMbox)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxOpenReturnSurveyMbox ] = useMutation(UPDATE_SURVEY_MBOX_OPEN_RETURN_SURVEY_MBOX, {
        onCompleted: async (data) => {
            const { open, end_date, end_date_used } = data.updateSurveyMboxOpenReturnSurveyMbox
            const result = {
                open,
                end_date_used,
                end_date: end_date ? moment(Number(end_date)) : null
            }
            changeState(result)
        },
        onError: async() => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const changeOpen = useCallback(async() => {
        updateSurveyMboxOpenReturnSurveyMbox({ variables: { survey_no, open: !state.open } })
    }, [state.open, survey_no, updateSurveyMboxOpenReturnSurveyMbox])

    return [state.open, changeOpen, { end_date: state.end_date, end_date_used: state.end_date_used } ]
 }
 
export default useSurveyOpen
