import gql from 'graphql-tag'

export const CREATE_WEBHOOK_URL = gql`
    mutation CreateWebhookUrl($survey_no: Int!, $webhook_url_id: ID!, $url: String!, $secret: String!, $method: String!) {
        createWebhookUrl(survey_no: $survey_no, webhook_url_id: $webhook_url_id, url: $url, secret: $secret, method: $method)
    }
`

export const UPDATE_WEBHOOK_URL_OPEN = gql`
    mutation UpdateWebhookUrlOpen($survey_no: Int!, $webhook_url_id: ID!, $open: Boolean!) {
        updateWebhookUrlOpen(survey_no: $survey_no, webhook_url_id: $webhook_url_id, open: $open)
    }
`

export const DELETE_WEBHOOK_URL = gql`
    mutation DeleteWebhookUrl($survey_no: Int!, $webhook_url_id: ID!) {
        deleteWebhookUrl(survey_no: $survey_no, webhook_url_id: $webhook_url_id)
    }
`

export const GET_WEBHOOK_URLS = gql`
    query WebhookUrls($survey_no: Int!) {
        webhookUrls(survey_no: $survey_no) {
            webhook_url_id
            url
            secret
            open
            wdate
        }
    }
`

export const GET_WEBHOOK_EVENT = gql`
    query WebhookEvent($survey_no: Int!, $webhook_event_id: ID!) {
        webhookEvent(survey_no: $survey_no, webhook_event_id: $webhook_event_id) {
            headers
            body
            status
            status_text
            udate
            retry
        }
    }
`

export const GET_WEBHOOK_EVENTS = gql`
    query WebhookEvents($survey_no: Int!, $webhook_url_id: ID!, $page: Int!, $limit: Int!, $sdate: String!, $edate: String!, $status: String!) {
        webhookEvents(survey_no: $survey_no, webhook_url_id: $webhook_url_id, page: $page, limit: $limit, sdate: $sdate, edate: $edate, status: $status) {
            count
            rows {
                webhook_event_id
                headers
                body
                status
                status_text
                is_test
                udate
                retry
            }
        }
    }
`

export interface WebhookUrlProps {
    webhook_url_id: string;
    url: string;
    secret: string;
    open: boolean;
    wdate: string;
}

export interface WebhookEventProps {
    webhook_event_id: string;
    headers: string;
    body: string;
    status: number;
    status_text: string;
    is_test: boolean;
    udate: string;
    retry: number;
}

export interface WebhookEventsProps {
    count: number;
    rows: WebhookEventProps[];
}