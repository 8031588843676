import styled from 'styled-components'
import oc from 'open-color-js'

const WrapperStyled = styled.div`
    padding-bottom: 20px;
    
    & > div:last-child {
        margin-top: 10px;
        text-align: center;
        & > * {
            color: ${oc.gray4};
            cursor: pointer;
            transition: all .3s;
            font-size: 38px;
        }
        & > *:hover {
            color: ${oc.gray6};
        }

    }
`
const BoxStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-top: ${props => props.i === 0 ? 0 : '10px'};
    

    & > div:first-child {
        width: calc(100% - 45px);
    }

    & > div:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 45px;
        text-align: right;
        color: ${oc.gray4};
        cursor: pointer;
        transition: all .3s;

    }
    & > div:last-child > * {
        font-size: 32px;
    }
    & > div:last-child:hover {
        color: ${oc.gray6};
    }
`

export {
    WrapperStyled,
    BoxStyled
}