import { useIntl } from 'react-intl'
import { Grid, Button, Typography } from '@material-ui/core'
import { GetTaxTypeName, TaxType, getTaxType } from 'ts-policy'

interface Props {
    tax_in_innopay: number;
    buttonName?: string;
    onChange: () => void
}

function NoneTaxBoxComponent(props: Props) {
    const { tax_in_innopay, buttonName, onChange } = props

    const { formatMessage: f } = useIntl()

    const taxType = getTaxType(TaxType.None, tax_in_innopay)

    return (
        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>
                <Typography color="secondary">{f({id: 'component.Payment.Receipt.NoneTaxBox.title'}, {taxType: GetTaxTypeName(taxType)})}</Typography>
            </Grid>
            <Grid item>
                <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={onChange}
                    
                >
                    {buttonName || f({id: 'component.Payment.Receipt.TaxBox.button.confirm'})}
                </Button>
            </Grid>
        </Grid>
    )
}

export default NoneTaxBoxComponent