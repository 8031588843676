export const SHOW = 'survey/create/menu/SHOW'
export const HIDE = 'survey/create/menu/HIDE'

const initState = {
    show: null,
}

export default (state = initState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                show: action.show
            }
        case HIDE:
            return {
                ...state,
                show: null
            }
        default:
            return state
    }
}