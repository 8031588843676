import styled, { css } from 'styled-components'
import youtubeUrl from 'youtube-url'
    
const YoutubeStyled = styled.div`
    width: 100%;
    

    & > div {
        position: relative;
        height: 0;
    }
    
    
    ${({ screen }) => {
        return screen === 'split' ? css`
            & > div {
                padding-bottom: 100%;
                
            }
        ` : screen === 'wide' ? css`
            & > div {
                padding-bottom: 56.25%;
            }
            
        ` : screen === 'stack' && css`
            & > div {
                padding-bottom: 56.25%;
            }
        `
    }}
    

    & > div > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

export default (props) => {
    // screen: stack float split wide
    const { src, autoplay, mute, control, screen="split" } = props

    const id = youtubeUrl.extractId(src)
    const option = `autoplay=${autoplay}&mute=${mute}&controls=${control}&modestbranding=0`

    const _src = `https://www.youtube.com/embed/${id}?${option}`

    return (
        <YoutubeStyled screen={screen}>
            <div>
                <iframe src={_src} title="video" frameBorder="0" allowFullScreen></iframe>
            </div>
        </YoutubeStyled>
    )
}