import { useState, memo, ChangeEvent } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_SURVEY_TEMPLATE_CATEGORY, CreateSurveyTemplateCategoryVariables } from 'gql/survey_template'
import { SurveyTemplateCategoryProps } from 'hoc/Survey/FetchTemplateCategory'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

interface Props {
    data: SurveyTemplateCategoryProps['surveyTemplateCategory']['data']
    refetch: SurveyTemplateCategoryProps['surveyTemplateCategory']['refetch']
}

function CreateTemplateComponent(props: Props) {
    const { data, refetch } = props

    const [ name, setName ] = useState('')
    const [ create ] = useMutation<CreateSurveyTemplateCategoryVariables>(CREATE_SURVEY_TEMPLATE_CATEGORY, {
        onCompleted: () => {
            setName('')
            refetch()
        }
    })

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.target
        setName(value)
    }

    function handleSave() {
        create({
            variables: { name }
        })
    }

    let disabled = false
    if (!name) disabled = true
    if (data.find(c => c.name === name)) disabled = true 

    return (
        <>
        <TextField variant="outlined" value={name} onChange={handleChange} size="small"/>
        <Button onClick={handleSave} disabled={disabled}>생성</Button>
        </>


    )
}

export default memo(CreateTemplateComponent)