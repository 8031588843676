// 전체 해제시 해당 값으로 itemHeart를 초기화 시킨다.
import randomString from 'randomstring'

// 전체 데이터 초기화
export const RESET = 'survey/create/logic/jumpCollapse/RESET'

// 해당값 다시 null처리
export const NULL = 'survey/create/logic/jumpCollapse/NULL'

const initState: { reset: string | null } = {
    reset: null
}

interface ActionProps {
    type: string;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case RESET:
            return {
                reset: randomString.generate(7)
            }
        case NULL:
            return {
                reset: null
            }
        default:
            return state
    }
}