import { memo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Grid, ButtonGroup, Button, Popover, Box, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FontFamilyFocusComponent from './FontFamilyFocus'

function getFonts(f) {
    return {
        'sans-serif': f({id: 'styled.Survey.FontFamilys.fonts.0'}),
        ko: {
     
            'Nanum Gothic':f({id: 'styled.Survey.FontFamilys.fonts.2'}), // 나눔 고딕,
            'NanumMyeongjo': f({id: 'styled.Survey.FontFamilys.fonts.26'}), // 나눔명조
            'NanumBarunGothic':f({id: 'styled.Survey.FontFamilys.fonts.3'}), // 나눔 바른 고딕,
            'NanumSquareRound':f({id: 'styled.Survey.FontFamilys.fonts.4'}), // 나눔 스퀘어 라운드,
            'NanumGgocNaeEum': f({id: 'styled.Survey.FontFamilys.fonts.27'}), // '나눔손글씨 꽃내음'
            'MaruBuriSemiBold': f({id: 'styled.Survey.FontFamilys.fonts.30'}), // 마루 부리
            'MalgunGothic':f({id: 'styled.Survey.FontFamilys.fonts.5'}), // 맑은 고딕
            'Noto Sans KR':f({id: 'styled.Survey.FontFamilys.fonts.1'}), // 본고딕,
            'GmarketSans': f({id: 'styled.Survey.FontFamilys.fonts.31'}), // 지마켓 산스
            'BMJUA':f({id: 'styled.Survey.FontFamilys.fonts.9'}), // 주아체
            'yg-jalnan':f({id: 'styled.Survey.FontFamilys.fonts.12'}), // 잘난체
            'paybooc-Bold':f({id: 'styled.Survey.FontFamilys.fonts.8'}), // 페이북 글꼴
            'HangeulNuri-Bold':f({id: 'styled.Survey.FontFamilys.fonts.13'}), // 한글누리
            'Arial':f({id: 'styled.Survey.FontFamilys.fonts.15'}), // Arial
            'Georgia':f({id: 'styled.Survey.FontFamilys.fonts.17'}), // Georgia
            'Roboto':f({id: 'styled.Survey.FontFamilys.fonts.14'}), // Roboto
            'Segoe UI':f({id: 'styled.Survey.FontFamilys.fonts.18'}), //Segoe UI
        },
        jp: {
            'craftmincho': f({id: 'styled.Survey.FontFamilys.fonts.19'}),
            'KosugiMaru-Regular': f({id: 'styled.Survey.FontFamilys.fonts.20'}),
            'yokomoji': f({id: 'styled.Survey.FontFamilys.fonts.25'}),
            'RampartOne-Regular': f({id: 'styled.Survey.FontFamilys.fonts.21'}),
            'ReggaeOne-Regular': f({id: 'styled.Survey.FontFamilys.fonts.22'}),
            'saruji': f({id: 'styled.Survey.FontFamilys.fonts.23'}),
            'TsunagiGothic': f({id: 'styled.Survey.FontFamilys.fonts.24'})
        }
    }
} 


// 01 02 모듈 에서 사용되는 폰트 패딩
export const moduleBaseFontPadding = {
    'sans-serif': '',
    'Noto Sans KR': '',
    'Nanum Gothic': 'padding-top: .1em',
    'NanumBarunGothic': 'padding-top: .01em',
    'NanumSquareRound': 'padding-top: .09em',
    'NanumMyeongjo': '',
    'NanumGgocNaeEum': '',
    'NanumBaReunHiPi': '',
    'NanumBarunGothicYetHangul': '',
    'MaruBuri': '',
    'GmarketSans': '',
    'MalgunGothic': 'padding-top: .02em',
    'Arita-buri-SemiBold': 'padding-top: .14em',
    'Arita-dotum-Medium': 'padding-top: .09em',
    'paybooc-Bold': '',
    'BMJUA': 'padding-top: .12em',
    'BMEULJIRO': 'padding-top: .1em',
    'BMEuljiro10yearslater': 'padding-top: .08em',
    'yg-jalnan': 'padding-top: .04em',
    'HangeulNuri-Bold': 'padding-top: .08em',
    'Roboto': 'padding-top: .09em',
    'Arial': 'padding-top: .12em',
    'Verdana': '',
    'Georgia': 'padding-top: .08em',
    'Segoe UI': '',
    'craftmincho': '',
    'KosugiMaru-Regular': '',
    'RampartOne-Regular': '',
    'ReggaeOne-Regular': '',
    'saruji': '',
    'TsunagiGothic': '',
    'yokomoji': ''
}

const MyBox = withStyles(theme => ({
    root: {
        padding: 10,
        maxWidth: 680,
        overflowY: 'hidden',
        [theme.breakpoints.down('xs')]: {
            overflowY: 'auto',
            maxWidth: '100%'
        }
    }
}))(Box)

const MyPaper = withStyles(theme => ({
    root: (props) => {
        const { selected } = props

        return {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            textAlign: 'center', 
            height: 40,
            cursor: 'pointer',
            wordBreak: 'break-all',
            padding: 5,
            lineHeight: '110%',
            transition: 'all .3s',
            color: selected ? 'white' : theme.palette.action.active,
            backgroundColor: selected ? theme.palette.primary.main : 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: 'white'
            },
            [theme.breakpoints.down('xs')]: {
                height: 55
            }
        }
    }
}))(props => <Paper {...props} variant="outlined"/>)

const MyPopover = withStyles({
    root: {
        overflowY: 'hidden'
    },
    paper: {
        overflowY: 'hidden'
    }
})(Popover)

function FontFamilyComponent(props) {
    const { value, onChange } = props

    const { formatMessage: f, locale } = useIntl()

    const lists = getFonts(f)

    const lists1 = lists.ko
    const lists2 = lists.jp

    const header1 = f({id: 'styled.Survey.FontFamilys.subHeader.0'})
    const header2 = f({id: 'styled.Survey.FontFamilys.subHeader.1'})

    /**
     * 탭 기준
     * sans-serif이면 선택 언언 탭, 선택값 있다면 해당 폰트 기준
     */
  
    const selectedLocale = value === 'sans-serif' ? locale === 'en-US' ? 'ko-KR' : locale : lists1[value] ? 'ko-KR' : 'ja-JP'
    
    const [ anchorEl, setAnchorEl ] = useState(null)

    const [ newLocalce, setNewLocale ] = useState(selectedLocale)

    const newLists = newLocalce === 'ko-KR' ? lists1 : lists2

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChange = (val) => {
        if (!val) return
        onChange(val)
        handleClose()
    }

    const handleChangeTab = (val) => {
        setNewLocale(val)
    }

    const valueText = value === 'sans-serif' ? lists['sans-serif'] : lists1[value] || lists2[value]

    const open = Boolean(anchorEl)

    useEffect(() => {
        if (open) setNewLocale(selectedLocale)
    }, [open, selectedLocale])

    return (
        <>
        <Button variant="outlined" onClick={handleOpen}>
            <span style={{ fontFamily: value }}>{valueText}</span>
        </Button>
        <MyPopover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MyBox>
                <ButtonGroup variant="contained" disableElevation color="primary" style={{marginBottom: 10}}>
                    <Button 
                        color={newLocalce === 'ko-KR' ? 'primary' : 'default'} 
                        onClick={() => handleChangeTab('ko-KR')}
                    >
                        {header1}
                    </Button>
                    <Button 
                        color={newLocalce === 'ja-JP' ? 'primary' : 'default'} 
                        onClick={() => handleChangeTab('ja-JP')}
                    >
                        {header2}
                    </Button>
                </ButtonGroup>
                <Grid container spacing={1}>
                    <Grid item xs={4} sm={4} md={4} onClick={() => handleChange('sans-serif')}>
                        <MyPaper selected={value === 'sans-serif'}>
                            <span style={{fontFamily: 'sans-serif'}}>
                                {lists['sans-serif']}
                            </span>
                        </MyPaper>
                    </Grid>
                    {
                        Object.keys(newLists).map(key => (
                            <Grid item xs={4} sm={4} md={4} 
                                key={key}
                                style={{cursor: 'pointer'}} 
                                onClick={() => handleChange(key)}
                            >
                                <MyPaper selected={value === key}>
                                    <span style={{fontFamily: key}}>
                                        {newLists[key]}
                                    </span>
                                    <FontFamilyFocusComponent autoFocus={value === key}/>
                                </MyPaper>
                            </Grid>
                        ))
                    }
                </Grid>
            </MyBox>
        </MyPopover>
        </>
    )
}

export default memo(FontFamilyComponent)