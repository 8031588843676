import styled from 'styled-components'
import oc from 'open-color-js'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '16px'
    }
}))

const WrapperStyled = styled.div`
    padding-bottom: 20px;
`

const TableStyled = styled.div`
    display: table;
    width: 100%;
    border-collapse: collapse;
    background: white;
    border-radius: 6px;

    & > div {
        display: table-row;
    }

    & > div > div {
        font-size: 15px;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        border: 1px solid ${oc.gray2};
        height: 50px;
    }

    & > div:first-child > div {
        color: black;
    }

    & > div > div:first-child {
        width: 100px;
    }
    & > div:not(:first-child) > div:first-child  > div {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    & > div > div:nth-child(2) {

    }
    & > div:not(:first-child) > div:nth-child(2) {
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
    }
    & > div > div:last-child {
        width: 80px;
    }
`

const AddTableStyled = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 10px;
`

export {
    useStyles,
    WrapperStyled,
    TableStyled,
    AddTableStyled
}