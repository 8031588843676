import { useMemo } from 'react'
import { getMimePicture } from 'utils/survey'
import { handleDisabledRightClick } from 'ts-utils'
import htmlParser from 'react-html-parser'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withSurveyPopoverImage } from 'hoc'
import Video from 'styled/Survey/Live/Video'
import Youtube from 'styled/Survey/Live/Youtube'
import { defaultDesign } from 'container/Survey/Create/NewAnalysis/Filter'

interface Props {
    print: boolean;
    img_src: string;
    question: string;
    popoverImageProps: any;
}

export const useStyles = makeStyles(theme => ({
    img: {
        maxWidth: '100%',
        borderRadius: 3,
        marginBottom: 5
    },
    video: {
        maxWidth: '100%',
        marginBottom: 10,
        '&.null': {
            marginBottom: 0
        }
    }
}))

function Component (props: Props) {
    const { img_src, question, print, popoverImageProps } = props
    const { on } = popoverImageProps

    const classes = useStyles({ src: img_src })

    const mime = getMimePicture(img_src)

    return (
        <>
            {
                (mime === 'image') ? <img src={img_src} onClick={() => on.open(img_src)} onContextMenu={handleDisabledRightClick} alt="listovey" className={classes.img} /> : 
                (mime === 'youtube' && !print) ? <div className={`video ${classes.video}`}><Youtube src={img_src} autoplay={0} mute={0} control={1} screen="stack" /></div> : 
                (mime === 'video' && !print) && <div className={`video ${classes.video}`}><Video src={img_src} autoplay={0} mute={0} control={1} screen="stack"/></div> 
            }
            {
                useMemo(() => {
                    return (
                        <div>
                            <Typography 
                                style={{wordBreak: 'break-all', fontWeight: 700,  color: `rgba(${defaultDesign.question})`, fontFamily: defaultDesign.font_family }} 
                            >
                                {htmlParser(question.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />'))}
                            </Typography>
                        </div>
                    )
                }, [ question ])
            }
        </>
    )
}

export default withSurveyPopoverImage(Component)