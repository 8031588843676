/*
** 뒤로가기 버튼
**/
import { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Tooltip from 'styled/Custom/Tooltip'

function BackComponent() {
    const { formatMessage: f } = useIntl()
    const history = useHistory()

    const states = useSelector((states: RootState) => states.surveyCreateModuleIsEdit)
    const { is } = states

    const handleMove = () => {
        if (is) {
            if (window.confirm(f({id: 'component.Outer.Back.isEditMessage'}))) history.push('/workspace')
        } else history.push('/workspace')
    }

    return (
        <Tooltip title={f({id: 'component.Outer.Component.back'})}>
            <IconButton size="small" onClick={handleMove}>
                <KeyboardBackspaceIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
    )
}

export default memo(BackComponent)