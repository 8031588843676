import { IconButton }from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const MyIconButton = withStyles({
    root: (props) => {
        const { backgroundcolor, backgroundcolorhover } = props

        return {
            background: backgroundcolor,
            transition: 'all .3s',
            '&:hover': {
                background: backgroundcolorhover,
            }
        }
    }
})(IconButton)


export default (props) => {
    const { backgroundcolor='rgba(255, 255, 255, .1)', backgroundcolorhover='rgba(255, 255, 255, .3)', onClick=() => {}, children, ...other } = props

    return (
        <MyIconButton backgroundcolor={backgroundcolor} backgroundcolorhover={backgroundcolorhover} {...other} onClick={onClick}>
            {children}
        </MyIconButton>
    )
}