import { useContext } from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import PropsContext from 'context/props'

interface Props {
    marginTop: number
    checked: boolean
    children: React.ReactNode
}

const AnswerBox = withStyles({
    root: (props: { marginTop: number, answer_button: string} ) => {
        const { marginTop=5, answer_button } = props

        return {
            marginTop,
            border: `1px dashed rgba(${answer_button})`, 
            borderRadius: 5, 
            padding: 10,
            transition: 'all .3s',
            '&.checked': {
                border: `1px solid rgba(${answer_button})`, 
            }
        }
    }
})(Box)

function VerticalSacleBox(props: Props) {
    const { marginTop, checked, children } = props

    const { design }:any = useContext(PropsContext)
    const { answer_button } = design

    return (
        <AnswerBox marginTop={marginTop} answer_button={answer_button} className={checked ? 'checked' : ''}>
            { children }
        </AnswerBox>
    )
}

export default VerticalSacleBox