import { Step } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const MyStep = withStyles({
    root: {
       cursor: 'pointer'
    }
})(props => <Step {...props} />)

export default (props) => {
    const { className="", ...other } = props
    return <MyStep {...other} className={className}/>
}