//import 'react-app-polyfill/ie11'
//import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import dotenv from 'dotenv'
import { InMemoryCache, IntrospectionFragmentMatcher, ApolloClient, ApolloLink, split } from 'apollo-boost'
import { getMainDefinition } from 'apollo-utilities'
import { createHttpLink } from "apollo-link-http"
import { ApolloProvider } from '@apollo/react-hooks'
import * as serviceWorker from './serviceWorker'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducer'
import 'index.css'
import 'animate.css'
import App from './App'
import fragment from './fragment.json'
import { cookies, getTimezoneLang } from 'utils'
import { createTheme, ThemeProvider  } from '@material-ui/core/styles'

// material-ui typography
const theme = createTheme({
	typography: {
		fontFamily: ['Roboto', 'MalgunGothic', 'sans-serif'].join(','),
		button: {
			textTransform: 'none'
		}
	}
})

const { timearea } = getTimezoneLang()

// Logined 값이 없으면 로그인 안된다.
// 브라우저 창 닫으면 로그인 해제를 위함이다.
if (cookies.get('Logined') !== 'true') {
	localStorage.removeItem('authorization')
}

dotenv.config({path: `./.env.${process.env.NODE_ENV}`})

const store = process.env.NODE_ENV === 'development' ? createStore(rootReducer) : createStore(rootReducer)

const uploadLink = createHttpLink({
    uri: `${process.env.REACT_APP_API}/graphql`,
})

let wsLink = () => {}


/* 
split 함수는 세 가지 매개 변수를 사용
* 실행할 각 작업에 대해 호출되는 함수
* 함수가 "truthy"값을 반환하는 경우 작업에 사용할 링크
* 함수가 "거짓"값을 반환하는 경우 작업에 사용할 링크
*/
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query)
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
	},
	wsLink,
	uploadLink
)

const authLink = new ApolloLink(( operation, forward ) => {

	const authorization = localStorage.getItem('authorization')
	const vipAuthorization = sessionStorage.getItem('vip.authorization')
	const lang = cookies.get('NEXT_LOCALE')
	
	operation.setContext({
		headers: {
			authorization,
			'vip.authorization': vipAuthorization,
			lang,
			timearea
		}
	})

	return forward(operation)
})

// interface 로 지정된 쿼리는 여기에 지정해줘야한다
const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData: fragment
})
  
const cache = new InMemoryCache({
	fragmentMatcher
})

const client = new ApolloClient({
	link: authLink.concat(splitLink),
	cache,
	onError: ({ networkError, graphQLErrors }) => {
		console.log('graphQLErrors', graphQLErrors)
		console.log('networkError', networkError)
	}
})

const Apps = () => (
	<ApolloProvider client={client}>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<App/>
			</ThemeProvider>
		</Provider>
	</ApolloProvider>      
)


const container = document.getElementById('root')
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(<Apps/>)

serviceWorker.unregister()
