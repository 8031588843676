import { useIntl } from 'react-intl'

/*
** 결제 종류 
** 0: 무료->유료, 동일등급에서 연장
** 1: 기간 연장없이 스탠다드 -> 프리미업 업그레이드
** 2: 기간 연장과 함께 스탠다드 -> 프리미엄 업그레이드
** 3: 다운그레이드
** 4: 별도결제
*/

export type Kind = 0 | 1 | 2 | 3 | 4

export type Month = 12 | 1

export const MonthKey = {
    12: 'twelve',
    1: 'one'
}


export enum Levels {
    Level1000="level1000",
    Level100="level100",
    Level10="level10",
    Level0="level0"
}

// 파일업로드 리미트 사이즈
export const FileUploadLimitSize = {
    level1000: 20971520,
    level100: 52428800,
    level10: 20971520,
    level0: 20971520
}

export enum PayMethod {
    Card='card',   // 카드
    Trans='trans', // 계좌이체
    Vbank='vbank', // 가상계좌
    Epay='epay',  // 간편결제
    Acard='acard', // 카드 (미사용)
    Nbank='nbank' // 무통장
}

export enum TaxType {
    None="none",
    Company="company",
    Personal="personal"
}

export enum PayResult {
    Apply='apply',
    Non_Apply='non_apply',
    Cancel='cancel'
}

export function GetPayMethodName(pay_method:PayMethod) {
    const { formatMessage: f } = useIntl()

    return f({id: `ts-policy.GetPayMethodName.${pay_method}`})
}

export function GetTaxTypeName(tax_type:TaxType) {
    const { formatMessage: f } = useIntl()

    const msg = f({id: `ts-policy.GetTaxTypeName.${tax_type}`})
    
    return msg
}

export function GetPayResult() {
    const { formatMessage: f } = useIntl()

    return {
        apply: f({id: 'ts-policy.GetPayResult.apply'}),
        non_apply: f({id: 'ts-policy.GetPayResult.non_apply'}),
        cancel: f({id: 'ts-policy.GetPayResult.cancel'})
    }
}

export function getPayResultType(adate: string | null, cdate: string | null):PayResult {

    if (cdate) return PayResult.Cancel
    else if (adate) return PayResult.Apply
    else return PayResult.Non_Apply
}

// 등급
type LevelsValue = {
    name: string,
    originName: string,
    goodsName: string,
    price: number,
    annualPrice: number
}

export const GetLevels = (level: Levels) => {
    const { formatMessage: f } = useIntl()

    const levels:{ [n: string]: LevelsValue } = {
        level0: {
            name: f({id: 'policy.GetLevels.level0.name'}),
            originName: f({id: 'policy.GetLevels.level0.originName'}),
            goodsName: f({id: 'policy.GetLevels.level0.goodsName'}),
            price: 0,
            annualPrice: 0
        },
        level10: {
            name: f({id: 'policy.GetLevels.level10.name'}),
            originName: f({id: 'policy.GetLevels.level10.originName'}),
            goodsName: f({id: 'policy.GetLevels.level10.goodsName'}),
            price: 39000,
            annualPrice: 26000
        },
        level100: {
            name: f({id: 'policy.GetLevels.level100.name'}),
            originName: f({id: 'policy.GetLevels.level100.originName'}),
            goodsName: f({id: 'policy.GetLevels.level100.goodsName'}),
            price: 59000,
            annualPrice: 39000
        },
        level1000: {
            name: f({id: 'policy.GetLevels.level1000.name'}),
            originName: f({id: 'policy.GetLevels.level1000.originName'}),
            goodsName: f({id: 'policy.GetLevels.level1000.goodsName'}),
            price: 19000,
            annualPrice: 13000
        }
    }

    return levels[level ? level.toLowerCase() : 'level0']
}

export const GetMonth = (month: Month) => {
    const { formatMessage: f } = useIntl()

    const name = f({id: `policy.GetMonth.name.${month}`})

    return {
        name
    }
}

// innopay, listovey에 값으로 구분하여 tax_type을판별
export function getTaxType(tax_type:TaxType, tax_in_innopay: number) {

    if (tax_type === TaxType.Personal || tax_in_innopay === 1) return TaxType.Personal
    else if (tax_type === TaxType.Company || tax_in_innopay === 2) return TaxType.Company
    else return TaxType.None
}