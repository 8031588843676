import { memo } from 'react'
import Dialog from 'styled/Survey/Live/Dialog'
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { isEqual } from 'lodash-es'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import { TaxType, GetTaxTypeName } from 'ts-policy'
import oc from 'open-color'

interface Props {
    open: boolean;
    taxType: TaxType;
    email: string;
    onClose: () => void;
    breakpoints: BreakPointsProps;
} 

const MuiDialogContentText = withStyles({
    root: {
        color: oc.gray[8]
    }
})(DialogContentText)

function DialogComponent(props:Props) {

    const { open, taxType, email, onClose, breakpoints } = props

    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    let msg = null

    const taxTypeName = GetTaxTypeName(taxType)

    if (taxType === TaxType.None) {
        msg = parseHtmlIntl(f({id: 'component.MyAccount.Pay.Dialog.Content.none'}))
    } else {
        msg = parseHtmlIntl(f({id: 'component.MyAccount.Pay.Dialog.Content.default'}, { email, taxType: taxTypeName } ))
    }

    return (
        <Dialog open={open} fullScreen={xs} onClose={onClose} aria-labelledby="simple-dialog-title">
            <DialogTitle>{f({id: 'component.Payment.Receipt.Dialog.title'})}</DialogTitle>
            <DialogContent>
                <MuiDialogContentText>{msg}</MuiDialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">{f({id: 'component.Payment.Receipt.Dialog.button.confirm'})}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(withBreakPoints(DialogComponent), (prev, next) => {
    return  isEqual(prev.open, next.open) && 
            isEqual(prev.taxType, next.taxType) &&
            isEqual(prev.email, next.email) && 
            isEqual(prev.onClose, next.onClose) && 
            isEqual(prev.breakpoints, next.breakpoints)            
})