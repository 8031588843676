import { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW, HIDE as ALERTSNACKBARHIDE  } from 'reducer/alertSnackbar'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as CONFIRMSHOW } from 'reducer/confirm'
import { GET_FILE, DELETE_FILE, CREATE_FILE_S3, DELETE_FILE_S3  } from 'gql/recv_file'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { getExt, getColorHexToRGB } from 'utils'
import { getMimePicture } from 'utils/survey'
import { getFileuploadName } from 'ts-utils/survey'
import moment from 'moment'
import { fileSize, getFilename } from 'ts-utils'
import { useIntl } from 'react-intl'
import { createApi } from "unsplash-js"
import { createClient } from 'pexels'
import { key as unsplashKey } from 'config/unsplash.json'
import { key as pexelsKey }  from 'config/pexels.json'
import youtubeUrl from 'youtube-url'
import randomstring from 'randomstring'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { getUserSignedUrl, uploadFile, deleteUserFile } from 'component/Survey/Modules/19/S3'
import { 
    ImageComponent, 
    ImageHorizontalComponent, 
    ImageLoadingComponent,
    ImageSearchComponent,
    ImageTypeComponent,
    ImageOutDataComponent,
    ImageOutLinkComponent,
    ImageTabsComponent,
    ImageUnsplashByComponent
} from 'component'
import { filterKinds } from 'component/Survey/Create/Design/Custom/Filter/Kind'

const acceptFile = {
    ext: [
        '.png', '.jpg', '.jpeg', '.gif', 
        '.doc', '.docx', '.hwp', '.hwpx', '.ppt', '.pptx', '.xls', '.xlsx', '.csv', '.txt', '.pdf'
    ],
    imgSize: 1048576,
    fileSize: 1048576 * 3
}

export const isImage = (ext) => {
    const imgExts = ['.png', '.jpg', '.jpeg', '.gif']
    
    return imgExts.includes(ext.toLowerCase())
}

const getMyPhotoDefaultType = (onlyImage) => {
    return onlyImage ? 1 : 0
}

const initialState = {
    tabValue: "unsplash", // unsplash, pexels, my, link(youtubeLink)
    rows: [],
    usedSize: 0,
    selected: 0,
    sLoading: true,
    sMore: false //unsplash 추가값잇는지 파악하자
}

const pexels = createClient(pexelsKey)

const unsplash = createApi({
    accessKey: unsplashKey
})

// 언스플래시 고정값
const outlink = {
    nextRows: 12, // 리스트개수
    defaultSearch: 'simple' // 기본검색어
}

// 나의 그림 설정값
const myPhoto = {
    offset: 0,
    limit: 12
}


let timeout = null // 검색어 입력시 setTimeout 객체

const MySearchBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 5
    }
})(Box)

const SearchMarginBox = withStyles({
    root: {
        height: 5
    }
})(Box)

// hideAppendUrl : 외부링크 첨부 버튼 숨기기
function Container(props) {
    const {
        hideType="undo",
        cols, // 이미지 옆으로 갯수 (없으면 breakpoins로 처리한다) 
        isVideo=false, 
        height, 
        initialSelected=false,
        onlyImage=true, // 나의 사진첩 이미지만 선택해야할때 (백그라운드이미지, 이미지 선택형 순위형)
        onClose=null, 
        onAppend=null, 
        onAppendUrl=null, 
        onChange=null, 
        hideAppendUrl=false
    } = props

    // 리스트 높이 기본값
    const defaultHeight = window.outerHeight <= 950 ? '20em' : '25em'

    const dispatch = useDispatch()
    
    const refSearch = useRef()

    // 이미지 추가 방식
    const [ tabValue, setTabValue] = useState(initialState.tabValue)

    // 리스트
    const [ rows, setRows ] = useState(initialState.rows)

    // 나의사진첩 타입 변경 (나의 사진첩 onlyImage 이면)
    const myPhotoDefaultType = getMyPhotoDefaultType(onlyImage) ? 1 : 0
    const [ myPhotoType, setMyPhotoType ] = useState(myPhotoDefaultType)

    const rowsLen = rows.length

    // 나의 그림 총 갯수
    const [ myPhotoTotal, setMyPhotoTotal ] = useState(0)

    // 선택된 리스트 src
    const [ selected, setSelected ]  = useState(initialState.selected)

    // 로딩 검색어 onchange 시, 리스트 가져올시 
    const [ sLoading, setSLoading ] = useState(initialState.sLoading)

    // 더불러올데이터잇으면 true, false
    const [ sMore, setSMore ] = useState(initialState.sMore)

    // 이미지 주소 직접입력 값
    const [ url, setUrl ] = useState('')

    const { formatMessage: f } = useIntl()

    // myPhoto data parsing
    const parsingMyPhotoData = useCallback((data) => {
        return data.map(c => ({
            no: c.recv_file_no, 
            src: c.src, 
            name: c.name, 
            subtitle: `${fileSize(Number(c.size), 2)} / ${moment(Number(c.wdate)).format('YYYY.MM.DD')}`
        }))
    }, [])

    // 데이터 가져오기 (more가 아님)
    const [getMyPhoto, { error, loading }] = useLazyQuery(GET_FILE, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: async (data) => {
            // rows state에 담는다
            setRows(parsingMyPhotoData(data.recvFile.rows))
            setMyPhotoTotal(data.recvFile.count)

            setSMore(false)
            setSelected(0)

            setTimeout(() => {
                setSMore(true)
            }, 500)
        }
    })

    // 데이터 가져오기 (more임)
    const [getMyPhotoMore, { loading: myPhotoMoreLoading }] = useLazyQuery(GET_FILE, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: async (data) => {
            // rows state를 추가로 담는다
            setRows(prevState => ([
                ...prevState,
                ...parsingMyPhotoData(data.recvFile.rows)
            ]))
            setMyPhotoTotal(data.recvFile.count)
        }
    })

    const [ createRecvFileS3 ] = useMutation(CREATE_FILE_S3, {
        onCompleted:  (data) => {
            let myPhotoDefaultTypeValue = myPhotoDefaultType

            // 이미지 선택에서는 파일업로드 파일이 이미지이면 이미지type 아니면 모두
            if (onlyImage) {
                const ext = `.${getExt(data.createRecvFileS3[0].name)}`
                if (!isImage(ext)) myPhotoDefaultTypeValue = 0
            }

            dispatch({ type: ALERTSNACKBARHIDE })
            
            refSearch.current.value = ''
            setMyPhotoType(myPhotoDefaultTypeValue)

            getMyPhoto({
                variables: {
                    offset: myPhoto.offset,
                    limit: myPhoto.limit,
                    search: '',
                    type: myPhotoDefaultTypeValue
                }
            })
        }
    })

   // const [ getOverlapFile ] = useMutation(GET_OVERLAP_FILE)

    const [ deleteRecvFile ] = useMutation(DELETE_FILE, {
        onCompleted: async () => {
        }
    })

    const [ deleteRecvFileS3 ] = useMutation(DELETE_FILE_S3, {
        onCompleted: async(data) => {
            const no = Number(data.deleteRecvFileS3)
            const is = rows.find(c => c.no === no)

            if (is) {
                const { src } = is
                const filename = getFilename(src)

                // 삭제할 s3 주소값을 담는다.
                const srcs = filterKinds.map(filter => src.replace(filename, `${filter}-${filename}`))
                srcs.push(src)

                await deleteUserFile({ srcs })
            }

            setRows(prevState => {
                const newRows = prevState.filter(c => c.no !== no)
                return newRows
            })
        }
    })

    const handleImageSelected = useCallback((no) => {

        const row =  rows.find(c => c.no === no)

        if (!row) return

        // 나의 사진첩에서 onlyImage는 이미지만 선택되게 한다
        if (tabValue === 'my' && onlyImage) {
            const ext = getExt(row.src)

            if (!isImage(`.${ext}`)) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error',
                    message: f({id: 'container.Image.Container.alert.myPhotoSelected'})
                })
                return
            }
        }
        
        setSelected(no)

        if (typeof onChange === 'function') {
         

            // background_color 는 unslpash 에서 만 제공한다
            const background_color = row.background_color ? getColorHexToRGB(row.background_color) : ''
        
            if (tabValue === "pexels") onChange({img_src: row.video, img_poster: row.src, img_name: row.name, background_color })
            else onChange({img_src: row.src, img_poster: '', img_name: row.name, background_color })


            // tracking for unsplash
            if (row.download_location) {
                unsplash.photos.trackDownload({ downloadLocation: row.download_location.toString() })
                
            }
        }
    }, [onChange, rows, tabValue, onlyImage, dispatch, f])

    const handleImage = {
        create: async(e) => {

            if (typeof e === 'undefined') {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Image.Container.alert.noFile'})
                })
                return
            }
            const selfTarget = e.target
            const files = e.target.files
            const name = files[0].name
            const ext = getExt(name)
            const size = files[0].size


            if (!acceptFile.ext.includes(`.${ext}`)) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    duration: 5000,
                    message: f({id: 'container.Image.Container.alert.acceptFile'}, {ext: acceptFile.ext.join(', ').replace(/\./g, '')})
                })

                selfTarget.value = ''

                return
            }

            if (size === 0) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Image.Container.alert.zeroFile'})
                })
                selfTarget.value = ''
                return
            }

            // 이미지랑 파일이랑  제한되는 사이즈가 다르다
            const isImageValue = isImage(`.${ext}`)
            const acceptSize = isImageValue ? acceptFile.imgSize : acceptFile.fileSize

            /* 
            이미지 파일이 아니면서, 무료 사용자라면 파일 업로드는 못한다
            if (!isImageValue && token.levels === 'level0') {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Image.Container.alert.free'}),
                    duration: 5000
                })
                selfTarget.value = ''
                return
            }
            */

            if (size > acceptSize) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Image.Container.alert.overSize'}, {uploadSize: fileSize(size, 2), acceptSize: fileSize(acceptSize)}),
                    duration: 3500
                })

                selfTarget.value = ''

                return
            }

            // 파일명 중복 검사
            try {
                /*const isOverlapFile = await getOverlapFile({variables: { filenames: [name] }})

                if (isOverlapFile.data.getOverlapFile.length > 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Image.Container.alert.overlap'}, {filenames: isOverlapFile.data.getOverlapFile.join(', ')}),
                        duration: 32000
                    })
    
                    selfTarget.value = ''
    
                    return
                }*/


                /* 
                현재 까지 저장된 파일 사이즈
                const rejectSize =  limit_file_size - (usedSize + size)

                if (rejectSize < 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Image.Container.alert.limitOverSize'}, {totalSize: fileSize(limit_file_size), rejectSize: fileSize(Math.abs(rejectSize), 2)}),
                        duration: 12000
                    })

                    selfTarget.value = ''

                    return
                }
                */

                dispatch({ type: BACKDROPSHOW })

                const signedurl = await getUserSignedUrl({ filename: name })

                const res = await uploadFile(signedurl, files[0])

                if (!res.status) {
                    dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'container.Image.Container.alert.networkError'}, { url: process.env.REACT_APP_S3 }), duration: 60000, variant: 'error' })
                    selfTarget.value = ''
                    return
                }

                const input = {
                    src: res.location,
                    name: getFileuploadName(name),
                    size,
                    type: isImageValue ? 'image' : 'file'
                }

                createRecvFileS3({variables: { input }})
                
                selfTarget.value = ''

                dispatch({ type: BACKDROPHIDE })
                
            } catch(e) {
                throw e
            }
        },
        append: () => {
            if (selected === 0) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Image.Container.alert.append'})
                })
                return
            }

            const row =  rows.find(c => c.no === selected)

            onAppend(row.src)
        }
    }

    // 나의 그림 데이터 더 가져오기
    const handleMyPhotoMore = useCallback(() => {
        // 현재 rows가 myPhotoTotal하고 같으면 전체 rows를 다 겨자 온거다
        if (rowsLen >= myPhotoTotal) return

        if (myPhotoMoreLoading) return

        getMyPhotoMore({
            variables: {
                offset: rowsLen,
                limit: myPhoto.limit,
                search: refSearch.current.value,
                type: myPhotoType
            }
        })
    }, [ rowsLen, myPhotoTotal, myPhotoType, getMyPhotoMore, refSearch, myPhotoMoreLoading ])

    // 나의 사진첩 타입 변경
    const handleMyPhotoType = useCallback((e) => {
        const type = e.target.value

        setMyPhotoType(type)
        getMyPhoto({
            variables: {
                offset: myPhoto.offset,
                limit: myPhoto.limit,
                search: refSearch.current.value,
                type
            }
        })
    }, [refSearch, getMyPhoto])


    // 나의 그림 삭제 실행
    const hanldeDeleteFileSave = useCallback(async(recv_file_no) => {
        if (rowsLen=== 0) return
        
        try {
            const is = rows.find(c => c.no === recv_file_no)

            if (is) {
                if (is.src.search(process.env.REACT_APP_CF) >= 0) {
                    deleteRecvFileS3({variables: { recv_file_no: recv_file_no }})


                } else {
                    /**
                     * 서버에 저장된 파일일경우... (이제 거의 없다..)
                     */
                    deleteRecvFile({variables: {recv_file_nos: [recv_file_no]}})
                    setRows(prevState => {
                        const newRows = prevState.filter(c => c.no !== recv_file_no)
                        return newRows
                    })
                }
            }
         
        } catch(e) {

        }
       

    }, [rows, rowsLen, deleteRecvFile, deleteRecvFileS3])

    // 나의 그림 삭제 컨펌
    const hanldeDeleteFile = useCallback((recv_file_no, is_image) => {

        const key = is_image ? 'image' : 'file'

        dispatch({ 
            type: CONFIRMSHOW, 
            title: f({id: `container.Image.Container.confirmRemove.${key}.title`}),
            subtitle: f({id: `container.Image.Container.confirmRemove.${key}.subtitle`}),
            content: f({id: 'container.Image.Container.confirmRemove.content'}),
            onActions: [ {on: () => hanldeDeleteFileSave(recv_file_no), name: f({id: 'container.Image.Container.confirmRemove.remove'}), hide: true} ]
        })

    }, [f, dispatch, hanldeDeleteFileSave])

    const handlePexelsDo = useCallback(async (page) => {
        return new Promise((resolve, reject) => {
            const search = refSearch.current.value || outlink.defaultSearch

            try {
                pexels.videos.search({ query: search, per_page: outlink.nextRows, page })
                .then(json => {
                    const { videos } = json

                    if (videos.length === 0) setSMore(false)
                    else setSMore(true)

                    const _data = videos.map(c => {
                        return {
                            no: `${c.id}${randomstring.generate(4)}`, 
                            src: c.image, 
                            name: '', 
                            subtitle: '',
                            video: c.video_files[0].link
                        }
                    })

                    resolve(_data)
                })
            } catch(e) {
                reject(e)
            }
        })
    }, [])

    const handlePexelsInit = useCallback(async () => {
        try {
            const _data = await handlePexelsDo(1)
            setRows(_data)
        } catch(e) {
            dispatch({ type: ERRORPAGESHOW })
        }
    }, [dispatch, handlePexelsDo])

    const handlePexelsMore = useCallback(async () => {
        const page = Math.ceil(rowsLen/ outlink.nextRows) + 1

        try {
            const _data = await handlePexelsDo(page)
            
            setRows(prevState => ([
                ...prevState,
                ..._data
            ]))
        } catch(e) {
            dispatch({ type: ERRORPAGESHOW })
        }
    }, [dispatch, rowsLen, handlePexelsDo])

    const handleUnsplashDo = useCallback((page) => {
        return new Promise((resolve, reject) => {
            const search = refSearch.current.value || outlink.defaultSearch

            try {
                unsplash.search
                .getPhotos({ query: search, page, perPage: outlink.nextRows, orientation: "landscape" })
                .then(json => {
                    const { results, total_pages } = json.response
                    
                    if (Number(total_pages) <= Number(page)) {
                        setSMore(false)
                    } else setSMore(true)
                    
                    const _data = results.map(c => {
                        return {
                            no: `${c.id}${randomstring.generate(4)}`, 
                            src: c.urls.regular, 
                            background_color: c.color,
                            name: c.user.name,
                            author: c.user.username,
                            download_location: c.links.download_location 
                        }
                    })
                       

                    resolve(_data)
                })
            } catch(e) {
                console.log('e', e)
                reject(e)
            }
        })
    }, [])

    const handleUnsplashInit = useCallback(async () => {
        try {
            const _data = await handleUnsplashDo(1)
            setRows(_data)
        } catch(e) {
            dispatch({ type: ERRORPAGESHOW })
        }
    }, [dispatch, handleUnsplashDo])

    const handleUnsplashMore = useCallback(async () => {
        const page = Math.ceil(rowsLen/ outlink.nextRows) + 1

        try {
            const _data = await handleUnsplashDo(page)
            
            setRows(prevState => ([
                ...prevState,
                ..._data
            ]))
        } catch(e) {
            dispatch({ type: ERRORPAGESHOW })
        }
    }, [dispatch, rowsLen, handleUnsplashDo])

    const handleSearch = {
        change: async () => {
            if (tabValue === "my") {
                
                clearTimeout(timeout)
                
                setSLoading(true)

                timeout = setTimeout(async() => {
                    getMyPhoto({
                        variables: {
                            offset: myPhoto.offset,
                            limit: myPhoto.limit,
                            search: refSearch.current.value,
                            type: myPhotoType
                        }
                    })

                    setSLoading(false)
                }, 500) 

               
            } else {
                setSLoading(true)
                
                clearTimeout(timeout)

                timeout = await setTimeout(async() => {
                    if (tabValue === "unsplash") await handleUnsplashInit()
                    else if (tabValue === "pexels") await handlePexelsInit()
                    setSLoading(false)
                }, 500) 

            }
        }
    }

    const handleOutLink = {
        change: (e) => {
            setUrl(e.target.value)
            if (hideAppendUrl) onAppendUrl({img_src: e.target.value})
        },
        append: () => {
            if (!url) return

            const mime = getMimePicture(url)

            if (!mime) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Image.Container.alert.appendUrl.nothing'})
                })

                return
            }
            
            if (mime === 'image' || !isVideo) {
                const img = document.createElement('img');
                img.src = url
    
                img.onload = function () {
                    onAppendUrl({img_src: url})
                }
    
                img.onerror = function() {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Image.Container.alert.appendUrl.image'})
                    })
                }

                return
            }

            // 유투브만제공
            if (isVideo) {
                if (!youtubeUrl.valid(url)) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Image.Container.alert.appendUrl.youtube'})
                    })
                    return
                }

                onAppendUrl({img_src: url})
            }

            setSelected('')
        }
    }

    const handleTabs = {
        change: async (e, index) => {
            if (refSearch.current) refSearch.current.value = ''

            setSLoading(true)
            await Promise.all([setTabValue(index), setRows(initialState.rows)])

            if (index === "my") {
                setMyPhotoType(myPhotoDefaultType)
                getMyPhoto({
                    variables: {
                        offset: myPhoto.offset,
                        limit: myPhoto.limit,
                        search: '',
                        type: myPhotoDefaultType
                    }
                })
            }
            else if (index === "unsplash") await handleUnsplashInit()
            else if (index === "pexels") await handlePexelsInit()

            setSLoading(false)
        }
    }


    useEffect(() => {
        if (initialSelected) {
            setSelected('')
            setUrl('')
        }
        
    }, [initialSelected])

    // 에러발생시
    useEffect(() => {
        if (error)  {
            dispatch({ type: ERRORPAGESHOW })
        }
    }, [error, dispatch])


    useEffect(() => {
        if (loading) setSLoading(true)
        else setSLoading(false)
    }, [loading])


    // 초기로딩
    useEffect(() => {
        async function _do() {
            await setSLoading(true)
            await handleUnsplashInit()
            setSLoading(false)
        }

        _do()
        
    }, [handleUnsplashInit])

    // unmount
    useEffect(() => {
        return () => {
            setSelected(0)
            setUrl('')
        }
    }, [])

    const _height = height || defaultHeight

    const ListComponent = ImageHorizontalComponent

    const isAppendUrl = typeof onAppendUrl === 'function'

    return (
        <>
        <ImageTabsComponent hideType={hideType} isVideo={isVideo} isAppendUrl={isAppendUrl} tabValue={tabValue} onChange={handleTabs.change} onClose={onClose} />
        {
            tabValue === "link" ? (
                <ImageOutLinkComponent url={url} isVideo={isVideo} hideAppendUrl={hideAppendUrl} on={handleOutLink}/>
            ) : tabValue === "unsplash" ? (
                <>
                <ImageSearchComponent tabValue={tabValue} refSearch={refSearch} on={handleSearch}/>
                <SearchMarginBox/>
                <ImageOutDataComponent rows={rows} sMore={sMore} height={_height} onMore={handleUnsplashMore}>
                {
                    sLoading ? <ImageLoadingComponent/> : <ListComponent tabValue={tabValue} cols={cols} rows={rows} selected={selected} onChange={handleImageSelected}/>
                }
                </ImageOutDataComponent>
                <ImageUnsplashByComponent/>
                </>
             ) : tabValue === "pexels" ? (
                <>
                <ImageSearchComponent tabValue={tabValue} refSearch={refSearch} on={handleSearch}/>
                <SearchMarginBox/>
                <ImageOutDataComponent rows={rows} sMore={sMore} height={_height} onMore={handlePexelsMore}>
                {
                    sLoading ? <ImageLoadingComponent/> : <ListComponent tabValue={tabValue} cols={cols} rows={rows} selected={selected} onChange={handleImageSelected}/>
                }
                </ImageOutDataComponent>
                </>
            ) : tabValue === "my"  && (
                <>
                <MySearchBox>
                    <ImageTypeComponent onChange={handleMyPhotoType} type={myPhotoType}/>
                    <ImageSearchComponent tabValue={tabValue} refSearch={refSearch} on={handleSearch}/>
                </MySearchBox>
                <SearchMarginBox/>
                <ImageOutDataComponent rows={rows} sMore={sMore} loading={loading} height={_height} onMore={handleMyPhotoMore}>
                    {
                        sLoading ? <ImageLoadingComponent/> : (
                            <ListComponent 
                                tabValue={tabValue} 
                                cols={cols} 
                                rows={rows} 
                                selected={selected} 
                                onChange={handleImageSelected}
                                onDeleteFile={hanldeDeleteFile}
                            />
                        )
                    }
                   
                </ImageOutDataComponent>
                <ImageComponent          
                    acceptExts={acceptFile.ext.join(',')}
                    imageOneSize={acceptFile.imgSize}
                    fileOneSize={acceptFile.fileSize}
                    onImage={handleImage} 
                    isAppend={typeof onAppend === 'function'}
                    isAppendUrl={typeof onAppendUrl === 'function'}
                />
                </>
            )
        }
        </>
    )
}

export default Container