import { useState, useCallback } from 'react'
import oc from 'open-color'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import { ParentProps } from './Component'
import CategoryComponent from "./Category"
import ItemsComponent from "./Items"

export type Edit = boolean

interface Props {
    data: ParentProps['data']
    edit: Edit
    survey_workspace_no?: number
}

export type Selected = number
export type OnChange = (val: Selected) => void

const MyPaper01 = withStyles(theme => ({
    root: {
        height: 'calc(100vh - 150px)',
        [theme.breakpoints.down('xs')]: {
            height: 'inherit',
            padding: 5,
            position: 'sticky',
            top: 0
        }
    }
}))(Paper)

const MyPaper02 = withStyles(theme => ({
    root: {
        height: 'calc(100vh - 150px)',
        padding: 20,
        background: oc.gray[1],
        [theme.breakpoints.down('xs')]: {
            height: 'inherit'
        }
    }
}))(Paper)

function PublishComponent(props: Props) {
    const { data, edit, survey_workspace_no } = props
    
    const [ selected, setSelected ] = useState<Selected>(data.length > 0 ? data[0].survey_template_category_no : 0)

    const handleChannge: OnChange = useCallback(val => {
        setSelected(Number(val))
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3} style={{position: 'sticky', top: -16, zIndex: 1}}>
                <MyPaper01 elevation={1}>
                    <CategoryComponent data={data} edit={edit} selected={selected} onChange={handleChannge}/>
                </MyPaper01>
            </Grid>
            <Grid item xs={12} sm={9}>
                <MyPaper02 elevation={4}>
                    <ItemsComponent categorys={data} selected={selected} edit={edit} survey_workspace_no={survey_workspace_no}/>
                </MyPaper02>
            </Grid>
        </Grid>
    )
}

export default PublishComponent