import { useCallback, useState, memo } from 'react'
import { Levels } from 'ts-policy'
import { Method } from 'gql/survey_mbox'
import { Format19 } from 'hoc/Survey/SetReplys'
import { OnChange } from './Component'
import PreviewComponent from './Preview'
import CautionComponent from './Caution'
import FileUploadComponent from './FileUpload'

interface Props {
    survey_no: number;
    mode: string;
    method: Method;
    token: string;
    levels: Levels;
    exts: string[];
    reply: Format19[];
    onChange: OnChange;
}

export type OnChangeReject = (a: string) => void;

export type OnRemoveFile = () => void;

function Dropzone(props: Props) {
    const { survey_no, mode, method, token, reply, exts, levels, onChange } = props

    const [ reject, setReject ] = useState('')

    const handleChangeReject: OnChangeReject = useCallback((msg) => {
        setReject(msg)
    }, [])

    return (
    <>
    <CautionComponent message={reject}/>
    {
        reply.length > 0 ? (
            <PreviewComponent survey_no={survey_no} mode={mode} token={token} method={method} reply={reply[0]} onRemove={onChange}/>
        ) : (
            <FileUploadComponent survey_no={survey_no} mode={mode} levels={levels} exts={exts} onChange={onChange} onChangeReject={handleChangeReject}/>
        )
    }
    </>   
    )
}

export default memo(Dropzone)