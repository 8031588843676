import gql from 'graphql-tag'
import { Month } from 'ts-policy'

export const GET_OVER_RESPONSE = gql`
    query OverResponse {
        overResponse
    }
`

export const GET_STANDARD_RESPONSE = gql`
    query StandardResponse {
        standardResponse {
            hit
            month
        }
    }
`

export const GET_USEFUL_RESPONSE = gql`
    query UsefulResponse {
        usefulResponse {
            hit
            total
            ldate
        }
    }
`

export type OverResponse = number;

export interface StandardResponse {
    hit: number;
    month: Month;
}