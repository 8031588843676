import styled from 'styled-components'

const WrapperStyled = styled.div`
    padding-top: 0;
    & > div:last-child {
        margin-top: 10px;
        text-align: center;
    }
`
const BoxStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 10px;
    }

    & > div:first-child {
        width: calc(100% - 180px);
    }
    & > div:nth-child(2) {
        width: 145px;
        font-size: 12px;
        text-align: center;
    }
    & > div:last-child {
        display: flex;
        align-items: center;
        width: 35px;
        text-align: right;
    }
`


export {
    WrapperStyled,
    BoxStyled
}