import { useContext, useState, useCallback, useEffect } from 'react'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { isEqual } from 'lodash-es'
import { VerifyIsJust } from '../01/Component'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import ItemsComponent from './Items'
import CommentComponent from '../Comment'

const Component = (props) => {
    const { mode } = useContext(AccessMethodContext)
    const { mbox, option, format, design } = useContext(PropsContext)
    const { survey_no, method } = mbox
    const { just } = option

    const { answer: answer_color, answer_button: line_color, answer_sbutton: button_color, background_color, font_family } = design

    const { lists, replyComment, is_comment, is_comment_placeholder, required=false, required_end=0, survey_question_no, label, kind, reply } = props

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState(reply)

    const handleChangeOne = useCallback(async(survey_module_no) => {
    
        // 바로 다음문항으로 이동하기 여부
        const verifyIsJust = new VerifyIsJust({ mode, method, just })
        const isJust = verifyIsJust._21(survey_module_no, is_comment)

        const reply = format._21({ survey_question_no, survey_module_no, isJust })


        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        // 전체 통합작업
        const newReplys = !survey_module_no ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no) ] :
                                              [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no), reply ]                     
                        
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)

    }, [ mode, method, just, survey_question_no, format, is_comment, onChangeReplys ])

    const handleChangeMulti = useCallback(async(survey_module_nos) => {
        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        const newReplys = [ 
            ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no),
            ...survey_module_nos.reduce((acc, c) => {
                acc.push(format._21({ survey_question_no, survey_module_no: c }))
                return acc
            }, [])
        ] 
                                      
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [ survey_question_no, format, onChangeReplys ])

    
    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <>
        <ItemsComponent 
            survey_no={survey_no}
            items={lists} 
            reply={newReply} 
            label={label} 
            answer_color={answer_color} 
            line_color={line_color} 
            button_color={button_color} 
            background_color={background_color}
            font_family={font_family}
            kind={kind}
            required={required}
            required_end={required_end}
            onChangeOne={handleChangeOne}
            onChangeMulti={handleChangeMulti}
        />
        <CommentComponent 
            marginTop="10px"
            marginBottom="5px"
            survey_question_no={survey_question_no} 
            is_comment={is_comment}
            is_comment_placeholder={is_comment_placeholder}
            comment={replyComment}
            disabled={newReply.length > 0 ? false : true}
        />
        </>
    )
}

export default Component