// 인사이트 데이터 가져오기 (device별)

import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_ANALYSIS_PAGE_VIEWS, GET_SURVEY_ANALYSIS_PAGE_VIEWS_ONE } from 'gql/survey_analysis_views'
import { numberFormat } from 'utils'
import { parseGraphqlDate, parseGraphqlDeviceType } from 'ts-utils/summary'
import { getDate } from 'styled/Survey/Analysis/Calendar'

function getProperty(props) {
    const { method, data } = props

    function parseId(id) {
        return `id-${id}`
    }

    function parseViews(cc) {
        return numberFormat(cc)
    }

    function parseDelays(views, delays) {
        const per = views === 0 ? `(0%)` : `(${((delays / views) * 100).toFixed(0)}%)`
        return `${numberFormat(delays)} ${per}`
    }

    let returnData = []

    if (method === 'multi') {
        returnData = data.map(c => ({
            id: parseId(c.survey_page_no),
            pure_question: `Section ${c.indexs}`,
            viewString: parseViews(c.views),
            delayString: parseDelays(c.views, c.delays),
            delayCount: Number(c.delays),
            page: c.indexs,
            survey_page_no: c.survey_page_no
        }))
    } else {
        returnData = data.map(c => ({
            id: parseId(c.survey_question_no),
            pure_question: c.pure_question,
            viewString: parseViews(c.views),
            delayString: parseDelays(c.views, c.delays),
            module_type: c.module_type,
            question_type: c.question_type,
            delayCount: Number(c.delays),
            number: c.number,
            survey_question_no: c.survey_question_no
        }))
    }

    return returnData
}

const usePageViewData = (props) => {
    const { survey_no, method } = props

    const dispatch = useDispatch()

    // 데이터 값을 저장한다
    const [ data, setData ] = useState([])

    const [ getDataMulti, { loading: loadingMulti, refetch: refetchMulti } ] = useLazyQuery(GET_SURVEY_ANALYSIS_PAGE_VIEWS, {
        onCompleted: (data) => {
            setData(
                getProperty({ method, data: data.surveyAnalysisPageViews })
            )
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true
    })

    const [ getDataOne, { loading: loadingOne, refetch: refetchOne } ] = useLazyQuery(GET_SURVEY_ANALYSIS_PAGE_VIEWS_ONE, {
        onCompleted: (data) => {
            setData(
                getProperty({ method, data: data.surveyAnalysisPageViewsOne })
            )
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true
    })

    const getData = useCallback((props) => {
        const { date, order, orderBy, deviceType } = props

        const { sdate, edate } = getDate(date)

        const parseDate = parseGraphqlDate(sdate, edate)
        
        const variables = {
            survey_no,
            sdate: parseDate.sdate, 
            edate: parseDate.edate,
            order,
            orderBy,
            deviceType: parseGraphqlDeviceType(deviceType)
         }

        if (method === 'multi') getDataMulti({ variables }) 
        else getDataOne({ variables })
    }, [survey_no, method, getDataMulti, getDataOne ])

    const options = method === 'multi' ? { loading: loadingMulti, refetch: refetchMulti }
                                       : { loading: loadingOne, refetch: refetchOne }

    return [ data, getData, options ]
    
}
 
export default usePageViewData
