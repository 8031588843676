export const SHOW = 'alert/SHOW'
export const HIDE = 'alert/HIDE'

const initState = {
    show: false,
    title: '',
    message: ''
}

export default (state = initState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
                title: action.title,
                message: action.message
            }
        case HIDE:
            return {
                ...state,
                show: false
            }
        default:
            return state
    }
}