/* 
    이미지 선택 팝업
*/
import { DialogTitle, DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { StyledDialog, StyledDialogContent, StyledButton } from 'styled/Modal'
import { ImageContainer } from 'container'
import { withBreakPoints } from 'hoc'
import oc from 'open-color'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
    content: {
        paddingBottom: 20,
        background: oc.gray[0]
    }
}))

// 이미지박스 스타일
function getHeight(xs) {
    return xs ? '100vh - 24em' :  ''
}

const PopupImageComponent = (props) => {
    const { title, open, saveButtonText, closeButtonText, onClose, onSave, onAppendUrl, onChangeImage, breakpoints } = props

    const { xs } = breakpoints

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <StyledDialog open={open} onClose={onClose} fullScreen={xs} maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <StyledDialogContent className={classes.content}>
                <ImageContainer height={getHeight(xs)} onAppendUrl={onAppendUrl} hideAppendUrl={true} onChange={onChangeImage}/>
            </StyledDialogContent>
            <DialogActions>
                <StyledButton onClick={onSave} color="primary">
                    {saveButtonText || f({id: 'styled.PopupImage.button.save'})}
                </StyledButton>
                <StyledButton onClick={onClose} color="inherit">
                    {closeButtonText || f({id: 'styled.PopupImage.button.close'})}
                </StyledButton>
            </DialogActions>
        </StyledDialog>
    )
 }
 
export default withBreakPoints(PopupImageComponent)
