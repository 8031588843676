import { Element } from 'react-scroll'

export default function Component(props) {
    const { 
        scrolls={
            id: '', 
            to: '', 
            bottom: ''
        }, 
        className,
        children 
    } = props

    return (
        <>
        {/* CreateBox 스타일정의는 /component/Survey/Create/Component에 위치 */}
        <Element id={scrolls.id} className={`CreateBox ${className}`}>
            <div>
                <Element name={scrolls.to}></Element>
                {children}
            </div>
            <Element name={scrolls.bottom}></Element>
        </Element>
        </>
    )
}