import { memo, useState, useCallback } from 'react'
import { UserPaymentsProps } from 'types/user_payment'
import moment from 'moment'
import oc from 'open-color'
import { useIntl } from 'react-intl'
import { GetPayResult, getPayResultType, GetTaxTypeName, getTaxType, TaxType, PayResult, PayMethod } from 'ts-policy'
import HtmlParser from 'react-html-parser'
import { numberFormat } from 'utils'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import useProductName from 'hooks/useProductName'
import Dialog from 'styled/Survey/Live/Dialog'
import { StyledTableCell } from './Component'
import DetailComponent from './Detail'

const StyledTableRow = withStyles((theme) => ({
    root: { 
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&.selected': {
            backgroundColor: `${oc.pink[1]} !important`
        }
    }
}))(TableRow)

interface Props extends UserPaymentsProps {
    selected: boolean;
    onChangeSelected: (a: number) => void;
}

function ItemComponent(props: Props) {
    const { user_payment_no, wdate, kind, levels, month, amt, appCardName, cardNum, vbankDate, order_name, pay_method, adate, cdate, tax_in_innopay, tid, receiptUrl, user_tax, user_payset, selected, onChangeSelected } = props
    const { tax_type } = user_tax || { tax_type: TaxType.None } 
    const { product_name } = user_payset || { product_name: '' }

    const [ open, setOpen ] = useState(false)
    const productName = useProductName({ kind, level: levels, month })
    
    const originName = kind === 4 ? product_name : productName

    const payResult:{[s: string]: string} = GetPayResult()

    const { formatMessage: f } = useIntl()

    const handleOpen = useCallback(() => {
        setOpen(true)
        onChangeSelected(user_payment_no)
    }, [user_payment_no, onChangeSelected])
    
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const payResultKey = getPayResultType(adate, cdate)
    const taxTypeName = pay_method === PayMethod.Card ? '   ' : GetTaxTypeName(getTaxType(tax_type, tax_in_innopay))

    return (
        <>
        <StyledTableRow className={selected ? 'selected' : ''}>
            <StyledTableCell align="left" component="th" scope="row">
                {moment(Number(wdate)).format('YYYY.MM.DD')}
            </StyledTableCell>
            <StyledTableCell>
                {originName}
            </StyledTableCell>
            <StyledTableCell>
                {f({id: 'component.MyAccount.Pay.Item.amt'}, {amt: numberFormat(amt)})}
            </StyledTableCell>
            <StyledTableCell>
                {payResult[payResultKey]}
            </StyledTableCell>
            <StyledTableCell>
                {
                    payResultKey === PayResult.Cancel ? HtmlParser(`<strike>${taxTypeName}</strike>`) : taxTypeName
                }
            </StyledTableCell>
            <StyledTableCell>
                <Button color="primary" onClick={handleOpen}>{f({id: 'component.MyAccount.Pay.Item.Button.detail'})}</Button>
            </StyledTableCell>
        </StyledTableRow>
        <Dialog open={open} onClose={handleClose}>
            <DetailComponent
                originName={originName}
                user_payment_no={user_payment_no}
                wdate={wdate}
                kind={kind}
                amt={amt}
                receiptUrl={receiptUrl}
                appCardName={appCardName}
                cardNum={cardNum}
                vbankDate={vbankDate}
                order_name={order_name}
                pay_method={pay_method}
                adate={adate}
                cdate={cdate}
                tax_in_innopay={tax_in_innopay}
                tid={tid}
                user_tax={user_tax}
                user_payset={user_payset}
                onClose={handleClose}
            />
        </Dialog>
        </>
    )
}

export default memo(ItemComponent)