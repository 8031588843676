import styled from 'styled-components'

interface Props {
    index: number;
    currentIndex: number;
}

const WrapperStyled = styled.div<Props>`
    display: ${props => props.index === props.currentIndex ? 'block' : 'none'};
`

export default WrapperStyled