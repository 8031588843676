/**
 * preview일때 Redirect 일경우 안내 메시지 보여주는 박스
 * 관리자의 언어 설정값으로 보여준다
 */
import { useSelector } from "react-redux"
import { RootState } from "reducer"
import { LangComponent } from "component"
import RedirectMessageComponent from "./RedirectMessage"

function RedirectMessageLangComponent() {
    const { lang } = useSelector((state: RootState) => state.lang)

    return (
        <LangComponent lang={lang}>
            <RedirectMessageComponent/>
        </LangComponent>
    )
}

export default RedirectMessageLangComponent