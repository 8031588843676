// 작업함 정렬
export const CHANGE = 'survey/workspace/left/utility/download/change'
export const INIT = 'survey/workspace/left/utility/download/init'

interface InitProps {
    survey_no: number | null;
}

const initState: InitProps = {
    survey_no: null
}

interface ActionProps {
    type: string;    
    survey_no: number;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                survey_no: action.survey_no
            }
        case INIT: 
            return {
                survey_no: null
            }
        default:
            return state
    }
}