import styled from 'styled-components'

const WrapperStyled = styled.div`
    padding-top: 1em;
    display: ${({show}) => show ? 'block' : 'none'};
`
// tabIndex 해당 컨첸츠 인덱스
// index 현재 선택된 인덱스
export default ({tabIndex, index, children}) => {

    return (
        <WrapperStyled show={tabIndex === index}>
            {children}
        </WrapperStyled>
    )
}