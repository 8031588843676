import { memo } from 'react'
import ImgOne from './Live/ImgOne'
import ImgMulti from './Live/ImgMulti'
import Video from 'styled/Survey/Live/Video'
import Youtube from 'styled/Survey/Live/Youtube'

// isEditList : 편집리스트 화면일때..
const PictureComponent = (props) => {
    const { method, isEditList, mime, img_src, img_poster, img_position, thanks=false } = props

    // 클래식조사이면서 시작글완료글 아닐때, 편집페이지의 화면일때(수정페이지말구 클릭전 화면), 시작글완료글의 img position이 stack일때는 기본형태로 보여준다
    return (
        ((method === 'multi' && !thanks) || (thanks && img_position ==='stack') || isEditList) ? (
            mime === 'image' ? <ImgMulti src={img_src}/> :
            mime === 'video' ? <Video src={img_src} poster={img_poster} autoplay={thanks ? 1 : 0} mute={0} control={1} screen="stack"/> :
            mime === 'youtube' && <Youtube src={img_src} autoplay={thanks ? 1 : 0} mute={0} control={1} screen="stack"/>
        ) : (
            mime === 'image' ? (
                img_position ==='stack' ? <ImgMulti src={img_src}/> :
                img_position === 'float' && <ImgOne src={img_src}/>
            ) : (
                mime === 'video' ? <Video src={img_src} poster={img_poster} autoplay={1} mute={0} control={1} screen={img_position}/> :
                mime === 'youtube' && <Youtube src={img_src} autoplay={1} mute={0} control={1} screen="stack"/>
            )
        )
    )
}

export default memo(PictureComponent)