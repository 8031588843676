import oc from 'open-color'
import { Paper, Button, ButtonGroup, Box }from '@material-ui/core'
import { makeStyles, withStyles }from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: (props) => {
        const { position } = props

        return {
            position: position || 'sticky',
            top: -15,
            zIndex: 1,
            display: 'block',
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                top: -10
            }
        }
    }
}))

const WrapperBox = withStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 7,
        paddingRight: 0
    }
}))(Box)

export default (props) => {
    const { position, borderRadius, tabs=[], index, onChange=() => {}, children } = props

    const classes = useStyles({ position })

    return (
        <div className={classes.root}>
            <Paper variant='outlined' style={{ borderRadius: borderRadius ? 5 : 0, border: `1px solid ${oc.gray[4]}`}}>
                <WrapperBox>
                    <div style={{paddingRight: 20}}>
                        {
                            tabs.length > 0 && (
                            <ButtonGroup size='small'>
                                {
                                    tabs.map((c, i) => (
                                        <Button 
                                            style={{minWidth: 80, cursor: i === index ? 'default' : 'pointer'}}
                                            key={i} 
                                            variant='contained' 
                                            color={i === index ? 'primary' : 'default'} 
                                            disableElevation={true}
                                            onClick={() => onChange(i)}
                                        >
                                            {c}
                                        </Button>
                                    ))
                                }
                            </ButtonGroup>
                            )
                        }
                    </div>
                    <div>
                        {children}
                    </div>
                </WrapperBox>
            </Paper>
        </div>
    )
}