import { Dialog, DialogContent, DialogContentText, TextField, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledDialog = withStyles(theme => ({}))((props) => <Dialog fullWidth {...props}/>)

const StyledDialogContent = withStyles({})((props) => <DialogContent dividers {...props}/>)

const StyledDialogContentText = withStyles({
    root: {
        fontSize: '14px', 
        color: 'black'
    }
})(DialogContentText)

const StyledTextField = withStyles({
    root: {
        fontWeight: 500,
        fontSize: '13px'
    }
})((props) => <TextField  variant="outlined" margin="normal" fullWidth {...props}/>)

const StyledButton = withStyles({

})(props => {
    const { children, ...other } = props
    return (
        <Button {...other}>
            <Typography variant="button">{children}</Typography>
        </Button>
    )
})

export {
    StyledDialog,
    StyledDialogContent,
    StyledDialogContentText,
    StyledTextField,
    StyledButton
}