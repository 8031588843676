import gql from 'graphql-tag'

export const SUBSCRIBE_SURVEY_WORKSPACES = gql`
    subscription SurveyWorkspaces {
        surveyWorkspaces {
            survey_workspace_no      
            title
            base              
            wdate
        }
    }
`


export const GET_SURVEY_WORKSPACES = gql`
    query SurveyWorkspaces {
        surveyWorkspaces {
            survey_workspace_no      
            title
            base              
            wdate
            mbox_count                       
        }
    }
`

export const GET_SURVEY_WORKSPACES_IN_GROUP = gql`
    query SurveyWorkspacesInGroup($survey_workspace_group_no: Int) {
        surveyWorkspacesInGroup(survey_workspace_group_no: $survey_workspace_group_no) {
            survey_workspace_no      
            title
            base              
            wdate
            mbox_count                       
        }
    }
`

export const GET_SURVEY_WORKSPACES_IN_GROUP_COUNT = gql`
    query SurveyWorkspacesInGroupCount($survey_workspace_group_no: Int) {
        surveyWorkspacesInGroupCount(survey_workspace_group_no: $survey_workspace_group_no)
    }
`

export const GET_SURVEY_MBOX_COUNT_IN_WORKSPACES = gql`
    query SurveyMboxCountInWorkspace($survey_workspace_no: Int!) {
        surveyMboxCountInWorkspace(survey_workspace_no: $survey_workspace_no)
    }
`

export const CREATE_SURVEY_WORKSPACE = gql`
    mutation CreateSurveyWorkspace($base: Boolean, $title: String!, $survey_workspace_group_no: Int!) {
        createSurveyWorkspace(base: $base, title: $title, survey_workspace_group_no: $survey_workspace_group_no)
    }
`

export const UPDATE_SURVEY_WORKSPACE_TITLE = gql`
    mutation UpdateSurveyWorkspaceTitle($survey_workspace_no: Int!, $title: String!) {
        updateSurveyWorkspaceTitle(survey_workspace_no: $survey_workspace_no, title: $title)
    }
`


export const UPDATE_SURVEY_WORKSPACE_SORTING = gql`
    mutation UpdateSurveyWorkspaceSorting($survey_workspace_nos: [Int!]!) {
        updateSurveyWorkspaceSorting(survey_workspace_nos: $survey_workspace_nos)
    }
`

export const UPDATE_SURVEY_WORKSPACE_GROUP_NO = gql`
    mutation UpdateSurveyWorkspaceGroupNo($survey_workspace_no: Int!, $target_survey_workspace_group_no: Int!) {
        updateSurveyWorkspaceGroupNo(survey_workspace_no: $survey_workspace_no, target_survey_workspace_group_no: $target_survey_workspace_group_no)
    }
`


export const DELETE_SURVEY_WORKSPACE = gql`
    mutation DeleteSurveyWorkspace($survey_workspace_no: Int!) {
        deleteSurveyWorkspace(survey_workspace_no: $survey_workspace_no)
    }
`

export interface SurveyWorkspaceProps {
    survey_workspace_no: number;
    title: string;
    base: boolean;               
    wdate: string;
    mbox_count: number;                  
}