/**
 * address1 삭제
 */
import { memo, useCallback } from 'react'
import { Props as ParentProps } from './Item'
import ClearIconComponent from 'styled/Custom/ClearIcon'

function RemoveComponent(props: Pick<ParentProps, 'zipcode' | 'address2' | 'onChange'>) {
    const { zipcode, address2, onChange } = props

    const handleClick = useCallback(() => {
        onChange({ zipcode: '', address1: '', address2 })
    }, [address2, onChange])

    if (!zipcode) return null

    return (
        <ClearIconComponent onClick={handleClick}/>
    )
}

export default memo(RemoveComponent)