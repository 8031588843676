import gql from 'graphql-tag'

export const UPDATE_SURVEY_MBOX_APPLICATION_GOOGLE_SHEET_USED = gql`
    mutation UpdateSurveyMboxApplicationGoogleSheetUsed($survey_no: Int!, $used: Boolean!) {
        updateSurveyMboxApplicationGoogleSheetUsed(survey_no: $survey_no, used: $used)
    }
`

export const UPDATE_SURVEY_MBOX_APPLICATION_GOOGLE_SHEET_TYPE = gql`
    mutation UpdateSurveyMboxApplicationGoogleSheetType($survey_no: Int!, $type: Int!) {
        updateSurveyMboxApplicationGoogleSheetType(survey_no: $survey_no, type: $type)
    }
`


export const UPDATE_SURVEY_MBOX_APPLICATION_GOOGLE_SHEET_ID = gql`
    mutation UpdateSurveyMboxApplicationGoogleSheetId($survey_no: Int!, $id: String!, $googlesheet_already_response: Boolean!) {
        updateSurveyMboxApplicationGoogleSheetId(survey_no: $survey_no, id: $id, googlesheet_already_response: $googlesheet_already_response)
    }
`

export const CREATE_SURVEY_MBOX_APPLICATION_GOOGLE_SHEET_ID = gql`
    mutation CreateSurveyMboxApplicationGoogleSheetId($survey_no: Int!, $title: String!, $googlesheet_already_response: Boolean!) {
        createSurveyMboxApplicationGoogleSheetId(survey_no: $survey_no, title: $title, googlesheet_already_response: $googlesheet_already_response)
    }
`

export const RESET_SURVEY_MBOX_APPLICATION_GOOGLE_SHEET = gql`
    mutation ResetSurveyMboxApplicationGoogleSheet($survey_no: Int!) {
        resetSurveyMboxApplicationGoogleSheet(survey_no: $survey_no)
    }
`

export const RESET_SURVEY_MBOX_APPLICATION_SLACK_PUSH = gql`
    mutation ResetSurveyMboxApplicationSlackPush($survey_no: Int!) {
        resetSurveyMboxApplicationSlackPush(survey_no: $survey_no)
    }
`

export const CREATE_SURVEY_MBOX_APPLICATION_SLACK_PUSH_ID = gql`
    mutation CreateSurveyMboxApplicationSlackPushId($survey_no: Int!, $type: SlackPushType!, $id: String!, $name: String!, $questions: [Int!]!, $etc: [String!]!) {
        createSurveyMboxApplicationSlackPushId(survey_no: $survey_no, type: $type, id: $id, name: $name, questions: $questions, etc: $etc)
    }
`

export const GET_SURVEY_MBOX_APPLICATION = gql`
    query SurveyMboxApplication($survey_no: Int!) {
        surveyMboxApplication(survey_no: $survey_no) {
            survey_mbox_application_no
            survey_no
            googlesheet_used
            googlesheet_spreadsheet_id
            googlesheet_sheet_id
            googlesheet_type
            is_googlesheet_refresh_token
            slackpush_used
            slackpush_connect
            slackpush_id
            slackpush_name
        }
    }
`

export const GET_LIMIT_DATA_GOOGLESHEET = gql`
    query LimitDataGoogleSheet($survey_no: Int!, $mode: Mode!) {
        limitDataGoogleSheet(survey_no: $survey_no, mode: $mode)
    }
`

export const UPDATE_SURVEY_MBOX_APPLICATION_SLACK_PUSH_USED = gql`
    mutation UpdateSurveyMboxApplicationSlackPushUsed($survey_no: Int!, $used: Boolean!) {
        updateSurveyMboxApplicationSlackPushUsed(survey_no: $survey_no, used: $used)
    }
`

export interface UpdateSurveyMboxApplicationGoogleSheetUsedVariables {
    survey_no: number;
    used: boolean;
}

export interface UpdateSurveyMboxApplicationGoogleSheetTypeVariables {
    survey_no: number;
    type: number;
}

export interface UpdateSurveyMboxApplicationGoogleSheetIdVariables {
    survey_no: number;
    id: string;
}

export interface CreateSurveyMboxApplicationGoogleSheetIdVariables {
    survey_no: number;
    title: string;
}

export interface ResetSurveyMboxApplicationGoogleSheetVariables {
    survey_no: number;
}

export interface ResetSurveyMboxApplicationSlackPushVariables {
    survey_no: number;
} 

export interface SurveyMboxApplication {
    survey_mbox_application_no: number;
    survey_no: number;
    googlesheet_used: boolean;
    googlesheet_spreadsheet_id: string | null;
    googlesheet_sheet_id: string | null;
    googlesheet_type: number;
    is_googlesheet_refresh_token: boolean;
    slackpush_used: boolean;
    slackpush_connect: boolean;
    slackpush_id: string;
    slackpush_name: string;
}

export interface UpdateSurveyMboxApplicationSlackPushUsedVariables {
    survey_no: number;
    used: boolean;
}