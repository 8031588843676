/**
 * 주소찾기 인풋창
 */
import { memo } from "react"
import Box from '@material-ui/core/Box'
import { withStyles } from "@material-ui/core/styles"
import Input from 'styled/Custom/Input'
import { Props as ParentProps, OnChangeFocus } from './Item'
import PostCodeButtonComponent from './PostCodeButton'

interface Props extends Pick<ParentProps, 'survey_question_no' | 'zipcode' | 'address1' | 'address2' | 'onChange'> {
    onChangeFocus: OnChangeFocus;
}

const MyBox = withStyles(theme => ({
    root: {
        display: 'flex',
        gap: 5,
        marginBottom: 5,
        flexWrap: 'wrap',
        '& > div:first-child': {
            width: '15%',
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
                flexGrow: 1
            }
        },
        '& > div:last-child': {
            flexGrow: 1
        }
    } 
}))(Box)

function PostCodeComponent(props: Props) {
    const { survey_question_no, zipcode, address1, address2, onChange, onChangeFocus } = props 

    return (
         <MyBox>
            <div>
                <Input
                    value={zipcode}
                    inputProps={{readOnly: true}}
                    style={{padding: 5}}
                    endAdornment={
                        <PostCodeButtonComponent 
                            isMobileOnly={true} 
                            survey_question_no={survey_question_no}
                            zipcode={zipcode}
                            address2={address2}
                            onChange={onChange}
                            onChangeFocus={onChangeFocus}
                        />
                    }
                />
            </div>
            <div>
                <Input
                    value={address1}
                    inputProps={{readOnly: true}}
                    endAdornment={
                        <PostCodeButtonComponent 
                            isMobileOnly={false} 
                            survey_question_no={survey_question_no} 
                            zipcode={zipcode}
                            address2={address2}
                            onChange={onChange}
                            onChangeFocus={onChangeFocus}
                        />
                    }
                    style={{padding: 5}}
                />
            </div>
        </MyBox>
    )
}

export default memo(PostCodeComponent)