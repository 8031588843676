import gql from 'graphql-tag'

export const GET_STORAGE = gql`
    query Storage {
        storage {
            myPhoto
            fileupload
            response {
                hit
                total
                ldate
            }
        }
    }
`

export interface Storage {
    myPhoto: number;
    fileupload: number;
    response: {
        hit: number;
        total: number;
        ldate: string
    }[];
}