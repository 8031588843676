// 미리보기

import { getPreviewMode } from 'utils/survey'
import { withSurveyFetchMboxWithRelation } from 'hoc'
import BodyContainer from './Body'

// preview가 실행되는 위치에따라 survey_no 가져오는 조건이 달라진다 (편집페이지, (배포페이지, 워크스페이스)) 로 구분된다
const mode = getPreviewMode()

const Component = (props) => {
    const { open, survey_no, onClose, ...other } = props

    return (
        <>
            {open && <BodyContainer survey_no={survey_no} open={open} onClose={onClose} mode={mode} {...other}/>}
        </>
    )
}

export default withSurveyFetchMboxWithRelation({
    mode
})(true)(Component)