import gql from 'graphql-tag'

export const CREATE_SURVEY_MBOX_OPTION = gql`
    mutation CreateSurveyMboxOption($survey_no: Int!) {
        createSurveyMboxOption(survey_no: $survey_no)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_PAGE_RANDOM = gql`
    mutation UpdateSurveyMboxOptionPageRandom($survey_no: Int!, $page_random: Boolean!) {
        updateSurveyMboxOptionPageRandom(survey_no: $survey_no, page_random: $page_random)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_EXCEPT_LAST_PAGE_RANDOM = gql`
    mutation UpdateSurveyMboxOptionExceptLastPageRandom($survey_no: Int!, $except_last_page_random: Boolean!) {
        updateSurveyMboxOptionExceptLastPageRandom(survey_no: $survey_no, except_last_page_random: $except_last_page_random)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_QUESTION_RANDOM = gql`
    mutation UpdateSurveyMboxOptionQuestionRandom($survey_no: Int!, $question_random: JSONObject!) {
        updateSurveyMboxOptionQuestionRandom(survey_no: $survey_no, question_random: $question_random)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_JUST = gql`
    mutation UpdateSurveyMboxOptionJust($survey_no: Int!, $just: Boolean!) {
        updateSurveyMboxOptionJust(survey_no: $survey_no, just: $just)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_ALARM = gql`
    mutation UpdateSurveyMboxOptionAlarm($survey_no: Int!, $alarm: Int!) {
        updateSurveyMboxOptionAlarm(survey_no: $survey_no, alarm: $alarm)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_IP_BLOCKS = gql`
    mutation UpdateSurveyMboxOptionIpBlocks($survey_no: Int!, $ip_blocks: Boolean!) {
        updateSurveyMboxOptionIpBlocks(survey_no: $survey_no, ip_blocks: $ip_blocks)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_IP_BLOCKS_LIST = gql`
    mutation UpdateSurveyMboxOptionIpBlocksList($survey_no: Int!, $ip_blocks_list: String!) {
        updateSurveyMboxOptionIpBlocksList(survey_no: $survey_no, ip_blocks_list: $ip_blocks_list)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_ENDBUTTON = gql`
    mutation UpdateSurveyMboxOptionEndButton($survey_no: Int!, $end_button: String, $end_button_link: String) {
        updateSurveyMboxOptionEndButton(survey_no: $survey_no, end_button: $end_button, end_button_link: $end_button_link)
    }
`

export const UPDATE_SURVEY_MBOX_OPTION_FIRST_QUESTION = gql`
    mutation UpdateSurveyMboxOptionFirstQuestion($survey_no: Int!, $first_question: Int!) {
        updateSurveyMboxOptionFirstQuestion(survey_no: $survey_no, first_question: $first_question)
    }
`

export const schemaSurveyMboxOption = `
    survey_no              
    page_random            
    except_last_page_random
    question_random
    just
    end_button
    end_button_link
    alarm
`

export const GET_SURVEY_MBOX_OPTION = gql`
    query SurveyMboxOption($survey_no: Int!, $mode: Mode) {
        surveyMboxOption(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyMboxOption}
        }
    }
`

export interface SurveyMboxOptionProps {
    survey_no: number;
    page_random: boolean;            
    except_last_page_random: boolean;
    question_random: number[];
    just: boolean;
    end_button: string | null;
    end_button_link: string | null;
    alarm: number;
    ip_blocks: boolean;
    ip_blocks_list: string | null;
}