import { useContext } from 'react'
import { useIntl } from 'react-intl'
import summaryContext from 'context/summary'
import styled, { css } from 'styled-components'
import { componentType } from 'utils/analysis'
import { FormGroup, FormControlLabel, Typography, Switch } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'

const useStyles = makeStyles({
    formGroup: (props) => {
        const { is_comment } = props

        return {
            background: 'rgba(255, 255, 255, .6)',
            borderRadius: 3,
            paddingLeft: 20,
            paddingRight: is_comment ? 16 : 0
        }
    }
})

const WrapperStyled = styled.div`
    position: absolute;
    display: flex;
    justify-content: flex-start;

    padding: 5px 1.5em;
    z-index: 2;
    
`

const VisibleStyled = styled.div`
    position: absolute; 
    top: -1000px;
    left: -1000px;
    background: rgba(0, 0, 0, .5);
    visibility: hidden;
    border-radius: 3px;
    z-index: 1;

    ${({visible}) => visible && css`
        top: 0;
        right: 0;
        bottom: 0; 
        left: 0;
        visibility: visible;
    `}
`
const DummyStyled = styled.div`
    height: 2em;
`

export default (props) => {
    const { __typename, summary_question_open, summary_comment_open, onChangeQuestion, onChangeComment } = props

    const { viewMode } = useContext(summaryContext)

    const { formatMessage: f } = useIntl()

    // 편집화면에서는 dimmed가 사용된다 (질문 히든일 경우...)
    const visible = viewMode === 'edit' && !summary_question_open

    // 입력형은 코멭트 숨기기가 없다
    const hideComment = (componentType[__typename] === 'text' || __typename === '_ending') ? true : false 


    const classes = useStyles({ is_comment: !hideComment })

    return (
        <>
        <VisibleStyled visible={visible}/>
        <WrapperStyled>
            <FormGroup row className={classes.formGroup}>
                <FormControlLabel 
                    control={<Switch color="primary" size="small" checked={summary_question_open} onChange={onChangeQuestion}/>} 
                    label={<Typography variant="caption">{f({id: 'styled.Survey.Analysis.SummaryVisible.question'})}</Typography>}
                />
                {
                    !hideComment && (
                        <div style={{ position: 'relative' }}>
                        <FormControlLabel 
                            control={<Switch color="primary" size="small" disabled={!summary_question_open} checked={summary_comment_open} onChange={onChangeComment}/>} 
                            label={<Typography variant="caption">{f({id: 'styled.Survey.Analysis.SummaryVisible.comment'})}</Typography>}
                        />
                        <div style={{ position: 'absolute', right: -6, top: 2 }}>
                            <Tooltip title={f({id: 'styled.Survey.Analysis.SummaryVisible.tooltip.comment'})}>
                                <HelpIcon color="action" fontSize="small" />
                            </Tooltip>
                        </div>

                        </div>
                    )
                }
            </FormGroup>
        </WrapperStyled>
        <DummyStyled/>
        </>
    )
    
}