import { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { getColorConcentration } from 'utils'
import PropsContext from 'context/props'

const MyButton = withStyles((theme) => ({
    root: (props) => {
        const { concent, answer_sbutton, size } = props
        return {
            color: concent ? 'white' : 'black',
            fontSize: size === 'large' ? 18 : 16,
            backgroundColor: `rgba(${answer_sbutton})`,
            transition: 'all .3s',
            padding: size === 'large' ? '12px !important' : size === 'medium' ? '10px 12px !important' : '8px 10px !important',
            '&:hover': {
                backgroundColor: `rgba(${answer_sbutton})`,
                filter: 'brightness(108%)'
            } 
        }
    },
    disabled: {
        backgroundColor: `${theme.palette.action.disabledBackground} !important`,
        color: `${theme.palette.action.disabledOpacity}`
    }
}))(Button)

export default (props) => {
    const { children, size="large", onClick, ...other } = props
    const { design } = useContext(PropsContext)
    const { answer_sbutton, font_family } = design

    const concent = getColorConcentration(answer_sbutton)

    return (
        <MyButton 
            onClick={onClick} 
            size={size} 
            concent={concent ? 1 : 0} 
            answer_sbutton={answer_sbutton}
            font_family={font_family}

            {...other}
        >
            <span style={{fontFamily: font_family, textAlign: 'left', wordBreak: 'break-all'}}>{children}</span>
        </MyButton>
    )
}