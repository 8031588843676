export const SHOW = 'loading/SHOW'
export const HIDE = 'loading/HIDE'

const initState = {
    show: false,
    message: '로딩중...'
}

export default (state = initState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
                message: action.message ? action.message : state.message 
            }
        case HIDE:
            return {
                ...state,
                show: false
            }
        default:
            return state
    }
}