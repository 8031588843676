import { useContext } from 'react'
import { Typography } from '@material-ui/core'
import PropsContext from 'context/props'
import clsx from 'clsx'
import { useStepIconClass } from './Class/StepIcon'
import { Check as CheckIcon } from '@material-ui/icons'

export function getNumber(props) {
    const { reverse, zero_number, len, index } = props

    if (zero_number) return reverse ? (len - index) : index - 1
    else return reverse ? (len - index + 1) : index
}

export default (props) => {
    const { reverse, zero_number, len, icon, active } = props

    const { design } = useContext(PropsContext)

    const classes = useStepIconClass({ backgroundcolor: design.answer_button, backgroundcolorActive: design.answer_button })

    return (
        <div className={clsx(classes.root, { [classes.active]: active })}>
            {active ? <CheckIcon/> : <Typography variant="button">{getNumber({ reverse, zero_number, len, index: icon })}</Typography>}
        </div>
    )  
}