import { memo } from 'react'
import { useIntl } from 'react-intl'
import ConfirmComponent from 'component/Survey/Edit/Confirm'
import {
    SurveyEditTabsComponent,
    SurveyEditTabComponent,
    SurveyEditQuestionComponent,
    SurveyEditSubTabsComponent
} from 'component'
import SurveyEdit09Component from 'component/Survey/Edit/09/Component'
import SurveyEdit09OptionComponent from 'component/Survey/Edit/09/Option'
import SurveyEdit09BaseComponent from 'component/Survey/Edit/09/Base/Component'
import SurveyEdit09NumberComponent from 'component/Survey/Edit/09/Number/Component'
import SurveyEdit09EmailComponent from 'component/Survey/Edit/09/Email/Component'
import SurveyEdit09PhoneComponent from 'component/Survey/Edit/09/Phone/Component'
import SurveyEdit09DateComponent from 'component/Survey/Edit/09/Date/Component'


const Component = (props) => {
    const { method } = props
    const { formatMessage: f } = useIntl()

    const tabs = [f({id: 'container.Survey.Edit.09.tabs.0'}), f({id: 'container.Survey.Edit.09.tabs.1'})]
    const subTabs = [f({id: 'container.Survey.Edit.09.subTabs.0'})]

    if (method === 'one') subTabs.push(f({id: 'container.Survey.Edit.09.subTabs.1'}))

    return (
        <>
        <SurveyEditTabsComponent tabs={tabs}/>
        <SurveyEditTabComponent index={0}>
            <SurveyEditQuestionComponent/>
            <SurveyEditSubTabsComponent subTabs={subTabs}/>
            <SurveyEdit09Component>
                <SurveyEdit09BaseComponent/>
                <SurveyEdit09PhoneComponent/>
                <SurveyEdit09NumberComponent/>
                <SurveyEdit09EmailComponent/>
                <SurveyEdit09DateComponent/>
            </SurveyEdit09Component>
            <ConfirmComponent index={1}/>
        </SurveyEditTabComponent>
        <SurveyEditTabComponent index={1}>
            <SurveyEdit09OptionComponent/>
        </SurveyEditTabComponent>
        </>
    )
}

export default memo(Component)