import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { app_name } from 'config/unsplash'

const useStyles = makeStyles({
    typography: {
        cursor: 'pointer',
        textAlign: 'right',
        fontSize: 12,
        marginTop: 5
    }
})

function visit() {
    window.open(`https://unsplash.com/?utm_source=${app_name}&utm_medium=referral`, '_blank')
}

const Component = () => {
    const classes = useStyles()

    return (
        <Typography onClick={visit} className={classes.typography}>Photos by Unsplash</Typography>
    )
}

export default Component