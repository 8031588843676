import gql from 'graphql-tag'
import { ModuleType } from './survey_question_modules'
import { JumpNo } from './jump'

export const CREATE_JUMP_POINT = gql`
    mutation CreateJumpPoint($survey_no: Int!, $jumpPoint: JSONObject!, $jumpPointText: JSONObject!) {
        createJumpPoint(survey_no: $survey_no, jumpPoint: $jumpPoint, jumpPointText: $jumpPointText)
    }
`

export const GET_JUMP_POINT_WITH_RELATION = gql`
    query JumpPointWithRelation($survey_no: Int!, $mode: Mode) {
        jumpPointWithRelation(survey_no: $survey_no, mode: $mode) {
            jumpPointText {
                jump_point_text_no 
                survey_question_no     
                value
            }
            jumpPoint {
                jump_point_no
                jump_point_text_no          
                survey_question_no     
                survey_module_no       
                survey_module_answer_no
                module_type               
                point                  
            }
         
        }
    }
`

export const GET_JUMP_POINT_AND_EXAMPLE_COUNT_EXIST = gql`
    query JumpPointAndExampleCountExist($survey_no: Int!) {
        jumpPointAndExampleCountExist(survey_no: $survey_no) {
           point
           exampleCount
         
        }
    }
`

export const DELETE_JUMP_POINT_WITH_TEXT = gql`
    mutation DeleteJumpPointWithText($survey_no: Int!) {
        deleteJumpPointWithText(survey_no: $survey_no)
    }
`

// 기본 디폴트
export interface JumpPointTextProps {
    jump_point_text_no: JumpNo; 
    survey_question_no: number;     
    value: string;
}

// 기본 디폴트
export interface JumpPointProps {
    jump_point_no: JumpNo;
    jump_point_text_no: JumpNo | null;           
    survey_question_no: number;
    survey_module_no: number | null;       
    survey_module_answer_no: number | null;
    module_type: ModuleType;                         
    point: number;
}

export interface JumpPointAndExampleCountExistProps {
    point: boolean;
    exampleCount: boolean;
}