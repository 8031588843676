import { memo } from 'react'
import stringWidth from 'string-width'
import { withSurveyPopoverImage } from 'hoc'
import { getMaxlength } from 'policy'
import { CardStyled, CommnetStyled } from 'styled/Survey/Img'
import { InputRef } from '../01/Item'
import AvatarComponent from '../17/Avatar'

function ItemComponent(props) {
    const { 
        survey_module_answer_no, 
        is_comment, 
        ranksKeyOfValue, 
        answer, 
        src, 
        mobile_one,
        myReply, 
        reply, 
        is_comment_placeholder, 
        required, 
        required_end, 
        refComment, 
        answerColor,
        onChange, 
        popoverImageProps 
    } = props

    const { on } = popoverImageProps

    // 선택된 순위값
    const { survey_module_no, comment } = myReply || { survey_module_no: '', comment: '' }

    const selected = survey_module_no !== ''
    const disabled = !selected && required && reply.length >= required_end

    return (
        <>
        <CardStyled
            answer={answer}
            src={src}
            mobile_one={mobile_one}
            answerWidth={stringWidth(answer)}
            selected={selected}
            disabled={disabled}  
            answerColor={answerColor}
            onChange={()=> onChange(survey_module_answer_no, disabled)}
            onChangeZoomImage={on.open}
        >
            {
                ranksKeyOfValue[survey_module_no] ? (
                    <AvatarComponent disabled={disabled}>
                        {ranksKeyOfValue[survey_module_no].indexs}
                    </AvatarComponent>
                ) : (
                    <AvatarComponent disabled={disabled}> </AvatarComponent>
                )
            }
        </CardStyled>
         {
            is_comment && (
                <CommnetStyled>
                    <InputRef
                        defaultValue={comment}
                        maxLength={getMaxlength('component.Survey.Modules.01.comment')}
                        disabled={survey_module_no === ''}  
                        placeholder={is_comment_placeholder}
                        ref={ref => refComment.current[survey_module_answer_no] = ref}
                    />
                </CommnetStyled>
            )
        }
        </>
    )
}

export default withSurveyPopoverImage(memo(ItemComponent))