/**
 * 완료율, 완료수 상태값
 */
import { Kind, Levels, Month } from 'ts-policy'

export const CHANGE = 'payment/choice/pay/CHANGE'
export const INIT = 'payment/choice/pay/INIT'

export type CLevels = Levels | ''
export type CMonth = Month | 0

export interface Props {
    open: boolean;
    kind: Kind;
    level: CLevels;
    month: CMonth;
    product_name: string;
    amt: number;
    discount: number;
    add_amt: number;
    user_payset_no: number; // 별도결제에서 사용
}

const initState:Props = {
    open: false,
    kind: 0,
    level: '',
    month: 0,
    product_name: '',
    amt: 0,
    discount: 0,
    add_amt: 0,
    user_payset_no: 0
}
 
interface ActionProps extends Props {
    type: string;
}
 
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                open: action.open,
                kind: action.kind || state.kind,
                level: action.level || state.level,
                month: action.month || state.month,
                product_name: action.product_name || state.product_name,
                amt: action.amt || state.amt,
                discount: action.discount || state.discount,
                add_amt: action.add_amt || state.add_amt,
                user_payset_no: action.user_payset_no || state.user_payset_no
            }
        case INIT:
            return initState
        default:
            return state
    }
}