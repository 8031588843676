import gql from 'graphql-tag'
import { schemaSurveyMboxDesign } from 'gql/survey_mbox_design'
import { schemaSurveyMboxOption } from 'gql/survey_mbox_option'
import { Levels } from 'ts-policy'

export const SUBSCRIBE_SURVEY_MBOX = gql`
    subscription SurveyMbox($survey_no: Int!, $token: ID!) {
        surveyMbox(survey_no: $survey_no, token: $token) {
            open
            end_date_used
            end_date
        }
    }
`

export const SUBSCRIBE_SURVEY_MBOX_TITLE = gql`
    subscription SurveyMboxTitle($survey_no: Int!, $token: ID!) {
        surveyMboxTitle(survey_no: $survey_no, token: $token)
    }
`

export const CREATE_SURVEY = gql`
    mutation CreateSurvey($title: String!, $method: MethodMbox!, $survey_workspace_no: Int!, $lang: Lang!) {
        createSurvey(title: $title, method: $method, survey_workspace_no: $survey_workspace_no, lang: $lang)
    }
`


export const CREATE_SURVEY_IN_CATEGORY = gql`
    mutation CreateSurveyInCategory($title: String!, $method: MethodMbox!, $survey_template_category_no: Int!, $lang: Lang!) {
        createSurveyInCategory(title: $title, method: $method, survey_template_category_no: $survey_template_category_no, lang: $lang)
    }
`


export const CREATE_SURVEY_COPY_ALL = gql`
    mutation CreateSurveyCopyAll($survey_no: Int!, $target_survey_workspace_no: Int!, $template: Boolean) {
        createSurveyCopyAll(survey_no: $survey_no, target_survey_workspace_no: $target_survey_workspace_no, template: $template)
    }
`



export const UPDATE_SURVEY_MBOX_LANG = gql`
    mutation UpdateSurveyMboxLang($survey_no: Int!, $lang: Lang!) {
        updateSurveyMboxLang(survey_no: $survey_no, lang: $lang)
    }
`

export const UPDATE_SURVEY_MBOX_PROGRESS = gql`
    mutation UpdateSurveyMboxProgress($survey_no: Int!, $progress: ProgressType!) {
        updateSurveyMboxProgress(survey_no: $survey_no, progress: $progress)
    }
`

export const UPDATE_SURVEY_MBOX_PREV_BUTTON = gql`
    mutation UpdateSurveyMboxPrevButton($survey_no: Int!, $prev_button: Boolean!) {
        updateSurveyMboxPrevButton(survey_no: $survey_no, prev_button: $prev_button)
    }
`

export const UPDATE_SURVEY_MBOX_ALLOW_IPS = gql`
    mutation UpdateSurveyMboxAllowIps($survey_no: Int!, $allow_ips: Boolean!) {
        updateSurveyMboxAllowIps(survey_no: $survey_no, allow_ips: $allow_ips)
    }
`

export const UPDATE_SURVEY_MBOX_END_DATE_USED = gql`
    mutation UpdateSurveyMboxEndDateUsed($survey_no: Int!, $end_date_used: Boolean!) {
        updateSurveyMboxEndDateUsed(survey_no: $survey_no, end_date_used: $end_date_used)
    }
`

export const UPDATE_SURVEY_MBOX_END_DATE = gql`
    mutation UpdateSurveyMboxEndDate($survey_no: Int!, $end_date: String!) {
        updateSurveyMboxEndDate(survey_no: $survey_no, end_date: $end_date)
    }
`

export const UPDATE_SURVEY_MBOX_CUT_PEOPLE = gql`
    mutation UpdateSurveyMboxCutPeople($survey_no: Int!, $cut_people_flag: Boolean!) {
        updateSurveyMboxCutPeople(survey_no: $survey_no, cut_people_flag: $cut_people_flag) {
            cut_people
            cut_people_type
        }
    }
`

export const UPDATE_SURVEY_MBOX_CUT_PEOPLE_OPTION = gql`
    mutation UpdateSurveyMboxCutPeopleOption($survey_no: Int!, $cut_people: Int!, $cut_people_type: MboxCutPeopleType!) {
        updateSurveyMboxCutPeopleOption(survey_no: $survey_no, cut_people: $cut_people, cut_people_type: $cut_people_type)
    }
`

export const UPDATE_SURVEY_MBOX_OPEN = gql`
    mutation UpdateSurveyMboxOpen($survey_no: Int!, $open: Boolean!) {
        updateSurveyMboxOpen(survey_no: $survey_no, open: $open)
    }
`

export const UPDATE_SURVEY_MBOX_RESTART = gql`
    mutation UpdateSurveyMboxRestart($survey_no: Int!, $restart: Boolean!) {
        updateSurveyMboxRestart(survey_no: $survey_no, restart: $restart)
    }
`

export const UPDATE_SURVEY_MBOX_OPEN_RETURN_SURVEY_MBOX = gql`
    mutation UpdateSurveyMboxOpenReturnSurveyMbox($survey_no: Int!, $open: Boolean!) {
        updateSurveyMboxOpenReturnSurveyMbox(survey_no: $survey_no, open: $open) {
            open
            end_date_used
            end_date
        }
    }
`

export const UPDATE_SURVEY_MBOX_DELAY = gql`
    mutation UpdateSurveyMboxDelay($survey_no: Int!, $delay: Int!) {
        updateSurveyMboxDelay(survey_no: $survey_no, delay: $delay)
    }
`

export const UPDATE_SURVEY_MBOX_TITLE = gql`
    mutation updateSurveyMboxTitle($survey_no: Int!, $title: String) {
        updateSurveyMboxTitle(survey_no: $survey_no, title: $title)
    }
`

export const UPDATE_SURVEY_MBOX_MARK_NUMBER = gql`
    mutation UpdateSurveyMboxMarkNumber($survey_no: Int!, $mark_number: Boolean!) {
        updateSurveyMboxMarkNumber(survey_no: $survey_no, mark_number: $mark_number)
    }
`

export const UPDATE_SURVEY_MBOX_MARK_REQUIRED = gql`
    mutation UpdateSurveyMboxMarkRequired($survey_no: Int!, $mark_required: Boolean!) {
        updateSurveyMboxMarkRequired(survey_no: $survey_no, mark_required: $mark_required)
    }
`

export const UPDATE_SURVEY_MBOX_METHOD = gql`
    mutation UpdateSurveyMboxMethod($survey_no: Int!, $method: MethodMbox!) {
        updateSurveyMboxMethod(survey_no: $survey_no, method: $method)
    }
`

export const UPDATE_SURVEY_MBOX_WORKSPACE_NO = gql`
    mutation UpdateSurveyMboxWorkspaceNo($survey_no: Int!, $target_survey_workspace_no: Int!) {
        updateSurveyMboxWorkspaceNo(survey_no: $survey_no, target_survey_workspace_no: $target_survey_workspace_no) 
    }
`

// 공개 비공개 설정
export const UPDATE_SURVEY_MBOX_SUMMARY_ENDING_OPEN = gql`
    mutation UpdateSurveyMboxSummaryEndingOpen($survey_no: Int!, $summary_ending_open: Boolean!) {
        updateSurveyMboxSummaryEndingOpen(survey_no: $survey_no, summary_ending_open: $summary_ending_open)
    }
`

export const DELETE_SURVEY_MBOX = gql`
    mutation DeleteSurveyMbox($survey_no: Int!) {
        deleteSurveyMbox(survey_no: $survey_no)
    }
`

const schemaSurveyMbox = `
    survey_no
    title
    mark_number
    mark_required
    method
    progress
    prev_button
    lang
    end_date_used
    end_date
    cut_people
    cut_people_type
    open
    restart
    delay_one
    delay_multi
    summary_ending_open
    allow_ips
    args
    wdate
`

export const GET_SURVEY_MBOX = gql`
    query SurveyMbox($survey_no: Int!, $mode: Mode) {
        surveyMbox(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyMbox}
        }
    }
`

export const GET_SURVEY_MBOX_WITH_RELATION  = gql`
    query SurveyMboxWithRelation($survey_no: Int!, $mode: Mode) {
        surveyMboxWithRelation(survey_no: $survey_no, mode: $mode) {
            survey_no
            title
            mark_number
            mark_required
            method
            progress
            prev_button
            lang
            end_date_used
            end_date
            cut_people
            cut_people_type
            open
            restart
            delay_one
            delay_multi
            summary_ending_open
            allow_ips
            args
            survey_mbox_design {
                ${schemaSurveyMboxDesign}
            }
            survey_mbox_option {
                ${schemaSurveyMboxOption}
                ip_blocks
                ip_blocks_list
                first_question
            }
            survey_start {
                message
                img_src
                used
            }
            idd
        }
    }
`

// 디자인 값도 같이가져온다 (Graphql에서 Join)
export const GET_SURVEY_MBOX_DESIGNS_IN_WORKSPACE = gql`
    query SurveyMboxDesignsInWorkspace($survey_workspace_no: Int!) {
        surveyMboxDesignsInWorkspace(survey_workspace_no: $survey_workspace_no) {
            survey_no
            title
            end_date_used
            end_date
            open
            args
            wdate
            survey_mbox_design {
                question
                background_color
                background_image
                background_filter
                background_opacity
                font_family
            }
            survey_analysis_user {
                today_end_count
                is_response
            }
        }
    }
`

// 디자인 값도 같이가져온다 (Graphql에서 Join)
export const GET_SURVEY_MBOX_DESIGNS_IN_WORKSPACE_ACTION_DOWNLOAD = gql`
    query SurveyMboxDesignsInWorkspaceActionDownload($survey_workspace_no: Int!, $sdate: Date, $edate: Date, $title: String) {
        surveyMboxDesignsInWorkspaceActionDownload(survey_workspace_no: $survey_workspace_no, sdate: $sdate, edate: $edate, title: $title) {
            survey_no
            title
            wdate
            survey_analysis_user {
                is_response
            }
        }
    }
`


// 디자인 값도 같이가져온다 (Graphql에서 Join)
export const GET_SURVEY_MBOX_IN_WORKSPACE = gql`
    query SurveyMboxInWorkspace($survey_workspace_no: Int!) {
        surveyMboxInWorkspace(survey_workspace_no: $survey_workspace_no) {
            survey_no
            title
        }
    }
`

// workspace and mbox 검색
export const GET_SURVEY_WORKSPACES_AND_MBOXS = gql`
    query SurveyWorkspacesAndMboxs($survey_workspace_group_no: Int!, $survey_workspace_nos: [Int!]!,  $title: String!) {
        surveyWorkspacesAndMboxs(survey_workspace_group_no: $survey_workspace_group_no, survey_workspace_nos: $survey_workspace_nos, title: $title) {
            workspaces {
                survey_workspace_no
                title
                base
            }
            mboxs {
                survey_no
                survey_workspace_no
                title
            }
        }
    }
`


export const GET_SURVEY_NO = gql`
    query SurveyNo($args: String!, $custom: Boolean) {
        surveyNo(args: $args, custom: $custom) {
            survey_no
            levels
        }
    }
`

export const GET_LEVELS_IN_SURVEY_NO = gql`
    query LevelsInSurveyNo($survey_no: Int!) {
        levelsInSurveyNo(survey_no: $survey_no)
    }
`

export enum Method {
    Multi='multi',
    One='one'
}

export enum Progress {
    Nothing='nothing',
    Text='text',
    Progress='progress',
    Dots='dots'
}

export enum Lang {
    Ko='ko',
    En='en'
}

export enum CutPeopleType {
    Connect='connect',
    Start='start',
    Completed='completed'
}

export interface SurveyMboxProps {
    survey_no: number;
    title: string;
    mark_number: boolean;
    mark_required: boolean;
    method: Method;
    progress: Progress
    prev_button: boolean;
    lang: Lang;
    end_date_used: boolean;
    end_date: string | number;
    cut_people: number;
    cut_people_type: CutPeopleType;
    open: boolean;
    delay_one: number;
    delay_multi: number;
    allow_ips: boolean;
    args: string;
    wdate: string | number;
    summary_ending_open: boolean;
    idd: number;
}

export interface SurveyNo {
    survey_no: number;
    levels: Levels;
}

export interface SurveyMboxDesignsInWorkspaceActionDownloadProps {
    survey_no: number;
    title: string;
    wdate: string;
    survey_analysis_user: {
        is_response: boolean;
    }
}