import { memo } from 'react'
import Radio from 'styled/Custom/Radio'
import { isEqual } from 'lodash-es'

function RadioComponent(props) {
    const { survey_module_no, selected } = props

    return (
        <Radio checked={selected} key={`_01-${survey_module_no}`} id={`_01-${survey_module_no}`}/>
    )
}

export default memo(RadioComponent, (prev, next) => {
    return  isEqual(prev.survey_module_no, next.survey_module_no) &&
    isEqual(prev.selected, next.selected)
})
