import gql from 'graphql-tag'

export const GET_SIGNED_URL = gql`
    query SignedUrl($survey_no: Int!, $mode: Mode!) {
        signedUrl(survey_no: $survey_no, mode: $mode)
    }
`

export const IS_FILES = gql`
    query IsFiles($srcs: JSONObject!) {
        isFiles(srcs: $srcs)
    }
`