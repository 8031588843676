import { useState, useEffect } from 'react'
import { ColorPickerComponent } from 'component'

export default (props) => {
    const { type, color: defaultColor, onChange } = props
    const [ color, setColor ] = useState('')

    const handleChange = color => {        
        setColor(color.rgb)
    }

    const handleChangeComplete = color => {        
        onChange(color)
    }

    useEffect(() => {
        const [ r, g, b, a ] = defaultColor.split(',')

        setColor({r, g, b, a})
    }, [defaultColor])

    return (
        <>
        {
            color && <ColorPickerComponent type={type} color={color} onChange={handleChange} onChangeComplete={handleChangeComplete}/>
        }
        </>
    )
}