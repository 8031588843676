import { useMemo, useContext } from 'react'
import { Context } from './Component'
import Checkbox from 'styled/Custom/Checkbox'

function ColumnAnswerChildItemComponent(props) {
    const { selected, disabled } = props

    const { getClass } = useContext(Context)

    const className = getClass(selected, disabled)

    return (
        <>
        {
            useMemo(() => {
                return (
                    <>
                    <Checkbox checked={selected} disabled={disabled}/>
                    <div className={`selected ${className}`}></div>
                    </>
                )
            }, [selected, disabled, className])
        }   
        </>
    )
}

export default ColumnAnswerChildItemComponent