import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import oc from 'open-color'
import { List, Divider, Paper } from '@material-ui/core'
import useTokenInfo from 'hooks/useTokenInfo'
import AvatarComponent from './Avatar'
import EmailComponent from './Email'
import PasswordComponent from './Password'
import OtpComponent from './Otp'

const Component = () => {
    const token = useTokenInfo()

    const location = useLocation()
    const hash = queryString.parse(location.hash)

    return (
        <List>
            <AvatarComponent token={token}/>
            <Divider/>
            <EmailComponent token={token}/>
            <Divider/>
            <PasswordComponent token={token}/>
            <Divider/>
            {
                hash.goal === 'mfa' ? (
                    <Paper elevation={24} style={{background: oc.red[9]}}>
                        <OtpComponent goal={true}/>
                    </Paper>
                ) : (
                    <OtpComponent goal={false}/>
                )
            }
        </List>
    )
}

export default Component