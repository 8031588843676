import gql from 'graphql-tag'

export const CREATE_OTP = gql`
    mutation CreateOtp {
        createOtp
    }
`

export const VERIFY_OTP = gql`
    mutation VerifyOtp($code: Int!) {
        verifyOtp(code: $code)
    }
`

export const GET_OTP = gql`
    query Otp {
        otp {
            qrcode
            approve
            used
        }
    }
`

export const UPDATE_OTP_USED = gql`
    mutation UpdateOtpUsed($used: Boolean!) {
        updateOtpUsed(used: $used)
    }
`

export interface OtpProps {
    qrcode: string;
    approve: boolean;
    used: boolean;
}