import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { HIDE as CAUTIONHIDE } from 'reducer/survey/live/caution'
import { 
    SurveyPreviewComponent, 
    SurveyPreviewMultiComponent, 
    SurveyPreviewTemplateMultiComponent, 
    SurveyPreviewOneComponent, 
    SurveyPreviewTemplateOneComponent, 
    LangComponent 
} from 'component'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { scrollerObj as _scrollerObj, getDevice } from 'utils/survey'
import { getStartsTokenname } from 'utils'
import moment from 'moment'
import { CREATE_SURVEY_ANALYSIS_USERS_IP } from 'gql/survey_analysis_users'

const method = 'ip'
const device = getDevice()

const Component = (props) => {
    const { survey_no, mbox, open, onClose, mode, refetchMboxWithRelation } = props

    const startsTokenname = getStartsTokenname(survey_no)

    sessionStorage.setItem(startsTokenname, moment())

    const dispatch = useDispatch()

    const [ token, setToken ] = useState('')
    const [ required, setRequired ] = useState(false)
    const [ scrollerObj, setScrollerObj ] = useState('')

    const [ createSurveyAnalysisUsers_IP ] = useMutation(CREATE_SURVEY_ANALYSIS_USERS_IP, {
        onCompleted: async (data) => {
            setToken(data.createSurveyAnalysisUsers_IP.token)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleChangeRequired = useCallback(() => {
        setRequired(prevState => !prevState)
    }, [])

    useEffect(() => {
       
        async function createUser() {
            await setToken('')
            dispatch({ type: BACKDROPSHOW })
            createSurveyAnalysisUsers_IP({ variables: { survey_no, device, mode }})
            setRequired(false)
            refetchMboxWithRelation()
        }

        if (open && survey_no) createUser()
        
    }, [open, createSurveyAnalysisUsers_IP, survey_no, mode, refetchMboxWithRelation, dispatch])

    // 스크롤 object는 열릴때만 호출한다
    useEffect(() => {
        if (open) setScrollerObj(_scrollerObj())
    }, [open])

    useEffect(() => {
        dispatch({ type: CAUTIONHIDE })
    }, [dispatch])

    return (
        <LangComponent lang={mbox.lang}>
            <AccessMethodContext.Provider value={{mode, method}}>
                <PropsContext.Provider value={{open, onClose, scrollerObj}}>
                    <SurveyPreviewComponent {...props} required={required} onChangeRequired={handleChangeRequired}>
                    {
                        open && survey_no && token && mbox.method === 'multi' ? (
                            mode === 'live' ? (
                                <SurveyPreviewTemplateMultiComponent required={required} survey_no={survey_no} token={token}/>
                            ) : (
                                <SurveyPreviewMultiComponent required={required} survey_no={survey_no} token={token}/>
                            )
                        ) : open && survey_no && token && mbox.method === 'one' && (
                            mode === 'live' ? (
                                <SurveyPreviewTemplateOneComponent required={required} survey_no={survey_no} token={token}/>
                            ) : (
                                <SurveyPreviewOneComponent required={required} survey_no={survey_no} token={token}/>
                            )
                        )
                    }
                    </SurveyPreviewComponent>
                </PropsContext.Provider>
            </AccessMethodContext.Provider>
        </LangComponent>
    )
}

export default Component