import { memo } from 'react'
import { useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Props as ParentProps } from './Items'

interface Props {
    show: boolean;
    answer_color: ParentProps['answer_color'];
    font_family: ParentProps['font_family'];
}

function ScrollTipComponent(props: Props) {
    const { show, answer_color, font_family } = props

    const { formatMessage: f } = useIntl()

    const message = f({id: 'component.Survey.Modules.21.ScrollTip.content'})

    if (!show) return null

    return (
        <MenuItem dense button={false} style={{paddingTop: 0, paddingBottom: 0}}>
            <ListItemText>
                <Typography component="span" variant='body2' style={{ color: `rgba(${answer_color})`, fontFamily: font_family, fontSize: 11 }}>
                    {message}
                </Typography>
            </ListItemText>
        </MenuItem>
    )
}

export default memo(ScrollTipComponent)