/**
 * 응답페이지 개인별 응답 데이터 유저번호들 (창을 띄웠다가 페이징 변경 값이 없어진다면... 창을 닫는다.)
 */
export const CHANGE = 'analysis/rows/personalIds/CHANGE' // 값 변경시
export const INIT = 'analysis/rows/personalIds/INIT'

type Type = {
    ids: number[]
}

const initState: Type = {
    ids: []
}
 
interface ActionProps {
    ids: Type['ids'];
    type: string;
}
 
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                ids: action.ids,
            }
        case INIT:
            return initState
        default:
            return state
    }
}