import { cookies } from "utils"
// graphql에 적용 되는 sdate, edate format
export function parseGraphqlDate(sdate: string | null, edate: string | null) {
    const customSdate = sdate ? `${sdate} 00:00:00` : ''
    const customEdate = edate ? `${edate} 23:59:59` : ''

    return {
        sdate: customSdate,
        edate: customEdate
    }
}

// device type
export function parseGraphqlDeviceType(deviceType: string) {
    return deviceType === 'all' ? null : deviceType
}

// 검색기능 스토리지에 저장
export function setSearchCookie(name: string, value: string | number) {
    localStorage.setItem(name, String(value))
}

export function getSearchCookie(name: string) {
    const storageValue = localStorage.getItem(name)
    if (storageValue) return storageValue

    // 2023-12-31에 삭제하자 기존 사용자땜시...
    const value = cookies.get(name)
    if (value) {
        setSearchCookie(name, value)
        cookies.remove({name, domain: `.${process.env.REACT_APP_DOMAIN}`})

        return value
    }

    return ''
}

export function removeSearchCookie(name: string) {
    return localStorage.removeItem(name)
}