import { memo, useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { SurveyMboxDesignProps } from 'gql/survey_mbox_design'
import { SurveyMboxCoverProps } from 'gql/survey_mbox_cover'
import { Levels } from 'ts-policy'
import { PLAY, STOP } from 'reducer/backdropHide'
import { getBackground } from 'utils/survey'
import { getRGBA } from 'utils/analysis'   
import { convertRgbaToRgb } from 'ts-utils'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

export enum Pos {
    Edit,
    Live,
    Preview,
    Template
}

interface Props {
    pos: Pos;
    levels?: Levels;
    brand: {
        used?: SurveyMboxCoverProps['used'];
        src: SurveyMboxCoverProps['src'];
        animation: SurveyMboxCoverProps['animation'];
    };
    design: SurveyMboxDesignProps;
}

const useStyles = makeStyles(theme => ({
    container: (props: { pos: Pos }) => {
        const { pos } = props

        return {
            background: 'white',
            position: pos === Pos.Edit ? 'absolute' : 'fixed',
            zIndex: pos === Pos.Edit ? 1 : theme.zIndex.drawer + 10000011,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            transition: 'all .7s',
            '&.hide': {
                opacity: 0,
                zIndex: -10000,
            }
        }
    },
    root: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer + 1000002,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center'
    },
    logo: {
        maxWidth: '100%',
        maxHeight: 50,
        borderRadius: 3,
    },
    listovey: {
        fontWeight: 900,
        fontFamily: 'Roboto',
        fontSize: 25,
        textShadow: '1px 2px 2px white',
        color: theme.palette.primary.dark,
    },
    avatar: {
        background: 'rgba(255, 255, 255, .1)',
        borderRadius: 100,
        padding: '10px 10px 5px 10px'
    }
}))

const useLinerStyles = makeStyles(theme => ({
    root: (props: { answer_sbutton: string }) => {
        const { answer_sbutton } = props

        const background = getRGBA({color: answer_sbutton, opacity: .3})

        return {
            marginTop: 20,
            width: 80,
            borderRadius: 5,
            background
        }
    },
    bar: (props: { answer_sbutton: string }) => {
        const { answer_sbutton } = props

        const background = getRGBA({color: answer_sbutton, opacity: 1})

        return {
            background
        }
    }
}))

let timer: ReturnType<typeof setTimeout> 

function CoverComponent(props: Props) {
    const { levels, pos, brand, design } = props
    const { used, src, animation } = brand
    const { answer_sbutton, background_color } = design
    const rgba = background_color ||  '255, 255, 255, 1'
    const rgb = '255, 255, 255'

    // 투명도가 있으면 안돼서 rgb로 변경
    const bgColor = convertRgbaToRgb(rgba, rgb)

    const style = getBackground({ ...design, background_color: bgColor })

    const dispatch = useDispatch()

    const refContainer = useRef<null | any>(null)

    const classes = useStyles({ pos })

    const classesLiner = useLinerStyles({ answer_sbutton })

    const [ unmount, setUnMount ] = useState<boolean | 1>(false)

    const css = animation ? `animate__animated ${animation}` : ''

    // 설문 응답페이지에서는 미사용이면 안보여주지만 무료사용자는 무조건 보여준다.
    let render = true
    if (pos === Pos.Live || pos === Pos.Preview) if (used === false && levels !== 'level0') render = false

    useEffect(() => {
        setUnMount(true)
    }, [brand])

    useEffect(() => {
        if (unmount === true) setUnMount(false)
    }, [unmount, refContainer])

    useEffect(() => {
        // 템플릿, 미리보기에서 리사이즈하면 다시 로딩된다... 그러므로 unmount 식별해서 커버 내려가는 스크립트 계속 실행해야한다.
        if (unmount) return

        // 설문페이지에서 커버 없어질때까지 backdrop 메시지 안보이게 처리
        dispatch({ type: ((pos === Pos.Live || pos === Pos.Preview || pos === Pos.Template) && render) ? PLAY : STOP })

        const time = pos === Pos.Live ? 2000 : 1500

        clearTimeout(timer)

        timer = setTimeout(() => {
            if (pos === Pos.Live || pos === Pos.Preview || pos === Pos.Template) {
                // 혹시나  refContainer.current 빈값일 경우 강제로 닫는다
                let flag = false

                if (refContainer) {
                    if (refContainer.current) {
                        flag = true
                        refContainer.current.classList.add("hide")
                    }
                }

                dispatch({ type: STOP })

                if (!flag) setUnMount(1)
            }
        }, time)

        return () => {
            clearTimeout(timer)
            dispatch({ type: STOP })
        }
    }, [unmount, render, pos, dispatch])

    // 설문 응답페이지에서는 미사용이면 안보여주지만 무료사용자는 무조건 보여준다.
    if (!render) return null

    if (unmount) return null

    // 무료사용자인데, 라이브에서는 리스토베이 로고를 보여준다. 애니메이션은 바운스로 한다.
    let newSrc = src

    if (pos === Pos.Live && levels === Levels.Level0) {
        newSrc = ''
    }
    
    return (
        <Box className={classes.container} style={{...style, backgroundAttachment: 'scroll' }} ref={refContainer}>
            <Box className={classes.root}>
                {  
                    newSrc ? <img src={newSrc} alt="cover" className={`${classes.logo} ${css}`}/> : <Typography className={`${classes.listovey} ${css}`}>Listovey</Typography>
                }
                <LinearProgress classes={classesLiner}/>
            </Box>
        </Box>
    )
}

export default memo(CoverComponent)