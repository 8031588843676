import gql from 'graphql-tag'

const schemaSurveyEnding = `
    survey_ending_no
    message
    pure_message
    button_name
    button_link
    analysis_name
    img_src
    img_position
    img_name
    used
    align
    sns            
    branding       
    branding_src   
    branding_size
    branding_link
    start_point
    end_point
    label
    indexs
    kind
    redirect_url
    redirect_label
`

export const CREATE_SURVEY_ENDING = gql`
    mutation CreateSurveyEnding($survey_no: Int!) {
        createSurveyEnding(survey_no: $survey_no)
    }
`

export const CREATE_SURVEY_ENDING_COPY = gql`
    mutation CreateSurveyEndingCopy($survey_no: Int!, $survey_ending_no: Int!) {
        createSurveyEndingCopy(survey_no: $survey_no, survey_ending_no: $survey_ending_no)
    }
`

export const CREATE_SURVEY_ENDING_COPY_APPEND = gql`
    mutation CreateSurveyEndingCopyAppend($survey_no: Int!, $survey_ending_no: Int!, $indexs: [Int!]!) {
        createSurveyEndingCopyAppend(survey_no: $survey_no, survey_ending_no: $survey_ending_no, indexs: $indexs)
    }
`


// 설문 응답값 저장
export const CREATE_SURVEY_ANALYSIS_ENDING = gql`
    mutation CreateSurveyAnalysisEnding($survey_no: Int!, $token: ID!, $survey_ending_no: Int, $mode: Mode!) {
        createSurveyAnalysisEnding(survey_no: $survey_no, token: $token, survey_ending_no: $survey_ending_no, mode: $mode) {
            ${schemaSurveyEnding}
        }
    }
`

export const UPDATE_SURVEY_ENDING_SORT = gql`
    mutation UpdateSurveyEndingSort($survey_no: Int!, $indexs: [Int!]!) {
        updateSurveyEndingSort(survey_no: $survey_no, indexs: $indexs)
    }
`

export const UPDATE_SURVEY_ENDING = gql`
    mutation UpdateSurveyEnding($input: UpdateSurveyEnding!) {
        updateSurveyEnding(input: $input)
    }
`

export const UPDATE_SURVEY_ENDING_LABEL = gql`
    mutation UpdateSurveyEndingLabel($survey_no: Int!, $survey_ending_no: Int!, $label: String!) {
        updateSurveyEndingLabel(survey_no: $survey_no, survey_ending_no: $survey_ending_no, label: $label)
    }
`

export const UPDATE_SURVEY_ENDING_POINTS = gql`
    mutation UpdateSurveyEndingPoints($survey_no: Int!, $data: JSONObject!) {
        updateSurveyEndingPoints(survey_no: $survey_no, data: $data)
    }
`

export const UPDATE_SURVEY_ENDING_REDIRECT = gql`
    mutation UpdateSurveyEndingRedirect($survey_no: Int!, $survey_ending_no: Int!, $redirect_url: String!, $redirect_label: String!) {
        updateSurveyEndingRedirect(survey_no: $survey_no, survey_ending_no: $survey_ending_no, redirect_url: $redirect_url, redirect_label: $redirect_label)
    }
`

export const DELETE_SUVEY_ENDING = gql`
    mutation DeleteSurveyEnding($survey_no: Int!, $survey_ending_no: Int!) {
        deleteSurveyEnding(survey_no: $survey_no, survey_ending_no: $survey_ending_no)
    }
`

export const GET_SURVEY_ENDING = gql`
    query SurveyEnding($survey_no: Int!, $survey_ending_no: Int!, $mode: Mode) {
        surveyEnding(survey_no: $survey_no, survey_ending_no: $survey_ending_no, mode: $mode) {
            ${schemaSurveyEnding}
        }
    }
`

export const GET_SURVEY_ENDINGS = gql`
    query SurveyEndings($survey_no: Int!, $mode: Mode) {
        surveyEndings(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyEnding}
        }
    }
`

export const GET_SURVEY_ENDING_USER = gql`
    query SurveyEndingUser($survey_no: Int!, $token: ID!, $mode: Mode!) {
        surveyEndingUser(survey_no: $survey_no, token: $token, mode: $mode) {
            ${schemaSurveyEnding}
        }
    }
`

export interface SurveyEndingProps {
    survey_ending_no: number;
    message: string;
    pure_message: string;
    button_name: string;
    button_link: string;
    analysis_name: string;
    img_src: string;
    img_position: string;
    img_name: string;
    used: boolean;
    align: string;
    sns: boolean;            
    branding: string;       
    branding_src: string;   
    branding_size: string;
    branding_link: string; 
    start_point: number;
    end_point: number;
    label: string;
    indexs: number;
    kind: 'content' | 'redirect';
    redirect_url: string | null;
    redirect_label: string | null;
}