import { useReducer, useEffect, useCallback } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { GET_SURVEY_QUESTIONS_MODULE } from 'gql/survey_question_modules'
import { GET_SURVEY_PAGE_TOKEN } from 'gql/survey_page'

// 초기값
const initialState = {
    page: 0, // 선택된 페이지의 survey_page_no
    pages: [], // 페이지 리스트
    firstPage: 0 // 첫번째 survey_page_no를 구해서 시작글에서 시작누를시 넘긴다
}


const handleReducer = (state, action) => {
    switch (action.type) {
        case 'firstPage': { // firstPage 값
            return { ...state, firstPage: action.firstPage }
        }
        case 'pages': { // 페이지목록
            return { ...state, pages: action.pages }
        }
        case 'page': { // 선택 페이지
            return { ...state, page: action.page }
        }
        case 'reset': {
            return initialState
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}

let currentSurveyPageNo = -1

export default mode => WrappedComponent => props => {
    const params = useParams()
    const location = useLocation()
    const dispatch = useDispatch()
    
    const { hash } = location

    const { surveyAnalysisUserRow } = props
    
    if (surveyAnalysisUserRow) {
        currentSurveyPageNo = surveyAnalysisUserRow.survey_page_no
    }

    // 페이징 파라미터
    const hashParams = queryString.parse(hash)


    // hash로 넘어온 페이지 값이 없거나 미리보기일경우 페이지는 0부터 시작
    const page = mode === 'edit' ? (Number(hashParams.page) || 0) : currentSurveyPageNo

    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    const [ states, dispatchStates ] = useReducer(handleReducer, {
        ...initialState,
        page
    })

    const [ getQuestions, { refetch: refetchQuestions, loading: loadingQuestions, data: dataQuestions } ] = useLazyQuery(GET_SURVEY_QUESTIONS_MODULE, {
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })


    const { refetch: refetchPages } = useQuery(GET_SURVEY_PAGE_TOKEN, {
        variables: { 
            survey_no,
            token: props.token
        },
        onCompleted: async (c) => { 
            if (c.surveyPage.length) {
                dispatchStates({ type: 'pages', pages: [ ...c.surveyPage.map((d, i) => ({survey_page_no: d.survey_page_no, indexs: (i+1)})) ] })

                // page가 0이고 시작글이 있을경우 아무것도 안한다 시작글부터 보여줘야해서 (firtst Page에 1페이지 번호를 담는다 시작글에서 1페이지로 넘겨야 하므로)
                // 시작글이 없는데 page가 0일경우 1페이지 보여준다
                const firstPage = c.surveyPage[0].survey_page_no

                if (mode === 'edit') {
                    // 편집화면일경우 page가 일경우 첫번째 페이지로 보낸다
                    if (page === 0) dispatchStates({type: 'page', page: firstPage })
                }
                else if (currentSurveyPageNo === 0) {
                    dispatchStates({type: 'firstPage', firstPage })
                }
                else {
                    // 시작 누르고 1페이지 와서 새로고침하고 다시 이전으로 가서 시작페이지로 갈경우 firstPage를 입력해줘야한다
                    dispatchStates({type: 'firstPage', firstPage })
                    dispatchStates({type: 'page', page: currentSurveyPageNo })
                }
            }

            getQuestions({
                variables: { 
                    survey_no,
                    token: props.token,
                    mode
                }
            })
               
        },
        onError: (e) => {
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    
    const hanndleChangePage = useCallback((survey_page_no) => {
        dispatch({type: BACKDROPSHOW})
        setTimeout(() => {
            dispatchStates({type: 'page', page: survey_page_no})
        }, 100)
    }, [dispatch])

    useEffect(() => {
        dispatchStates({type: 'reset'})
    }, [params.survey_no])


    // 설문 편집, 실설문에서는 페이지값을 해시값으로 준다
    useEffect(() => {
        dispatchStates({type: 'page', page})
    }, [page])

    // 여러군데서 사용되기때문에 마운트시 백드롭
    useEffect(() => {
        dispatch({type: BACKDROPSHOW})
    }, [dispatch])

    if (!dataQuestions) return null

    return <WrappedComponent 
            {...states}
            questions={dataQuestions.surveyQuestionsModule}
            refetchQuestions={refetchQuestions}
            refetchPages={refetchPages}
            loadingQuestions={loadingQuestions}
            onChangePage={hanndleChangePage}
            {...props}
        />
}