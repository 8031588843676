import { memo } from 'react'
import { getMaxlength } from 'policy'
import ItemComponent from './Item'
import { CommnetStyled } from 'styled/Survey/Img'
import { isEqual } from 'lodash-es'
import { InputRef } from '../01/Item'

function ItemsComponent(props) {
    const { lists, reply, icon_type, mobile_one, is_comment_placeholder, required, required_end, refComment, answerColor, onChange } = props

    return (
        <>
            {
                lists.map(c => {
                    const { answer, src, survey_module_no, is_comment } = c
                    const _self = reply.find(d => d.survey_module_no === c.survey_module_no)
                    const selected = _self ? true : false
                    const comment = _self ? _self.comment : ''
                                
                    // required_end 만큼 체크가 됏으면 나머지는 disabled 처리한다. (선택된값은 해제도 해야하기때문 disabled 처리안한다)
                    const disabled = reply.length > 0 ? 
                                    !selected && required && required_end > 0 && reply.length >= required_end ? true : false
                                    : false

                    return (
                        <div key={`_13-${survey_module_no}`}>
                            <ItemComponent
                                answer={answer}
                                src={src}
                                mobile_one={mobile_one}
                                icon_type={icon_type}
                                selected={selected}
                                disabled={disabled}
                                survey_module_no={survey_module_no} 
                                answerColor={answerColor}
                                onChange={onChange}
                            />
                            {is_comment && (
                                <CommnetStyled>
                                    <InputRef
                                    defaultValue={comment}
                                    maxLength={getMaxlength('component.Survey.Modules.13.comment')}
                                    disabled={!selected}  
                                    placeholder={is_comment_placeholder}
                                    ref={ref => refComment.current[survey_module_no] = ref}
                                    />
                                </CommnetStyled>
                            )} 
                        </div>
                    )
                })
            }    
        </>
    )
}

export default memo(ItemsComponent, (prev, next) => {
    return  isEqual(prev.lists, next.lists) && 
            isEqual(prev.reply, next.reply) && 
            isEqual(prev.icon_type, next.icon_type) && 
            isEqual(prev.mobile_one, next.mobile_one) && 
            isEqual(prev.is_comment_placeholder, next.is_comment_placeholder) && 
            isEqual(prev.required, next.required) && 
            isEqual(prev.required_end, next.required_end) && 
            isEqual(prev.refComment, next.refComment) && 
            isEqual(prev.answerColor, next.answerColor) && 
            isEqual(prev.onChange, next.onChange)
})