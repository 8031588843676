import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Method } from 'gql/survey_mbox'
import ConfirmComponent from 'component/Survey/Edit/Confirm'
import SurveyEdit19ExtsComponent from 'component/Survey/Edit/19/Exts'
import SurveyEdit19OptionComponent from 'component/Survey/Edit/19/Option'
import {
    SurveyEditTabsComponent,
    SurveyEditTabComponent,
    SurveyEditQuestionComponent,
    SurveyEditSubTabsComponent
} from 'component'

interface Props {
    method: Method
}
const Component = (props: Props) => {    
    const { method } = props
    const { formatMessage: f } = useIntl()

    const tabs = [f({id: 'container.Survey.Edit.01.tabs.0'}), f({id: 'container.Survey.Edit.01.tabs.1'})]
    const subTabs = [f({id: 'container.Survey.Edit.19.subTabs.0'})]

    if (method === Method.One) subTabs.push(f({id: 'container.Survey.Edit.19.subTabs.1'}))

    return (
        <>
        <SurveyEditTabsComponent tabs={tabs}/>
        <SurveyEditTabComponent index={0}>
            <SurveyEditQuestionComponent/>
            <SurveyEditSubTabsComponent subTabs={subTabs}/>
            <SurveyEdit19ExtsComponent/>
            <ConfirmComponent index={1}/>
        </SurveyEditTabComponent>
        <SurveyEditTabComponent index={1}>
            <SurveyEdit19OptionComponent/>
        </SurveyEditTabComponent>
        </>
    )
}

export default memo(Component)