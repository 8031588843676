import { memo, useContext } from 'react'
import GridContainer, { GridAnswer } from 'styled/Survey/Matrix'
import { isEqual } from 'lodash-es'
import { Context } from './Component'
import { getReplysMatrixKeyOfValue } from '../03/Component'
import MobileColumnItemComponent from './MobileColumnItem'
import MobileAnswerItemComponent from './MobileAnswerItem'
import CheckCountComponent from '../02/CheckCount'

function MobileOnlyComponent(props) {
    const { columns, answers } = props

    const { reply } = useContext(Context)

    const replys = getReplysMatrixKeyOfValue(reply)

    return (
        columns.map((c, i) => {
            const myReply = replys[c.survey_module_no]

            return (
                <GridContainer key={c.survey_module_no}>
                    <MobileColumnItemComponent column={c.column}/>
                    <GridAnswer>
                        {
                            answers.map(d => {
                                
                                const selected = myReply ? myReply[d.survey_module_answer_no] !== undefined : false

                                return (
                                    <MobileAnswerItemComponent
                                        key={d.survey_module_answer_no}
                                        survey_module_no={c.survey_module_no}
                                        survey_module_answer_no={d.survey_module_answer_no}
                                        answer={d.answer}
                                        selected={selected}
                                    />
                                )
                            })
                        }
                    </GridAnswer>
                    <CheckCountComponent count={myReply ? Object.keys(myReply).length : 0}/>
                </GridContainer>
            )
        })
    )
}

export default memo(MobileOnlyComponent, (prev, next) => {
    return  isEqual(prev.columns, next.columns) &&
        isEqual(prev.answers, next.answers)
})