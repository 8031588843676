import { useContext, useMemo } from 'react'
import { getColorConcentration } from 'utils'
import PropsContext from 'context/props'
import Divider from '@material-ui/core/Divider'

interface Props {
    isLast: boolean;
}

function DividerComponent(props: Props) {
    const { isLast } = props

    const { design } = useContext<{background_color: string}>(PropsContext)
    const { background_color } = design
    const color = getColorConcentration(background_color) ? 'rgba(255, 255, 255, .1)' : 'rgba(126, 126, 126, .2)'

    return (
        <>
        {
            useMemo(() => {
                return (
                    <Divider style={{marginTop: 5, marginBottom: isLast ? 0 : 15, backgroundColor: color, display: 'block'}}/>
                )
            }, [color, isLast])
        }
        </>

    )
}

export default DividerComponent