import { ComponentType } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export interface BreakPointsProps {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

export default (WrappedComponent: ComponentType<{breakpoints: BreakPointsProps}>) => (props:any) => {
    const theme = useTheme()

    const xs = useMediaQuery(theme.breakpoints.down('xs'))
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const lg = useMediaQuery(theme.breakpoints.down('lg'))
    const xl = useMediaQuery(theme.breakpoints.down('xl'))

    const breakpoints = {
        xs,
        sm,
        md,
        lg,
        xl
    }

    return <WrappedComponent breakpoints={breakpoints} {...props}/>
}