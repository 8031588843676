import { createContext } from 'react'

const accessMethodContext = createContext({
    /*
    ** 설문 모드 (edit: 편집, preview: 미리보기, live: 실제)
    **  method: (ip, email, mobile)
    */
    mode: '',
    method: ''
})

export default accessMethodContext