import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { GET_SURVEY_ANALYSIS_19_USED_FILESIZE } from 'gql/survey_analysis_users'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import useTokenInfo from './useTokenInfo'

// 파일 업로드 사이즈 가능 여부
function useFileUploadSize() {
    const token:any = useTokenInfo()
    
    const dispatch = useDispatch()

    const [ used, setUsed ] = useState(0)

    const { loading, refetch } = useQuery(GET_SURVEY_ANALYSIS_19_USED_FILESIZE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const used = data.surveyAnalysis19UsedFilesize
            setUsed(Number(used))
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    return [{ limit: token.limit_fileupload_size, used, isOver: (used > token.limit_fileupload_size || loading), refetch }]
}

export default useFileUploadSize