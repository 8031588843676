import gql from 'graphql-tag'

export const GET_SURVEY_ANALYSIS_COMMENTS = gql`
     query SurveyAnalysisComments($survey_no: Int!, $token: ID!, $survey_page_no: Int!, $mode: Mode!) {
        surveyAnalysisComments(survey_no: $survey_no, token: $token, survey_page_no: $survey_page_no, mode: $mode) {        
            survey_question_no
            comment
        }
    }
`

export const GET_SURVEY_ANALYSIS_COMMENT = gql`
     query SurveyAnalysisComment($survey_no: Int!, $token: ID!, $survey_question_no: Int!, $mode: Mode!) {
        surveyAnalysisComment(survey_no: $survey_no, token: $token, survey_question_no: $survey_question_no, mode: $mode) {        
            survey_question_no
            comment
        }
    }
`

export const schemaSurveyAnalysisCommentsAll = `
    survey_analysis_users_no
    survey_question_no
    comment
    wdate
`

export const GET_SURVEY_ANALYSIS_COMMENTS_ALL = gql`
    query SurveyAnalysisCommentsAll($survey_no: Int!, $mode: Mode!) {
        surveyAnalysisCommentsAll(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyAnalysisCommentsAll}
        }
    }
`