import gql from 'graphql-tag'

export const GET_BUG_REPORT = gql`
    query BugReport {
        bugReport
    }
`

export const CREATE_SURVEY_STARTS_TOKEN = gql`
    mutation createSurveyStartsToken($msg: String, $type: Int!) {
        createSurveyStartsToken(msg: $msg, type: $type)
    }
`

export const CREATE_LOG_ERROR = gql`
    mutation createLogError($msg: String) {
        createLogError(msg: $msg)
    }
`

export const CREATE_BUG_REPORT = gql`
    mutation CreateBugReport {
        createBugReport
    }
`