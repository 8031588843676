import { useContext, useCallback, useEffect, useState } from 'react'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { withPrevState } from 'hoc'
import { memoryReply } from 'container/Survey/Live/Container'
import ItemsComponent from './Items'
import CommentComponent from '../Comment'
import useChangeReplys from '../useChangeReplys'

function order(props) {
    const { ranks, answers, reply } = props

    // survey_module_no로 찾기위해 obj key survey_module_no로 바꾼다
    const replyObj = reply.reduce((acc, c) => {
        return {
            ...acc,
            [`reply-${c.survey_module_no}`]: {...c}
        }
    }, {})

    const _answers = [...answers]

    // 새로운 배열을 담는다
    const answersNew = new Array(_answers.length).fill('')

    // index 낮은것부터 1순위이다.
    for (let i=0; i<ranks.length; i++) {

        const { survey_module_no } = ranks[i]

        // 해당 순위를 선택한 값이 잇는지 체크
        const { survey_module_answer_no } = replyObj[`reply-${survey_module_no}`] || { survey_module_answer_no: '' }

        if (survey_module_answer_no) {
            for (let j=0; j<_answers.length; j++) {

                if (survey_module_answer_no === _answers[j].survey_module_answer_no) {
                    // answersNew에 추가하거
                    answersNew.splice(i, 1, _answers[j])

                    // _asnwers에서는 삭제
                    _answers.splice(j, 1)
                    break
                }
            }
        }
    }

    // 선택안된값들은 0번부터 빈값에 들어간다.


    // 선택된 값이 없으면 보기 순서대로 저장한다
    /*if (!isData) {
        answersNew.splice(answersNew.length, 0, _answers[0])
        // _asnwers에서는 삭제
        _answers.splice(0, 1)
    }*/

    if (_answers.length === 0) return answersNew

    return answersNew.map(c => {
        if (c) return c

        // 0번째 값 복사에서 배열에 담고
        const copy = _answers[0]
        // 복사한값은 삭제
        _answers.splice(0, 1)
        
        return copy
    })
}


const Component = props => {
    const { 
        survey_question_no, 
        ranks, 
        answers, 
        reply, 
        is_comment, 
        is_comment_placeholder,
        replyComment,
        usePrevState 
    } = props
    const { format } = useContext(PropsContext)

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState(reply)

    const answersLength = answers.length
    const replyLength = newReply.length

    // 답변수
    const prevReplyLength = usePrevState(replyLength)


    const answersOrder = order({ ranks, answers, reply: newReply })


    // 1문 1답은 전체 랜더링, 클래식모드는 현재 문항만 랜더링
    const handleChangeState = useCallback(async(data) => {
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = data

        await setNewReply(data.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(data)
    }, [survey_question_no, onChangeReplys])

    
    const handleChange = useCallback((survey_module_no, survey_module_answer_no) => {


        // 선택된 보기값을 삭제한다
        const deleteReplys = memoryReply.rows.filter(c => {
            return !(c.survey_question_no === survey_question_no && c.survey_module_answer_no === survey_module_answer_no)
        })

         // survey_module_no가 없을경우 None을 선택한거다
         if (!survey_module_no) handleChangeState(deleteReplys)
         else handleChangeState([...deleteReplys, format._11({ survey_question_no, survey_module_no, survey_module_answer_no }) ])
        
    }, [survey_question_no, format, handleChangeState])


    const handleChangeAll = useCallback(data => {

        // 모든 보기값을 삭제한다
        const deleteReplys = memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no)

        // 모든값을 새롭게 다시넣는다
        const newReply = data.map(c => {
            const { survey_question_no, survey_module_no, survey_module_answer_no } = c
            return format._11({ survey_question_no, survey_module_no, survey_module_answer_no })
        })
        
        handleChangeState([...deleteReplys, ...newReply])   

    }, [survey_question_no, format, handleChangeState])

    
    const handleDnDStart = () => {
        if (typeof document.activeElement !== 'undefined') {
            document.activeElement.blur()
        }
    }

    const handleDnDEnd = useCallback((result) => {
        if (!result.destination) {
            return
        }

        // survey_question 복사
        const copy = [...answersOrder]

        const sourceIndex = result.source.index //원래 인덱스
        const destinationIndex = result.destination.index
    
        // 옮겨져야할 값 을 배열에서 삭제하고 value값은 저장
        const [removedData] = copy.splice(sourceIndex, 1)
    
        // 옮겨져야할 값 추가
        copy.splice(destinationIndex, 0, removedData)
        
        // 보기 나열대로 순서를 셋팅한다
        const newData = copy.map((c, i) => ({
            survey_question_no: c.survey_question_no,
            survey_module_no: ranks[i].survey_module_no,
            survey_module_answer_no: c.survey_module_answer_no
        }))

        handleChangeAll(newData)

    }, [ranks, answersOrder, handleChangeAll])



    useEffect(() => {
    
        // 순위체크(해제말고) 후 한개 남았을때 나머지 하나는 자동으로 선택되게 한다
        if (answersLength-1 === replyLength) {
            // 순위를 선택했을때 (해제말고)
            if (prevReplyLength+1 === replyLength) {

                const surveyModuleNoReply = []
                const surveyModuleAnswerNoReply = []

                // survey_module_no만 survey_module_answer_no만 담는다
                for (const c of newReply) {
                    surveyModuleNoReply.splice(surveyModuleNoReply.length, 0 , c.survey_module_no)
                    surveyModuleAnswerNoReply.splice(surveyModuleAnswerNoReply.length, 0 , c.survey_module_answer_no)
                }

                // 남은 랭크
                const { survey_module_no } = 
                ranks.find(c => !surveyModuleNoReply.includes(c.survey_module_no)) || {survey_question_no: '', survey_module_no: ''}                           

                // 남은 보기
                const  { survey_module_answer_no } = 
                answers.find(c => !surveyModuleAnswerNoReply.includes(c.survey_module_answer_no)) || { survey_module_answer_no: '' }

                // 자동으로 순위선택해준다
                handleChange(survey_module_no, survey_module_answer_no)
            }
        }
        
    }, [answersLength, replyLength, prevReplyLength, newReply, ranks, answers, handleChange])

    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <>
        <DragDropContext onDragStart={handleDnDStart} onDragEnd={handleDnDEnd}>
            <Droppable droppableId={`droppable-11-${survey_question_no}`}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}  {...provided.droppableProps} style={{width: '100%'}}> 
                    <ItemsComponent 
                        answersOrder={answersOrder}
                        ranks={ranks} 
                        reply={newReply}
                        onChange={handleChange}
                    />
                    {provided.placeholder}    
                </div>
            )}
            </Droppable>
        </DragDropContext>
        <CommentComponent 
            survey_question_no={survey_question_no} 
            is_comment={is_comment}
            is_comment_placeholder={is_comment_placeholder}
            comment={replyComment}
            disabled={newReply.length > 0 ? false : true}
            marginTop="10px"
            marginBottom="5px"
        />
        </>
    )
}

export default withPrevState(Component)