import { useDispatch } from 'react-redux'
import { SHOW } from 'reducer/survey/create/moduleTip'
import { IconButton } from '@material-ui/core'
import oc from 'open-color'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import HelpOutlineTwoToneIcon from '@material-ui/icons/HelpOutlineTwoTone'

interface Props {
    index: number;
    breakpoints: BreakPointsProps
}

function TipComponent(props: Props) {
    const { index, breakpoints } = props
    const { sm } = breakpoints

    const dispatch = useDispatch()

    function handleTip(index: number) {
        const styles = sm ? { backgroundColor: oc.gray[0] } : {}
        dispatch({ type: SHOW, index, styles })
    }

    return (
        <IconButton size="small" color="primary" onClick={(e) => handleTip(index)}>
            <HelpOutlineTwoToneIcon fontSize="small"/>
        </IconButton>
    )
}

export default withBreakPoints(TipComponent)