import { useState, useCallback, useReducer } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { isEqual } from 'lodash-es'
import { checkCorporateRegistrationNumber, checkJumin } from 'ts-utils'
import { validation, replaceNotNumber } from 'utils'
import { GetTaxTypeName, TaxType } from 'ts-policy'
import { CREATE_USER_TAX_COMPANY, CREATE_USER_TAX_PERSONAL, CREATE_USER_TAX_NONE } from 'gql/user_tax'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { Grid, ButtonGroup, Button, Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { OnChangeTaxType } from './Component'
import TextFieldComponent from './TextField'

interface DbProps {
    c_id_no: string;
    c_name: string;
    c_email: string;
    c_company_name: string;
    p_id_no: string;
    p_name: string;
    p_email: string;
    p_mobile_no: string;
}

type numberAndStringAndBlank = number | string | ''

type Action = {
    [key: string]: numberAndStringAndBlank
}

interface Props extends DbProps {
    location: 'new' | 'edit';
    user_payment_no?: number;
    tax_type?: TaxType;
    modify_possible?: boolean;
    onChange: OnChangeTaxType;
}

const GridContainer = withStyles({
    root: {

    }
})(props => <Grid container alignItems="center" justifyContent="space-between" {...props}/>)

// 저장 버튼 grid 모바일일때 안보인다

const GridSave = withStyles(theme => ({
    root: {
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))(props => <Grid item xs={false} sm={5} {...props}/>)
 
const TaxButtonGroup = withStyles({
    root: {}
})(props => <ButtonGroup variant="contained" disableElevation={true} {...props}/>)

const TaxGrid = withStyles({
    root: {
        marginTop: 4
    }
})(props => <Grid container spacing={2} {...props}/>)


// 모바일일때 보이는 버튼
const MobileSaveBox = withStyles(theme => ({
    root: {
        height: 150,
        display: 'none',
        marginTop: 15,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    }
}))(Box)


function getInit(props:DbProps) {

    return {
        c_id_no: props.c_id_no || '',
        c_company_name: props.c_company_name || '',
        c_name: props.c_name || '',
        c_email: props.c_email || '',
        p_id_no: props.p_id_no || '',
        p_name: props.p_name || '',
        p_email: props.p_email || '',
        p_mobile_no: props.p_mobile_no || ''
    }
}

const handleReducer = (state:Action, action:Action):Action => {
    switch (action.type) {
        case 'c_id_no': {
            return { ...state, c_id_no: action.c_id_no }
        }
        case 'c_company_name': {
            return { ...state, c_company_name: action.c_company_name }
        }
        case 'c_name': {
            return { ...state, c_name: action.c_name }
        }
        case 'c_email': {
            return { ...state, c_email: action.c_email }
        }
        case 'p_id_no': {
            return { ...state, p_id_no: action.p_id_no }
        }
        case 'p_name': {
            return { ...state, p_name: action.p_name }
        }
        case 'p_mobile_no': {
            return { ...state, p_mobile_no: action.p_mobile_no }
        }
        case 'p_email': {
            return { ...state, p_email: action.p_email }
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}



type DisabledCompanyKeys = 'c_id_no' | 'c_company_name' | 'c_name' | 'c_email'
type DisabledPersonalKeys = 'p_id_no' | 'p_mobile_no' | 'p_name' | 'p_email'
type DisabledTypes = numberAndStringAndBlank

interface DisabledCompanyProps {
    location: Props['location'];
    taxType: TaxType | undefined;
    originData: Pick<DbProps, DisabledCompanyKeys>;
    newData: Record<DisabledCompanyKeys, DisabledTypes>; 
}

interface DisabledPersonalProps {
    location: Props['location'];
    taxType: TaxType | undefined;
    originData: Pick<DbProps, DisabledPersonalKeys>;
    newData: Record<DisabledPersonalKeys, DisabledTypes>; 
}

interface DisabledNoneProps {
    location: Props['location'];
    taxType: TaxType | undefined;
    newTaxType: TaxType;
}

// 세금계산서 발행이 되었거나, 분기가 지난경우 수정몬하게 막는다 (막는건 관리자에서)
function getModifyPossible(tax_type: TaxType | undefined, modify_possible: boolean) {
    let disabledCompanyTab = false 
    let disabledPersonalTab = false
    let disabledNoneTab = false
    let readOnly = false

    //
    if (tax_type === TaxType.Company && !modify_possible) {
        disabledPersonalTab = true
        disabledNoneTab = true
        readOnly = true
    } else if (tax_type === TaxType.Personal && !modify_possible) {
        disabledCompanyTab = true
        disabledNoneTab = true
        readOnly = true
    } else if (tax_type === TaxType.None && !modify_possible) {
        disabledCompanyTab = true
        disabledPersonalTab = true
        readOnly = true
    }

    return {
        disabledCompanyTab,
        disabledPersonalTab,
        disabledNoneTab,
        readOnly
    }
}

function getDisabledCompany(props: DisabledCompanyProps) {
    const { location, taxType, originData, newData } = props

    // 마이페이지내 결제냐엑에서 디폴트값과 새로운값이 같아도 저장버튼 disabled 한다
    if (location === 'edit' && taxType === TaxType.Company) {
        if (isEqual(originData, newData)) return true
    }

    if (!newData.c_id_no || !newData.c_company_name || !newData.c_name || !newData.c_email) return true

    if (!checkCorporateRegistrationNumber(Number(newData.c_id_no))) return true
    if(!validation.email(newData.c_email)) return true
    
    return false
}

function getDisabledPersonal(props: DisabledPersonalProps) {
    const { location, taxType, originData, newData } = props

    // 마이페이지내 결제냐엑에서 디폴트값과 새로운값이 같아도 저장버튼 disabled 한다
    if (location === 'edit' && taxType === TaxType.Personal) {
        if (isEqual(originData, newData)) return true
    }

    // 결제확인 페이지에서 디폴트값과 새로운값이 같아도 저장버튼 disabled 한다
    if (!newData.p_id_no || !newData.p_name || !newData.p_mobile_no || !newData.p_email) return true

    if (!checkJumin(Number(newData.p_id_no))) return true
    if(!validation.email(newData.p_email)) return true

    return false
}

function getDisabledNone(props: DisabledNoneProps) {
    const { location, taxType, newTaxType } = props

     // 마이페이지내 결제냐엑에서 디폴트값과 새로운값이 같아도 저장버튼 disabled 한다
    if (location === 'edit' && taxType === TaxType.None) {
        if (taxType === newTaxType) return true
    }

    return false
}

function errorCorporateRegistrationNumber(val:numberAndStringAndBlank) {
    // 값이 없으면 error로 표시 안한다
    if (!val) return false

    return !checkCorporateRegistrationNumber(Number(val))
}

function errorJumin(val:numberAndStringAndBlank) {
    // 값이 없으면 error로 표시 안한다
    if (!val) return false

    return !checkJumin(Number(val))
}

function errorEmail(val:numberAndStringAndBlank) {
    if (!val) return false

    return !validation.email(val)
}

function errorMobileNo(val:numberAndStringAndBlank) {
    if (!val) return false

    return !validation.tel(val, ['mobile'])
}

function TaxBoxComponent(props:Props) {

    const { 
        location,
        user_payment_no, 
        tax_type,
        modify_possible=true,
        c_id_no: origin_c_id_no, 
        c_company_name: origin_c_company_name, 
        c_name: origin_c_name, 
        c_email: origin_c_email, 
        p_id_no: origin_p_id_no,
        p_name: origin_p_name,
        p_mobile_no: origin_p_mobile_no,
        p_email: origin_p_email,
        onChange 
    } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ taxType, setTaxType ] = useState(tax_type || TaxType.None)

    const [ states, dispatchStates ] = useReducer(handleReducer, getInit({ 
        c_id_no: origin_c_id_no, 
        c_company_name: origin_c_company_name, 
        c_name: origin_c_name,
        c_email: origin_c_email,
        p_id_no: origin_p_id_no,
        p_name: origin_p_name,
        p_mobile_no: origin_p_mobile_no,
        p_email: origin_p_email
    }))

    const { 
        c_id_no, 
        c_company_name, 
        c_name, 
        c_email, 
        p_id_no,
        p_name,
        p_mobile_no,
        p_email 
    } = states

    const originCompanyDb = { 
        c_id_no: origin_c_id_no, 
        c_company_name: origin_c_company_name, 
        c_name: origin_c_name, 
        c_email: origin_c_email
    }

    const originPersonalDb = { 
        p_id_no: origin_p_id_no,
        p_name: origin_p_name,
        p_mobile_no: origin_p_mobile_no,
        p_email: origin_p_email
    }

    const newCompanyDb = {
        c_id_no, 
        c_company_name, 
        c_name, 
        c_email
    }

    const newPersonalDb = {
        p_id_no, 
        p_mobile_no, 
        p_name, 
        p_email
    }

    const { disabledCompanyTab, disabledPersonalTab, disabledNoneTab, readOnly } = getModifyPossible(tax_type, modify_possible)

    const email = taxType === TaxType.Company ? c_email : taxType === TaxType.Personal ? p_email : ''

    const disabled = taxType === TaxType.Company ?  getDisabledCompany({ location, taxType: tax_type, originData: originCompanyDb, newData: newCompanyDb }) : 
                     taxType === TaxType.Personal ? getDisabledPersonal({ location, taxType: tax_type, originData: originPersonalDb, newData: newPersonalDb }) : 
                                                    getDisabledNone({ location, taxType: tax_type, newTaxType: taxType })

    const [ createUserTaxCompany ] = useMutation(CREATE_USER_TAX_COMPANY, {
        onCompleted: () => {
            dispatch({type: BACKDROPHIDE})
            onChange({
                taxType,
                email
            })
        },
        onError: () => {
            dispatch({type: ERRORPAGESHOW})
        }
    })

    const [ createUserTaxPersonal ] = useMutation(CREATE_USER_TAX_PERSONAL, {
        onCompleted: () => {
            dispatch({type: BACKDROPHIDE})
            onChange({
                taxType,
                email
            })
        },
        onError: () => {
            dispatch({type: ERRORPAGESHOW})
        }
    })

    const [ createUserTaxNone ] = useMutation(CREATE_USER_TAX_NONE, {
        onCompleted: () => {
            dispatch({type: BACKDROPHIDE})
            onChange({
                taxType,
                email
            })
        },
        onError: () => {
            dispatch({type: ERRORPAGESHOW})
        }
    })

    // 세금계산서 종류
    const handleChangeTaxType = useCallback((val:TaxType) => {
        setTaxType(val)
    }, [])

    // input 값 변경
    const handleChangeText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name: _name, value } = e.target
        
        dispatchStates({
            type: _name,
            [_name]: value
        })

    }, []) 


    const handleChangeNumber = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name: _name, value } = e.target
        
        const _value = replaceNotNumber(value)

        dispatchStates({
            type: _name,
            [_name]: _value
        })

    }, []) 

    // 신청 저장
    const handleSave = useCallback(() => {

        let input = {}
        
        switch(taxType) {
            case TaxType.Company:
                dispatch({type: BACKDROPSHOW})

                input = {
                    user_payment_no,
                    id_no: String(c_id_no),
                    company_name: c_company_name,
                    name: c_name,
                    email: c_email
                }
    
                createUserTaxCompany({
                    variables: {input}
                })

                break
            case TaxType.Personal:
                dispatch({type: BACKDROPSHOW})

                input = {
                    user_payment_no,
                    id_no: p_id_no,
                    name: p_name,
                    email: p_email,
                    mobile_no: p_mobile_no
                }

                createUserTaxPersonal({
                    variables: {input}
                })
                
                break
            case TaxType.None:
                dispatch({type: BACKDROPSHOW})

                input = {
                    user_payment_no
                }

                createUserTaxNone({
                    variables: {input}
                })
                
                break
            default:
        }
    }, [  
        user_payment_no,
        c_id_no, 
        c_company_name, 
        c_name, 
        c_email, 
        p_id_no,
        p_name,
        p_mobile_no,
        p_email , 
        taxType, 
        dispatch,
        createUserTaxCompany, 
        createUserTaxPersonal,
        createUserTaxNone
    ])

    return (
        <>
        <GridContainer>
            <Grid item xs={12} sm={7}>
                <TaxButtonGroup>
                    <Button 
                        color={taxType === TaxType.Company ? 'primary' : 'default'} 
                        onClick={() => handleChangeTaxType(TaxType.Company)}
                        disabled={disabledCompanyTab}
                    >
                        {f({id: `component.Payment.Receipt.TaxBox.buttonGroup.taxType`}, {taxType: GetTaxTypeName(TaxType.Company)})}
                    </Button>
                    <Button 
                        color={taxType === TaxType.Personal ? 'primary' : 'default'} 
                        onClick={() => handleChangeTaxType(TaxType.Personal)}
                        disabled={disabledPersonalTab}
                    >
                        {f({id: 'component.Payment.Receipt.TaxBox.buttonGroup.taxType'}, {taxType: GetTaxTypeName(TaxType.Personal)})}
                    </Button>
                    <Button 
                        color={taxType === TaxType.None ? 'primary' : 'default'} 
                        onClick={() => handleChangeTaxType(TaxType.None)}
                        disabled={disabledNoneTab}
                    >
                        {f({id: 'component.Payment.Receipt.TaxBox.buttonGroup.none'})}
                    </Button>
                </TaxButtonGroup>
            </Grid>
            <GridSave>
                <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={handleSave}
                    disabled={disabled}
                >
                    {f({id: 'component.Payment.Receipt.TaxBox.button.confirm'})}
                </Button>
            </GridSave>
        </GridContainer>
   
        <TaxGrid>
            {
                taxType === 'company' ? (
                    <>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent  
                            name="c_id_no" 
                            value={c_id_no} 
                            label={f({id: 'component.Payment.Receipt.TaxBox.input.label.id_no_company'})}
                            maxLength={getMaxlength('component.Payment.Receipt.TaxBox.input.id_no_company')}
                            error={errorCorporateRegistrationNumber(Number(c_id_no))}
                            readOnly={readOnly}
                            onChange={handleChangeNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent 
                            name="c_company_name" 
                            value={c_company_name} 
                            label={f({id: 'component.Payment.Receipt.TaxBox.input.label.company_name'})}
                            maxLength={getMaxlength('component.Payment.Receipt.TaxBox.input.company_name')}
                            error={false}
                            readOnly={readOnly}
                            onChange={handleChangeText}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent 
                            name="c_name" 
                            value={c_name} 
                            label={f({id: 'component.Payment.Receipt.TaxBox.input.label.name'})}
                            maxLength={getMaxlength('component.Payment.Receipt.TaxBox.input.name')}
                            error={false}
                            readOnly={readOnly}
                            onChange={handleChangeText}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent 
                            name="c_email" 
                            value={c_email} 
                            label={f({id: 'component.Payment.Receipt.TaxBox.input.label.email'})}
                            maxLength={getMaxlength('component.Payment.Receipt.TaxBox.input.email')}
                            error={errorEmail(c_email)}
                            readOnly={readOnly}
                            onChange={handleChangeText}
                        />
                    </Grid>
                    </>
                ) : taxType === 'personal' ? (
                    <>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent  
                            name="p_id_no" 
                            value={p_id_no} 
                            label={f({id: 'component.Payment.Receipt.TaxBox.input.label.id_no_personal'})}
                            maxLength={getMaxlength('component.Payment.Receipt.TaxBox.input.id_no_personal')}
                            error={errorJumin(Number(p_id_no))}
                            readOnly={readOnly}
                            onChange={handleChangeNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent 
                            name="p_name" 
                            value={p_name} 
                            label={f({id: 'component.Payment.Receipt.TaxBox.input.label.name'})}
                            maxLength={getMaxlength('component.Payment.Receipt.TaxBox.input.name')}
                            error={false}
                            readOnly={readOnly}
                            onChange={handleChangeText}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent 
                            name="p_mobile_no"
                            value={p_mobile_no} 
                            label={f({id: 'component.Payment.Receipt.TaxBox.input.label.mobile_no'})}
                            maxLength={getMaxlength('component.Payment.Receipt.TaxBox.input.mobile_no')}
                            error={errorMobileNo(p_mobile_no)}
                            readOnly={readOnly}
                            onChange={handleChangeNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent 
                            name="p_email" 
                            value={p_email} 
                            label={f({id: 'component.Payment.Receipt.TaxBox.input.label.email'})}
                            maxLength={getMaxlength('component.Payment.Receipt.TaxBox.input.email')}
                            error={errorEmail(p_email)}
                            readOnly={readOnly}
                            onChange={handleChangeText}
                        />
                    </Grid>
                    </>
                ) : ''
            }
        </TaxGrid>
        <div style={{height: 10}}></div>
        {
            modify_possible ? (
                <Typography variant="body2">
                    {f({id: 'component.Payment.Receipt.TaxBox.tip.default'})}
                </Typography>
            ): (
                <Typography variant="body2" color="secondary">
                    {f({id: 'component.Payment.Receipt.TaxBox.tip.error'})}
                </Typography>
            )
        }
        <MobileSaveBox>
            <Button variant="outlined" color="primary" onClick={handleSave} disabled={disabled}>
                {f({id: 'component.Payment.Receipt.TaxBox.button.confirm'})}
            </Button>
        </MobileSaveBox>
        </>
    )
}

export default TaxBoxComponent