import { useContext } from 'react'
import styled, { css } from 'styled-components'
import PropsContext from 'context/props'
import { getMimePicture } from 'utils/survey'

const BoxStyled = styled.div`
    display: grid;
    align-content: center;
    justify-content: center;
    width: 100%;

    & > div.message {
        grid-area: message;
        font-size: 1.5rem;
        color: ${props => `rgba(${props.question})`};
        word-break: break-all;
        width: 100%;
    }

    & > div.image {
        grid-area: image;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 1em;
    }

    & > div.button {
        grid-area: button;
        text-align: center;
        margin-top: ${props => props.agree ? '20px' : '20px'};
    }
    
    & > div.sns {
        display: block;
        grid-area: sns;
        text-align: center;
    }

    & > div.branding {
        margin-top: 2.2em;
        display: block;
        grid-area: branding;
        color: ${props => `rgba(${props.question})`};
        font-size: .9rem;
        text-align: center;
    }
    
    & > div + div {
        margin-top: 1em;
    }

    /*
    경우의 수
    PC: 이미지가 없을때, 이미지가 있을때 - 내용안, 우측 비율맞게, 우측 꽉차게
    모바일: 이미지가 없을때, 이미지가 있을때 - 내용안, 상단 비율맞게, 상단 꽉차게
    */
    ${
        ({mime, selected, img_src, img_position}) => (
            selected === 'ending' ? (
                !img_src || img_position !== 'stack' ? (
                    css `
                    & > div.image {
                        display: none;
                    }
                    grid-template-columns: auto;
                    grid-template-rows: auto auto auto auto;
                    grid-template-areas:
                        "message"
                        "sns"
                        "button"
                        "branding";
                    `
                ) : (
                    mime === 'youtube' ? (
                        css `
                  
                        grid-template-columns:  minmax(37em, auto);
                        grid-template-rows: auto auto auto auto auto;
                        grid-template-areas:
                            "image"
                            "message"
                            "sns"
                            "button"
                            "branding";
                        @media only screen and (max-width: 600px) {
                            grid-template-columns:  1fr;
                        };
                        `
                    ) : mime === 'file' ? (
                        css `
                            grid-template-columns: auto;
                            grid-template-rows: auto auto auto auto auto;
                            grid-template-areas:
                                "message"
                                "image"
                                "sns"
                                "button"
                                "branding";
                            `
                    ) : (
                        css `
                            grid-template-columns: auto;
                            grid-template-rows: auto auto auto auto auto;
                            grid-template-areas:
                                "image"
                                "message"
                                "sns"
                                "button"
                                "branding";
                            `
                    )
                )
            ) : (
                !img_src || img_position !== 'stack' ? (
                    css `
                    & > div.image {
                        display: none;
                    }
                    grid-template-columns: auto;
                    grid-template-rows: auto auto;
                    grid-template-areas:
                        "message"
                        "button";
                    `
                ) : (
                    (mime === 'youtube' || mime === 'video') ? (
                        css `
                  
                        grid-template-columns:  minmax(37em, auto);
                        grid-template-rows: auto auto auto;
                        grid-template-areas:
                            "image"
                            "message"
                            "button";
                        @media only screen and (max-width: 600px) {
                            grid-template-columns:  1fr;
                        };
                        `
                    ) : mime === 'file' ? (
                        css `
                        grid-template-columns:  auto;
                        grid-template-rows: auto auto auto;
                        grid-template-areas:
                            "message"
                            "image"
                            "button";
                        `
                    ) : (
                        css `
                        grid-template-columns:  auto;
                        grid-template-rows: auto auto auto;
                        grid-template-areas:
                            "image"
                            "message"
                            "button";
                        `
                    )
                )
            )
        )
        
    }
`


export default (props) => {
    const { selected, img_src, img_position, agree, children } = props
    const { mbox, design } = useContext(PropsContext)
    const mime = getMimePicture(img_src)
    const { method } = mbox
    const { question } = design

    return (
        <BoxStyled 
            method={method} 
            mime={mime} 
            question={question} 
            selected={selected} 
            img_src={img_src} 
            img_position={img_position} 
            agree={agree}
        >
            {children}
        </BoxStyled>
    )
}