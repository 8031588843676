/**
 * 포스트코드 대화창
 */
import { memo, useState, MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode'
import Popover from '@material-ui/core/Popover'
import Button from 'styled/Custom/Button'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import { Props as ParentProps, OnChangeFocus } from './Item'
import RemoveComponent from './Remove'

interface Props extends Pick<ParentProps, 'survey_question_no' | 'zipcode' | 'address2' | 'onChange'> {
    isMobileOnly: boolean;
    breakpoints: BreakPointsProps;
    onChangeFocus: OnChangeFocus;
}

const styles = { whiteSpace: 'pre', fontSize: 12 }

function PostCodeButtonComponent(props: Props) {
    const { isMobileOnly, survey_question_no, zipcode, address2, onChange, onChangeFocus, breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const [ anchor, setAnchor ] = useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchor)

    const id = open ? `address-question-${survey_question_no}` : undefined

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget)
    }

    const handleClose = () => {
        setAnchor(null)
    }

    const handleComplete = async(data: Address) => {
        let address = ''
        let zipcode = data.zonecode
    
        if (data.userSelectedType === 'R') address = data.roadAddress
        else address = data.jibunAddress

        onChange(({zipcode, address1: address, address2}))
        onChangeFocus()
       
        handleClose()
    }

    if ((isMobileOnly && !xs) || (!isMobileOnly && xs)) return null

    return (
        <>
        <RemoveComponent zipcode={zipcode} address2={address2} onChange={onChange}/>
        <Button size="small" variant="text" style={styles} onClick={handleOpen}>
            {f({id: 'component.Survey.Modules.20.PostCodeButton.button.find'})}
        </Button>
        <Popover 
            id={id} 
            open={open} 
            anchorEl={anchor} 
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >

            <DaumPostcodeEmbed style={{width: window.innerWidth > 600 ? 600 :(window.innerWidth - 50)}} onComplete={handleComplete}/>
        

        </Popover>
        </>
    )
}

export default withBreakPoints(memo(PostCodeButtonComponent))