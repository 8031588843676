import { useMemo, useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Radio } from '@material-ui/core'
import PropsContext from 'context/props'

const CustomRadio = withStyles({
    root: ({unselected}) => ({
        color: `rgba(${unselected})`,
        margin: 0,
        padding: 0
    })
})((props) => <Radio color="default" {...props} />)

export default (props) => {
    const { design } = useContext(PropsContext)
    const { answer_button: unselected } = design

    return (
        <>
        {
            useMemo(() => (
                <CustomRadio {...props} unselected={unselected}/>
            ), [props, unselected])
        }
        </>
        
    )
}
