import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { LangComponent } from 'component'
import RequiredButtonComponent from './RequiredButton'
import CloseComponent from './Close'

export interface Props {
    required: boolean;
    onChange: (required: boolean) => void;
    onClose: () => void;
}

function RequireLangaugeComponent(props: Props) {
    const { required, onChange, onClose } = props
    const { lang } = useSelector((state: RootState) => state.lang)

    return (
        <LangComponent lang={lang}>
            <RequiredButtonComponent required={required} onChange={onChange}/>
            <CloseComponent onClose={onClose} />
        </LangComponent>
    )
}


export default memo(RequireLangaugeComponent)
