import { useContext, memo } from 'react'
import styled from 'styled-components'
import { getMaxlength } from 'policy'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { InputRef } from '../01/Item'
import Answer from 'styled/Custom/Answer'
import AvatarComponent from './Avatar'

const OptionStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    border: ${({ bordercolor, disabled, selected }) => {
        const [ r, g, b ] = bordercolor.split(',')
        return `1px solid rgba(${r}, ${g}, ${b}, ${disabled ? '.3' : selected ? '1' : '.5'})`
    }};
    background-color: ${({ selected }) => selected ? 'rgba(0, 0, 0, .1)' : 'transparent'};
    padding: 5px;
    margin-top: ${props => props.index > 0 ? '8px' : 0 };

    & > div:first-child {
        min-width: 64px;
    }

    & > div:last-child {
        flex-grow: 1;
        word-break: break-all;
        opacity: ${({ disabled }) => disabled ? '.5' : '1'}; // 문항내용 disabled일경우 텍스트 좀 흐리게하자
    }
`

function ItemComponent(props) {
    const { survey_module_answer_no, is_comment, ranksKeyOfValue, answer, myReply, reply, is_comment_placeholder, required, required_end, index, refComment, onChange } = props
    const { design } = useContext(PropsContext)
    const { answer_button } = design

    const { formatMessage: f } = useIntl()

    // 선택된 순위값
    const { survey_module_no, comment } = myReply || { survey_module_no: '', comment: '' }

    const selected = survey_module_no !== ''
    const disabled = !selected && required && reply.length >= required_end

    return (
        <>
        <OptionStyled 
            index={index} 
            bordercolor={answer_button}  
            selected={selected}
            disabled={disabled}
            onClick={()=> onChange(survey_module_answer_no, disabled)}
        >
            <div>
                {
                    ranksKeyOfValue[survey_module_no] ? (
                        <AvatarComponent disabled={disabled}>
                            {ranksKeyOfValue[survey_module_no].indexs}
                        </AvatarComponent>
                    ) : (
                        <AvatarComponent disabled={disabled}> </AvatarComponent>
                    )
                }
            </div>
            <div><Answer>{answer || f({id: 'component.Survey.Modules.17.answer'})}</Answer></div>
        </OptionStyled>
        {
            is_comment && (
                <div style={{marginTop: 4, marginBottom: 10}}>
                    <InputRef
                        defaultValue={comment}
                        maxLength={getMaxlength('component.Survey.Modules.01.comment')}
                        disabled={survey_module_no === ''}  
                        placeholder={is_comment_placeholder}
                        ref={ref => refComment.current[survey_module_answer_no] = ref}
                    />
                </div>
            )
        }
        </>
    )
}

export default memo(ItemComponent)