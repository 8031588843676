import { ChangeEvent, memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { 
    UPDATE_SURVEY_TEMPLATE_LIST_CATEGORY_IN_MBOX, 
    UPDATE_SURVEY_TEMPLATE_LIST_OPEN_IN_MBOX, 
    UpdateSurveyTemplateListCategoryInMboxVariables,
    UpdateSurveyTemplateListOpenInMboxVariables,
    SurveyMboxInTemplateListsProps 
} from 'gql/survey_template'
import { UPDATE_SURVEY_MBOX_TITLE, DELETE_SURVEY_MBOX } from 'gql/survey_mbox'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import SvgIcon from '@material-ui/core/SvgIcon'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import { useStyles } from 'component/Survey/Workspace/Body/ListItem'
import { SurveyPreviewContainer } from 'container'
import { ParentProps } from './Component'
import { Selected, Edit } from './Publish'

interface Props extends SurveyMboxInTemplateListsProps {
    categorys: ParentProps['data']
    selected: Selected
    refetch: () => void
    edit: Edit
    survey_workspace_no?: number
}

const MyPaper = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        borderRadius: 0,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            gap: 5
        }
    }
})(Paper)

const IconPaper = withStyles({
    root: (props: { i: number }) => {
        const { i } = props

        return {
            padding: 5,
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: i * 30,
            zIndex: 1
        }

    }
})(Paper)

const MySvgIcon = withStyles({
    root: {
        display: 'block',
        cursor: 'pointer',
        width: 18,
        height: 18
    }
})(SvgIcon)

const MyButton = withStyles({
    root: (props: { view: number }) => {
        const { view } = props

        return {
            position: 'absolute',
            opacity: view ? 1 : 0,
            left: '50%',
            top: '43%',
            transform: 'translate(-50%, -50%)',
            transition: 'opacity .3s'
        }
    }
})(Button)

let time: ReturnType<typeof setTimeout>
function ItemComponent(props: Props) {
    const { categorys, selected, refetch, edit, survey_workspace_no, open, survey_no, survey_mbox, survey_mbox_design } = props

    const { formatMessage: f } = useIntl()

    const { title, method } = survey_mbox
  
    const { question, background_color, background_image, background_opacity, font_family } = survey_mbox_design
    
    const classes = useStyles({ question_color: question, background_color, background_image, background_opacity, fontFamily: font_family, height: 250 })

    const [ preview, setPreview ] = useState(false)

    const [ previewButton, setPreviewButton ] = useState(false)

    const [ ctitle, setCTitle ] = useState(title)

    const [ updateTitle ] = useMutation(UPDATE_SURVEY_MBOX_TITLE, {
        onCompleted: () => {
            refetch()
        }
    })

    const [ updateCategory ] = useMutation<UpdateSurveyTemplateListCategoryInMboxVariables>(UPDATE_SURVEY_TEMPLATE_LIST_CATEGORY_IN_MBOX, {
        onCompleted: () => {
            refetch()
        }
    })

    const [ updateOpen ] = useMutation<UpdateSurveyTemplateListOpenInMboxVariables>(UPDATE_SURVEY_TEMPLATE_LIST_OPEN_IN_MBOX, {
        onCompleted: () => {
            refetch()
        }
    })

    const [ remove ] = useMutation(DELETE_SURVEY_MBOX, {
        onCompleted: () => {
            refetch()
        }
    })

    function handlePreviewButtonOpen() {
        setPreviewButton(true)
    }

    function handlePreviewButtonClose() {
        setPreviewButton(false)
    }

    function handlePreviewOpen() {
        setPreview(true)
    }

    const handlePreviewClose = useCallback(() => {
        setPreview(false)
    }, [])

    function handleUpdateTitle(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.target
        clearTimeout(time)
        setCTitle(value)

        time = setTimeout(() => {
            updateTitle({ variables: {
                survey_no,
                title: value
            } })
        }, 700)

    }

    function handleUpdateCategory(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.target
        updateCategory({
            variables: {
                survey_no,
                survey_template_category_no: value
            }
        })
    }

    function handleUpdateOpen(val: boolean) {
       

        if (window.confirm(`${val ? '공개' : '비공개'}로 전환 하시겠습니까?`)) {
            updateOpen({
                variables: {
                    survey_no,
                    open: val
                }
            })
        }
    }

    function handleRemove() {
        if (window.confirm('레알 삭제?')) {
            remove({
                variables: {
                    survey_no
                }
            })
        }
    }

    return (
        <>
        <Card className={classes.card} style={{position: 'relative'}} variant="outlined" onMouseOver={handlePreviewButtonOpen} onMouseOut={handlePreviewButtonClose}>
            <MyButton view={previewButton ? 1 : 0} onClick={handlePreviewOpen} variant="contained" disableElevation>
                {f({id: 'component.Survey.Workspace.List.Button.preview'})}
            </MyButton>
            <MyPaper>
                {
                    !edit ? (
                        <div style={{display: 'flex', flexDirection: 'column', gap: 0, alignItems: 'flex-start'}}> 
                            <Typography style={{ fontFamily: font_family }}>{ctitle}</Typography> 
                            <Typography variant="caption" >
                                {f({id: `component.Survey.Workspace.Body.CreateSurvey.method.menuItem.${method === 'one' ? 0 : 1}`})}
                            </Typography> 
                        </div>
                    ) : (
                        <>
                        <div><TextField value={ctitle} onChange={handleUpdateTitle} style={{width: 120}}/></div>
                        <div>
                            <Select value={selected} onChange={handleUpdateCategory}>
                                {
                                    categorys.map(category => (
                                        <MenuItem key={category.survey_template_category_no} value={category.survey_template_category_no}>{category.name}</MenuItem>
                                    ))
                                }
                            </Select>
                            <IconPaper i={0}>
                            {
                                open ? (
                                    <MySvgIcon onClick={() => handleUpdateOpen(false)}>
                                        <WbSunnyRoundedIcon color="primary" fontSize="small"/>
                                    </MySvgIcon>
                                ) : (
                                    <MySvgIcon  onClick={() => handleUpdateOpen(true)}>
                                        <WbSunnyOutlinedIcon color="action" fontSize="small"/>
                                    </MySvgIcon>
                                ) 
                            }
                            </IconPaper>
                            <IconPaper i={1}>
                                <MySvgIcon onClick={handleRemove}>
                                    <ClearIcon color="action" fontSize="small"/>
                                </MySvgIcon>
                            </IconPaper>
                        </div>
                        </>
                    )
                }
                
            </MyPaper>
        </Card>
        <SurveyPreviewContainer open={preview} survey_no={survey_no} template={true} survey_workspace_no={survey_workspace_no} onClose={handlePreviewClose}/>
        </>
    )
}

export default memo(ItemComponent)