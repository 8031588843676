/* 
    설문 응답자 토큰
*/
import { useState, useEffect } from 'react'
import jwt from 'jsonwebtoken'
import { getTokenname } from 'utils'

function verify(survey_no: number, method: string) {
    const tokenname = getTokenname(survey_no, method === 'email' ? 'email' : 'ip')

    const tokenString = localStorage.getItem(tokenname) || ''

    let verify = null
    try {
        const token = JSON.parse(tokenString)
      
        verify = { survey_analysis_users_no: token.b }
    } catch(e) {
      
        if (tokenString) {
            try {
                verify = jwt.decode(tokenString)
            } catch(e) {
                
            }
        }
    }

    return verify
      

    // 토큰값이 있는데 survey_analysis_users_no 가 없다면, 삭제된 응답값? 정도로 보면되니. 값이 없다 판단하여 임의로 0번 검색

   
}


const useSurveyTokenInfo = (survey_no: number, method: string) => {

    const [ token, setToken ] = useState(verify(survey_no, method))

    useEffect(() => {
        setToken(verify(survey_no, method))
    }, [survey_no, method])
    
    return token    
}
 
export default useSurveyTokenInfo
