import { memo, ChangeEvent } from 'react'
import { TextField } from '@material-ui/core';
import { isEqual } from 'lodash-es'

interface Props { 
    name: string;
    value: string | number | '';
    label: string;
    maxLength: number;
    readOnly: boolean;
    error: boolean;
    onChange(e:ChangeEvent<HTMLInputElement>): void;
}

function TextFieldComponent(props:Props) {
    const { name, value, label, maxLength, readOnly, error, onChange } = props

    return (
        <TextField 
            fullWidth 
            variant="outlined"
            inputProps={{maxLength, readOnly}}
            error={error}
            label={label} 
            name={name} 
            value={value}
            onChange={onChange}
        />
    )
}

export default memo(TextFieldComponent, (prev, next) => {
    return  isEqual(prev.name, next.name) && 
            isEqual(prev.value, next.value) && 
            isEqual(prev.label, next.label) && 
            isEqual(prev.maxLength, next.maxLength) && 
            isEqual(prev.error, next.error) && 
            isEqual(prev.onChange, next.onChange)
})