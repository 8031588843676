/**
 * ip 중복 미허용일경우 응답자 체크해서 리턴
 * 설문 종료 날짜 체크
 */
import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { getTokenname } from 'utils'
import { GET_SURVEY_POLICY } from 'gql/survey_analysis_users'

export default mode => WrappedComponent => props => {
    const dispatch = useDispatch()

    const survey_no = Number(props.survey_no)

    const tokenname = getTokenname(survey_no)

    const token = localStorage.getItem(tokenname)

    const { data } = useQuery(GET_SURVEY_POLICY, {
        variables: { 
            survey_no,
            token
        },
        onCompleted: () => {
           
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    if (!data) return null

    return <WrappedComponent policyType={data.surveyCheckPolicy} {...props}/>
}