/**
 * args로 survey_no 구하기
 */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_IS_TOKEN } from 'gql/survey_analysis_users'

const tokenname = process.env.REACT_APP_TOKEN

export default mode => WrappedComponent => props => {
    const dispatch = useDispatch()
    
    const [ checked, setChecked ] = useState(false)

    const [ getIsToken ]  = useLazyQuery(GET_IS_TOKEN, {
        onCompleted: async (result) => {
            
            if (!result.surveyIsToken) {
                await sessionStorage.removeItem(tokenname)
            }
            await setChecked(true)
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        const a = async() => {
            if (sessionStorage.getItem(tokenname) !== null) {
                
                getIsToken({ variables: {
                    survey_no: props.survey_no,
                    token: sessionStorage.getItem(tokenname)
                 } })
            } else {
                setChecked(true)
            }
        }

        if (checked === false) a()
    }, [getIsToken, checked, props.survey_no])

    if (!checked) return null

    return <WrappedComponent {...props}/>
}