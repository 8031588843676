import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clipboard from 'copy-to-clipboard'

const useStyles = makeStyles({
    icon: {
        cursor: 'pointer',
        whiteSpace: 'pre',
        paddingLeft: 10
    }
})

let timer = null
export default (props) => {
    const { url, label, ...other } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [state, setState] = useState(false)

    const copy = () => {
        clipboard(url)
        setState(true)
    }

    useEffect(() => {
        if (state) {
            clearTimeout(timer)
            timer = setTimeout(() => setState(false), 1000)
        }

        return () => clearTimeout(timer)
    }, [state])

    const name = f({id: `styled.Survey.CopyLink.button.${!state ? 0 : 1}`})

    return (
        <TextField
            {...other}
            label={label} 
            value={url}
            InputProps={{
                readOnly: true,
                endAdornment: <Button size='small' variant='text' color="primary" className={classes.icon}>{name}</Button>
            }}
            variant="outlined"
            onClick={copy}
        />
    )
}

