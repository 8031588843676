import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { SHOW as POPOVERSHOW, HIDE as POPOVERHIDE } from 'reducer/popover'
import { removeSearchCookie } from 'ts-utils/summary'
import { List, ListItem, ListItemText, Button, Typography } from '@material-ui/core'
import { Today as TodayIcon } from '@material-ui/icons'
import Calendar, { getDate } from 'styled/Survey/Analysis/Calendar'
import withBreakPoints from 'hoc/BreakPoints'

export function getDateName(f, date, xs) {
    // 검색 날짜 표기법 
    function parse(date) {
        return date.substring(xs ? 5 : 2, 11).replace(/-/g, '.')
    }

    function format(sdate, edate) {
        return sdate === edate ? `${parse(sdate)}` : `${parse(sdate)} ~ ${parse(edate)}`
    }

    // 전체 검색
    if (!date) return f({id: 'styled.Survey.Analysis.DateButton.date'})

    // 날짜값 찍어서 검색
    if (date.indexOf('~') > -1) {
        const [ sdate, edate ] = date.split(' ~ ')
        return format(sdate, edate)
    }

    const { sdate, edate } = getDate(date)

    return xs ? `${format(sdate, edate)}` : `${f({id: `styled.Survey.Analysis.Calendar.quick.item.${date}`})}: ${format(sdate, edate)}`
}

const Component = props => {
    /**
     * cookieName: 기간설정 저장 쿠키명 (insight, summary, rows에서 사용)
     * open: caledar open 여부
     * sdate: 시작날짜값 2020-12-12 형태
     * edate: 종료날짜 없으면 오늘
     * onInitial: 전체날짜 상태값 리턴
     * onOpen: 캘린더 오픈
     * onClose: 캘린더 닫기
     * onDo: 캘린더값 전송
     */
    const { 
        style={}, 
        size="small", 
        variant="outlined", 
        cookieName,
        open, 
        date, 
        onInitial, 
        onOpen, 
        onClose, 
        onDo,
        breakpoints
    } = props
    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const { xs } = breakpoints

    // 기간설정 open
    function handleOpenCalendar() {
        onOpen()
    }

    // 기간설정일때는 전체기간 버튼 선택이 필요
    function handleMenu(e) {
        const Component = () => (
            <List>
                <ListItem button onClick={() => {
                    onInitial()
                    removeSearchCookie(cookieName)
                    dispatch({ type: POPOVERHIDE })
                }}>
                    <ListItemText>{f({id: 'styled.Survey.Analysis.DateButton.popover.0'})}</ListItemText>
                </ListItem>
                <ListItem button onClick={() => {
                    onOpen()
                    dispatch({ type: POPOVERHIDE })
                }}>
                    <ListItemText>{f({id: 'styled.Survey.Analysis.DateButton.popover.1'})}</ListItemText>
                </ListItem>
            </List>
        )
    
        dispatch({ 
            type: POPOVERSHOW, 
            component: <Component/>,
            anchorEl: e.currentTarget,
            isClose: false
        })
    }

    // 검색버튼 이름
    const dateName = getDateName(f, date, xs)

    return (
        <>
        <Button 
            variant={variant} 
            disableElevation 
            onClick={(e) => !date ? handleOpenCalendar() : handleMenu(e)} 
            size={size} 
            style={style} 
            startIcon={<TodayIcon/>}
        >
            <Typography noWrap variant="button">{dateName}</Typography>
        </Button>
        <Calendar 
            cookieName={cookieName}
            date={date}
            open={open} 
            onClose={onClose}
            onDo={onDo}
        />
        </>
    )
}

export default withBreakPoints(Component)