import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { List, ListItem, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import LangButton from 'styled/LangButton'
import { useIntl } from 'react-intl'

const useStyles = makeStyles({
    div: {
        width: '100%'
    },
    divider: {
        marginTop: 10,
        marginBottom: 15,
        width: '100%'
    }
})

const Component = () => {
    const dispatch = useDispatch()

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    useEffect(() => {
        function _do() {
            setTimeout(() => dispatch({ type: BACKDROPHIDE }), 500)
        }
        dispatch({ type: BACKDROPSHOW })
        _do()
    }, [dispatch])

    return (
        <List>
            <ListItem>
                <Typography component="div" className={classes.div}>
                    <Typography>{f({id: 'component.MyAccount.Lang.title'})}</Typography>
                    <Divider  className={classes.divider}/>
                    <LangButton size="medium"/>    
                </Typography>
            </ListItem>
        </List>
    )
}

export default Component