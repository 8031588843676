import { memo } from 'react'
import { useIntl } from 'react-intl'
import ConfirmComponent from 'component/Survey/Edit/Confirm'
import {
    SurveyEditTabsComponent,
    SurveyEditTabComponent,
    SurveyEditQuestionComponent,
    SurveyEdit10OptionComponent
} from 'component'
import { getConfirmIndex } from '../00/Container'

const Component = (props) => {
    const { method } = props
    const { formatMessage: f } = useIntl()

    const tabs = [f({id: 'container.Survey.Edit.10.tabs.0'}), f({id: 'container.Survey.Edit.10.tabs.1'})]

    return (
        <>
        <SurveyEditTabsComponent tabs={tabs}/>
        <SurveyEditTabComponent index={0}>
            <SurveyEditQuestionComponent/>
            <ConfirmComponent index={getConfirmIndex(method)}/>
        </SurveyEditTabComponent>
        <SurveyEditTabComponent index={1}>
            <SurveyEdit10OptionComponent/>
        </SurveyEditTabComponent>
        </>
    )
}

export default memo(Component)