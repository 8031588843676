import { memo } from 'react'
import stringWidth from 'string-width'
import { isEqual } from 'lodash-es'
import { withSurveyPopoverImage } from 'hoc'
import { CardStyled } from 'styled/Survey/Img'
import RatingIcon from 'styled/Survey/RatingIcon'
import IconComponent from 'styled/Custom/Icon'

function ItemComponent(props) {
    const { answer, src, icon_type, mobile_one, selected, disabled, survey_module_no, popoverImageProps, answerColor, onChange } = props
    const { on } = popoverImageProps

    return (
        <CardStyled
            answer={answer}
            src={src}
            mobile_one={mobile_one}
            disabled={disabled}  
            answerColor={answerColor}
            answerWidth={stringWidth(answer)}
            onChange={() => onChange(survey_module_no, false, disabled)}
            onChangeZoomImage={on.open}
        >
             <IconComponent selected={selected}  disabled={disabled}>
                <RatingIcon selected={selected} icon_type={icon_type}/>
            </IconComponent>
        </CardStyled>
    )
}

export default withSurveyPopoverImage(memo(ItemComponent, (prev, next) => {
    return  isEqual(prev.answer, next.answer) &&
            isEqual(prev.src, next.src) &&
            isEqual(prev.icon_type, next.icon_type) &&
            isEqual(prev.mobile_one, next.mobile_one) &&
            isEqual(prev.selected, next.selected) && 
            isEqual(prev.disabled, next.disabled) && 
            isEqual(prev.survey_module_no, next.survey_module_no) && 
            isEqual(prev.answerColor, next.answerColor) && 
            isEqual(prev.onChange, next.onChange)
}))