/**
 * 응답자 마다 엔딩페이지가 다르다.
 */
import { useEffect, ComponentType } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_ENDING_USER, SurveyEndingProps } from 'gql/survey_ending'

export interface AnalysisEndingProps {
    rowEnding: SurveyEndingProps;
    loadingEnding: boolean;
}

export default (loading: boolean) => (WrappedComponent: ComponentType<AnalysisEndingProps>)  => (props: { token: string, mode: string, survey_no?: string | number }) => {
    const { token, mode } = props

    const dispatch = useDispatch()

    const params = useParams<{ survey_no: string }>()

    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    const { data, loading: loadingEnding } = useQuery(GET_SURVEY_ENDING_USER, {
        variables: { survey_no, token, mode },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    useEffect(() =>  {  
        if (loading) {
            if (loadingEnding) dispatch({ type: BACKDROPSHOW })
        }
    }, [loadingEnding, dispatch])

    if (!data) return null

    return <WrappedComponent rowEnding={data.surveyEndingUser} loadingEnding={loadingEnding} {...props}/>
}