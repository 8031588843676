import { memo, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import summaryContext from 'context/summary'
import Typography from '@material-ui/core/Typography'

interface Props {
    isNps: boolean;
    left_label: string;
    right_label: string;
}

function SummaryNpsLabelComponent(props: Props) {
    const { isNps, left_label, right_label } = props

    const { design } = useContext(summaryContext)

    const { formatMessage: f } = useIntl()

    const leftLabel = left_label || (isNps ? f({id: `component.Survey.Modules.05.labelNps.0`}) : '')
    const rightLabel = right_label || (isNps ? f({id: `component.Survey.Modules.05.labelNps.1`}) : '')

    return (
        <>
        {
            useMemo(() => (
                <>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 3, gap: 5}}>
                    <Typography variant="caption" style={{color: `rgba(${design.question})`, fontFamily: design.font_family}}>{leftLabel}</Typography>
                    <Typography variant="caption" style={{color: `rgba(${design.question})`, fontFamily: design.font_family}}>{rightLabel}</Typography>                    
                </div>
                </>
            ), [leftLabel, rightLabel, design.question, design.font_family])
        }
        </>      
    )
}

export default memo(SummaryNpsLabelComponent)