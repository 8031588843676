import { Slider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const IOSSlider = withStyles({
    valueLabel: {
        left: 'calc(-50% - 4px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
            fontSize: '14px'
        }
    }  
})(Slider)

export {
    IOSSlider
}