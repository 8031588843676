import styled from 'styled-components'
import oc from 'open-color'
import { makeStyles } from '@material-ui/core/styles'
import { Element } from 'react-scroll'

const WrapperStyled = styled.div`
    width: 100%;
    height: 100%;
    padding-bottom: 10em;
    position: relative;
`

// 스크롤러 설정값
export const scrollerObj = {
    scrollTo: {
        duration: 0,
        delay: 0,
        offset: 0,
        smooth: 'easeInOutQuart'
    }
}

// 편집 래퍼
export const EditWrapper = styled.div`
    padding-top: ${props => props.paddingTop || props.paddingTop === 0 || '.5em' };
    padding-right: ${props => props.paddingRight || props.paddingRight === 0 ||  '2.5em' };
    padding-bottom: ${props => props.paddingBottom || props.paddingBottom === 0 ||  '2.5em' };
    padding-left: ${props => props.paddingLeft || props.paddingLeft === 0 ||  '2.5em' };
`

export const useStyles = makeStyles(theme => ({
    icon: {
        color: oc.gray[7]
    },
    iconSelected: {
        color: theme.palette.primary.dark
    }
}))

const useRootStyles = makeStyles({
    root: ({ 
        top,
        height, 
        paddingTop, 
        paddingBottom,
        borderRight
    }) => ({
        top,
        position: 'sticky',
        width: '100%',
        height,
        overflowY: 'auto',
        paddingTop,
        paddingBottom,
        borderRight
    })
})

export default (props) => {
    const { 
        scrolls={id: '', to: '', bottom: ''},
        top='64px',
        height='calc(100vh - 64px)',
        paddingTop='.8em', 
        paddingBottom='.8em', 
        background, 
        borderRight=`1px solid ${oc.gray[3]}`,
        children 
    } = props

    const classes = useRootStyles({ top, height, paddingTop, paddingBottom, borderRight })

    return (
        <Element id={scrolls.id} className={`${classes.root} Left scroll`} style={{ backgroundColor: background }}>
            <WrapperStyled>
                <Element name={scrolls.to}></Element>
                {children}
            </WrapperStyled>
            <Element name={scrolls.bottom}></Element>
        </Element>
    )
}