import { useContext, useMemo, memo } from 'react'
import { Typography } from '@material-ui/core'
import summaryContext from 'context/summary'
import styled, { css } from 'styled-components'
import { getRGBA } from 'utils/analysis'

const WrapperStyled =styled.div`
    margin-top: ${({index}) => index > 0 ? '15px' : 0};
`

const ContsStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 11.5em;
    
    margin-bottom: .1em;
    word-break: break-all;

    & > *:first-child {
        padding-left: 3px;
    }

    & > *:last-child {
        text-align: right;
        padding-right: 3px;
        align-self: center;
    }
    & .count {
        display: none;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr 4.5em;
        & .percent {
           font-size: 14px;
           line-height: 80%;
        }    
        & .count {
           font-size: 12px;
           display: block;
           line-height: 80%;
           margin-bottom: 5px;
        }
        & .cols {
            display: none;
        }
    };
`
// 게이지 바 스타일
const BarStyled = styled.div`
    width: 100%;
    height: 1.8rem;
    border-radius: 3px;
    position: relative;
    z-index: 1;

    & > div.bg {
        position: absolute;
        z-index: 2;
        background: ${({ bg }) => bg};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 3px;
    }

    & > div.bar {
        background: ${({ bar }) => bar};
        z-index: 3;
        transition: all .3s;
        height: 100%;
        width: 0;
        position: absolute;
        left: 0;
        top: 0;
    }

    & > div.bar.pink {
        background: ${({ bar }) => bar};
    }

    & > div.bar.animation {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        ${({per}) => Number(per.toFixed(1)) === 100.00 && css`
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        `}
    }
`

function Component(props) {
    const { index, per, answer, cols } = props
    const { viewMode, design } = useContext(summaryContext)

    // 보기내용
    const color1 = getRGBA({color: design.question, opacity: 1})

    // 응답수 색상
    const color2 = getRGBA({color: design.question, opacity: .8})


    // 보기 폰트모양
    const fontFamily = design.font_family

    // 바 배경색
    const bg = getRGBA({color: design.bar, opacity: .3})
    // 바 색
    const bar = getRGBA({color: design.bar, opacity: 1})
    
    return (
        <WrapperStyled index={index}>
            {
                useMemo(() => (
                    <ContsStyled>
                        <div><Typography variant="body2" style={{color: color1, fontFamily}}>{answer}</Typography></div>
                        <div>
                            <Typography 
                                variant="body2"
                                style={{color: color1, fontFamily}} 
                                display="inline" 
                                className="percent">
                                    {`${per.toFixed(1)}%`}
                            </Typography>
                            <Typography 
                                variant="body2"
                                style={{color: color2, fontFamily}} 
                                display="block" 
                                className="count">
                                    ({cols})
                            </Typography>
                            <Typography 
                                variant="caption" 
                                style={{color: color2, fontFamily}} 
                                display="inline" 
                                className="cols"> / {cols}
                            </Typography>
                        </div>
                    </ContsStyled>
                ), [color1, color2, fontFamily, answer, per, cols])
            }
            {
                useMemo(() => (
                    <BarStyled per={per} viewMode={viewMode} bg={bg} bar={bar}>
                        <div className="bg"></div>
                        <div className="bar animation" style={{width: `${per}%`}} ></div>
                    </BarStyled>
                ), [per, viewMode, bg, bar])
            }
        </WrapperStyled>
    )
}

export default memo(Component)