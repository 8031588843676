// multi caution

export const SHOW = 'survey/live/caution/SHOW'
export const HIDE = 'survey/live/caution/HIDE'

const initState = {
    survey_question_no: 0,
    code: '',
    message: ''
}

export default (state = initState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                survey_question_no: action.survey_question_no,
                code: action.code,
                message: action.message
            }
        case HIDE:
            return initState
        default:
            return state
    }
}