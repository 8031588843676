import { useMemo } from 'react'
import { _04Answer } from 'gql/survey_question_modules'
import AnswerItemComponent from './AnswerItem'

interface Props {
    answers: _04Answer[];
    scrollPos: number;
}

function AnswerItemsComponent(props: Props) {
    const { answers } = props 

    return (
        <div>
            <div></div>
            {
                useMemo(() => {
                    return (
                        answers.map(c => {
                            return (
                                <AnswerItemComponent 
                                    key={c.survey_module_answer_no} 
                                    answer={c.answer}
                                />
                            )
                        })
                    )    
                }, [answers])
                
            }
        </div>
    )
}

export default AnswerItemsComponent