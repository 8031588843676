export const SHOW = 'summary/SHOW'
export const HIDE = 'summary/HIDE'

const initState = {
    show: false
}

export default (state = initState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                show: true
            }
        case HIDE:
            return initState
        default:
            return initState
    }
}