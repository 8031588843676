import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { GET_USER_TAX_RECENT } from 'gql/user_tax'
import { GET_USER_CARDS } from 'gql/user_card'
import { GET_USER_PAYMENTS, GET_USER_PAYMENT_NON_APPLY } from 'gql/user_payment'
import { GET_OVER_RESPONSE, GET_STANDARD_RESPONSE } from 'gql/user_response'
import { GET_PERMIT_LIST } from 'gql/user_permit'
import { GET_STORAGE } from 'gql/storage'
import { GET_USER_PAYSET_IN_USER } from 'gql/user_payset'
import { GET_USER_PAYBLOCK_IN_USER } from 'gql/user_payblock'
import { GET_OTP } from 'gql/otp'

/*
root 값
{
    mbox: true 라면 fetch
    _fetchPolicy: {mbox: 'cache-first'}  패치정책 default network_only
}

loading : boolen
*/

class fetchPolicyClass {
    get root() {
        return this._root
    }

    set root(val) {
        this._root = val
    }

    // fetch value
    get(kind) { 
        //_fetchPolicy가 정의되지 않았다는건 모두 network-noly
        const defaultFetchPolicy = 'network-only'

        if (typeof(this._root._fetchPolicy) === 'undefined') return defaultFetchPolicy

        if (typeof(this._root._fetchPolicy[kind]) === 'undefined') return defaultFetchPolicy

        return this._root._fetchPolicy[kind]
    }
}

const parseStorage = (data) => {
    
    const parseData = {
        ...data,
        myPhoto: Number(data.myPhoto),
        fileupload: Number(data.fileupload)
    }

    return parseData
}

const fetchPolicy = new fetchPolicyClass()

export default root => loading => WrappedComponent => props => {
    const dispatch = useDispatch()

    fetchPolicy.root = root

    const [getUserTaxRecent, { data: dataUserTaxRecent }] = useLazyQuery(GET_USER_TAX_RECENT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('userTaxRecent'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getUserCards, { data: dataUserCards, refetch: refetchUserCards }] = useLazyQuery(GET_USER_CARDS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('userCards'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getUserPayments, { data: dataUserPayments }] = useLazyQuery(GET_USER_PAYMENTS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('userPayments'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getUserPaymentNonApply, { data: dataUserPaymentNonApply, refetch: refetchUserPaymentNonApply }] = useLazyQuery(GET_USER_PAYMENT_NON_APPLY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('userPaymentsNonApply'),
        onCompleted: () => {
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getOverResponse, { data: dataOverResponse }] = useLazyQuery(GET_OVER_RESPONSE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('overResponse'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getStandardResponse, { data: dataStandardResponse }] = useLazyQuery(GET_STANDARD_RESPONSE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('standardResponse'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getStorage, { data: dataStorage, refetch: refetchStorage }] = useLazyQuery(GET_STORAGE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('storage'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getPermitList, { data: dataPermitList }] = useLazyQuery(GET_PERMIT_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('permitList'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getUserPaysetInUser, { data: dataUserPaysetInUser, refetch: refetchUserPaysetInUser }] = useLazyQuery(GET_USER_PAYSET_IN_USER, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('userPaysetInUser'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getUserPayblockInUser, { data: dataUserPayblockInUser }] = useLazyQuery(GET_USER_PAYBLOCK_IN_USER, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('userPayblockInUser'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const [getOtp, { data: dataOtp, refetch: refetchOtp }] = useLazyQuery(GET_OTP, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('otp'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    
    useEffect(() => {
        if (root.userTaxRecent) getUserTaxRecent()
        if (root.userCards) getUserCards()
        if (root.userPayments) getUserPayments()
        if (root.userPaymentNonApply) getUserPaymentNonApply()
        if (root.overResponse) getOverResponse()
        if (root.standardResponse) getStandardResponse()
        if (root.storage) getStorage()
        if (root.permitList) getPermitList()
        if (root.userPaysetInUser) getUserPaysetInUser()
        if (root.userPayblockInUser) getUserPayblockInUser()
        if (root.otp) getOtp()
    }, [
        getUserTaxRecent,
        getUserCards,
        getUserPayments,
        getUserPaymentNonApply,
        getOverResponse,
        getStandardResponse,
        getStorage,
        getPermitList,
        getUserPaysetInUser,
        getUserPayblockInUser,
        getOtp
    ])

    useEffect(() => {
        if (loading) dispatch({ type: BACKDROPSHOW })
    }, [dispatch])

    if (root.userTaxRecent && !dataUserTaxRecent) return null
    if (root.userCards && !dataUserCards) return null
    if (root.userPayments && !dataUserPayments) return null
    if (root.userPaymentsNonApply && !dataUserPaymentNonApply) return null
    if (root.overResponse && !dataOverResponse) return null
    if (root.standardResponse && !dataStandardResponse) return null
    if (root.permitList && !dataPermitList) return null
    if (root.storage && !dataStorage) return null
    if (root.userPaysetInUser && !dataUserPaysetInUser) return null
    if (root.userPayblockInUser && !dataUserPayblockInUser) return null
    if (root.otp && !dataOtp) return null

    const propsFetchData = {
        userTaxRecent: dataUserTaxRecent ? dataUserTaxRecent.userTaxRecent : '',
        userCards: dataUserCards ? dataUserCards.userCards : '',
        userPayments: dataUserPayments ? dataUserPayments.userPayments : '',
        userPaymentNonApply: dataUserPaymentNonApply ? dataUserPaymentNonApply.userPaymentNonApply : '',
        overResponse: dataOverResponse ? dataOverResponse.overResponse : 0,
        standardResponse: dataStandardResponse ? dataStandardResponse.standardResponse : '',
        storage: dataStorage ? parseStorage(dataStorage.storage) : '',
        permitList: dataPermitList ? dataPermitList.permitList : '',
        userPaysetInUser: dataUserPaysetInUser ? dataUserPaysetInUser.userPaysetInUser : '',
        userPayblockInUser: dataUserPayblockInUser ? dataUserPayblockInUser.userPayblockInUser : null,
        otp: dataOtp ? dataOtp.otp : null,
        refetchUserPaymentNonApply,
        refetchUserCards,
        refetchUserPaysetInUser,
        refetchOtp,
        refetchStorage
    }
    
    return <WrappedComponent propsFetchData={propsFetchData} {...props}/>
}