import { useState, useCallback, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_SURVEY_TEMPLATE_CATEGORY_SORT } from 'gql/survey_template'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { ParentProps } from './Component'
import CreateTemplateComponent from './CreateTemplate'
import CategoryManageItemComponent from './CategoryManageItem'

const MyBox = withStyles({
    root: {
        overflow: 'hidden'
    }
})(Box)

const MyItemBox = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        marginTop: 10,
        marginBottom: 10
    }
})(Box)

function CategoryManageComponent(props: ParentProps) {
    const { data, refetch } = props

    const [ rows, setRows ] = useState(data)
    const [ height, setHeight ] = useState(false)

    const [ updateSort ] = useMutation(UPDATE_SURVEY_TEMPLATE_CATEGORY_SORT, {
        onCompleted: () => {
            refetch()
        }
    })

    const handleDnD = useCallback((result: DropResult) => {
        if (!result.destination) {
            return
        }

        const copy = [...rows]

        const sourceIndex = result.source.index - 1 //원래 인덱스
        const destinationIndex = result.destination.index - 1
    
        // 옮겨져야할 값 을 배열에서 삭제하고 value값은 저장
        const [removedData] = copy.splice(sourceIndex, 1)
    
        // 옮겨져야할 값 추가
        copy.splice(destinationIndex, 0, removedData)
        
        setRows(copy)

        updateSort({
            variables: {
                survey_template_category_nos: copy.map(c => c.survey_template_category_no)
            }
        })
        
    }, [rows, updateSort])

    useEffect(() => {
        setRows(data)
    }, [data])

    return (
        <>
        <div style={{paddingTop: 10, paddingBottom: 20, cursor: 'pointer'}} onClick={() => setHeight(prev => !prev)}>{height ? 'CATEGORY EDIT CLOSE' : 'CATEGORY EDIT OPEN'}</div>
        <MyBox style={{height: height ? 'auto' : 0}}>
            <CreateTemplateComponent data={data} refetch={refetch}/>
            <DragDropContext onDragEnd={handleDnD} >
                <Droppable droppableId='category'>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}  {...provided.droppableProps}> 
                            <MyItemBox>
                                {
                                    rows.map((c, i) => (
                                        <CategoryManageItemComponent key={c.survey_template_category_no} {...c} index={i} refetch={refetch}/>
                                    ))
                                }
                            </MyItemBox>
                            {provided.placeholder}    
                        </div>
                    )}
                
                </Droppable>
            </DragDropContext>
        </MyBox>
        </>
    )
}

export default CategoryManageComponent