/**
 * 응답하다가 완료시
 */
import { useDispatch } from 'react-redux'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SurveyEndingProps } from 'gql/survey_ending'
import { SurveyModulesEndingComponent } from 'component'
import { useEffect } from 'react'

interface Props {
    survey_no: number;
    row: SurveyEndingProps;
}
function EndingComponent(props: Props) {

    const { row, survey_no } = props

    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch({ type: BACKDROPHIDE })
    }, [row, survey_no, dispatch])

    return (
        <SurveyModulesEndingComponent survey_no={survey_no} row={row}/>
    )
}

export default EndingComponent