import gql from 'graphql-tag'

export const GET_PERMIT = gql`
    query Permit {
        userPermit {
            limit_file_size  
        }
    }
`

export const GET_PERMIT_LIST = gql`
    query PermitList {
        permitList {
            level0 {
                limit_file_size
                response
                limit_fileupload_size
            }
            level10 {
                limit_file_size
                response
                limit_fileupload_size
            }  
            level100 {
                limit_file_size
                response
                limit_fileupload_size
            }
            level1000 {
                limit_file_size
                response
                limit_fileupload_size
            }
        }
    }
`

export interface PermitList {
    level0: {
        limit_file_size: number;
        response: number;
        limit_fileupload_size: [string, string];
    }
    level10: {
        limit_file_size: number;
        response: number;
        limit_fileupload_size: [string, string];
    }
    level100: {
        limit_file_size: number;
        response: number;
        limit_fileupload_size: [string, string];
    }
    level1000: {
        limit_file_size: number;
        response: number;
        limit_fileupload_size: [string, string];
    }
}