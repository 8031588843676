import { useMemo, useContext } from 'react'
import { FormControlElement } from 'styled/Survey/MatrixTable'
import { Context } from './Component'
import MobileAnswerChildItemComponent from './MobileAnswerChildItem'

function MobileAnswerItemComponent(props) {
    const { selected, survey_module_no, survey_module_answer_no, answer } = props

    const { onChange } = useContext(Context)

    return (
        <>
        {
            useMemo(() => {
                return (
                    <FormControlElement className={selected ? 'selected' : ''} onClick={() => onChange(survey_module_no, survey_module_answer_no)}>
                        <MobileAnswerChildItemComponent selected={selected} answer={answer}/>
                    </FormControlElement>
                )
            }, [survey_module_no, survey_module_answer_no, answer, selected, onChange])
        }
        </>
        
    )
}

export default MobileAnswerItemComponent