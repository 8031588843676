import { useCallback } from 'react'
import ChannelIO from 'utils/channel'
import * as config from 'config/channel.json'

const key = config.key

const useChannelIO = (props) => {
    const { user_no, email } = props

    const getChannel = useCallback(async() => {
        const res = await ChannelIO.boot({
            "pluginKey": key,
            "memberId": user_no,
            "hideChannelButtonOnBoot": true,
            "profile": {
                "email": email
            }
        })

        return res
    }, [user_no, email])

    return getChannel
 }
 
export default useChannelIO
