import { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import Answer from 'styled/Custom/Answer'

export const MyBox = withStyles({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left'
    }
})(Box)

function AnswerItemComponent(props) {
    const { answer } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        {
            useMemo(() => {
                return (
                    <div className="center">
                        <MyBox>
                            <Answer>{answer || f({id: 'component.Survey.Modules.03.answer'})}</Answer>
                        </MyBox>
                    </div>
                )
            }, [answer, f])
        }
        </>
    )
}

export default memo(AnswerItemComponent, (prev, next) => {
    return  isEqual(prev.answer, next.answer)
})