import gql from 'graphql-tag'

export const UPDATE_SURVEY_MBOX_COVER= gql`
    mutation UpdateSurveyMboxCover($input: UpdateSurveyMboxCover!) {
        updateSurveyMboxCover(input: $input)
    }
`

export const UPDATE_SURVEY_MBOX_COVER_USED = gql`
    mutation UpdateSurveyMboxCoverUsed($survey_no: Int!, $used: Boolean!) {
        updateSurveyMboxCoverUsed(survey_no: $survey_no, used: $used)
    }
`

export const GET_SURVEY_MBOX_COVER = gql`
    query SurveyMboxCover($survey_no: Int!, $mode: Mode!) {
        surveyMboxCover(survey_no: $survey_no, mode: $mode) {
            used
            src
            animation
            question_top_used
            question_top_align
        }
    }
`

export interface SurveyMboxCoverProps {
    used: boolean;
    src: string;
    animation: string;
    question_top_used: boolean;
    question_top_align: 'left' | 'center' | 'right'
}