import gql from 'graphql-tag'

export const DELETE_SURVEY_ANALYSIS_USERS_INDIVIDUAL = gql`
    mutation DeleteSurveyAnalysisUsersIndividual($survey_no: Int!, $ids: [Int!]!) {
        deleteSurveyAnalysisUsersIndividual(survey_no: $survey_no, ids: $ids)
    }
`

export const DELETE_SURVEY_ANALYSIS_USERS_ALL = gql`
    mutation DeleteSurveyAnalysisUsersAll($survey_no: Int!, $input: DeleteSurveyAnalysisUsersAll!) {
        deleteSurveyAnalysisUsersAll(survey_no: $survey_no, input: $input)
    }
`

export const DELETE_SURVEY_ANALYSIS_USERS_ALL_SOCKET = gql`
    mutation DeleteSurveyAnalysisUsersAllSocket($survey_no: Int!, $input: DeleteSurveyAnalysisUsersAll!) {
        deleteSurveyAnalysisUsersAllSocket(survey_no: $survey_no, input: $input)
    }
`

export const DELETE_SURVEY_ANALYSIS_USER_FILES_INDIVIDUAL = gql`
    mutation DeleteSurveyAnalysisUserFilesIndividual($survey_no: Int!, $ids: [Int!]!) {
        deleteSurveyAnalysisUserFilesIndividual(survey_no: $survey_no, ids: $ids)
    }
`

export const DELETE_SURVEY_ANALYSIS_USER_FILES_ALL = gql`
    mutation DeleteSurveyAnalysisUserFilesAll($survey_no: Int!, $input: DeleteSurveyAnalysisUsersAll!) {
        deleteSurveyAnalysisUserFilesAll(survey_no: $survey_no, input: $input)
    }
`

export const DELETE_SURVEY_ANALYSIS_USER_FILES_USER = gql`
    mutation DeleteSurveyAnalysisUserFilesUser {
        deleteSurveyAnalysisUserFilesUser
    }
`

export const CREATE_SURVEY_ANALYSIS_USERS_EMAIL = gql`
    mutation CreateSurveyAnalysisUsers_EMAIL($survey_no: Int!, $email: String!, $name: String, $device: Device!, $mode: Mode!) {
        createSurveyAnalysisUsers_EMAIL(survey_no: $survey_no, email: $email, name: $name, device: $device, mode: $mode) {
            token
        }
    }
`

export const CREATE_SURVEY_ANALYSIS_USERS_IP = gql`
    mutation CreateSurveyAnalysisUsers_IP($survey_no: Int!, $device: Device!, $mode: Mode!, $referer: String) {
        createSurveyAnalysisUsers_IP(survey_no: $survey_no, device: $device, mode: $mode, referer: $referer) {
            token
        }
    }
`

export const UPDATE_SURVEY_ANALYSIS_START = gql`
    mutation UpdateSurveyAnalysisStart($survey_no: Int!, $token: ID!, $mode: Mode!) {
        updateSurveyAnalysisStart(survey_no: $survey_no, token: $token, mode: $mode)
    }
`

export const UPDATE_SURVEY_ANALYSIS_STATUS = gql`
    mutation UpdateSurveyAnalysisStatus($survey_no: Int!, $survey_page_no: Int!, $token: ID!, $step: Step!, $einterval: Int!, $mode: Mode!) {
        updateSurveyAnalysisStatus(survey_no: $survey_no, survey_page_no: $survey_page_no, token: $token, step: $step, einterval: $einterval, mode: $mode)
    }
`

export const UPDATE_SURVEY_ANALYSIS_STATUS_ONE = gql`
    mutation UpdateSurveyAnalysisStatusOne($survey_no: Int!, $survey_question_no: Int!, $token: ID!, $step: Step!, $einterval: Int!, $mode: Mode!) {
        updateSurveyAnalysisStatusOne(survey_no: $survey_no, survey_question_no: $survey_question_no, token: $token, step: $step, einterval: $einterval, mode: $mode)
    }
`

export const UPDATE_SURVEY_ANALYSIS_USERS_RESET = gql`
    mutation UpdateSurveyAnalysisUsersReset($survey_no: Int!, $survey_analysis_users_no: Int!, $device: Device!) {
        updateSurveyAnalysisUsersReset(survey_no: $survey_no, survey_analysis_users_no: $survey_analysis_users_no, device: $device)
    }
`


export const UPDATE_SURVEY_ANALYSIS_USERS_MOVE_FIRST_QUESTION = gql`
    mutation UpdateSurveyAnalysisUsersMoveFirstQuestion($survey_no: Int!, $token: ID!, $first_question: Int!, $restart: Boolean!, $method: Method!) {
        updateSurveyAnalysisUsersMoveFirstQuestion(survey_no: $survey_no, token: $token, first_question: $first_question, restart: $restart, method: $method)
    }
`

export const DELETE_SURVEY_ANALYSIS_QUESTION = gql`
    mutation DeleteSurveyAnalysisQuestion($survey_no: Int!, $survey_question_no: Int!, $typename: String!, $date: String!, $step: Int!) {
        deleteSurveyAnalysisQuestion(survey_no: $survey_no, survey_question_no: $survey_question_no, typename: $typename, date: $date, step: $step)
    }
`

export const GET_SURVEY_ANALYSIS_19_USED_FILESIZE = gql`
    query SurveyAnalysis19UsedFilesize {
        surveyAnalysis19UsedFilesize
    }
`

// 설문 참여시 파일업로드 사용량 체크
export const GET_SURVEY_ANALYSIS_19_IS_POSSIBLE_FILE_UPLOAD = gql`
    query SurveyAnalysis19IsPossibleFileUpload($survey_no: Int!) {
        surveyAnalysis19IsPossibleFileUpload(survey_no: $survey_no)
    }
`

export const GET_SURVEY_ANALYSIS_INSIGHT = gql`
    query SurveyAnalysisInsight($survey_no: Int!, $sdate: String, $edate: String, $deviceType: Device) {
        surveyAnalysisInsight(survey_no: $survey_no, sdate: $sdate, edate: $edate, deviceType: $deviceType) {
            connectCount
            startCount
            completeCount
            completeInterval
            deviceConnectCount
            groupDayConnectCount
            groupWeekDayConnectCount
            groupTimeConnectCount
            refererConnectCount {
                url
                contury
                city
            }
        }
    }
`

export const GET_SURVEY_POLICY = gql`
    query SurveyCheckPolicy($survey_no: Int!, $token: ID) {
        surveyCheckPolicy(survey_no: $survey_no, token: $token) {
            type
            message
            button
            link
        }
    }
`

export const GET_SURVEY_POLICY_EMAIL = gql`
    query SurveyCheckPolicyEmail($survey_no: Int!, $email: String!) {
        surveyCheckPolicyEmail(survey_no: $survey_no, email: $email) {
            type
            message
            button
            link
        }
    }
`

export const GET_SURVEY_ANALYSIS_USER = gql`
    query surveyAnalysisUser($survey_no: Int!, $token: ID!, $mode: Mode!) {
        surveyAnalysisUser(survey_no: $survey_no, token: $token,  mode: $mode) {
            survey_page_no
            survey_question_no
            start
        }
    }
` 

export const schemaSurveyAnalysisUserAll = `
    ip
    email
    survey_analysis_users_no
    name
    wdate
    start
    step
    einterval
`

export const GET_SURVEY_ANALYSIS_USER_ALL = gql`
    query SurveyAnalysisUserAll($survey_no: Int!, $mode: Mode!) {
        surveyAnalysisUserAll(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyAnalysisUserAll}
        }
    }
`

export const GET_SURVEY_ANALYSIS_RESPONSE_COUNT = gql`
    query surveyAnalysisResponseCount($survey_no: Int!, $mode: Mode!, $step: Int!) {
        surveyAnalysisResponseCount(survey_no: $survey_no, mode: $mode, step: $step)
    }
`

export const GET_IS_TOKEN = gql`
    query SurveyIsToken($survey_no: Int!, $token: ID!) {
        surveyIsToken(survey_no: $survey_no, token: $token)
    }
`

export const GET_SURVEY_ANALYSIS_USERS_COUNT = gql`
    query SurveyAnalysisUserCount($survey_no: Int!) {
        surveyAnalysisUserCount(survey_no: $survey_no)
    }
`

export const GET_SURVEY_ANALYSIS_RESPONSE_START_TOTAL_COUNT = gql`
    query SurveyAnalysisResponseStartTotalCount {
        surveyAnalysisResponseStartTotalCount
    }
`

export const GET_SURVEY_ANALYSIS_IS_RESPONSE = gql`
    query SurveyAnalysisIsResponse($survey_no: Int!) {
        surveyAnalysisIsResponse(survey_no: $survey_no)
    }
`

export const GET_SURVEY_ANALYSIS_END_RESPONSE = gql`
    query SurveyAnalysisEndResponse($survey_no: Int!) {
        surveyAnalysisEndResponse(survey_no: $survey_no)
    }
`

export const SURVEY_ANALYSIS_QUESTION_COUNT = gql`
    query SurveyAnalysisQuestionCount($survey_no: Int!, $survey_question_no: Int!, $typename: String!, $date: String!, $step: Int!) {
        surveyAnalysisQuestionCount(survey_no: $survey_no, survey_question_no: $survey_question_no, typename: $typename, date: $date, step: $step)
    }
`


export interface SurveyAnalysisEndResponseProps {
    end_count: number;
    today_end_count: number;
}