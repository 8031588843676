import { useState, useCallback } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell, { TableCellProps } from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { PayResult } from 'ts-policy'
import SearchComponent from './Search'
import ItemsComponent from './Items'
import oc from 'open-color'

export type ParentPayResult = PayResult | 'all'

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: oc.indigo[0],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(({ align='right', ...other }: TableCellProps) => <TableCell align={align} {...other}/>)


const useStyles = makeStyles(theme => ({
	tableContainer: {
		marginTop: 10,
		maxHeight: 'calc(100vh - 170px)',
		[theme.breakpoints.down('sm')]: {
			maxHeight: 'calc(100vh - 170px)'
		},
		[theme.breakpoints.down('xs')]: {
			maxHeight: 'calc(100vh - 162px)'
		}
	},
	table: {
		minWidth: 700
	}
}))

function PayComponent() {
  	const classes = useStyles()

	const { formatMessage: f } = useIntl()

	const [ date, setDate ] = useState(Number(moment().format('YYYY')))
	const [ payResult, setPayResult ] = useState<ParentPayResult>('all')

	const handelChangeDate = useCallback((e) => {
		const { value } = e.target
		setDate(value)
	}, [])

	const handelChangePayResult = useCallback((e) => {
		const { value } = e.target
		setPayResult(value)
	}, [])

	return (
		<>
		<SearchComponent date={date} payResult={payResult} onChangeDate={handelChangeDate} onChangePayResult={handelChangePayResult}/>
		<TableContainer 
			className={classes.tableContainer}
			component={props => <Paper elevation={1} {...props}/>} 
		>
		<Table className={classes.table} stickyHeader>
			<TableHead>
			<TableRow>
				<StyledTableCell align="left">{f({id: 'component.MyAccount.Pay.Component.column.date'})}</StyledTableCell>
				<StyledTableCell>{f({id: 'component.MyAccount.Pay.Component.column.level'})}</StyledTableCell>
				<StyledTableCell>{f({id: 'component.MyAccount.Pay.Component.column.amt'})}</StyledTableCell>
				<StyledTableCell>{f({id: 'component.MyAccount.Pay.Component.column.apply'})}</StyledTableCell>
				<StyledTableCell>{f({id: 'component.MyAccount.Pay.Component.column.tax'})}</StyledTableCell>
				<StyledTableCell></StyledTableCell>
			</TableRow>
			</TableHead>
			<TableBody>
				<ItemsComponent date={date} payResult={payResult}/>
			</TableBody>
		</Table>
		</TableContainer>
		</>
	)
}

export default PayComponent