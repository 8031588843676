/**
 * 응답페이지 정렬
 */
 import { getSearchCookie, setSearchCookie } from 'ts-utils/summary'

 export const CHANGE = 'analysis/rows/order/CHANGE' // 값 변경시
 export const INIT = 'analysis/rows/order/INIT'     // 초기 쿠키에서 값 가져올때
 
 const getCookieName = (survey_no: number) => {
     return `rows-order-${survey_no}`
 }
 
 type OrderType = {
     key: 'id' | 'einterval' | 'accesstime' | 'ip';
     value: boolean;
 }
 
 // order: answer => 보기순, count => 응답순
 // pointType: point => 가중치로 계산, count => 응답수로 계산 
 const initState: OrderType = {
     key: 'einterval',
     value: true
 }
  
 interface ActionProps {
     type: string;
     survey_no: number;
     key: OrderType['value'];
     value: OrderType['value'];
 }
  
 export default (state = initState, action: ActionProps) => {
     if (action.type === CHANGE) {
         const cookieName = getCookieName(action.survey_no)
         setSearchCookie(cookieName, JSON.stringify({ key: action.key, value: action.value }))
         return {
             key: action.key,
             value: action.value
         }
     } else if (action.type === INIT) { // 초기값은 스토리지에서 가져온다.
         const cookieName = getCookieName(action.survey_no)
         const values = getSearchCookie(cookieName)
         const { key, value } = values ? JSON.parse(values) : { ...initState }
 
         return { 
             key, 
             value
         }
     } else return state
 }