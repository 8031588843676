/**
 * 작업함 응답 수(완료 수) 가져왔던 설문 번호 저장
 */

export const CHANGE = 'survey/workspace/responseCount/change'

interface InitProps {
    survey_nos: number[];
}

const initState: InitProps = {
    survey_nos: []
}

interface ActionProps {
    type: string;    
    survey_no: number;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE:
            return {
                survey_nos: [...state.survey_nos, action.survey_no]
            }
        default:
            return state
    }
}