/**
 * 스크롤 팁
 */
import { memo } from 'react'
import { useIntl } from 'react-intl'
import Answer from 'styled/Custom/Answer'

interface Props {
    isScroll: boolean
}

function ScrollTipComponent(props: Props) {
    const { isScroll } = props

    const { formatMessage: f } = useIntl()

    if (!isScroll) return null

    return (
        <div style={{position: 'sticky', left: 0}}>
            <Answer style={{fontSize: 11, fontWeight: 900}}>
                {f({id: "component.Survey.Modules.03.ScrollTip.content"})}
            </Answer>
        </div>
    )
}

export default memo(ScrollTipComponent)