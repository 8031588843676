/**
 * 1문1답 or 점프로직이 있을경우 전체 응답값 변경한다
 */
import { useContext, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { JumpProps } from 'gql/jump'
import { UPDATE_SURVEY_ANALYSIS_START } from 'gql/survey_analysis_users'
import { SHOW as ANSWERERRORPAGESHOW } from 'reducer/answerErrorPage'
import { parseGraphqlError } from 'utils'
import { FormatAll } from 'hoc/Survey/SetReplys'


interface ContextProps {
    survey_no: number;
    mbox: {
        method: 'multi' | 'one'
    };
    rowsJump: JumpProps[];
    userStart: boolean;
    token: string;
    onChangeReplys: (a: FormatAll[]) => void;
}

// survey_analysis_user start=1이 되면 true로 바꿔준다.
let userStarted = false
function useChangeReplys(survey_question_no: number) {
    const { survey_no, mbox, rowsJump, userStart, token, onChangeReplys } = useContext<ContextProps>(PropsContext)
    const { mode } = useContext<{ mode: string }>(AccessMethodContext)

    const { method } = mbox
    
    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    if (userStarted === false && userStart === true) userStarted = true

    const [ updateSurveyAnalysisStart ] = useMutation(UPDATE_SURVEY_ANALYSIS_START, {
        onCompleted: () => {
            userStarted = true
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'No User') {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.noUser'})})
            } else {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
            }
        }
    }) 

    const handleChangeUserStart = useCallback(() => {
        if (!userStarted) {
            if (survey_no && token) {
                updateSurveyAnalysisStart({variables: { 
                    survey_no,
                    token,
                    mode
                }})
            }
        }
    }, [survey_no, token, mode, updateSurveyAnalysisStart])


    const handleChane = useCallback((replys: FormatAll[]) => {
        if (method === 'one' || rowsJump.find(c => c.id === survey_question_no)) {
            onChangeReplys(replys)
        }

        handleChangeUserStart()
    }, [survey_question_no, method, rowsJump, onChangeReplys, handleChangeUserStart])

    return [handleChane, handleChangeUserStart]

}

export default useChangeReplys