import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { getLimitMessage } from 'ts-utils/survey'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'


function useLimitQuestionMessage() {

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    // 문항수 추가 제한 alert
    const showLimitQuestion = useCallback((e) => {
        const msg = getLimitMessage(e)

        if (typeof msg === 'object') {
            const [ section, all ] = msg

            dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'policyMessage.limitQuestionCount'}, {section, all}), variant: 'error', duration: 100000 })
        } else {
            dispatch({ type: ERRORPAGESHOW })
        }

    }, [dispatch, f])

    return showLimitQuestion
}

export default useLimitQuestionMessage