import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { List, Divider } from '@material-ui/core'
import { GET_USER_ALARM } from 'gql/user_alarm'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKGROPHIDE } from 'reducer/backdrop'
import { postMessage } from 'utils'
import NewsletterComponent from './Newsletter'
import PolicyComponent from './Policy'

const Component = () => {

    const dispatch = useDispatch()

    const { data, loading, refetch } = useQuery(GET_USER_ALARM, {
        onCompleted: () => {
            dispatch({ type: BACKGROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKGROPHIDE })

            postMessage({ 
                dispatch: { type: ERRORPAGESHOW } 
            })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (loading) dispatch({ type: BACKDROPSHOW })
    }, [loading, dispatch])

    if (!data) return null

    return (
        <List>
            <NewsletterComponent newsletter={data.userAlarm.newsletter} refetch={refetch}/>
            <Divider/>
            <PolicyComponent policy={data.userAlarm.policy} refetch={refetch}/>
        </List>
    )
}

export default Component