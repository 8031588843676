import gql from 'graphql-tag'

const schemaCommon = `
    user_payment_no
    user_payset_no
    kind
    levels
    month
    amt
    discount
    add_amt
    pay_method
    tax_in_innopay
`

export const CREATE_USER_CARD_PAYMENT = gql`
    mutation CreateUserCardPayment($input: CreateUserCardPayment!) {
        createUserCardPayment(input: $input) {
            ${schemaCommon}
        }
    }
`

export const CREATE_USER_EPAY_PAYMENT = gql`
    mutation CreateUserEpayPayment($input: CreateUserEpayPayment!) {
        createUserEpayPayment(input: $input) {
            ${schemaCommon}
        }
    }
`

export const CREATE_USER_TRANS_PAYMENT = gql`
    mutation CreateUserTransPayment($input: CreateUserTransPayment!) {
        createUserTransPayment(input: $input) {
            ${schemaCommon}
        }
    }
`

export const CREATE_USER_VBANK_PAYMENT = gql`
    mutation CreateUserVbankPayment($input: CreateUserVbankPayment!) {
        createUserVbankPayment(input: $input) {
            ${schemaCommon}
            vbankDate
            cardNum
            appCardName
            order_name
        }
    }
`

export const CREATE_USER_NBANK_PAYMENT = gql`
    mutation CreateUserNbankPayment($input: CreateUserNbankPayment!) {
        createUserNbankPayment(input: $input) {
            ${schemaCommon}
            vbankDate
            order_name
        }
    }
`




export const CREATE_USER_AUTH_CARD_PAYMENT = gql`
    mutation CreateUserAuthCardPayment($input: CreateUserAuthCardPayment!) {
        createUserAuthCardPayment(input: $input) {
            ${schemaCommon}
        }
    }
` 

export const CREATE_USER_CARD_AUTH_CARD_PAYMENT = gql`
    mutation CreateUserCardAuthCardPayment($card: String!, $payment: CreateUserCardAuthCardPayment!) {
        createUserCardAuthCardPayment(card: $card, payment: $payment) {
            ${schemaCommon}
        }
    }
`

export const CREATE_USER_UNAUTH_CARD_PAYMENT = gql`
    mutation CreateUserUnAuthCardPayment($card: String!, $payment: CreateUserCardAuthCardPayment!) {
        createUserUnAuthCardPayment(card: $card, payment: $payment) {
            ${schemaCommon}
        }
    }
`




export const DELETE_USER_PAYMENT_NON_APPLY = gql`
    mutation DeleteUserPaymentNonApply($user_payment_no: Int!) {
        deleteUserPaymentNonApply(user_payment_no: $user_payment_no)
    }
`

export const GET_USER_PAYMENTS = gql`
    query UserPayments($s_date: String!, $e_date: String!, $pay_result: PayResult) {
        userPayments(s_date: $s_date, e_date: $e_date, pay_result: $pay_result) {
            user_payment_no
            user_payset_no
            kind
            levels
            month
            pay_method
            amt
            discount
            appCardName
            cardNum
            vbankDate
            order_name
            tax_in_innopay
            tid
            wdate
            adate
            cdate
            amt
            receiptUrl
            user_tax {
                tax_type
                modify_possible
                id_no
                name
                email
                company_name
                mobile_no
            }
            user_payset {
                product_name
                content
            }
        }
        
    }
`

export const GET_USER_PAYMENT_NON_APPLY = gql`
    query UserPaymentNonApply {
        userPaymentNonApply {
            user_payment_no
            user_payset_no
            pay_method
            tax_in_innopay
            vbankDate
            cardNum
            appCardName
            order_name
            kind
            levels
            month
            amt
            discount
            add_amt
            user_tax {
                tax_type
                id_no
                name
                email
                company_name
                mobile_no
            }
        }
    }
`


// 토스
export const CREATE_USER_PAYMENT = gql`
    mutation CreateUserPayment($input: CreateUserPayment!) {
        createUserPayment(input: $input) {
            ${schemaCommon}
        }
    }
`