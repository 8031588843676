import { isMobileOnly } from 'react-device-detect'

// 테블릿일 경우  최대 2줄 모바일은 1줄로 한다 (글씨가 가로폭이 좁아 보기흉함)
const changeLayout = ({ xs, md, layout }) => {
    if (xs) return 1
    else if (md && layout > 2) return 2
    
    return layout
}

export default WrappedComponent => props => {
    const { lists, layout, breakpoints, ...other } = props
    const { xs, md } = breakpoints
    
    const newLayout = isMobileOnly ? 1 : changeLayout({ xs, md, layout })
    
    const len = lists.length

    // 리스트의 랜덤 적용및 열수에 맞는 보기수 처리
    const customLists =  [...lists]

    // row수
    const boxRows = new Array(Math.ceil(len / newLayout)).fill(true)

    // 열수
    const boxCells = boxRows.reduce((acc) => ([
        ...acc,
        customLists.splice(0, newLayout)
    ]), [])

    return <WrappedComponent boxRows={boxRows} boxCells={boxCells} layout={newLayout} lists={lists} breakpoints={breakpoints} {...other}/>
}