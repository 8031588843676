import { memo } from 'react'
import { InputAdornment } from '@material-ui/core'
import { MailOutline as MailOutlineIcon } from '@material-ui/icons'
import { isEqual } from 'lodash-es'
import Input from 'styled/Custom/Input'
import useValue from './useValue'
import { getIsDisabled } from './Base'
import ConfirmComponent from './Confirm'

function EmailComponent(props) {
    const { survey_question_no, classes, refComment, isConfirm, originAnswered, onChange } = props

    const [ value, onChangeValue ] = useValue(originAnswered, onChange)

    return (
        <>
        <Input
            value={value}
            inputProps={{inputMode: 'email'}} 
            onChange={(e) => onChangeValue((e.target.value))}
            inputRef={ref => refComment.current[survey_question_no] = ref}
            startAdornment={<InputAdornment position="start" className={classes.icon}><MailOutlineIcon/></InputAdornment>}
        />
        {
            isConfirm && (
                <ConfirmComponent survey_question_no={survey_question_no} value={value} isEqualAnswer={getIsDisabled(value, originAnswered)}/>
            )
        }
        </>
    )
}


export default memo(EmailComponent, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.isConfirm, next.isConfirm) && 
            isEqual(prev.originAnswered, next.originAnswered) && 
            isEqual(prev.classes, next.classes) && 
            isEqual(prev.refComment, next.refComment) &&
            isEqual(prev.onChange, next.onChange)
})