class ChannelService {
    constructor() {
		// 응답 도메인에서는 채널을 쓸일이 일절없다 호출도 하지말자 시간뺏긴다...
		if (window.location.origin !== process.env.REACT_APP_RESPOND) {
			this.loadScript();
		}
    }
  
    loadScript() {
      	var w = window;
      	if (w.ChannelIO) {
        	return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
      	}
      	var ch = function() {
        	ch.c(arguments);
      	};
      	ch.q = [];
      	ch.c = function(args) {
        	ch.q.push(args);
      	};
      	w.ChannelIO = ch;
      	function l() {
        	if (w.ChannelIOInitialized) {
          	return;
        	}
			w.ChannelIOInitialized = true;
			var s = document.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
			s.charset = 'UTF-8';
			var x = document.getElementsByTagName('script')[0];
			x.parentNode.insertBefore(s, x);
      	}
		if (document.readyState === 'complete') {
			l();
		} else if (window.attachEvent) {
			window.attachEvent('onload', l);
		} else {
			window.addEventListener('DOMContentLoaded', l, false);
			window.addEventListener('load', l, false);
		}
    }
    
    init(settings) {
		return new Promise((resolve, reject) => {
			window.ChannelIO('boot', settings, function onBoot(error, user) {
				if (error) resolve(false)
				else resolve(true)
			})
		})
    }

    async boot(settings) {
		const res = await this.init(settings)
		return res ? window.ChannelIO : null
    }
  
    shutdown() {
      	window.ChannelIO('shutdown');
    }

    get _self() {
      	return window.ChannelIO
    }
}
  
  export default new ChannelService()