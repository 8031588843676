/**
 * 파일과 이미지에 따라 가져오는 컴포넌트가 다르다
 */

import { useContext } from 'react'
import { Levels } from 'ts-policy/index'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { Method } from 'gql/survey_mbox'
import { getMimePicture } from 'utils/survey'
import FileAttach from 'styled/Custom/FileAttach'
import Picture from 'styled/Survey/Picture'


interface Props {
    method?: Method;
    isEditList?: boolean;
    img_src: string;
    img_position?: string;
    img_poster?: string;
    img_name: string;
    thanks?: boolean;
}


function FileAndPictureComponent(props: Props) {
    const { method, isEditList=false, img_src, img_position, img_poster, img_name, thanks } = props

    const { mode } = useContext<any>(AccessMethodContext)

    const { levels, mbox } = useContext<any>(PropsContext)
    const { survey_no } = mbox

    const mime = getMimePicture(img_src)

    return (
        <>
        {
            (img_position === 'stack' || !img_position) ? (
                mime === 'file' ? (
                    <FileAttach survey_no={survey_no} name={img_name} mode={mode} src={img_src} action={levels === Levels.Level0 ? false : true} live={Boolean(levels)}/> 
                ) : (
                    <Picture method={method} isEditList={isEditList} mime={mime} img_src={img_src} thanks={thanks} img_position={img_position} img_poster={img_poster}/>
                )
            ) : null
        }
        </>
    )
}

export default FileAndPictureComponent