import { useSelector, useDispatch } from 'react-redux'
import { HIDE } from 'reducer/alert'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import oc from 'open-color-js'
import ReactHtmlParser from 'react-html-parser'

const useContentStyles = makeStyles(theme => ({
    root: {
       fontSize: '14px',
       color: oc.gray9,
    },
    title: {
        fontWeight: 'bold',
        color: 'black'
    }
}))

export default () => {
    const classes = useContentStyles()
    const alert = useSelector(state => state.alert)
    const dispatch = useDispatch()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const onClose = () => {
        dispatch({type: HIDE})
    }

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={alert.show}
                onClose={onClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" className={classes.title}>{alert.title}</DialogTitle>
                <DialogContent>
                <DialogContentText className={classes.root}>
                    {ReactHtmlParser(alert.message.replace(/\n/g, "<br />"))}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={onClose} color="primary" style={{fontWeight: 700}}>
                    확인
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
