import { useEffect, useReducer, useState, useContext, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { SHOW as ANSWERERRORPAGESHOW } from 'reducer/answerErrorPage'
import { HIDE as BACKDROPHIDE, SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { SHOW as CAUTIONSHOW } from 'reducer/survey/live/caution'
import { scroller, Element } from 'react-scroll'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { useIntl } from 'react-intl'
import { saveComment, checkRequired, getDiffSeconds } from 'utils/survey'
import { getStartsTokenname, parseGraphqlError } from 'utils'
import { withSurveySetReplys, withSurveySetState, withPrevState } from 'hoc'
import moment from 'moment'
import { GET_SURVEY_ANALYSIS_QUESTION_MODULES, CREATE_SURVEY_ANALYSIS_ONE, GET_SURVEY_ANALYSIS_QUESTION_MODULES_THEN_SUBMIT, GET_SURVEY_ANALYSIS_QUESTION_MODULES_THEN_PREV } from 'gql/survey_analysis_modules'
import { UPDATE_SURVEY_ANALYSIS_START, UPDATE_SURVEY_ANALYSIS_STATUS_ONE } from 'gql/survey_analysis_users'
import { GET_SURVEY_ANALYSIS_COMMENT } from 'gql/survey_analysis_comment'
import { GET_SURVEY_QUESTION_JUMP_NEXT } from 'gql/survey_question'
import { CREATE_SURVEY_ANALYSIS_ENDING } from 'gql/survey_ending'
import { Method } from 'gql/jump'
import { getCurrentIndex } from 'hoc/Survey/FetchQuestionsOne'
import useVerifySaveReply from 'hooks/useVerifySaveReply'
import { VerifyFileUpload } from 'component/Survey/Live/PageMulti'
import { memoryReply } from './Container'
import { PageContext, FileUploadLoadingBox } from './Multi'
import { SurveyLiveOneComponent, SurveyLiveSlideComponent, SurveyLivePageOneComponent } from 'component'
import SurveyLiveRestartComponent from 'component/Survey/Live/Restart'
import FreeLogoComponent from 'component/Survey/Live/FreeLogo'
import StartContainer from './Start'
import EndingaContainer from './Endinga'


// 초기값
const initialState = {
    slide: {
        direction: 'left',
        do: true
    }
}

const osaveComment = new saveComment()

const ocheckRequired = new checkRequired()

// 페이지 다음으로 넘길수있는 변수
export let pageNextDo = true
let pagePrevDo = true

let timer = null

const justModuleTypes = ['_01', '_05', '_07', '_12', '_14', '_16', '_21']

const handleReducer = (state, action) => {
    switch (action.type) {
        case 'slide': { // slide
            return { ...state, slide: action.slide }
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}

// 마지막 질문 체크
function isLastQuestion(questions, currentIndex) {
    const lastIndex = questions.length - 1
    return lastIndex === currentIndex
}

// 필수응답 체크
export async function checkRequireAnswered(props) {
    const { questions, replys, comments, passComment=false, confirm09Rows=[] } = props

    // 필수응답 체크 안된값이 있다면 응답하라고 안내한다
    ocheckRequired.init({ replys, questions, replyComments: comments, confirm09Rows }) 
    ocheckRequired.passComment = passComment
    return await ocheckRequired.do()
}

// 최초 열릴때 상태값
let mount = false

function OneContainer(props) {
    const { 
        survey_no, 
        levels,
        mbox, 
        option, 
        start,
        required=true, 
        token, 
        usePrevState,
        propsSetReplys, 
        propsSetState, 
        currentIndex, 
        questions, 
        rowsJump, 
        hideQuestionNos,
        moveHideQuestionNos,
        target, 
        surveyAnalysisUserRow,
        onChangePage,
        getFilterJumpQuestions
    } = props

    const accessMethod = useContext(AccessMethodContext)
    const { scrollerObj } = useContext(PropsContext)

    const { start: userStart } = surveyAnalysisUserRow

    const { 
        format, 
        replys, 
        refComments01,
        refComments02,
        refComments09,
        refComments10,
        refComments12,
        refComments13,
        refComments17,
        refComments18,
        refComments20,
        refCommentsWith,
        handleChangeReplys
    } = propsSetReplys
    const { replyComments, ending, setReplyComments, setEnding } = propsSetState

    const startsTokenname = getStartsTokenname(survey_no)

    // 현재 설문번호
    const survey_question_no = questions[currentIndex] ? questions[currentIndex]._question.survey_question_no : 0

    // 현재 모둘 타입
    const module_type = questions[currentIndex] ? questions[currentIndex]._question.module_type : ''

    // 파일 업로드 로딩 div id
    const fileuploadLoadingId = `fileupload-one-id-${survey_no}`

    const prevSurveyQuestionNo = usePrevState(survey_question_no)
    const prevCurrentIndex = usePrevState(currentIndex)

    // 완료글 저장하고 updateStatus까지 완료 되면 완료글을 보여준다 (redirect url할때 혹여나 updateStatus가 안되었는데 이동되면 상태값이 ing로 되니까..)
    const [ endingView, setEndingView ] = useState(false)

    const [ states, dispatchStates ] = useReducer(handleReducer, initialState)

    const prevReplys = usePrevState(replys)

    const [ onVerifySaveReply ] = useVerifySaveReply('one')

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    // 답변 저장후 리플라이 셋팅
    const handleChange = useCallback(() => {
        // 재런더링 방지를 위해 memoryReply rows에 담는다
        handleChangeReplys(memoryReply.rows.map(c => {
            const isJust = c.isJust ? { isJust: undefined } : {}
            return {
                ...c,
                ...isJust
            }
        }))
        
        setTimeout(() => {            
            scroller.scrollTo(scrollerObj.targetTop, {
                ...scrollerObj.scrollTo,
                containerId: scrollerObj.id
            })
        }, 100)
    }, [handleChangeReplys, scrollerObj])

    // 데이터를 형식에 맞게 포멧한다
    const handleChangeFormatData = useCallback((data) => {
        return  data.map(c => {
            const { survey_question_no, survey_module_no, survey_module_answer_no,  __typename } = c

            switch(__typename) {
                case 'Module_00':
                    return format._00({ survey_question_no })
                case 'Module_01':
                    return format._01({ survey_question_no, survey_module_no, comment: c.comment })
                case 'Module_02':
                    return format._02({ survey_question_no, survey_module_no, comment: c.comment })
                case 'Module_03':
                    return format._03({ survey_question_no, survey_module_no, survey_module_answer_no })
                case 'Module_04':
                    return format._04({ survey_question_no, survey_module_no, survey_module_answer_no })
                case 'Module_05':
                    return format._05({ survey_question_no, survey_module_no })
                case 'Module_06':
                    return format._06({ survey_question_no, survey_module_no, survey_module_answer_no })
                case 'Module_07':
                    return format._07({ survey_question_no, survey_module_no })
                case 'Module_08':
                    return format._08({ survey_question_no, survey_module_no, survey_module_answer_no })
                case 'Module_09':
                    return format._09({ survey_question_no, answer: c.answer, national_flag: c.national_flag })
                case 'Module_10':
                    return format._10({ survey_question_no, answer: c.answer })
                case 'Module_11':
                    return format._11({ survey_question_no, survey_module_no, survey_module_answer_no })
                case 'Module_12':
                    return format._12({ survey_question_no, survey_module_no, comment: c.comment })
                case 'Module_13':
                    return format._13({ survey_question_no, survey_module_no, comment: c.comment })
                case 'Module_14':
                    return format._14({ survey_question_no, survey_module_no })
                case 'Module_15':
                    return format._15({ survey_question_no, survey_module_no, survey_module_answer_no })
                case 'Module_16':
                    return format._16({ survey_question_no, survey_module_no })
                case 'Module_17':
                    return format._17({ survey_question_no, survey_module_no, survey_module_answer_no, comment: c.comment })
                case 'Module_18':
                    return format._18({ survey_question_no, survey_module_no, survey_module_answer_no, comment: c.comment })
                case 'Module_19':
                    return format._19({ survey_question_no, src: c.src, filename: c.filename, filetype: c.filetype, filesize: c.filesize })
                case 'Module_20':
                    return format._20({ survey_question_no, zipcode: c.zipcode, address1: c.address1, address2: c.address2 })
                case 'Module_21':
                    return format._21({ survey_question_no, survey_module_no })
                case 'Module_22':
                    return format._22({ survey_question_no, survey_module_no, survey_module_answer_no })
                default:
                    return null
            }
        })
    }, [format])

    // 필수 응답값 잘 들어 왔는지 체크
    const verifyData = async(data) => {
        // 필수항목 체크가 아니면 무조건 true를 리턴
        if (!required) return true

        // 이전문항값 저장되었는지 체크
        const exceptQuestionNos = [...hideQuestionNos, ...moveHideQuestionNos]
        const prevQuestions = questions.filter((question, i) => {
            if (i>=currentIndex) return false
            if (exceptQuestionNos.includes(question._question.survey_question_no)) return false

            return true
        })

        const requiredValue = await checkRequireAnswered({
            questions: prevQuestions, 
            replys: data, 
            comments: [], 
            passComment: true,
            confirm09Rows: []
        }) 

        if (requiredValue[0] === false) {   
            // 저장이 안되었다면 해당문항으로 강제로 보낸다
            onChangePage(getCurrentIndex(questions, requiredValue[1]))
            pageNextDo = true
            return false
        }

        return true
    }

    // 설문 완료글 응답값 저장
    const [ createSurveyAnalysisEnding ] = useMutation(CREATE_SURVEY_ANALYSIS_ENDING, {
        onCompleted: async (data) => {
            setEnding(data.createSurveyAnalysisEnding)
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        }
    })
    
    const [ getDataAnlysis ] = useLazyQuery(GET_SURVEY_ANALYSIS_QUESTION_MODULES, {
        onCompleted: async(data) => {
            // replys 기본값 셋팅
            const replys = handleChangeFormatData(data.surveyAnalysisQuestionModules)
            
            handleChangeReplys(replys)

            // 재런더링 방지를 위해 memoryReply rows에 담는다
            memoryReply.rows = replys
            
            setTimeout(() => {            
                scroller.scrollTo(scrollerObj.targetTop, {
                    ...scrollerObj.scrollTo,
                    containerId: scrollerObj.id
                })
            }, 100)
            
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        },
        fetchPolicy: 'network-only'
    })

    // 제출하기전에 데이터 검증
    const [ getDataAnlysisThenSubmit ] = useLazyQuery(GET_SURVEY_ANALYSIS_QUESTION_MODULES_THEN_SUBMIT, {
        onCompleted: async(data) => {
            // 필수 응답값 다 잘 들어가있는 체크
            const verifyReplys = handleChangeFormatData(data.surveyAnalysisQuestionModulesThenSubmit.rows)

            // 데이터에 문제가 있으면 완료로 안넘긴다.
            const verify = await verifyData(verifyReplys)
            if (!verify) return

            // 완료글 저장으로 넘긴다.
            setTimeout(() => {
                createSurveyAnalysisEnding({ variables: {
                    survey_no,
                    token,
                    survey_ending_no: data.surveyAnalysisQuestionModulesThenSubmit.survey_ending_no,
                    mode: accessMethod.mode
                } })
            }, 350)
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        },
        fetchPolicy: 'network-only'
    })

    // 이전버튼 누르면 DB에 저장된값을 가져온다
    const [ getDataAnlysisThenPrev ] = useLazyQuery(GET_SURVEY_ANALYSIS_QUESTION_MODULES_THEN_PREV, {
        onCompleted: async(data) => {
            // DB에 저장된값
            const prevReply = handleChangeFormatData(data.surveyAnalysisQuestionModulesThenPrev.rows)

            // prev_survey_quetion_no값을 제외한다.
            const exceptReplys = memoryReply.rows.filter(c => c.survey_question_no !== data.surveyAnalysisQuestionModulesThenPrev.survey_question_no)

            const newReplys = [...exceptReplys, ...prevReply]

            // 재런더링 방지를 위해 memoryReply rows에 담는다
            memoryReply.rows = newReplys
            handleChange()
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        },
        fetchPolicy: 'network-only'
    })

    const [ getComment ] = useLazyQuery(GET_SURVEY_ANALYSIS_COMMENT, {
        onCompleted: async (data) => {
            // replys 기본값 셋팅
            const replyComments = data.surveyAnalysisComment.map(c => ({ survey_question_no: c.survey_question_no, comment: c.comment }))

            // 다음 누를시 값이 없어지는게 보여서 좀 늦게 state로 변경한다
            setTimeout(() => {
                setReplyComments(replyComments)
            }, 100)
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        },
        fetchPolicy: 'network-only'
    })

    const [ createSurveyAnalysisOne ] = useMutation(CREATE_SURVEY_ANALYSIS_ONE, {
        onCompleted: async(data) => {

            // 값이 제대로 저장이 되지 않았다면, 기존 값을 삭제한다
            if (!onVerifySaveReply(memoryReply.currentData, memoryReply.currentWithComments, data.createSurveyAnalysisOne)) {

                const currentQuestionNo = memoryReply.currentData.length > 0 ? memoryReply.currentData[0].survey_question_no : 0
                
                memoryReply.rows = memoryReply.rows.filter(c => c.survey_question_no !== currentQuestionNo)
                handleChange()
                                 
                setTimeout(() => {
                    memoryReply.currentWithComments =  memoryReply.currentWithComments.filter(c => c.survey_question_no !== currentQuestionNo)
                    setReplyComments(memoryReply.currentWithComments)
                }, 100)
               
                onChangePage(currentIndex - 1)
         
                pageNextDo = true

                return
            }

            // 데이터 제대로 들어왓는지 검증
            const verify = await verifyData(memoryReply.rows)
            if (!verify) return

            // 점프로직이 아닐때
            if (rowsJump.length === 0) {
                 // 점프설정값이 있어도, 완료설정값이 있을수도있구, 그게 아니더라도 마지막페이지 마지막 문항 완료글 응답저장으로 넘긴다.
                if (isLastQuestion(questions, currentIndex)) handleEndingSave()
                else {
                    await handleSlideNext()
                    onChangePage(currentIndex + 1)
                }
            } else {
                getQuestionJumpNext({ variables: {
                    survey_no,
                    token,
                    survey_question_no,
                    method: target.method, 
                    id: target.id, 
                    mode: accessMethod.mode
                }})
            }
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'No User') {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.noUser'})})
            } else {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
            }
        }
    })

    const [ updateSurveyAnalysisStart ] = useMutation(UPDATE_SURVEY_ANALYSIS_START, {
        onError: (e) => {
            if (parseGraphqlError(e) === 'No User') {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.noUser'})})
            } else {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
            }
        }
    })

    const [ updateSurveyAnalysisStatusOne ] = useMutation(UPDATE_SURVEY_ANALYSIS_STATUS_ONE, {
        onCompleted: async (data) => {
            sessionStorage.setItem(startsTokenname, moment())
            //완료된 상태라면 setEndingView를 true
            if (ending) setEndingView(true)
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'No User') {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.noUser'})})
            } else {
                dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
            }
        }
    })

    const [ getQuestionJumpNext ] = useLazyQuery(GET_SURVEY_QUESTION_JUMP_NEXT, {
        onCompleted: async (c) => {
            // target.id가 없으면 다음 페이지 번호로 가져온다.
            const moveQuestion = c.surveyQuestionJumpNext
            
            // 타겟이 완료일때는 뒤로가기도 없고 무조건 첫번째 우선순위
            if (target.method === Method.End) {

                // 타겟이 지정되었다면 해당타겟으로 저장
                handleEndingSave(moveQuestion)

            }
            else if (hideQuestionNos.includes(survey_question_no)) { // 숨김처리된 문항일 경우 로직 무시할고 다음 문항으로 넘긴다
                // 해당 문항 값은 없앤다
                memoryReply.rows = memoryReply.rows.filter(c => survey_question_no !== c.survey_question_no)
                
                // 점프설정값이 있어도, 완료설정값이 있을수도있구, 그게 아니더라도 마지막페이지 마지막 문항 완료글 응답저장으로 넘긴다.
                if (isLastQuestion(questions, currentIndex)) handleEndingSave()
                else {
                    await handleSlideNext()
                    onChangePage(currentIndex + 1)
                }
            } 
            else if (target.method === Method.Move && target.id === 1) {

                // method move이고 id가 1이면 시작글로 보내는거다.
                await handleSlideNext()
                onChangePage(-1)

            } else if (isLastQuestion(questions, currentIndex) && !moveQuestion) {

                // 현재 문항이 마지막 문항이면서, moveQuestion가 없다는건 이동하는 타겟이 없는것이므로 종료한다.
                // 점프설정값이 있어도, 완료설정값이 있을수도있구, 그게 아니더라도 마지막페이지 마지막 문항 완료글 응답저장으로 넘긴다.
                handleEndingSave()

            } else {
                const moveIndex = getCurrentIndex(questions, moveQuestion)
            
                if (target.method === Method.Move) {
                    // 현재 문항 보다 큰  값으로 이동할 경우 중간 값 삭제 (DB에서는 이미 삭제함)
                    if (moveIndex > currentIndex) {
                        const deleteQuestionNo = questions.filter((c, i) => i > currentIndex && i < moveIndex).map(c => c._question.survey_question_no)
                        const newReplys = memoryReply.rows.filter(c => !deleteQuestionNo.includes(c.survey_question_no))
                        memoryReply.rows = newReplys
                    }
                }

                await handleSlideNext()
                onChangePage(moveIndex)
            }
        },
        onError: (e) => {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Live.Container.createSurveyAnalysis.error'})})
        },
        fetchPolicy: 'network-only'
    })

    // 완료글 응답 저장
    const handleEndingSave = useCallback(async(survey_ending_no) => {
        dispatch({ type: BACKDROPSHOW })

        getDataAnlysisThenSubmit({
            variables: {
                survey_no,
                token,
                survey_ending_no,
                mode: accessMethod.mode
            }
        })
    
    }, [dispatch, survey_no, token, accessMethod.mode, getDataAnlysisThenSubmit])

    const handleSlideNext = useCallback(() => {
        return new Promise(async(resolve, reject) => {
            await dispatchStates({type: 'slide', slide: {
                direction: 'right',
                do: false
            }})

            setTimeout(resolve, 100)

            setTimeout(() => {
                pageNextDo = true
            }, 1000)
            
        })
    }, [])

    const handleSlidePrev = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatchStates({type: 'slide', slide: {
                direction: 'left',
                do: false
            }})
            
            setTimeout(resolve, 100)
        })
    }, [])

    const handleCallbackSlide = useCallback((direction) => {
        dispatchStates({
            type: 'slide', 
            slide: { 
                direction: direction === 'right' ? 'left' : 'right', 
                do: true
            }
        })
    }, [])
    
    
    const handleChangeUserStart = useCallback(() => {
        updateSurveyAnalysisStart({variables: { 
            survey_no,
            token,
            mode: accessMethod.mode
        }})
    }, [survey_no, token, accessMethod, updateSurveyAnalysisStart])


    async function handleChangeStart() {
        await handleSlideNext()
        await onChangePage(currentIndex + 1)
        
        handleChangeUserStart()
    }

    const handleNext = useCallback(async() => {
        try {
            if (!pageNextDo) return

            pageNextDo = false

            // 점프로직이 생기면서 문항이 보이고 안보이는 경우가 생겻다 점프있는 보기 체크 안한상태에서 체크한값이 사용자가 점프있느 보기로 체크를 변경한후 저장할때
            // 점프로인해 안보이는값 그전에 체크된값은 저장하면안된다
            const question =  questions[currentIndex]
        
            // ref 값을 state에 저장한다
            let copy = [...replys.filter(c => survey_question_no === c.survey_question_no)]

            const refComments = {
                refComments01,
                refComments02,
                refComments09,
                refComments10,
                refComments12,
                refComments13,
                refComments17,
                refComments18,
                refComments20
            }

            // 보기 코멘트 값 저장
            const data = osaveComment.saveAnswerComment({questions: [question], rows: copy, refComments})

            const comments = osaveComment.saveQuestionComment({questions: [question], refCommentsWith})
            
            const requiredValue = await checkRequireAnswered({
                questions: [question], 
                replys: data, 
                comments, 
                passComment: false,
                confirm09Rows: []
            }) 
            
            if (required) {
          
                if (requiredValue[0] === false) {
                
                    await dispatch({ type: CAUTIONSHOW, survey_question_no: requiredValue[1], code: requiredValue[2], message: requiredValue[3] })

                    scroller.scrollTo(scrollerObj.target, {
                        ...scrollerObj.scrollTo,
                        containerId: scrollerObj.id
                    })

                    pageNextDo = true
                    
                    return
                }
            }

            const verifyFileUpload = new VerifyFileUpload({ mode: accessMethod.mode, idd: mbox.idd, survey_no, fileuploadLoadingId, dispatch, f, copy: data, initData: [] })
            const customCopy = await verifyFileUpload.do([survey_question_no])

            if (!customCopy) {
                window.alert(f({id: 'component.Survey.Live.PageMulti.VerifyFileUpload.message.error'}))
                pageNextDo = true
                return
            }

            dispatch({ type: BACKDROPHIDE })
            
            const input = {
                token,
                survey_no,
                mode: accessMethod.mode,
                method: accessMethod.method,
                data: customCopy,
                comments,
                survey_question_no,
                required
            }
            
            // 전체 문항값에 현재 문항값을 추가한다 (선택형 보기 코멘트 추가 및 파일업로드 값 추가)
            memoryReply.rows = [...replys.filter(c => c.survey_question_no !== survey_question_no), ...customCopy]

            // 현재 문항값 저장
            memoryReply.currentData = customCopy
            memoryReply.currentWithComments = comments

            createSurveyAnalysisOne({variables: { input }})
        } catch(e) {
            throw e
        } finally {
           // setIsQuestions(false)
        }
    }, [
        currentIndex, 
        survey_no,
        mbox.idd,
        fileuploadLoadingId,
        token,
        scrollerObj.id,
        scrollerObj.scrollTo,
        scrollerObj.target,
        replys, 
        survey_question_no, 
        required,
        questions, 
        accessMethod.mode, 
        accessMethod.method, 
        refComments01,
        refComments02,
        refComments09,
        refComments10,
        refComments12,
        refComments13,
        refComments17,
        refComments18,
        refComments20,
        refCommentsWith,
        createSurveyAnalysisOne,
        dispatch,
        f
    ])
     
    const handlePrev = useCallback(async() => {
        if (!pagePrevDo) return

        pagePrevDo = false

        try {
            await handleSlidePrev()
            if (rowsJump.length === 0) {
                onChangePage(currentIndex - 1)
            } else {
                if (target.pmethod === Method.Move && target.pid) {
                    onChangePage(getCurrentIndex(questions, target.pid))
                } else {
                    onChangePage(currentIndex - 1)
                }
            }
        } catch(e) {

        } finally {
            setTimeout(() => {
                pagePrevDo = true
            }, 100)
        }
    }, [currentIndex, questions, target.pmethod, target.pid, rowsJump, handleSlidePrev, onChangePage])

    // 숨김처리로 문항이 안보일때 기존 데이터값지운다.
    const handleNextNull = useCallback(async() => {
        // 점프로직 실행
        await getFilterJumpQuestions(replys.filter(reply => reply.survey_question_no !== survey_question_no))
        
        const input = {
            token,
            survey_no,
            mode: accessMethod.mode,
            method: accessMethod.method,
            data: [],
            comments: [],
            survey_question_no,
            required
        }
         
        memoryReply.currentData = []
        memoryReply.currentWithComments = []

        createSurveyAnalysisOne({variables: { input }})
    }, [replys, token, survey_no, accessMethod.mode, accessMethod.method, survey_question_no, required, createSurveyAnalysisOne, getFilterJumpQuestions])

    // 숨김처리된 문항인지 체크
    const isHideQuestion = hideQuestionNos.includes(survey_question_no)

    useEffect(() => {
        const variables = {
            survey_no,
            token,
            survey_question_no,
            mode: accessMethod.mode
        }

        // 맨 처음 마운트 시 에만 데이터를 가져온다.
        const init = async () => {
            await Promise.all([
                getDataAnlysis({ variables }),
                getComment({ variables })
            ])

            if (!isHideQuestion) dispatch({ type: BACKDROPHIDE })

            mount = true
        }

        // 이전 버튼 클릭시
        const prev = async () => {
            await Promise.all([
                getDataAnlysisThenPrev({ 
                    variables: {
                        ...variables,
                        survey_question_no: prevSurveyQuestionNo
                    }
                }),
                getComment({ variables })
            ])

            if (!isHideQuestion) dispatch({ type: BACKDROPHIDE })

            mount = true
        }

        // 다음버튼 클릭시
        const next = () => {
            handleChange()
            getComment({ variables })
            if (!isHideQuestion) dispatch({ type: BACKDROPHIDE })
        }

        if (!mount) init() // 최초 mount시 데이터 가져오기
        else if (currentIndex < prevCurrentIndex) prev() // 이전으로 가기도 데이터를 가져온다 (이전으로 가는 행위는 많이 안하니까)
        else if (currentIndex >= 0) next()
    }, [
        survey_no, 
        token, 
        survey_question_no, 
        prevSurveyQuestionNo,
        accessMethod.mode, 
        currentIndex, 
        prevCurrentIndex, 
        handleChange, 
        getDataAnlysis, 
        getComment, 
        getDataAnlysisThenPrev, 
        isHideQuestion, 
        dispatch
    ])

    // 문항 처리 상태값 저장한다
    useEffect(() => {
        /*
        ** 숨김처리가 아니고, 다음으로 이동한 새문항이면 업데이트. 
        ** 완료글이면 무조건 업데이트
        */
        if ((!isHideQuestion && survey_question_no > 0 && prevSurveyQuestionNo !== survey_question_no) || ending) {
            updateSurveyAnalysisStatusOne({
                variables: {
                    survey_no,
                    survey_question_no,
                    step: ending ? 'end' : 'ing',
                    token,
                    einterval: getDiffSeconds({ now: sessionStorage.getItem(startsTokenname), then: moment() }),
                    mode: accessMethod.mode
                }
            })
        }    
    }, [accessMethod.mode, survey_no, survey_question_no, isHideQuestion,  prevSurveyQuestionNo, ending, startsTokenname, updateSurveyAnalysisStatusOne, token])

    // 숨김처리로 안보이는 데이터는 기존데이터값 있으면지우고 자동으로 다음으로 넘긴다.
    useEffect(() => {
        if (isHideQuestion) {
            if (currentIndex > prevCurrentIndex) handleNextNull()
            else if (currentIndex < prevCurrentIndex) {
                pagePrevDo = true
                handlePrev()
            }



            dispatch({type: BACKDROPSHOW, msg: f({id: 'container.Survey.Live.One.waiting'}) })
        }
    }, [currentIndex, prevCurrentIndex, isHideQuestion, handleNextNull, handlePrev, dispatch, f])


    // 다음 버튼없이 바로가기
    let isJust = false

    if (replys.length > 0) {
        if (survey_question_no) {
            if (justModuleTypes.includes(module_type)) {
                // isJust는 단일선택으로만 하는 문항만 지원하기때문에 무조건 한개의 값이다. find로 값이 있는지 판단하면된다.
                const isAnswer = replys.find(c => c.survey_question_no === survey_question_no)
                if (isAnswer) {
                    isJust = Boolean(isAnswer.isJust)
                }
            }
        }
    }

    useEffect(() => {
        async function Do() {
            if (replys !== prevReplys) {
                await getFilterJumpQuestions(replys)
                if (isJust) {
                    clearTimeout(timer)
                    timer = setTimeout(() => {
                        handleNext()
                    }, 350)
                }
            }
        }
        Do()
    }, [replys, prevReplys, getFilterJumpQuestions, isJust, handleNext])

    // 현재글이 질문인지 
    const isQuestion = typeof questions[currentIndex] === 'object' && !ending    

    // 시작글을 disabled 처리했다면 바로 1번 문항으로 넘긴다.
    useEffect(() => {
        if (!ending && !isQuestion && start.used === false) {
            onChangePage(currentIndex + 1)
        }
    }, [ending, isQuestion, start.used, currentIndex, onChangePage])

    useEffect(() => {
        setTimeout(() => dispatch({type: BACKDROPHIDE}), 500)
    }, [dispatch])


    // 미리보기에서는 언마운트 처리를 해줘야 저장된 응답값이 초기화 된다.
    useEffect(() => {
        return () => {
            mount = false
        } 
    }, [])

    const isPage = (ending || (!isQuestion && start.used) ? false : true)

    return (
        <Element id={scrollerObj.id} style={scrollerObj.style}>
            <PropsContext.Provider value={{
                survey_no,
                levels,
                token,
                mbox,
                fileuploadLoadingId,
                contentID: `One-Content-${survey_no}`,
                option,
                questions,
                currentIndex, 
                startUsed: start.used,
                userStart,
                replys, 
                isJust,
                format,
                replyComments, 
                refComments01,
                refComments02,
                refComments09,
                refComments10,
                refComments12,
                refComments13,
                refComments17,
                refComments18,
                refComments20,
                refCommentsWith,
                onChangeReplys: handleChangeReplys,
                design: props.design, 
                onNext: handleNext,
                onPrev: handlePrev,
                scrollerObj
            }}>
         
            <SurveyLiveRestartComponent isView={Boolean(ending || (isQuestion && !isHideQuestion))}/>
            <SurveyLiveSlideComponent slide={states.slide} onCallbackSlide={handleCallbackSlide}>
                <div style={{height: '100%'}}>
                {
                    (ending && endingView) ? (
                        <EndingaContainer survey_no={survey_no} row={ending} mode={accessMethod.mode}/> 
                    ) : (!isQuestion && start.used) ? (
                        <StartContainer survey_no={survey_no} onChange={handleChangeStart}/> 
                    ) : 
                    props.page === 0 ? null : 
                    (isQuestion && !isHideQuestion) ? (
                        <PageContext.Provider value={{target}}>
                            <SurveyLiveOneComponent/> 
                        </PageContext.Provider>
                    ) : null
                }
                </div>
            </SurveyLiveSlideComponent>
            {
                isQuestion && (
                    <PageContext.Provider value={{target}}>
                        <SurveyLivePageOneComponent/>
                    </PageContext.Provider>
               )
            }
            <FreeLogoComponent mode={accessMethod.mode} levels={levels} isPage={isPage}/>
            </PropsContext.Provider>
            <FileUploadLoadingBox id={fileuploadLoadingId} />
        </Element>

    )
}

export default withPrevState(withSurveySetState(withSurveySetReplys(OneContainer)))