import { memo, MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { getAnimationClassName } from '../01/Item'
import { AgreeBox } from 'styled/Survey/Agree'
import Answer from 'styled/Custom/Answer'
import RatingIcon from 'styled/Survey/RatingIcon'
import IconComponent from 'styled/Custom/Icon'

interface Props {
    selected: boolean;
    isJust: boolean;
    answer: string;
    icon_type: string;
    onChange: (e: MouseEvent<HTMLElement>) => void
}

function ItemComponent(props: Props) {

    const { selected, isJust, answer, icon_type, onChange } = props

    const { formatMessage: f } = useIntl()

    return (
        <AgreeBox 
            selected={selected} 
            className={getAnimationClassName(isJust)} 
            onClick={(e: MouseEvent<HTMLElement>) => {
                if (!isJust) onChange(e)
            }}>
            <div>
                <IconComponent>
                    <RatingIcon selected={selected} icon_type={icon_type}/>
                </IconComponent>
            </div>
            <div>
                <Answer>{answer || f({id: 'component.Survey.Modules.16.answer'})}</Answer>
            </div>
        </AgreeBox>
    )
}

export default memo(ItemComponent)