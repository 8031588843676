import { useContext, useCallback, useState, useEffect } from 'react'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import ItemComponent from './Item'
import CommentComponent from '../Comment'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import { MyBox } from '../07/Component'

export default (props) => {
    const { mbox, format } = useContext(PropsContext)
    const { 
        survey_question_no, 
        columns, 
        answers, 
        reply,
        is_comment, 
        is_comment_placeholder,
        reverse,
        zero_number,
        replyComment
    } = props

    const { method } = mbox

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState(reply)

    const handleChange = useCallback(async(survey_module_no, survey_module_answer_no) => {

        // 이미 체크된 값이 있는지 체크 (선택한값)
        const otherChecked = newReply.find(c => {
            return (
                c.survey_module_no === survey_module_no && 
                survey_module_answer_no && c.survey_module_answer_no !== survey_module_answer_no
            )
        })  ? true : false

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c => {
            return (
                c.survey_module_no === survey_module_no && 
                survey_module_answer_no && c.survey_module_answer_no === survey_module_answer_no
            )
        }) ? true : false


        const reply = format._08({ survey_question_no, survey_module_no, survey_module_answer_no })

        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        const newReplys =   selfChecked  ? [...memoryReply.rows.filter(c => !(c.survey_question_no === survey_question_no && c.survey_module_no === survey_module_no && c.survey_module_answer_no === survey_module_answer_no)) ] :
                            otherChecked ? [ ...memoryReply.rows.filter(c => !(c.survey_question_no === survey_question_no && c.survey_module_no === survey_module_no && c.survey_module_answer_no !== survey_module_answer_no)), reply ]                     
                                         : [ ...memoryReply.rows, reply ] 

        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [ survey_question_no, newReply, format, onChangeReplys ])


    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])


    return (
        <MyBox method={method}>
            <ItemComponent reply={newReply} columns={columns} zero_number={zero_number} reverse={reverse} answers={answers} onChange={handleChange}/>
            <CommentComponent 
                survey_question_no={survey_question_no} 
                is_comment={is_comment}
                is_comment_placeholder={is_comment_placeholder}
                comment={replyComment}
                disabled={newReply.length > 0 ? false : true}
                marginTop="15px"
            />
        </MyBox>
    )
}