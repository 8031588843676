import { memo } from 'react'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import ColumnAnswerItemComponent from './ColumnAnswerItem'
import RowsComponent from './Rows'

function ColumnItemComponent(props) {
    const { survey_module_no, column, answers, replys } = props

    const { formatMessage: f } = useIntl()
     
    return (
        <div key={survey_module_no}>
            <RowsComponent str={column || f({id: 'component.Survey.Modules.03.column'})}/>
            {
                answers.map(d => {

                    const selected = replys[survey_module_no] ? replys[survey_module_no][d.survey_module_answer_no] !== undefined : false

                    return (
                        <ColumnAnswerItemComponent 
                            key={`${d.survey_module_answer_no}-${survey_module_no}`}
                            survey_module_answer_no={d.survey_module_answer_no}
                            survey_module_no={survey_module_no}
                            selected={selected}
                        />
                    )
                })
            }
            
        </div>
    ) 
}

export default memo(ColumnItemComponent, (prev, next) => {
    return  isEqual(prev.survey_module_no, next.survey_module_no) &&
            isEqual(prev.column, next.column) && 
            isEqual(prev.answers, next.answers) && 
            isEqual(prev.replys, next.replys)
})