import styled from 'styled-components'

const WrapperStyled = styled.div`
    padding-bottom: 20px;

    & > div:last-child {
        margin-top: 10px;
        text-align: center;
    }
`
const BoxStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    
    & + & {
        margin-top: 10px;
    }

    & > div:first-child {
        width: calc(100% - 45px);
    }

    & > div:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 45px;
        text-align: right;
    }
`

export {
    WrapperStyled,
    BoxStyled
}