import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducer'
import { HIDE } from 'reducer/dialog'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from 'styled/Survey/Live/Dialog'

function DialogComponet() {
    const dispatch = useDispatch()
    
    const state = useSelector((state: RootState) => state.dialog)
    const { show, fullScreen, fullWidth, content } = state

    return (
        <Dialog open={show} fullScreen={fullScreen} fullWidth={fullWidth}>
            <IconButton 
                style={{
                    display: fullScreen ? 'block' : 'none',
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    cursor: 'pointer'
                }}
                onClick={() => dispatch({ type: HIDE })}
            >
                <CloseIcon/>
            </IconButton>
            {content}
        </Dialog>
    )
}

export default DialogComponet