/*
** 뒤로가기 버튼
**/
import { memo } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Levels } from '../../ts-policy/index';

interface Props {
    levels: Levels;
    name: string;
}

const useStyles = makeStyles(theme => ({
    paymentButton: {
        minWidth: 80,
        '& .MuiButton-startIcon': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    }
}))

const UpgradeButton = withStyles({
    root: {
        '@media (max-width: 350px)': {
            display: 'none'
        }
    }
})(Button)

function UpgradeComponent(props: Props) {
    const { levels, name } = props

    const { formatMessage: f } = useIntl()
    const classes = useStyles()

    const history = useHistory()

    const states = useSelector((states: RootState) => states.surveyCreateModuleIsEdit)
    const { is } = states

    const handleMove = () => {
        if (is) {
            if (window.confirm(f({id: 'component.Outer.Back.isEditMessage'}))) history.push('/payment/choice')
        } else history.push('/payment/choice')
    }

    return (
        <UpgradeButton
            className={classes.paymentButton} 
            variant="outlined" 
            startIcon={<PaymentTwoToneIcon/>} 
            size="small"
            color="primary" 
            onClick={handleMove}
        >
            <Typography variant="button">
                {levels === Levels.Level0 ? f({id: `component.Outer.Component.planView`}) : name}
            </Typography>
        </UpgradeButton>
    )
}

export default memo(UpgradeComponent)