import { useEffect, useState, MutableRefObject } from 'react'

function useScrollEvent(div: MutableRefObject<HTMLDivElement>) {
    const [ pos, setPos ] = useState(0)

    useEffect(() => {
        let mounted = true
        if (div) {
            if (div.current) {
                div.current.addEventListener('scroll', () => {
                    if (mounted) {
                        const { scrollLeft } = div.current
                        setPos(scrollLeft)
                    }
                })
            }
        }

        return () => {
            mounted = false
        }
      }, [div])

      return pos
}

export default useScrollEvent