/**
 * 언어변경 버튼
 */
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonGroup, Button } from '@material-ui/core'
import { CHANGE } from 'reducer/lang'
import { postMessage, isCanService } from 'utils'

function geLangButtonColor(lang, button) {
    return lang === button ? 'contained' : 'outlined'
}

const Component = (props) => {
    const { disableElevation=true, color="primary", size="small", callback=() => {}, timeout=0 } = props

    const dispatch = useDispatch()
    const { lang } = useSelector(state => state.lang)

    const handleChangeLang = useCallback(async(_lang) => {
        if (lang === _lang) return

        await setTimeout(async() => {
            // 프레임내에서 호출하는 경우가 있어서..
            await postMessage({
                dispatch: {
                    type: CHANGE,
                    lang: _lang
                }
            })

            dispatch({type: CHANGE, lang: _lang})
        }, timeout)

        callback()
    }, [dispatch, lang, callback, timeout])

    return (
        <ButtonGroup disableElevation={disableElevation} color={color} size={size}>
            <Button variant={geLangButtonColor(lang, 'ko')} onClick={() => handleChangeLang('ko')}>Korean</Button>
            <Button variant={geLangButtonColor(lang, 'jp')} onClick={() => handleChangeLang('jp')}>Japanese</Button>
            {
                (isCanService()) ? (
                    <Button variant={geLangButtonColor(lang, 'en')} onClick={() => handleChangeLang('en')}>English</Button>
                ) : ''
            }
        </ButtonGroup>
    )
}

export default Component