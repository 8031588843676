import { useRef, useEffect, ComponentType, MutableRefObject } from 'react'

type UsePrevState = (a: any) => MutableRefObject<undefined>

export interface UsePrevStateProps {
   usePrevState: UsePrevState
}

//  이전값 저장
function usePrevState(value: any) {
	const ref = useRef()
   
	// 새로운값 저장
   useEffect(() => {
   	ref.current = value
   }, [value])
    
	// 현재 값 넘기고
   return ref.current
}

export default (WrappedComponent: ComponentType<{usePrevState: UsePrevState}>) => (props: any) => {

    return <WrappedComponent usePrevState={usePrevState} {...props}/>
}