import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'
import randomString from 'randomstring'
import { UPDATE_SURVEY_MBOX_METHOD, Method } from 'gql/survey_mbox'
import { GET_JUMP } from 'gql/jump'
import { SHOW as CONFIRMSHOW } from 'reducer/confirm'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE  } from 'reducer/backdrop'

function useChangeMethod(survey_no: number) {
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ complete, setComplete ] = useState<string | null>(null)

    const { data, refetch } = useQuery(GET_JUMP, {
        variables: { survey_no, mode: 'preview'  }
    })

    const [ update ] = useMutation(UPDATE_SURVEY_MBOX_METHOD, {
        onCompleted: async (data) => {
            refetch()
            setComplete(randomString.generate(7))

            if (data.updateSurveyMboxMethod === 'PAGE_RANDOM_NOT_WORKING') {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    message: f({id: 'container.Survey.Create.MethodRoot.updateSurveyMboxMethod.pageRandomNotWorking'}),
                    duration: 3500 })
            }
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleSave = useCallback((method: Method) => {
        dispatch({ type: BACKDROPSHOW })
        update({
            variables: { survey_no, method }
        })
    }, [survey_no, update, dispatch])

    const handleChange = useCallback((method: Method) => {
        if (!data) {
            dispatch({ type: BACKDROPHIDE })
            dispatch({type: ALERTSNACKBARSHOW, message: f({id: 'hooks.useChangeMethod.retry'}), variant: 'info'})
            return
        }

        if (data.jump.length > 0) {
            dispatch({ type: BACKDROPHIDE })
            dispatch({
                type: CONFIRMSHOW,
                content: f({id: 'hooks.useChangeMethod.confirm'}),
                onActions: [ {on: () => handleSave(method), name: f({id: 'hooks.useChangeMethod.button'}), hide: true} ]
            })
            return
        } 

        handleSave(method)
    }, [data, handleSave, f, dispatch])

    return [ complete, handleChange ]
}

export default useChangeMethod