import { useState, useEffect, useCallback } from 'react'

function useJustDebounce() {
    const [ debounce, setDbounce ] = useState(false)

    const handleDebounce = useCallback((just:Boolean) => {
        if (just) setDbounce(true)
    }, [])
    

    useEffect(() => {
        if (debounce) {
            setTimeout(() => {
                setDbounce(false)
            }, 1000)
        }
    }, [debounce])

    return [ debounce, handleDebounce]
    
}

export default useJustDebounce