/**
 * 작업함이 한개도 없을때
 */
import { memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import { SurveyWorkspaceLeftCreatePopComponent } from 'component'

const MyBox = withStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))(Box)

function NothigWorkspaceContainer() {
    const [ open, setOpen ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
        <MyBox>
            <Button variant="contained" color='primary' startIcon={<AddIcon/>} onClick={handleOpen}>
                {f({id: 'container.Survey.Workspace.NothingWorkspace.button.add'})}
            </Button>
        </MyBox>
        <SurveyWorkspaceLeftCreatePopComponent open={open} onClose={handleClose}/>
        </>
    )
}

export default memo(NothigWorkspaceContainer)