import { useContext, useCallback, useEffect, useState } from 'react'
import PropsContext from 'context/props'
import { withPrevState } from 'hoc'
import { isEqual } from 'lodash-es'
import { memoryReply } from 'container/Survey/Live/Container'
import { getFixData } from '../17/Component'
import ItemsComponent from './Items'
import useChangeReplys from '../useChangeReplys'

const Component = props => {
    const { 
        survey_question_no, 
        ranks, 
        answers, 
        reply, 
        is_comment_placeholder,
        mobile_one,
        required,
        required_end,
        usePrevState
    } = props

    const { format, refComments18, design } = useContext(PropsContext)

    const { answer: answerColor } = design

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState(reply)

    const answersLength = answers.length
    const prevAnswersLength = usePrevState(answersLength)

    // 1문 1답은 전체 랜더링, 클래식모드는 현재 문항만 랜더링
    const handleChangeState = useCallback(async(data) => {
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = data

        await setNewReply(data.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(data)
    }, [survey_question_no, onChangeReplys])

    
    const handleChange = useCallback((survey_module_answer_no, disabled) => {
        if (disabled) return

        const fixData = getFixData({ ranks, reply: newReply, survey_question_no, survey_module_answer_no, format: format._18 })

        handleChangeState(fixData)
        
    }, [survey_question_no, format, ranks, newReply, handleChangeState])

    // resetChange 
    const handleChangeReset = useCallback(() => {
        const resetData = memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no)
        handleChangeState(resetData)
    }, [survey_question_no, handleChangeState])

    // 편집페이지에서 보기.추가 삭제시 reply 리셋해준다 (실제 응답에서는 상관없다)
    useEffect(() => {
        if (prevAnswersLength) {
            if (answersLength !== prevAnswersLength) {
                handleChangeReset()
            }
        }
    }, [ answersLength, prevAnswersLength, handleChangeReset ])

      // 저장된 값을 가져올때 사용된다.
      useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <ItemsComponent 
            survey_question_no={survey_question_no}
            answers={answers}
            ranks={ranks} 
            reply={newReply}
            mobile_one={mobile_one}
            is_comment_placeholder={is_comment_placeholder}
            required={required}
            required_end={required_end}
            refComment={refComments18}
            answerColor={answerColor}
            onChange={handleChange}
            onChangeReset={handleChangeReset}
        />
    )
}

export default withPrevState(Component)