import { useState, useCallback, useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import { UserPaymentsProps } from 'types/user_payment'
import { TaxType } from 'ts-policy'
import { useIntl } from 'react-intl'
import useUserTaxDefaultValue from 'hooks/useUserTaxDefaultValue'
import NoneTaxBoxComponent from 'component/Payment/Receipt/NoneTaxBox'
import TaxBoxComponent from 'component/Payment/Receipt/TaxBox'
import DialogComponent from './Dialog'
import { PropsContext } from './Items'

type PropsKeys = 'user_payment_no' | 'tax_in_innopay' | 'user_tax'
type Props = Pick<UserPaymentsProps, PropsKeys>

const defaultUserTax = { 
    tax_type: TaxType.None, 
    modify_possible: 1, 
}

const initialState = {
    taxComplete: {
        open: false,
        taxType: TaxType.None,
        email: ''
    }
}
  
// type t1 = keyof c1; // 'a' | 'b' | 'c' | 'd'
// type t2 = Pick<c1, t1>; // { a: number; b: string; c: Date; d?: Date; }


function TaxComponent(props: Props) {
    const { onRefetch } = useContext(PropsContext)

    const { user_payment_no, tax_in_innopay, user_tax } = props

    const { tax_type, modify_possible } = user_tax || defaultUserTax

    const taxDefautValue = useUserTaxDefaultValue(user_tax)

    const { formatMessage: f } = useIntl()

    const [ taxComplete, setTaxComplete ] = useState(initialState.taxComplete)

    const handleChangeTax = useCallback(async(rows) => {
        const { taxType, email } = rows

        setTaxComplete({ open: true, taxType, email})
    }, [])

    const handleCloseTax = useCallback(() => {
        setTaxComplete(prevState => ({
            ...prevState,
            open: false
        }))
        
        setTimeout(() => {
            onRefetch()
        }, 200)
        
    }, [onRefetch])

    const handleChangeNonTax = useCallback(() => {
        window.alert('fuck')
    }, [])

    return (
        <>
        <Typography variant="h6" style={{marginTop: 50}} gutterBottom>{f({id: 'component.MyAccount.Pay.Tax.title'})}</Typography>
        {
            /* 이노페이로 현금영수증 발헹 안한 사람만 보여준다.(계좌이체 가상계좌는 이노페이에서 신청이 가능하다.) */
            (tax_in_innopay === 0  && taxDefautValue) ? (
                <>
                <TaxBoxComponent
                    location="edit"
                    user_payment_no={user_payment_no}
                    tax_type={tax_type}
                    modify_possible={Boolean(modify_possible)}
                    c_id_no={taxDefautValue.c_id_no}
                    c_name={taxDefautValue.c_name}
                    c_email={taxDefautValue.c_email}
                    c_company_name={taxDefautValue.c_company_name}
                    p_id_no={taxDefautValue.p_id_no}
                    p_name={taxDefautValue.p_name}
                    p_email={taxDefautValue.p_email}
                    p_mobile_no={taxDefautValue.p_mobile_no}
                    onChange={handleChangeTax}
                />
                </>
            ) : (tax_in_innopay === 1 || tax_in_innopay === 2) && (
                <>
                <NoneTaxBoxComponent tax_in_innopay={tax_in_innopay} buttonName={f({id: 'component.MyAccount.Pay.Tax.buttonName'})} onChange={handleChangeNonTax}/>
                </>
            )
        }
        <DialogComponent {...taxComplete} onClose={handleCloseTax}/>
        </>
    )
}

export default TaxComponent