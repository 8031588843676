import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { 
    Box, InputBase , Button, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio as MuiRadio, Checkbox, Divider } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { CREATE_USER_FIRED } from 'gql/user_fired'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { postMessage } from 'utils'
import oc from 'open-color-js'
import useLogout from 'hooks/useLogout'

const useStyles = makeStyles(theme => ({
    textarea: {
        background: 'white',
        padding: 10,
        marginBottom: 15,
        border: `1px solid ${oc.gray3}`,
        borderRadius: 3,
        [theme.breakpoints.down('xs')]: {
            background: oc.gray0
        }
    },
    box: {
        padding: 20,
        paddingTop: 0
    },
    buttonBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: 10
    },
    agreeBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 15,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 30
        }
    },
    formControl: {
        marginBottom: 10
    },
    formLabel: {
        marginBottom: 10
    }
}))

const Radio = withStyles({})(props => <MuiRadio {...props} color="primary" size="small"/>)

const Component = () => {
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const logout = useLogout()

    const [ kind, setKind ] = useState('')
    const [ agree, setAgree ] = useState(false)
    const [ text, setText ] = useState('')

    const [ createUserFired ] = useMutation(CREATE_USER_FIRED, {
        onCompleted: async() => {
            await dispatch({ type: BACKDROPHIDE })
            logout()
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })

            postMessage({ 
                dispatch: { type: ERRORPAGESHOW } 
            })
        }
    })

    const handleKind = useCallback((e) => {
        const { value } = e.target
        setKind(value)
    }, [])

    const handleChange = useCallback((e) => {
        const { value } = e.target
        setText(value)
    }, [])

    const handleAgree = useCallback((e) => {
        const { checked } = e.target
        setAgree(checked)
    }, [])

    const handleSave = useCallback(async() => {
        if (!kind) {
            dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.MyAccount.Fired.Textarea.handleSave.kind'}) })
            return
        }

        if (kind === 'etc' && !text) {
            dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.MyAccount.Fired.Textarea.handleSave.input'}) })
            return
        }

        if (!agree) {
            dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.MyAccount.Fired.Textarea.handleSave.agree'}) })
            return
        }

        await dispatch({ type: BACKDROPSHOW })
        createUserFired({ variables: { kind, text } })
    }, [f, dispatch, agree, kind, text, createUserFired])
 
    return (
        <Box className={classes.box}>
            <Box>
                <FormControl component="fieldset" margin="dense" className={classes.formControl}>
                    <FormLabel component="legend" className={classes.formLabel}>{f({id: 'component.MyAccount.Fired.Textarea.formLabel'})}</FormLabel>
                    <RadioGroup aria-label="type" name="type" value={kind} onChange={handleKind}>
                        <FormControlLabel value="used" control={<Radio/>} label={<Typography variant="body2">{f({id: 'component.MyAccount.Fired.Textarea.label.used'})}</Typography>}/>
                        <FormControlLabel value="service" control={<Radio/>} label={<Typography variant="body2">{f({id: 'component.MyAccount.Fired.Textarea.label.service'})}</Typography>}/>
                        <FormControlLabel value="price" control={<Radio/>} label={<Typography variant="body2">{f({id: 'component.MyAccount.Fired.Textarea.label.price'})}</Typography>}/>
                        <FormControlLabel value="respond" control={<Radio/>} label={<Typography variant="body2">{f({id: 'component.MyAccount.Fired.Textarea.label.respond'})}</Typography>}/>
                        <FormControlLabel value="etc" control={<Radio/>} label={<Typography variant="body2">{f({id: 'component.MyAccount.Fired.Textarea.label.etc'})}</Typography>}/>
                    </RadioGroup>
                </FormControl>
            </Box>

            {
                kind === 'etc' && (
                    <InputBase 
                        multiline 
                        rows={4} 
                        fullWidth 
                        className={classes.textarea} 
                        placeholder={f({id: 'component.MyAccount.Fired.Textarea.placeholder'})}
                        value={text}
                        onChange={handleChange}
                    />
                )
            }
            
            <Divider/>

            <Box className={classes.agreeBox}>
                <FormControlLabel control={<Checkbox name="agree" size="small" checked={agree} onChange={handleAgree}/>} label={<Typography variant="body2">{f({id: 'component.MyAccount.Fired.Textarea.agree'})}</Typography>}/>
            </Box>
            
            <Box className={classes.buttonBox}>
                <Button color="inherit" variant="contained" disableElevation onClick={handleSave}>
                    <Typography variant="button">{f({id: 'component.MyAccount.Fired.Textarea.button'})}</Typography>    
                </Button>
            </Box>
        </Box>
    )
}

export default Component