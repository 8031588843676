import { handleDisabledRightClick } from 'ts-utils'
import styled from 'styled-components'
import { withSurveyPopoverImage } from 'hoc'

const ImgOne = styled.img`
    max-width: 100%;
    max-height: 100%;


    @media only screen and (max-width: 1024px) {
        width: 100%;

        @media only screen and (max-width: 600px) {
            width: 100%;
        };
    };

    
    border-radius: 3px;
    display: block;
    cursor: pointer;
`

function ImgOneComponent(props) {
    const { src, popoverImageProps } = props
    const { on } = popoverImageProps

    const handleOpen = () => {
        on.open(src)
    }

    return <ImgOne alt="" src={src} onClick={handleOpen}  onContextMenu={handleDisabledRightClick}/>
}

export default withSurveyPopoverImage(ImgOneComponent)