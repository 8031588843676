import { memo } from 'react'
import { useIntl } from 'react-intl'
import ConfirmComponent from 'component/Survey/Edit/Confirm'
import {
    SurveyEditTabsComponent,
    SurveyEditTabComponent,
    SurveyEditSubTabsComponent,
    SurveyEditQuestionComponent,
    SurveyEdit15Component,
    SurveyEdit15ColumnComponent, 
    SurveyEdit15OptionComponent,
} from 'component'

const Component = (props) => {
    const { method } = props
    const { formatMessage: f } = useIntl()

    const tabs = [f({id: 'container.Survey.Edit.15.tabs.0'}), f({id: 'container.Survey.Edit.15.tabs.1'})]
    const subTabs = [f({id: 'container.Survey.Edit.15.subTabs.0'}), f({id: 'container.Survey.Edit.15.subTabs.1'})]

    if (method === 'one') subTabs.push(f({id: 'container.Survey.Edit.15.subTabs.2'}))

    return (

        <>
        <SurveyEditTabsComponent tabs={tabs}/>
        <SurveyEditTabComponent index={0}>
            <SurveyEditQuestionComponent/>
            <SurveyEditSubTabsComponent subTabs={subTabs}/>
            <SurveyEdit15Component/>
            <SurveyEdit15ColumnComponent/>
            <ConfirmComponent index={2}/>
        </SurveyEditTabComponent>
        <SurveyEditTabComponent index={1}>
            <SurveyEdit15OptionComponent/>
        </SurveyEditTabComponent>
        </>
    )
}

export default memo(Component)