import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent as MuiDialogContent, 
    DialogTitle,
    Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { UPDATE_USER_INFO_PASSWORD } from 'gql/user_info'
import { SHOW as BACKDROPSHOW, HIDE as BACKGROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { withBreakPoints } from 'hoc'
import { parseGraphqlError, postMessage } from 'utils'
import useIsPassword from 'hooks/useIsPassword'
import InputPassword from 'styled/InputPassword'

const DialogContent = withStyles({
    root: {
        
    }
})(MuiDialogContent)



const Component = props => {
    const { open, onClose, breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const [ password, setPassword ] = useState('')
    const [ newPassword, setNewPassword ] = useState('')
    const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('')

    const [ getIsPassword, getIsNewPasswordConfirm ] = useIsPassword()

    const dispatch = useDispatch()

    const [ updateUserInfoPassword ] = useMutation(UPDATE_USER_INFO_PASSWORD, {
        onCompleted: async(data) => {
            dispatch({ 
                type: ALERTSNACKBARSHOW,
                message: f({id: 'component.MyAccount.Info.PasswordModal.success'}),
                variant: 'success'
            })
            
            dispatch({ type: BACKGROPHIDE })
            onClose()
        },
        onError: (e) => {
            const errorMessage = parseGraphqlError(e)

            switch(errorMessage) {
                case 'password':
                case 'newPassword':
                case 'newPasswordConfirm':
                    dispatch({ 
                        type: ALERTSNACKBARSHOW,
                        message: f({id: `component.MyAccount.Info.PasswordModal.error.${errorMessage}`}),
                        variant: 'error'
                    })
                    break
                default:
                    postMessage({ 
                        dispatch: { type: ERRORPAGESHOW } 
                    })
                    break
            }            
        }
    })

    const isPassword = getIsPassword(password)
    const isNewPassword = getIsPassword(newPassword)
    const isNewPasswordConfirm = getIsNewPasswordConfirm(newPasswordConfirm, newPassword)

    
    const changeValue = useCallback((e) => {
        const { name, value } = e.target

        switch(name) {
            case 'password':
                setPassword(value)
                break
            case 'newPassword':
                setNewPassword(value)
                break
            case 'newPasswordConfirm':
                setNewPasswordConfirm(value)
                break
            default:
        }
        
    }, [])

    const handleSave = useCallback(async() => {
        
        if (!isPassword) {
            dispatch({ 
                type: ALERTSNACKBARSHOW,
                message: f({id: 'component.MyAccount.Info.PasswordModal.handleSave.passoword'}),
                variant: 'error'
            })
            return
        }

        if (!isNewPassword) {
            dispatch({ 
                type: ALERTSNACKBARSHOW,
                message: f({id: 'component.MyAccount.Info.PasswordModal.handleSave.newPassoword'}),
                variant: 'error'
            })
            return
        }

        if (!isNewPasswordConfirm) {
            dispatch({ 
                type: ALERTSNACKBARSHOW,
                message: f({id: 'component.MyAccount.Info.PasswordModal.handleSave.newPassowordConfirm'}),
                variant: 'error'
            })
            return
        }

        await dispatch({type: BACKDROPSHOW })
        updateUserInfoPassword({ variables: { password, new_password: newPassword, new_password_confirm: newPasswordConfirm } })
    }, [f, password, newPassword, newPasswordConfirm, isPassword, isNewPassword, isNewPasswordConfirm, updateUserInfoPassword, dispatch])

    useEffect(() => {
        if (!open) {
            setPassword('')
            setNewPassword('')
            setNewPasswordConfirm('')
        }
    }, [open])
    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={xs}>
            <DialogTitle id="myaccount-change-password">{f({id: 'component.MyAccount.Info.PasswordModal.title'})}</DialogTitle>
            <DialogContent>
                <form>
                <div>
                    <InputPassword 
                        name="password"
                        label={f({id: 'component.MyAccount.Info.PasswordModal.TextField.label.password'})}
                        placeholder={f({id: 'component.MyAccount.Info.PasswordModal.TextField.placeholder.password'})}
                        onChange={changeValue}
                        value={password}
                        error={!isPassword}
                    />
                </div>
                <div>
                    <InputPassword 
                        name="newPassword"
                        label={f({id: 'component.MyAccount.Info.PasswordModal.TextField.label.newPassword'})}
                        placeholder={f({id: 'component.MyAccount.Info.PasswordModal.TextField.placeholder.newPassword'})}
                        onChange={changeValue}
                        value={newPassword}
                        error={!isNewPassword}
                    />
                </div>
                <div>
                    <InputPassword 
                        name="newPasswordConfirm"
                        label={f({id: 'component.MyAccount.Info.PasswordModal.TextField.label.newPasswordConfirm'})}
                        placeholder={f({id: 'component.MyAccount.Info.PasswordModal.TextField.placeholder.newPasswordConfirm'})}
                        onChange={changeValue}
                        value={newPasswordConfirm}
                        error={!isNewPasswordConfirm}
                    />
                </div>  
                </form>  
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary" disabled={!password || !newPassword || !newPasswordConfirm}>
                    <Typography variant="button">{f({id: 'component.MyAccount.Info.PasswordModal.button.save'})}</Typography>
                </Button>
                <Button onClick={onClose} color="inherit">
                    <Typography variant="button">{f({id: 'component.MyAccount.Info.PasswordModal.button.close'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default withBreakPoints(Component)