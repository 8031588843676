import { useContext, useCallback, useEffect, useState } from 'react'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { WrapperStyled } from 'styled/Survey/Img'
import ItemsComponent from './Items'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import CheckCountComponent from '../02/CheckCount'

const Component = (props) => {
    const { format, refComments13, design } = useContext(PropsContext)

    const {  lists, icon_type, mobile_one, survey_question_no, is_comment_placeholder, required=false, required_end=0, reply } = props

    const { answer: answerColor } = design

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState(reply)

    const handleChange = useCallback(async(survey_module_no, click_comment, disabled) => {
  
        // 답체크
        // 미체크시 체크, 체크시 미체크
        // 체크된 상태에서 코멘트 클릭시 유지

        if (disabled) return

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c => c.survey_module_no === survey_module_no) ? true : false

        //체크 상태인데 코멘트 클릭시에는 리턴
        if (selfChecked && click_comment) return

        const reply = format._13({ survey_question_no, survey_module_no, comment: '' })

        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        const newReplys = selfChecked ? [ ...memoryReply.rows.filter(c => !(c.survey_question_no === survey_question_no && c.survey_module_no === survey_module_no)) ] 
                                      : [ ...memoryReply.rows, reply ] 
    

        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [ survey_question_no, newReply, format, onChangeReplys ])


    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])


    return (
        <>
        <WrapperStyled mobile_one={mobile_one}>
            <ItemsComponent
                lists={lists}
                reply={newReply}
                icon_type={icon_type}
                mobile_one={mobile_one}
                is_comment_placeholder={is_comment_placeholder}
                required={required}
                required_end={required_end}
                refComment={refComments13}
                answerColor={answerColor}
                onChange={handleChange}
            />
        </WrapperStyled>        
         <CheckCountComponent count={newReply.length}/>
         </>
    )
}

export default Component