import { useContext, useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { VerifyIsJust } from '../01/Component'
import OverflowComponent from './Overflow'
import CommentComponent from '../Comment'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import useJustDebounce from 'hooks/useJustDebounce'

export default (props) => {
    const { mode } = useContext(AccessMethodContext)
    const { mbox, option, format } = useContext(PropsContext)
    const { method } = mbox
    const { just } = option
    
    const { 
        survey_question_no, 
        lists, 
        left_label, 
        right_label, 
        reply,
        is_comment, 
        is_comment_placeholder,
        replyComment,
        reverse,
        zero_number,
        nps
    } = props

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ debounce, onChangDebounce ] = useJustDebounce()

    const [ newReply, setNewReply ] = useState(reply)

    const { formatMessage: f } = useIntl()

    const handleChange = useCallback(async(survey_module_no) => {
        // 답체크
        // 미체크시 체크, 체크시 미체크
        // 체크된 상태에서 코멘트 클릭시 유지

        // 이미 체크된 값이 있는지 체크 (선택한값)
        const otherChecked = newReply.find(c => c.survey_module_no && c.survey_module_no !== survey_module_no)  ? true : false

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c =>  c.survey_module_no === survey_module_no) ? true : false

        // 바로 다음문항으로 이동하기 여부
        const verifyIsJust = new VerifyIsJust({ mode, method, just })
        const isJust = verifyIsJust._05(survey_module_no, is_comment)

        if (debounce) return

        onChangDebounce(isJust)

        const reply = format._05({ survey_question_no, survey_module_no, isJust })

        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        const newReplys = selfChecked ? [...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no) ] :
                        otherChecked ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no), reply ]                     
                                     : [ ...memoryReply.rows, reply ] 
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [ mode, method, just, is_comment, survey_question_no, newReply, format, debounce, onChangeReplys, onChangDebounce ])

    const placeholderType = nps ? 'labelNps' : 'label'

    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <>
        <OverflowComponent 
            left_label={left_label ? left_label : f({id: `component.Survey.Modules.05.${placeholderType}.0`})}
            right_label={right_label ? right_label : f({id: `component.Survey.Modules.05.${placeholderType}.1`})}
            reply={newReply} 
            lists={lists} 
            reverse={reverse}
            zero_number={zero_number}
            onChange={handleChange}
        />
        <CommentComponent 
            survey_question_no={survey_question_no} 
            is_comment={is_comment}
            is_comment_placeholder={is_comment_placeholder}
            comment={replyComment}
            disabled={newReply.length > 0 ? false : true}
            marginTop="10px"
            marginBottom="5px"
        />
        </>
    )
}