import { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { StepConnector } from '@material-ui/core'
import PropsContext from 'context/props'

const Connector = withStyles({
    alternativeLabel: () => {
        return {
            top: 12,
        }
    },
    line: (props) => {
        const { orientation='horizontal', answer_button } = props

        let style = {
            border: 0,
            backgroundColor: `rgba(${answer_button})`,
            opacity: .7
        }

        style = orientation === 'vertical' ? { ...style, width: 1, height: '100%', marginTop: 8, marginLeft: 1, marginBottom: 0 } : { ...style, height: 1, width: '82%' }
        
        return style
    }
})(props => <StepConnector {...props}/>)

export default (props) => {
    
    const { design } = useContext(PropsContext)

    return <Connector {...props} {...design}/>
}