import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { Method } from '../gql/survey_mbox'


// 응답데이터 세이브가 제대로 됐는지 체크
function useVerifySaveReply(method: Method) {
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const handleVerify = useCallback((inputData: any[], commentData: any[], saveData: { datas: any[], comments: any[ ]}) => {
        function getName(data: { survey_question_no: number, survey_module_no?: number, survey_module_answer_no?: number}) {
            let name = String(data.survey_question_no)
            if (typeof data.survey_module_no === 'number') {
                name = `${name}-${data.survey_module_no}`
            }
            if (typeof data.survey_module_answer_no === 'number') {
                name = `${name}-${data.survey_module_answer_no}`
            }
    
            return name
        }

        const errorMessage = f({id: `hooks.useVerifySaveReply.message.${method}`})
    
        const { datas, comments } = saveData

        // rows
        const customData = new Map()
        const datasLen = datas.length
        for (let i=0; i<datasLen; i++) {
            const data = datas[i]
            const name = getName(data)
    
            customData.set(name, data.result) 
        }
    
        const inputDataLen = inputData.length
        for (let i=0; i<inputDataLen; i++) {
            const data = inputData[i]
            const name = getName(data)
    
            const value = customData.get(name)
    
            // [숫자, 1] 이 아니면 정상이 아닌것
            if (typeof value !== 'object') {
                dispatch({ type: BACKDROPHIDE })
                dispatch({ type: ALERTSNACKBARSHOW, variant: 'error', message: errorMessage, duration: 5000 })
                return false
            }
    
            if (typeof value[0] !== 'number' || value[1] !== 1) {
                dispatch({ type: BACKDROPHIDE })
                dispatch({ type: ALERTSNACKBARSHOW, variant: 'error', message: errorMessage, duration: 5000 })
                return false 
            }
        }

        // comments
        const customComments = new Map()
        const commentsLen = comments.length
        for (let i=0; i<commentsLen; i++) {
            const comment = comments[i]
            const name = getName(comment)
    
            customComments.set(name, comment.result) 
        }

        const commentDataLen = commentData.length
        for (let i=0; i<commentDataLen; i++) {
            const comment = commentData[i]
            const name = getName(comment)
    
            const value = customComments.get(name)
    
            // 숫자가 아니면 에러
            if (typeof value !== 'number') {
                dispatch({ type: BACKDROPHIDE })
                dispatch({ type: ALERTSNACKBARSHOW, variant: 'error', message: errorMessage, duration: 3500 })
                return false
            }

        }
        
        return true
    }, [method, dispatch, f])

    return [ handleVerify ]
}

export default useVerifySaveReply