/**
* Drawer Center 정렬 , 사이즈 반응형
*/
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import oc from 'open-color-js'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import ReactIframeComponent from './ReactIframe'

const useStyles = makeStyles(theme => ({
    container: props => {
        const { kind } = props
        const defaultHeight = 'calc(100vh - 4.6em)'
        
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: defaultHeight,
            paddingTop: 40,
            paddingBottom: 40,
            [theme.breakpoints.down('md')]: {
                paddingTop: 20,
                paddingBottom: 20
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: 15,
                paddingBottom: 15,
                height: kind === 'share' ? 'calc(100vh - 8.1em)' : defaultHeight
            }
        }
    }
}))


// kind: share, myaccount => sm 아래일때 높이값이 달라서 구분해준다
const Component = props => {
    const { kind, src } = props

    const classes = useStyles({ kind })

    const history = useHistory()

    const dispatch = useDispatch()
    
    useEffect(() => {
        async function receiveMessage(e) {
            if (e.origin === process.env.REACT_APP_ADMIN) {
                
                if (typeof e.data.dispatch === 'object') {
                    dispatch(e.data.dispatch)
                } else if (typeof e.data.push === 'string') {
                    history.push(e.data.push)
                }
            } 
        }

        dispatch({ type: BACKDROPSHOW })

        window.addEventListener("message", receiveMessage)
    }, [dispatch, history])

    return (
        <Container className={classes.container} kind={kind}>
            <ReactIframeComponent src={src} id={kind} style={{ border: `1px solid ${oc.gray3}` }}/>
        </Container>
    )
}

export default Component