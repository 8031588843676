import gql from 'graphql-tag'

const schemaJump = `
    jump_no    
    id          
    kind
    method
    survey_question_nos
    rules {
        jump_rule_no
        jump_no            
        method      
        survey_question_nos
        childs {
            jump_rule_child_no
            jump_rule_no             
            type
            sign
            value
            survey_module_nos
            survey_module_answer_no
            conditiond
        }
    }
`

export const GET_JUMP = gql`
    query Jump($survey_no: Int!, $mode: Mode) {
        jump(survey_no: $survey_no, mode: $mode) {
            ${schemaJump}
        }
    }
`

export const GET_JUMP_IN_RESPONSE = gql`
    query JumpInResponse($survey_no: Int!, $mode: Mode) {
        jumpInResponse(survey_no: $survey_no, mode: $mode) {
            ${schemaJump}
        }
    }
`

export const GET_JUMP_WHERE_PAGE = gql`
    query JumpWherePage($survey_no: Int!, $survey_page_no: Int!) {
        jumpWherePage(survey_no: $survey_no, survey_page_no: $survey_page_no) {
            ${schemaJump}
        }
    }
`

export const CREATE_JUMP = gql`
    mutation CreateJump($survey_no: Int!, $data: JSONObject!) {
        createJump(survey_no: $survey_no, data: $data)
    }
`

export const RESET_JUMP = gql`
    mutation ResetJump($survey_no: Int!) {
        resetJump(survey_no: $survey_no)
    }
`

export enum Kind {
    Page='page',
    Question='question',
    End='end' // Diagram에서만 사용한다.
}

export enum Type {
    Point = 'point',
    Question = 'question'
}

export enum Conditiond {
    And = 'and',
    Or = 'or'
}

export enum Method {
    Move = 'move',
    Show = 'show',
    Hide = 'hide',
    End = 'end'
}

export type ID = number;

export type JumpNo = number | string

// 규칙 if에 조건 추가
export interface JumpRuleChildProps {
    jump_rule_child_no: JumpNo;             // pk
    jump_rule_no: JumpNo;                   // 부모 키값 
    type: Type;                        // 비교 타입 기준 question, point
    sign: number;                      // 부등호  = != 등등
    value: string | number;            // point에 대한 비교값
    survey_module_nos: number[];          // survey_module_nos
    survey_module_answer_no: number;          // survey_module_answer_no
    conditiond: Conditiond;              // 조건절이 2개 이상일경우 사용되는 and or
}

// 규칙 if문 
export interface JumpRuleProps {
    jump_rule_no: JumpNo;
    jump_no: JumpNo;
    method: Method;             // 타겟문항 show hide move
    survey_question_nos: number[]; // 타겟 질문
    childs: JumpRuleChildProps[];
}

// 기본 디폴트
export interface JumpProps {
    jump_no: JumpNo;
    id: number;                 // 페이지 일경우 survey_page_no question일경우 survey_question_no
    kind: Kind;                 // page | question
    method: Method;             // 타겟문항 show hide move   
    survey_question_nos: number[]; // 타겟 질문
    rules: JumpRuleProps[];
}