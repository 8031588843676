import { useState, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { 
    MyAccountFrameComponent, 
    MyAccountInfoComponent, 
    MyAccountAlarmComponent, 
    MyAccountLangComponent, 
    MyAccountFiredComponent,
    MyAccountPayComponent,
    MyAccountStorageComponent
} from 'component'
import { postMessage } from 'utils'
import queryString from 'query-string'

const initialState = {
    selected: 'info'
}

const Component = () => {

    const location = useLocation()
    const hash = queryString.parse(location.hash)

    const [ selected, setSelected ] = useState(hash.code || initialState.selected)

    const handleChange = useCallback((key=initialState.selected) => {
        postMessage({ push: `#code=${key}` })
    }, [])

    useEffect(() => {
        if (hash.code) setSelected(hash.code)
    }, [hash.code])

    return (
        <MyAccountFrameComponent selected={selected} onChange={handleChange}>
            {
                hash.code === 'alarm' ? <MyAccountAlarmComponent/> : 
                hash.code === 'language' ? <MyAccountLangComponent/> : 
                hash.code === 'fired' ? <MyAccountFiredComponent/> : 
                hash.code === 'paylist' ? <MyAccountPayComponent/> : 
                hash.code === 'storage' ? <MyAccountStorageComponent/> : <MyAccountInfoComponent/>
            }
        </MyAccountFrameComponent>
    )
}

export default Component