import { useMemo, memo, useContext } from 'react'
import { Context } from './Component'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import RowsComponent from '../03/Rows'
import ColumnAnswerItemComponent from './ColumnAnswerItem'

function ColumnItemComponent(props) {
    const { survey_module_no, column, answers, replys } = props

    const { reply } = useContext(Context)

    const { formatMessage: f } = useIntl()

    const countReply = reply.filter(d => d.survey_module_no === survey_module_no).length
    
    return (
        <>
      
            <RowsComponent str={column || f({id: 'component.Survey.Modules.04.column'})}/>
     
            {
                useMemo(() => {
                    return (
                        <>
                        {
                            answers.map(d => {
                                const selected = replys[survey_module_no] ? replys[survey_module_no][d.survey_module_answer_no] !== undefined : false

                                return (
                                    <ColumnAnswerItemComponent
                                        key={d.survey_module_answer_no}
                                        survey_module_no={survey_module_no}
                                        survey_module_answer_no={d.survey_module_answer_no}
                                        countReply={countReply}
                                        selected={selected}
                                    />
                                )
                            })
                        }
                        </>
                    )
                }, [survey_module_no, answers, countReply, replys])
                
            }
        </>
    ) 
}

export default memo(ColumnItemComponent, (prev, next) => {
    return  isEqual(prev.survey_module_no, next.survey_module_no) &&
            isEqual(prev.column, next.column) && 
            isEqual(prev.answers, next.answers) &&
            isEqual(prev.replys, next.replys)
})