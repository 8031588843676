import { useContext } from 'react'
import summaryContext from 'context/summary'
import styled from 'styled-components'
import { getRGBA } from 'utils/analysis'
import { Typography } from '@material-ui/core'

//  게이지 바 포인트 적용 (척도형) 랩퍼
const WrapperBarPointStyled = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 4rem 1fr;
    grid-gap: 1rem;
    
    & > div:first-child {
        width: 4rem;
        height: 4rem;

        & * {
            font-family: ${({ font_family }) => font_family};
        }

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
        
            width: 100%;
            height: 100%;
            border-radius: 3px;
            background: ${({ bg }) => bg};
            color: ${({ color }) => color};
            font-weight: 900;
        }
    }

    & > div:last-child {
        
    }

    & + & {
        margin-top: 2em;
    }
`

export default (props) => {
    const { conts, children } = props
    const { design } = useContext(summaryContext)

    const bg = getRGBA({color: design.bar, opacity: .1})
    const color = getRGBA({color: design.bar, opacity: 1})

    // conts 타입이 숫자나 이미지가 올때가잇어서 구분하엿다
    const typeofConts = typeof conts

    return (
        <WrapperBarPointStyled bg={bg} color={color} font_family={design.font_family}>
            <div>
                <div>
                    {
                        typeofConts === 'string' || typeofConts === 'number' ? (
                            <Typography>{conts}</Typography>
                        ) : conts
                    }
                    
                </div>
            </div>
            <div>
                {children}
            </div>
        </WrapperBarPointStyled>
    )
}