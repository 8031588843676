/**
 * 요약 결과보기에서 나의결과 클릭시 해당유저의 survey_analysis_user_no 담는다...
 */
export const SEARCH = 'analysis/summary/targetUser/search'
export const INITIAL = 'analysis/summary/targetUser/initial'

const initState = {
    survey_analysis_users_no: null
}

export default (state = initState, action) => {

    switch(action.type) {
        case SEARCH:
            return {
                survey_analysis_users_no: action.survey_analysis_users_no
            }
        case INITIAL:
            return initState
        default:
            return state
    }
}