import { useMemo, useContext, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { cookies } from 'utils'
import FileSaver from 'file-saver'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone'

interface Props {
    survey_no: number;
    name: string;
    src: string;
    action: boolean;
    mode: string;
}

interface UseStylesProps {
    color: string;
    font_family: string;
}

const useStyles = makeStyles({
    icon: (props: UseStylesProps) => {
        const { color } = props
        return { color, width: 20, height: 20, marginTop: 1 }
    },
    circul: (props: UseStylesProps) => {
        const { color } = props
        return { color, marginRight: 4}
    },
    typography: (props: UseStylesProps) => {
        const { color, font_family } = props
        return { color, fontFamily: font_family, fontSize: 15, textDecoration: 'underline', wordBreak: 'break-all', lineHeight: '120%' }
    },
})

const MyBox = withStyles({
    root: () => {
        return {
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
            padding: 5,
            backgroundColor: 'transparent',
            border: 'transparent'
        }
    }
})(Paper)

const getCookieName = (survey_no: Props['survey_no'], src: Props['src']) => {
    const name = `fileAttach-${survey_no}-${src.replace(process.env.REACT_APP_CF, '')}`
    return name
}

function FileAttachComponent(props: Props) {
    const { survey_no, name, src, action, mode } = props
    
    const { design } = useContext(PropsContext)

    const { formatMessage: f } = useIntl()

    // 다운로드 중
    const [ doing, setDoing ] = useState(false)

    // 1분안에 다운로드 이력이 있는지 체
    const [ isDownloaded, setIsDownloaded ] = useState(cookies.get(getCookieName(survey_no, src)) ? true : false)

    const { font_family, answer_sbutton }:any = design

    const button_color = `rgba(${answer_sbutton})`

    const classes = useStyles({ color: button_color, font_family })

    const handleDownload = useCallback(async() => {
        if (!action) return

        // 연속으로 누르는거 방지
        if (doing) return

        // 실제 설문에서는 같은 파일을 다운로드는 10분동안 10번만 가능하다
        if (mode === 'live') {
            const name = getCookieName(survey_no, src)

            const value = cookies.get(name) ? Number(cookies.get(name)) + 1 : 1

            setIsDownloaded(true)

            if (value > 10) {
                window.alert(f({id: 'styled.Custom.FileAttach.blockDownload'}))
                return
            }

            cookies.set({ value: `${name}=${value}`, maxAge: 60 })
        }

        setDoing(true)

        const doFetch = async() => {
            const blob = await fetch(src).then(response => {
                if (response.status !== 200) return null
                return response.blob()
            })
        
            return blob
        }

        const blob = await doFetch()
        
        if (!blob) {
            window.alert('Nothing File!')
            setDoing(false)
            return
        }

        // create a new file from the blob object
        const file = new File([blob], name)

        FileSaver.saveAs(file, name)

        setDoing(false)
    }, [survey_no, name, src, action, doing, mode, f])
    
    return (
        <>
        {
            useMemo(() => (
                <MyBox onClick={handleDownload} elevation={0}>
                    {
                        doing ?  <CircularProgress size={13} className={classes.circul}/> : (
                            isDownloaded ? <GetAppTwoToneIcon fontSize='small' className={classes.icon}/> : <GetAppIcon fontSize='small' className={classes.icon}/>
                        ) 
                    }
                    <Typography variant='body2' className={classes.typography}>
                        {name}
                    </Typography>
                </MyBox>
            ), [name, classes, doing, isDownloaded, handleDownload])
        }
        </>
        
    )
}

export default FileAttachComponent