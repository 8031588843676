import { memo, useState, useCallback, useEffect, MouseEvent } from 'react' 
import { getRGBA } from 'utils/analysis'
import { convertRgbaToRgb } from 'ts-utils' 
import { isMobile, isMobileOnly } from 'react-device-detect'
import { _21Module, _21Option } from 'gql/survey_question_modules'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import { Format21 } from 'hoc/Survey/SetReplys'
import withPrevState, { UsePrevStateProps } from 'hoc/PrevState'
import ItemComponent from './Item'
import SearchComponent from './Search'
import SelectComponent from './Select'
import NothingComponent from './Nothing'
import ScrollTipComponent from './ScrollTip'
import ConfirmComponent from './Confirm'

export interface Props extends UsePrevStateProps {
    survey_no: number;
    items: _21Module[];
    reply: Format21[];
    answer_color: string;
    line_color: string;
    button_color: string;
    background_color: string;
    font_family: string;
    kind: _21Option['kind'];
    label: _21Option['label'];
    required: _21Option['required'];
    required_end: _21Option['required_end'];
    onChangeOne: (a: number | '') => void;
    onChangeMulti: (a: number[]) => void;
}

type OnChangeOne = (a: number) => void;
type OnChangeMulti = (a: number) => void;

export type OnChange = OnChangeOne | OnChangeMulti;
export type OnChangeSearch = (a: string) => void;
export type OnOpen  = (event: MouseEvent<HTMLElement>) => void;
export type OnClose = () => void;
export type OnRemove  = (event: MouseEvent<SVGAElement>, survey_module_no: number) => void;
export type ScrollZeroTop = boolean;

const usePopoverStyles = makeStyles(theme => ({
    root: {
       padding: 0
    },
    paper: (props: { line_color: string, background_color: string }) => {
        const { line_color, background_color } = props

        let maxWidth: number | string = 700
        let maxHeight = '50vh'
        let minHeight = 'initial'

        if (isMobileOnly) {
            maxWidth = '90%'
            minHeight = '50vh'
            maxHeight = '65vh'
        } else if (isMobile) {
            maxWidth = '90%'
            minHeight = '40vh'
            maxHeight = '65vh'
        }

        return {
            position: 'relative',
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between', 
            background: background_color,
            border: `1px solid ${line_color}`,
            width: '92%',
            maxWidth,
            maxHeight,
            minHeight
        }
    }
}))

function ItemsComponent(props: Props) {
    const { survey_no, items, reply, answer_color, line_color, button_color, background_color, font_family, kind, label, required, required_end, onChangeOne, onChangeMulti, usePrevState } = props

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)

    // 스크롤이 0인지 아닌지 판단해서 검색화면 색상 변경해준다
    const [ scrollZeroTop, setScrollZeroTop ] = useState<ScrollZeroTop>(true)

    // 스크롤이 있고, 스크롤위치가 맨아래가 아니라면 true
    const [ scrollTip, setScrollTip ] = useState(false)

    // 리스트창에서 선택한 옵션값 
    const [ optionReplys, setOptionReplys ] = useState(reply.map(c => c.survey_module_no))

    const [ search, setSearch ] = useState('')

    const lineColor = getRGBA({ color: line_color, opacity: .1})
    const [ _, __, ___, backgroundOpacity ] = background_color.split(',')

    const rgba = background_color || '255, 255, 255, 1'
    const rgb = '255, 255, 255'

    let backgroundColor = Number(backgroundOpacity) >= .6 ? getRGBA({ color: background_color, opacity:  1}) : 'rgba(255, 255, 255, 1)'
    
    // 투명도가 있으면 안돼서 rgb로 변경 (기존 설문은 위에 정책 그대로 유지한다.)
    if (/listovey/.test(window.location.hostname)) {
        if (survey_no > 10830) backgroundColor = `rgba(${convertRgbaToRgb(rgba, rgb)})`
    }
    else if (survey_no > 7470) {
        backgroundColor = `rgba(${convertRgbaToRgb(rgba, rgb)})`
    }

    const popoverClasses = usePopoverStyles({ line_color: lineColor, background_color: backgroundColor })

    const handleOpen: OnOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    // 단일선택 닫기
    const handleCloseOne = useCallback(() => {
        if (optionReplys.length === 0) {
            onChangeOne('')
            setAnchorEl(null)
            return            
        }

        // 동일한 값이면 아무 반응안한다
        if (reply.length > 0) {
            if (optionReplys[0] === reply[0].survey_module_no) {
                setAnchorEl(null)
                return
            }
        }

        onChangeOne(optionReplys[0])
        setAnchorEl(null)
    }, [reply, optionReplys, onChangeOne])

    // 단일선택 변경
    const handleChangeOne: OnChangeOne = useCallback((survey_module_no: Format21['survey_module_no']) => {
        // 선택된걸 다시 클릭하면 해제한다.
        let selected = true
        setOptionReplys(prevNo => {
            // prev랑 같지 않다면, 값을 선택한거다
            selected = prevNo[0] !== survey_module_no

            return selected ? [survey_module_no] : []
        })

        if (selected) {
            onChangeOne(survey_module_no)
            setAnchorEl(null)

            return
        }
    }, [onChangeOne])


    // 다중 선택 닫기
    const handleCloseMulti = useCallback(() => {
        onChangeMulti(optionReplys)
        setAnchorEl(null)
    }, [optionReplys, onChangeMulti])

    // 다중 선택 변경
    const handleChangeMulti: OnChangeMulti = useCallback((survey_module_no: Format21['survey_module_no']) => {
        setOptionReplys(prevNos => {
            // prevNos에 값이 없다면, 값을 선택한거다
            const selected = !prevNos.includes(survey_module_no) ? true : false

            return selected ? [...prevNos, survey_module_no] : prevNos.filter(prevNo => prevNo !== survey_module_no)
        })
    }, [])

    // 선택된 값 삭제
    const handleRemove: OnRemove = useCallback((e, survey_module_no) => {
        e.stopPropagation()

        // 단일선택은 값을 삭제
        if (!kind) onChangeOne('')
        else onChangeMulti(optionReplys.filter(c => c !== survey_module_no))
        
    }, [kind, optionReplys, onChangeOne, onChangeMulti])

    const handleChangeSearch:OnChangeSearch = useCallback((word: string) => {
        setSearch(word)
    }, [])

    const open = Boolean(anchorEl)

    const prevOpen = usePrevState(open)

    useEffect(() => {
        setOptionReplys(reply.map(c => c.survey_module_no))
    }, [reply])

    const handleClose = !kind ? handleCloseOne : handleCloseMulti
    const handleChange = !kind ? handleChangeOne : handleChangeMulti

    
    // 검색 결과에 대해 리스트를 다시 랜더링한다
    const regexAllCase = new RegExp(search, "gi")
    const filterItems = !search ? [...items] : items.filter(c =>  c.answer.search(regexAllCase) >= 0)

    // 갯수만큼 채워졌으면 selected 안된 아이템에 한해 disabled 처리한다
    const disabled = required && optionReplys.length >= required_end

    return (
        <>
        <SelectComponent 
            items={items} 
            reply={reply} 
            label={label}
            answer_color={answer_color} 
            line_color={line_color}
            font_family={font_family} 
            onOpen={handleOpen}
            onRemove={handleRemove}
        />
        {
            !open && <div style={{height: 5}}/>
        }
        <Popover 
            elevation={10}
            open={open} 
            anchorEl={anchorEl} 
            onClose={() => isMobile ? () => {} : handleClose()} 
            classes={popoverClasses}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            getContentAnchorEl={(e) => {
                setScrollTip(e.scrollTop + e.clientHeight + 3 < e.scrollHeight)
                const handleScroll = () => {

                    setScrollZeroTop(e.scrollTop === 0)

                    // 스크롤이 있구, 맨아래가 아니라면 스크롤 팁 메세지 보여준다
                    if (e.scrollHeight > e.clientHeight) {
                        setScrollTip(e.scrollTop + e.clientHeight + 3 < e.scrollHeight)
                    }
                }

                if (open) {
                    e.addEventListener('scroll', handleScroll)
                } else {
                    e.removeEventListener('scroll', handleScroll)
                }

            }}
        >
            <div>
                <Box style={{ position: 'sticky', top: -6, zIndex: 1, background: backgroundColor }}>
                    <SearchComponent 
                        open={open} 
                        answer_color={answer_color}
                        line_color={line_color}
                        font_family={font_family} 
                        scrollZeroTop={scrollZeroTop} 
                        onChange={handleChangeSearch}
                    />
                    <ScrollTipComponent show={scrollTip} answer_color={answer_color} font_family={font_family}/>
                </Box>
                <NothingComponent line_color={line_color} answer_color={answer_color} font_family={font_family} search={search} itemLength={filterItems.length}/>
                <List dense={!isMobile}>
                {
                    filterItems.map(c => {
                        const { survey_module_no, answer } = c

                        const selected = optionReplys.includes(survey_module_no)

                        const autoFocus = open && !prevOpen && selected

                        // 검색된 단어는 볼드 처리한다
                        let newAnswer = answer
                        if (search) {
                            const regexAllCase = new RegExp(search, "gi")
                            newAnswer = answer.replace(regexAllCase, `<b style='font-family: ${font_family};'>${search}</b>`)
                        }

                        return (
                            <ItemComponent 
                                key={survey_module_no} 
                                kind={kind} 
                                disabled={disabled}
                                survey_module_no={survey_module_no} 
                                answer={newAnswer} 
                                answer_color={answer_color}
                                line_color={line_color}
                                font_family={font_family}
                                selected={selected} 
                                autoFocus={autoFocus}
                                onChange={handleChange}
                            />
                        )
                    })
                }
                </List>
            </div>
            <ConfirmComponent open={open} button_color={button_color} font_family={font_family} onClose={handleClose}/>
        </Popover>
        </>
    )
}

export default withPrevState(memo(ItemsComponent))