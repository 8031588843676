import { ReactChild } from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

interface Props {
    children: ReactChild
}

const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 3;

    & > div {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns:  1fr;
        grid-template-rows: auto;
        grid-template-areas: "title";
        gap: .5em;
        align-items: center;

        & > div.title {
            grid-area: title;
        }
    }

`

const Component = (props: Props) =>  {
    const { children } = props

    return (
        <WrapperStyled>
            <div>
                <div className='title'>
                    <Typography variant="body1">{children}</Typography>
                </div>
            </div>
        </WrapperStyled>
    )
}

export default Component
