import { useContext, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { validation } from 'utils'
import { handleDisabledRightClick } from 'ts-utils'
import { getShareDomain } from 'ts-utils/survey'
import { Levels } from 'ts-policy'
import { getQuestionParsing, getShareLink } from 'utils/survey'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { withSurveyFetchData } from 'hoc'
import SnsIcon from 'styled/SnsIcon/Component'
import Tooltip from 'styled/Custom/Tooltip'
import OneStyled, { QuestionStyled } from 'styled/Survey/Live/One'
import Question from 'styled/Custom/Question'
import Answer from 'styled/Custom/Answer'
import Button from 'styled/Custom/Button'
import { SurveyModulesThanksComponent } from 'component'
import RedirectLabelComponent from './RedirectLabel'
import RedirectMessageLangComponent from './RedirectMessageLang'
import FileAndPictureComponent from './FileAndPicture'

const brandingSize = {
    s: ['100px', '100px'],
    m: ['170px', '170px'],
    l: ['240px', '240px'],
}

const BrandingStyled = styled.img`
    display: block;
    
    ${({ branding_size }) => {
        const [ width, height ] = brandingSize[branding_size]

        return `
            max-width: ${width};
            max-height: ${height};
        `
    }}

    border-radius: 3px;
    margin: 0 auto;
    margin-top: .5em;
    cursor: ${props => validation.url(props.branding_link) ? 'pointer' : 'default'};
`

const SnsStyled = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 8px;

    & > * + * {
        margin-left: .7em;
    }

    & div.tooltip {
        & > * + * {
            margin-left: .7em;
        }
    }
`

const ButtonBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: 5,
        flexWrap: 'wrap',
        flexGrow: 1
    }
})(Box)

// 외부사이트 연결
export function moveOutLink(link) {
    if (validation.url(link)) {
        window.open(link, "_blank")
    }
}

const thanks = true

function EndingComponent(props) {
    const { row, propsFetchData } = props

    const { 
        message, 
        button_name, 
        button_link, 
        analysis_name, 
        img_src, 
        img_position, 
        img_name, 
        align, 
        sns, 
        branding, 
        branding_src, 
        branding_size, 
        branding_link, 
        kind, 
        redirect_url, 
        redirect_label 
    } = row
    
    const { surveyMboxAnalysisDesign, mboxThumbnailSrc } = propsFetchData
    const { args: analysisArgs } = surveyMboxAnalysisDesign
    const { mode } = useContext(AccessMethodContext)
    const { mbox, levels } = useContext(PropsContext)
    const { survey_no, args, title } = mbox

    const location = useLocation()

    const { formatMessage: f } = useIntl()

    const domain = getShareDomain(survey_no)
    
    const shareLink = getShareLink({domain, args, analysisArgs})
    const url = shareLink.survey.web
    const analysisUrl = (/\/re\//.test(location.pathname) || /\/e\//.test(location.pathname)) ? `${shareLink.analysis}?m=email` : shareLink.analysis

    // 통계페이지 연결
    const moveAnalysis = useCallback(() => {
        window.open(analysisUrl, "_blank")
    }, [analysisUrl])

    const getRedirectUrl = useCallback(() => {
        const url = process.env.REACT_APP_LANDING
   
        if (!redirect_url) {
            return url
        }
        if (!validation.url(redirect_url)) {
            return url
        }

        return redirect_url
    }, [redirect_url])

    // 프레임에서 호출된건지 판단
    const getFrame = useCallback(() => {
        try {
            return window.self !== window.top
        } catch (e) {
            return true
        }
    }, [])

    // 프레임에서 호출된거면 팝업으로 띄우고, 아니면 replace
    const isFrame = getFrame()

    useEffect(() => {
        if (mode === 'live') {
            if (kind === 'redirect') {

                const url = getRedirectUrl()

                if (isFrame) moveOutLink(url)
                else window.location.replace(url)        
            }
        }
    }, [mode, kind, isFrame, getRedirectUrl])

    // mode: preview, previewTs
    if (/^preview/.test(mode) && kind === 'redirect') {
        return <RedirectMessageLangComponent/>
    }

    // live에서 보여주는 컴포넌트
    if (mode === 'live' && kind === 'redirect') {
        // 프레임내에서만 이렇게 보여준다.
        if (isFrame) return <RedirectLabelComponent redirect_label={redirect_label} getUrl={getRedirectUrl}/>
        else return null
    }

    return (
        <OneStyled img_src={img_src} img_position={img_position} thanks={thanks}>
            <QuestionStyled img_src={img_src} img_position={img_position} thanks={thanks}>
                <div>
                    <div>
                        <SurveyModulesThanksComponent selected='ending' img_src={img_src} img_position={img_position}>
                            <div className='message'>
                                <Question style={{textAlign: align}}>
                                    {
                                        message ? getQuestionParsing(message) : f({id: 'container.Survey.Create.Thanks.EditEnding.placeholder'})
                                    }
                                </Question>
                            </div>
                            <div className='image'>
                                <FileAndPictureComponent
                                    img_src={img_src} 
                                    img_position={img_position} 
                                    img_name={img_name} 
                                    thanks={thanks}
                                />
                            </div>
                            {
                                (button_name && analysis_name) ? (
                                    <div className='button'>
                                        <ButtonBox>
                                            <Button size="small" onClick={() => moveOutLink(button_link)}>{button_name}</Button>
                                            <Button size="small"  onClick={moveAnalysis}>{analysis_name}</Button>
                                        </ButtonBox>
                                    </div>
                                ) : button_name ? (
                                    <div className='button'>
                                        <Button size="large" onClick={() => moveOutLink(button_link)}>{button_name}</Button>
                                    </div>
                                ) : analysis_name && (
                                    <div className='button'>
                                        <Button size="large" onClick={moveAnalysis}>{analysis_name}</Button>
                                    </div>
                                )
                            }
                            {
                                sns && (
                                    <div className='sns'>
                                        <SnsStyled>
                                            {
                                                mode === 'live' ? ( 
                                                    <SnsIcon url={url} title={title} thumbnail={mboxThumbnailSrc} size={35}/>
                                                ) : (
                                                    <Tooltip title={f({id: 'component.Survey.Modules.Ending.sns'})} isMobileClick={true}>
                                                        <div className="tooltip">
                                                            <SnsIcon title={title} size={35}/>
                                                        </div>
                                                    </Tooltip>
                                                )
                                            }
                                        </SnsStyled>
                                    </div>
                                )
                            }
                            {
                                // 무료 플랜은 무조건 리스토에비 보여준다 라이브에선..
                                mode === 'live' && levels === Levels.Level0 ? (
                                    <div className='branding'>
                                      
                                    </div>
                                ) : branding && (
                                    <div className='branding'>
                                        {
                                            branding_src && (
                                                <>
                                                <Answer>Powered by</Answer>
                                                <BrandingStyled 
                                                    src={branding_src} 
                                                    branding_size={branding_size} 
                                                    branding_link={branding_link} 
                                                    onClick={() => moveOutLink(branding_link)}
                                                    onContextMenu={handleDisabledRightClick}
                                                />
                                                </>
                                            ) 
                                        }
                                    </div>
                                )
                            }
                        </SurveyModulesThanksComponent>
                    </div>
                </div>
            </QuestionStyled>
        </OneStyled>
    )
}

export default withSurveyFetchData('live')({
    surveyMboxAnalysisDesign: true,
    mboxThumbnailSrc: true,
    _fetchPolicy: { surveyMboxAnalysisDesign: 'cache-first', mboxThumbnailSrc: 'cache-first' }
})(false)(EndingComponent)
