import { useEffect, useContext, useCallback, useState } from 'react'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { makeStyles } from '@material-ui/core/styles'
import { Kind } from 'gql/jump'
import BaseComponent from './Base'
import PhoneComponent from './Phone'
import NumberComponent from './Number'
import EmailComponent from './Email'
import DateComponent from './Date'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'

const useStyles = makeStyles((theme) => ({
    icon: (props) => {
        const { answer } = props

        return {
            color: `rgba(${answer})`
        }
    }
}))


function Component (props) {
    const { design, format, onChangeReplys, refComments09, mbox, rowsJump } = useContext(PropsContext)

    const { 
        survey_question_no, 
        type, 
        min_length, 
        max_length, 
        min_number, 
        max_number, 
        badge, 
        unit, 
        national_flag, 
        unit_position, 
        minus, 
        decimald, 
        number_format, 
        date_format_type,
        date_start,
        date_range,
        reply 
    } = props

    const { method } = mbox

    const classes = useStyles(design)

    const [ newReply, setNewReply ] = useState(reply)

    const [ _, onChangeUserStart ] = useChangeReplys(survey_question_no)

    const originAnswered = reply.length === 0 ? '' : reply[0].answer
    const answered = newReply.length === 0 ? '' : newReply[0].answer

    // 1문 1답은 전체 랜더링, 클래식모드는 현재 문항만 랜더링
    const handleChangeState = useCallback(async(data) => {
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = data

        await setNewReply(data.filter(c => c.survey_question_no === survey_question_no))

        if (method === 'one') onChangeReplys(data)
    }, [method, survey_question_no, onChangeReplys])


    // state 값이 없을때 추가 값이 있을때 제거
    const handleChange = useCallback((value) => {
        // 인풋박스 클릭시 replys에 값이 없다면 기본값으로 state 해준다
        const isData = memoryReply.rows.find(c => c.survey_question_no === survey_question_no)

        // 기본적으로는 값이 없는상태에서 memoryReply에 있다면 memoryReply에서도 지운다
        // 폰일경우 국가선택값이 잇으면 값이없어도 reply 안지우고 없는데 값도 업으면 지운다
        if (!value && isData) {
            if (type !== 'phone') {
                let newReplys = [...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no)]
                
                handleChangeState(newReplys)
                return

            } else {
                if (!isData.national_flag) {
                    let newReplys = [...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no)]

                    handleChangeState(newReplys)
                }
            }

        } else if ( value && !isData ) { 
            // 전화번호 검증이고, 국가 선택을 한개만 했다면 자동으로 적용한다 (select 박스를 안보여줄거다)
            const nationalFlag = type === 'phone' && national_flag.length === 1 ? national_flag[0] : ''
           
            const reply = format._09({ survey_question_no, answer: value, national_flag: nationalFlag })

            let newReplys = [...memoryReply.rows, reply]

            handleChangeState(newReplys)
        } else if ( value && isData ) {
            let newReplys = memoryReply.rows.map(row => {
                return row.survey_question_no === survey_question_no ? {
                    ...row,
                    answer: value
                } : row
            })

            handleChangeState(newReplys)
        }

        // 클릭하는 순간 어떤경우에도 시작 true
        onChangeUserStart()
    }, [ survey_question_no, type, format, national_flag, handleChangeState, onChangeUserStart ])


    useEffect(() => {
        if (answered) {
            if (refComments09.current[survey_question_no]) refComments09.current[survey_question_no].value = answered
        }
    }, [ answered, survey_question_no, refComments09 ])
    
    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])


    // 해당문항에 점프값이 있다면..
    const isConfirm = (method === 'multi' && rowsJump.find(c => c.kind === Kind.Question && c.id === survey_question_no))

    return (
        <>
        {    
            type === 'base' ? (
                <BaseComponent 
                    survey_question_no={survey_question_no} 
                    isConfirm={isConfirm}
                    originAnswered={originAnswered}
                    min_length={min_length} 
                    max_length={max_length} 
                    badge={badge}
                    refComment={refComments09}
                    onChange={handleChange}
                />
            ) : 
            type === 'phone' ? (
                <PhoneComponent 
                    survey_question_no={survey_question_no} 
                    isConfirm={isConfirm}
                    originAnswered={originAnswered}
                    format={format._09}
                    national_flag={national_flag} 
                    reply={newReply}
                    refComment={refComments09}
                    classes={classes}
                    onChange={handleChange} 
                    onChangeState={handleChangeState}
                />
            ) : 
            type === 'number' ? (
                <NumberComponent 
                    survey_question_no={survey_question_no} 
                    isConfirm={isConfirm}
                    originAnswered={originAnswered}
                    min_number={min_number}
                    max_number={max_number}
                    badge={badge}
                    number_format={number_format}
                    classes={classes}
                    unit={unit}
                    unit_position={unit_position}
                    minus={minus} 
                    decimald={decimald}
                    refComment={refComments09}
                    onChange={handleChange}
                />
            ) : 
            type === 'email' ? (
                <EmailComponent 
                    survey_question_no={survey_question_no}
                    isConfirm={isConfirm}
                    originAnswered={originAnswered}
                    classes={classes}
                    reply={newReply}
                    refComment={refComments09}
                    onChange={handleChange}
                />
            ) : type === 'date' ? (
                <DateComponent 
                    survey_question_no={survey_question_no}
                    isConfirm={isConfirm}
                    originAnswered={originAnswered}
                    date_format_type={date_format_type}
                    date_start={date_start}
                    date_range={date_range}
                    classes={classes}
                    reply={newReply}
                    refComment={refComments09}
                    onChange={handleChange}
                />
            ) : ''
                             
        }
        </>
    )
}

export default Component