import { memo, useContext, useState, useEffect, useCallback } from 'react'
import { SurveyModule16, SurveyOption16 } from 'types/survey_module'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { Format16 } from 'hoc/Survey/SetReplys'
import { VerifyIsJust } from '../01/Component'
import { memoryReply } from 'container/Survey/Live/Container'
import ItemComponent from './Item'
import useChangeReplys from '../useChangeReplys'

interface Props {
    reply: Format16[];
    survey_question_no: number;
    item: SurveyModule16[];
    option: SurveyOption16;
}

function Module16Component(props: Props) {
    const { mode } = useContext(AccessMethodContext)
    const { mbox, format } = useContext<any>(PropsContext)
    const { method } = mbox

    const { reply, survey_question_no, item, option } = props
    const { icon_type } = option

    const [{ survey_module_no, answer }] = item

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState<Format16[]>(reply)

    const handleChange = useCallback(async(e) => {
        e.stopPropagation()
        e.preventDefault()
  
        // 답체크
        // 미체크시 체크, 체크시 미체크
        // 체크된 상태에서 코멘트 클릭시 유지

        // 이미 체크된 값이 있는지 체크 (선택한값)
        const otherChecked = newReply.find(c => c.survey_module_no && c.survey_module_no !== survey_module_no)  ? true : false

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c => c.survey_module_no === survey_module_no) ? true : false

        const verifyIsJust = new VerifyIsJust({ mode, method, just: true })
        const isJust = verifyIsJust._16(survey_module_no)

        const reply = format._16({ survey_question_no, survey_module_no, isJust })


        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        // 전체 통합작업
        const newReplys = selfChecked  ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no) ] :
                          otherChecked ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no), reply ]                     
                                       : [ ...memoryReply.rows, reply ] 

        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [ mode, method, survey_question_no, survey_module_no, newReply, format, onChangeReplys ])

    let selected = false
    let isJust = false
    const selfReply = newReply.find(c => c.survey_module_no === survey_module_no)
   
    if (selfReply) {
        selected = true
        isJust = Boolean(selfReply.isJust)
    }


    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <ItemComponent selected={selected} isJust={isJust} icon_type={icon_type} answer={answer} onChange={handleChange}/>
    )
}

export default memo(Module16Component)