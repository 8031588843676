// 저장할때 재런더링 방지를 위해 문항 다 숨긴다.

export const ON = 'survey/live/multi/ON'
export const OFF = 'survey/live/multi/OFF'

const initState = {
    mode: '', //question save
    no: ''
}

export default (state = initState, action) => {
    switch(action.type) {
        case ON:
            return {
                mode: action.mode,
                survey_question_no: action.survey_question_no
            }
        case OFF:
            return initState
        default:
            return state
    }
}