import { useState, useCallback } from "react"
import { useIntl } from 'react-intl'
import Button from "@material-ui/core/Button"
import randomString from 'randomstring'

type Order = 'answer' | 'rank'

const boxStyles = {
    marginBottom: 10, 
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center'
}

function buttonStyles(selected: boolean, color: string) {
    let styles = { color: `rgba(${color})` }

    if (selected) return { ...styles, textDecoration: 'underline', fontWeight: 700 }

    return styles
}

function barStyles(color: string) {
    return {
        borderLeft: `1px solid ${color}`, 
        height: 15
    }
}

function useOptionButtonsComponent(survey_question_no: number, color: string) {
    const localOrderName = `rankOption-${survey_question_no}}`
    const localOrder = localStorage.getItem(localOrderName) 
    const [ order, setOrder ] = useState<Order>((localOrder === 'answer' || localOrder === 'rank') ? localOrder : 'answer')
    const [ reset, setReset ] = useState<string>('')

    const { formatMessage: f } = useIntl()

    const handleChangeOrder = useCallback((args: Order) => {
        setOrder(args)
        localStorage.setItem(localOrderName, args)
    }, [localOrderName])

    const handleChangeReset = useCallback(() => {
        setReset(randomString.generate(3))
    }, [])

    /*
    const Buttons = useCallback(() => (
        <div style={boxStyles}>
            <Button 
                style={buttonStyles(order === 'answer', color)} 
                onClick={() => handleChangeOrder('answer')}
            >
                {f({id: 'component.Survey.Modules.17.OptionButtons.order.answer'})}
            </Button>
            <div style={barStyles(color)}></div>
            <Button 
                style={buttonStyles(order === 'rank', color)} 
                onClick={() => handleChangeOrder('rank')}
            >
                {f({id: 'component.Survey.Modules.17.OptionButtons.order.rank'})}
            </Button>
            <Button 
                style={buttonStyles(false, color)} 
                onClick={handleChangeReset}
            >
                {f({id: 'component.Survey.Modules.17.OptionButtons.reset'})}
            </Button>
        </div>
    ), [color, order, f, handleChangeOrder, handleChangeReset])
    */

    const Buttons = useCallback(() => (
        <div style={boxStyles}>
            <Button 
                style={buttonStyles(false, color)} 
                onClick={handleChangeReset}
            >
                {f({id: 'component.Survey.Modules.17.OptionButtons.reset'})}
            </Button>
        </div>
    ), [color, f, handleChangeReset])


    return [Buttons, { order, reset }]
}

export default useOptionButtonsComponent