import { memo } from 'react'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import { StepLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getAnimationClassName } from '../05/Item'
import Step from 'styled/Custom/Step'
import StepIconText from 'styled/Custom/StepIconText'
import StepConnector from 'styled/Custom/StepConnector'
import Stepper from 'styled/Custom/Stepper'
import Answer from 'styled/Custom/Answer'
import VerticalScaleBox from 'styled/Custom/VerticalScaleBox'
import { getValue } from '../05/Item'

const orientation = 'vertical'

export const MyAnswer = withStyles({
    root: {
        fontSize: 14,
        lineHeight: '120%'
    }
})(Answer)

function ItemComponent(props) {
    const { lists, reply, reverse, zero_number, onChange } = props

    const { formatMessage: f } = useIntl()

    // 한개만 선택하는거라 배열은 무조건 0 이다
    const value = getValue(reply, lists)

    let isJust = false

    const len = lists.length

    const isValue = typeof value === 'number'

    return (
        <VerticalScaleBox marginTop={5} checked={isValue}>
            <Stepper activeStep={value} connector={<StepConnector orientation={orientation} />}  orientation={orientation}>
                {
                    lists.map(c => {
                        if (isValue) {
                            isJust = Boolean(reply[0].isJust) && reply[0].survey_module_no === c.survey_module_no
                        }
                        return (
                            <Step key={c.survey_module_no} className={getAnimationClassName(isJust)}>
                                <StepLabel StepIconComponent={(props) => <StepIconText zero_number={zero_number} reverse={reverse} len={len} {...props}/>} onClick={() =>  !isJust && onChange(c.survey_module_no)}>
                                    <MyAnswer>{c.answer? c.answer : f({id: 'component.Survey.Modules.07.answer'})}</MyAnswer>
                                </StepLabel>
                            </Step>
                        )
                    })
                }
            </Stepper>
        </VerticalScaleBox>
    )
}

export default memo(ItemComponent, (prev, next) => {
    return  isEqual(prev.reply, next.reply) && 
            isEqual(prev.lists, next.lists) && 
            isEqual(prev.onChange, next.onChange)
})