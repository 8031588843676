import { memo } from 'react'
import { isEqual } from 'lodash-es'
import { getMaxlength } from 'policy'
import Input from 'styled/Custom/Input'
import useValue from '../09/useValue'
import { getIsDisabled } from '../09/Base'
import ConfirmComponent from '../09/Confirm'

function Item(props) {
    const { survey_question_no, isConfirm, originAnswered, onChange, refComment } = props

    const [ value, onChangeValue ] = useValue(originAnswered, onChange)

    return (
        <>
        <Input
            multiline
            rows={7}
            value={value}
            onChange={(e) => onChangeValue(e.target.value)}
            inputProps={{maxLength: getMaxlength('component.Survey.Modules.Comment.comment')}}
            inputRef={ref => refComment.current[survey_question_no] = ref}
        />
        {
            isConfirm && (
                <ConfirmComponent survey_question_no={survey_question_no} value={value} isEqualAnswer={getIsDisabled(value, originAnswered)}/>
            )
        }
        </>
    )
}

export default memo(Item, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.isConfirm, next.isConfirm) && 
            isEqual(prev.originAnswered, next.originAnswered) && 
            isEqual(prev.onChange, next.onChange) && 
            isEqual(prev.refComment, next.refComment)
})