import { ComponentType, useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import sortArray from 'sort-array'
import randomString from 'randomstring'
import { parseGraphqlError } from 'utils'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { GET_SURVEY_ANALYSIS_ROWS_PAGING, SurveyAnalysisRowsPagingVariables, SurveyAnalysisPagingRowsProps } from 'gql/survey_analysis_rows'
import { getOrderKey } from 'component/Survey/Create/NewAnalysis/Rows/Component'

interface Props extends Omit<SurveyAnalysisRowsPagingVariables ,'mode'> {
    questions?: SurveyQuestionModuleProps[];
}

interface ComponentTypeProps extends SurveyAnalysisPagingRowsProps {
    loading: boolean;
    isError: boolean;
    refetch: () => void;
}

// 백엔드에서 정렬을 다시하면 부담스러워서 여기서 처리한다...
export function sort(order: SurveyAnalysisRowsPagingVariables['input']['order'], answers: SurveyAnalysisPagingRowsProps['rows']['answers']) {
    const { key, value } = order

    const sort = value ? 'desc' : 'asc'
    sortArray(answers, 
        {
            by: [key, 'id'],
            order: [sort, 'asc']
        }
    )    
}

let key: { [a: number]: string } = {}

export const createKey = (survey_no: number) => {
    const value = randomString.generate(10)
    key[survey_no]= value
}

export const getKey = (survey_no: number) => {
    if (!key[survey_no]) createKey(survey_no)

    return key[survey_no]
}

export default (mode: SurveyAnalysisRowsPagingVariables['mode']) => (isLoading: boolean) => (WrappedComponent: ComponentType<{rowsData: ComponentTypeProps}>) => (props: Props) => {
    const { survey_no, input } = props
    const { conditionsPlay, step, date, search, order, startpoint, offset } = input

    const dispatch = useDispatch()

    // 에러일때 true
    const [ isError, setIsError ] = useState(false)

    const [getData, { data, loading, refetch }] = useLazyQuery<{surveyAnalysisRowsPaging: SurveyAnalysisPagingRowsProps}, SurveyAnalysisRowsPagingVariables>(GET_SURVEY_ANALYSIS_ROWS_PAGING, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            setIsError(false)
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'limit') {
                setIsError(true)
            }
        }
    })

    const handleRefetch = useCallback(() => {
        if (!refetch) return
        
        // 리패치 할때 키를 바꿔서 새로 담는다 (필터 데이터는 DB에 Json으로 담기 때문이다.)
        createKey(survey_no)
        const key = getKey(survey_no)

        refetch({
            survey_no, 
            mode,
            input: {
                conditionsPlay, 
                step: Number(step),
                date, 
                search,
                order: { key: getOrderKey(order.key, step), value: order.value },
                startpoint: startpoint * offset, 
                offset 
            },
            key
        })
    }, [survey_no, conditionsPlay, step, date, search, order, startpoint, offset, refetch])



    useEffect(() => {
      /*
      ** 정렬, 페이징 변경, 리미트 수 변경하는거는 이미 저장된 json에서 가져오고 
      ** 검색 값이 변경되면 다시 한다.
      */
     // 여기서 처리하면 안되겠다. conditionsPlay, step, date, search 변경시 getKey도 변경 되게 하는게 맞을거같은데?
      //createKey()
    },[conditionsPlay, step, date, search])

    useEffect(() => { 
        getData({
            variables: {
                survey_no, 
                mode,
                input: {
                    conditionsPlay, 
                    step: Number(step),
                    date, 
                    search,
                    order: { key: getOrderKey(order.key, step), value: order.value },
                    startpoint: startpoint * offset, 
                    offset 
                },
                key: getKey(survey_no)
            }
        })
    }, [survey_no, conditionsPlay, step, date, search, order, startpoint, offset, getData])

    useEffect(() => {
        if (isLoading && loading) {
            // dispatch({type: BACKDROPSHOW })
        }
    }, [loading, dispatch])

    // if (!data) return null

    const { userCount, fileCount, rows } = data ? data.surveyAnalysisRowsPaging : { 
        userCount: 0,
        fileCount: 0,
        rows: {
            answers: [], 
            columns: [], 
            files: []
        }
    }

    sort({ key: getOrderKey(order.key, step), value: order.value }, rows.answers)

    return <WrappedComponent {...props} rowsData={{userCount, fileCount, rows, loading, refetch: handleRefetch, isError }}/>
}