import { useState, useEffect, Fragment } from 'react'
import { 
    AppBar, CssBaseline, Divider, Drawer, Hidden, IconButton,  
    List, ListItem, Toolbar, Typography, ListItemIcon, ListItemText, ListSubheader, Box } from '@material-ui/core'
import {  Menu as MenuIcon } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { postMessage } from 'utils'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        [theme.breakpoints.down('xs')]: {
            background: 'white',
            color: 'black'
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: (props) => {
        const { isPadding } = props

        return {
            flexGrow: 1,
            background: 'white',
            padding: theme.spacing(isPadding ? 3 : 0),
            minHeight: '100vh',
            height: '100%',
            [theme.breakpoints.up('sm')]: {
                background: 'transparent'
            },
        }
    },
    box: {
        display: 'table', 
        width: '100%', 
        tableLayout: 'fixed'
    }
}))

/*
 selected: index, subindex, key (index, subindex 는 해당 좌측메뉴 카테고리 선택에서 사용되고 key는 본문내용에서 사용된다)
           key는 items값에서 배열 인덱스와 상관없이 전체 배열값에서 unique 해야한다. (순서변경으로 인한 본문내용부분도 변경되는 부분을 방지하기위해 key로 구분한다)
*/

const getSelf = (items, selected) => {
    const length = items.length
    let _self = ''

    for (let i=0; i<length; i++) {
        _self = items[i].find(c => c.key === selected)
        if (_self) break
    }

    return _self

}


export default (props) => {
    const { subheaders, items, window, selected, onChange, children } = props

    const _self = getSelf(items, selected)

    const { isPadding, primary: title, secondary: subtitle } = _self || { isPadding: 0, primary: '', secondary: '' }

    const classes = useStyles({ isPadding })
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false)
    
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen)
    }
    
    // 메뉴 리스트
    let drawer = subheaders.map((subheader, i) => {
        const lists = items[i].map((item, j) => {
            const { key, primary, secondary, icon } = item
            const Icon = icon

            return (
                <ListItem key={`${key}-${j}`} button selected={key === selected} onClick={() => onChange(key)}>
                    <ListItemIcon><Icon/></ListItemIcon>
                    <ListItemText primary={primary} secondary={secondary}/>
                </ListItem>
            )
        })

        return (
            <Fragment key={`key-${i}`}>
            <Divider/>
            <List>
                <ListSubheader>{subheader}</ListSubheader>
                {lists}
            </List>
            </Fragment>
        )
    })

    drawer = (
        <div>
            <div className={classes.toolbar}/>
            {drawer}
        </div>
    )
    
    
    const container = window !== undefined ? () => window().document.body : undefined

    useEffect(() => {
        setMobileOpen(false)
    }, [selected])

    useEffect(() => {
        postMessage({ 
            dispatch: { type: BACKDROPHIDE } 
        })
    }, [])
    
    if (!_self) return null

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap>
                    {title || subtitle}
                </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Box className={classes.box}>{children}</Box>
            </main>
        </div>
    )
}