import gql from 'graphql-tag'

export const GET_USER_CARDS = gql`
    query UserCards {
        userCards {
            user_card_no
            card_name
            cardNum
            cardExpire
            appCardName
            wdate
        }
    }
`

export const DELETE_USER_CARD = gql`
    mutation DeleteUserCard($user_card_no: Int!) {
        deleteUserCard(user_card_no: $user_card_no)
    }
`