import { useState, useEffect } from 'react'
import LoadingBar from 'react-top-loading-bar'

export default function LinearDeterminate() {

    const [progress, setProgress] = useState(0)
    const [ mount, setMount ] = useState(false)
    useEffect(() => {
        setMount(true)
        setProgress(100)
        return () => {
            setMount(false)
        }
    }, [])

    if (!mount) return null

    return <LoadingBar progress={progress} height={3} onLoaderFinished={() => setProgress(0)} waitingTime={100} color='black'/> 
}
