/**
 * args로 survey_no 구하기
 */
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_ANALYSYS_SURVEY_NO } from 'gql/survey_mbox_analysis_design'

export default mode => WrappedComponent => props => {
    const dispatch = useDispatch()

    const { analysisArgs } = useParams()


    const { data } = useQuery(GET_ANALYSYS_SURVEY_NO, {
        variables: { 
            args: analysisArgs
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    if (!data) return null

    return <WrappedComponent survey_no={data.analysisSurveyNo} {...props}/>
}