/*
**    저장버튼에서 구독 
** questionItemsSplit에서 포인트변경, 추가 삭제시 dispatch
*/
import { SurveyEndingProps } from 'gql/survey_ending'

// 전체 데이터 저장
export const INIT = 'survey/create/logic/jumpEnding/INIT'

// 개별문항 및 페이지의 row 추가 삭제 변경시 저장
export const CHANGE = 'survey/create/logic/jumpEnding/CHANGE'

const initState: { rows: SurveyEndingProps[] } = {
    rows: []
}

interface ActionProps {
    type: string;
    O_INIT: {
        rows: SurveyEndingProps[]; // 점프로직 포인트 초기 데이터 저장
    },
    O_CHANGE: {
        survey_ending_no: number;
        start_point: number;
        end_point: number;
    }
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case INIT:
            const { O_INIT } = action

            return {
                rows: O_INIT.rows
            }
        case CHANGE:
            const { O_CHANGE } = action

            return {
                rows: state.rows.map(c => {
                    return c.survey_ending_no === O_CHANGE.survey_ending_no ? {
                        ...c,
                        start_point: O_CHANGE.start_point,
                        end_point: O_CHANGE.end_point
                    } : c
                })
            }
            
        default:
            return state
    }
}