import { memo } from 'react'
import { Row1, Row2 } from 'styled/Survey/Base'
import { getMaxlength } from 'policy'
import Answer from 'styled/Custom/Answer'
import CheckboxComponent from './Checkbox'
import { useIntl } from 'react-intl'
import { InputRef } from '../01/Item'
import { isEqual } from 'lodash-es'

function Item(props) {
    const { 
        survey_module_no, 
        selected,
        comment,
        disabled, 
        answer, 
        is_comment, 
        is_comment_placeholder, 
        refComment,
        onChange 
    } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        <div key={`_02-${survey_module_no}`} className={`${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}>
            <Row1 
                disabled={disabled}
                onClick={(e) => onChange(e, survey_module_no, false, disabled)}
            >
                <div className="row1">
                    <CheckboxComponent 
                        selected={selected} 
                        disabled={disabled}
                        survey_module_no={survey_module_no}
                    />
                </div>
                <div className="row2">
                    <label htmlFor={`_02-label-${survey_module_no}`}>
                        <Answer>{answer ? answer : f({id: 'component.Survey.Modules.01.answer'})}</Answer>
                    </label>
                </div>
                <div className="selected" ></div>
            </Row1>
            {is_comment && (
                <Row2> 
                <InputRef
                    defaultValue={comment}
                    maxLength={getMaxlength('component.Survey.Modules.02.comment')}
                    disabled={!selected}  
                    placeholder={is_comment_placeholder}
                    ref={ref => refComment.current[survey_module_no] = ref}
                />
                </Row2>
            )} 
        </div>
        </>
    )
}

export default memo(Item, (prev, next) => {
    return  isEqual(prev.survey_module_no, next.survey_module_no) &&
            isEqual(prev.selected, next.selected) &&
            isEqual(prev.comment, next.comment) &&
            isEqual(prev.disabled, next.disabled) &&
            isEqual(prev.answer, next.answer) &&
            isEqual(prev.is_comment, next.is_comment) &&
            isEqual(prev.is_comment_placeholder, next.is_comment_placeholder) &&
            isEqual(prev.refComment, next.refComment) &&
            isEqual(prev.onChange, next.onChange)
})