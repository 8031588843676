/**
 * 한개씩 보여지는 컴포넌트
 */
import { useReducer, useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_SURVEY_QUESTIONS_MODULE_ALL_ONE } from 'gql/survey_question_modules'

// 초기값
const initialState = {
    questions: [],
    currentIndex: -1, // 현재 표기되는 문항
}

export const getCurrentIndex = (questions, currentSurveyQuestionNo) => {
    let index = ''
    
    for (let i=0; i<questions.length; i++) {
        if (Number(questions[i]._question.survey_question_no) === Number(currentSurveyQuestionNo)) {
            index = i
            break
        }
    }

    return Number(index)
}

const handleReducer = (state, action) => {
    switch (action.type) {
        case 'currentIndex': { // 현재 문항 인덱스
            return { ...state, currentIndex: action.currentIndex }
        }
        case 'reset': {
            return initialState
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}

let currentSurveyQuestionNo = -1

export default mode => WrappedComponent => props => {
    const { surveyAnalysisUserRow } = props

    if (surveyAnalysisUserRow) {
        currentSurveyQuestionNo = surveyAnalysisUserRow.survey_question_no
    }

    const params = useParams()
    const dispatch = useDispatch()

    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    const [ states, dispatchStates ] = useReducer(handleReducer, initialState)

    const { data, loading: loadingQuestions } = useQuery(GET_SURVEY_QUESTIONS_MODULE_ALL_ONE, {
        variables: { 
            survey_no,
            token: props.token,
            mode
        },
        onCompleted: (result) => {
            // 시작글 사용하고 currentSurveyQuestionNo 0일경우 시작글 보여준다
            // 시작글 사용안하고 currentSurveyQuestionNo 0일경우 첫번째문항보여준다
            // currentSurveyQuestionNo ? 0 보다 클경우 해당글보여준다

            if (currentSurveyQuestionNo > 0) {
                // currentSurveyQuestionNo index 순서 구하기
                const currentIndex = getCurrentIndex(result.surveyQuestionsModuleAllOne, currentSurveyQuestionNo)
                dispatchStates({type: 'currentIndex', currentIndex})
            }
            else if (currentSurveyQuestionNo === 0) dispatchStates({type: 'currentIndex', currentIndex: -1})
            //else if (props.start.used === true && currentSurveyQuestionNo === 0) dispatchStates({type: 'currentIndex', currentIndex: -1})
            //else if (props.start.used === false && currentSurveyQuestionNo === 0) dispatchStates({type: 'currentIndex', currentIndex: 0})
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const handleChangPage = useCallback((currentIndex) => {
        dispatchStates({ type: 'currentIndex', currentIndex })
    }, [])
    
    useEffect(() => {
        dispatchStates({type: 'reset'})
    }, [params.survey_no])

    if (!data) return null
    
    return <WrappedComponent 
            {...states}
            questions={data.surveyQuestionsModuleAllOne}
            onChangePage={handleChangPage}
            loadingQuestions={loadingQuestions}
            {...props}
        />
}