import moment from 'moment'

export const getMaxlength = (properties) => {
    const obj = {
        common: {
            email: 85,
            password: 15
        },
        component: {
            Application: {
                Webhook: {
                    CreateUrl: 2000,
                    CreateSecret: 100
                }
            },
            Image: {
                OutLink: 500
            },
            Survey: {
                Workspace: {
                    Left: {
                        Group: {
                            Add: {
                                title: 20
                            }
                        }
                    }
                },
                Edit: {
                    Question: {
                        question: 20000
                    },
                    '01': {
                        Example: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '02': {
                        Example: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '03': {
                        Column: 120,
                        Answer: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '04': {
                        Column: 120,
                        Answer: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '05': {
                        Label: 50,
                        Option: {
                            comment: 100
                        }
                    },
                    '06': {
                        Column: 120,
                        Label: 50,
                        Option: {
                            comment: 100
                        }
                    },
                    '07': {
                        Example: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '08': {
                        Column: 120,
                        Answer: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '09': {
                        TypeBase: {
                            min: 0,
                            max: 255
                        },
                        TypeNumber: {
                            min: -9999999,
                            max: 9999999,
                            unit: 5
                        }
                    },
                    '11': {
                        Answer: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '12': {
                        Example: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '13': {
                        Example: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '14': {
                        Example: 12,
                        Option: {
                            comment: 100
                        }
                    },
                    '15': {
                        Column: 120,
                        Answer: 12,
                        Option: {
                            comment: 100
                        }
                    },
                    '16': {
                        Example: 120,
                        Option: {
                            requiredMessage: 100
                        }
                    },
                    '17': {
                        Answer: 120,
                        Option: {
                            comment: 100
                        }
                    },
                    '21': {
                        Example: 120,
                        Enter: {
                            one: 205,
                            multi: 30
                        },
                        Option: {
                            comment: 100
                        }
                    },
                    '22': {
                        Column: 70,
                        Answer: 100,
                        Option: {
                            comment: 100
                        }
                    },
                    Confirm: 100
                },
                Modules: {
                    '01': {
                        comment: 1500
                    },
                    '02': {
                        comment: 1500
                    },
                    '12': {
                        comment: 1500
                    },
                    '13': {
                        comment: 1500
                    },
                    '20': {
                        comment: 350
                    },
                    Comment: {
                        comment: 1500
                    }
                },
                Create: {
                    Thanks: {
                        Edit: {
                            Question: {
                                message: 20000
                            },
                            ButtonStart: {
                                button_name: 50
                            },
                            ButtonEnding: {
                                button_name: 50,
                                button_link: 500,
                                analysis_name: 50
                            }
                        }
                    },
                    Design: {
                        Custom: {
                            Option: {
                                title: 20
                            }
                        }
                    },
                    Setting: {
                        Live: {
                            Component: {
                                label: 50
                            }
                        }
                    },
                    Publish: {
                        MessageInput: 250,
                        EndButton: {
                            end_button: 50,
                            end_button_link: 250
                        },
                        CutPeople: 100000
                    },
                    Analysis: {
                        Condition: {
                            Item: {
                                SubText: 50
                            }
                        }
                    },
                    Step: {
                        title: 50
                    },
                    Share: {
                        CustomArgs: {
                            args: 50
                        }
                    },
                    Menu: {
                        EndingsItemLabel: 20,
                        EndingsItemRedirect: {
                            Input: {
                                url: 500,
                                label: 100
                            }
                        },
                        ClassicNextButton: {
                            Input: 100
                        }
                    },
                    Logic: {
                        Jump: {
                            Lists: {
                                InputValue: 100
                            },
                            Point: {
                                InputAllPoint: {
                                    min: -99,
                                    max: 99
                                },
                                InputText: 100
                            }
                        }
                    }
                }
            },
            Payment: {
                Choice: {
                    CardInput: {
                        input: {
                            cardName: 20,
                            cardNumber: 19,
                            cardExpire: 7,
                            cardPwd: 2,
                            idNum: 12,
                            buyerEmail: 85
                        }
                    }
                },
                Receipt: {
                    TaxBox: {
                        input: {
                            id_no_company: 12,
                            company_name: 50,
                            name: 20,
                            email: 85,
                            id_no_personal: 13,
                            mobile_no: 11
                        }
                    }
                }
            }
        }
    }

    return properties.split('.').reduce((acc, c) => {
        return acc[c]
    }, obj) 
}


// 설문 공개 비공개 정책
export const getPolicyOpen = (props) => {
    const { open, end_date, end_date_used }  = props
    // 공개설정 true이고 종료날짜미사용일경우 공개이다. open: true, end_date_used: false => true
    // 공개설정이 true 이고 종료날짜 사용인데 종료날짜값이 현재시간보다 크거나 같다면 true, 나머지는 false
    
    return open && !end_date_used ? true : 
           open && end_date_used && moment(Number(end_date)) >= moment() ? true : false
}