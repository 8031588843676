import { useIntl } from 'react-intl'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SHOW as CONFIRMSHOW } from 'reducer/confirm'
import useTokenInfo from 'hooks/useTokenInfo'
import useChannelIO from 'hooks/useChannel'

type HandleAlertProps = (content?: string) => void;

// 로우데이터 다운로드 에러시 (파일 사이즈가 클경우)
function useRowsDownloadError() {
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()
    
    const { user_no, email } = useTokenInfo()
    const channelIO = useChannelIO({ user_no, email })

    const handleAlert: HandleAlertProps = useCallback((content) => {
        async function openChannel() {
            const ChannelIO = await channelIO()
            ChannelIO('showMessenger')
        }
    
        dispatch({ 
            type: CONFIRMSHOW,
            detectiveFullScreen: 'xs',
            title: f({id: 'hooks.useRowsDownloadError.title'}),
            subtitle: '',
            content: content || f({id: 'hooks.useRowsDownloadError.content'}),
            child: null,
            closeName: '',
            maxWidth: 'sm',
            onClose: '',
            onActions: [{ on: openChannel, name: f({id: 'hooks.useRowsDownloadError.button.action'}) }]
        })
    }, [dispatch, f, channelIO])
    


    return [ handleAlert ]
}

export default useRowsDownloadError