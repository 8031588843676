import { useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import oc from 'open-color'
import PropsContext from 'context/props'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withSurveyFetchData } from 'hoc'
import { SurveyWorkspaceLeftContainer, SurveyWorkspaceBodyContainer } from 'container'
import NothingWorkspaceContainer from './NothingWorkspace'

const defaultHeight = 'calc(100vh - 4.6em)'

const useStyles = makeStyles(theme => ({
    body: props => {
        const { openMobileLeft } = props

        // PC 일경우 defaultHeight
        // 모바일은 메뉴버튼잇는 헤더가 위에 생기므로 height를 더줄인다
        // 모바일 레프트 메뉴 open시 안보여준다
        
        return {
            position: 'relative',
            height: defaultHeight,
            background: oc.gray[0],
            overflowY: 'auto',
            display: openMobileLeft ? 'none' : 'grid',
            
            alignContent: 'start',

            [theme.breakpoints.down('sm')]: {
                height: 'calc(100vh - 9.16em)'
            },
            [theme.breakpoints.up('md')]: {
                display: 'grid'
            }
        }
    }
}))


function getStorageWorkspaceNo(workspaceRows) {
    if (workspaceRows.length === 0) return ''

    const storageWorkspaceNo = Number(localStorage.getItem('survey_workspace_no'))
    const is = workspaceRows.find(c => c.survey_workspace_no === storageWorkspaceNo)

    return  is ? storageWorkspaceNo : workspaceRows[0].survey_workspace_no
}

const Component = (props) => {
    const { survey_workspace_group_no, propsFetchData } = props
    const { surveyWorkspacesInGroup: workspaceRows, refetchSurveyWorkspacesInGroup: refetchSurveyWorkspaces } = propsFetchData

    const dispatch = useDispatch()

    /**
     * 1. 모바일 (sm) 에서는 left menu가 가려진상태이다.
     * 2. 메뉴 open 시 리스트가 보이면, Body component는 안보여지게 처리한다
     */
    const [ openMobileLeft, setOpenMobileLeft ] = useState(false)

    /* 선태된 워크스페이스 첨에는 기본 첫번째이이고 워크스페이스 추가하면 추가한 스페이스의 survey_workspace_no 이다
    ** 세션스토리지로 선택된 값을 저장하여 새로고침이나 설문이동후 다시 돌아올때 디폴트로 보여준다 
    */
  
    const [ selected, setSelected ] = useState(getStorageWorkspaceNo(workspaceRows)) 

    const classes = useStyles({ openMobileLeft })

    const changeOpenMobileLeft = useCallback(() => {
        setOpenMobileLeft(prevState => !prevState)
    }, [])

    const changeSelected = useCallback(async(survey_workspace_no) => {
        setSelected(survey_workspace_no)
        localStorage.setItem('survey_workspace_no', survey_workspace_no)
        setOpenMobileLeft(false)
    }, [])


    useEffect(() => {
        setSelected(getStorageWorkspaceNo(workspaceRows))
        dispatch({type: BACKDROPHIDE})
    }, [dispatch, workspaceRows])


    return (

        <Grid container>
            <Grid item xs={12} sm={12} md={3} lg={2}>
                {
                    useMemo(() => {
                        return (
                            <PropsContext.Provider value={{
                                openMobileLeft,
                                survey_workspace_group_no,
                                selected,
                                onChangeOpenMobileLeft: changeOpenMobileLeft,
                                onChangeSelected: changeSelected,
                                workspaceRows,
                                refetchSurveyWorkspaces
                            }}>
                                <SurveyWorkspaceLeftContainer defaultHeight={defaultHeight}/>
                            </PropsContext.Provider> 
                        )
                    }, [openMobileLeft, survey_workspace_group_no, selected, changeOpenMobileLeft, changeSelected, workspaceRows, refetchSurveyWorkspaces])
                }
                
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={10} className={classes.body}>
                {
                    !selected ?  (
                        <PropsContext.Provider value={{
                            openMobileLeft,
                            survey_workspace_group_no,
                            selected,
                            onChangeOpenMobileLeft: changeOpenMobileLeft,
                            onChangeSelected: changeSelected,
                            workspaceRows,
                            refetchSurveyWorkspaces
                        }}>
                            <NothingWorkspaceContainer/>
                        </PropsContext.Provider> 
                    ) : (
                        <SurveyWorkspaceBodyContainer 
                            workspaceRows={workspaceRows}
                            selected={selected} 
                            onChangeSelected={changeSelected}
                            refetchSurveyWorkspaces={refetchSurveyWorkspaces}
                        />
                    )
                }
            </Grid>
        </Grid>
    )
}

export default withSurveyFetchData()({surveyWorkspacesInGroup: true})(true)(Component)