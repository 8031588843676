import { ComponentType, useState } from 'react'
import { useQuery } from '@apollo/client'
import randomString from 'randomstring'
import { parseGraphqlError } from 'utils'
import { GET_SURVEY_ANALYSIS_SUMMARY, SurveyAnalysisSummaryProps, SurveyAnalysisSummaryVariables, Mode } from 'gql/survey_analysis_summary'

export type SummaryData = {
    rows:  SurveyAnalysisSummaryProps[]; 
    response: number;
    loading: boolean; 
    isError: boolean;
    refetch: () => void;
}

type QueryData = {
    instance: SummaryData['rows'];
    response: SummaryData['response'];
    key: string | null;
}

const key: { [a: number ]: string } = {}

export const getKey = (survey_no: number) => {
    if (!key[survey_no]) {
        const value = randomString.generate(10)
        key[survey_no] = value
    }

    return key[survey_no]
}

export default (mode: SurveyAnalysisSummaryVariables['mode']) => (isLoading: boolean) => (WrappedComponent: ComponentType<{summaryData: SummaryData}>) => (props: Omit<SurveyAnalysisSummaryVariables, 'mode'>) => {
    const { survey_no, input } = props
    const { conditionsPlay, step, date, search, viewMode, target_survey_analysis_users_no } = input

    // 에러일때 true
    const [ isError, setIsError ] = useState(false)

    const { data, loading, refetch } = useQuery<{surveyAnalysisSummary: QueryData}, SurveyAnalysisSummaryVariables>(GET_SURVEY_ANALYSIS_SUMMARY, {
        fetchPolicy: (Mode.Edit === mode ? 'network-only' : 'cache-first'),
        notifyOnNetworkStatusChange: true,
        variables: {
            survey_no, 
            mode,
            input: {
                conditionsPlay, 
                step: Number(step),
                date, 
                search, 
                viewMode, 
                target_survey_analysis_users_no
            },
            key: getKey(survey_no)
        },
        onCompleted: (data) => {
            const { key: newKey } = data.surveyAnalysisSummary
            
            // 새로운 키값이 있다면 변경한다. 레포트 코멘트 더보기일때 필요하다 (인스턴스 값이 저장됐을경우)
            if (newKey) key[survey_no] = newKey

            if (isError) setIsError(false)
        },
        onError: (e) => {
            if (parseGraphqlError(e) === 'limit') {
                setIsError(true)
            }
        }
    })

    /*
    ** 로딩이 아닐때 처리
    ** 데이터값이 없고, 에러도 아니라면 (데이터 값이 있어도 에러라면 안내메시지 보여줘여해서리..)
    ** 로딩일때는 로딩이미지를 보여줄거라 패스한다.
    */

    if (!loading) {
        if (!data && !isError) return null
    }

    return (
        <WrappedComponent 
            {...props} 
            summaryData={{
                rows: data ? data.surveyAnalysisSummary.instance : [], 
                response: data ? data.surveyAnalysisSummary.response : 0,  
                loading, 
                isError,
                refetch
            }}
        />
    )
}