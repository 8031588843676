import gql from 'graphql-tag'

export const GET_USER_PAYSET_IN_USER = gql`
    query UserPaysetInUser {
        userPaysetInUser {
            user_payset_no     
            product_name
            content    
            price      
        }
    }
`

export const GET_USER_PAYSET_ONE = gql`
    query UserPaysetOne($user_payset_no: Int!) {
        userPaysetOne(user_payset_no: $user_payset_no) {
            product_name
            content       
        }
    }
`

export interface UserPaysetInUserProps {
    user_payset_no: number;
    product_name: string;
    content: string;
    price: number;
}

export interface UserPaysetOne {
    user_payset_no: UserPaysetInUserProps['user_payset_no'];
    product_name: UserPaysetInUserProps['product_name'];
}