import { useState, useCallback, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { UPDATE_OTP_USED, OtpProps } from 'gql/otp'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { postMessage } from 'utils'
import oc from 'open-color'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import { ListItem, ListItemAvatar, ListItemSecondaryAction, Avatar, Switch } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import withUserFetchData from 'hoc/User/FetchData'
import useUpdateToken from 'hooks/useUpdateToken'
import Tooltip from 'styled/Custom/Tooltip'
import { ListItemText, useCommonStyles } from '../Component'
import OtpContentComponent from './OtpContent'
import OtpQrModalComponent from './OtpQrModal'

export type Open = boolean
export type OnClose = () => void

interface Props {
    goal: boolean;
    propsFetchData: {
        otp: OtpProps | null
        refetchOtp: () => void;
    }
}

function OtpComponent(props: Props) {
    const { goal, propsFetchData } = props
    const { otp, refetchOtp } = propsFetchData
    const { qrcode, approve, used } = otp || { qrcode: '', used: false }
    const commonClasses = useCommonStyles()

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const updateToken = useUpdateToken()

    const [ open, setOpen ] = useState(false)

    const [ updateUsed ] = useMutation(UPDATE_OTP_USED, {
        onCompleted: () => {
            refetchOtp()
            updateToken()
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { checked } = e.target

        if (!checked) {
            updateUsed({
                variables: {
                    used: false
                }
            })

            return
        }

        // 등록이 완료된 qrcode를 껏다가 다시 키면 바로 켜준다
        if (checked && approve) {
            updateUsed({
                variables: {
                    used: true
                }
            })

                        
            // 아이콘 클릭 하고 들어오면 흰색배경으로 다시 바꿔주기
            if (goal) postMessage({ push: '#code=info' })

            return
        }
        
        setOpen(true)
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    // 계정보호 클릭으로 들어왓다면 강조를 위해
    let avatarStyle = {}
    let primaryStyle = {}

    if (goal) {
        avatarStyle = { color: oc.red[9] }
        primaryStyle = { color: 'white' }
    }

    return (
        <>
        <ListItem>
            <ListItemAvatar className={commonClasses.icon}>
                <Avatar><VerifiedUserIcon style={avatarStyle}/></Avatar>
            </ListItemAvatar>
            <ListItemText 
                style={primaryStyle}
                primary={
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span>{f({id: 'component.MyAccount.Info.Otp.primary'})}</span>
                        <Tooltip isMobileClick={true} title={f({id: 'component.MyAccount.Info.Otp.tooltip'})}>
                            <HelpIcon 
                                style={{
                                    display: 'block', 
                                    marginBottom: 2, 
                                    marginLeft: 2, 
                                    ...primaryStyle
                                    }} 
                                    fontSize="small" 
                                    color="action"
                                />
                        </Tooltip>
                    </div>
                } 
            />
            <ListItemSecondaryAction>
                <Switch checked={used} onChange={handleChange}/>
            </ListItemSecondaryAction>
        </ListItem>
        <OtpContentComponent qrcode={qrcode} used={used}/>
        <OtpQrModalComponent open={open} qrcode={qrcode} goal={goal} refetch={refetchOtp} onClose={handleClose}/>
        </>
    )
}

export default withUserFetchData({ otp: true })(false)(OtpComponent)