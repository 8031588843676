import { memo, useCallback } from 'react'
import { InputAdornment } from '@material-ui/core'
import { replaceNotNumber, decimalFormat } from 'utils'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import Input from 'styled/Custom/Input'
import useValue from './useValue'
import { getIsDisabled } from './Base'
import ConfirmComponent from './Confirm'

function NumberComponent(props) {
    const { survey_question_no, min_number, max_number, badge, number_format, classes, unit, unit_position, minus, decimald, refComment, isConfirm, originAnswered, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ value, onChangeValue ] = useValue(originAnswered, onChange)

    // 순수 숫자
    const handleChange = useCallback(() => {

        let val = replaceNotNumber(refComment.current[survey_question_no].value, minus, decimald)
        val = val === 0 ? '' : val
        refComment.current[survey_question_no].value = val

        return val

    }, [survey_question_no, refComment, minus, decimald])
    
     // number format
     const handleChangeNumberFormat = useCallback(() => {

        let val = decimalFormat(replaceNotNumber(refComment.current[survey_question_no].value, minus, decimald))
      
        val = val === 0 ? '' : val
       
        refComment.current[survey_question_no].value = val

        return val

    }, [survey_question_no, refComment, minus, decimald])

    let inputMode = 'numeric'
    if (decimald) inputMode = 'decimal'
    if (minus) inputMode = 'text'

    // 도움말 표기
    let helper = ''
    if (badge) helper = f({id: 'component.Survey.Modules.09.number.helper'}, {min_number: decimalFormat(min_number), max_number: decimalFormat(max_number)})

    return (
        <>
        <Input
            name="number"
            inputRef={ref => refComment.current[survey_question_no] = ref}
            value={value}
            onChange={(e) => {
                if (number_format) onChangeValue(handleChangeNumberFormat())
                else onChangeValue(handleChange())
            }}
            inputProps={{min: min_number, max: max_number, inputMode}} 

            startAdornment={(!unit_position && unit) && (
                <InputAdornment position="start" className={classes.icon}><span className={classes.icon}>{unit}</span></InputAdornment>
            )}

            endAdornment={(unit_position && unit) && (
                <InputAdornment position="end" className={classes.icon}><span className={classes.icon}>{unit}</span></InputAdornment>
            )}

            helper={helper}
        />
        {
            isConfirm && (
                <ConfirmComponent survey_question_no={survey_question_no} value={value} isEqualAnswer={getIsDisabled(value, originAnswered)}/>
            )
        }
        </>
    )

}

export default memo(NumberComponent, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.isConfirm, next.isConfirm) && 
            isEqual(prev.originAnswered, next.originAnswered) && 
            isEqual(prev.min_number, next.min_number) && 
            isEqual(prev.max_number, next.max_number) &&
            isEqual(prev.badge, next.badge) && 
            isEqual(prev.number_format, next.number_format) && 
            isEqual(prev.classes, next.classes) && 
            isEqual(prev.unit, next.unit) && 
            isEqual(prev.unit_position, next.unit_position) && 
            isEqual(prev.minus, next.minus) && 
            isEqual(prev.decimald, next.decimald) && 
            isEqual(prev.refComment, next.refComment) && 
            isEqual(prev.onChange, next.onChange)
})