/*
** 기존 세금계산서 값이 있다면, 해당값을 아니면 recent 값을 리턴
*/
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { GET_USER_TAX_RECENT } from 'gql/user_tax'
import { UserTaxRecentProps } from 'types/user_tax'
import { UserPaymentNonApplyProps } from 'types/user_payment'
import { TaxType } from 'ts-policy'

interface StateProps {
    c_id_no: string;
    c_name: string;
    c_email: string;
    c_company_name: string;
    p_id_no: string;
    p_name: string;
    p_email: string;
    p_mobile_no: string;
}

function useUserTaxDefaultValue(user_tax: UserPaymentNonApplyProps['user_tax'] | null) {
    const dispatch = useDispatch()

    const [ state, setState ] = useState<StateProps | null>(null)
    const { data } = useQuery<UserTaxRecentProps>(GET_USER_TAX_RECENT, {
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    useEffect(() => {
        if (data) {
            const { userTaxRecent } = data
            const { company, personal } = userTaxRecent
            const { id_no: c_id_no, name: c_name, email: c_email, company_name: c_company_name } = company
            const { id_no: p_id_no, name: p_name, email: p_email, mobile_no: p_mobile_no } = personal
            
            if (!user_tax) {
                setState({
                    c_id_no, c_name, c_email, c_company_name,
                    p_id_no, p_name, p_email, p_mobile_no
                })

                return
            }
    
            const { tax_type, id_no, name, email, company_name, mobile_no } = user_tax
            
            if (tax_type === TaxType.Company) {
                setState({
                    c_id_no: id_no, c_name: name, c_email: email, c_company_name: company_name || '',
                    p_id_no, p_name, p_email, p_mobile_no
                })

                return
            }
    
            if (tax_type === TaxType.Personal) {
                setState({
                    c_id_no, c_name, c_email, c_company_name,
                    p_id_no: id_no, p_name: name, p_email: email, p_mobile_no: mobile_no || ''
                })

                return
            }
    
            setState({
                c_id_no, c_name, c_email, c_company_name,
                p_id_no, p_name, p_email, p_mobile_no
            })
        }
    }, [data, user_tax])

    return state
}

export default useUserTaxDefaultValue