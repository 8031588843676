import gql from 'graphql-tag'

export const ADMIN_ING = gql`
    mutation AdminIng {
        adminIng
    }
`

// admin에서 알람을 뿌리면 사용자페이지에서 감지하다가 이벤트 작동
export const SUBSCRIPTION_ADMIN_TAX_ALARM = gql`
    subscription AdminTaxAlarm($token: ID!) {
        adminTaxAlarm(token: $token)
    }
`