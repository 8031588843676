import gql from 'graphql-tag'
import { schemaSurveyMboxDesign, SurveyMboxDesignProps } from './survey_mbox_design'
import { Method } from './survey_mbox'

export const GET_SURVEY_TEMPLATE_CATEGORY = gql`
        query SurveyTemplateCategory {
        surveyTemplateCategory {
            survey_template_category_no
            name
            indexs
            open
        }
    }
`

export const GET_SURVEY_MBOX_IN_TEMPLATE_LISTS = gql`
    query SurveyMboxInTemplateLists {
        surveyMboxInTemplateLists {
            survey_template_category_no
            survey_no
            open
            survey_mbox {
                title
                method
            }
            survey_mbox_design {
                ${schemaSurveyMboxDesign}
            }
        }
    }
`

export const CREATE_SURVEY_TEMPLATE_CATEGORY = gql`
    mutation CreateSurveyTemplateCategory($name: String!) {
        createSurveyTemplateCategory(name: $name)
    }
`


export const UPDATE_SURVEY_TEMPLATE_CATEGORY_OPEN = gql`
    mutation UpdateSurveyTemplateCategoryOpen($survey_template_category_no: Int!, $open: Boolean!) {
        updateSurveyTemplateCategoryOpen(survey_template_category_no: $survey_template_category_no, open: $open)
    }
`

export const UPDATE_SURVEY_TEMPLATE_CATEGORY_NAME = gql`
    mutation UpdateSurveyTemplateCategoryName($survey_template_category_no: Int!, $name: String!) {
        updateSurveyTemplateCategoryName(survey_template_category_no: $survey_template_category_no, name: $name)
    }
`

export const UPDATE_SURVEY_TEMPLATE_CATEGORY_SORT = gql`
    mutation UpdateSurveyTemplateCategorySort($survey_template_category_nos: [Int!]!) {
        updateSurveyTemplateCategorySort(survey_template_category_nos: $survey_template_category_nos)
    }
`

export const DELETE_SURVEY_TEMPLATE_CATEGORY = gql`
    mutation DeleteSurveyTemplateCategory($survey_template_category_no: Int!) {
        deleteSurveyTemplateCategory(survey_template_category_no: $survey_template_category_no)
    }
`

export const UPDATE_SURVEY_TEMPLATE_LIST_CATEGORY_IN_MBOX = gql`
    mutation UpdateSurveyTemplateListCategoryInMbox($survey_no: Int!, $survey_template_category_no: Int!) {
        updateSurveyTemplateListCategoryInMbox(survey_no: $survey_no, survey_template_category_no: $survey_template_category_no)
    }
`

export const UPDATE_SURVEY_TEMPLATE_LIST_OPEN_IN_MBOX = gql`
    mutation UpdateSurveyTemplateListOpenInMbox($survey_no: Int!, $open: Boolean!) {
        updateSurveyTemplateListOpenInMbox(survey_no: $survey_no, open: $open)
    }
`

export interface SurveyTemplateCategoryProps {
    survey_template_category_no: number
    name: string
    indexs: number
    open: boolean
}

export interface SurveyMboxInTemplateListsProps {
    survey_template_category_no: number
    survey_no: number
    open: boolean
    survey_mbox: {
        title: string
        method: Method
    }
    survey_mbox_design: SurveyMboxDesignProps
}


export interface CreateSurveyTemplateCategoryVariables {
    name: string
}

export interface DeleteSurveyTemplateCategoryVariables {
    survey_template_category_no: number
}

export interface UpdateSurveyTemplateCategoryOpenVariables {
    survey_template_category_no: number
    open: boolean
}

export interface UpdateSurveyTemplateCategoryNameVariables {
    survey_template_category_no: number
    name: string
}

export interface UpdateSurveyTemplateListCategoryInMboxVariables {
    survey_no: number
    survey_template_category_no: number
}

export interface UpdateSurveyTemplateListOpenInMboxVariables {
    survey_no: number
    open: boolean
}