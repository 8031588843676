import gql from 'graphql-tag'

export const CREATE_FILE = gql`
    mutation File($files: [FileUpload!]!) {
        createRecvFile(files: $files) {
            recv_file_no
            name
        }
    }
`

export const CREATE_FILE_S3 = gql`
    mutation CreateRecevFileS3($input: Input!) {
        createRecvFileS3(input: $input) {
            recv_file_no
            name
        }
    }
`

export const DELETE_FILE = gql`
    mutation File($recv_file_nos: [Int!]!) {
        deleteRecvFile(recv_file_nos: $recv_file_nos)
    }
`

export const DELETE_FILE_S3 = gql`
    mutation DeleteRecvFileS3($recv_file_no: Int!) {
        deleteRecvFileS3(recv_file_no: $recv_file_no)
    }
`

export const GET_FILE = gql`
    query File($offset: Int!, $limit: Int!, $search: String!, $type: Int!) {
        recvFile(offset: $offset, limit: $limit, search: $search, type: $type) {
            rows {
                recv_file_no
                src       
                name
                size        
                type       
                wdate    
            }
            count
        }
    }
`

export const GET_FILE_USED = gql`
    query RecvFileUsed {
        recvFileUsed
    }
`

export const GET_OVERLAP_FILE = gql`
    mutation GetOverlapFile($filenames: [String!]!) {
        getOverlapFile(filenames: $filenames) 
    }
`
