import Dialog from 'styled/Survey/Live/Dialog'
import CoverContaner from './Cover'

const Component = (props) => {
    const { open, onClose, ...other } = props

    return (
        <Dialog open={open} onClose={onClose}>
            { open ? <CoverContaner open={open} onClose={onClose} {...other}/>: null }
        </Dialog>
    )
}

export default Component