/**
 * mbox option design 한번에 가져오기
 */
import { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { GET_SURVEY_MBOX_WITH_RELATION } from 'gql/survey_mbox'

function formatData(data) {
    const mbox = {...data }
    delete mbox.survey_mbox_option
    delete mbox.survey_mbox_design
    delete mbox.survey_start

    const option = { ...data.survey_mbox_option }
    const design = { ...data.survey_mbox_design }
    const start = { ...data.survey_start }

    return { mbox, option, design, start }
}

export default args => isLoading => WrappedComponent => props => {
    const params = useParams()
    const dispatch = useDispatch()

    const { mode, fetchPolicy='network-only' } = args
    
    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    if (!survey_no) return null
    
    const { data, loading, refetch } = useQuery(GET_SURVEY_MBOX_WITH_RELATION, {
        variables: { survey_no, mode },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy
    })

    useEffect(() => {
        if (isLoading && loading) dispatch({type: BACKDROPSHOW})
    }, [loading, dispatch])
    
    if (!data) return null
    
    return (
        <WrappedComponent 
            {...formatData(data.surveyMboxWithRelation)}
            refetchMboxWithRelation={refetch}
            {...props}
        />
    )
}