import { withStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8'
    },
    indicator: {
        backgroundColor: '#1890ff'
    }
})((props) => <Tabs variant="scrollable" {...props}/>)

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover': {
            color: '#40a9ff',
            opacity: 1
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff'
        },
        minWidth: 30,
        marginRight: 20
    },
    selected: {}
}))((props) => <Tab disableRipple {...props} />)


export {
    AntTabs,
    AntTab
}