import { useState } from 'react'
import { injectIntl, useIntl } from 'react-intl'
import moment from 'moment'
import { setSearchCookie } from 'ts-utils/summary'
import { getLocale } from 'ts-utils'
import oc from 'open-color'
import { DialogActions, Box, Select, MenuItem, Divider, FormControl, InputLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils  from '@date-io/date-fns'
import styled from 'styled-components'
import { withBreakPoints } from 'hoc'
import { StyledDialog, StyledDialogContent, StyledButton } from 'styled/Modal'

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    --gap: 1.2em;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    & > * {
        margin: var(--gap) 0 0 var(--gap);
    }

    
    background: ${oc.gray[0]};
`

const CalendarBox = withStyles(theme => ({
    root: {
        display: 'table',
        border: `1px solid ${oc.gray[3]}`,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))(Box)

export function getDate(date) {
    let s = null // 시작날짜
    let e = null // 완료날짜
    let x = null // 기준 타임스탬프

    const today = moment().format('YYYY-MM-DD')

    switch(date) {
        case 'all':
            return {
                sdate: '',
                edate: ''
            }
        case 'today':
            s = moment().format('YYYY-MM-DD')
            
            return {
                sdate: s,
                edate: s
            }
        case 'thisWeekend':
            x = moment().startOf('week').valueOf()
            s = moment(x).format('YYYY-MM-DD')
            e = moment(x).add(7, 'days').format('YYYY-MM-DD')

            return {
                sdate: s,
                edate: e > today ? today : e
            }

        case 'thisMonth':
            s = moment().format('YYYY-MM-01')
            e = moment().format(`YYYY-MM-${moment().daysInMonth()}`)
        
            return {
                sdate: s,
                edate: e > today ? today : e
            }
        case 'yesterday':
            s = moment().subtract(1, 'days').format('YYYY-MM-DD')
            
            return {
                sdate: s,
                edate: s
            }
        case 'lastWeekend':
            x = moment().startOf('week').valueOf()
            s = moment(x).subtract(7, 'days').format('YYYY-MM-DD')
            e = moment(x).subtract(1, 'days').format('YYYY-MM-DD')

            return {
                sdate: s,
                edate: e
            }
        case 'last7Days':
            // 최근 7일
            s = moment().subtract(7, 'days').format('YYYY-MM-DD')
            e = moment().subtract(1, 'days').format('YYYY-MM-DD')
            return {
                sdate: s,
                edate: e
            }
        case 'last14Days':
            // 최근 7일
            s = moment().subtract(14, 'days').format('YYYY-MM-DD')
            e = moment().subtract(1, 'days').format('YYYY-MM-DD')
            return {
                sdate: s,
                edate: e
            }
        case 'lastMonth': 
            x = moment().subtract(1, 'month')
            s = x.format('YYYY-MM-01')
            e = x.format(`YYYY-MM-${x.daysInMonth()}`)
            return {
                sdate: s,
                edate: e
            }
       
        default:
            const [ sdate, edate ] = date ? date.split(' ~ ') : ['', '']
            return {
                sdate,
                edate
            }
    }
}

const Component = props => {
    const { cookieName, date, open, onClose, onDo, breakpoints, intl } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const defaultDate = date || 'today'

    const parseDate = getDate(defaultDate)

    const today = moment().format('YYYY-MM-DD')

    const [ _sdate, setSdate ] = useState(parseDate.sdate)
    const [ _edate, setEdate ] = useState(parseDate.edate)
    const [ value, setValue ]  = useState(defaultDate) // today last7Days, lastMonth, thisMonth, '', 2012-12-12 ~ 2013-12-12

    // 날짜 직접 설정 
    function handleChangeStartDate(newDate) {
        const date = moment(newDate).format('YYYY-MM-DD')
        setSdate(date)
        setValue(`${date} ~ ${_edate}`)
    }

    function handleChangeEndDate(newDate) {
        const date = moment(newDate).format('YYYY-MM-DD')
        setEdate(date)
        setValue(`${_sdate} ~ ${date}`)
    }

    // 버튼 클릭(오늘, 어제 등..)
    function handleChangeQuickDate(kind) {
        const { sdate, edate } = getDate(kind)
        setSdate(sdate)
        setEdate(edate)
        setValue(kind)
    }

    function handleSave() {
        if (cookieName) {
            setSearchCookie(cookieName, value)
        }

        onDo(value)
    }

    let disabled = false
    // 시작검색이 크다면 이상한거다
    if (_sdate > _edate) disabled = true

    return (
        <>
        <StyledDialog
            open={open}
            onClose={onClose}
            scroll={'paper'}
            fullWidth={false}
            maxWidth="md"
            fullScreen={xs}
        >
          
            <StyledDialogContent style={{background: oc.gray[0]}}>
                <FormControl variant="filled">
                    <InputLabel>{f({id: 'styled.Survey.Analysis.Calendar.quick.label'})}</InputLabel>
                    <Select 
                        value={value.indexOf("~") > -1 ? "" : value} 
                        style={{ width: 140,marginBottom: 3}}
                        onChange={(e) => handleChangeQuickDate(e.target.value)}
                    >   
                        <MenuItem value="today">{f({id: 'styled.Survey.Analysis.Calendar.quick.item.today'})}</MenuItem>
                        <MenuItem value="thisWeekend">{f({id: 'styled.Survey.Analysis.Calendar.quick.item.thisWeekend'})}</MenuItem>
                        <MenuItem value="thisMonth">{f({id: 'styled.Survey.Analysis.Calendar.quick.item.thisMonth'})}</MenuItem>
                        <Divider/>
                        <MenuItem value="yesterday">{f({id: 'styled.Survey.Analysis.Calendar.quick.item.yesterday'})}</MenuItem>
                        <MenuItem value="lastWeekend">{f({id: 'styled.Survey.Analysis.Calendar.quick.item.lastWeekend'})}</MenuItem>
                        <MenuItem value="last7Days">{f({id: 'styled.Survey.Analysis.Calendar.quick.item.last7Days'})}</MenuItem>
                        <MenuItem value="last14Days">{f({id: 'styled.Survey.Analysis.Calendar.quick.item.last14Days'})}</MenuItem>
                        <MenuItem value="lastMonth">{f({id: 'styled.Survey.Analysis.Calendar.quick.item.lastMonth'})}</MenuItem>
                    </Select>
                </FormControl>

                <WrapperStyled>
                    <CalendarBox>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(intl.locale)}>
                            <DatePicker 
                                variant="static" 
                                openTo="date"
                                value={_sdate} 
                                maxDate={today}
                                onChange={date => handleChangeStartDate(date)}
                            />                    
                        </MuiPickersUtilsProvider>
                    </CalendarBox>
                    <CalendarBox>

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(intl.locale)}>
                            <DatePicker 
                                variant="static" 
                                openTo="date" 
                                value={_edate} 
                                maxDate={today}
                                onChange={date => handleChangeEndDate(date)}
                            />                    
                        </MuiPickersUtilsProvider>
                    </CalendarBox>
                </WrapperStyled>
            </StyledDialogContent>
            
            <DialogActions>
                <StyledButton disabled={disabled} onClick={handleSave} color="primary">
                    {f({id: 'styled.Survey.Analysis.Calendar.button.0'})}
                </StyledButton>
                <StyledButton onClick={onClose} color="default">
                    {f({id: 'styled.Survey.Analysis.Calendar.button.1'})}
                </StyledButton>
            </DialogActions>
        </StyledDialog>
        </>
    )
}

export default withBreakPoints(injectIntl(Component))