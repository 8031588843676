import gql from 'graphql-tag'

export const CREATE_USER_ADMIN_ALARM_COOKIE = gql`
    mutation CreateUserAdminAlarmCookie($admin_alarm_no: Int!) {
        createUserAdminAlarmCookie(admin_alarm_no: $admin_alarm_no)
    }
`

export const GET_USER_ADMIN_ALARM = gql`
    query userAdminAlarm {
        userAdminAlarm {
            admin_alarm_no
            message
            link
        }
    }
`

export interface CreareUserAdminAlarmCookieVariables {
    admin_alarm_no: number
}

export interface UserAdminAlarmProps {
    admin_alarm_no: number
    message: string
    link: string | null
}
