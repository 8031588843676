import { cookies, getTimezoneLang } from 'utils'
export const CHANGE = 'lang/CHANGE'

const { lang: _lang } = getTimezoneLang()

function setLang(lang) {
    cookies.set({value: `NEXT_LOCALE=${lang}`, expires: 365})
}

if (!cookies.get('NEXT_LOCALE')) {
    setLang(_lang)
}

const initState = {
    lang: cookies.get('NEXT_LOCALE')
}

export default (state = initState, action) => {
    switch(action.type) {
        case CHANGE:
            setLang(action.lang)
            return {
                lang: action.lang
            }
        default:
            return state
    }
}