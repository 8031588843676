import { _05Module, _06Answer, _07Module, _08Answer, _05Option } from 'gql/survey_question_modules'
import { v4 as uuidv4 } from 'uuid'

type Type = 'one' | 'matrix'
type Rows = _05Module[] | _06Answer[]

interface Props {
    survey_question_no: number
    data: {
        _modules: _05Module[] | _07Module[]
        _answers: _06Answer[] | _08Answer[]
    }

    type: Type
    reverse?: _05Option['reverse']
    zero_number?: _05Option['zero_number']
}

interface CalcuProps extends Omit<Props, 'data'>  {
    states: any;
    guideLen: number;
}

interface MatchProps {
    newRows: _05Module[] | _06Answer[];
    newReverse: Props['reverse'];
    oringinRows: _05Module[] | _06Answer[];
    originReverse: Props['reverse']; 
    type: Type;
}

export class Scale implements Props {
    survey_question_no: Props['survey_question_no']
    data: Props['data']
    rows: Rows
    type: Props['type']
    reverse: Props['reverse']
    zero_number: Props['zero_number']
    
    constructor(props: Props) {
        const { survey_question_no, data, type, reverse, zero_number } = props
        this.survey_question_no = survey_question_no
        this.rows  = type === 'one' ? data._modules : data._answers
        this.type = type
        this.reverse = typeof reverse === 'boolean' ? reverse : false
        this.zero_number = typeof zero_number === 'boolean' ? zero_number : false
    }

    // 타입에 따른 값
    getTypeValue() {
        let key = '_modules'
        let column = 'survey_module_no'

        if (this.type === 'matrix') {
            key = '_answers'
            column = 'survey_module_answer_no'
        }

        return { key, column }
    }

    // type: one: 단수형, multi: 매트릭스형 
    addAnswer() {
        const { column } = this.getTypeValue()
        
        let count = this.rows.length
        let indexs = 0

        if (!this.zero_number) {
            count = count + 1
            indexs = 1
        }

        if (this.reverse) {
            const rows = this.rows.map((c, i) => {
                return {
                    ...c,
                    indexs: count - i
                }
            }) 

            return [
                ...rows,
                {
                    survey_question_no: this.survey_question_no, 
                    [column]: uuidv4(), 
                    answer: '', 
                    indexs, 
                    point: 1
                }
            ]
        } else { 
            const rows = this.rows.map((c, i) => {
                return {
                    ...c,
                    indexs: indexs++
                }
            })

            return [
                ...rows,
                {
                    survey_question_no: this.survey_question_no, 
                    [column]: uuidv4(), 
                    answer: '', 
                    indexs: count, 
                    point: 1
                }
            ]
        }
    }
    
    removeAnswer(id: number) {
        // 삭제하는 row가 아니면 true
        function isLiveRow(row: any, column: string) {
            if (typeof row[column] !== 'undefined') {
                if (row[column] !== id) return true
            } 

            return false
        }

        const { column } = this.getTypeValue()  

        const rows = this.rows.filter((row: any) => isLiveRow(row, column))

        if (this.reverse) {
            // 삭제 처리 후 최종 길이
            const len = rows.length
            let startIndexs = this.zero_number ? len  - 1 : len

            return rows.map((row: any) => ({
                ...row,
                indexs: startIndexs--
            }))
        } 
        
        let startIndexs = this.zero_number ? 0 : 1

        return rows.map((row: any) => ({
            ...row,
            indexs: startIndexs++
        }))
    }   

    getReverseRows() {
        const rows = [ ...this.rows ]

        // zero_number 에 따라 첫번째 인덱스 값이 다르다
        const firstIndexs = this.zero_number ? 0 : 1

        if ((this.reverse && this.rows[0].indexs === firstIndexs) || (!this.reverse && this.rows[0].indexs !== firstIndexs)) {
            rows.reverse()
        }

        return rows
    }

    getZeroNumberRows() {
        let newRows = [...this.rows]

        if (!this.zero_number && newRows.length >= 11) {
            newRows = newRows.filter((c, i) => i <= 10)
        }

        if (this.reverse) {

            const count = newRows.length

            // 역순이면서 zero_number가 포함되면 -1 이다 로우갯수가 3개일때, 2 1 0 이렇게 된다.
            let startIndexs = this.zero_number ? count - 1 : count

            return newRows.map(row => ({
                ...row,
                indexs: startIndexs--
            }))
        } else {
            let startIndexs = this.zero_number ? 0 : 1

            return newRows.map(row => ({
                ...row,
                indexs: startIndexs++
            }))
        }
    }

}


// 가로 척도형 더하기
export const getScalePlusRows = (props: CalcuProps) => {
    const { survey_question_no, states, reverse, zero_number, type, guideLen } = props

    // 정순, 0부터 시작은 디폴트로 설정해준다
    const scale = new Scale({ 
        survey_question_no, 
        data: states.row, 
        reverse,
        zero_number,
        type
    })

    const rows = scale.getZeroNumberRows()
    const { key } = scale.getTypeValue()
    const len = guideLen - rows.length

    let newStatesRow = { 
        ...states.row,
        [key]: rows
    }

    for (let i=0; i<len; i++) {

        const scale = new Scale({ 
            survey_question_no, 
            data: newStatesRow, 
            reverse,
            zero_number,
            type
        })
        newStatesRow = {
            ...newStatesRow,
            [key]: scale.addAnswer()
        }
    }

    return { [key]: newStatesRow[key] }
}

export const getScaleMinusRows = (props: CalcuProps) => {
    const { survey_question_no, states, reverse, zero_number, type, guideLen } = props

    const scale = new Scale({ 
        survey_question_no, 
        data: states.row, 
        reverse,
        zero_number,
        type
    })

    const rows = scale.getZeroNumberRows()
    const { key } = scale.getTypeValue()

    let newStatesRow = { 
        ...states.row,
        [key]: rows
    }

    const idName = type === 'one' ? 'survey_module_no' : 'survey_module_answer_no'

    const rowsLen = rows.length

    const len = rowsLen - guideLen

    // 뒷배열 부터 삭제를 할거다
    for (let i=0; i<len; i++) {
        const j = rowsLen - i -1
        const row = rows[j]
        const id = row[idName]
        
        const scale = new Scale({ 
            survey_question_no, 
            data: newStatesRow, 
            reverse,
            zero_number,
            type
        })

        newStatesRow = {
            ...newStatesRow,
            [key]: scale.removeAnswer(id)
        }

    }

    return { [key]: newStatesRow[key] }
}

// 범위를 풀다운르로 선택하다보니, 응답값이 있는상태에서 사용자가 범위를 줄엿다가 다시 원복해도 기존 키값이 날라가는 문제가있다
// 최종적으로 기존키값에다가 매칭시켜준다
export const getMatchOriginalScaleRows = (props: MatchProps) => {
    const { newRows, newReverse, oringinRows, originReverse, type } = props

    const getMatchData = (newData: MatchProps['newRows'], originData: MatchProps['oringinRows']) => {
        const key = type === 'one' ? 'survey_module_no' : 'survey_module_answer_no'

        return newData.reduce((acc, c, i) => {
            if (typeof originData[i] !== 'undefined') {
                const originKey = originData[i][key]

                c[key] = originKey

                acc.push(c)

                return acc
            }

            c[key] = 0
            acc.push(c)

            return acc

        }, [])
    }

    if (newReverse === originReverse) {
        return getMatchData(newRows, oringinRows)
    }

    if (newReverse !== originReverse) {
        const newOriginRows = [...oringinRows]
        newOriginRows.reverse()

        return getMatchData(newRows, newOriginRows)
    }
}