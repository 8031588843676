import gql from 'graphql-tag'

export const CREATE_SURVEY_MBOX_DESIGN = gql`
    mutation CreateSurveyMboxDesign($survey_no: Int!) {
        createSurveyMboxDesign(survey_no: $survey_no)
    }
`

export const UPDATE_SURVEY_MBOX_DESIGN = gql`
    mutation UpdateSurveyMboxDesign($input: UpdateSurveyMboxDesign!) {
        updateSurveyMboxDesign(input: $input)
    }
`

export const schemaSurveyMboxDesign = `
    question
    answer
    answer_button
    answer_sbutton
    background_color
    line_color
    background_image
    background_filter
    background_size
    background_opacity
    font_family
    numbering
`

export const GET_SURVEY_MBOX_DESIGN = gql`
    query SurveyMboxDesign($survey_no: Int!, $mode: Mode) {
        surveyMboxDesign(survey_no: $survey_no, mode: $mode) {
            ${schemaSurveyMboxDesign}
        }
    }
`

export interface SurveyMboxDesignProps {
    question: string;
    answer: string;
    answer_button: string;
    answer_sbutton: string;
    background_color: string;
    line_color: string;
    background_image: string;
    background_size: string;
    background_opacity: string;
    font_family: string;
    numbering: string;
}