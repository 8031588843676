import { memo } from 'react'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import Input from 'styled/Custom/Input'
import useValue from './useValue'
import ConfirmComponent from './Confirm'

export function getIsDisabled(answered, originAnswered) {
    return answered === originAnswered
}

function BaseComponent(props) {
    const { survey_question_no, min_length, max_length, badge, refComment, isConfirm, originAnswered, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ value, onChangeValue ] = useValue(originAnswered, onChange)

    // 도움말 표기
    let helper = ''
    if (badge) helper = f({id: 'component.Survey.Modules.09.base.helper'}, {min_length, max_length})

    return (
        <>
        <Input
            multiline
            value={value}
            onChange={(e) => onChangeValue(e.target.value)}
            inputRef={ref => refComment.current[survey_question_no] = ref}
            inputProps={{maxLength: max_length}}
            helper={helper}
        />
        {
            isConfirm && (
                <ConfirmComponent survey_question_no={survey_question_no} value={value} isEqualAnswer={getIsDisabled(value, originAnswered)}/>
            )
        }
        </>
    )
}

export default memo(BaseComponent, (prev, next) => {
    return  isEqual(prev.survey_question_no, next.survey_question_no) && 
            isEqual(prev.isConfirm, next.isConfirm) && 
            isEqual(prev.originAnswered, next.originAnswered) && 
            isEqual(prev.min_length, next.min_length) && 
            isEqual(prev.max_length, next.max_length) && 
            isEqual(prev.badge, next.badge) && 
            isEqual(prev.refComment, next.refComment) && 
            isEqual(prev.onChange, next.onChange)
})