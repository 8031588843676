import { memo } from "react"
import { useIntl } from "react-intl"
import HtmlParser from 'react-html-parser'
import { getRGBA } from 'utils/analysis'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from "@material-ui/core/Typography"
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from "@material-ui/core/styles"
import { getAnimationClassName } from '../01/Item'
import { Props as ParentProps, OnOpen, OnRemove } from './Items'

interface Props {
    items: ParentProps['items'];
    reply: ParentProps['reply'];
    label: ParentProps['label'];
    answer_color: ParentProps['answer_color'];
    line_color: ParentProps['line_color'];
    font_family: ParentProps['font_family'];
    onOpen: OnOpen;
    onRemove: OnRemove;
}

interface StylesProps {
    font_family: string;
    button_color: string;
    answer_color: string
    line_color: string;
    selected_color: string;
}

const useStyles = makeStyles({
    button: (props: StylesProps) => {
        const { font_family, button_color, line_color } = props
        return {
            fontFamily: font_family,
            color: button_color,
            lineHeight: '130%', 
            textAlign: 'left', 
            justifyContent: 'space-between', 
            wordBreak: 'break-all', 
            paddingTop: 10, 
            paddingBottom: 10,
            paddingLeft: 0,
            paddingRight: 4,
            borderBottom: `1px solid ${line_color}`,
            borderRadius: 0,
            '&:hover': {
                backgroundColor: 'transparent'
            }
        }   
    },
    endIconBox: (props: StylesProps) => {
        const { line_color } = props

        return {
            display: 'flex', 
            alignItems: 'center', 
            border: `1px solid ${line_color}`, 
            borderRadius: 3, 
            paddingTop: 4, 
            paddingBottom: 4,
            paddingLeft: 7,
            paddingRight: 0
        }
    },
    endIconText: (props: StylesProps) => {
        const { answer_color, font_family } = props

        return {
            color: answer_color, 
            fontFamily: font_family, 
            fontSize: 14, 
            whiteSpace: 'nowrap' 
        }
    },
    endIconIcon: (props: StylesProps) => {
        const { line_color } = props

        return {
            color: line_color, 
        }
    },
    itemsBox: {
        display: 'flex', 
        flexWrap: 'wrap',
        gap: 5 
    },
    itemBox: (props: StylesProps) => {
        const { selected_color, font_family } = props

        return {
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            borderRadius: 15, 
            padding: 5, 
            border: `1px solid ${selected_color}`,
            '& > span': {
                fontFamily: font_family,
            }
        }
    },
    cancelIcon: (props: StylesProps) => {
        const { selected_color } = props

        return {
            color: selected_color
        }
    },
})

function SelectComponent(props: Props) {
    const { items, reply, label, answer_color, line_color, font_family, onOpen, onRemove } = props

    const { formatMessage: f } = useIntl()

    // 선택된 아이템
    const replyNos = reply.map(c => c.survey_module_no)
    const selectItems = items.filter(c => replyNos.includes(c.survey_module_no))
    
    const isJust = reply.length === 0 ? false : reply[0].isJust 
    
    const placeholder_color = getRGBA({color: answer_color, opacity: .3})
    const selected_color = getRGBA({color: line_color, opacity: .7})

    const classes = useStyles({
        font_family,
        answer_color: `rgba(${answer_color})`,
        line_color: `rgba(${line_color})`,
        button_color: selectItems.length === 0 ? placeholder_color : `rgba(${answer_color})`,
        selected_color
    })

    const newLabel = label || f({id: 'component.Survey.Edit.21.Label.label'})

    return (
        <Button 
            fullWidth 
            endIcon={
                <div className={classes.endIconBox}>
                    <Typography 
                        component="span" 
                        variant="body2" 
                        className={classes.endIconText}
                    >
                        {f({id: 'component.Survey.Modules.21.Select.open'})}
                    </Typography>
                    <ArrowDropDownOutlinedIcon className={classes.endIconIcon}/>
                </div>
 
            } 
            className={`${getAnimationClassName(isJust)} ${classes.button}`}
            onClick={onOpen}
        >
            {
                selectItems.length > 0 ? (    
                    <Box className={classes.itemsBox}>
                        {
                            selectItems.map(c => {
                                const { survey_module_no, answer } = c
                                return (
                                    <Box 
                                        key={survey_module_no} 
                                        className={classes.itemBox}
                                    >
                                        <span>{answer}</span>
                                        <CancelIcon className={classes.cancelIcon} onClick={(e: any) => onRemove(e, survey_module_no)}/>
                                    </Box>
                                ) 
                            })
                        }
                    </Box>
                ) : (
                    <>
                    {HtmlParser(`<span style="font-size: 16px; font-family: ${font_family};">${newLabel}</span>`)}
                    </>
                ) 
               
            }
        </Button>
    )
}

export default memo(SelectComponent)