// 확인 버튼

import {  memo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { isIOS, isMobile, isSafari } from 'react-device-detect'
import { getColorConcentration } from 'utils'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Props as ParentProps, OnClose } from './Items'

interface Props {
    open: boolean;
    button_color: string;
    font_family: ParentProps['font_family'];
    onClose: OnClose;
}

const useStyles = makeStyles(theme => ({
    button: (props: { button_color: string }) => {
        const { button_color } = props
        const concent = getColorConcentration(button_color)

        return {
            background: `rgba(${button_color})`,
            borderTopLeftRadius: 0, 
            borderTopRightRadius: 0,
            position: 'sticky', 
            bottom: 0, 
            fontWeight: 700,
            fontSize: 18,
            display: 'none',
            color: concent ? 'white' : 'black',
            [theme.breakpoints.down('sm')]: {
                display: 'flex'
            },
            '&:hover': {
                background: `rgba(${button_color})`
            }
        }
    }
}))

let timer: ReturnType<typeof setTimeout>

function ConfirmComponent(props: Props) {
    const { open, button_color, font_family, onClose } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles({ button_color })

    const [ newOpen, setNewOpen ] = useState(false)

    useEffect(() => {
        clearTimeout(timer)

        // 아이폰, 아이패드는 창을 열때 버튼이 중간에 위치하다가 맨아래로  떨어지는 이상한현상이 발생해서 버튼을 좀  늦게띄운다.
        if ((isIOS && isMobile) || (!isMobile && isSafari)) {
            timer = setTimeout(() => {
                setNewOpen(open)
            }, 225)
            return
        }

        setNewOpen(open)

        return () => {
            clearTimeout(timer)
        }

    }, [open])

    if (!newOpen) return null

    return (
        <div style={{ position: 'sticky', bottom: 0 }}>
            <Button 
                variant='contained' 
                fullWidth 
                disableElevation 
                className={classes.button}
                onClick={onClose}
            >
                <span style={{fontFamily: font_family}}>
                    {f({id: 'component.Survey.Modules.21.Confirm.button'})}
                </span>
            </Button>
        </div>
      
    )
}

export default memo(ConfirmComponent)