import { useState } from 'react'
import { useIntl } from 'react-intl'
import { getQuestionParsing } from 'utils/survey'
import Button from 'styled/Custom/Button'
import { AgreeBox } from 'styled/Survey/Agree'
import Answer from 'styled/Custom/Answer'
import RatingIcon from 'styled/Survey/RatingIcon'
import IconComponent from 'styled/Custom/Icon'
import OneStyled, { QuestionStyled } from 'styled/Survey/Live/One'
import Question from 'styled/Custom/Question'
import { SurveyModulesThanksComponent } from 'component'
import FileAndPictureComponent from './FileAndPicture'

const thanks = true

export default (props) => {
    const { row, onChange=() => {}} = props
    const { message, button_name, img_src, img_position, img_name, align, agree, agree_icon_type } = row
    
    const { formatMessage: f } = useIntl()

    const [ checkedAgree, setCheckedAgree ] = useState(false)

    const _button_name = button_name || f({id: 'component.Survey.Modules.Start.button_name'})

    function handleChangeAgree() {
        if (checkedAgree) return

        setCheckedAgree(true)

        setTimeout(() => {
            onChange()
        }, 350)
    }

    return (
        <OneStyled img_src={img_src} img_position={img_position} thanks={thanks}>
            <QuestionStyled img_src={img_src} img_position={img_position} thanks={thanks}>
                <div>
                    <div>
                        <SurveyModulesThanksComponent selected='start' agree={agree} img_src={img_src} img_position={img_position}>
                            <div className='message'><Question style={{textAlign: align}}>{getQuestionParsing(message)}</Question></div>
                            <div className='image'>
                                <FileAndPictureComponent
                                    img_src={img_src} 
                                    img_position={img_position} 
                                    img_name={img_name} 
                                    thanks={thanks}
                                />
                            </div>
                            <div className='button'>
                                {
                                    agree ? (
                                        <div style={{display: 'table', margin: '0 auto'}}>
                                            <AgreeBox onClick={handleChangeAgree} selected={checkedAgree} className={checkedAgree ? "animate__animated animate__pulse" : ""}>
                                                <div>
                                                    <IconComponent>
                                                        <RatingIcon selected={checkedAgree} icon_type={agree_icon_type}/>
                                                    </IconComponent>
                                                </div>
                                                <div>
                                                    <Answer style={{fontSize: 20, fontWeight: 700}}>{_button_name}</Answer>
                                                </div>
                                            </AgreeBox>
                                        </div>
                                    ) : (
                                        <Button onClick={onChange}>{_button_name}</Button>
                                    )
                                }
                            </div>
                        </SurveyModulesThanksComponent>
                    </div>
                </div>
            </QuestionStyled>
        </OneStyled>
    )
}