/**
 * 점프 포인트, 완료글 분기 저장할수 있는 상태값 disabled이면 true
 */
import { JumpPointTextProps } from 'gql/jump_point'

// 전체 데이터 저장
export const INIT = 'survey/create/logic/jumpPointSave/INIT'

// 개별문항 및 페이지의 row 추가 삭제 변경시 저장
export const CHANGE = 'survey/create/logic/jumpPointSave/CHANGE'

const initState: { disabled: boolean } = {
    disabled: true
}

interface ActionProps {
    type: string;
    disabled: boolean;
}

export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case INIT:
            return initState
        case CHANGE:
            const { disabled } = action

            return { disabled }            
        default:
            return state
    }
}