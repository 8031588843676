import { memo } from 'react'
import { useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
    mark_number: boolean; 
    mark_required: boolean;  
    number: number;  
    required: boolean; 
    module_type: string;
    fontFamily: string;
    questionColor: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 12
    }
}))

function NumberingComponent(props: Props) {
    const { mark_number, mark_required, number, required, module_type, fontFamily, questionColor  } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    if (module_type === '_00') return null

    if (!mark_number && !mark_required) return null

    const isNumber = (mark_number && module_type !== '_16') ? true : false
    const isRequired = (mark_required && required) ? true : false

    const styles = {
        color: `rgba(${questionColor})`,
        fontFamily
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5}}>
            <div>
            {
                (isNumber) && (
                    <Typography className={classes.root} style={{ ...styles, marginRight: 5, fontWeight: 'bold' }}>
                        {`Q${number}.`}
                    </Typography>
                )
            }
            </div>
            <div>
            {
                (isRequired) && (
                    <Typography className={classes.root} style={styles}>
                        *{f({id: 'component.Survey.Modules.Numbering.required'})}
                    </Typography>
                )
            }
            </div>
        </div>
    )
}

export default memo(NumberingComponent)