export const SHOW = 'popover/SHOW'
export const HIDE = 'popover/HIDE'

const initState = {
    show: false,
    anchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center'
    },
    title: '',
    message: '',
    messageColor: 'initial',
    component: '',
    isClose: true,      // 닫기 버튼을 보여줄지 안보여줄지
    closeName: '',
    onClose: '',        // 닫기 후 액션이 있을경우 여기에 function을 담는다
    onActions: []       // 추가적인 액션을 담는다 {on: function, name: string, hide: boolean, color}
}

export default (state = initState, action) => {
    const anchorEl = action.anchorEl || initState.anchorEl
    const anchorOrigin = action.anchorOrigin || initState.anchorOrigin
    const transformOrigin = action.transformOrigin || initState.transformOrigin
    const title = action.title || initState.title
    const message = action.message || initState.message
    const messageColor = action.messageColor || initState.messageColor
    const component = action.component || initState.component
    const isClose = typeof action.isClose === 'boolean' ? action.isClose : initState.isClose
    const closeName = action.closeName || initState.closeName
    const onClose = action.onClose || initState.onClose
    const onActions = action.onActions || initState.onActions

    switch(action.type) {
        case SHOW:
            return {
                ...state,
                show: true,
                anchorEl,
                anchorOrigin,
                transformOrigin,
                title,
                message,
                messageColor,
                component,
                isClose,
                closeName,
                onClose,
                onActions
            }
        case HIDE:
            return {
                ...state,
                show: false
            }
        default:
            return state
    }
}