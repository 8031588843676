import SurveyLiveRequiredMessageComponent from 'component/Survey/Live/RequiredMessage'
import SurveyLiveDragMessageComponent from 'component/Survey/Live/DragMessage'
import SurveyModules00Component from 'component/Survey/Modules/00/Component'
import SurveyModules01Component from 'component/Survey/Modules/01/Component'
import SurveyModules02Component from 'component/Survey/Modules/02/Component'
import SurveyModules03Component from 'component/Survey/Modules/03/Component'
import SurveyModules04Component from 'component/Survey/Modules/04/Component'
import SurveyModules05Component from 'component/Survey/Modules/05/Component'
import SurveyModules06Component from 'component/Survey/Modules/06/Component'
import SurveyModules07Component from 'component/Survey/Modules/07/Component'
import SurveyModules08Component from 'component/Survey/Modules/08/Component'
import SurveyModules09Component from 'component/Survey/Modules/09/Component'
import SurveyModules10Component from 'component/Survey/Modules/10/Component'
import SurveyModules11Component from 'component/Survey/Modules/11/Component'
import SurveyModules12Component from 'component/Survey/Modules/12/Component'
import SurveyModules13Component from 'component/Survey/Modules/13/Component'
import SurveyModules14Component from 'component/Survey/Modules/14/Component'
import SurveyModules15Component from 'component/Survey/Modules/15/Component'
import SurveyModules16Component from 'component/Survey/Modules/16/Component'
import SurveyModules17Component from 'component/Survey/Modules/17/Component'
import SurveyModules18Component from 'component/Survey/Modules/18/Component'
import SurveyModules19Component from 'component/Survey/Modules/19/Component'
import SurveyModules20Component from 'component/Survey/Modules/20/Component'
import SurveyModules21Component from 'component/Survey/Modules/21/Component'
import SurveyModules22Component from 'component/Survey/Modules/22/Component'



// 응답값이 있는데 게시자가 보기내용을 바꿔서 키값이 응답값이 있어도 키값에 부여된 값이 아니라면 없는거다
// 일단은 21번문항 드롭다운에서 진행한다
const realResponse = (type, items, replys) => {
    if (type === 'one') {
        const itemNos = items.map(c => c.survey_module_no)
        return replys.filter(c => itemNos.includes(c.survey_module_no))
    }

    return replys
}

// 모듈 포멧
const ModuleComponent = (props) => {
    const { _question, _modules, _answers, _option={}, reply=[], replyComment } = props
    const { survey_question_no, module_type } = _question
    const { required, badge } = _option

    const moduleType = module_type.replace('_', '')

    // 중복선택형 등의 범위 안내
    const isRequiredMessage = required && badge

    switch(moduleType) {
        case '00':
            return <SurveyModules00Component survey_question_no={survey_question_no} reply={reply}/>
       
        case '01':
            return <SurveyModules01Component lists={_modules} {..._option} reply={reply}/>
        case '02':
                return (
                    <>
                    {isRequiredMessage && <SurveyLiveRequiredMessageComponent module_type={moduleType} required_start={_option.required_start} required_end={_option.required_end}/>}
                    <SurveyModules02Component  reply={reply} lists={_modules} {..._option}/>
                    </>
                )
        case '03':
            return (
                <SurveyModules03Component reply={reply} columns={_modules} answers={_answers} {..._option} replyComment={replyComment}/>
            )
        case '04':
            return (
                <>
                {
                    isRequiredMessage && (
                        <SurveyLiveRequiredMessageComponent 
                            module_type={moduleType} 
                            required_start={_option.required_start} 
                            required_end={_option.required_end}
                        />
                    )
                }
                <SurveyModules04Component 
                    reply={reply} 
                    survey_question_no={survey_question_no} 
                    columns={_modules} 
                    answers={_answers} 
                    {..._option}
                    replyComment={replyComment}
                />
                </>
            )
        case '05':
            return (
                <SurveyModules05Component reply={reply} lists={_modules} {..._option} replyComment={replyComment}/>
            )
        case '06':
            return (
                <SurveyModules06Component reply={reply} columns={_modules} answers={_answers} {..._option} replyComment={replyComment}/>
            )
        case '07':
            return (
                <SurveyModules07Component reply={reply} lists={_modules} {..._option} replyComment={replyComment}/>
            )
        case '08':
            return (
                <SurveyModules08Component reply={reply} columns={_modules} answers={_answers} {..._option} replyComment={replyComment}/>
            )
        case '09':
            return (
                <SurveyModules09Component reply={reply} survey_question_no={survey_question_no} {..._option}/>                    
            )
        case '10':
            return (
                <SurveyModules10Component reply={reply} survey_question_no={survey_question_no} {..._option}/>                    
            )
        case '11':
            return (
                <>
                {badge && <SurveyLiveDragMessageComponent/>}
                <SurveyModules11Component 
                    reply={reply} 
                    survey_question_no={survey_question_no} 
                    ranks={_modules}
                    answers={_answers} 
                    {..._option} 
                    replyComment={replyComment}
                />
                </>                  
            )
        case '12':
            return <SurveyModules12Component reply={reply} lists={_modules} {..._option}/>
        case '13':
            return (
                <>
                {isRequiredMessage && <SurveyLiveRequiredMessageComponent module_type={moduleType} required_start={_option.required_start} required_end={_option.required_end}/>}
                <SurveyModules13Component reply={reply} lists={_modules} {..._option}/>
                </>
                
            )
        case '14':
            return (
                <SurveyModules14Component 
                    reply={reply} 
                    survey_question_no={survey_question_no} 
                    lists={_modules} 
                    {..._option} 
                    replyComment={replyComment}
                />
            )
        case '15':
            return (
                <SurveyModules15Component 
                    reply={reply} 
                    survey_question_no={survey_question_no} 
                    columns={_modules}
                    answers={_answers}
                    {..._option}
                    replyComment={replyComment}
                />
            )
        case '16':
            return <SurveyModules16Component reply={reply} survey_question_no={survey_question_no} item={_modules} option={_option}/>
        case '17':
            return (
                <>
                 {isRequiredMessage && <SurveyLiveRequiredMessageComponent module_type={moduleType} required_start={_option.required_start} required_end={_option.required_end}/>}
                <SurveyModules17Component 
                    reply={reply} 
                    survey_question_no={survey_question_no} 
                    ranks={_modules}
                    answers={_answers} 
                    {..._option} 
                    replyComment={replyComment}
                />
                </>                  
            )
        case '18':
            return (
                <>
                {isRequiredMessage && <SurveyLiveRequiredMessageComponent module_type={moduleType} required_start={_option.required_start} required_end={_option.required_end}/>}
                <SurveyModules18Component 
                   reply={reply} 
                   survey_question_no={survey_question_no} 
                   ranks={_modules}
                   answers={_answers} 
                   {..._option} 
                   replyComment={replyComment}
                />
                </>
                
            )
        case '19':
            return (
                <SurveyModules19Component reply={reply} survey_question_no={survey_question_no} {..._option} replyComment={replyComment}/>                    
            )
        case '20':
            return (
                <SurveyModules20Component reply={reply} survey_question_no={survey_question_no} {..._option}/>                    
            )
        case '21':
            return (
                <>
                {
                    (isRequiredMessage && _option.kind === true) && (
                        <SurveyLiveRequiredMessageComponent 
                            module_type={moduleType} 
                            required_start={_option.required_start} 
                            required_end={_option.required_end}
                        />
                    )
                }
                <SurveyModules21Component 
                    reply={realResponse('one', _modules, reply)} 
                    survey_question_no={survey_question_no} 
                    lists={_modules} 
                    {..._option}
                    replyComment={replyComment}
                />
                </>
            )
        case '22':
            return (
                <>
                {
                    (isRequiredMessage && _option.kind === true) && (
                        <SurveyLiveRequiredMessageComponent 
                            module_type={moduleType} 
                            required_start={_option.required_start} 
                            required_end={_option.required_end}
                        />
                    )
                }
                <SurveyModules22Component 
                    reply={reply} 
                    survey_question_no={survey_question_no} 
                    columns={_modules} 
                    answers={_answers} 
                    {..._option}
                    replyComment={replyComment}
                />
                </>
            )
        default:
            return null
    }
}

export default ModuleComponent