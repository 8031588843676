import { useContext, useState, useCallback, useEffect } from 'react'
import { createContext } from 'react'
import PropsContext from 'context/props'
import { isEqual } from 'lodash-es'
import { isMobileOnly } from 'react-device-detect'
import { getColorConcentration } from 'utils'
import { withBreakPoints } from 'hoc'
import BaseComponent from './Base'
import MobileOnlyComponent from './MobileOnly'
import CommentComponent from '../Comment'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'

// comment일경우 랜더링 때문에 ref로 등록한다
const getClass = (checked, disabled) => {
        return checked ? 'checked' : disabled ? 'disabled' : ''
}

export const Context = createContext({})

const Component = (props) => {
    const { format, design } = useContext(PropsContext)

    const { 
        survey_question_no, 
        columns, 
        answers, 
        required=false, 
        required_end=0, 
        reply, 
        is_comment, 
        is_comment_placeholder,
        mobile_matrix,
        replyComment,
        breakpoints 
    } = props

    const { background_color, answer } = design

    const background_concent = getColorConcentration(background_color)
    const answer_concent = getColorConcentration(answer)


    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ newReply, setNewReply ] = useState(reply)

    const { xs } = breakpoints


    // required_end 만큼 체크가 됏으면 나머지는 disabled 처리한다. (선택된값은 해제도 해야하기때문 disabled 처리안한다)
    const getDisabled = useCallback((countReply, checked) => {
        return countReply > 0 ? (
            !checked && required && required_end > 0 && countReply >= required_end ? true : false
        ) : (
            false
        )
    }, [ required, required_end ])

    const handleChange = useCallback(async(survey_module_no, survey_module_answer_no, disabled) => {

        if (disabled) return

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c => {
            return (
                c.survey_module_no === survey_module_no && 
                survey_module_answer_no && c.survey_module_answer_no === survey_module_answer_no
            )
        }) ? true : false


        const reply = format._04({ survey_question_no, survey_module_no, survey_module_answer_no })

        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        const newReplys = selfChecked ? [...memoryReply.rows.filter(c => !(c.survey_question_no === survey_question_no && c.survey_module_no === survey_module_no && c.survey_module_answer_no === survey_module_answer_no)) ] 
                                      : [...memoryReply.rows, reply]

        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys
 
        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)
    }, [survey_question_no, newReply, format, onChangeReplys ])

    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])

    return (
        <>
        <Context.Provider value={{onChange: handleChange, reply: newReply, getDisabled, getClass}}>
        {
            (!mobile_matrix && (isMobileOnly || xs || answers.length >= 9))  ? (
                <MobileOnlyComponent 
                    columns={columns} 
                    answers={answers} 
                    reply={newReply} 
                    getDisabled={getDisabled} 
                    getClass={getClass} 
                    onChange={handleChange}
                />
            ) : (
                <BaseComponent 
                    columns={columns} 
                    answers={answers} 
                    background_concent={background_concent} 
                    answer_concent={answer_concent}
                />
            )
        }
        </Context.Provider>
        <CommentComponent 
            survey_question_no={survey_question_no} 
            is_comment={is_comment}
            is_comment_placeholder={is_comment_placeholder}
            comment={replyComment}
            disabled={newReply.length > 0 ? false : true}
            marginBottom="5px"
        />
        </>
    )
}

export default withBreakPoints(Component)