/**
 * selected 바뀌면 로직 root에 호출
 */

// 빈값이 있을경우 해당 id kind 저장
export const CHANGE = 'survey/create/logic/menuSelected/CHANGE'

interface Props {
    selected: string | null;
}

const initState: Props = {
    selected: null,
}

interface ActionProps {
    type: string;
    selected: 'page_random' | 'question_random' | 'jump';
}
  
export default (state = initState, action: ActionProps) => {
    switch(action.type) {
        case CHANGE: 
            return {
                selected: action.selected
            }     
        default:
            return state
    }
}