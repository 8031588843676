import { useContext, useState, useCallback, useEffect } from 'react'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { isMobile } from 'react-device-detect'
import { isEqual } from 'lodash-es'
import { withSurveyLayout, withBreakPoints } from 'hoc'
import { Wrapper } from 'styled/Survey/Base'
import ItemComponent from './Item'
import { memoryReply } from 'container/Survey/Live/Container'
import useChangeReplys from '../useChangeReplys'
import useJustDebounce from 'hooks/useJustDebounce'

export function getReplysKeyOfValue(id, replys) {
    return replys.reduce((acc, c) => {
        return { ...acc, [c[id]]: c }
    }, {})
}

export class VerifyIsJust {
    constructor(props) {
        const { mode, method, just } = props
        this.mode = mode
        this.method = method
        this.just = just
    }

    // mode edit 일때는 작동안함, 1문1답일때만 작동, isJust가 true 일때만 작동
    verify() {
        if (/edit/.test(this.mode)) return false
        if (this.method !== 'one') return false
        if (!this.just) return false

        return true
    }

    _01(lists, survey_module_no) {
        if (!this.verify()) return false
        
        // 값이 있고, 코멘트가 없는 보기에서만 작동한다.
        if (survey_module_no) {

            return Boolean(lists.find(c => c.survey_module_no === survey_module_no && c.is_comment === false))
        }

        return false
    }

    _05(survey_module_no, is_comment) {
        if (!this.verify()) return false
        
        // 값이 있고, 코멘트가 없는 보기에서만 작동한다.
        if (survey_module_no) {

            return !is_comment
        }

        return false
    }

    _14(survey_module_no, is_answer, is_comment) {
        if (!this.verify()) return false

        // 모바일이고, 보기에 답변이 있다면 자동 넘기기 안한다 (클릭시 텍스트가 나오고, 보여줘야한다)
        if (isMobile && is_answer) return false
        
        // 값이 있고, 코멘트가 없는 보기에서만 작동한다.
        if (survey_module_no) {

            return !is_comment
        }

        return false
    }

    _16(survey_module_no, is_comment) {
        if (!this.verify()) return false
        
        return Boolean(survey_module_no)
    }

    _21(survey_module_no, is_comment) {
        if (!this.verify()) return false
        
        // 값이 있고, 코멘트가 없는 보기에서만 작동한다.
        if (survey_module_no) {

            return !is_comment
        }

        return false
    }
}

const Component = (props) => {
    const { mode } = useContext(AccessMethodContext)
    const { mbox, option, format, refComments01 } = useContext(PropsContext)
    const { method } = mbox
    const { just } = option

    const { boxRows, boxCells, layout, is_comment_placeholder, survey_question_no, lists, reply } = props

    const [ onChangeReplys ] = useChangeReplys(survey_question_no)

    const [ debounce, onChangDebounce ] = useJustDebounce()

    const [ newReply, setNewReply ] = useState(reply)

     // survey_module_no를 key로 사용
     const replysKeyOfValue = getReplysKeyOfValue('survey_module_no', newReply)


    const handleChange = useCallback(async(e, survey_module_no, click_comment) => {
        e.stopPropagation()
        e.preventDefault()
  
        // 답체크
        // 미체크시 체크, 체크시 미체크
        // 체크된 상태에서 코멘트 클릭시 유지

        // 이미 체크된 값이 있는지 체크 (선택한값)
        const otherChecked = newReply.find(c => c.survey_module_no && c.survey_module_no !== survey_module_no)  ? true : false

        // 자신이 체크상태인지 확인
        const selfChecked = newReply.find(c => c.survey_module_no === survey_module_no) ? true : false

        //체크 상태인데 코멘트 클릭시에는 리턴
        if (selfChecked && click_comment) return

        // 바로 다음문항으로 이동하기 여부
        const verifyIsJust = new VerifyIsJust({ mode, method, just })
        const isJust = verifyIsJust._01(lists, survey_module_no)

        if (debounce) return

        onChangDebounce(isJust)

        const reply = format._01({ survey_question_no, survey_module_no, isJust, comment: '' })


        // 체크상태이면 미체크로변경, 미체크이면 체크로변경
        // 전체 통합작업
        const newReplys = selfChecked  ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no) ] :
                          otherChecked ? [ ...memoryReply.rows.filter(c => c.survey_question_no !== survey_question_no), reply ]                     
                                       : [ ...memoryReply.rows, reply ] 
        // 랜더링 안되게 일반 변수로 저장
        memoryReply.rows = newReplys

        // 해당 문항의 값만 넣자
        await setNewReply(newReplys.filter(c => c.survey_question_no === survey_question_no))

        onChangeReplys(newReplys)

    }, [ mode, method, lists, just, survey_question_no, newReply, format, debounce, onChangeReplys, onChangDebounce ])


    // 저장된 값을 가져올때 사용된다.
    useEffect(() => {
        setNewReply(prev => {
            // 이전값하고 새로운값하고 다른게있는지 판단
            return isEqual(prev, reply) || prev.length > 0 ? prev : reply
        })
    }, [reply])
        
    return (
        <Wrapper layout={(100 / layout)}>
        {
            boxRows.map((d, i) => (
                <div key={i}>
                    {
                        boxCells[i].map(c => {
                            const { survey_module_no, answer, is_comment } = c

                            const selfReply = replysKeyOfValue[survey_module_no]
                            
                            let selected = false
                            let comment = ''
                            let isJust = false

                            if (selfReply) {
                                selected = true
                                comment = selfReply.comment
                                isJust = selfReply.isJust
                            }
    
                            return (
                                <ItemComponent
                                    key={survey_module_no}
                                    survey_module_no={survey_module_no}
                                    selected={selected}
                                    comment={comment}
                                    answer={answer}
                                    is_comment={is_comment}
                                    isJust={Boolean(isJust)}
                                    is_comment_placeholder={is_comment_placeholder}
                                    refComment={refComments01}
                                    onChange={handleChange}
                                />
                            )
                        })
                    }
                </div>
            ))
        }
        </Wrapper>
    )
}

export default withBreakPoints(withSurveyLayout(Component))