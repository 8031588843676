import { useIntl } from 'react-intl'
import oc from 'open-color'
import { parseHtmlIntl } from 'ts-utils'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import useTokenInfo from 'hooks/useTokenInfo'
import { QrGridItem, QrPaper } from './OtpQrModal'

interface Props {
    qrcode: string;
    used: boolean;
}

const WrapperBox = withStyles({
    root: {
        padding: '10px 20px 20px 20px'
    }
})(Box)

const MyBox = withStyles(theme => ({
    root: {
        padding: 20,
        border: `1px solid ${oc.gray[3]}`,
        background: 'white',
        borderRadius: 3
    }
}))(Box)

const id = 'qrcode-content'
function OtpContentComponent(props: Props) {
    const { qrcode, used } = props

    const { formatMessage: f } = useIntl()

    const { email } = useTokenInfo()

    const onDownload = () => {
        const link = document.createElement('a')
        link.href = qrcode
        link.download = 'OTP.jpg'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    if (!qrcode || !used) return null

    return (
        <WrapperBox>
            <MyBox>
                <Grid container>
                    <Grid item xs={12} sm={4} md={4}>
                        <QrPaper variant="outlined" id={id} qrcode={qrcode}/>
                        <Button variant="contained" disableElevation style={{marginTop: 2}} fullWidth onClick={onDownload}>
                            {f({id: 'component.MyAccount.Info.OtpContent.button.download'})}
                        </Button>
                    </Grid>
                    <QrGridItem kind="content" item xs={12} sm={8} md={8}>
                        <ul style={{listStyleType: 'disc', lineHeight: '155%'}}>
                            <li>{f({id: 'component.MyAccount.Info.OtpContent.list.0'})}</li>
                            <li style={{marginTop: 5}}>{parseHtmlIntl(f({id: 'component.MyAccount.Info.OtpContent.list.1'}, {email}))}</li>
                            <li style={{marginTop: 5}}>{f({id: 'component.MyAccount.Info.OtpContent.list.2'})}</li>
                            <li style={{marginTop: 5}}>{parseHtmlIntl(f({id: 'component.MyAccount.Info.OtpContent.list.3'}))}</li>
                            <li style={{marginTop: 10, color: oc.pink[9]}}>{f({id: 'component.MyAccount.Info.OtpContent.list.4'})}</li>
                        </ul>
                    </QrGridItem>
                </Grid>   
            </MyBox>
        </WrapperBox>
    )
}

export default OtpContentComponent