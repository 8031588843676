/* 
    jwt token 값 decode
*/
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducer'
import { Levels } from 'ts-policy'
import moment from 'moment'
import jwt from 'jsonwebtoken'
import useLogout from 'hooks/useLogout'
import useUpdateToken from './useUpdateToken'

export interface TokenTypes {
    email: string;
    google: number;
    iat: number;
    img_src: string;
    isPassword: boolean;
    ldate: string;
    levels: Levels;
    limit_file_size: number;
    nickname: string;
    onlyGoogle: boolean;
    reservation_levels: Levels | null;
    reservation_levels_date: string | null;
    filedownload_date: string | null;
    responses: {
        hit: number;
        total: number; 
        ldate: string;
    }[];
    status: number;
    user_no: number;
    wdate: string;
    maker: boolean;
    mfa: boolean;
    year_date: string | null;
}

export type Token = TokenTypes

export function verify(authorization: string | null):Token | '' {
    if (!authorization) return ''

    let token:TokenTypes | '' = ''

    try {
        token = jwt.decode(authorization)

        if (typeof token !== 'object') return ''

        // 비밀번호가 없고 구글이 truw라면 구글로그인으로만 사용하는 사용자이다.
        const onlyGoogle = (!token.isPassword && token.google) ? true : false
        token = { ...token, nickname: token.email.substr(0, 1).toUpperCase(), onlyGoogle }
    } catch(e) {
        token = ''
    }

    return token
}

const useTokenInfo = () => {
    const dispatch = useDispatch()

    const auth = useSelector((state: RootState) => state.auth)

    const [ token, setToken ] = useState<TokenTypes>(verify(localStorage.getItem('authorization')))

    const logout = useLogout()

    const updateToken = useUpdateToken()

    useEffect(() => {
        const parseToken = verify(localStorage.getItem('authorization'))

        // 값이 없다면 
        if (!parseToken) {
            logout()
            return
        }

        // 상태가 정상이 아니면 강제 로그아웃
        if (parseToken.status !== 10 && parseToken.status !== 40) {
            logout()
            return
        }
        
        if (!parseToken.responses) {
            logout()
            return
        }

        // 토큰 재발행...(하루 지나면 토큰 재발행)
        if (moment(parseToken.iat * 1000).format('YYYY-MM-DD HH:mm:ss') < moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')) {
            updateToken()
        }

        setToken(parseToken)
    }, [auth, logout, dispatch, updateToken])
  
    return token    
}
 
export default useTokenInfo
