import { memo, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import HtmlParser from 'react-html-parser'
import summaryContext from 'context/summary'
import { numberFormat } from 'utils'
import { getRGBA } from 'utils/analysis'
import { _05 } from 'gql/survey_analysis_summary'
import Box from '@material-ui/core/Box'
import Typography, { TypographyProps as MuiTypographyProps} from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    isNps: boolean;
    rows: _05["modules"];
}

interface TypographyProps extends MuiTypographyProps {
    fcolor: string; 
    fontFamily: string 
}

const useStyles = makeStyles(theme => ({
    icon: (props: { iconColor: string }) => {
        const { iconColor } = props

        return {
            marginLeft: 5, 
            width: 16, 
            height: 16, 
            color: iconColor,
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
      
    }
}))

const MyTypography = withStyles({
    root: (props: TypographyProps) => {
        const { fcolor, fontFamily } = props
        return {
            color: `rgba(${fcolor})`,
            fontFamily,
            fontSize: 15
        }
    }
})(Typography)

const MyTypography2 = withStyles({
    root: (props: TypographyProps) => {
        const { fcolor, fontFamily } = props
        return {
            color: `rgba(${fcolor})`,
            fontFamily
        }
    }
})(props => <Typography {...props} variant='h6'/>)

const ContentBox = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
})(Box)

const MyDivider= withStyles(theme => ({
    root: (props: { linecolor: string }) => {
        const { linecolor } = props

        return {
            marginTop: 3, 
            marginBottom: 6,
            backgroundColor: 'transparent',
            border: `1px solid ${linecolor}`,
            borderBottom: 'none',
            height: 5,
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    }
}))(Divider)

const WrapperBarRank = withStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '7.4fr 2fr 2fr',
        gap: 10,
        marginTop: 25,
        marginBottom: 10,
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            gap: 20
        }
    }
}))(Box)

// 합산, 평균
export const getScaleInsight = (rows: Props["rows"], isNps: Props["isNps"]) => {
    // 합산
    const [ count, sum ] = rows.reduce((acc, c) => {
        
        acc[0] = acc[0] + c.count // 총 응답수
        acc[1] = acc[1] + (c.indexs * c.count) // 합산
        return acc
    }, [0, 0])

    const avg = count === 0 ? 0 : sum / count

    
    // 카운트, 퍼센트
    let detractors = [ 0, 0 ]
    let passives = [ 0, 0 ]
    let promoters = [ 0, 0 ]

    if (isNps) {
        const [ detractorsCount, passivesCount, promotersCount ] = rows.reduce((acc, c) => {
            if (c.indexs <=6) acc[0] = acc[0] + c.count
            else if (c.indexs <= 8) acc[1] = acc[1] + c.count
            else if (c.indexs <= 10) acc[2] = acc[2] + c.count
            
            return acc
        }, [0, 0, 0])
        
        detractors = [ detractorsCount, count === 0 ? 0 : Number((detractorsCount / count * 100).toFixed(1)) ]
        passives = [ passivesCount, count === 0 ? 0 : Number((passivesCount / count * 100).toFixed(1)) ]
        promoters = [ promotersCount, count === 0 ? 0 : Number((promotersCount / count * 100).toFixed(1)) ]

    }

    return { 
        sum, 
        avg: Number.isInteger(avg) ? avg : avg.toFixed(1),
        nps: { detractors, passives, promoters }
    }
} 

function SummaryNpsCalcuComponent(props: Props) {
    const { rows, isNps } = props
    
    const { sum, avg, nps } = getScaleInsight(rows, isNps)
    const { detractors, passives, promoters } = nps
    
    const { design } = useContext(summaryContext)

    const lineColor = getRGBA({color: design.question, opacity: .2})

    const iconColor = getRGBA({color: design.question, opacity: .5})

    const classes = useStyles({ iconColor })

    const { formatMessage: f } = useIntl()

    return (
        <>
        {
            useMemo(() => (
                <>
                <div style={{ marginTop: 20, marginBottom: 20}}>
                    <div style={{ display: 'flex', gap: 50, borderBottom: `1px solid ${lineColor}`}}>
                        <ContentBox>
                            <MyTypography fcolor={design.question} fontFamily={design.font_family} style={{whiteSpace: 'nowrap'}}>
                                {f({id: 'styled.Survey.Analysis.SummaryNpsCalcu.column.sum'})}
                            </MyTypography>
                            <MyTypography2 fcolor={design.question} fontFamily={design.font_family}>
                                {numberFormat(sum)}
                            </MyTypography2>
                        </ContentBox>
                        <ContentBox>
                            <MyTypography fcolor={design.question} fontFamily={design.font_family} style={{whiteSpace: 'nowrap'}}>
                                {f({id: 'styled.Survey.Analysis.SummaryNpsCalcu.column.avg'})}
                            </MyTypography>
                            <MyTypography2 fcolor={design.question} fontFamily={design.font_family}>
                                {avg}
                            </MyTypography2>
                        </ContentBox>
                        {
                            isNps && (
                                <ContentBox>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <MyTypography fcolor={design.question} fontFamily={design.font_family} component="span">
                                            Net Promoter Score® 
                                        </MyTypography>
                                        <Tooltip title={f({id: 'styled.Survey.Analysis.SummaryNps.scoreDetail'})} isMobileClick={true}>
                                            <HelpRoundedIcon className={classes.icon}/>
                                        </Tooltip>
                                        </div>
                                    </div>
                                    <MyTypography2 fcolor={design.question} fontFamily={design.font_family}>
                                        {Number((nps.promoters[1] - nps.detractors[1]).toFixed(1))}
                                    </MyTypography2>
                                </ContentBox>
                            )
                        }
                    
                    </div>
                </div>
                {
                    isNps && (
                        <WrapperBarRank>
                            <div>
                                <MyTypography fcolor={design.question} fontFamily={design.font_family} style={{fontSize: 13}}>
                                    {HtmlParser(f({id: 'styled.Survey.Analysis.SummaryNps.title.0'}, {percent: `${detractors[1]}%`, count: numberFormat(detractors[0])}))}
                                </MyTypography>
                                <MyDivider linecolor={lineColor}/>
                            </div>
                            <div>
                                <MyTypography fcolor={design.question} fontFamily={design.font_family} style={{fontSize: 13}}>
                                    {HtmlParser(f({id: 'styled.Survey.Analysis.SummaryNps.title.1'}, {percent: `${passives[1]}%`, count: numberFormat(passives[0])}))}
                                </MyTypography>
                                <MyDivider linecolor={lineColor}/>
                            </div>
                            <div>
                                <MyTypography fcolor={design.question} fontFamily={design.font_family} style={{fontSize: 13}}>
                                    {HtmlParser(f({id: 'styled.Survey.Analysis.SummaryNps.title.2'}, {percent: `${promoters[1]}%`, count: numberFormat(promoters[0])}))}
                                </MyTypography>
                                <MyDivider linecolor={lineColor}/>
                            </div>
                        </WrapperBarRank>
                    )
                }
                </>
            ), [classes, lineColor, sum, avg, nps, detractors, passives, promoters, isNps, design.question, design.font_family, f])
        }
        </>      
    )
}

export default memo(SummaryNpsCalcuComponent)