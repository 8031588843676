import gql from 'graphql-tag'

export const UPDATE_USER_INFO_IMGSRC = gql`
    mutation UpdateUserInfoImgSrc($img_src: String!) {
        updateUserInfoImgSrc(img_src: $img_src)
    }
`

export const UPDATE_USER_INFO_EMAIL = gql`
    mutation UpdateUserInfoEmail($no: String!) {
        updateUserInfoEmail(no: $no)
    }
`

export const SEND_NEW_EMAIL_AUTH = gql`
    mutation SendNewEmailAuth($email: String!) {
        sendNewEmailAuth(email: $email)
    }
`


export const UPDATE_USER_INFO_PASSWORD = gql`
    mutation updateUserInfoPassword($password: String!, $new_password: String!, $new_password_confirm: String!) {
        updateUserInfoPassword(password: $password, new_password: $new_password, new_password_confirm: $new_password_confirm)
    }
`

export const UPDATE_USER_INFO_PASSWORD_GOOGLE = gql`
    mutation updateUserInfoPasswordGoogle($new_password: String!, $new_password_confirm: String!) {
        updateUserInfoPasswordGoogle(new_password: $new_password, new_password_confirm: $new_password_confirm)
    }
`

export const UPDATE_USER_INFO_LANG_TIMEAREA = gql`
    mutation UpdateUserInfoLangTimeArea($lang: String, $timearea: String) {
        updateUserInfoLangTimeArea(lang: $lang, timearea: $timearea)
    }
`