import { lazy, Suspense, forwardRef } from 'react'
import styled from 'styled-components'
import oc from 'open-color'

const QuestionEditorComponent = lazy(() => import('./QuestionEditor'))

const WrapperStyed = styled.div`
    padding: 0;
    margin-bottom: 1em;
    display: flex;
    width: 100%;
    flex-direction: column;
    background: white;
    border: 1px solid ${oc.gray[4]};
    border-radius: 5px;
    & > div:first-child {
        min-height: ${props => `${props.rows * 3}em`};
        padding-top: 1px;
    }
    & > div:last-child {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background: ${oc.gray[0]};
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    } 

    & > div:last-child > div:last-child > * + * {
        margin-left: .3em;
    }    
`

export default forwardRef((props, ref) => {
    const { question, module_type, name, rows=8, img_src, align, placeholder, maxlength, onChange, onChangeAlign, onAttach } = props

    return (
        <WrapperStyed rows={rows}>
            <Suspense fallback="">
                <QuestionEditorComponent 
                    ref={ref}
                    question={question} 
                    name={name} 
                    placeholder={placeholder} 
                    maxlength={maxlength}
                    align={align}
                    module_type={module_type}
                    img_src={img_src}
                    onChangeAlign={onChangeAlign}
                    onAttach={onAttach}
                    onChange={onChange}
                />
            </Suspense>
        </WrapperStyed>
    )
})