import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { GET_SURVEY_MBOX, GET_SURVEY_MBOX_WITH_RELATION } from 'gql/survey_mbox'
import { GET_SURVEY_MBOX_DESIGN } from 'gql/survey_mbox_design'
import { GET_SURVEY_MBOX_COVER } from 'gql/survey_mbox_cover'
import { GET_SURVEY_MBOX_ARGS } from 'gql/survey_mbox_args'
import { GET_SURVEY_MBOX_MESSAGE } from 'gql/survey_mbox_message'
import { GET_SURVEY_MBOX_OPTION } from 'gql/survey_mbox_option'
import { GET_SURVEY_MBOX_APPLICATION } from 'gql/survey_mbox_application'
import { GET_SURVEY_MBOX_ANALYSIS_DESIGN } from 'gql/survey_mbox_analysis_design'
import { GET_SURVEY_MBOX_THUMBNAIL_SRC } from 'gql/survey_mbox_tumbnail'
import { GET_SURVEY_PAGE, GET_SURVEY_PAGE_CLASSIC_BUTTONS } from 'gql/survey_page'
import { GET_SURVEY_QUESTION_ALL, GET_SURVEY_QUESTION_SLACK } from 'gql/survey_question'
import { GET_SLACK_USER_CHANNEL_LIST } from 'gql/slack'
import { GET_SURVEY_START } from 'gql/survey_start'
import { GET_SURVEY_ENDINGS, GET_SURVEY_ENDING } from 'gql/survey_ending'
import { GET_SURVEY_ANALYSIS_MODULES_ALL } from 'gql/survey_analysis_modules'
import { GET_SURVEY_ANALYSIS_USER_ALL, GET_SURVEY_ANALYSIS_19_IS_POSSIBLE_FILE_UPLOAD } from 'gql/survey_analysis_users'
import { GET_SURVEY_ANALYSIS_COMMENTS_ALL } from 'gql/survey_analysis_comment'
import { GET_SURVEY_QUESTIONS_MODULE_ALL, GET_SURVEY_QUESTIONS_MODULE_SAMPLE } from 'gql/survey_question_modules'
import { GET_SURVEY_WORKSPACES, GET_SURVEY_WORKSPACES_IN_GROUP } from 'gql/survey_workspace'
import { GET_JUMP } from 'gql/jump'
import { GET_JUMP_POINT_WITH_RELATION, GET_JUMP_POINT_AND_EXAMPLE_COUNT_EXIST } from 'gql/jump_point'
import { GET_JUMP_EXAMPLE_COUNT_WITH_RELATION } from 'gql/jump_example_count'
import { GET_JUMP_FLOW } from 'gql/jump_flow'
import { GET_WEBHOOK_URLS } from 'gql/webhook'

/*
mode 값
edit ## 수정페이지등에서 사용 (로그인 토큰 필수)
preview ## 미리보기 페이지 통계페이지등에서 사용 (로그인 토큰 필수)
previewTs ## 미리보기인데 
live ## 실제 응답페이지에서 사용하기 때문에 로그인 토큰 체크 안함

root 값
{
    mbox: true 라면 fetch
    _fetchPolicy: {mbox: 'cache-first'}  패치정책 default network_only
}

loading : boolen
*/

class fetchPolicyClass {
    get root() {
        return this._root
    }

    set root(val) {
        this._root = val
    }

    // fetch value
    get(kind) { 
        //_fetchPolicy가 정의되지 않았다는건 모두 network-noly
        const defaultFetchPolicy = 'network-only'

        if (typeof(this._root._fetchPolicy) === 'undefined') return defaultFetchPolicy

        if (typeof(this._root._fetchPolicy[kind]) === 'undefined') return defaultFetchPolicy

        return this._root._fetchPolicy[kind]
    }
}

const fetchPolicy = new fetchPolicyClass()

export default mode => root => loading => WrappedComponent => props => {
    const dispatch = useDispatch()
    const params = useParams()

    fetchPolicy.root = root

    // props survey_no 가 없다면 params로 체크
    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)
    // props survey_workspace_group_no 가 없다면 params로 체크
    const survey_workspace_group_no = props.survey_workspace_group_no ? Number(props.survey_workspace_group_no) : params.survey_workspace_group_no ? Number(params.survey_workspace_group_no) : null

    const [getSurveyWorkspaces, { data: dataSurveyWorkspaces, refetch: refetchSurveyWorkspaces }] = useLazyQuery(GET_SURVEY_WORKSPACES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyWorkspaces'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyWorkspacesInGroup, { data: dataSurveyWorkspacesInGroup, refetch: refetchSurveyWorkspacesInGroup }] = useLazyQuery(GET_SURVEY_WORKSPACES_IN_GROUP, {
        variables: { survey_workspace_group_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyWorkspacesInGroup'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyPage, { data: dataPages }] = useLazyQuery(GET_SURVEY_PAGE, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('pages'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const [getSurveyPageClassicButtons, { data: dataPageClassicButtons, refetch: refetchPageClassicButtons }] = useLazyQuery(GET_SURVEY_PAGE_CLASSIC_BUTTONS, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('pageClassicButtons'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyQuestionAll, { data: dataQuestionAll, refetch: refetchQuestionAll }] = useLazyQuery(GET_SURVEY_QUESTION_ALL, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('questionAll'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyQuestionSlack, { data: dataQuestionSlack }] = useLazyQuery(GET_SURVEY_QUESTION_SLACK, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('questionAll'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyMbox, { data: dataMbox, loading: loadingMbox, refetch: refetchMbox }] = useLazyQuery(GET_SURVEY_MBOX, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('mbox'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyMboxMessage, { data: dataMboxMessage, refetch: refetchMboxMessage }] = useLazyQuery(GET_SURVEY_MBOX_MESSAGE, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('mboxMessage'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const [getSurveyMboxOption, { data: dataMboxOption, refetch: refetchMboxOption, loading: loadingMboxOption }] = useLazyQuery(GET_SURVEY_MBOX_OPTION, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('mboxOption'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const [getSurveyMboxDesign, { data: dataMboxDesign, loading: loadingMboxDesign }] = useLazyQuery(GET_SURVEY_MBOX_DESIGN, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('mboxDesign'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyMboxCover, { data: dataMboxCover, refetch: refetchMboxCover }] = useLazyQuery(GET_SURVEY_MBOX_COVER, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('mboxCover'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyMboxArgs, { data: dataMboxArgs, refetch: refetchMboxArgs }] = useLazyQuery(GET_SURVEY_MBOX_ARGS, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('mboxArgs'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    

    const [getSurveyMboxApplication, { data: dataMboxApplication, loading: loadingMboxApplication, refetch: refetchMboxApplication }] = useLazyQuery(GET_SURVEY_MBOX_APPLICATION, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('mboxApplication'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyMboxWithRelation, { data: dataMboxWithRelation, refetch: refetchMboxWithRelation }] = useLazyQuery(GET_SURVEY_MBOX_WITH_RELATION, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('mboxWithRelation'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyEndings, { data: dataEndings, refetch: refetchEndings }] = useLazyQuery(GET_SURVEY_ENDINGS, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('endings'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyEnding, { data: dataEnding, loading: loadingEnding }] = useLazyQuery(GET_SURVEY_ENDING, {
        variables: { survey_no, survey_ending_no: props.survey_ending_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('ending'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyStart, { data: dataStart, refetch: refetchStart, loading: loadingStart }] = useLazyQuery(GET_SURVEY_START, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('start'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const [getSurveyAnalysisUserAll, { data: dataSurveyAnalysisUserAll, refetch: refetchSurveyAnalysisUserAll }] = useLazyQuery(GET_SURVEY_ANALYSIS_USER_ALL, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyAnalysisUserAll'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyAnalysisModulesAll, { data: dataSurveyAnalysisModulesAll, refetch: refetchSurveyAnalysisModulesAll }] = useLazyQuery(GET_SURVEY_ANALYSIS_MODULES_ALL, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyAnalysisModulesAll'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyAnalysisCommentsAll, { data: dataSurveyAnalysisCommentsAll, refetch: refetchSurveyAnalysisCommentsAll }] = useLazyQuery(GET_SURVEY_ANALYSIS_COMMENTS_ALL, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyAnalysisCommentsAll'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyQuestionsModuleAll, { data: dataSurveyQuestionsModuleAll, refetch: refetchSurveyQuestionsModuleAll  }] = useLazyQuery(GET_SURVEY_QUESTIONS_MODULE_ALL, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyQuestionsModuleAll'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyQuestionsModuleSample, { data: dataSurveyQuestionsModuleSample }] = useLazyQuery(GET_SURVEY_QUESTIONS_MODULE_SAMPLE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('questionsModuleSample'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyMboxAnalysisDesign, { data: dataSurveyMboxAnalysisDesign, refetch: refetchMboxAnalysisDesign }] = useLazyQuery(GET_SURVEY_MBOX_ANALYSIS_DESIGN, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyMboxAnalysisDesign'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyMboxThmubnailSrc, { data: dataMboxThmubnailSrc, refetch: refetchMboxThmubnailSrc }] = useLazyQuery(GET_SURVEY_MBOX_THUMBNAIL_SRC, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyMboxAnalysisDesign'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getJump, { data: dataJump, refetch: refetchJump }] = useLazyQuery(GET_JUMP, {
        variables: { survey_no, mode  },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('jump'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getJumpPointWithRelation, { data: dataJumpPointWithRelation, refetch: refetchJumpPointWithRelation }] = useLazyQuery(GET_JUMP_POINT_WITH_RELATION, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('jumpPointWithRelation'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getJumpExampleCountWithRelation, { data: dataJumpExampleCountWithRelation, refetch: refetchJumpExampleCountWithRelation }] = useLazyQuery(GET_JUMP_EXAMPLE_COUNT_WITH_RELATION, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('jumpExampleCountWithRelation'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getJumpFlow, { data: dataJumpFlow, refetch: refetchJumpFlow }] = useLazyQuery(GET_JUMP_FLOW, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('jumpFlow'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getJumpPointAndExampleCountExist, { data: dataJumpPointAndExampleCountExist }] = useLazyQuery(GET_JUMP_POINT_AND_EXAMPLE_COUNT_EXIST, {
        variables: { survey_no, mode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('jumpPointAndExampleCountExist'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSurveyAnalysis19IsPossibleFileUpload, { data: dataSurveyAnalysis19IsPossibleFileUpload }] = useLazyQuery(GET_SURVEY_ANALYSIS_19_IS_POSSIBLE_FILE_UPLOAD, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('surveyAnalysis19IsPossibleFileUpload'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getSlackUserChannelList, { data: dataSlackUserChannelList, refetch: refetchSlackUserChannelList }] = useLazyQuery(GET_SLACK_USER_CHANNEL_LIST, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('slackUserChannelList'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [getWebhookUrls, { data: dataWebhookUrls, refetch: refetchWebhookUrls }] = useLazyQuery(GET_WEBHOOK_URLS, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: fetchPolicy.get('webhookUrls'),
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    useEffect(() => {
        if (root.surveyWorkspaces) getSurveyWorkspaces()
        if (root.surveyWorkspacesInGroup) getSurveyWorkspacesInGroup()
        if (root.pages) getSurveyPage()
        if (root.pageClassicButtons) getSurveyPageClassicButtons()
        if (root.questionAll) getSurveyQuestionAll()
        if (root.questionSlack) getSurveyQuestionSlack()
        if (root.mbox) getSurveyMbox()
        if (root.mboxMessage) getSurveyMboxMessage()
        if (root.mboxApplication) getSurveyMboxApplication()
        if (root.mboxOption) getSurveyMboxOption()
        if (root.mboxDesign) getSurveyMboxDesign()
        if (root.mboxCover) getSurveyMboxCover()
        if (root.mboxArgs) getSurveyMboxArgs()
        if (root.mboxThumbnailSrc) getSurveyMboxThmubnailSrc()
        if (root.mboxWithRelation) getSurveyMboxWithRelation()
        if (root.endings) getSurveyEndings()
        if (root.ending) getSurveyEnding()
        if (root.start) getSurveyStart()
        if (root.surveyAnalysisUserAll) getSurveyAnalysisUserAll()
        if (root.surveyAnalysisModulesAll) getSurveyAnalysisModulesAll()
        if (root.surveyAnalysisCommentsAll) getSurveyAnalysisCommentsAll()
        if (root.surveyAnalysis19IsPossibleFileUpload) getSurveyAnalysis19IsPossibleFileUpload()
        if (root.surveyQuestionsModuleAll) getSurveyQuestionsModuleAll()
        if (root.surveyQuestionsModuleSample) getSurveyQuestionsModuleSample()
        if (root.surveyMboxAnalysisDesign) getSurveyMboxAnalysisDesign()
        if (root.jump) getJump()
        if (root.jumpPointWithRelation) getJumpPointWithRelation()
        if (root.jumpExampleCountWithRelation) getJumpExampleCountWithRelation()
        if (root.jumpPointAndExampleCountExist) getJumpPointAndExampleCountExist()
        if (root.jumpFlow) getJumpFlow()
        if (root.slackUserChannelList) getSlackUserChannelList()
        if (root.webhookUrls) getWebhookUrls()
    }, [
        getSurveyWorkspaces, 
        getSurveyWorkspacesInGroup,
        getSurveyPage, 
        getSurveyPageClassicButtons,
        getSurveyQuestionAll, 
        getSurveyQuestionSlack, 
        getSurveyMbox, 
        getSurveyMboxMessage,
        getSurveyMboxOption, 
        getSurveyMboxDesign,
        getSurveyMboxCover,
        getSurveyMboxArgs,
        getSurveyMboxApplication,
        getSurveyMboxWithRelation,
        getSurveyMboxThmubnailSrc,
        getSurveyEndings,
        getSurveyEnding,
        getSurveyStart, 
        getSurveyAnalysisUserAll, 
        getSurveyAnalysisModulesAll, 
        getSurveyAnalysisCommentsAll,
        getSurveyAnalysis19IsPossibleFileUpload, 
        getSurveyQuestionsModuleAll, 
        getSurveyQuestionsModuleSample,
        getSurveyMboxAnalysisDesign,
        getJump,
        getJumpPointWithRelation,
        getJumpExampleCountWithRelation,
        getJumpPointAndExampleCountExist,
        getJumpFlow,
        getSlackUserChannelList,
        getWebhookUrls
    ])

    useEffect(() => {
        if (loading) dispatch({ type: BACKDROPSHOW })
    }, [dispatch])

    if (root.surveyWorkspaces && !dataSurveyWorkspaces) return null
    if (root.surveyWorkspacesInGroup && !dataSurveyWorkspacesInGroup) return null
    if (root.pages && !dataPages) return null
    if (root.pageClassicButtons && !dataPageClassicButtons) return null
    if (root.questionAll && !dataQuestionAll) return null
    if (root.questionSlack && !dataQuestionSlack) return null
    if (root.mbox && !dataMbox) return null
    if (root.mboxMessage && !dataMboxMessage) return null
    if (root.mboxOption && !dataMboxOption) return null
    if (root.mboxDesign && !dataMboxDesign) return null
    if (root.mboxCover && !dataMboxCover) return null
    if (root.mboxArgs && !dataMboxArgs) return null
    if (root.mboxApplication && !dataMboxApplication) return null
    if (root.mboxThumbnailSrc && !dataMboxThmubnailSrc) return null
    if (root.mboxWithRelation && !dataMboxWithRelation) return null
    if (root.endings && !dataEndings) return null
    if (root.ending && !dataEnding) return null
    if (root.start && !dataStart) return null
    if (root.surveyAnalysisUserAll && !dataSurveyAnalysisUserAll) return null
    if (root.surveyAnalysisModulesAll && !dataSurveyAnalysisModulesAll) return null
    if (root.surveyAnalysisCommentsAll && !dataSurveyAnalysisCommentsAll) return null
    if (root.surveyAnalysis19IsPossibleFileUpload && !dataSurveyAnalysis19IsPossibleFileUpload) return null
    if (root.surveyQuestionsModuleAll && !dataSurveyQuestionsModuleAll) return null
    if (root.surveyQuestionsModuleSample && !dataSurveyQuestionsModuleSample) return null
    if (root.surveyMboxAnalysisDesign && !dataSurveyMboxAnalysisDesign) return null
    if (root.jump && !dataJump) return null
    if (root.jumpFlow && !dataJumpFlow) return null
    if (root.jumpPointWithRelation && !dataJumpPointWithRelation) return null
    if (root.jumpExampleCountWithRelation && !dataJumpExampleCountWithRelation) return null
    if (root.jumpPointAndExampleCountExist && !dataJumpPointAndExampleCountExist) return null
    if (root.slackUserChannelList && !dataSlackUserChannelList) return null
    if (root.webhookUrls && !dataWebhookUrls) return null
    
    const propsFetchData = {
        surveyWorkspaces: dataSurveyWorkspaces ? dataSurveyWorkspaces.surveyWorkspaces : '',
        surveyWorkspacesInGroup: dataSurveyWorkspacesInGroup ? dataSurveyWorkspacesInGroup.surveyWorkspacesInGroup : '',
        pages: dataPages ? dataPages.surveyPage : '',
        pageClassicButtons: dataPageClassicButtons ? dataPageClassicButtons.surveyPageClassicButtons : [],
        questionAll: dataQuestionAll ? dataQuestionAll.surveyQuestionAll : '',
        questionSlack: dataQuestionSlack ? dataQuestionSlack.surveyQuestionSlack : '',
        mbox: dataMbox ? dataMbox.surveyMbox : '',
        mboxMessage: dataMboxMessage ? dataMboxMessage.surveyMboxMessage : '',
        mboxOption: dataMboxOption ? dataMboxOption.surveyMboxOption : '',
        mboxDesign: dataMboxDesign ? dataMboxDesign.surveyMboxDesign : '',
        mboxCover: dataMboxCover ? dataMboxCover.surveyMboxCover : '',
        mboxArgs: dataMboxArgs ? dataMboxArgs.surveyMboxArgs : '',
        mboxApplication: dataMboxApplication ? dataMboxApplication.surveyMboxApplication : '',
        mboxWithRelation: dataMboxWithRelation ? dataMboxWithRelation.surveyMboxWithRelation : '',
        mboxThumbnailSrc: dataMboxThmubnailSrc ? dataMboxThmubnailSrc.surveyMboxThumbnailSrc : '',
        endings: dataEndings ? dataEndings.surveyEndings : [],
        ending: dataEnding ? dataEnding.surveyEnding : '',
        start: dataStart ? dataStart.surveyStart : '',
        surveyAnalysisUserAll: dataSurveyAnalysisUserAll ? dataSurveyAnalysisUserAll.surveyAnalysisUserAll : '',
        surveyAnalysisModulesAll: dataSurveyAnalysisModulesAll ? dataSurveyAnalysisModulesAll.surveyAnalysisModulesAll : '',
        surveyAnalysisCommentsAll: dataSurveyAnalysisCommentsAll ? dataSurveyAnalysisCommentsAll.surveyAnalysisCommentsAll : '',
        surveyAnalysis19IsPossibleFileUpload: dataSurveyAnalysis19IsPossibleFileUpload ? dataSurveyAnalysis19IsPossibleFileUpload.surveyAnalysis19IsPossibleFileUpload : false,
        surveyQuestionsModuleAll: dataSurveyQuestionsModuleAll ? dataSurveyQuestionsModuleAll.surveyQuestionsModuleAll : '',
        surveyQuestionsModuleSample: dataSurveyQuestionsModuleSample ? dataSurveyQuestionsModuleSample.surveyQuestionsModuleSample : '',
        surveyMboxAnalysisDesign: dataSurveyMboxAnalysisDesign ? dataSurveyMboxAnalysisDesign.surveyMboxAnalysisDesign : '',
        jump: dataJump ? dataJump.jump : '',
        jumpPointWithRelation: dataJumpPointWithRelation ? dataJumpPointWithRelation.jumpPointWithRelation : '',
        jumpExampleCountWithRelation: dataJumpExampleCountWithRelation ? dataJumpExampleCountWithRelation.jumpExampleCountWithRelation : '',
        jumpPointAndExampleCountExist: dataJumpPointAndExampleCountExist ? dataJumpPointAndExampleCountExist.jumpPointAndExampleCountExist : '',
        jumpFlow: dataJumpFlow ? dataJumpFlow.jumpFlow : '',
        slackUserChannelList: dataSlackUserChannelList ? dataSlackUserChannelList.slackUserChannelList : '',
        webhookUrls: dataWebhookUrls ? dataWebhookUrls.webhookUrls : [],
        refetchSurveyWorkspaces,
        refetchSurveyWorkspacesInGroup,
        refetchSurveyAnalysisUserAll,
        refetchSurveyAnalysisModulesAll, 
        refetchSurveyAnalysisCommentsAll,
        refetchSurveyQuestionsModuleAll,
        refetchMbox,
        refetchMboxMessage,
        refetchMboxCover,
        refetchMboxArgs,
        refetchMboxOption,
        refetchMboxApplication,
        refetchMboxAnalysisDesign,
        refetchMboxWithRelation,
        refetchMboxThmubnailSrc,
        refetchPageClassicButtons,
        refetchQuestionAll,
        refetchJump,
        refetchJumpPointWithRelation,
        refetchJumpExampleCountWithRelation,
        refetchJumpFlow,
        refetchEndings,
        refetchStart,
        refetchSlackUserChannelList,
        refetchWebhookUrls,
        loadingMbox,
        loadingMboxOption,
        loadingMboxDesign,
        loadingMboxApplication,
        loadingEnding,
        loadingStart
    }
    

    return <WrappedComponent propsFetchData={propsFetchData} {...props} survey_no={survey_no}/>
}