import { useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { getExt } from 'utils'
import { handleDisabledRightClick } from 'ts-utils'
import ImgItemContext from 'context/imgItem'
import MuiIconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import Tooltip from 'styled/Custom/Tooltip'
import { isImage } from 'container/Image/Container'

const FileBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: oc.gray[1]
    }
})(Box)

const IconButton = withStyles({
    root: {
        position: 'absolute',
        top: 5,
        right: 3
    }
})(MuiIconButton)

function ImgComponent(props) {
    const { onChange } = useContext(ImgItemContext)
    const { tabValue, no, src, name, onDeleteFile } = props

    const ext = `.${getExt(name)}`

    const isImageValue = isImage(ext)

    const { formatMessage: f } = useIntl()
    
    return (
        <>
        {
            useMemo(() => {
                const img = <img 
                                style={{width: '100%', height: '100%'}} 
                                src={src} 
                                alt={name} 
                                onClick={() => onChange(no)}  
                                onContextMenu={handleDisabledRightClick}
                            />
                return (
                    <>
                    {
                        tabValue === 'my' && (
                            <IconButton size="small" onClick={() => onDeleteFile(no, isImageValue)} color="secondary">
                                <Tooltip title={f({id: 'component.Image.Img.remove'})}>
                                    <DeleteTwoToneIcon fontSize="small"/>
                                </Tooltip>
                            </IconButton>
                        )
                    }
                    {
                        tabValue === 'my' ? (
                            isImageValue ? img : (
                                <FileBox onClick={() => onChange(no)}>
                                    <InsertDriveFileIcon color='action' fontSize='large' alt={name} style={{marginBottom: 10, width: 50, height: 50}}/>
                                </FileBox>
                            )
                        ) : img
                    }
                    </>
                )
              
            }, [f, tabValue, no, src, name, isImageValue, onDeleteFile, onChange])
        }
        </>
    )
}

export default ImgComponent