/**
*** 페이지 기준 점프
**/
import { useEffect, ComponentType, useCallback, useState } from "react"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { useQuery } from "@apollo/client"
import { SHOW as BACKDROPSHOW } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { getKeyOfArray } from 'ts-utils'
import { GET_JUMP_IN_RESPONSE, Kind, Method, Type, JumpProps, JumpRuleProps, JumpRuleChildProps, Conditiond } from "gql/jump"
import { GET_JUMP_POINT_WITH_RELATION, JumpPointProps, JumpPointTextProps } from "gql/jump_point"
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { FormatAll, Format01, Format03, Format09, Format11 } from './SetReplys'

interface ArgsProps {
    mode: string;
    loading: boolean;
}

export interface JumpTarget {
    method: Method | null; // 이동, 완료 
    id: JumpProps['id'] | null; // 다음 페이지 또는 완료글 번호
    pmethod: Method | null; // 이전 이동
    pid: JumpProps['id'] | null; //이전 페이지 번호
}

interface ComponentProps {
    rowsJump: JumpProps[];
    isJumpPoint: boolean;
    questions: SurveyQuestionModuleProps[];
    prevQuestions: SurveyQuestionModuleProps[];
    loadingQuestions: boolean;
    target: JumpTarget;
    loadingJump: boolean;
    onOpenLoadingQuestions: () => void;
    onCloseLoadingQuestions: () => void;
    getFilterJumpQuestions: (replys: FormatAll[]) => void;
}

interface Props {
    survey_no?: number; 
    page: number;
    pages: [{
        survey_page_no: number;
        indexs: number; 
    }];
    questions: SurveyQuestionModuleProps[];
    loadingQuestions: boolean;
}

export interface ClassProps {
    jump: JumpProps[];
    jumpPoint: JumpPointProps[];
    jumpPointText: JumpPointTextProps[];
    questions: SurveyQuestionModuleProps[];
    replys: FormatAll[];
}

type JumpPointInKey = { [a: number]: JumpPointProps[] }
type JumpPointTextInKey = { [a: number]: JumpPointTextProps[] }
type PagesInKey = { [a: number]: Props['pages'][] }
type ReplysInKey = { [a: number]: FormatAll[] }
export type HideQuestionNos = number[]

export class ClassFilterJump {
    jump: ClassProps['jump'];
    jumpPointInKey: JumpPointInKey;
    jumpPointTextInKey: JumpPointTextInKey;
    questions: ClassProps['questions'];

    replys: ClassProps['replys'];
    replysInKey: ReplysInKey;  // survey_quetion_no를 키로 사용하요 다시 구성
    hideQuestionNos: HideQuestionNos; // 최종적으로 숨김파일 담기
    moveHidePageNos: HideQuestionNos; // 최종적으로 숨김파일 담기
    questionPoint: {[a: number]: number};
    targetMethod: Method | null;
    targetID: number | null;
    prevTargetMethod: Method | null;
    prevTargetID: number | null;

    constructor(props: ClassProps) {
        const { jump, jumpPoint, jumpPointText, questions, replys } = props
        const questionNos = questions.map(c => c._question.survey_question_no)
        this.jump = jump.filter(c => c.kind === Kind.Question && questionNos.includes(c.id))
        this.jumpPointInKey = getKeyOfArray('survey_question_no', jumpPoint) || {}
        this.jumpPointTextInKey = getKeyOfArray('survey_question_no', jumpPointText) || {}
        this.questions = questions
        this.replys = replys
        this.replysInKey = getKeyOfArray('survey_question_no', this.replys) || {}
        this.hideQuestionNos = [] 
        this.moveHidePageNos = []
        this.questionPoint = []
        this.targetMethod = null
        this.targetID = null
        this.prevTargetMethod = null
        this.prevTargetID = null
    }

    set HideQuestionNos(hideQuestionNos: number[]) {
        this.hideQuestionNos = hideQuestionNos
    }
    // 종료나 이동일경우 타겟값을 정한다
    getTarget():JumpTarget {
        return {
            method: this.targetMethod,
            id: this.targetID,
            pmethod: this.prevTargetMethod,
            pid: this.prevTargetID
        }
    }

    // 텍스트 비교 
    getTextSign(sign: number, answer: Format09['answer'], value: JumpRuleChildProps['value']) {
        function replace(str: string | number) {
            let s = String(str)

            s = s.replace(/\)/g, 'b').replace(/\(/g, 'c')
            
            const arr = s.replace(/\r\n/g, "\n").split("\n")

            let returnStr = ''
            for (let i=0; i<arr.length; i++) {
                const val = arr[i].trim()
                if (val) returnStr += returnStr ? `\n${val}` : val
            }

            return returnStr
        }

        const _answer = replace(answer || '')
        const _value = replace(value)
   
        // 1: 같은, 2: 로 시작, 3: 로 끝, 4: 포함, 5: 미포함
        if (sign === 1) return new RegExp(`^${_value}$`, 'i').test(_answer)
        else if (sign === 2) return new RegExp(`^${_value}`, 'i').test(_answer)
        else if (sign === 3) return new RegExp(`${_value}$`, 'i').test(_answer)
        else if (sign === 4) return new RegExp(`${_value}`, 'i').test(_answer)
        else if (sign === 5) return !new RegExp(`${_value}`, 'i').test(_answer)
        else return false
    }


    // 전체 문항의 포인트 값을구 key별로 구한다.
    points() {
        const that = this

        // 일반 선택형 포인트 구하기
        function getBase(points:JumpPointProps[], survey_module_no:number) {
            return points.find(point => point.survey_module_no === survey_module_no)
        }

        // 매트릭스형 순위형 포인트 구하기
        function getMatrix(points:JumpPointProps[], survey_module_no:number, survey_module_answer_no:number) {
            return points.find(point => point.survey_module_no === survey_module_no && point.survey_module_answer_no === survey_module_answer_no)
        }

        // 서술형 포인트 구하기
        function getText(pointsText:JumpPointTextProps[], points:JumpPointProps[], answer:string) {
            if (pointsText.length === 0) return

            for (let i=0; i<pointsText.length; i++) {
                const { jump_point_text_no, value } = pointsText[i]
                
                // 답변 포인트값
                const textPoints = points.filter(c => c.jump_point_text_no === jump_point_text_no)
                for (let j=0; j<textPoints.length; j++) {
                    const { point } = textPoints[j]
                    const is = that.getTextSign((j+1), answer, value)
                    if (is) return point
                }
            }

            return
        }

        // 해당문항기준포함해서 이전 문항의 값을 더한값이 포인트이다.
        let pointSum:number = 0
        for (let i=0; i<this.questions.length; i++) {
            const { _question } = this.questions[i]
            const { survey_question_no } = _question
            const replys = this.replysInKey[survey_question_no] || []
            const points = this.jumpPointInKey[survey_question_no] || []
            const pointsText = this.jumpPointTextInKey[survey_question_no] || []

            // replys을 불러올때 해당페이지 포함이전거까지 가져오기때문에 replys로 돌리는거자체가 이전문항이다.
            for (let j=0; j<replys.length; j++) {
                const reply = replys[j] 

                let is = undefined
                switch(reply.module_type) {
                    case '_01': // 단일 선택형
                    case '_02':
                    case '_05':
                    case '_07':
                    case '_12':
                    case '_13':
                    case '_14':
                    case '_21':
                        is = getBase(points, reply.survey_module_no)
                        if (is) pointSum += is.point
                        break
                    case '_03':
                    case '_04':
                    case '_06':
                    case '_08':
                    case '_11':
                    case '_15':
                    case '_17':
                    case '_18':
                        is = getMatrix(points, reply.survey_module_no, reply.survey_module_answer_no)
                        if (is) pointSum += is.point
                        break
                    case '_09':
                    case '_10':
                        // 주관식 답변은 무조건 한개이고, 해당값을 가지고 주관식 포인트 (= ^시작 끝$ 포함)순으로 4개를 비교해서 점수를 구한다 한개가 맞으면 끝!

                        is = getText(pointsText, points, reply.answer)
                        if (is) pointSum += is 
                       
                        break
                    default:
                        break
                }
            }

            this.questionPoint = {...this.questionPoint, [survey_question_no]: pointSum}
        }
    }

    pointSign(id:JumpProps['id'], sign: number, value:string | number) {
        const point = Number(this.questionPoint[id]) || 0
        const _value = Number(value)

        // 문항을 체크해야지만 효력이 발생한다.
        const replysInKey = this.replysInKey[id] || []

        if (replysInKey.length === 0) return false

        if (sign === 1) return point === _value
        else if (sign === 2) return point !== _value
        else if (sign === 3) return point > _value
        else if (sign === 4) return point >= _value
        else if (sign === 5) return point < _value
        else if (sign === 6) return point <= _value
        
        return false
    }

    // kind Question and 선택
    questionSign1(typename: string, jump_question_no: JumpProps['id'], jump_module_nos: JumpRuleChildProps['survey_module_nos'], jump_module_answer_no: JumpRuleChildProps['survey_module_answer_no']) {
        const replysInKey = this.replysInKey[jump_question_no] || []

        switch(typename) {
            case 'Module_01': // 단일 선택형
            case 'Module_02':
            case 'Module_05':
            case 'Module_07':
            case 'Module_12':
            case 'Module_13':
            case 'Module_14':
            case 'Module_21':
                return replysInKey.find((reply: Format01) => {
                    const { survey_question_no, survey_module_no } = reply
                    // 해당문항의 보기의 값이 체크가 된거면 true
                    return (jump_question_no === survey_question_no && survey_module_no === jump_module_nos[0])
                }) ? true : false
            case 'Module_03':
            case 'Module_04':
            case 'Module_06':
            case 'Module_08':
            case 'Module_15':
                // 점프에 설정된 항목값 + 보기값 모두가 참일경우  
                return jump_module_nos.filter(jump_module_no => {
                    return replysInKey.find((reply: Format03) => reply.survey_module_no === jump_module_no && reply.survey_module_answer_no === jump_module_answer_no)
                }).length === jump_module_nos.length
            case 'Module_11':
            case 'Module_17':
            case 'Module_18':
                // 점프에 설정된 순위값 + 보기값  참일경우  
                return replysInKey.find((reply: Format11) => {
                    return reply.survey_module_no === jump_module_nos[0] && reply.survey_module_answer_no === jump_module_answer_no
                }) ? true : false
            case 'Module_00':
            case 'Module_16':
                return true
            default:
                return false
                      
        }
    }
    
    // kind Question and 미선택
    questionSign2(typename: string, jump_question_no: JumpProps['id'], jump_module_nos: JumpRuleChildProps['survey_module_nos'], jump_module_answer_no: JumpRuleChildProps['survey_module_answer_no']) {
        const replysInKey = this.replysInKey[jump_question_no] || []

        switch(typename) {
            case 'Module_01':
            case 'Module_02':
            case 'Module_05':
            case 'Module_07':
            case 'Module_12':
            case 'Module_13':
            case 'Module_14':
            case 'Module_21':
                // 미선택일지라도 해당문항에 응답한값이 있어야한다.
                if (replysInKey.length === 0) return false

                return this.replys.filter((reply: Format01) => {
                    const { survey_question_no, survey_module_no } = reply
                    // 해당문항의 보기의 값이 체크가 된거면 true
                    return (jump_question_no === survey_question_no && survey_module_no === jump_module_nos[0])
                }).length === 0
            case 'Module_03':
            case 'Module_04':
            case 'Module_06':
            case 'Module_08':
            case 'Module_15':
                 // 점프에 설정된 항목값 + 보기값 모두가 참일경우  
                 return jump_module_nos.filter(jump_module_no => {
                    return replysInKey.find((reply: Format03) => reply.survey_module_no === jump_module_no && reply.survey_module_answer_no !== jump_module_answer_no)
                }).length === jump_module_nos.length
            case 'Module_11':
            case 'Module_17':
            case 'Module_18':
                // 점프에 설정된 순위값 + 보기값  참일경우  
                return replysInKey.find((reply: Format11) => {
                    return reply.survey_module_no === jump_module_nos[0] && reply.survey_module_answer_no !== jump_module_answer_no
                }) ? true : false
            default:
                return false
                      
        }
    }

    // 주관식 답변 체크
    questionText(jump_question_no: JumpProps['id'], sign:JumpRuleChildProps['sign'], value:JumpRuleChildProps['value']) {
    
        const replysInKey = this.replysInKey[jump_question_no]

        // 응답값이 없다면 false
        if (!replysInKey) return false

        const [{ answer }] = replysInKey

        return this.getTextSign(sign, answer || '', value)
    }

    questionAddress(jump_question_no: JumpProps['id'], sign:JumpRuleChildProps['sign'], value:JumpRuleChildProps['value']) {
    
        const replysInKey = this.replysInKey[jump_question_no]

        // 응답값이 없다면 false
        if (!replysInKey) return false

        const [{ zipcode, address1, address2 }] = replysInKey

        let answer = ''
        if (zipcode) answer = zipcode
        if (address1) answer = `${answer} ${address1}`
        if (address2) answer = `${answer} ${address2}`

        return this.getTextSign(sign, answer || '', value)
    }
    
    updateHideNos(nos: number[]) {
         // hide 일경우 nos 추가
         this.hideQuestionNos = [...this.hideQuestionNos, ...nos]
    }

    updateShowNos(nos: number[]) {
        // show 일경우 nos삭제
        this.hideQuestionNos =   this.hideQuestionNos.filter(c => !nos.includes(c))
    }
}

interface ClassMultiProps extends ClassProps {
    pages: Props['pages'];
    page: Props['page'];
}

class ClassFilterJumpMulti extends ClassFilterJump {
    pages: Props['pages'];
    pagesIndex: PagesInKey;
    pagesStandardIndexs: PagesInKey; // indexs기준으로 정령
    currentPage: number;
    currentPageIndex: Props['page'];

    constructor(props: ClassMultiProps) {
        const { pages, page, ...rest } = props
        super({ ...rest })

        this.currentPage = page
        this.pages = pages
        this.pagesIndex = getKeyOfArray('survey_page_no', pages) || {} // survey_page_no기준 indexs
        this.pagesStandardIndexs = getKeyOfArray('indexs', pages) || {} // index기준 
        this.currentPageIndex = this.pagesIndex[page] ? this.pagesIndex[page][0].indexs : -1 // 현재 페이지 인덱스 (없다면, 페이지가 삭제되었가면. 아무것도 안걸리는데 임의의 값 -1을 임시로 부여)
    }

    // 현재페이지는에서 다음으로 넘어갈때는 survey_question_nos[0], 이전페이지로 갈때는 prev_survey_page_no
    updateEndNos(method: Method, survey_question_nos: JumpProps['survey_question_nos'], pageIndex: number, prev_survey_page_no: number) {
        // 설정된값이 없다는것은 점프설정을 안햇다는것임으로 return
        if (!survey_question_nos[0]) return

        const startIndex = pageIndex
        let endIndex = this.pagesIndex[survey_question_nos[0]] ? this.pagesIndex[survey_question_nos[0]][0].indexs : 0

        // 완료글로 이동이라면, 설문 마지막문항까지 moveHidePageNos 에 담는다
        if (method === Method.End) {
            endIndex = Number(this.pages[(this.pages.length  - 1)].indexs) + 1
        }

        for (let i=(startIndex + 1); i<endIndex; i++) {
            if (this.pagesStandardIndexs[i]) {
                this.moveHidePageNos.push(this.pagesStandardIndexs[i][0].survey_page_no)
            }         
        }

        if (this.currentPageIndex === pageIndex) {
            this.targetMethod = method
            this.targetID = survey_question_nos[0]
            return
        } 

        if (pageIndex < this.currentPageIndex && survey_question_nos[0] !== this.currentPage) { // 목표값이 현재페이지값일때만 부여한다.
            this.prevTargetMethod = null
            this.prevTargetID = null
            return
        } 

        if (pageIndex < this.currentPageIndex && survey_question_nos[0] === this.currentPage) { // 목표값이 현재페이지값일때만 부여한다.
            this.prevTargetMethod = method
            this.prevTargetID = prev_survey_page_no
        }
    }

    do() {
        const that = this

        // 문항별 포인트 총합 구하기
        this.points()

        function forRules(typename: string, id: JumpProps['id'], rules: JumpRuleProps[], pageIndex: number, survey_page_no: number) {

            function forRuleChilds(childs: JumpRuleChildProps[]) {
                // childs의 전체 조건에 대한 참거짓
                let bool = false

                const childsLength = childs.length
                for (let i=0; i<childsLength; i++) {
                    const { type, sign, conditiond, survey_module_nos, survey_module_answer_no, value } = childs[i]

                    // child별 참 거짓
                    let childBool = false

                    // child 조건
                    if (type === Type.Question) {
                        if (typename === 'Module_09' || typename === 'Module_10') {
                            childBool = that.questionText(id, sign, value)
                        } else if (typename === 'Module_20') {
                            childBool = that.questionAddress(id, sign, value)
                        } else if (type === Type.Question && sign === 1) {
                            childBool = that.questionSign1(typename, id, survey_module_nos, survey_module_answer_no)
                        } else if (type === Type.Question && sign === 2) {
                            childBool = that.questionSign2(typename, id, survey_module_nos, survey_module_answer_no)
                        } 
                    } else if (type === Type.Point) {
                        childBool = that.pointSign(id, sign, value)
                    }

                    // 첫번째 조건은 conditiond pass
                    if (i === 0) {
                        bool = childBool
                        continue
                    }

                    // and 조건은 앞조건과 자신의 조건 모두 true여야 true
                    // or 조건은 앞조건이나 자신의 조건 둘중하나가 true 이면 true
                    if (conditiond === Conditiond.And) bool = bool && childBool
                    else if (conditiond === Conditiond.Or) bool = bool || childBool
                }

                return bool
            }

            // 해당 조건 참 거짓
            let bool = false

            const rulesLength = rules.length
            for (let j=0; j<rulesLength; j++) {
                const { method, survey_question_nos, childs } = rules[j]

                // child 조건문 체크
                const _bool = forRuleChilds(childs)

                // 참일경우 조건을 실행한다.
                if (_bool) {
                    if (method === Method.Hide) that.updateHideNos(survey_question_nos)
                    else if (method === Method.Show) that.updateShowNos(survey_question_nos)
                    else if (method === Method.Move || method === Method.End) {
                        that.updateEndNos(method, survey_question_nos, pageIndex, survey_page_no)
                    }

                    // 참이면 다음 룰이 false일지라도 true이다. (rules중에 하나만 true여도 else 실행하지 않기 위해서)
                    bool = true
                }
            }

            return bool
        }

        // 점프 로우데이와 replys로 숨김 보이기, 처리를 한다.
        const jumpLenth = this.jump.length
        for (let i=0; i<jumpLenth; i++) {
            const { kind, id, method, survey_question_nos, rules } = this.jump[i]

            // 페이지는 검증안한다. (미사용)
            if (kind === Kind.Page) continue

            const question = this.questions.find(c => c._question.survey_question_no === id)

            // 점프로직에는 있지만 삭제된 문항일수가있다. 그렇다면 체크할필요가 없다.
            if (!question) continue
            const { __typename, _question } = question
            const { survey_page_no } = _question

            const pageIndexObject = this.pagesIndex[survey_page_no]

            // 페이지 인덱스값이 없다는건 해당 페이지가 삭제되었다고 판단하는게 맞아서 검증처리를 안한다.
            if (!pageIndexObject) continue

            // loop 돌고있는 점프값에대한 페이지 인덱스
            const pageIndex = pageIndexObject[0].indexs

            // 해당페이지보다 큰페이지는 검증할 필요가 없다

            if (pageIndex > this.currentPageIndex) break

            // rules 검증
            const bool = forRules(__typename, id, rules, pageIndex, survey_page_no)

            // rules에 걸리는게 없다면 Default 실행
            if (!bool) {
                // 체크한 값이 있을경우 실행한다
                if (this.replysInKey[id]) {

                    // 본 문항이 숨김처리로 되어있을경우 로직 안탄다.
                    if (!this.hideQuestionNos.includes(id)) {
                        if (method === Method.Hide) that.updateHideNos(survey_question_nos)
                        else if (method === Method.Show) that.updateShowNos(survey_question_nos)
                        else if (method === Method.Move || method === Method.End) that.updateEndNos(method, survey_question_nos, pageIndex, survey_page_no)
                    }
                }
            }
        }

        return this.questions.filter(c => !this.hideQuestionNos.includes(c._question.survey_question_no))
    }
}

export function getHideQuestionNos(mode: string, questions: SurveyQuestionModuleProps[]) {
    if (mode === 'edit') return []

    return questions.filter(c => {
        const { _question } = c
        const { visible } = _question
        return !visible
    }).map(c => {
        const { _question } = c
        const { survey_question_no } = _question
        return survey_question_no
    })
}

export const initState = {
    target: {
        method: null,
        id: null,
        pmethod: null,
        pid: null
    }
}

// 현재 문항, 이전 문항 가져오기
function getQuestionsResult(questions: SurveyQuestionModuleProps[], hideQuestionNos: HideQuestionNos, hidePageNos: HideQuestionNos, survey_page_no: number, pages: Props['pages']) {
    const currentQuestions:SurveyQuestionModuleProps[] = []
    const prevQuestions:SurveyQuestionModuleProps[] = []

    const pageKeys = getKeyOfArray('survey_page_no', pages)

    if (!pageKeys[survey_page_no]) {

        return {
            currentQuestions,
            prevQuestions
        }
    }

    // 현재 페이지 번호
    const currentPageIndex = survey_page_no > 0 ? pageKeys[survey_page_no][0].indexs : 0


    // 이전 문항이면 true
    let prevFlag = true


    // 숨기기 처리된거 빼고, 이동이 1에서 4일때 2,3 뺀다. 그러나! 4에서 3으로 다시 돌아올경우 3은 다시 집어넣어야한다. 
    // 그러므로 page와 hidePageNos가 동시에있다면 hidePageNos에서 뺀다.
    const newHidePageNos = hidePageNos.filter(no => no !== survey_page_no)
    const newQuestions = questions.filter(c => !hideQuestionNos.includes(c._question.survey_question_no) && !newHidePageNos.includes(c._question.survey_page_no))

    const len = newQuestions.length

    for (let i=0; i<len; i++) {
        const question = newQuestions[i]
        const { _question } = question
        const { survey_page_no: csurvey_page_no } = _question


        // 페이지 인덱스 번호
        if (!pageKeys[csurvey_page_no]) continue
        
        const pageIndex = pageKeys[csurvey_page_no][0].indexs


        // 현재 문항이라면 currentQuestions에 담고, 이전 문항은 담지 않는다
        if (csurvey_page_no === survey_page_no) {
            prevFlag = false

            currentQuestions.push(question)
        }

        // 현재페이지 인덱스보다 작은 페이지 인덱스의 문항만 담는다.
        if (prevFlag && pageIndex < currentPageIndex && survey_page_no > 0) {
            prevQuestions.push(question)
        }
    }

    return { currentQuestions, prevQuestions }
}

export default (args: ArgsProps) => (WrappedComponent: ComponentType<ComponentProps>)  => (props: Props) => {
    const { mode, loading } = args
    const { questions, page, pages, loadingQuestions } = props

    const dispatch = useDispatch()

    const params = useParams<{ survey_no: string }>()

    const survey_no = props.survey_no ? Number(props.survey_no) : Number(params.survey_no)

    const [ data, setData ] = useState<{questions: SurveyQuestionModuleProps[], target: JumpTarget, hideQuestionNos: HideQuestionNos, hidePageNos: HideQuestionNos}>({questions, target: initState.target, hideQuestionNos: []})

    const [ _loadingQuestions, setLoading ] = useState(loadingQuestions)

    const { data:jump, loading: loadingJump } = useQuery(GET_JUMP_IN_RESPONSE, {
        variables: {
            survey_no, mode
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const { data:jumpPoint, loading: loadingJumpPoint } = useQuery(GET_JUMP_POINT_WITH_RELATION, {
        variables: {
            survey_no, mode
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const rowsJump = jump ? jump.jumpInResponse : null
    const rowsJumpPoint = jumpPoint ? jumpPoint.jumpPointWithRelation.jumpPoint : null
    const rowsJumpPointText = jumpPoint ? jumpPoint.jumpPointWithRelation.jumpPointText : null

    // 숨김처리 값 가쟈오기
    const hideQuestionNos = getHideQuestionNos(mode, questions)

    const handleOpenLoadingQuestions = useCallback(() => {
        setLoading(true)
    }, [])

    const handleCloseLoadingQuestions = useCallback(() => {
        setLoading(false)
    }, [])

    const getFilterJumpQuestions = useCallback((replys: FormatAll[]) => {
        const filterJump = new ClassFilterJumpMulti({ jump: rowsJump, jumpPoint: rowsJumpPoint,  jumpPointText: rowsJumpPointText, questions, pages, page, replys })

        filterJump.HideQuestionNos = hideQuestionNos

        filterJump.do()

        const target = filterJump.getTarget()
    
        setData({ questions, target, hideQuestionNos: filterJump.hideQuestionNos, hidePageNos: filterJump.moveHidePageNos })
    }, [rowsJump, rowsJumpPointText, rowsJumpPoint, questions, pages, page, hideQuestionNos])

    useEffect(() =>  {  
        if (loading) {
            if (loadingJump || loadingJumpPoint) dispatch({ type: BACKDROPSHOW })
        }
    }, [loading, loadingJump, loadingJumpPoint, dispatch])

    useEffect(() => {
        setData({questions, target: initState.target, hideQuestionNos: [], hidePageNos: []})
    }, [questions])

    if (!rowsJump || !rowsJumpPoint || !rowsJumpPointText) return null

    const { currentQuestions, prevQuestions } = getQuestionsResult(data.questions, data.hideQuestionNos, data.hidePageNos, page, pages)

    return (
        <WrappedComponent 
            rowsJump={rowsJump}
            isJumpPoint={jumpPoint ? true : false}
            loadingJump={loadingJump} 
            {...props} 
            questions={currentQuestions}
            prevQuestions={prevQuestions}
            loadingQuestions={_loadingQuestions}
            onOpenLoadingQuestions={handleOpenLoadingQuestions}
            onCloseLoadingQuestions={handleCloseLoadingQuestions}
            target={data.target}
            getFilterJumpQuestions={getFilterJumpQuestions}
        />
    )
}